import { AutoTabKey, JudicialAutomationModel } from '@/components/automation/judicial/types';
import { Ref } from 'vue';
import { PeriodicTaskSchedule } from '@/hooks/useSchedule';
import { Translate } from '@/components/automation/judicial/tabs/types';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';

export function defaultCheckboxOptions(model: Ref<JudicialAutomationModel>, t: Translate) {
  return {
    state: ['primary', 'short'],
    options: yesNoOptionsFn(t),
    isDisabled: !model.value.judicial_automation_enabled,
  };
}

export function yesNoOptionsFn(rootT: Translate) {
  return [
    { value: true, label: rootT('other.yes') },
    { value: false, label: rootT('other.no') },
  ];
}

export function fieldGroup(group: AutoTabKey | 'common') {
  return {
    tag: 'fieldGroup',
    key: group.toString(),
  };
}

export function updatePeriodDefault(key: keyof JudicialAutomationModel, options?: Partial<PeriodicTaskSchedule>) {
  if (!key.includes('period')) {
    throw new Error(`Error in using property ${key}`);
  }
  return function (model: Record<keyof JudicialAutomationModel, PeriodicTaskSchedule | null>, value: boolean) {
    if (!value) {
      model[key] = null;
    } else {
      model[key] = {
        minute: options?.minute ?? '0',
        hour: options?.hour ?? '10',
        day_of_week: options?.day_of_week ?? '*',
        day_of_month: options?.day_of_month ?? '*',
        month_of_year: options?.month_of_year ?? '*',
      };
    }
  };
}

export function fieldsUniter(name: AutoTabKey | 'common', fields: Record<string, unknown>[]) {
  return fields.map((field) => ({
    ...field,
    ionFieldGroup: fieldGroup(name),
  }));
}

export function getCheckbox(
  key: string,
  model: Ref<JudicialAutomationModel>,
  rootT: (s: string) => string,
  disabled?: boolean,
) {
  return {
    key,
    type: ActiveFormFieldType.checkbox,
    options: {
      state: ['primary', 'short'],
      options: yesNoOptionsFn(rootT),
      isDisabled: !model.value.judicial_automation_enabled || disabled,
    },
  };
}
