import { useProtectedInject } from '@/hooks/useProtectedInject';
import {
  DebtorDialogTitleKey,
  DebtorIdKey,
  DebtorIsOrganizationKey,
  DebtorKey, DialogIsCurrentKey,
  FetchDebtorDataKey,
  ProductionTypeKey,
} from '@/symbols';

export const useInjectDebtorDialog = () => {
  const debtor = useProtectedInject(DebtorKey);
  const debtorId = useProtectedInject(DebtorIdKey);
  const productionType = useProtectedInject(ProductionTypeKey);
  const isOrganization = useProtectedInject(DebtorIsOrganizationKey);
  const fetchDebtorData = useProtectedInject(FetchDebtorDataKey);
  const dialogIsCurrent = useProtectedInject(DialogIsCurrentKey);
  const dialogTitle = useProtectedInject(DebtorDialogTitleKey);

  return {
    debtor,
    debtorId,
    productionType,
    isOrganization,
    fetchDebtorData,
    dialogIsCurrent,
    dialogTitle,
  };
};
