import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { Company } from '@/hooks/useCompanies';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import { PermissionsModel } from '@/hooks/useCompanyPermissionsApi';
import { permissionRouteMap } from '@/hooks/useDefaultCompanyPermissions';
import { CompaniesState } from '@/store/modules/companies';
import { EmployeeRole } from '@/hooks/useEmployees';

export const getRoutePermissionChecker = (permissionsMap: PermissionsModel) => (route: keyof typeof permissionRouteMap) => {
  const routePermissionKey = permissionRouteMap[route];
  if (!routePermissionKey) {
    return 'write';
  }
  console.log('check permission', route, permissionsMap[routePermissionKey]);
  return permissionsMap[routePermissionKey] ?? null;
};

export const useProtectedDefaultCompany = () => {
  const store = useStore();

  const company = computed<Company>({
    get: () => (
      store.getters['companies/defaultCompany']
    ),
    set: async (id) => {
      await store.dispatch('companies/setDefaultCompanyId', id);
    },
  });

  const permissionsMap = computed<PermissionsModel>(
    () => store.getters['companies/permissions'],
  );

  const employeeRoles = computed<CompaniesState['employeeRoles']>(
    () => store.getters['companies/employeeRoles'],
  );

  const employeeIsManager = computed(
    () => employeeRoles.value.isOwner
      || employeeRoles.value.employeeRole === EmployeeRole.manager,
  );

  const companyId = computed(() => company.value?.id as number);

  const companyModuleType = computed(() => company.value?.module as CompanyModuleType);

  const acl = computed(() => store.getters['companies/defaultCompanyACL']);

  const hasRoutePermission = (route: keyof typeof permissionRouteMap) => getRoutePermissionChecker(permissionsMap.value)(route);

  return {
    company,
    acl,
    companyId,
    companyModuleType,
    hasRoutePermission,
    employeeRoles,
    employeeIsManager,
  };
};
