import { commonLegacyApiRequest as request } from '@core/service/commonService';
import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { UserSettingsSecureCryptoProResponse } from '@/hooks/useUserSettingsSecureCryptoProModel';
import { useUserUsesRemoteSignUserSetting } from '@/hooks/useUserUsesRemoteSignUserSetting';
import { useUser } from '@/hooks/useUser';
import { useLocalEncryptSignSelect } from '@/hooks/useLocalEncryptSignSelect';
import { useToast } from '@/hooks/useToast';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { Certificate } from '@core/plugins/cryptoPro/getUserCertificates';
import { getMockCertificate } from '@/components/dialog/dialogs/print/localSign/getMockCertificate';

type Result = {
  certificate?: Certificate;
  terminalError?: boolean;
}

export function useLocalCertificate() {

  const { userUsesRemoteSign } = useUserUsesRemoteSignUserSetting();

  const selectLocalCertificate = useLocalEncryptSignSelect();
  const { showDangerToast } = useToast();
  const { user } = useUser();
  const { companyId } = useProtectedDefaultCompany();

  async function getLocalCertificate(encrypt = true): Promise<Result> {

    // return getMockCertificate();

    const signLocal = await (
      encrypt
        ? userUsesRemoteSign().then((response) => !response.response)
        : Promise.resolve(false)
    );

    const certificate = signLocal
      ? {
        ...(await selectLocalCertificate()),
        isMock: false,
      } as Certificate
      : null;

    console.log('signLocal', signLocal, certificate);

    if (signLocal && !certificate) {
      await showDangerToast({
        label: 'print.toast.noLocalSignLabel',
        message: 'print.toast.noLocalSignMessage',
      });
      return {
        terminalError: true,
      };
    }

    if (!signLocal) {
      return {};
    }

    const response = await request<ListingResponse<UserSettingsSecureCryptoProResponse>>({
      command: ApiCommand.fetchCompanySettingsSecure,
      params: {
        key: 'crypto_pro',
        company_id: companyId.value,
      },
    });

    if (!response.status) {
      await showDangerToast({
        label: 'print.toast.noLocalSignLabel',
        message: 'print.toast.canNotFetchSavedCertificates',
      });
      return {
        terminalError: true,
      };
    }

    const currentCertificate = response.response.results
      .find((data) => data.data.thumbprint === certificate!.thumbprint);

    const saveResponse = await request<UserSettingsSecureCryptoProResponse>({
      command: currentCertificate
        ? ApiCommand.updateCompanySettingsSecure
        : ApiCommand.createCompanySettingsSecure,
      params: {
        company_id: companyId.value,
        ...(currentCertificate ? { id: currentCertificate.id } : {}),
      },
      data: {
        is_readable: true,
        key: 'crypto_pro',
        // сертификат должен быть с is_default: true, иначе любой с is_public: true его перекроет
        set_default: true,
        set_public: false,
        users: [user.value?.id],
        source: {
          certificate: certificate!.serialNumber,
          period: certificate!.periodFormatted,
          thumbprint: certificate!.thumbprint,
          owner: certificate!.fio || certificate!.name,
        },
      },
    });

    if (!saveResponse.status) {
      await showDangerToast({
        label: 'print.toast.noLocalSignLabel',
        message: 'print.toast.canNotSaveLocalCertificateDataToServer',
      });
      return {
        terminalError: true,
      };
    }

    return { certificate: certificate ?? undefined };
  }

  return { getLocalCertificate };
}
