import { Company } from '@/hooks/useCompanies';
import { ProductionType } from '@/hooks/useConstructor';
import { CompanyModuleType, DebtorQuery } from '@/pages/debtors/_module/useDebtorsActions';
import { Debtor, DebtorMainProfile } from '@/hooks/useDebtors';
import { DataFileModel } from '@/types/datafile';

export type MoveDebtorsModel = DataFileModel & {
  company_id: Company['id'];
  to: ProductionType;
  config?: {
    keep_old_active: boolean;
  };
}

type MoveDebtorsResponseDebtor = {
  id: number;
  debtor: number;
  debtor_main_profile: DebtorMainProfile;
  production_type: ProductionType;
}

export type MoveDebtorsResponse = {
  id: number;
  conflict: boolean;
  completed: boolean;
  debtors: MoveDebtorsResponseDebtor[];
  existing_ids: Debtor['pk'][];
  conflicting_ids: Debtor['pk'][];
  movable_ids: Debtor['pk'][];
  created_ids: Debtor['pk'][];
}

export type ConfirmMoveDebtorsModel = {
  company_id: Company['id'];
  move_id: number;
  filters: Record<string, boolean>;
  to: ProductionType;
}

export enum MoveDebtorsResultStatus {
  success = 'success',
  error = 'error',
  conflict = 'conflict',
  totalConflict = 'totalConflict'
}

export type MoveDebtorsResultSuccess = {
  status: MoveDebtorsResultStatus.success;
}

export type MoveDebtorsResultError = {
  status: MoveDebtorsResultStatus.error;
  message?: string;
}

export type MoveDebtorsResultConflict = {
  status: MoveDebtorsResultStatus.conflict;
  conflictingDebtorsIds: number[];
}

export type MoveDebtorsResultTotalConflict = {
  status: MoveDebtorsResultStatus.totalConflict;
  conflictingDebtorsIds: number[];
}

export type MoveDebtorsResult = MoveDebtorsResultSuccess
  | MoveDebtorsResultError
  | MoveDebtorsResultConflict
  | MoveDebtorsResultTotalConflict;
