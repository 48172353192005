import { Company } from '@/hooks/useCompanies';
import { Ref } from 'vue';
import { useApi2 } from '@/hooks/useApi2';
import { ApiCommand, ListingResponse } from '@/store/modules/api';
import {
  ApiResponse, ApiResponseError, ApiResponseSuccess, unwrapListingApiResponse,
} from '@/service/api';
import { PermissionType } from '@/hooks/useDefaultCompanyPermissions';

export type CompanyPermissionRight = 'read'|'write'

export type CompanyPermissionRaw = {
  id: number;
  production_type: PermissionType;
  right: CompanyPermissionRight;
  company: number;
  user: number;
  general_information: boolean;
  account_transaction: boolean;
  rate_service: boolean;
  organization: boolean;
  integration: boolean;
  report: boolean;
}

type CompanyPermissionToCreate = Omit<CompanyPermissionRaw, 'id'>;

export type CompanyPermission = {
  id?: number;
  production_type: PermissionType;
  right: CompanyPermissionRight;
  company: number;
  user: number;
}
export type PermissionsModel = Partial<Record<PermissionType, CompanyPermissionRight|null>>;

export const permissionsToModel = (list: CompanyPermission[]) => list.reduce((acc, el) => {
  acc[el.production_type] = el.right;
  return acc;
}, {
  [PermissionType.pretrial]: null,
  [PermissionType.judicial]: null,
  [PermissionType.executive]: null,
} as PermissionsModel);

const companyPermissionsToCreate = (
  permissions: CompanyPermission[],
): CompanyPermissionToCreate[] => {
  const final: CompanyPermission[] = [];

  const extraFields = {
    account_transaction: false,
    general_information: false,
    integration: false,
    report: false,
    rate_service: false,
    organization: false,
  } as Omit<CompanyPermissionRaw, 'id'|'production_type'|'right'|'company'|'user'>;

  permissions.forEach((perm) => {
    if (perm.production_type === PermissionType.rates_and_services) {
      extraFields.rate_service = true;
    } else if (perm.production_type === PermissionType.data_exchange_upload_report) {
      extraFields.report = true;
    } else if (perm.production_type === PermissionType.data_exchange_integrations) {
      extraFields.integration = true;
    } else if (perm.production_type === PermissionType.general_info) {
      extraFields.general_information = true;
    } else if (perm.production_type === PermissionType.billing_and_transactions) {
      extraFields.account_transaction = true;
    } else {
      final.push({
        ...perm,
      });
    }
  });

  return final.map(
    (perm) => ({
      ...extraFields,
      ...perm,
    }),
  );
};

export const mapRawPermissionsResponse = async (r: ApiResponse<CompanyPermissionRaw[]>) => {
  if (!r.status) return r;

  const permissions = r.response;
  const userPermissionsPrepared = [...permissions] as CompanyPermission[];
  if (permissions.length) {
    const perm = permissions[0];
    if (perm) {
      const { company, user } = perm;
      if (perm.general_information) {
        userPermissionsPrepared.push({
          production_type: PermissionType.general_info,
          company,
          user,
          right: 'write',
        });
      }
      if (perm.account_transaction) {
        userPermissionsPrepared.push({
          production_type: PermissionType.billing_and_transactions,
          company,
          user,
          right: 'write',
        });
      }
      if (perm.rate_service) {
        userPermissionsPrepared.push({
          production_type: PermissionType.rates_and_services,
          company,
          user,
          right: 'write',
        });
      }
      if (perm.integration) {
        userPermissionsPrepared.push({
          production_type: PermissionType.data_exchange_integrations,
          company,
          user,
          right: 'write',
        });
      }
      if (perm.report) {
        userPermissionsPrepared.push({
          production_type: PermissionType.data_exchange_upload_report,
          company,
          user,
          right: 'write',
        });
      }
    }
  }

  return {
    status: r.status,
    response: userPermissionsPrepared,
  } as ApiResponseSuccess<CompanyPermission[]>;
};

export const useCompanyPermissionsApi = (companyId: Ref<Company['id']>) => {
  const request = useApi2();

  const fetchUserPermissionsListRaw = (userId: number) => request<ListingResponse<CompanyPermissionRaw>>({
    command: ApiCommand.companyPermissionsFetchList,
    params: {
      company_id: companyId.value,
      user: userId,
      limit: 1000,
    },
  }).then(unwrapListingApiResponse);

  const fetchUserPermissionsList = (userId: number) => request<ListingResponse<CompanyPermissionRaw>>({
    command: ApiCommand.companyPermissionsFetchList,
    params: {
      company_id: companyId.value,
      user: userId,
      limit: 1000,
    },
  }).then(unwrapListingApiResponse).then(mapRawPermissionsResponse);

  const deletePermission = (id: number) => request<boolean>({
    command: ApiCommand.companyPermissionsDelete,
    params: {
      company_id: companyId.value,
      id,
    },
  });

  const deleteUserPermissions = async (userId: number) => {
    const userPermissionsResponse = await fetchUserPermissionsListRaw(userId);

    if (!userPermissionsResponse.status) {
      return {
        status: false,
        response: 'Ошибка обновления настроек доступа',
      } as ApiResponseError;
    }

    const allSuccess = await Promise.all(
      userPermissionsResponse.response.map(({ id }) => deletePermission(id)),
    );

    if (allSuccess.some((r) => !r.status)) {
      return {
        status: false,
        response: 'Ошибка обновления настроек доступа',
      } as ApiResponseError;
    }

    return {
      status: true,
      response: null,
    } as ApiResponseSuccess<null>;
  };

  const createPermission = (model: Omit<CompanyPermission, 'id'|'company'>) => request<CompanyPermission>({
    command: ApiCommand.companyPermissionsCreate,
    data: model,
    params: {
      company_id: companyId.value,
    },
  });

  return {
    fetchList: () => request<ListingResponse<CompanyPermission>>({
      command: ApiCommand.companyPermissionsFetchList,
      params: {
        company_id: companyId.value,
      },
    }).then(unwrapListingApiResponse),
    fetchUserPermissionsList,
    fetchUserPermissionsMap: (userId: number) => request<ListingResponse<CompanyPermissionRaw>>({
      command: ApiCommand.companyPermissionsFetchList,
      params: {
        company_id: companyId.value,
        user: userId,
        limit: 1000,
      },
    }).then(unwrapListingApiResponse)
      .then(mapRawPermissionsResponse)
      .then((r) => {
        if (!r.status) return r;
        return {
          status: r.status,
          response: permissionsToModel(r.response.filter((perm) => perm.user === userId)),
        };
      }),
    deleteUserPermissions,
    updateUserPermissions: async (userId: number, newPermissions: CompanyPermission[]) => {
      const deletePermissionsResult = await deleteUserPermissions(userId);

      if (!deletePermissionsResult.status) {
        return deletePermissionsResult;
      }

      const results = await Promise.all(
        companyPermissionsToCreate(newPermissions).map(
          (perm) => createPermission(perm),
        ),
      );

      if (results.some((r) => !r.status)) {
        return {
          status: false,
          response: 'Ошибка обновления доступов пользователя',
        };
      }
      return {
        status: true,
        response: null,
      };
    },
    create: (model: Omit<CompanyPermission, 'id'|'company'>) => request<CompanyPermission>({
      command: ApiCommand.companyPermissionsCreate,
      data: model,
      params: {
        company_id: companyId.value,
      },
    }),
    update: (model: Partial<CompanyPermission> & { id: number }) => request<CompanyPermission>({
      command: ApiCommand.companyPermissionsUpdate,
      data: model,
      params: {
        company_id: companyId.value,
        id: model.id,
      },
    }),
    delete: (id: number) => request<boolean>({
      command: ApiCommand.companyPermissionsDelete,
      params: {
        company_id: companyId.value,
        id,
      },
    }),
  };
};
