import { TileLine, TileParametersModel } from '@/service/api/reporting/tile';

export const tileLineModelDefaultFilters: Record<
  TileParametersModel['name'],
  {
    filters?: Record<string, any>;
    excludes?: Record<string, any>;
  }
> = {
  debtor_data: {
    filters: {
      status: 'active',
    },
  },
};

export const filtersToModelApply = {
  production_type: ['debtor_data', 'debtor_status'],
};

// фильтры, которые нельзя выбрать
export const excludedFilters = [
  'debtor_data__status',
  'debtor_status__production_type',
];
