import { Ref } from 'vue';
import { IDialog, IDialogComponent, useDialog } from '@/hooks/useDialog';
import EsiaRolesList from '@/pages/exchange/integration/dialogs/integrationGosuslugi/EsiaRolesList.vue';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useUnsubs } from '@/hooks/useUnsubs';

export const useSelectRecognitionDocumentType = (documents: Ref<{ label: string; value: string }[]>) => {
  const { showDialog } = useDialog();
  const { subscribeToSignal } = useSignal();
  const { sub, unsub, unsubAll } = useUnsubs();

  return () => new Promise<string|null>(async (resolve) => {
    let selectHasBeenMade = false;

    const closeDialog = await showDialog({
      component: IDialogComponent.content,
      addInRoute: false,
      payload: {
        title: 'Выберите тип документа',
        content: (<EsiaRolesList esiaRoleNames={documents.value}
        onSelect={(value) => {
          selectHasBeenMade = true;
          resolve(value as string);
          closeDialog();
        }}
    />),
      },
    });
    const unsubSignal = subscribeToSignal(
      SignalType.dialogClosed,
      (dialog: IDialog) => {
        if (dialog.id !== closeDialog.id) {
          return;
        }
        if (!selectHasBeenMade) {
          return;
        }
        resolve(null);
      },
    );
    sub(closeDialog);
    sub(unsubSignal);
  });
};
