// @ts-ignore
import isEq from 'lodash/fp/isEqual';

export const isEqual = isEq;

export const arrayFrom = (source: Array<any> | any) => (
  source instanceof Array ? source : [source]
);

export const getDeepField = (
  object: Record<any, any>,
  field: any,
  delimiter = '.',
) => field.split(delimiter).reduce((acc: any, cur: any) => acc?.[cur], object);

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any) {
  return (item && typeof item === 'object' && !(item instanceof Date) && !Array.isArray(item));
}

export function mergeDeep(
  target: Record<string, any>, ...sources: Record<string, any>[]
): Record<string, any> {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
