import { ProductionType, TemplateType, useConstructor } from '@/hooks/useConstructor';
import {
  ComputedRef, ref, Ref, watch,
} from 'vue';
import { DocumentAttachment, PrintType, usePrint } from '@/hooks/usePrint';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { unwrapListingApiResponse } from '@/service/api';

export const useCompanyAttachments = (
  companyId: Ref<number>,
  productionType: Ref<ProductionType>,
  groupName: Ref<string>,
  subgroupName?: Ref<string>,
  forRegisterLetters?: Ref<boolean>,
) => {
  const { showToast } = useToast();
  const isLoading = ref(false);
  const isUploading = ref(false);
  const documents = ref<DocumentAttachment[]>([]);

  const {
    fetchCompanyAttachments,
    fetchDefaultAttachments,
    createBulkAttachments,
    fetchCompanyAttachmentsToPrint,
  } = usePrint();

  const {
    fetchTemplateTypes,
    fetchCompanyTemplateTypes,
  } = useConstructor();

  const fetchTemplateTypesToPrint = async () => {
    const [
      templateTypesResponse,
      companyTemplateTypesResponse,
    ] = await Promise.all([
      fetchTemplateTypes({
        limit: 1000,
        page: 1,
        filters: { company_id: companyId.value, production_type: productionType.value },
      }).then(unwrapListingApiResponse),
      fetchCompanyTemplateTypes(companyId.value),
    ]);
    if (!templateTypesResponse.status || !companyTemplateTypesResponse.status) {
      console.error(
        `fetchTemplateTypesToPrint error: templateTypesResponse.status: ${templateTypesResponse.status}, companyTemplateTypesResponse.status: ${companyTemplateTypesResponse.status}`,
      );
      return [];
    }
    return templateTypesResponse.response.filter((t) => companyTemplateTypesResponse.response.find((tt) => tt.template_type === t.id)) as TemplateType[];
  };

  const fetchDocuments = async () => {
    isLoading.value = true;
    const [
      currentResponse,
      defaultResponse,
      companyDocumentsToPrint,
      templateTypesToPrintResponse,
    ] = await Promise.all([
      fetchCompanyAttachments({
        filters: {
          production_type: productionType.value,
          company_id: companyId.value,
          group_name: groupName.value,
          ...(groupName.value === 'court_order' ? { subgroup_name: subgroupName?.value } : {}),
          for_registered_letters: Boolean(forRegisterLetters?.value),
        },
        page: 1,
        limit: 1000,
      }),
      fetchDefaultAttachments(),
      fetchCompanyAttachmentsToPrint({ company_id: companyId.value }),
      fetchTemplateTypesToPrint(),
    ]);

    if (!currentResponse.status || !defaultResponse.status || !companyDocumentsToPrint.status) {
      await showToast({
        level: IToastLevel.danger,
        label: 'pureLabel',
        params: {
          label: 'Ошибка загрузки документов',
        },
      });
      return Promise.reject(new Error(`fetchAvailableDocuments: cannot fetch
       currentResponse.status: ${currentResponse.status}
       defaultResponse.status: ${defaultResponse.status}
       companyDocumentsToPrint.status: ${companyDocumentsToPrint.status}
       `));
    }

    documents.value = [
      ...currentResponse.response.results.map((doc) => {
        const defaultDocument = defaultResponse.response.attachments[doc.type];
        return {
          ...doc,
          ...(defaultDocument ? {
            rename_attachment_name: defaultDocument.rename_attachment_name,
          } : {}),
        };
      }),
    ] as Array<DocumentAttachment>;
    documents.value.push(
      ...companyDocumentsToPrint.response?.results.filter(
        (d) => !currentResponse.response.results.find((doc) => doc.document === d.id),
      ).map(
        ({ id, ...d }) => (
          {
            ...d,
            type: 'organization',
            document: id,
            is_active: false,
          }
        ) as DocumentAttachment,
      ),
    );

    documents.value.push(...(
      defaultResponse.response.productions[productionType.value].filter((type: string) => (
        documents.value.findIndex((d) => d.type === type) === -1
      )).map((type: string) => ({
        type,
        name: defaultResponse.response.attachments[type].name,
        is_active: false,
        rename_attachment_name: defaultResponse.response.attachments[type]?.rename_attachment_name,
      }))
    ) as Array<DocumentAttachment>);

    documents.value.push(...(
      templateTypesToPrintResponse
        .filter((tt) => documents.value.findIndex((d) => d.type === tt.name) === -1)
        .map((tt) => ({
          name: tt.description,
          type: tt.name,
          is_active: false,
          rename_attachment_name: defaultResponse.response.attachments[tt.name]?.rename_attachment_name,
        })) as Array<DocumentAttachment>
    ));

    isLoading.value = false;
  };

  watch([companyId, productionType, forRegisterLetters], async () => {
    await fetchDocuments();
  }, { immediate: true });

  const updateDocuments = async (docValues: DocumentAttachment[]) => {
    documents.value = docValues;
    isUploading.value = true;
    try {
      const attachments = documents.value.map((document, index) => ({
        ...document,
        order_number: index + 1,
        type: document.type || 'organization',
        group_name: groupName.value as string,
        subgroup_name: groupName.value && groupName.value === 'court_order' ? subgroupName?.value : null,
        for_automatic: groupName.value !== 'print',
        for_registered_letters: Boolean(forRegisterLetters?.value),
      }));

      await createBulkAttachments({
        company_id: companyId.value,
        production_type: productionType.value,
        // @ts-ignore
        attachments,
        for_automatic: groupName.value !== 'print',
        for_registered_letters: Boolean(forRegisterLetters?.value),
      });
      await fetchDocuments();
    } catch (e) {
      console.error(`updateDocuments error: ${e}`);
      await showToast({
        message: 'print.toast.failure',
        level: IToastLevel.danger,
      });
      await fetchDocuments();
    } finally {
      isUploading.value = false;
    }
  };

  return {
    fetchCompanyAttachments,
    fetchDocuments,
    documents,
    isLoading,
    isUploading,
    updateDocuments,
  };
};
