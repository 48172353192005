import { Company } from '@/hooks/useCompanies';
import { ProductionType } from '@/hooks/useConstructor';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import { Debtor } from '@/hooks/useDebtors';
import { TaskId } from '@/types/socket';

export type DataFileFilters = {
  company_id: Company['id'];
  module: CompanyModuleType;
  production_type?: ProductionType;
  debtor_ids?: Debtor['pk'][];
}
/*
debtors-data-move/
debtor/update-inn
debtor/update-vehicle
executive/fssp_update
pretrial/sms
pretrial/voice
pretrial/claim
pretrial/email
package/composite
 */
export type DataFileModel = {
  filters: DataFileFilters;
  company: Company['id'];
  payload?: any;
}

export enum DataFileStatus {
  NEW = 1,
  UPLOADED = 2,
  PASED = 3,
  PROCESSED = 4,
  FAILED = 5,
}

export enum DataFileStatusRecordStatus {
  NEW = 1,
  PROCESSING = 2,
  PROCESSED = 3,
  FAILED = 4,
}

export type DataFileStatusRecord = {
  state_name: string;
  status_text: string;
  state: DataFileStatusRecordStatus;
  status_value: number;
  status_value_max: number;
}

export type DataFileResponse = {
  task_uuid: string; // uuid типа задачи
  uuid: TaskId; // уникальный uuid задачи
  errors: { name: string; error: string }[];
  payload: {
    task_count?: number; // только в composite_update
    scarcity?: number;
    used?: number;
    result?: {
      url: string;
      name: string;
    };
  };
  id: number;
  status: DataFileStatus;
  statusrecord_set: DataFileStatusRecord[];
  detail?: string;
}

export type PackageCompositeUpdateModel = DataFileModel & {
  config: {
    fin_profile?: boolean;
    update_passports_and_inn?: boolean;
    update_organization?: boolean;
    update_vehicle?: boolean;
    update_address?: boolean;
    update_finance?: boolean;

    update_encr_address?: boolean;
    update_encr_fio?: boolean;
    update_encr_fio_by_estate?: boolean;
    update_encr_inn?: boolean;
    update_encr_passport?: boolean;
    update_encr_snils?: boolean;
    update_encr_phone?: boolean;
  };
  arguments: {
    only_main?: boolean;
    force?: boolean;
  };
}
