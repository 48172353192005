import { computed, Ref } from 'vue';
import { getDeepField } from '@/utils/object';
import { valueFormattersMap } from '@/components/activeTable/valueFormattersMap';
import { ActiveTableColumn, ActiveTableColumnFormat } from '@/components/activeTable/useActiveTable';

export type RecordsData = {keys: any[]; map: any; records: any[]};

export const useRecordsData = (
  propsRecords: Ref<any[]>,
  columns: Ref<ActiveTableColumn<any>[]>,
  keyField: Ref<string>,
  getRecordRenderKey?: Ref<undefined|((record: any) => string)>,
) => computed<RecordsData>(() => {
  // @ts-ignore
  const getRenderKey = getRecordRenderKey?.value || ((rec: any) => (rec[keyField] as string));
  const recordKeys: any[] = [];
  const records: any[] = [];
  const recordsMap = propsRecords.value?.reduce((acc, cur, i) => {
    const tableKey = getDeepField(cur, keyField.value) || i;
    recordKeys.push(tableKey);
    const rec = {
      key: tableKey,
      renderKey: getRenderKey(cur),
      record: cur,
      columns: Object.fromEntries(columns.value.map((col) => {
        const rawValue = col.getValue
          ? col.getValue(cur)
          : getDeepField(cur, col.field || col.key);
        const formatter = typeof col.format === 'function'
          ? col.format
          : valueFormattersMap[col.format || ActiveTableColumnFormat.string];
        return [col.key, {
          hasValue: rawValue !== null && rawValue !== undefined,
          value: rawValue,
          formatted: formatter(rawValue, 'ru'),
        }];
      })),
    };
    acc[tableKey] = rec;
    records.push(rec);
    return acc;
  }, {}) || {};

  return {
    keys: recordKeys,
    map: recordsMap,
    records,
  };
});
