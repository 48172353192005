import { useStore } from 'vuex';
import { computed } from 'vue';
import { ApiResponse } from '@/store/modules/api';
import { CaptchaModel } from '@urrobot/core/hooks/useCaptcha';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { RouterKey } from '@core/symbols';

export type StoreJwtTokenData = {
  value: string;
  exp: string; // timestamp
}

export type StoreJwtToken = {
  access: StoreJwtTokenData;
  refresh: StoreJwtTokenData;
}

export type UserToken = {
  token: string;
  validUntil: Date;
};

export enum UserRole {
  company= 'company',
  judge = 'judge',
  bailiff = 'bailiff',
}

export type UserData = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  user_role: UserRole;
  is_active: boolean;
  is_staff: boolean;
  is_demo: boolean;
  is_eula_accepted: boolean;
  master: null;
}

export type UpdateUserDataModel = Partial<
  {
  first_name: string;
  last_name: string;
  user_role: string;
  is_eula_accepted: boolean; }>

export type RegistrationData = {
  inn: string;
  email?: string;
  user_phone?: string;
  verification_code?: string;
}

export type SignInModel = {
  user_login?: string;
  password?: string;
  agreement?: boolean;
  demo_role?: UserRole;
}

export type SignInResponse = {
  auth_token: string;
  id: number;
  demo: boolean;
  valid_till: string;
  detail: string;
  agreement: string;
}

export type PasswordModel = {
  password: string;
  passwordConfirmation: string;
  refererId?: number;
}

export type PasswordResponse = {
  password: string;
  passwordConfirmation: string;
}

export type RestoreModel = {
  login: string;
  captcha?: {
    captcha_key: string;
    captcha_value: string;
  };
}

export type RestoreResponse = {
}

export type VerifyModel = {
  verification_code: string;
}

export type VerifyResponse = {
  verification_code: string;
}

export type SignUpModel = {
  login: string;
  inn: string;
  agreement: boolean;
  referrer_id?: string;
  captcha?: {
    captcha_key: string;
    captcha_value: string;
  };
}

export type SignUpResponse = {
  inn: string;
  email: string;
  user_phone: string;
  detail: string;
  agreement: string;
}

export type ChangePasswordModel = {
  old_password: string;
  password: string;
  confirmation: string;
}

export type ChangePasswordResponse = Partial<{
  old_password: string;
  password: string;
  confirmation: string;
}>

export type UserResponse = UserData;

export const useUser = () => {
  const store = useStore();
  const router = useProtectedInject(RouterKey);

  const signIn = async (model: SignInModel): Promise<ApiResponse<SignInResponse>> => store.dispatch(
    'user/signIn',
    model,
  );

  const signOut = async (): Promise<ApiResponse> => {
    await router.push({ name: 'sign-in', query: { forceLogOut: 1 } });
    return store.dispatch(
      'user/signOut',
    );
  };

  const signUp = async (model: SignUpModel): Promise<ApiResponse<SignUpResponse>> => store.dispatch(
    'user/signUp',
    model,
  );

  const verify = async (model: VerifyModel): Promise<ApiResponse<VerifyResponse>> => store.dispatch(
    'user/verify',
    model,
  );

  const changePassword = async (
    model: ChangePasswordModel,
  ): Promise<ApiResponse<ChangePasswordResponse>> => store.dispatch(
    'user/changePassword',
    model,
  );

  const restore = async (
    model: RestoreModel,
  ): Promise<ApiResponse<RestoreResponse>> => store.dispatch(
    'user/restore',
    model,
  );

  const setPassword = async (
    model: PasswordModel,
  ): Promise<ApiResponse<PasswordResponse>> => store.dispatch(
    'user/setPassword',
    model,
  );

  const registrationData = computed<RegistrationData | null>(() => (
    store.getters['user/registrationData']
  ));

  const resendCode = async (model?: CaptchaModel): Promise<ApiResponse> => (
    registrationData.value?.inn
      ? signUp
      : restore
  )({
    login: registrationData.value?.email || registrationData.value?.user_phone || '',
    inn: registrationData.value?.inn || '',
    agreement: true,
    ...(model ? { captcha: model } : {}),
  });

  const codeUntil = computed<Date | null>(() => (
    store.getters['user/codeUntil']
  ));

  const updateUserData = (
    model: UpdateUserDataModel,
  ) => store.dispatch('user/updateData', model) as Promise<ApiResponse<UserData>>;

  const acceptEula = (
  ) => store.dispatch('user/acceptEula') as Promise<ApiResponse<any>>;

  const user = computed<UserData | null>(() => (
    store.getters['user/data']
  ));

  const isStaff = computed(
    () => user.value?.is_staff,
  );

  return {
    signOut,
    signIn,
    signUp,
    verify,
    registrationData,
    codeUntil,
    resendCode,
    setPassword,
    restore,
    user,
    isStaff,
    changePassword,
    updateUserData,
    acceptEula,
  };
};
