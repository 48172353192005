// eslint-disable-next-line import/no-unresolved
import { SeriesOption } from 'echarts/types/dist/shared';
import { CHART_COLORS, CHART_COLORS_1, DefaultTooltipConfig } from '@/components/plate/utils';
import { ChartLineSeriesVariant } from '@/components/plate/chartConfigs/lineChart/line-chart';

export enum ChartPolarSeriesVariant {
  Line = 'line',
  FillArea = 'fill-area',
  Bar = 'bar'
}

export const chartPolarSeriesVariantOptions = [
  {
    label: 'Стандартная',
    value: ChartPolarSeriesVariant.Line,
  },
  {
    label: 'Заполненеая',
    value: ChartPolarSeriesVariant.FillArea,
  },
  {
    label: 'Область',
    value: ChartPolarSeriesVariant.Bar,
  },
];

export const PolartChartBasicOptions = {
  tooltip: DefaultTooltipConfig,
  angleAxis: {
    show: true,
    type: 'category',
    axisTick: {
      show: false,
    },
    axisLabel: {
      opacity: 0.5,
      color: '#5e6476',
    },
    axisLine: {
      lineStyle: {
        width: 2,
        color: '#e9eef7',
      },
    },
  },
  polar: {
    radius: [0, '80%'],
  },
  radiusAxis: {
    show: true,
    type: 'value',
    axisLabel: {
      show: false,
    },
    axisLine: {
      show: false,
      lineStyle: {
        width: 10,
      },
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: true,
      interval: 2,
    },
    splitArea: {
    },
  },
  series: [],
};

export const getPolarChartSeriesLine = ({
  name,
  id,
  stack,
  encode,
  color,
}: any) => ({
  name,
  id,
  stack,
  encode,
  type: 'line',
  symbolSize: 7,
  coordinateSystem: 'polar',
  label: {
    show: false,
  },
  lineStyle: {
    color,
  },
}) as SeriesOption;

export const getPolarChartSeriesFillArea = ({
  name,
  id,
  stack,
  encode,
  color,
}: any) => ({
  name,
  id,
  stack,
  encode,
  type: 'line',
  barGap: '-100%',
  barCategoryGap: '50%',
  symbolSize: 7,
  symbol: 'none',
  connectNulls: true,
  clip: false,
  coordinateSystem: 'polar',
  areaStyle: {
    opacity: 0.4,
    color,
  },
  lineStyle: {
    show: false,
    opacity: 0,
  },
});

export const getPolartChartSeriesBar = ({
  name,
  id,
  stack,
  encode,
  color,
}: any) => ({
  name,
  id,
  stack,
  encode,
  type: 'bar',
  barGap: '-100%',
  barCategoryGap: '50%',
  symbolSize: 7,
  emphasis: {
    focus: 'self',
  },
  coordinateSystem: 'polar',
  label: {
    show: false,
    position: 'middle',
    formatter: '{b}: {c}',
  },
  itemStyle: {
    color,
  },
}) as SeriesOption;
