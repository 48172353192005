import { ActiveFormFieldType } from '@/hooks/useActiveForm';

const defaultOptions = {
  state: ['primary'],
};

const stringInput = (key: string, label: string) => {
  return {
    key,
    field: key,
    type: ActiveFormFieldType.input,
    options: {
      ...defaultOptions,
      label,
    },
  };
};

const selectInput = (key: string, label: string, options: unknown) => {
  return {
    key,
    field: key,
    type: ActiveFormFieldType.select,
    options: {
      ...defaultOptions,
      options,
      label,
    },
  };
};

const booleanInput = (key: string, label: string) => {
  return {
    key,
    field: key,
    type: ActiveFormFieldType.checkbox,
    defaultValue: false,
    options: {
      ...defaultOptions,
      label,
    },
  };
};

const fromOptions = [{
  value: 'ul',
  label: 'Юр.лицо',
}, {
  value: 'ip',
  label: 'ИП',
}];

export const fields = [
  stringInput('name', 'Название'),
  selectInput('income_from', 'Юр.лицо / ИП', fromOptions),
  stringInput('inn', 'ИНН'),
  stringInput('fact_addr', 'Юридический адрес'),
  stringInput('reg_addr', 'Фактический адрес'),
  booleanInput('is_general_director', 'Генеральный директор'),
  booleanInput('is_founder', 'Учредитель'),
];
