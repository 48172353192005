import {
  fieldsUniter, getCheckbox,
} from '@/components/automation/judicial/tabs/utils';
import { AutoTabKey, JudicialAutomationModel } from '@/components/automation/judicial/types';
import { Ref } from 'vue';

export const getCalculationsFields = (
  model: Ref<JudicialAutomationModel>,
  rootT: (s: string) => string,
) => {
  return fieldsUniter(AutoTabKey.calculations, [
    getCheckbox('short_penalty_calculation', model, rootT),
  ]);
};
