import {
  computed, ref, Ref,
} from 'vue';
import { Debtor } from '@/hooks/useDebtors';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { DebtorPayload, useDialogRouteParam } from '@/hooks/useDialog';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { ITab } from '@/components/tabs/useTabs';
import { ProductionType } from '@/hooks/useConstructor';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';

export type ActiveTabKey = 'main' | 'organization_details' | 'person_details' | 'vehicle' | 'tenants' | 'owners';
export const useDebtorCommonDialogTabs = (
  debtor: Ref<Debtor>,
  productionType: Ref<DebtorPayload['productionType']>,
  isOrganization: Ref<boolean>,
) => {
  const { t } = useLocalI18n('debtor.common');
  const { companyModuleType } = useDefaultCompany();

  const isVisibleOrganizationTab = () => isOrganization.value;
  const isVisiblePersonTab = () => companyModuleType.value === 2
    && !isOrganization.value;

  const tabs = computed<Array<ITab<ActiveTabKey>>>(() => (([
    {
      key: 'main',
      label: t('main.tab'),
      visible: true,
    },
    isVisibleOrganizationTab() && {
      key: 'organization_details',
      label: t('organization_details.tab'),
    },
    isVisiblePersonTab() && {
      key: 'person_details',
      label: t('person_details.tab'),
    },
    companyModuleType.value === 2 && {
      key: 'vehicle',
      label: t('vehicle.tab'),
    },
    !isOrganization.value && {
      key: 'tenants',
      label:
        companyModuleType.value === 4
          ? 'Должник'
          : productionType.value === ProductionType.executive
            ? t('tenants.tabExecutive')
            : t('tenants.tab'),
    },
    companyModuleType.value === 1 && productionType.value === ProductionType.judicial && {
      key: 'owners',
      label: t('owners.tab'),
    },
    companyModuleType.value === 4 && {
      key: 'borrower',
      label: 'Заемщик',
    },
  ] as Array<ITab<ActiveTabKey> | boolean>).filter(Boolean) as Array<ITab<ActiveTabKey>>)
    .map((tab) => ({
      ...tab,
      id: `modal_debtor_common_${tab.key}_tab`,
    })) as Array<ITab<ActiveTabKey>>);

  const [activeTab] = useDialogRouteParam<ActiveTabKey>(
    'common-tab',
    'main',
    ref(true),
    true,
  );

  return {
    t,
    activeTab,
    tabs,
  };
};
