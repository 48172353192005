import {
  mapTileFromResponse,
  Tile,
  TileRaw,
  UUID,
} from '@/service/api/reporting/tile';
import * as GridConfig from '@/shared/grid';

export enum ReportingDashboardMode {
  user = 'user',
  company = 'company',
  user_and_company = 'user_and_company',
}

export type ReportingDashboard = {
  id: UUID;
  name: string;
  mode: ReportingDashboardMode;
  position: number;
  config: GridConfig.GridConfig;
  company?: number|null;
  user?: number|null;
  parent_id?: string;
  isCustomDefault?: boolean;
  companies?: number[]|null;
  all_companies?: boolean|null;
}
export type ReportingDashboardPostRaw = { config: GridConfig.GridConfigJSON}
  & Omit<ReportingDashboard, 'config'|'id'|'position'>

export type ReportingDashboardRaw = { config: GridConfig.GridConfigJSON}
  & ReportingDashboard

export type ReportingDashboardFull = ReportingDashboard & { tiles: Tile[] }

export type ReportingDashboardFullRaw = Omit<ReportingDashboardFull, 'config'|'tiles'> & {
  config: GridConfig.GridConfigJSON;
  tiles: TileRaw[];
}

export const mapReportingDashboardToPostPayload = (
  d: Omit<ReportingDashboard, 'id'|'position'>,
) => ({
  ...d,
  config: GridConfig.gridConfigToJSON(d.config),
});

export const mapReportingDashboardToPayload = (d: ReportingDashboard) => ({
  ...d,
  config: GridConfig.gridConfigToJSON(d.config),
});

export const mapReportingDashboardFromResponse = (d: ReportingDashboardRaw) => ({
  ...d,
  config: GridConfig.gridConfigFromResponse(d.config),
});

export const mapReportingDashboardFullFromResponse = (d: ReportingDashboardFullRaw) => ({
  ...d,
  config: GridConfig.gridConfigFromResponse(d.config),
  tiles: d.tiles.map((tile) => mapTileFromResponse(tile)),
});
