import { ActiveTableColumn } from '@/components/activeTable/useActiveTable';
import { computed, Ref } from 'vue';
import { arrayFrom } from '@/utils/object';

export const useGridTemplateWidth = (
  columns: Ref<Array<ActiveTableColumn<any>>>,
  hasRowActions: Ref<boolean>,
) => {
  // предполагаем ширину только в пикселях
  // TODO сделать ширину в других единицах измерения
  const getColumnMinWidth = (width?: string|number) => {
    if (!width) return 120;
    if (typeof width === 'string') {
      if (width.endsWith('fr')) {
        const parsed = parseFloat(width);
        // умножаем на 100, т.к. это fr
        return parsed > 5 ? parsed : parsed * 100;
      }
      if (width.startsWith('(minmax')) {
        const val = width.match(/minmax\((\d+)/);
        if (!val || !val[1]) return 120;
        return Number.parseFloat(val[1]) || 120;
      }
      return Number.parseFloat(width) || 120;
    }
    return 120;
  };

  const gridTemplateMinWidth = computed(() => {
    const colWidth = gridTemplateArray.value.map((width) => (
      getColumnMinWidth(width)
    ));
    return colWidth.reduce((acc: number, width: number) => acc + width);
  });

  const gridTemplateArray = computed(() => (
    columns.value.map(({ key, width }) => (
      key === '__actions' && hasRowActions.value
        ? 'minmax(90px, auto)'
        : width
          ? (typeof width === 'string')
            ? width.startsWith('minmax') ? width : `minmax(${width}, 1fr)`
            : `${width || 1}fr`
          : 'minmax(120px, 1fr)'
    ))
  ));

  const gridTemplate = computed(() => (
    gridTemplateArray.value.join(' ')
  ));

  return {
    gridTemplateMinWidth, gridTemplate,
  };
};
