import { Employee } from '@/hooks/useEmployees';
import { getPercantageChange } from '@/utils/number';

export const getRandomString = (length = 7): string => Math.random().toString(36).substr(2, length);

export const isAtLeastPartialEmail = (string: string) => string.includes('@');
export const isAtLeastPartialPhoneDirty = (string: string) => string.match(/^(\+|\d|\(|\)|-)+$/g);
export const isAtLeastPartialPhone = (string: string) => string.match(/^(\+|\d)+$/g);

export const
  formatPhone = (phone?: string) => String(phone || '')
    .replace(/[^\d]/g, '')
    .replace(/\d(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4');

export const compareStrings = (
  v1: string, v2: string,
) => v1.toLowerCase() === v2.toLowerCase();

const intlFormatMoneySimpleRub = (new Intl.NumberFormat('ru', {
  currency: 'RUB',
  style: 'currency',
  currencyDisplay: undefined,
}));

export const formatMoneySimple = (amount = 0, currency = 'RUB', locale?: string) => (
  intlFormatMoneySimpleRub.format(amount)
);

const intlFormatMoneyRub = (new Intl.NumberFormat('ru', {
  currency: 'RUB',
  style: 'currency',
  currencyDisplay: 'symbol',
  maximumFractionDigits: 2,
}));

export const formatMoney = (amount = 0, currency = 'RUB', locale?: string) => (
  intlFormatMoneyRub.format(amount)
);

export const formatNumber = (amount = 0, locale?: string) => (
  (new Intl.NumberFormat(locale, {})).format(amount)
);

export const formatDuration = (duration: number) => ([
  Math.floor(duration / 60),
  duration % 60,
].map((i) => String(i).padStart(2, '0')).join(':'));

export const formatFullName = ({ first_name, last_name, middle_name }: Partial<Employee>) => ([
  last_name,
  first_name,
  middle_name,
].filter(Boolean).join(' '));

export const copyToClipboard = async (somethingToCopy: string) => {
  try {
    await navigator.clipboard.writeText(somethingToCopy as string);
  } catch (e) {
    // @TODO implement text input polyfill
  }
};

export const capitalizeFirstLetter = (
  string: string,
) => string.charAt(0).toUpperCase() + string.slice(1);

export const clearGosuslugiPhone = (
  str: string,
) => `7${str.replace(/(^8)|^7|\+7|\(|\)|-/gi, '')}`;

export function copy2DToClipboard(arr: string[][]) {
  const range = document.createRange();
  const sel = document.getSelection();
  const tableWrapper = document.createElement('div');
  const table = document.createElement('table');
  arr.forEach((row) => {
    const tr = document.createElement('tr');
    row.forEach((cell) => {
      const td = document.createElement('td');
      td.innerText = cell;
      tr.appendChild(td);
    });
    table.appendChild(tr);
  });
  tableWrapper.appendChild(table);
  tableWrapper.style.position = 'absolute';
  tableWrapper.style.zIndex = '-1';
  tableWrapper.style.opacity = '0';
  document.body.appendChild(tableWrapper);
  sel?.removeAllRanges();
  try {
    range.selectNodeContents(table);
    sel?.addRange(range);
  } catch (e) {
    console.error(e);
    range.selectNode(table);
    sel?.addRange(range);
  }
  const copyResult = document.execCommand('copy', false);
  // console.log('copy2D', { input: arr, result: copyResult });
  document.body.removeChild(tableWrapper);
}
