import {
  PlateIndicatorFractionVariant,
  PlateIndicatorSimpleVariants,
} from '@/components/plate/plateIndicator/types';
import { PlateIndicatorType } from '@/components/plate/plateIndicator';
import { PlateIndicatorListVariant } from '@/components/plate/list/list';
import { ChartBarVariant } from '@/components/plate/chartConfigs/bar-charts';
import { ChartLineVariant } from '@/components/plate/chartConfigs/lineChart/line-chart';
import { ChartPieVariant } from '@/components/plate/chartConfigs/pie-charts';

export enum ReportingChartPlateType {
  Indicator = 'indicator',
  // IndicatorList = 'indicator-list',
  ByStatus = 'by-status',
  Timeline = 'timeline',
}

export const plateTypeOptions = [
  {
    label: 'Показатель',
    value: ReportingChartPlateType.Indicator,
  },
  // {
  //   label: 'Набор показателей',
  //   value: ReportingChartPlateType.IndicatorList,
  // },
  {
    label: 'Значения по категории',
    value: ReportingChartPlateType.ByStatus,
    hint: 'Все значения являются частью целого. Например все должники делятся по статусу делопроизводства либо по сумме долга. Показывается сумма их процент или среднее за весь период',
  },
  {
    label: 'Значения по временной шкале',
    value: ReportingChartPlateType.Timeline,
  },
  // {
  //   label: 'Значения по временной шкале и по статусам',
  //   value: 'timeline-by-status',
  // },
];

export const plateVariantOptions = {
  [ReportingChartPlateType.Indicator]: [
    `${PlateIndicatorType.Simple}--${PlateIndicatorSimpleVariants.indicator}`,
    `${PlateIndicatorType.Fraction}--${PlateIndicatorFractionVariant.Ring}`,
    `${PlateIndicatorType.List}--${PlateIndicatorListVariant.List}`,
    // `${PlateIndicatorType.Simple}--${PlateIndicatorListVariant.List}`,
  ],
  [ReportingChartPlateType.ByStatus]: [
    `${PlateIndicatorType.Pie}--${ChartPieVariant.Pie}`,
    `${PlateIndicatorType.Pie}--${ChartPieVariant.Ring}`,
    `${PlateIndicatorType.Pie}--${ChartPieVariant.VerticalBars}`,
    // `${PlateIndicatorType.Bar}--${ChartBarVariant.Polar}`,
    // `${PlateIndicatorType.Bar}--${ChartBarVariant.PolarRoseOfWind}`,
    // `${PlateIndicatorType.Bar}--${ChartBarVariant.Vertical}`,
  ],
  [ReportingChartPlateType.Timeline]: [
    `${PlateIndicatorType.Line}--${ChartLineVariant.Linear}`,
    `${PlateIndicatorType.Line}--${ChartLineVariant.Polar}`,
  ],
};

export const plateNames = {
  [PlateIndicatorType.Simple]: [
    {
      name: 'Индикатор',
      value: PlateIndicatorSimpleVariants.indicator,
    },
    {
      name: 'Список',
      value: PlateIndicatorListVariant.List,
    },
    // {
    //   name: 'Кольцо',
    //   value: PlateIndicatorSimpleVariants.ring,
    // },
  ],
  [PlateIndicatorType.Fraction]: [
    {
      name: 'Доля',
      value: PlateIndicatorFractionVariant.Ring,
    },
  ],
  [PlateIndicatorType.List]: [
    // {
    //   name: 'Плитки',
    //   value: PlateIndicatorListVariant.Plates,
    // },
    {
      name: 'Список',
      value: PlateIndicatorListVariant.List,
    },
    // {
    //   name: 'Слайдер',
    //   value: PlateIndicatorListVariant.Slider,
    // },
    // {
    //   name: 'Шкала эффективности',
    //   value: PlateIndicatorListVariant.SliderSpeedometer,
    // },
  ],
  [PlateIndicatorType.Pie]: [
    {
      name: 'Пирог',
      value: ChartPieVariant.Pie,
    },
    {
      name: 'Кольцо',
      value: ChartPieVariant.Ring,
    },
    {
      name: 'Столбцы',
      value: ChartPieVariant.VerticalBars,
    },
  ],
  [PlateIndicatorType.Line]: [
    // {
    //   name: 'Линия',
    //   value: ChartLineVariant.Linear,
    // },
    {
      name: 'Линейная',
      value: ChartLineVariant.Linear,
    },
    {
      name: 'Полярная',
      value: ChartLineVariant.Polar,
    },
    // {
    //   name: 'Линия - гладк. заполн.',
    //   value: ChartLineVariant.LinearSmoothFillArea,
    // },
    // {
    //   name: 'Полярн',
    //   value: ChartLineVariant.Polar,
    // },
    // {
    //   name: 'Полярн - заполн. обл.',
    //   value: ChartLineVariant.PolarFillArea,
    // },
  ],
  [PlateIndicatorType.Bar]: [
    // {
    //   name: 'Вертикальная',
    //   value: ChartBarVariant.Vertical,
    // },
    // {
    //   name: 'Горизонтальная',
    //   value: ChartBarVariant.Horizontal,
    // },
    // {
    //   name: 'Полярная',
    //   value: ChartBarVariant.Polar,
    // },
    // {
    //   name: 'Роза ветров',
    //   value: ChartBarVariant.PolarRoseOfWind,
    // },
  ],
};
