/* eslint-disable no-else-return */
import * as E from 'fp-ts/Either';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import { pipe, flow } from 'fp-ts/lib/function';
import {
  getApiRequest, isServerError,
} from './serviceConstructor';
import { apiUrl } from '@urrobot/web/src/utils/env';
import { UserRole } from '@urrobot/web/src/hooks/useUser';
import { IToastLevel } from '@urrobot/web/src/hooks/useToast';
import { resolveStore } from '../utils/resolveStore';

let customApiUrl:string|null = null;

const authServiceRequestBase = getApiRequest({
  baseUrl: () => customApiUrl || apiUrl,
  interceptor: {
    request: [
      async (config) => {
        config.headers['Content-Type'] = 'application/json';
        return config;
      },
    ],
  },
});

resolveStore().then((store) => {
  store.watch((state) => state.user.customApiUrl, (url) => {
    customApiUrl = url;
  }, { immediate: true });
});

export const authServiceRequest = flow(
  authServiceRequestBase,
  TE.orElse(
    (e) => pipe(
      TE.leftTask(async () => {
        if (e === null) return O.none;
        else if (isServerError(e)) {
          const store = await resolveStore();
          store.dispatch(
            'layout/showToast',
            {
              level: IToastLevel.danger,
              label: 'pureLabel',
              params: { label: 'Ошибка сервера' },
            },
          );
          return O.none;
        } else {
          return O.some(e);
        }
      }),
    ),
  ),
);

export type AuthModel = {
  user_login: string;
  password: string;
  captcha?: {
    captcha_key: string;
    captcha_value: string;
  };
} | { demo_role: UserRole }

export type AuthResponseSuccess = {
  refresh: string; access: string;
}

export type NoUserAuthError = {
  detail: 'Пользователь или пароль не найден';
}

export type CaptchaRequiredAuthError = {
  detail: 'Для входа нужно ввести капчу';
}

export type CaptchaErrors = 'Invalid or expired captcha key'|'Invalid captcha value'

export type AuthError = {
  password: string[];
  user_login: string[];
}

export type CaptchaAuthError = {
  captcha: { non_field_errors: Array<CaptchaErrors> } & Record<string, string[]|string>;
}

export type InvalidTokenError = {
  detail: 'Недопустимый заголовок токена. Токен не должен содержать пробелов.';
}

export type AuthRequiredError = {
  detail: 'Учетные данные не были предоставлены.';
}

export type TokenNotValidAuthError = {
  detail: string;
  code: 'token_not_valid';
  messages: [{ token_type: 'access'|'refresh' }];
}

export type AuthServiceCapthaResponse = {
  captcha_key: string;
  captcha_image: string;
  image_type: 'image/png';
  image_decode: 'base64';
};

export const isNoUserAuthError = (
  e: any,
): e is NoUserAuthError => e && e.detail === 'неверно введены данные для аутентификации';
export const isAuthError = (
  e: any,
): e is AuthError => e && ((e as AuthError).user_login || (e as AuthError).password);
export const isCapthaRequiredError = (
  e: any,
): e is CaptchaRequiredAuthError => e && e.detail === 'Для входа нужно ввести капчу';
export const isCaptchaAuthError = (
  e: any,
): e is CaptchaAuthError => e && !!e.captcha;
export const isInvalidTokenError = (
  e: any,
): e is InvalidTokenError => e
  && (e as InvalidTokenError).detail?.startsWith('Недопустимый');
export const isTokenNotValidAuthError = (
  e: any,
): e is TokenNotValidAuthError => e
  && (e as TokenNotValidAuthError).code === 'token_not_valid';
export const isAuthRequiredError = (
  e: any,
): e is AuthRequiredError => e
  && (e as AuthRequiredError).detail === 'Учетные данные не были предоставлены.';

export const AuthService = {
  generateCaptcha: () => pipe(
    authServiceRequest<AuthServiceCapthaResponse, any>({
      url: '/auth/captcha/',
      method: 'POST',
    }),
    TE.map((r) => ({
      ...r,
      src: `data:${r.image_type};${r.image_decode},${r.captcha_image}`,
    })),
  ),
  signIn: (model: AuthModel) => authServiceRequest<
    AuthResponseSuccess, AuthError|NoUserAuthError|CaptchaRequiredAuthError|CaptchaAuthError
    >({
      url: '/auth/jwt/token/',
      method: 'POST',
      data: model,
    }),
  signOut: (token: string) => authServiceRequest<{ token: string }>({
    url: '/auth/jwt/token/blacklist/',
    method: 'POST',
    data: { token },
  }),
  verify: (token: string) => authServiceRequest<{ token: string }, TokenNotValidAuthError>({
    url: '/auth/jwt/token/verify/',
    method: 'POST',
    data: { token },
  }),
  refresh: (refresh: string) => authServiceRequest<{ refresh: string; access: string }, TokenNotValidAuthError>({
    url: '/auth/jwt/token/refresh/',
    method: 'POST',
    data: { refresh },
  }),
};
