import { Module } from 'vuex';
import { StoreState } from '@/store';
import {
  FetchCompanyBalanceModel, FetchCompanyLicenses,
} from '@/hooks/useFinance';
import {
  ApiCommand, ApiRequest,
} from '@/store/modules/api';

export type FinanceState = {
}

type FinanceModule = Module<FinanceState, StoreState>;

export const namespaced = true;

export const actions: FinanceModule['actions'] = {
  async fetchCompanyBalance({ dispatch }, { id }: FetchCompanyBalanceModel) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.fetchCompanyBalance,
      params: { id },
    } as ApiRequest, { root: true });
    return { status, response };
  },
  async fetchAvailableTariffs({ dispatch }) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.fetchAvailableLicenses,
    } as ApiRequest, { root: true });
    return { status, response };
  },
  async fetchCompanyLicenses({ dispatch }, { id } : FetchCompanyLicenses) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.fetchCompanyLicenses,
      params: { id },
    } as ApiRequest, { root: true });
    return { status, response };
  },
  async fetchCompanyLicensesActiveCount({ dispatch }, { id } : FetchCompanyLicenses) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.fetchCompanyLicensesActiveCount,
      params: { id },
    } as ApiRequest, { root: true });
    return { status, response };
  },
  async companyLicenseBuy({ dispatch }, { id, licenseId }: { id: number; licenseId: number }) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.companyLicenseBuy,
      params: { id },
      data: { id: licenseId },
    } as ApiRequest, { root: true });
    return { status, response };
  },
};
