import { ProgressContext } from '@/store/modules/tasksProgress';
import { commonLegacyApiRequest, getAuthProtectedFile1 } from '@core/service/commonService';
import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { unwrapListingApiResponse } from '@/service/api';
import * as E from 'fp-ts/Either';
import {
  executeCryptoProTaskWithObjectsCleanUp,
  signFileWithCertThumbprint,
} from '@core/plugins/cryptoPro/signFileWithCertObj';
import { IToastLevel } from '@/hooks/useToast';

type SignDocument = {
  id: number;
  file: string | null;
  final_file: string | null;
  file_hash: string;
}

export async function signDocument(
  context: ProgressContext,
  ids: number[],
  certThumbprint: string,
  logError: (e: Error) => void,
  rewriteDocumentSigns?: boolean,
) {

  const result = await commonLegacyApiRequest<ListingResponse<SignDocument>>({
    command: ApiCommand.signDocumentFetchList,
    params: {
      gd_ids: ids.join(), limit: 10000,
    },
  }).then(unwrapListingApiResponse);

  if (!result.status) {
    logError(new Error('Local sign error: fetch for related documents failed'));
    return {
      status: false,
      response: null,
    };
  }
  if (!result.response.length) {
    logError(new Error(`Local sign error: can not fetch related to some in ${ids.join()} documents`));
    return {
      status: false,
      response: null,
    };
  }
  const docs = result.response as SignDocument[];
  const signResults = await Promise.all(
    docs.map(async (doc) => {
      if (!rewriteDocumentSigns && doc.final_file) {
        // уже подписан - переподписывать не нужно
        return {
          id: doc.id,
          status: true,
        };
      }
      const url = doc.file;
      if (!url) {
        const message = `Local sign error: can not sign file, file has no url, signFileId: ${doc.id}`;
        console.error(new Error(message));
        logError(new Error(message));
        return {
          id: doc.id,
          status: false,
        };
      }
      const fileName = url.substring(url.lastIndexOf('/') + 1);
      const response = await getAuthProtectedFile1(url, fileName);
      if (E.isLeft(response)) {
        const message = `Local sign error: can not fetch file for sign: ${JSON.stringify(response.left)}`;
        console.error(new Error(message));
        logError(new Error(message));
        return {
          id: doc.id,
          status: false,
        };
      }

      console.log('signDocument originalId:', doc.id);
      const signResult: File | Error = await executeCryptoProTaskWithObjectsCleanUp(() => signFileWithCertThumbprint(
        certThumbprint,
        response.right,
      ).catch((e) => {
        console.log('signResult error', e);
        console.error(e);
        return e;
      }));

      console.log('signResult', 'originalId:', doc.id, signResult);

      if (signResult instanceof Error || signResult?.type === 'error') {
        const message = (signResult as { message: string }).message;
        console.error(new Error(`Local sign error: can not sign file: ${message}`));
        await context.dispatch('layout/showToast', {
          message: 'pure',
          params: { message },
          level: IToastLevel.danger,
        }, { root: true });
        if (message.indexOf('доверия') !== -1) {
          window.open('https://help.kontur.ru/diadoc', '_blank');
        }
        logError(new Error(`Local sign error: can not sign file: ${(signResult as { message: string }).message}`));
        return {
          id: doc.id,
          status: false,
        };
      }

      const saveSignResult = await commonLegacyApiRequest({
        command: ApiCommand.signDocumentUpdateItem,
        params: {
          id: doc.id,
        },
        data: {
          final_file: signResult,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (!saveSignResult.status) {
        const message = `Local sign error: can not upload sign file, signFileId: ${doc.id}`;
        console.error(new Error(message));
        logError(new Error(message));
        return {
          id: doc.id,
          status: false,
        };
      }

      return {
        id: doc.id,
        status: true,
      };
    }),
  );

  return {
    status: signResults.every((result) => result.status),
    successIds: signResults.filter((result) => result.status).map((result) => result.id),
    failedIds: signResults.filter((result) => !result.status).map((result) => result.id),
  };
}
