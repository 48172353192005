import { ActionContext, Module, Plugin } from 'vuex';
import * as E from 'fp-ts/Either';
import { StoreState } from '@/store';
import { IToast, IToastLevel, IToastProgressbar } from '@/hooks/useToast';
import { ApiCommand, ApiResponse, ListingResponse } from '@/store/modules/api';
import { DataFileResponse, DataFileStatus, DataFileStatusRecordStatus } from '@/types/datafile';
import { SocketSubscriber } from '@/store/modules/socket';
import { DataFileSocketMessage, getSocketMessageTaskID, isDataFileEvent } from '@/types/socket';
import { IDialog, IDialogComponent } from '@/hooks/useDialog';
import { t } from '@/i18n/index';
import { SignalType } from '@/hooks/useSignal';
import { Duration } from 'luxon';
import { h } from 'vue';
import ExchangeImportPaymentOrdersResult
  from '@/pages/exchange/import/paymentOrdersResult/ExchangeImportPaymentOrdersResult.vue';
import { FillingCourtReport, PrintReport } from '@/hooks/usePrint';
import { getSocketErrorMessages, hasSocketErrorMessage } from '@/utils/socket';
import { commonLegacyApiRequest, getAuthProtectedFile1 } from '@urrobot/core/service/commonService';
import { unwrapListingApiResponse } from '@/service/api';
import {
  executeCryptoProTaskWithObjectsCleanUp,
  signFileWithCertThumbprint,
} from '@core/plugins/cryptoPro/signFileWithCertObj';
import { getFileExtension } from '@/utils/fileUrl';
import { PLUGIN_EXECUTION_ERROR_MESSAGE } from '@core/plugins/cryptoPro/utils';
import Router from '../../router/index';
import { RouteLocationRaw } from 'vue-router';
import { getProgressBars } from '@/store/modules/standartize/getProgressBars';
import { getTaskProgress } from '@/store/modules/standartize/getTaskProgress';
import { Progress } from '@/store/modules/standartize/types';
import { startImportTask } from '@/store/modules/progress/importTask';
import { startExportTask } from '@/store/modules/progress/exportTask';
import { startPrintTask } from '@/store/modules/progress/printTask';

type UUID = string;

export type ProgressTaskAction = 'startImportTask'|'startExportTask'|'startPrintTask'|'startStandartizeTask'

export type ProgressTask = {
  id: string|number|null;
  key: string|number;
  toastId?: string;
  label: string;
  uuid: UUID|number;
  action: ProgressTaskAction;
  progressbars: IToastProgressbar[];
  encrypt?: boolean;
  companyId: number;
  reports?: { name: string; url: string }[];
  // сертификат пользователя для локальной подписи документов
  cert_thumbprint?: string;
}

export type StandartizeProgressTask = {
  key: string;
  toastId?: string;
  label: string;
  standartizeTaskUUID?: string;
  saveStandartizeTaskUUID?: string;
  cadnumTaskUUID?: string;
  standartizeTaskRemainingTime: number;
  standartizeTaskSpeed: number;
  action: ProgressTaskAction;
  progressbars: IToastProgressbar[];
}

export type ProgressTaskPartial = Partial<ProgressTask>
  & {
    key: string;
    progressbars: Partial<IToastProgressbar & { key: string }>[];
  }

export type TasksProgressState = {
  tasksFetchingPromises: Record<UUID, Promise<void>[]>;
  tasks: Record<UUID, ProgressTask>;
}

export type TasksProgressModule = Module<TasksProgressState, StoreState>;

export type ProgressContext = ActionContext<TasksProgressState, StoreState>;
export type ProgressPayload = { task: ProgressTask; restore: boolean };

export const namespaced = true;

export const state: TasksProgressModule['state'] = () => ({
  tasksFetchingPromises: {},
  tasks: {},
});

export const getters: TasksProgressModule['getters'] = {
  taskProgressbars: (state) => Object.fromEntries(
    Object.entries(state.tasks).map(([id, task]) => ([
      id,
      task.progressbars.filter(({ max }) => !!max),
    ])),
  ),
};

enum DocumentAttachmentsStatus {
  PROCESSING = 0,
  PROCESSED = 1,
  FAILED = 2,
}

type DocumentAttachmentsStatusResponse = {
  id: number;
  status: DocumentAttachmentsStatus;
  file: string;
  file_pdf: string;
  file_json: string;
}

export const UNKNOWN_TOAST_RESULT: IToast = {
  label: 'tasksProgress.toast.unknown',
  level: IToastLevel.warning,
  duration: 4000,
};

export const isToast = (v: any): v is IToast => v.label && v.level;

// пинг раз в 10 секунд, 720 попыток - 2 часа
export const CHECK_TASK_RESULT_RETRIES_COUNT = 720;

export const mutations: TasksProgressModule['mutations'] = {
  setTask(state, task: ProgressTask) {
    state.tasks[task.key] = task;
  },
  setTaskToastId(state, { taskKey, toastId }: { taskKey: string; toastId: string }) {
    state.tasks[taskKey].toastId = toastId;
  },
  setTaskFetchingPromise(state, { taskKey, promise }: { taskKey: string; promise: Promise<void> }) {
    if (!state.tasksFetchingPromises[taskKey]) {
      state.tasksFetchingPromises[taskKey] = [];
    }
    state.tasksFetchingPromises[taskKey].push(promise);
  },
  addTaskReports(
    state,
    { taskKey, reports }: { taskKey: string; reports: { name: string; url: string }[]},
  ) {
    const task = state.tasks[taskKey];
    if (!task) {
      console.error(`task ${taskKey} not found`);
      return;
    }
    if (!task.reports) {
      task.reports = [];
    }
    task.reports.push(...reports);
  },
  clearTasksToastId(state) {
    Object.keys(state.tasks).forEach((key) => {
      delete state.tasks[key].toastId;
    });
  },
  updateTask(state, task: ProgressTaskPartial) {
    const taskToUpdate = state.tasks[task.key];
    if (!taskToUpdate) {
      return;
    }
    (Object.keys(task) as Array<keyof ProgressTask>).forEach((key) => {
      if (key !== 'progressbars') {
        // @ts-ignore
        state.tasks[task.key][key] = task[key];
      }
    });
    if (task.progressbars) {
      const storedTaskProgressBars = state.tasks[task.key]?.progressbars;
      task.progressbars.forEach((pr) => {
        const oldProgress = storedTaskProgressBars.find((p) => p.key === pr.key);
        if (!oldProgress) {
          storedTaskProgressBars.push({ ...pr });
        } else {
          const { current, max, message } = pr;
          if (current !== undefined) {
            oldProgress.current = current;
          }
          if (max !== undefined) {
            oldProgress.max = max;
          }
          if (message !== undefined) {
            oldProgress.message = message;
          }
        }
      });
    }
  },
  removeTask(state, task: ProgressTask) {
    if (state.tasks[task.key]) {
      delete state.tasks[task.key];
    }
    if (state.tasksFetchingPromises[task.key]) {
      delete state.tasksFetchingPromises[task.key];
    }
  },
  clearTasks(state) {
    state.tasks = {};
  },
};

export const actions: TasksProgressModule['actions'] = {
  async clearTasks({
    state, commit, dispatch,
  }) {
    Object.values(state.tasks).forEach((task) => {
      if (task.toastId) {
        dispatch('layout/closeToastById', task.toastId, { root: true });
      }
    });
    commit('clearTasks');
  },
  // start progress checking, actual start is going through request
  async startTask({
    dispatch, rootGetters,
  }, { task, restore }: { task: ProgressTask; restore: boolean }) {

    dispatch('socket/subscribeToCompany', task.companyId, { root: true });
    // const defaultCompanyId = rootGetters['companies/defaultCompanyId'];
    // const needSubscribe = task.companyId !== defaultCompanyId;
    // if (needSubscribe) {
    //   dispatch('socket/subscribeToCompany', task.companyId, { root: true });
    // }
    await dispatch(task.action, { task, restore });
    // if (needSubscribe) {
    //   dispatch('socket/unsubscribeFromCompany', task.companyId, { root: true });
    // }
  },

  startImportTask,
  startExportTask,
  startPrintTask,

  async restoreTasks(
    { commit, state, dispatch },
  ) {
    commit('clearTasksToastId');
    Object.values(state.tasks).forEach((task) => dispatch('startTask', {
      task, restore: true,
    }));
  },

  async startStandartizeTask({
    commit, state, dispatch, rootState, getters,
  }, { task, restore }: { task: StandartizeProgressTask; restore: boolean }) {
    let closeProgressToast: ((() => void) & { id: string })|undefined;

    // console.log('start progress', task, new Error().stack);

    const isFinished = (p: Progress) => p.max_progress && p.max_progress === p.current_progress;

    await new Promise<void>(async (resolve) => {

      let getTaskProgressTimeoutId:number|null = null;

      const tasksProgress = await getTaskProgress(task);

      if (Object.values(tasksProgress).every(isFinished)) {
        commit('layout/signal', { signalType: SignalType.standartizeUpdated }, { root: true });
        resolve();
        return;
      }

      if (!restore) {
        commit('setTask', {
          ...task,
          label: task.label ?? 'Стандартизация...',
          isHideable: true,
          progressbars: getProgressBars(task, tasksProgress),
        });
      }

      closeProgressToast = await dispatch('layout/showToast', {
        label: state.tasks[task.key].label,
        level: IToastLevel.info,
        duration: null,
        progressbars: getters.taskProgressbars[task.key],
        isHideable: true,
        onClose: async () => {
          if (getTaskProgressTimeoutId) {
            clearInterval(getTaskProgressTimeoutId);
            resolve();
          }
        },
        ...(task.toastId ? { id: task.toastId } : {}),
      }, { root: true });

      commit('updateTask', { key: task.key, toastId: closeProgressToast?.id });

      getTaskProgressTimeoutId = setInterval(async () => {

        const responsesMap = await getTaskProgress(task);
        const progressbars = getProgressBars(task, responsesMap);

        commit('updateTask', {
          key: task.key,
          progressbars,
        });

        if (state.tasks[task.key]) {
          commit('layout/updateToastById', {
            id: (state.tasks[task.key] as unknown as StandartizeProgressTask).toastId,
            progressbars: getters.taskProgressbars[task.key],
          }, { root: true });
        }

        if (Object.values(responsesMap).every(isFinished)) {
          clearInterval(getTaskProgressTimeoutId as number);
          commit('layout/signal', { signalType: SignalType.standartizeUpdated }, { root: true });
          resolve();
        }
      }, 5 * 1000);
    });

    if (closeProgressToast) {
      closeProgressToast?.();
    }
    commit('removeTask', { key: task.key });
  },
  async fetchPrintReportUrl({ dispatch }, id: number) {
    const response = await dispatch(
      'api/request',
      { command: ApiCommand.fetchPrintReport, params: { id } }, { root: true },
    ) as ApiResponse<PrintReport>;

    if (!response.status) {
      console.error(`fetch print report error (report_id: ${id}) `);
      dispatch('layout/showToast', {
        label: 'pureLabel',
        message: 'pure',
        duration: 5000,
        params: {
          label: 'Ошибка получения отчета печати',
        },
        level: IToastLevel.danger,
      }, { root: true });
      return null;
    }

    return response.response.report;
  },
  async fetchCourtFillingReportUrl({ dispatch }, id: number) {
    const response = await dispatch(
      'api/request',
      { command: ApiCommand.fetchCourtFillingReport, params: { id } }, { root: true },
    ) as ApiResponse<FillingCourtReport>;

    if (!response.status) {
      console.error(`fetch print report error (report_id: ${id}) `);
      dispatch('layout/showToast', {
        label: 'pureLabel',
        message: 'pure',
        duration: 5000,
        params: {
          label: 'Ошибка получения отчета подачи в суд',
        },
        level: IToastLevel.danger,
      }, { root: true });
      return null;
    }

    return response.response.file;
  },
  async fetchTaskReportUrl(
    { state, dispatch, commit }, { taskKey, description, reportId }:{
      taskKey: string;
      description: 'Отчет по подаче в суд'|'Отчёт по печати документов'|'Отчёт'; reportId: number;
      },
  ) {
    const actionsMap = {
      'Отчет по подаче в суд': 'fetchCourtFillingReportUrl',
      'Отчёт по печати документов': 'fetchPrintReportUrl',
      Отчёт: 'fetchPrintReportUrl',
    };

    const promise = new Promise<null|string>(async (resolve) => {
      const url = await (dispatch(
        actionsMap[description] || 'fetchPrintReportUrl',
        reportId,
      ) as Promise<string|null>);
      if (url) {
        commit('addTaskReports', { taskKey, reports: [{ name: 'Отчет', url }] });
      }
      resolve(url);
    });
    commit('setTaskFetchingPromise', { taskKey, promise });
    return promise;
  },
  fetchTaskResult({ dispatch }, taskId: number) {
    return dispatch('api/request', {
      command: ApiCommand.getDataFilePackage,
      params: { id: taskId },
    }, { root: true }).then((response: ApiResponse<DataFileResponse>) => {
      if (!response.status) {
        return null;
      }
      let status: DataFileStatus;
      if (response.response.status === 2) {
        if (
          response.response.statusrecord_set?.length === 1
          && response.response.statusrecord_set[0].state === DataFileStatusRecordStatus.FAILED
        ) {
          status = DataFileStatus.FAILED;
        }
        status = DataFileStatus.UPLOADED;
      } else {
        status = response.response.status;
      }
      const result = response.response.payload?.result;
      return {
        status, result,
      };
    });
  },
  fetchPrintTaskResult({ dispatch, state }, taskId: number) {
    const task = state.tasks[taskId];
    return dispatch('api/request', {
      command: ApiCommand.fetchDefaultAttachmentStatus,
      params: { id: taskId },
    }, { root: true }).then((response: ApiResponse<DocumentAttachmentsStatusResponse>) => {
      if (!response.status) {
        return null;
      }
      const { status } = response.response;
      if (status === 0) {
        return {
          status: DataFileStatus.UPLOADED,
          result: null,
        };
      } if (status === 1) {
        return {
          status: DataFileStatus.PROCESSED,
          result: {
            url: task.encrypt ? response.response.file : response.response.file_pdf,
            urlJson: response.response.file_json,
          },
        };
      } if (status === 2) {
        return {
          status: DataFileStatus.FAILED,
          result: null,
        };
      }
    });
  },
};

export const plugins: Array<Plugin<StoreState>> = [
  (store) => {
    if (Object.keys(store.state.tasksProgress.tasks).length) {
      store.dispatch('tasksProgress/restoreTasks');
    }
  },
];
