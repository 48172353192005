import { AutoTabKey, PretrialAutomationModel } from '@/components/automation/pretrial/tabs/types';
import { fieldGroup, fieldsUniter } from '@/components/automation/pretrial/tabs/utils';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { Ref } from 'vue';

export const getClaimFields = (model: Ref<PretrialAutomationModel>, rootT: (s: string) => string) => {
  return fieldsUniter(AutoTabKey.claim, [
    {
      key: 'auto_create_email_notifications',
      field: 'auto_create_email_notifications',
      label: '',
      type: ActiveFormFieldType.checkbox,
      state: ['primary', 'horizontal'],
      options: {
        placeholder: null,
        allowEmpty: false,
        state: ['primary', 'horizontal'],
        options: [
          { value: true, label: rootT('other.yes') },
          { value: false, label: rootT('other.no') },
        ],
      },
    },
    {
      key: 'debt_notifications_period_email',
      type: ActiveFormFieldType.schedule,
      state: ['primary'],
      options: {
        isDisabled: !model.value.auto_create_email_notifications,
        state: ['primary'],
      },
    },
  ].map((field) => ({
    ...field,
    ionFieldGroup: fieldGroup(AutoTabKey.claim),
  })));
};
