import { useApi } from '@/hooks/useApi';
import { ApiCommand, ApiResponse } from '@/store/modules/api';

export type ImapCredentials = {
  smtp_host: string;
  smtp_port: string;
  smtp_ssl: boolean;
  imap_host: string;
  imap_port: string;
  imap_ssl: boolean;
  user: string;
  password: string;
  sender_name: string;
  smtp_ssl_type?: string;
  imap_ssl_type?: string;
}

export type TestMessageImapData = ImapCredentials & { email: string };

export const useImapApi = () => {
  const api = useApi();

  const sendingTestImapEmail = async (data: TestMessageImapData) => api<any>({
    command: ApiCommand.sendingTestImapEmail,
    data,
  });

  return {
    sendingTestImapEmail,
  };
};
