import { ApiCommand, ApiResponse } from '@/store/modules/api';
import { useApi } from '@/hooks/useApi';
import { Company } from '@/hooks/useCompanies';

export type FetchAisGorodProviderCodesModel = {
  server?: string;
  database: string;
  query: Company['name_full'];
}

export type AisGorodServerDatabase = {
  name: string;
  server: string;
  database: string;
}

export type FetchAisGorodServersResponse = {
  servers: AisGorodServerDatabase[];
}

export type FetchAisGorodProviderCodesResponse = FetchAisGorodProviderCodesModel & {
  provider_codes: Array<{code: number}>;
}

export type CheckAisGorodSignInTaskModel = {
  server: string;
  database: string;
  user: string;
  password: string;
}

export type CheckAisGorodSignInTaskResponse = {
  request_id: string;
}

export type CheckAisGorodSignInResultModel = CheckAisGorodSignInTaskResponse;

export type CheckAisGorodSignInResultResponse = {
  finished: boolean;
  result: {
    valid: boolean;
  } | null;
}

export const useAisGorodApi = () => {
  const api = useApi();

  const fetchAisGorodServers = async (): Promise<ApiResponse<FetchAisGorodServersResponse>> => api({
    command: ApiCommand.fetchAisGorodServers,
  }).then((response) => {
    if (!response.status) {
      return {
        status: false,
        response: response.response,
      };
    }

    return {
      status: true,
      response: response.response,
    };
  });

  const fetchAisGorodProviders = async (payload: FetchAisGorodProviderCodesModel) => api({
    command: ApiCommand.fetchAisGorodProviderCodes,
    data: payload,
  }).then((response) => {
    if (!response.status) {
      return {
        status: false,
        response: response.response,
      };
    }

    return {
      status: true,
      response: response.response,
    };
  }) as Promise<ApiResponse<FetchAisGorodProviderCodesResponse>>;

  const checkAisGorodSignInTask = async (data: CheckAisGorodSignInTaskModel) => api({
    command: ApiCommand.checkAisGorodSignInTask,
    data,
  }).then((response) => response) as Promise<ApiResponse<CheckAisGorodSignInTaskResponse>>;

  const checkAisGorodSignInResult = async (data: CheckAisGorodSignInResultModel) => api({
    command: ApiCommand.checkAisGorodSignInResult,
    data,
  }).then((response) => response) as Promise<ApiResponse<CheckAisGorodSignInResultResponse>>;

  return {
    fetchAisGorodServers,
    fetchAisGorodProviders,
    checkAisGorodSignInTask,
    checkAisGorodSignInResult,
  };
};
