import { ref, SetupContext, watch } from 'vue';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useI18n } from 'vue-i18n';
import { useCompanySettings } from '@/components/automation/useCompanySettings';
import { getEmptyModel, toModel, toSettings } from '@/components/automation/pretrial/tabs/utils';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { CompanySettings, useCompanies } from '@/hooks/useCompanies';
import { PeriodicTaskSchedule, useSchedule } from '@/hooks/useSchedule';
import { SourceErrors, useErrors } from '@/hooks/useErrors';
import { envIsDev } from '@/utils/env';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { usePreventLeaveTrigger } from '@/hooks/usePreventLeave';
import { IToastLevel, useToast } from '@/hooks/useToast';
import {
  CompanySettingsPretrial,
  PretrialAutomationModel,
} from '@/components/automation/pretrial/tabs/types';
import { useTabs } from '@/components/automation/pretrial/tabs/tabs';

export const useAutomationPretrial = (emit: SetupContext['emit'] | any) => {
  const leaveController = usePreventLeaveTrigger();
  const { t } = useLocalI18n('debtor.automatizing');
  const { t: rootT } = useI18n();
  const isLoading = ref(true);
  const { showToast } = useToast();
  const { errorsMap, setErrors, clearErrors } = useErrors(true);

  const { companySettings } = useCompanySettings();
  const model = ref(getEmptyModel());
  const { companyId } = useProtectedDefaultCompany();
  const {
    updateCompanySettings,
  } = useCompanies();
  const scheduleApi = useSchedule();

  const updateModel = (companySettings: CompanySettingsPretrial) => {
    model.value = toModel(companySettings);
  };

  const reset = () => {
    leaveController.reset();
  };

  const claimTab = ref(1);

  const claimTabs = [
    {
      key: 0,
      label: 'I',
      id: 'modal_automation_pretrial_claim_tab_0',
    },
    {
      key: 1,
      label: 'II',
      id: 'modal_automation_pretrial_claim_tab_1',
    },
    {
      key: 2,
      label: 'III',
      id: 'modal_automation_pretrial_claim_tab_2',
    },
  ];

  watch(() => companySettings.value, async (companySettings) => {
    if (companySettings) {
      isLoading.value = true;
      const settingsCopied = JSON.parse(
        JSON.stringify(companySettings),
      ) as CompanySettingsPretrial;
      const response = await scheduleApi.task.getList();

      if (!response.status) {
        isLoading.value = false;
        return;
      }

      const prepareTaskByName = (
        taskName: string,
        taskField: string,
      ) => {
        const taskPeriod = response.response.find(
          (task) => task.name === taskName,
        );
        if (taskPeriod) {
          // @ts-ignore
          settingsCopied[taskField] = taskPeriod.schedule;
        }
      };

      prepareTaskByName(
        'pretrial.auto_create_sms_notifications',
        'debt_notifications_period_sms',
      );

      prepareTaskByName(
        'pretrial.auto_create_voice_notifications',
        'debt_notifications_period_voice',
      );

      prepareTaskByName(
        'pretrial.auto_create_email_notifications',
        'debt_notifications_period_email',
      );

      updateModel({
        ...settingsCopied,
        court_threshold: settingsCopied.court_threshold ? settingsCopied.court_threshold : 0,
      });

      isLoading.value = false;
    }
  });
  const timeRegExp = new RegExp(/\b[0-2]?\d:[0-5]\d\b/);

  const onSubmit = async () => {
    if (isLoading.value) {
      return;
    }
    isLoading.value = true;
    clearErrors();
    const errors: SourceErrors<keyof PretrialAutomationModel> = [];

    if (errors.length) {
      setErrors(errors);
      isLoading.value = false;
      return;
    }

    const preparedModel = toSettings(model.value);

    const { status, response } = await updateCompanySettings({
      id: companyId.value,
      payload: preparedModel as unknown as Partial<CompanySettings>,
    });

    if (!status) {
      await showToast({
        label: 'Ошибка сохранения, попробуйте еще раз',
        level: IToastLevel.danger,
      });
      isLoading.value = false;
    }

    const tasksResponse = await scheduleApi.task.getList();
    if (!tasksResponse.status) {
      isLoading.value = false;
      return;
    }

    const processTasks = async (
      taskName: string,
      taskFlagField: string,
      taskField: string,
    ) => {
      // @ts-ignore
      const taskSchedule = preparedModel[taskField] as PeriodicTaskSchedule;

      const tasksToDelete = tasksResponse.response.filter(
        (task) => task.name === taskName,
      );
      if (tasksToDelete.length) {
        await Promise.all(
          tasksToDelete.map((task) => scheduleApi.task.delete(task.id)),
        );
      }

      if (taskSchedule) {
        await scheduleApi.task.create({
          name: taskName,
          schedule: taskSchedule as PeriodicTaskSchedule,
          ...(taskName === 'pretrial.auto_create_debt_notifications' ? {
            ...(preparedModel.debt_notification_failed_attempts
              ? { count: preparedModel.debt_notification_failed_attempts }
              : {}
            ),
            interval: {
              hours: 3,
              minutes: 0,
            },
            // сделать лимит только на деве
            ...(envIsDev ? {
              limit: {
                hours: 22,
                minutes: 0,
              },
            } : {}),
          } : {}),
        });
      }
    };

    await processTasks(
      'pretrial.auto_create_sms_notifications',
      'auto_create_sms_notifications',
      'debt_notifications_period_sms',
    );
    await processTasks(
      'pretrial.auto_create_voice_notifications',
      'auto_create_voice_notifications',
      'debt_notifications_period_voice',
    );
    await processTasks(
      'pretrial.auto_create_email_notifications',
      'auto_create_email_notifications',
      'debt_notifications_period_email',
    );

    leaveController.reset();
    emit('close');
    isLoading.value = false;
  };

  const onUpdateModelValue = (
    $event: any, fieldConfig: ActiveFormField<PretrialAutomationModel>,
  ) => {
    if (fieldConfig.onUpdateModelValue) {
      fieldConfig.onUpdateModelValue(model.value, $event);
    }
    // @ts-ignore
    model.value[fieldConfig.key] = $event;
    leaveController.markDirty();
  };

  return {
    ...useTabs(model),
    t,
    ActiveFormFieldType,
    model,
    reset,
    onSubmit,
    onUpdateModelValue,
    claimTabs,
    claimTab,
    leaveController,
    isLoading,
    errorsMap,
  };
};
