import { t } from '@/i18n';
import { dateToApiDate } from '@/utils/date';
import { computed, h, SetupContext } from 'vue';

export enum IDateInputState {
  tiny = 'tiny'
}

export type IDateInputType = 'type' | 'week' | 'month' | 'year';

// TODO переименовать type
export type IDateInput = {
  modelValue: Date | string | undefined | null;
  isDisabled?: boolean;
  placeholder?: string;
  type: IDateInputType;
  state: IDateInputState[]|IDateInputState;
  serializeValue: boolean;
  format: string;
  ionInline?: boolean;
  id?: string;
}

export const DATE_INPUT_SHORTCUTS = [
  {
    text: t('components.datepicker.shortcuts.lastWeek'),
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      return [start, end];
    },
  },
  {
    text: t('components.datepicker.shortcuts.lastMonth'),
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      return [start, end];
    },
  },
  {
    text: t('components.datepicker.shortcuts.last3Months'),
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      return [start, end];
    },
  },
];

export const useDateInput = (props: IDateInput, emit: SetupContext['emit']) => {
  const prefix = computed(
    () => (props.placeholder ? h('div', props.placeholder) : undefined),
  );

  const onChange = (
    date: string|Date|{ $d: Date }|undefined|null,
  ) => {
    if (!date) {
      emit('update:modelValue', null);
    } else {
      // @ts-ignore
      const d = date.$v ? date.$v : date;
      emit('update:modelValue', props.serializeValue ? dateToApiDate(d) : d);
    }
  };

  return {
    onChange, prefix,
  };
};
