import {
  computed, ref,
} from 'vue';
import { useStore } from 'vuex';
import { ImportTypeKey } from '@/pages/exchange/import/importTypes';
import { Company, CompanySettings } from '@/hooks/useCompanies';
import { IToastLevel, IToastProgressbar, useToast } from '@/hooks/useToast';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import { useTaskProgress } from '@/hooks/useTaskProgress';
// @ts-ignore
import JSZip from 'jszip';
import { ProductionType } from '@/hooks/useConstructor';

export enum ExchangeImportPeriod {
  monthly = 'monthly',
  all = 'all'
}

export enum ExchangeImportMode {
  linear = 'linear',
}

export type ExchangeImportFile = {
  key: number;
  file: string | null;
  name: string | null;
  fileRaw?: File;
}

export type ExchangeImportRequest = {
  dataType: ImportTypeKey;
  uuid?: string;
  last?: boolean;
  companyId: Company['id'] | null;
  regionId?: CompanySettings['default_region'] | null;
  year: number;
  mode: ExchangeImportMode;
  period: ExchangeImportPeriod;
  files: Array<ExchangeImportFile>;
  module?: 1|2|3|null;
  module_type?: CompanyModuleType;
  template_id: number;
  template?: 1|2|null;
  // для judgement
  production_type: ProductionType | null;
  isFreshTemplate?: boolean;
  neuroModelTag?: string;
  enableDuplicatesSearch?: boolean;
  ignoreFeeAmount?: boolean;
  ignoreDetails?: boolean;
  ignoreFullName?: boolean;
  ignoreStatuses?: boolean;
  ignorePersonalAccount?: boolean;
  repaid?: boolean;
  searchByFileName?: boolean;
  useFileNameAddress?: boolean;
}

export type ExchangeImportPartialRequest = Omit<ExchangeImportRequest, 'files'> & {
  file: ExchangeImportFile;
  month?: number;
}

export type ExchangeImportApiRequest<IsData extends boolean = boolean> = {
  company: Company['id'];
  last: boolean;
} & (IsData extends true ? {
  module: 1 | 2 | 3;
  module_type: CompanyModuleType;
  mode: ExchangeImportMode;
  region?: CompanySettings['default_region'];
  year: ExchangeImportRequest['year'];
} : {
  task_uuid:
    '0574d4db-7cfa-42f0-be4e-0029302e8bf1'
    | '193ee6a2-f1c7-4437-a804-7f00ee29ae10'
    | 'a2fc3e07-048a-42a9-ab30-55635c5ddc54';
})

export type ExchangeMetrics = {
  uploading: number;
  uploaded: number;
  checking: number;
  checked: number;
  parsing: number;
  parsed: number;
  parsingMessage: string;
  syncing: number;
  synced: number;
  syncingMessage: string;
  caching: number;
  cached: number;
  cachingMessage: string;
}

export const useExchangeImport = () => {

  const store = useStore();
  const {
    showToast,
    showPureDangerToast,
    closeToastById,
  } = useToast();

  const { startTaskProgress } = useTaskProgress();

  const uploadData = async (model: ExchangeImportRequest) => {

    const uploadMetrics = ref<ExchangeMetrics>({
      uploading: 0,
      uploaded: 0,
      checking: 0,
      checked: 0,
      parsing: 0,
      parsed: 0,
      parsingMessage: '',
      syncing: 0,
      synced: 0,
      syncingMessage: '',
      caching: 0,
      cached: 0,
      cachingMessage: '',
    });
    uploadMetrics.value.uploading = model.files.length;

    const progressbars = computed(() => [
      {
        key: 'uploading',
        label: 'exchange.import.form.toast.submit.progresses.uploading',
        current: uploadMetrics.value.uploaded,
        max: uploadMetrics.value.uploading,
      },
      {
        key: 'parsing',
        label: 'exchange.import.form.toast.submit.progresses.parsing',
        current: uploadMetrics.value.parsed,
        max: uploadMetrics.value.parsing,
        message: uploadMetrics.value.parsingMessage,
        showMessageInsteadOfLabel: true,
      },
      {
        key: 'syncing',
        label: 'exchange.import.form.toast.submit.progresses.syncing',
        current: uploadMetrics.value.synced,
        max: uploadMetrics.value.syncing,
        message: uploadMetrics.value.syncingMessage,
        showMessageInsteadOfLabel: true,
      },
      {
        key: 'caching',
        label: 'exchange.import.form.toast.submit.progresses.caching',
        current: uploadMetrics.value.cached,
        max: uploadMetrics.value.caching,
        message: uploadMetrics.value.cachingMessage,
        showMessageInsteadOfLabel: true,
      },
    ].filter((b) => b.max));

    const hideProgressToast = await showToast({
      message: `exchange.import.form.toast.submit.message${model.dataType === 'photos' ? 'Photos' : ''}`,
      progressbars: progressbars.value,
      level: IToastLevel.info,
      duration: null,
    });

    const isCustom = [
      ImportTypeKey.pretrial,
      ImportTypeKey.judicial,
      ImportTypeKey.executive,
    ].includes(model.dataType);

    // eslint-disable-next-line no-constant-condition
    const filesPrepared = await (false && model.files.length > 1
      ? (model.files.filter((file) => file.file) as { name: string; file: string }[])
        .reduce((zip, file) => {
          zip.file(file.name as string, file.file.replace(/^.*?base64,/, '') as string, { base64: true });
          return zip;
        }, new JSZip()).generateAsync({ type: 'base64' }).then((file: any) => ([{
          key: 1,
          name: 'payment_orders.zip',
          file,
        }])) as Promise<[ExchangeImportFile]>
      : Promise.resolve(model.files));

    function close(message: string, resolve?: () => void) {
      if (model.files.length === 1) {
        closeToastById(hideProgressToast.id);
        showPureDangerToast({
          params: {
            label: 'Ошибка загрузки реестра',
            message,
          },
          duration: 10000,
        });
        resolve && resolve();
      }
    }

    if (!isCustom) {
      return new Promise<void>(async (resolve) => {
        let counter = 0;
        let uuid: string|null = null;
        // eslint-disable-next-line no-restricted-syntax
        for (const file of model.files) {
          // @ts-ignore
          // eslint-disable-next-line no-await-in-loop
          const { status, response } = await store.dispatch('dataFile/uploadDataOrDataFile', {
            ...model,
            file,
            last: counter === model.files.length - 1,
            ...(uuid ? { uuid } : {}),
          });
          if (status) {
            uuid = response.uuid;
          }
          if (!status) {
            close(response.detail, resolve);
          }
          if (counter === 0) {
            startTaskProgress({
              key: uuid as string,
              toastId: hideProgressToast.id,
              id: uuid,
              uuid: uuid as string,
              action: 'startImportTask',
              label: model.files.length > 1 ? 'Документы' : file.name as string,
              companyId: model.companyId as number,
              progressbars: ([
                {
                  key: 'uploading',
                  label: 'exchange.import.form.toast.submit.progresses.uploading',
                  current: counter + 1,
                  max: model.files.length,
                },
                !isCustom && {
                  key: 'parsing',
                  label: 'exchange.import.form.toast.submit.progresses.parsing',
                  current: 0,
                  max: 0,
                  message: '',
                  showMessageInsteadOfLabel: true,
                },
                isCustom && {
                  key: 'parsing',
                  label: 'exchange.import.form.toast.submit.progresses.parsing',
                  current: 0,
                  max: 0,
                  message: '',
                  showMessageInsteadOfLabel: true,
                },
                isCustom && {
                  key: 'syncing',
                  label: 'exchange.import.form.toast.submit.progresses.syncing',
                  current: 0,
                  max: 0,
                  message: '',
                  showMessageInsteadOfLabel: true,
                },
                isCustom && {
                  key: 'caching',
                  label: 'exchange.import.form.toast.submit.progresses.caching',
                  current: 0,
                  max: 0,
                  message: '',
                  showMessageInsteadOfLabel: true,
                },
              ]).filter(Boolean) as IToastProgressbar[],
            }).then(() => resolve());
          }
          counter += 1;
          uploadMetrics.value.uploaded += 1;

          if (counter !== 0) {
            store.commit('tasksProgress/updateTask', {
              key: uuid,
              progressbars: [
                progressbars.value[0],
              ],
            });
            store.commit('layout/updateToastById', {
              id: hideProgressToast.id,
              progressbars: store.getters['tasksProgress/taskProgressbars'][uuid as string]
                .map((el: any) => ({ ...el })),
            });
          }
        }
      });
    }

    await Promise.all(filesPrepared.map(async (file, index) => {
      if (model.period === ExchangeImportPeriod.monthly && !file.file) {
        return;
      }
      const { status, response } = await store.dispatch('dataFile/uploadDataOrDataFile', {
        ...model,
        file,
        ...(model.period === ExchangeImportPeriod.monthly ? {
          month: index + 1,
        } : {}),
      });

      if (!status) {
        close(response.detail);
        return;
      }

      const uuid = (response.package || response).uuid as unknown as string;
      await startTaskProgress({
        key: uuid,
        toastId: hideProgressToast.id,
        id: uuid,
        uuid,
        action: 'startImportTask',
        label: file.name as string,
        companyId: model.companyId as number,
        progressbars: ([
          {
            key: 'uploading',
            label: 'exchange.import.form.toast.submit.progresses.uploading',
            current: model.files.length,
            max: model.files.length,
          },
          !isCustom && {
            key: 'parsing',
            label: 'exchange.import.form.toast.submit.progresses.parsing',
            current: 0,
            max: 0,
            message: '',
            showMessageInsteadOfLabel: true,
          },
          isCustom && {
            key: 'parsing',
            label: 'exchange.import.form.toast.submit.progresses.parsing',
            current: 0,
            max: 0,
            message: '',
            showMessageInsteadOfLabel: true,
          },
          isCustom && {
            key: 'syncing',
            label: 'exchange.import.form.toast.submit.progresses.syncing',
            current: 0,
            max: 0,
            message: '',
            showMessageInsteadOfLabel: true,
          },
          isCustom && {
            key: 'caching',
            label: 'exchange.import.form.toast.submit.progresses.caching',
            current: 0,
            max: 0,
            message: '',
            showMessageInsteadOfLabel: true,
          },
        ]).filter(Boolean) as IToastProgressbar[],
      });
    }));
  };

  return {
    uploadData,
  };
};
