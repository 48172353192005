import {
  ReportingDataModelFilterType,
} from '@/service/api/reporting/dataModelConfig';
import { TileDefaultParametersFilters, TileLineFunction } from '@/service/api/reporting/tile';
import { NonNullableKeys } from '@/types/utils';

export const splitFilterString = (str: string) => {
  const result = str.match(/(?<not>-)?(?<name>.+)__(?<operator>.+?)$/i);
  if (!result) return null;
  if (!result.groups) return null;
  const { not, name, operator } = result.groups;
  return { not: !!not, name, operator };
};

export type ReportingChartFormFilterOption = {
  name: string; value: string; type: (ReportingDataModelFilterType)[];
};

export const filters: ReportingChartFormFilterOption[] = [
  {
    name: 'Равно',
    value: 'exact',
    type: ['enum', 'string', 'float', 'integer', 'boolean'],
  },
  {
    name: 'Включает',
    value: 'in',
    type: ['enum'],
  },
  {
    name: 'Включает подстроку',
    value: 'icontains',
    type: ['string'],
  },
  {
    name: 'Больше',
    value: 'gt',
    type: ['float', 'integer'],
  },
  {
    name: 'Больше или равно',
    value: 'gte',
    type: ['float', 'integer'],
  },
  {
    name: 'Меньше',
    value: 'lt',
    type: ['float', 'integer'],
  },
  {
    name: 'Меньше или равно',
    value: 'lte',
    type: ['float', 'integer'],
  },
];

const filtersWithNegatives: ReportingChartFormFilterOption[] = filters.reduce(
  (acc, { name, value, type }) => {
    acc.push({ name, value, type });
    acc.push({
      name: `Не ${name}`,
      value: `!${value}`,
      type,
    });
    return acc;
  }, [] as ReportingChartFormFilterOption[],
);

export const booleanOptions = [{
  name: 'Истина',
  value: true,
}, {
  name: 'Ложь',
  value: false,
}];

// @ts-ignore
export const filterMapByType = filtersWithNegatives.reduce((acc: any, { name, value, type }) => {
  type.forEach((type: string) => {
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push({ name, value, type });
  });
  return acc;
}, {});

export type ReportingChartFormFilterOperator = 'exact'|'in'|'icontains'|'gt'|'gte'|'lt'|'lte'

export type ReportingChartFormFilterValue = string|number|boolean|string[]|null

export type ReportingChartFormFilter = {
  name: string|null;
  operator: ReportingChartFormFilterOperator|null;
  value: ReportingChartFormFilterValue;
}

export type ReportingChartFormFilterFieldSplitOptionEnum = string;
export type ReportingChartFormFilterFieldSplitOptionNumber = {
  field: string; interval: number; max: number;
}

// разбиваем значение по енаму либо по числовому значению
export type ReportingChartFormFilterFieldSplitOption =
  ReportingChartFormFilterFieldSplitOptionEnum
  | ReportingChartFormFilterFieldSplitOptionNumber

export type ReportingChartFormFilterField = {
  field: string;
  key: string;
  name: string;
  show_percent: boolean;
  show_previous: boolean;
  split_by: ReportingChartFormFilterFieldSplitOption;
  function: TileLineFunction;
  filters: ReportingChartFormFilter[];
}

export const mapFormModelFiltersToTile = (
  filters: NonNullableKeys<ReportingChartFormFilter>[],
): TileDefaultParametersFilters => filters.reduce((acc, filter) => {
  const isNegative = filter.operator.startsWith('!');
  acc[`${isNegative ? '-' : ''}${filter.name}__${isNegative ? filter.operator.substr(1) : filter.operator}`] = filter.value;
  return acc;
}, {} as TileDefaultParametersFilters);
