export type ApiRequestGeneric<
  Command,
  Data extends Record<any, any> = Record<any, any>,
  Params extends Record<any, any> = Record<any, any>,
> = {
  command: Command;
  data?: Data | Record<string, any>;
  params?: Params | Record<string, any>;
  headers?: Record<string, any>;
  signal?: AbortSignal;
  raw?: boolean;
}

export enum ApiMethod {
  get = 'get',
  post = 'post',
  patch = 'patch',
  put = 'put',
  delete = 'delete',
  options = 'options',
}

export type ApiCommandConfig = {
  method: ApiMethod;
  url: string;
  dataMap?: Record<string, string>;
  paramsMap?: Record<string, string>;
}

export type ApiServiceConfig<Command> = {
  apiUrl: string;
  getToken: () => Promise<string | null>;
  getApiCommand: (command: Command) => ApiCommandConfig;
  tokenField: string;
  appendParams?: Record<string, string>;
}
