import { computed, Ref } from 'vue';
import { getDeepField } from '@/utils/object';
import { ActiveTableInput, ActiveTableColumn } from '@/components/activeTable/useActiveTable';
import { RecordsData } from '@/components/activeTable/useRecordsData';

export const useSummaries = (
  records: Ref<any[]>,
  summariesFields: Ref<string[]>,
  summaries: Ref<ActiveTableInput<any, any, any>['summaries']>,
  selectedItems: Ref<string[]>,
  allSelected: Ref<boolean>,
  columns: Ref<ActiveTableColumn<any>[]>,
  recordsData: Ref<RecordsData>,
) => {
  const computedSummaries = computed(() => (
    (summariesFields.value)
      .filter((col) => columns.value.find(({ key }) => key === col))
      .reduce((acc, columnKey, i, arr) => ({
        ...acc,
        [columnKey]: allSelected.value ? (
          records.value?.reduce((subAcc, record) => subAcc + getDeepField(record, columnKey) || 0, 0)
        ) : (
          selectedItems.value.length ? (
            selectedItems.value.reduce(
              (
                subAcc, keyField,
              ) => subAcc + (recordsData.value.map[keyField]?.columns[columnKey].value || 0),
              0,
            )
          ) : summaries.value?.[columnKey as keyof typeof summaries.value] || 0
        ),
      }), {})
  ));
  return computedSummaries;
};
