import { SourceErrors } from '@/hooks/useErrors';
import { Debtor, Tenant, useDebtors } from '@/hooks/useDebtors';
import { dateToApiDate } from '@/utils/date';
import { ProductionType } from '@/hooks/useConstructor';

export const useDebtorTenantsSave = () => {
  const { createTenant, updateTenant, removeTenant } = useDebtors();

  const processModel = (model: Tenant) => {
    const { id, sources, ...restModel } = model;
    const newModel = { ...restModel };

    ['birth_date', 'date_of_passport_issue', 'registration_date'].forEach((key) => {
      if (Object.hasOwn(newModel, key)) {
        const field = (newModel as any)[key];
        if (field instanceof Date) {
          (newModel as any)[key] = dateToApiDate(field as Date);
        }
      }
    });

    // для адреса нужна стандартизация
    newModel.registration_address = newModel.registration_address_st;
    newModel.registration_address_st = null;
    newModel.relationships = (newModel.relationships as any || []).filter(Boolean);
    return newModel;
  };

  const save = async (
    model: Tenant,
    productionType: ProductionType,
    debtor: Debtor['pk'],
  ): Promise<{ errors: SourceErrors<any>; response: Tenant }> => (
    model.id > 0
      ? updateTenant({ model: processModel(model) as Tenant, productionType, id: model.id })
      : createTenant({ model: processModel(model) as Tenant, productionType, debtor })
  ).then(({ status, response }) => {
    if (!status) {
      return {
        errors: Object.entries(response)
          .map(([key, errors]) => ([`${model.id}-${key}`, errors as string[]])),
        response,
      };
    }
    return {
      errors: [],
      response,
    };
  });

  const saveAll = async ({
    records, toRemove, productionType, debtor,
  } : {
    records : Array<Tenant>;
    toRemove: Array<Tenant['id']>;
    productionType: ProductionType;
    debtor: Debtor['pk'];
  }) : Promise<SourceErrors<any>> => {

    let errors: SourceErrors<any> = [];
    await Promise.all([
      ...toRemove.map((id) => removeTenant({ id }).then(({ status, response }) => {
        if (!status) {
          errors = errors.concat(Object.entries(response).map(([key, errors]) => ([`${id}-${key}`, errors as string[]])));
        }
      })),
      ...records.map((model: Tenant) => save(model, productionType, debtor).then((res) => {
        errors = errors.concat(res.errors);
      })),
    ]);
    return errors;
  };

  return {
    save,
    saveAll,
    remove: removeTenant,
  };
};
