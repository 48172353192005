import { computed, Ref, ref } from 'vue';
import {
  formValueToSchedule,
  monthDaysMap,
  ScheduleSelectorForm,
  ScheduleSelectorTab,
} from '@/components/scheduleSelector/utils';

export const useMonthsDays = (
  formValue: Ref<ScheduleSelectorForm>,
  activeTab: Ref<ScheduleSelectorTab>,
) => {
  const monthsDaysActiveMonth = ref<number>(1);

  const monthsDaysIncrMonth = () => {
    monthsDaysActiveMonth.value = monthsDaysActiveMonth.value === 12 ? 1 : (monthsDaysActiveMonth.value + 1);
  };

  const monthsDaysDecrMonth = () => {
    monthsDaysActiveMonth.value = monthsDaysActiveMonth.value === 1 ? 12 : (monthsDaysActiveMonth.value - 1);
  };

  // @ts-ignore
  const monthsDaysCurrentMonthOptions = computed(
    // @ts-ignore
    () => new Array(monthDaysMap[monthsDaysActiveMonth.value]).fill(null).map(
      (_, i) => (i + 1).toString(),
    ),
  );

  const monthsDaysCurrentMonthDays = computed<string[]>({
    get: () => {
      if (activeTab.value !== 'monthsDays') {
        return [];
      }
      if (!formValue.value.month_days || !Array.isArray(formValue.value.month_days)) {
        throw new Error('month_days should be array');
      }
      const currentMonthData = formValue.value.month_days.find(([month]) => month === String(monthsDaysActiveMonth.value));
      if (!currentMonthData) {
        return [];
      }
      return currentMonthData[1];
    },
    set: (days: string[]) => {
      if (!formValue.value.month_days || !Array.isArray(formValue.value.month_days)) {
        throw new Error('month_days should be array');
      }
      const currentMonthDataIndex = formValue.value.month_days.findIndex(([month]) => month === String(monthsDaysActiveMonth.value));
      const currentMonthData = formValue.value.month_days[currentMonthDataIndex];
      if (!currentMonthData) {
        formValue.value.month_days.push([String(monthsDaysActiveMonth.value), [...days]]);
      } else {
        if (!days.length) {
          formValue.value.month_days.splice(currentMonthDataIndex, 1);
        } else {
          currentMonthData[1] = [...days];
        }
      }
    },
  });

  return {
    monthsDaysActiveMonth,
    monthsDaysCurrentMonthOptions,
    monthsDaysCurrentMonthDays,
    monthsDaysIncrMonth,
    monthsDaysDecrMonth,
  };
};
