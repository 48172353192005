import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand } from '@/store/modules/api';
import { openAuthProtectedFile } from '@/utils/fileUrl';

type FileKey = 'mos_ru_instruction';

type InstructionResponse = {
  file: string;
  key: FileKey;
  name: string;
}

export const useInstructions = () => {

  const downloadInstruction = () => commonLegacyApiRequest<InstructionResponse>({
    command: ApiCommand.fetchInstruction,
    // params: { serviceName },
  }).then(async (r) => {
    console.log(r);
    if (!r.status) {
      return r;
    }
    if (!r.response) {
      return r;
    }
    await openAuthProtectedFile(r.response.file, true);
    return { status: true, response: null };
  });

  return {
    downloadInstruction,
  };
};
