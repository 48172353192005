import { ActiveFormFieldType, ActiveFormIonListGroup } from '@/hooks/useActiveForm';
import { useLocalI18n } from '@/hooks/useLocalI18n';

export function useFieldFunction(component: string) {

  const { t } = useLocalI18n(`exchange.integration.${component}`);

  return { field };

  function field(
    key: string,
    type: ActiveFormFieldType = ActiveFormFieldType.input,
    { group, options, state }: FieldOptions = {},
  ) {
    return {
      key,
      field: key,
      label: !group ? t(`form.${key}`) : '',
      type,
      state: state ?? ['primary', 'horizontal'],
      ionFieldGroup: group,
      options: options ?? {},
    };
  }

}

export type FieldOptions = {
  group?: ActiveFormIonListGroup;
  options?: any;
  state?: string[];
}
