import { arrayFrom } from '@/utils/object';
import { ApiCommand, apiCommands, ListingResponse } from '@/store/modules/api';
import { apiCommands as reportingApiCommands } from '@/service/api/reporting/api';
import { ApiCommand as ReportingApiCommand } from '@/service/api/reporting/apiCommand';
import {
  ApiCommandConfig,
  ApiMethod,
  ApiRequestGeneric,
  ApiServiceConfig,
} from '@/service/api-types';

export type ApiResponseSuccess<T extends any = any> = {
  status: true;
  response: T;
  statusCode?: number;
}

export type ApiResponseError = {
  status: false;
  response: any;
  statusCode?: number;
}

export type ApiResponse2<T> = {
  response?: T;
  error?: any;
}

export type ApiResponse<T> = ApiResponseSuccess<T> | ApiResponseError

const isApiCommand = (command: ApiCommand | ReportingApiCommand): command is ApiCommand => {
  // @ts-ignore
  if (ApiCommand[command] === undefined) {
    return false;
  }
  return true;
};

export const getApiCommand = (command: ApiCommand | ReportingApiCommand) => {
  if (isApiCommand(command)) {
    return apiCommands[command];
  }
  return reportingApiCommands[command];
};

export const unwrapListingResponse = <T>(r: ListingResponse<T>) => r.results;

export const unwrapListingApiResponse = <T>(
  response: ApiResponse<ListingResponse<T>>,
) => (response.status ? ({
    status: true,
    response: response.response.results,
  } as ApiResponseSuccess<T[]>) : ({
    status: false,
    response: response.response as any,
  } as ApiResponseError));

export const unwrapFirstRecord = <T>(
  response: ApiResponse<ListingResponse<T>>,
) => (response.status ? ({
    status: true,
    response: response.response.results?.[0],
  } as ApiResponseSuccess<T|undefined>) : ({
    status: false,
    response: response.response as any,
  } as ApiResponseError));

const normalizeSearchParamValue = (value: any) => {
  if (value === true) return 'true';
  if (value === false) return 'false';
  return value;
};

export const createApiRequest = <Command>(config: ApiServiceConfig<Command>) => {

  return async <Response extends any = any>(
    request: ApiRequestGeneric<Command>,

  ): Promise<ApiResponse<Response>> => {

    const command = config.getApiCommand(request.command) as ApiCommandConfig;
    const url = new URL(config.apiUrl);
    url.pathname = url.pathname.substr(1)
        + Object.entries(request.params || {}).reduce((acc, [field, value]) => (
          acc.replace(
            new RegExp(`{${field}}`, 'g'),
            encodeURIComponent(
              typeof value === 'string'
                ? value.replaceAll('/', '|')
                : value,
            ),
          )
        ), command.url);

    url.search = Object.entries(command.paramsMap || {})
      .map(([apiField, field]) => ([
        apiField.replace(/_+$/, ''), field,
      ]))
      .filter(
        ([, field]) => request.params?.[field] !== null && request.params?.[field] !== undefined,
      )
      .reduce((searchParams, [apiField, field]) => {
        const normValue = normalizeSearchParamValue(request.params?.[field]);
        if (Array.isArray(normValue)) {
          normValue.forEach((v) => {
            searchParams.append(apiField, v);
          });
        } else {
          searchParams.append(apiField, normValue);
        }
        return searchParams;
      }, new URLSearchParams())
      .toString();

    const data = request.data ? (
      'dataMap' in command ? (
        Object.entries(command.dataMap || {})
          .filter(([, field]) => request.data?.[field] ?? false)
          .reduce((acc, [apiField, field]) => ({
            ...acc,
            [apiField]: request.data?.[field],
          }), {})
      ) : request.data
    ) : undefined;

    let body: string | FormData | undefined;
    if (command.method !== ApiMethod.get) {
      if ((request.headers?.['Content-Type'] === 'multipart/form-data') && data) {
        body = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((val) => {
              if (val === null || val === undefined) {
                (body as FormData).append(key, '');
              } else {
                (body as FormData).append(key, val);
              }
            });
          } else {
            if (value === null || value === undefined) {
              (body as FormData).append(key, '');
            } else {
              (body as FormData).append(key, value);
            }
          }
        });
      } else {
        body = JSON.stringify(data);
      }
    }

    const headers = {
      ...request.headers || {},
    };
    if (headers?.['Content-Type'] === 'multipart/form-data') {
      delete headers['Content-Type'];
    } else {
      headers['Content-Type'] = headers['Content-Type'] || 'application/json';
    }

    const token = await config.getToken();
    const req = await fetch(url.toString(), {
      method: command.method.toUpperCase(),
      signal: request.signal,
      headers: {
        ...headers,
        ...([null, undefined, 'undefined'].includes(token) ? {

        } : {
          [config.tokenField]: token,
        }),
      },
      body,
    });

    const status = arrayFrom(({
      [ApiMethod.get]: 200,
      [ApiMethod.post]: [200, 201],
      [ApiMethod.patch]: 200,
      [ApiMethod.put]: 200,
      [ApiMethod.delete]: [204, 200],
      [ApiMethod.options]: 200,
    } as {[key in ApiMethod]: number | number[]})[command.method]).includes(req.status);

    try {
      const response = await req.json();
      return {
        status,
        response,
        statusCode: req.status,
      };
    } catch (e) {
      return {
        status,
        response: null as any,
        statusCode: req.status,
      };
    }
  };
};
