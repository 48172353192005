import { PeriodicTaskSchedule } from '@/hooks/useSchedule';
import {
  ActiveFormFieldScheduleOptions, isMonthsDaysSchedule,
  modelValueToTab,
  weekDayOptions,
} from '@/components/scheduleSelector/utils';
import { computed, Ref } from 'vue';
import { MONTH_TRANSLATIONS_FULL } from '@/utils/date';
import { NonEmptyArray } from 'fp-ts/es6/NonEmptyArray';
import { arrayFrom } from '@/utils/object';

export type IScheduleSelector = {
  modelValue: PeriodicTaskSchedule|NonEmptyArray<PeriodicTaskSchedule>|null;
  availableTabs: ActiveFormFieldScheduleOptions['availableTabs'];
  placeholder?: string;
  isDisabled?: boolean;
}

export const useScheduleInputTriggerText = (
  modelValue: Ref<IScheduleSelector['modelValue']>,
  hideTime?: boolean,
  justTime?: boolean,
) => {
  const triggerText = computed(() => {
    const v = modelValue.value;
    if (!v) return '';
    const tab = modelValueToTab(v);
    let dayText = '';
    let minute = '';
    let hour = '';
    if (Array.isArray(v) || isMonthsDaysSchedule(v as PeriodicTaskSchedule)) {
      const arr = arrayFrom(modelValue.value) as PeriodicTaskSchedule[];
      const firstEl = arr[0];
      hour = firstEl.hour;
      minute = firstEl.minute.length === 1 ? `0${firstEl.minute}` : firstEl.minute;
      dayText = [...arr]
        .sort((a, b) => (+a.month_of_year > +b.day_of_month ? 1 : -1))
        .map(
          ({ month_of_year, day_of_month }) => `${MONTH_TRANSLATIONS_FULL[month_of_year]}: ${day_of_month.split(',')
            .sort((a, b) => (+a > +b ? 1 : -1)).join(', ')}`,
        ).join('; ');
    } else {
      if (tab === 'everyday') {
        dayText = 'Каждый день';
      } else if (tab === 'daysOfWeek') {
        const days = v.day_of_week.split(',')
          .sort((a, b) => (+a > +b ? 1 : -1))
          .map((dayNumber) => weekDayOptions.find(
            ({ key }) => dayNumber === key,
          )?.label?.replace('.', '') as string);
        dayText = days.join(', ');
      } else if (tab === 'daysOfMonth') {
        const days = v.day_of_month.split(',')
          .sort((a, b) => (+a > +b ? 1 : -1));
        dayText = `дни: ${days.join(', ')} кажд. мес.`;
      } else if (tab === 'monthsOfYear') {
        const months = v.month_of_year.split(',')
          .sort((a, b) => (+a > +b ? 1 : -1));
        dayText = `1-го числа, ${months.length === 12 ? 'Каждый месяц' : months.map(
          (monthNumber) => MONTH_TRANSLATIONS_FULL[monthNumber].replace('.', ''),
        ).join(', ')}`;
      }
      minute = v.minute.length === 1 ? `0${v.minute}` : v.minute;
      hour = v.hour;
    }

    if (hideTime) {
      return `${dayText}`;
    }

    if (justTime) {
      return `${hour}:${minute}`;
    }

    return `В ${hour}:${minute} ${dayText}`;
  });

  return triggerText;
};
