import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useDefaultCompanyPermissions } from '@/hooks/useDefaultCompanyPermissions';
import { commonLegacyApiRequest as request } from '@core/service/commonService';
import { ApiCommand, ListingResponse } from '@/store/modules/api';
import {
  Borrower,
  emptyBorrower,
} from '@/components/dialog/dialogs/debtor/tabs/common/tabs/borrower/types';
import {
  ref, Ref, onMounted, computed,
} from 'vue';

export const useDebtorBorrowerTab = () => {
  const {
    debtor, productionType, isOrganization,
  } = useInjectDebtorDialog();

  onMounted(async () => {
    isLoading.value = true;
    const { status, response } = await fetchBorrowers();
    console.log('response', response);
    if (status && response.results) {
      model.value = response.results[0];
    }
    isLoading.value = false;
  });

  const { hasWritePermission } = useDefaultCompanyPermissions(productionType);
  const isLoading = ref<boolean>(false);
  const model = ref<Borrower>(emptyBorrower);
  const errorsMap: Ref<Record<string, any[]>> = ref({});

  const fetchBorrowers = () => request<ListingResponse<Borrower>>({
    command: ApiCommand.fetchDebtorBorrowers,
    params: {
      debtor: debtor.value.debtor.pk,
    },
  });

  const upsert = async () => {
    if (model.value.id) {
      return request<Borrower>({
        command: ApiCommand.updateDebtorBorrower,
        params: { id: model.value.id },
        data: model.value,
      });
    }
    return request<Borrower>({
      command: ApiCommand.createDebtorBorrower,
      data: {
        debtor: debtor.value.debtor.pk,
        ...model.value,
      },
    });
  };

  const save = async () => {
    isLoading.value = true;
    const { status, response } = await upsert();
    if (status) {
      errorsMap.value = {};
      isEditing.value = Object.keys(errorsMap.value).length > 0;
      model.value.id = response.id;
    } else {
      errorsMap.value = response;
    }
    isLoading.value = false;
  };

  const isEditing = ref(false);

  return {
    debtor,
    model,
    save,
    errorsMap,
    isLoading,
    isEditing,
    isOrganization,
    hasWritePermission,
  };
};
