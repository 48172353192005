import {
  ChartLineSeriesVariant,
  ChartLineVariant,
  getBarChart,
  getLineChartBasic,
  getLineChartLineSmoothFillAreaGradient,
  getLineChartSmooth,
  getLineChartSmoothFillArea,
  LineChartBasicOptions,
} from '@/components/plate/chartConfigs/lineChart/line-chart';
import {
  ChartPolarSeriesVariant,
  getPolarChartSeriesFillArea,
  getPolarChartSeriesLine,
  getPolartChartSeriesBar,
  PolartChartBasicOptions,
} from '@/components/plate/chartConfigs/lineChart/polar-chart';
import cloneDeep from 'lodash/cloneDeep';

export const getLineChartSeriesConstructor = (sVariant: ChartLineSeriesVariant) => {
  switch (sVariant) {
    case ChartLineSeriesVariant.Bar:
      return getBarChart;
    case ChartLineSeriesVariant.Smooth:
      return getLineChartSmooth;
    case ChartLineSeriesVariant.Basic:
      return getLineChartBasic;
    case ChartLineSeriesVariant.SmoothFillArea:
      return getLineChartSmoothFillArea;
    case ChartLineSeriesVariant.SmoothFillAreaGradient:
      return getLineChartLineSmoothFillAreaGradient;
    default:
      return getLineChartBasic;
  }
};

export const getLinearChartDefaultConfig = () => JSON.parse(
  JSON.stringify(LineChartBasicOptions),
);

export const getLinearChartByVariant = (variant: ChartLineVariant) => {
  switch (variant) {
    case ChartLineVariant.Linear:
      return cloneDeep(LineChartBasicOptions);
    case ChartLineVariant.Polar:
      return cloneDeep(PolartChartBasicOptions);
    default:
      return cloneDeep(LineChartBasicOptions);
  }
};

export const getPolarChartSeriesConstructor = (sVariant: ChartPolarSeriesVariant) => {
  switch (sVariant) {
    case ChartPolarSeriesVariant.Line:
      return getPolarChartSeriesLine;
    case ChartPolarSeriesVariant.Bar:
      return getPolartChartSeriesBar;
    case ChartPolarSeriesVariant.FillArea:
      return getPolarChartSeriesFillArea;
    default:
      return getPolarChartSeriesLine;
  }
};
