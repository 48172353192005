import {
  computed, ref, Ref, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useCompanyServices } from '@/hooks/useCompanyServices';
import { CompanyService } from '@/hooks/useCompanies';
import { capitalizeFirstLetter } from '@/utils/string';
import { ApiResponse } from '@/service/api';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';

export const useCompanyServicesForm = (
  companyId: Ref<number>, companyModuleType: Ref<CompanyModuleType>,
) => {
  const servicesSourceList = ref<CompanyService[]>([]);
  // у нас есть значения по умолчанию, которые невозможно убрать
  const servicesDefaultValues = computed(
    () => servicesSourceList.value.filter((s) => s.is_default),
  );
  const servicesModelList = ref<string[]>([]);
  const companyServices = useCompanyServices();
  const store = useStore();

  const fetchCompanyServices = () => companyServices.fetchList({
    company_id: companyId.value,
  }).then((response) => {
    if (response.status) {
      servicesSourceList.value = response.response;
      servicesModelList.value = response.response.map(({ name }) => name);
    }
  });

  watch(companyId, (id) => {
    if (companyModuleType.value !== CompanyModuleType.Vehicles) {
      fetchCompanyServices();
    }
  }, { immediate: true });

  const companyServicesSelected = computed({
    get: () => companyServices.dict.value.filter(
      (s) => servicesModelList.value.includes(s.label),
    ).map((v) => v.label),
    set: (values: string[]) => {
      if (!values) return;
      const valuesPrepared = values.map(capitalizeFirstLetter);
      servicesDefaultValues.value.forEach(
        (defaultService) => {
          if (!valuesPrepared.includes(defaultService.name)) {
            valuesPrepared.push(defaultService.name);
          }
        },
      );
      const newValues = valuesPrepared.filter(
        (name) => !companyServices.dict.value.find(
          ({ label }) => label === name,
        ),
      );
      servicesModelList.value = valuesPrepared;
      companyServices.dict.value.push(
        ...newValues.map((name) => ({ label: name, value: name })),
      );
    },
  });

  const updateCompanyServices = async () => {
    const toDelete = servicesSourceList.value.filter(
      (s) => !s.is_default && !servicesModelList.value.includes(s.name),
    );
    const toCreate = servicesModelList.value.filter(
      (name) => !servicesSourceList.value.find((s) => s.name === name),
    );
    const responses = await Promise.all([
      ...toDelete.map((s) => companyServices.deleteEntry({
        company_id: companyId.value,
        id: s.id,
      }) as Promise<ApiResponse<any>>),
      ...toCreate.map(
        (name) => companyServices.createEntry({
          company_id: companyId.value,
          name,
        }) as Promise<ApiResponse<any>>,
      ),
    ]);
    fetchCompanyServices();
    store.dispatch('dicts/fetchCompanyServices');
  };

  return {
    companyServicesSelected,
    companyServicesOptions: companyServices.dict,
    updateCompanyServices,
  };
};
