// @ts-nocheck third-party-js
/* eslint-disable */

export type CryptoProCheckResult = {
  enabled: boolean,
  loaded: boolean,
  currentPluginVersion: string | null,
  file: {
    url: string;
    name: string;
    displayName; string;
  }
}

const fileData = {
  url: 'https://cryptopro.ru/products/cades/plugin/get_2_0',
  name: 'плагин-КриптоПроЭЦП',
  displayName: 'плагин "КриптоПро ЭЦП Browser plug-in"',
}

const disabled = {
  enabled: false,
  loaded: false,
  currentPluginVersion: null,
  file: fileData,
}

export const cryptoProCheck = async () => {
  try {
    await cadesplugin
  } catch (e) {
    console.error(`cadesplugin: ${String(e)}`)
    return disabled;
  }
  return new Promise<CryptoProCheckResult>( resolve => {
    const result = disabled;

    window.cadesplugin.async_spawn(function* (args) {
      try {
         const oAbout = yield window.cadesplugin.CreateObjectAsync('CAdESCOM.About');

        oAbout.PluginVersion.then(async version => {
          console.log('oAbout', oAbout)
          result.currentPluginVersion = version;
          if (typeof result.currentPluginVersion !== 'string') {
            result.currentPluginVersion = await oAbout.Version;
          }
          result.enabled = true;
          result.loaded = true;
          resolve(result)
        })

      } catch (e) {
        console.log('crypto pro error', e);
        console.error(e);
        resolve(result)
      }
    })
  })
}
