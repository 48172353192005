import { TileTimeInterval } from '@/service/api/reporting/tile';

export const chartIntervals: {
  label: string;
  key: string;
  formatter: string;
  value: { start?: TileTimeInterval; interval?: TileTimeInterval };
}[] = [
  {
    label: 'Месяц - по дням',
    key: 'month--by-days',
    formatter: 'dd.LL.yyyy',
    value: {
      start: {
        months: 1,
      },
      interval: {
        days: 1,
      },
    },
  },
  {
    label: '30 дней - по дням и часам',
    key: '30-days--by-days-and-hours',
    formatter: 'dd.LL.yyyy HH:mm',
    value: {
      start: {
        days: 30,
      },
      interval: {
        days: 1,
      },
    },
  },
  {
    label: '30 дней - по дням',
    key: '30-days--by-days',
    formatter: 'dd.LL.yyyy',
    value: {
      start: {
        days: 30,
      },
      interval: {
        days: 1,
      },
    },
  },
  {
    label: '3 месяца - по неделям',
    key: '3-months--by-weeks',
    formatter: 'dd.LL.yyyy',
    value: {
      start: {
        months: 3,
      },
      interval: {
        weeks: 1,
      },
    },
  },
  {
    label: 'Год - по кварталам',
    key: 'year--by-quarters',
    formatter: 'qq',
    value: {
      start: {
        years: 1,
      },
      interval: {
        months: 4,
      },
    },
  },
  {
    label: 'Год - по месяцам',
    key: 'year--by-months',
    formatter: 'LLL',
    value: {
      start: {
        years: 1,
      },
      interval: {
        months: 1,
      },
    },
  },
  {
    label: '5 лет - по годам',
    key: '5-years--by-year',
    formatter: 'yyyy',
    value: {
      start: {
        years: 5,
      },
      interval: {
        years: 1,
      },
    },
  },
];
