import { IToastLevel, useToast } from '@/hooks/useToast';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand, ListingResponse, OrderDirection } from '@/store/modules/api';
import { Debtor } from '@/hooks/useDebtors';
import { formatListingRequest } from '@/components/activeTable/useActiveTable';
import {
  ApiResponse, ApiResponseError, ApiResponseSuccess, unwrapListingApiResponse,
} from '@/service/api';
import { SignalType, useSignal } from '@/hooks/useSignal';

const fetchDebtors = (
  filtersModel: Record<string, any>,
  page: number,
  limit: number,
) => commonLegacyApiRequest<ListingResponse<Debtor>>({
  command: ApiCommand.fetchDebtors,
  params: formatListingRequest({
    page,
    limit,
    filters: filtersModel,
    ordering: [{
      key: 'full_name',
      direction: OrderDirection.asc,
    }],
  }),
}).then(unwrapListingApiResponse);

export const useFetchDebtorsForAction = () => {
  const { showToast, updateToastById } = useToast();
  const { awaitSignalResponse } = useSignal();

  return async (
    limit: number,
    toastLabel: string,
  ): Promise<ApiResponse<Debtor[]>> => {
    const closeToast = await showToast({
      level: IToastLevel.info,
      label: toastLabel,
      message: 'Загрузка данных...',
      duration: null,
    });
    const filtersModel = await awaitSignalResponse<Record<string, any>>(
      SignalType.getDebtorFilters,
      SignalType.debtorFilters,
    );

    if (limit <= 3000) {
      const response = await fetchDebtors(
        filtersModel,
        1,
        limit,
      );
      closeToast();
      if (!response.status) {
        showToast({
          level: IToastLevel.info,
          label: toastLabel,
          message: 'Ошибка загрузки данных',
          duration: null,
        });
      }
      return response;
    }
    const PAGE_LIMIT = 1000;
    const results = [] as Debtor[];
    const finalPage = Math.ceil(limit / PAGE_LIMIT);
    let anyError = false;
    for (let page = 1; page <= finalPage; page++) {
      if (anyError) {
        break;
      }
      // eslint-disable-next-line no-await-in-loop
      const result = await fetchDebtors(
        filtersModel,
        page,
        PAGE_LIMIT,
      );
      updateToastById({
        id: closeToast.id,
        message: `Загрузка данных (${page} из ${finalPage})...`,
      });
      if (!result.status) {
        anyError = true;
        showToast({
          level: IToastLevel.info,
          label: toastLabel,
          message: 'Ошибка загрузки данных',
          duration: null,
        });
      } else {
        results.push(...result.response);
      }
    }
    closeToast();
    if (!anyError) {
      return {
        status: true,
        response: results,
      } as ApiResponseSuccess<Debtor[]>;
    }
    return {
      status: false,
      response: null,
    } as ApiResponseError;
  };
};
