import {
  computed,
  ComputedRef, Ref, ref, watchEffect,
} from 'vue';
import { DebtorCustomDocumentType, useDocumentsApi } from '@/hooks/useDocumentsApi';
import { useDialog } from '@/hooks/useDialog';
import { useToast } from '@/hooks/useToast';
import { useModelDialog } from '@/hooks/useModelDialog';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { ProductionType } from '@/hooks/useConstructor';
import { useCompanyAttachments } from '@/hooks/useCompanyAttachments';
import { usePrint } from '@/hooks/usePrint';
import { DocumentTab } from '@/components/dialog/dialogs/debtor/tabs/documents/types';

export const useDebtorCustomDocuments = (productionType: Ref<ProductionType>, companyId: ComputedRef<number | undefined>, totalDocsCount?: ComputedRef<number>) => {
  const isLoading = ref<boolean>(false);
  const documentTypes = ref<DebtorCustomDocumentType[]>([]);
  const customTabs = ref<Array<DocumentTab>>([]);
  const customTabKeysReqs = ref<Record<any, any>>({});
  const { confirmDialog } = useDialog();
  const { showPureSuccessToast, showPureDangerToast } = useToast();
  const { documents } = useCompanyAttachments(companyId as Ref<number>, productionType, computed(() => 'print'), ref(''), ref(false));
  const {
    createBulkAttachments,
  } = usePrint();

  const {
    fetchDebtorCustomDocumentTypes,
    createDebtorCustomDocumentType,
    updateDebtorCustomDocumentType,
    deleteDebtorCustomDocumentType,
    fetchDebtorCustomDocuments,
  } = useDocumentsApi();

  const fetchCustomDocTypes = async () => {
    isLoading.value = true;
    const { status, response } = await fetchDebtorCustomDocumentTypes({
      filters: {
        company: companyId.value as number,
        production_type: productionType.value,
      },
      page: 1,
      limit: 100,
    });
    if (status && response.results) {
      documentTypes.value = response.results;
    }
    isLoading.value = false;
  };

  watchEffect(() => {
    if (!documentTypes.value.length) {
      return;
    }
    customTabs.value = documentTypes.value
      .filter(({ is_show }) => Boolean(is_show))
      .map(({ id, name, order_number }, index) => ({
        label: name,
        key: `${id}`,
        id: `modal_debtor_documents_tab_custom_tab_${index}`,
        order_number,
      })) || [];
    customTabKeysReqs.value = documentTypes.value
      .filter(({ is_show }) => Boolean(is_show))
      .reduce((acc: Record<number, any>, { id }: DebtorCustomDocumentType) => {
        acc[id] = (params: any) => fetchDebtorCustomDocuments({ ...params, filters: { ...params.filters, type: id } });
        return acc;
      }, {}) || {};
  });

  const { showDialog: showCreateUpdateCustomDocTypeDialog } = useModelDialog<
    { name: string },
    DebtorCustomDocumentType,
    DebtorCustomDocumentType>({
      title: 'Пользовательский тип документа',
      key: 'custom_doc_types',
      fields: [
        {
          key: 'name',
          field: 'name',
          label: 'Название',
          type: ActiveFormFieldType.input,
        },
      ],
      getForm: (document) => Promise.resolve({
        status: true,
        response: {
          name: document.name || '',
        },
      }),
      onModelUpdate: async (payload, document) => {
        if (!payload.name) {
          return {
            status: false,
            response: {
              name: ['Введите имя'],
            },
          };
        }

        const { response, status } = document.id ? await updateDebtorCustomDocumentType({
          ...document,
          name: payload.name,
        }) : await createDebtorCustomDocumentType({
          name: payload.name,
          order_number: totalDocsCount!.value + 1,
          is_show: true,
          company: companyId.value as number,
          production_type: productionType.value,
        });

        if (status && !document.id) {
          await createBulkAttachments({
            company_id: companyId.value as number,
            production_type: productionType.value,
            for_automatic: false,
            for_registered_letters: false,
            attachments: [{
              production_type: productionType.value,
              type: 'debtor_custom',
              name: payload.name,
              group_name: 'print',
              is_show: true,
              is_active: true,
              company: companyId.value as number,
              document: null,
              order_number: documents.value.length + 1,
              rename_attachment_name: true,
              for_automatic: false,
              for_registered_letters: false,
              subgroup_name: null,
            }],
          });
        }

        await fetchCustomDocTypes();

        return { status, response };
      },
    });

  const showDeleteCustomTypeDialog = async (docType: DebtorCustomDocumentType) => {
    const {
      result,
      closeDialog,
    } = await confirmDialog({
      title: 'Вы действительно хотите удалить этот документ?',
      message: docType.name,
    });
    closeDialog();
    if (!result) {
      return;
    }

    const { status } = await deleteDebtorCustomDocumentType(docType.id);
    if (!status) {
      return showPureDangerToast({ params: { message: 'Ошибка при удалении типа документа' } });
    }
    await showPureSuccessToast({
      duration: 1000,
      params: { message: 'Пользовательский тип документа удален' },
    });
    const index = documentTypes.value.findIndex(({ id }) => id === docType.id);
    documentTypes.value.splice(index, 1);
  };

  return {
    isLoading,
    documentTypes,
    fetchCustomDocTypes,
    showCreateUpdateCustomDocTypeDialog,
    updateDebtorCustomDocumentType,
    showDeleteCustomTypeDialog,
    customTabs,
    customTabKeysReqs,
  };
};
