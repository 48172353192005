import {
  computed, ref, Ref, SetupContext, watch,
} from 'vue';
import { getDeepField } from '@/utils/object';
import { RecordsData } from '@/components/activeTable/useRecordsData';

export const useSelection = (
  withUniqueSelection: Ref<boolean>,
  keyField: Ref<string>,
  records: Ref<any[]>,
  initiallySelectedItems: Ref<string[]>,
  page: Ref<number | undefined>,
  limit: Ref<number | undefined>,
  tableKey: Ref<string | undefined>,
  recordsData: Ref<RecordsData>,
  emit: SetupContext['emit'] | any,
) => {
  const allSelected = ref(false);
  const allOnAllPagesSelected = ref(false);
  const selectedItemIdList = ref<Array<any>>(initiallySelectedItems.value || []);
  watch(computed(() => initiallySelectedItems.value), (items) => {
    selectedItemIdList.value = items || [];
  });
  const selectItem = (item: any) => {
    const index = selectedItemIdList.value.indexOf(item);
    if (withUniqueSelection.value) {
      selectedItemIdList.value = [item];
      return;
    }
    if (index > -1) {
      selectedItemIdList.value.splice(index, 1);
    } else if (allSelected.value || allOnAllPagesSelected.value) {
      selectedItemIdList.value = (records.value
        ?.map((record) => getDeepField(record, keyField.value)) || [])
        .filter((i) => i !== item);
      console.log(selectedItemIdList.value);
      allSelected.value = false;
    } else if (selectedItemIdList.value.length === ((records.value?.length || 0) - 1)) {
      selectedItemIdList.value = [];
      allSelected.value = true;
    } else {
      selectedItemIdList.value.push(item);
    }
    allOnAllPagesSelected.value = false;
  };

  watch(allSelected, (value) => {
    allOnAllPagesSelected.value = false;
    if (!value) emit('select', []);
    else {
      emit('select', allSelected.value
        ? records.value?.map((record) => getDeepField(record, keyField.value)) || []
        : [...selectedItemIdList.value]);
    }
  });

  const resetSelection = () => {
    selectedItemIdList.value = [];
    allSelected.value = false;
    allOnAllPagesSelected.value = false;
    emit('select', []);
  };

  const hasSelection = computed(
    () => allOnAllPagesSelected.value || allSelected.value || selectedItemIdList.value.length,
  );

  const selectedItems = computed(
    () => (selectedItemIdList.value[0] ? selectedItemIdList.value.map(
      (id) => recordsData.value.map[id].record,
    ) : selectedItemIdList.value),
  );

  return {
    allSelected,
    allOnAllPagesSelected,
    selectedItemIdList,
    selectedItems,
    resetSelection,
    selectItem,
    hasSelection,
  };
};
