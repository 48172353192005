import { onMounted, ref, Ref } from 'vue';
import { useToast } from '@/hooks/useToast';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useCompanySettings } from '@/components/automation/useCompanySettings';
import { CompanySettings, useCompanies } from '@/hooks/useCompanies';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';

type Payload = {
  settingKey: keyof CompanySettings;
  model: Ref<Partial<CompanySettings>>;
}

export function useNonSecureSettingsCommon({
  settingKey: key,
  model,
}: Payload) {
  type SettingType = CompanySettings[typeof key]
  const {
    fetchCompanySettings, updateCompanySettings: _updateCompanySettings,
  } = useCompanies();

  const { t } = useLocalI18n('exchange.integration.actions');
  const { showDangerToast, showSuccessToast } = useToast();
  const { dispatchSignal } = useSignal();
  const {
    companyId: defaultCompanyId,
  } = useDefaultCompany();

  const { updateCompanySettings } = useCompanySettings();

  const isLoading = ref(true);
  const setting = ref<SettingType | null>(null);

  onMounted(async () => {
    await fetchSetting();
  });

  async function fetchSetting() {
    isLoading.value = true;
    const { response } = await fetchCompanySettings(defaultCompanyId.value!);
    const value = response?.[key];
    if (value) {
      model.value[key] = value as SettingType;
    }
    isLoading.value = false;
  }

  async function saveSetting(action = 'save') {
    isLoading.value = true;
    const { status } = await updateCompanySettings(model.value);
    if (status) {
      await showSuccessToast({
        message: t(`${action}.success`),
      });
    } else {
      await showDangerToast({
        message: t(`${action}.error`),
      });
    }
    isLoading.value = false;

    await dispatchSignal(SignalType.integrationServiceConnectionUpdated);
  }

  async function deleteSetting() {
    setting.value = null;
    model.value[key] = null;
    return saveSetting('delete');
  }

  return {
    saveSetting, deleteSetting, setting, isLoading,
  };

}
