import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { computed } from 'vue';
import { Vehicle } from '@/hooks/useDebtors';
import { useActiveTable } from '@/components/activeTable/useActiveTable';

export const useDebtorCommonVehicleTab = () => {
  const { t } = useLocalI18n('debtor.common.vehicle');
  const { debtor } = useInjectDebtorDialog();

  const vehicle = computed(() => debtor.value?.vehicle as Vehicle);

  const {
    columns,
    records,
  } = useActiveTable<Vehicle, Vehicle, 'id'>({
    keyField: 'id',
    async fetch() {
      return {
        count: 1,
        results: ([vehicle.value]),
      };
    },
    columns: computed(() => ([
      { key: 'grz' },
      { key: 'model_name', field: 'info.model_name' },
      { key: 'tc_type_name', field: 'info.tc_type_name' },
      { key: 'vin' },
      { key: 'ctc' },
      { key: 'ptc' },
      { key: 'region', field: 'region.name' },
    ].map((column) => ({
      width: 1,
      ...column,
      label: t(`column.${column.key}`),
    })))),
  });

  return {
    t,

    columns,
    records,
  };
};
