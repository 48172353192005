import { h } from 'vue';

/**
 * Компонент, рендерящий vnodes
 */
export default {
  name: 'VNode',
  props: ['vnodes'],
  // @ts-ignore
  render: (ctx) => [ctx.vnodes],
};
