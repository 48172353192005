export const getRandomNumber = (
  to = Number.MAX_VALUE,
  from = 0,
): number => Math.floor(Math.random() * to) + from;

export const getPercantageChange = (
  newValue: number, oldValue: number,
) => (oldValue === 0 && newValue === 0
  ? 0
  : oldValue === 0 ? 100 : Math.ceil(((newValue - oldValue) / oldValue) * 100));

export const isNumber = (str: string) => /^\d*$/.test(str);
