import { ApiCommand } from '@/store/modules/api';
import { useApi } from '@/hooks/useApi';

let request: any;

export function useDictionary() {
  const apiRequest = useApi();

  return {
    getDictionary: async () => await request || fetchTranslations(apiRequest),
    getDictionaryItem: async (value: string) => {
      const { response } = await request;
      console.log(response.actions, value);
      const result = response.actions.find((item: any) => item.value === value);
      return result?.label || value;
    },
  };
}

async function fetchTranslations(apiRequest: any) {
  if (request) {
    return request;
  }
  try {
    request = apiRequest({
      command: ApiCommand.fetchWritoeActions,
    });
  } catch (e) {
    console.warn('Failed to fetch translations', e);
  }
}
