// @ts-nocheck plates

import { DateTime } from 'luxon';
import {
  Tile,
  TileRenderParameters,
  TileRenderParametersLineConfig,
} from '@/service/api/reporting/tile';
import {
  PlateIndicatorType,
  PlateIndicatorValueType,
} from '@/components/plate/plateIndicator/index';
import {
  getValueFormatter,
  PlateIndicator,
  PlateIndicatorBar,
  PlateIndicatorFraction,
  PlateIndicatorFractionVariant,
  PlateIndicatorSimple,
  PlateIndicatorSimpleVariants,
  PlateIndicatorValueConfig,
  PlateIndicatorWithPrev,
} from '@/components/plate/plateIndicator/types';
import {
  groupLines,
  parseLineKey,
  removeTitleFromLineKey,
} from '@/components/forms/form/reportingChartForm/form';
import { ChartPieVariant } from '@/components/plate/chartConfigs/pie-charts';
import { ChartLineVariant } from '@/components/plate/chartConfigs/lineChart/line-chart';
import { ReportingChartPlateType } from '@/components/forms/form/reportingChartForm/utils';
import { PlateIndicatorListItem } from '@/components/plate/list/list';
import { envIsMobile } from '@/utils/env';
import { CHART_COLOR_NAMES, CHART_COLORS } from '@/components/plate/utils';

export const mapFieldTypeToIndicatorConfig = (
  rParams: TileRenderParametersLineConfig,
  rP: TileRenderParameters,
): PlateIndicatorValueConfig => {
  if (rP.show_percent) {
    return {
      type: PlateIndicatorValueType.Percent,
    };
  }
  if (rParams.value_type === 'currency') {
    return {
      type: PlateIndicatorValueType.Money,
    };
  }
  if (rParams.value_type === 'string') {
    return {
      type: PlateIndicatorValueType.Number,
    };
  }
  return {
    type: PlateIndicatorValueType.Number,
  };
};

export const mapTileToPlateIndicator = (tile: Tile): PlateIndicator => {
  console.log('mapTileToPlateIndicator', tile);
  try {
    const rParams = tile.render_parameters[0];
    const {
      type, show_percent, show_previous, plate_indicator_type, plate_indicator_variant,
      line_variant_default, show_value, show_growth, show_total, show_groups_percent, sum_groups,
    } = rParams;
    const additionalFieldKeys = Object.values(rParams.lines).filter((line) => line.additional_to_group).map((line) => line.key);
    const groupedLines = groupLines(tile.parameters.lines.filter(
      (line) => {
        const isAdditional = additionalFieldKeys.includes(line.key);
        return !isAdditional;
      },
    ));
    const groupedAdditionalLines = groupLines(tile.parameters.lines.filter(
      (line) => {
        return additionalFieldKeys.includes(line.key);
      },
    ));
    if (type === ReportingChartPlateType.Indicator) {
      if (plate_indicator_type === PlateIndicatorType.List) {
        const listItems = Object.values(groupedLines).reduce((acc, lines) => {
          const entireLineKey = lines.find((line) => line.entire)?.line.key;
          lines.forEach((line) => {
            if (!line.entire) {
              const lineConfig = rParams.lines[line.line.key];
              const entireValue = entireLineKey ? tile.data.summary[entireLineKey] : null;
              const value = Number(tile.data.summary[line.line.key]);
              const formatter = getValueFormatter(
                mapFieldTypeToIndicatorConfig(lineConfig, rParams),
              );
              const finalPercent = entireValue
                ? ((value / entireValue) * 100).toFixed(2)
                : null;
              const finalValue = entireValue
                ? `${formatter(value)} ${finalPercent}%`
                : formatter(value);
              acc.push({
                title: lineConfig.name,
                value: {
                  value: finalValue,
                },
              });
            }
          });
          return acc;
        }, [] as any[]);
        // const listItems = tile.parameters.lines
        //   .filter((line) => !line.key.includes('-entire'))
        //   .map((line) => {
        //     const lineConfig = rParams.lines[line.key];
        //     const getValueByKey = (
        //       key: string,
        //     ) => tile.data.summary[line.key];
        //     return {
        //       title: lineConfig.name,
        //       value: {
        //         value: getValueByKey(lineConfig.key),
        //         valueConfig: mapFieldTypeToIndicatorConfig(lineConfig, rParams),
        //       },
        //     } as PlateIndicatorListItem;
        //   });

        return {
          type: PlateIndicatorType.List,
          title: tile.name,
          showRaw: true,
          variant: rParams.plate_indicator_variant,
          list: listItems,
        };
      }
      const [clearLineKey, groupedLinesFirst] = Object.entries(groupedLines)[0];
      const valueLineKey = show_percent
        ? groupedLinesFirst.find((el) => !el.entire)?.line.key
        : clearLineKey;
      const lineRenderData = tile.render_parameters[0].lines[valueLineKey]
        || tile.render_parameters[0].lines[clearLineKey]
        || groupedLinesFirst[0].line.key;

      const getValueByKey = (
        key: string,
      ) => tile.data.summary[key];
      const getValue = (isEntire: boolean, isPrev: boolean) => Object.entries(groupedLines).reduce(
        (acc, [cleanLineKey, groupedLines]) => {
          const valueKeys = groupedLines
            .filter((el) => (isEntire ? el.entire : !el.entire))?.map((el) => el.line.key);
          const value = valueKeys.reduce(
            (acc, key) => acc + (getValueByKey(key, isPrev) || 0), 0,
          );
          return acc + (value || 0);
        }, 0,
      );
      const value = getValue();
      let prevValue;
      if (show_percent) {
        const entireValue = getValue(true);
        const v = Math.round((value / entireValue) * 100);
        if (plate_indicator_type === PlateIndicatorType.Fraction) {
          return {
            type: PlateIndicatorType.Fraction,
            variant: PlateIndicatorFractionVariant.Ring,
            title: tile.name,
            value: value as number,
            entireValue: entireValue as number,
            valueConfig: { type: PlateIndicatorValueType.Percent },
            showPercent: show_percent,
            showValue: show_value,
          } as PlateIndicatorFraction;
        }
        return {
          type: PlateIndicatorType.Simple,
          variant: plate_indicator_variant,
          title: tile.name,
          value: v,
          valueConfig: { type: PlateIndicatorValueType.Percent },
        } as PlateIndicatorSimple;
      }
      if (show_previous) {
        prevValue = getValue(false, true);
      }

      if (value === undefined && prevValue === undefined) {
        return null;
      }

      if (prevValue !== undefined) {
        return {
          type: PlateIndicatorType.WithPrevValue,
          variant: PlateIndicatorSimpleVariants.indicator,
          title: tile.name,
          value,
          valueConfig: mapFieldTypeToIndicatorConfig(lineRenderData, rParams),
          prevValue,
        } as PlateIndicatorWithPrev;
      }

      return {
        type: PlateIndicatorType.Simple,
        variant: PlateIndicatorSimpleVariants.indicator,
        title: tile.name,
        value,
        valueConfig: mapFieldTypeToIndicatorConfig(lineRenderData, rParams),
      } as PlateIndicatorSimple;
    }

    console.log('groupedLines', groupedLines);

    if (type === ReportingChartPlateType.ByStatus) {
      const reverseXY = envIsMobile;
      const [cleanLineKey, groupedLinesFirst] = Object.entries(groupedLines)[0];
      const getValue = (key) => tile.data.summary[key] ?? null;
      const entireKey = groupedLinesFirst.find((line) => line.entire)?.line.key;
      const entireValue = Number(getValue(entireKey));
      // if (tile.id === 'e32d148b-122b-4e39-b081-7c4a00467ecd') {
      //   debugger;
      // }

      const chartOptions = {
        title: {

        },
        dataset: {
          source: [
            ['key', 'name', 'value', 'formatted', 'percent'],
          ],
        },
        series: [{
          id: cleanLineKey,
          encode: rParams.plate_indicator_variant === ChartPieVariant.VerticalBars
            ? (reverseXY ? {
              x: 'value',
              y: 'name',
              tooltip: 'formatted',
            } : {
              x: 'name',
              y: 'value',
              tooltip: 'formatted',
            })
            : {
              itemName: 'name',
              value: 'value',
              tooltip: 'formatted',
              color: 'color',
            },
          // tooltip: {
          //   formatter(arg) {
          //     console.log('formatter', arg);
          //     return `${arg}`;
          //     return null;
          //   },
          // },
        }],
      };

      let valueFormatter;
      let sum = 0;

      const groupedSummaryKeys = Object.keys(tile.data.summary).reduce((acc, summaryKey) => {
        const noTitleKey = removeTitleFromLineKey(summaryKey);
        if (!acc[noTitleKey]) {
          acc[noTitleKey] = [];
        }
        acc[noTitleKey].push(summaryKey);
        return acc;
      }, {} as Record<string, string[]>);

      // const getDefaultParametersLineKeys = (
      //   renderParamsLineKey: string,
      // ) => Object.keys(tile.data.summary).reduce((acc, summaryKey) => {
      //   const noTitleKey = removeTitleFromLineKey(summaryKey)
      //   if (!acc[noTitleKey]) {
      //     acc[noTitleKey] = []
      //   }
      //   acc[noTitleKey].push(summaryKey)
      //   return acc;
      // }, []: Record<string,string[]>)

      const hasColors = Object.values(rParams.lines).some(
        (line) => line.color,
      );
      if (hasColors) {
        chartOptions.series[0].color = [];
      }

      groupedLinesFirst.filter((v) => !v.entire)
        .forEach(({
          line, noTitleKey, hasTitle, group, part,
        }, i) => {
          const rParamsLineKey = noTitleKey;
          const lineRenderData = rParams.lines[noTitleKey] || rParams.lines[line.key];
          try {
            valueFormatter = getValueFormatter(
              mapFieldTypeToIndicatorConfig(rParams.lines[rParamsLineKey], rParams).type,
            );
          } catch (e) {
            console.error(e);
          }

          const summaryLineKeys = groupedSummaryKeys[rParamsLineKey];
          if (!summaryLineKeys) {
            console.error('no summary line key');
            return;
          }

          summaryLineKeys.forEach((lineKey) => {
            let title = hasTitle ? parseLineKey(lineKey)?.title : null;
            if (title === 'None') {
              title = 'Город не указан';
            }
            const { name } = lineRenderData;
            const namePrepared = title || name;
            const value = Number(getValue(lineKey));

            sum += value || 0;

            let formatted = valueFormatter(value);
            if (entireKey) {
              const percent = entireValue ? ((value / entireValue) * 100).toFixed(0) : 0;
              formatted = `${formatted} (${percent}%)`;
            }
            const additionalLine = Object.values(groupedAdditionalLines)?.[0]?.find((additionalLine) => {
              const lineRParams = rParams.lines[additionalLine.line.key];
              const additionalToGroup = lineRParams.additional_to_group as number;
              console.log('is additionalLine', additionalLine, additionalToGroup, group);
              return group === additionalToGroup && part === additionalLine.part;
            });
            console.log('additionalLine', groupedAdditionalLines, additionalLine);
            if (additionalLine) {
              const v = getValue(additionalLine.line.key);
              console.log('additionalValue', line.key, additionalLine.line.key, v);
              if (typeof v === 'number') {
                formatted += `ZZZ${rParams.lines[additionalLine.line.key]?.name} - ${v}`;
              }
            }
            if (value) {
              if (hasColors) {
                chartOptions.series[0].color.push(CHART_COLOR_NAMES[lineRenderData.color] ?? lineRenderData.color);
              }
              chartOptions.dataset.source.push([lineKey, namePrepared, value, formatted]);
            }
          });
        });

      if (!entireKey) {
        for (let i = 1; i < chartOptions.dataset.source.length; i++) {
          const value = chartOptions.dataset.source[i][2];
          const formatted = chartOptions.dataset.source[i][3];
          const percent = sum ? ((value / sum) * 100).toFixed(0) : 0;
          const [part1, part2] = formatted.split('ZZZ');
          chartOptions.dataset.source[i][3] = [`${part1} (${percent}%)`, part2].filter(Boolean).join(' ');
        }
      }

      if (entireKey) {
        chartOptions.title.text = 'Всего';
        chartOptions.title.subtext = valueFormatter(sum);
      }
      if (rParams.pie_title_key) {
        const valueRow = chartOptions.dataset.source.find(([key]) => key === rParams.pie_title_key);
        if (valueRow) {
          const [key, name, value, formatted] = valueRow;
          chartOptions.title.text = name;
          chartOptions.title.subtext = formatted;
        }
      }

      return {
        type: plate_indicator_type as PlateIndicatorType.Pie,
        variant: (plate_indicator_variant === 'vertical-bars' && reverseXY
          ? 'horizontal-bars'
          : plate_indicator_variant) as ChartPieVariant,
        title: tile.name,
        chartOptions,
        valueFormatter,
      } as unknown as PlateIndicatorBar;
    }
    if (type === ReportingChartPlateType.Timeline) {
      const showPrev = Object.keys(groupedLines).some((k) => rParams.lines[k]?.show_previous);

      const groupedLinesWithCleanKey = Object.entries(groupedLines).map(
        ([cleanLineKey, lines]) => ({ lines, cleanLineKey }),
      );
      let indicatorFormatterType;
      const { series, source } = groupedLinesWithCleanKey.reduce((acc, { lines, cleanLineKey }) => {
        const seriesPrepared = lines.filter((line) => !line.entire).map((line) => {
          acc.source[0].push(line.line.key);
          acc.source[0].push(`${line.line.key}-formatted`);
          if (show_groups_percent) {
            acc.source[0].push(`${line.line.key}-percent`);
          }
          indicatorFormatterType = mapFieldTypeToIndicatorConfig(
            rParams.lines[cleanLineKey], rParams,
          ).type;
          const valueFormatter = getValueFormatter(
            indicatorFormatterType,
          );
          const tooltip = `${line.line.key}-formatted`;
          const encode = plate_indicator_variant === ChartLineVariant.Linear
            ? {
              x: 'date-formatted',
              y: line.line.key,
              tooltip,
            }
            : {
              angle: 'date-formatted',
              radius: line.line.key,
              tooltip,
            };

          return {
            name: rParams.lines[line.line.key]?.name ?? line.line.key,
            id: line.line.key,
            stack: !rParams.stack_group || rParams.lines[cleanLineKey].no_stack
              ? undefined
              : (rParams.lines[cleanLineKey].stack || cleanLineKey),
            valueFormatter,
            variant: rParams.lines[line.line.key]?.line_variant || line_variant_default,
            encode,
          };
        });
        acc.series.push(...seriesPrepared);
        return acc;
      }, { series: [], source: [['date', 'date-formatted']] } as { series: any[]; source: any[] });
      if (show_groups_percent) {
        source[0].push('sum');
      }
      let total = 0;
      let start: number;
      let end: number;
      const startTimelineIndex = showPrev ? ((tile.data.time.length / 2) - 1) : 0;
      for (let dateIndex = startTimelineIndex; dateIndex < tile.data.time.length; dateIndex += 1) {
        const dataRow = [];
        const formattedDate = DateTime
          .fromISO(tile.data.time[dateIndex].split('+')[0])
          .toFormat(rParams.custom_date_formatter || 'yyyy-mm-dd', { locale: 'ru' });
        dataRow.push(formattedDate);
        dataRow.push(formattedDate);
        for (let seriesIndex = 0; seriesIndex < series.length; seriesIndex += 1) {
          let rowSum = 0;
          // if (tile.id === 'd8adbd1b-f6ed-49c5-a9e5-91a4c9dceab6') {
          //   debugger;
          //   const seriesData = tile.data.summary[series[seriesIndex].id];
          //   console.log('seriesData', seriesData);
          // }
          const v = tile.data.summary[series[seriesIndex].id][dateIndex];
          total += v;
          dataRow.push(v);
          rowSum += v;
          const formatted = series[seriesIndex].valueFormatter(v);
          dataRow.push(formatted);
          if (show_groups_percent) {
            dataRow.push(null);
          }
          if (start === undefined) {
            start = v;
          }
          if (v !== null && v !== undefined) {
            end = v;
          }
          if (seriesIndex === series.length - 1) {
            if (show_groups_percent) {
              dataRow.push(rowSum);
            }
          }
        }
        source.push(dataRow);
      }
      if (rParams.show_timeline_percent) {
        const [headRow, ...valueRows] = source;
        const timelineSummaries = valueRows.reduce((acc, values) => {
          headRow.forEach((k, i) => {
            if (k !== 'date' && k !== 'date-formatted') {
              if (k.endsWith('-formatted')) {
                const value = values[i - 1];
                const key = headRow[i - 1];
                if (!acc[key]) {
                  acc[key] = 0;
                }
                acc[key] += value;
              }
            }
          });
          return acc;
        }, {});
        const formattedColumnIndexes = headRow.map((k, i) => {
          if (k !== 'date' && k !== 'date-formatted') {
            if (k.endsWith('-formatted')) {
              return i;
            }
          }
          return false;
        }).filter((v) => v !== false);
        formattedColumnIndexes.forEach((i) => {
          const valueColumnKey = headRow[i - 1];
          valueRows.forEach((values) => {
            values.forEach((formattedValue, valueColIndex, valueArr) => {
              const sum = timelineSummaries[valueColumnKey] || 0;
              if (valueColIndex === i) {
                const value = valueArr[valueColIndex - 1];
                valueArr[valueColIndex] = `${formattedValue} (${((value / sum) * 100)
                  .toFixed(0)}%)`;
              }
            });
          });
        });
      }
      if (!start) {
        start = 0;
      }
      if (!end) {
        end = 0;
      }
      let growthPercent = start === total
        ? 0
        : Math.round((start === 0 ? 1 : (total - start) / start) * 100);

      if (show_groups_percent) {
        const percentCellIndexes = source[0].reduce((acc, name: string, i) => {
          if (name.endsWith('-percent')) {
            acc.push(i);
          }
          return acc;
        }, [] as string[]);
        for (
          let sourceRow = 1;
          sourceRow < source.length;
          sourceRow += 1
        ) {
          for (let sourceIndex = 0; sourceIndex < source[0].length; sourceIndex += 1) {
            if (percentCellIndexes.includes(sourceIndex)) {
              const valueIndex = sourceIndex - 2;
              const valueFormatted = source[sourceRow][sourceIndex - 1];
              const sumIndex = source.length - 1;
              const sumValue = source[sourceRow][sumIndex];
              const value = source[sourceRow][valueIndex];
              const percent = sumValue === 0 ? 0 : ((value / sumValue) * 100).toFixed(0);
              source[sourceRow][sourceIndex - 1] = `${valueFormatted} (${percent}%)`;
            }
          }
        }
      }

      const chartTile = {
        type: plate_indicator_type,
        variant: plate_indicator_variant as ChartLineVariant,
        title: tile.name,
        lineVariantDefault: line_variant_default,
        chartOptions: { dataset: { source }, series },
      };
      if (tile.data.extra && Object.keys(tile.data.extra).length) {
        const obj = tile.data.extra[series[0].id];
        total = obj.growth;
        growthPercent = obj.growth_procent;
      }

      if (show_growth || show_total) {
        const totalPlate = {
          type: PlateIndicatorType.Simple,
          variant: PlateIndicatorSimpleVariants.smallIndicator,
          value: Math.round(total || 0),
          caption: 'Всего',
          bodyClass: ['_w-100-h-100', '_b-r', '_b-b', '_b-color', '_flex-justify-center', '_ta-center'],
          valueConfig: {
            type: indicatorFormatterType || PlateIndicatorValueType.PlusNumber,
          },
        } as PlateIndicatorSimple;
        const growthPlate = {
          type: PlateIndicatorType.Simple,
          variant: PlateIndicatorSimpleVariants.borderedIndicator,
          value: Math.abs(growthPercent || 0),
          valueConfig: {
            type: growthPercent >= 0
              ? PlateIndicatorValueType.PlusPercent
              : PlateIndicatorValueType.MinusPercent,
          },
          bodyClass: ['_b-color', '_b-b', '_w-100-h-100', '_flex-justify-center'],
        } as PlateIndicatorSimple;
        return {
          title: tile.name,
          gridConfig: {
            rows: 2,
            cols: 2,
            rowSizes: envIsMobile ? '1fr 7fr' : '1fr 3fr',
            colSizes: '1fr 1fr',
          },
          type: PlateIndicatorType.Multi,
          children: [
            totalPlate,
            growthPlate,
            chartTile,
          ],
          childrenGridConfigs: [
            {
              rowStart: 1, rowEnd: 2, colStart: 1, colEnd: 2,
            },
            {
              rowStart: 1, rowEnd: 2, colStart: 2, colEnd: 3,
            },
            {
              rowStart: 2, rowEnd: 3, colStart: 1, colEnd: 3,
            },
          ],
        };
      }
      return chartTile;
    }
    return () => null;
  } catch (e) {
    console.error(e);
    throw new Error('Ошибка отрисовки графика');
  }
};
