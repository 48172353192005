import { SignalType, useSignal } from '@/hooks/useSignal';
import {
  onBeforeUnmount, onMounted, Ref, ref,
} from 'vue';
import { DebtorPayload } from '@/hooks/useDialog';
import { DebtorMeta } from '@/components/dialog/dialogs/debtor/useDebtorDialog';

export const useDebtorDialogNavigation = (
  id: Ref<DebtorPayload['id']>,
) => {
  const nextDebtorMeta = ref<DebtorMeta | null>(null);
  const prevDebtorMeta = ref<DebtorMeta | null>(null);
  const totalDebtors = ref<number | null>(null);

  const {
    subscribeToSignal,
    dispatchSignal,
  } = useSignal();

  onBeforeUnmount(
    subscribeToSignal(SignalType.debtorNeighboursFound, ({
      currentId, next, prev, total,
    }: {
      currentId: number;
      next: DebtorMeta|null;
      prev: DebtorMeta|null;
      total: number|null;
    }) => {
      if (currentId === id.value) {
        nextDebtorMeta.value = next;
        prevDebtorMeta.value = prev;
        totalDebtors.value = total;
      }
    }),
  );

  const goTo = async (debtorMeta: DebtorMeta|null) => {
    if (!debtorMeta) return;
    await dispatchSignal(SignalType.navigateDebtor, debtorMeta);
  };

  const onKeypress = (e: KeyboardEvent) => {
    if (e.key === 'ArrowRight' && nextDebtorMeta.value) {
      goTo(nextDebtorMeta.value);
    } else if (e.key === 'ArrowLeft' && prevDebtorMeta.value) {
      goTo(prevDebtorMeta.value);
    }
  };

  onMounted(() => {
    document.addEventListener('keydown', onKeypress);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('keydown', onKeypress);
  });

  return {
    goTo, nextDebtorMeta, prevDebtorMeta, totalDebtors,
  };
};
