import { Phone } from '@/hooks/useDebtors';

export function sortPhones(phones: Phone[]) {
  return phones.sort((a, b) => (
    sameValidness(a, b)
      ? sortAsString(a, b)
      : a.is_valid
        ? -1
        : 1
  ));
}

function sameValidness(a: Phone, b: Phone) {
  return (a.is_valid && b.is_valid) || (!a.is_valid && !b.is_valid);
}

function sortAsString(a: Phone, b: Phone) {
  return a.number.localeCompare(b.number);
}
