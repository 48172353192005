import { ActiveTabKey } from '@/components/dialog/dialogs/debtor/useDebtorDialogTabs';
import { computed, Ref } from 'vue';
import DebtorCommonTab from '@/components/dialog/dialogs/debtor/tabs/common/DebtorCommonTab.vue';
import DebtorDocumentsTab
  from '@/components/dialog/dialogs/debtor/tabs/documents/DebtorDocumentsTab.vue';
import DebtorFinanceTab from '@/components/dialog/dialogs/debtor/tabs/finance/DebtorFinanceTab.vue';
import DebtorNotificationsTab
  from '@/components/dialog/dialogs/debtor/tabs/notifications/DebtorNotificationsTab.vue';
import DebtorCourtsTab from '@/components/dialog/dialogs/debtor/tabs/courts/DebtorCourtsTab.vue';
import DebtorExecutionsTab
  from '@/components/dialog/dialogs/debtor/tabs/executions/DebtorExecutionsTab.vue';
import DebtorHistoryTab from '@/components/dialog/dialogs/debtor/tabs/history/DebtorHistoryTab.vue';

export const useDebtorDialogComponent = (
  activeTab: Ref<ActiveTabKey>,
) => computed(() => (({
  common: DebtorCommonTab,
  documents: DebtorDocumentsTab,
  finance: DebtorFinanceTab,
  notifications: DebtorNotificationsTab,
  courts: DebtorCourtsTab,
  executions: DebtorExecutionsTab,
  history: DebtorHistoryTab,
} as {[key in ActiveTabKey]: any})[activeTab.value]));
