import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { commonLegacyApiRequest as request } from '@core/service/commonService';
import { ProductionType } from '@/hooks/useConstructor';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';

type DocumentCommon = {
  id: number;
  production_type: ProductionType;
  is_loaded_manually: boolean;
  created_at: Date;
  debtor: number;
}

export type DocumentDto = DocumentCommon & {
  file: string;
}

export type CreateModel = DocumentCommon & {
  file?: File | string | null;
}

export type UpdateModel = CreateModel & {
  id: number;
};

export function useLoanAgreementsApi() {
  const { companyId } = useDefaultCompany();
  const key = 'loan_agreement';

  const fetchLoanAgreements = (model: { filters: { debtor_id: number } }) => request<ListingResponse<DocumentDto>>({
    command: ApiCommand.fetchUniqueDebtorsDocuments,
    params: {
      key,
      debtor_id: model.filters.debtor_id,
    },
  });

  const fetchLoanAgreement = (model: { filters: { id: number } }) => request<DocumentDto>({
    command: ApiCommand.fetchUniqueDebtorsDocument,
    params: {
      key,
      id: model.filters.id,
    },
  });

  const createLoanAgreement = (model: CreateModel) => request<DocumentDto>({
    command: ApiCommand.createUniqueDebtorsDocument,
    data: {
      key,
      company: companyId.value,
      ...model,
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const updateLoanAgreement = (model: UpdateModel) => request<DocumentDto>({
    command: ApiCommand.updateUniqueDebtorsDocument,
    data: {
      key,
      ...model,
    },
    params: {
      id: model.id,
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {
    fetchLoanAgreements,
    fetchLoanAgreement,
    createLoanAgreement,
    updateLoanAgreement,
  };
}
