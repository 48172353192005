import {
  Debtor,
  DebtorModel,
  DebtorOrganization,
  DebtorPerson,
  DebtorPersonModel,
} from '@/hooks/useDebtors';

export function isOrganization(debtor: Debtor): debtor is DebtorOrganization {
  return !!debtor?.debtor_main_profile?.organization;
}

export function isPersonDebtor(debtor: Debtor): debtor is DebtorPerson {
  return !debtor?.debtor_main_profile?.organization;
}

export function isPerson(debtor?: DebtorModel): debtor is DebtorPersonModel {
  return !debtor?.isOrganization;
}
