import { computed } from 'vue';
import { useApi2 } from '@/hooks/useApi2';
import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { unwrapListingApiResponse } from '@/service/api';
import { CompanyService } from '@/hooks/useCompanies';
import { DictType, useDicts } from '@/hooks/useDicts';

export const useCompanyServices = () => {
  const request = useApi2();
  const { getDict, getDictMap } = useDicts();

  const dict = computed(
    () => getDict<{ label: string; value: string }>(DictType.companyServices).value,
  );

  const fetchList = ({ company_id }: { company_id: number }) => request<
    ListingResponse<CompanyService>
    >({
      command: ApiCommand.getCompanyServicesByCompanyId,
      params: { company_id },
    }).then(unwrapListingApiResponse);

  const createEntry = (
    { company_id, name }: { company_id: number; name: string },
  ) => request<CompanyService[]>({
    command: ApiCommand.addCompanyServicesByCompanyId,
    params: { company_id },
    data: { name, sort_order: 1 },
  });

  const deleteEntry = ({
    company_id, id,
  }: { company_id: number; id: number }) => request<null>({
    command: ApiCommand.deleteCompanyServicesByServicesId,
    params: { company_id, id },
  });

  return {
    fetchList,
    createEntry,
    deleteEntry,
    dict,
  };
};
