import {
  computed, ref, SetupContext,
} from 'vue';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import {
  AisGorodDialog,
  Code,
  CodeOption,
  Model,
} from '@/pages/debtors/_module/tableActions/aisGorodUpdate/types';
import { useSettingsCommon } from '@/pages/exchange/integration/dialogs/useSettingsCommon';
import { key, defaultModel, ModelDto as SettingModel } from '@/pages/exchange/integration/billing/AisGorod.vue';

export const useAisGorodUpdateDialog = (props: {
  onSave: AisGorodDialog['onSave'];

}, { emit }: SetupContext<'close'[]>) => {

  const setting = ref<SettingModel>(defaultModel() as unknown as SettingModel);
  const { companyModuleType, companyId } = useProtectedDefaultCompany();

  useSettingsCommon({
    settingKey: key,
    companyId,
    model: setting,
    useOwner: false,
  });

  const model = ref<Model>({
    startDate: new Date(),
    endDate: new Date(),
    providerCodes: [],
  });

  const submit = () => {
    props.onSave({
      ...model.value,
      // @ts-ignore
      providerCodes: mapProviderCodes(),
    });
    emit('close');
  };

  function mapProviderCodes(): string[] | Record<string, string> {
    if (model.value.providerCodes.find((item) => item === 'all')) {
      return setting.value.provider_codes;
    }
    return Array.isArray(setting.value.provider_codes)
      ? model.value.providerCodes
      : Object.fromEntries(Object.entries(setting.value.provider_codes).filter(([k, v]) => model.value.providerCodes.includes(k)));
  }

  const allOption: CodeOption = { value: 'all', label: 'Все' };

  const providerCodeOptions = computed(() => {
    if (model.value.providerCodes.find((item: Code) => item === 'all')) {
      return [allOption];
    }
    const options: CodeOption[] = [
      allOption,
      ...(Array.isArray(setting.value.provider_codes)
        ? (setting.value.provider_codes ?? []).map((v) => ({
          value: v, label: v,
        }))
        : Object.entries(setting.value.provider_codes).map(([key, value]) => ({
          label: value, value: key,
        }))),
    ];
    return options;
  });

  model.value.providerCodes = ['all'];

  return {
    model,
    submit,
    companyModuleType,
    providerCodeOptions,
  };
};
