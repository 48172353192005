import { StandartizeApi } from '@/service/standartizeService';
import { StandartizeProgressTask } from '@/store/modules/tasksProgress';
import { Progress, ProgressMap, TaskKey } from '@/store/modules/standartize/types';

const nullProgress = {
  current_progress: 0,
  max_progress: 0,
  remaining_time: 0,
  speed: 0,
};

export async function getTaskProgress(task: StandartizeProgressTask): Promise<ProgressMap> {

  const resultArray: Array<[TaskKey, Progress]> = await Promise.all([
    ...(task.standartizeTaskUUID
      ? [StandartizeApi.taskProgress.getItem(task.standartizeTaskUUID).then((r) => {
        if (!r.status) {
          return ['standartizeTaskUUID', nullProgress];
        }
        return ['standartizeTaskUUID', r.response];
      })] : []),
    ...(task.saveStandartizeTaskUUID
      ? [StandartizeApi.taskProgress.getItem(task.saveStandartizeTaskUUID).then((r) => {
        if (!r.status) {
          return ['saveStandartizeTaskUUID', nullProgress];
        }
        return ['saveStandartizeTaskUUID', r.response];
      })] : []),
    ...(task.cadnumTaskUUID
      ? [StandartizeApi.taskProgress.getItem(task.cadnumTaskUUID).then((r) => {
        if (!r.status) {
          return ['cadnumTaskUUID', nullProgress];
        }
        return ['cadnumTaskUUID', r.response];
      })] : []),

  ]) as Array<[TaskKey, Progress]>;

  return resultArray.reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {} as Record<TaskKey, Progress>);

}
