import { useModelDialog } from '@/hooks/useModelDialog';
import { DebtorFinanceData, useDebtorFinanceApi } from '@/components/dialog/dialogs/debtor/tabs/finance/useDebtorFinanceApi';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { dateToApiDate } from '@/utils/date';
import { Ref } from 'vue';
import { useDialog } from '@/hooks/useDialog';

export const usePaidUpsEditModal = (debtorId: Ref<number>) => {
  const debtorFinanceDataApi = useDebtorFinanceApi();

  const { confirmDialog } = useDialog();

  const editDialog = useModelDialog<{ date: Date; value: number }, DebtorFinanceData, number>({
    key: 'edit_paid_ups',
    ionDialogClassList: ['debtorPaidUpsEditModal'],
    title: 'Редактирование данных об оплате',
    getForm: async (id) => debtorFinanceDataApi.fetchItem(id).then(
      (r) => {
        if (!r.status) {
          return {
            status: false,
            response: r.response,
          };
        }
        return {
          status: true,
          response: {
            date: new Date(r.response.for_date),
            value: +r.response.value ?? null,
          },
        };
      },
    ),
    fields: [
      {
        key: 'date',
        field: 'date',
        type: ActiveFormFieldType.date,
        options: {
          label: 'Дата оплаты',
          type: 'month',
        },
      },
      {
        key: 'value',
        field: 'value',
        type: ActiveFormFieldType.input,
        options: {
          label: 'Сумма',
          modelModifiers: { number: true },
        },
      },
    ],
    onModelUpdate: async (form, id: number) => {
      if (!form.date || form.value === null) {
        return {
          status: false,
          response: {
            errors: ['Все поля должны быть заполнены'],
          },
        };
      }

      return debtorFinanceDataApi.updateItem({
        id,
        debtor_data: debtorId.value,
        type: 'paid_up',
        date: dateToApiDate(form.date) as string,
        for_date: dateToApiDate(form.date) as string,
        value: form.value,
      });
    },
  });

  const createDialog = useModelDialog<{ date: Date; value: number }, DebtorFinanceData>({
    key: 'add_paid_ups',
    ionDialogClassList: ['debtorPaidUpsEditModal'],
    title: 'Добавление данных об оплате',
    fields: [
      {
        key: 'date',
        field: 'date',
        type: ActiveFormFieldType.date,
        options: {
          label: 'Дата оплаты',
          type: 'month',
        },
      },
      {
        key: 'value',
        field: 'value',
        type: ActiveFormFieldType.input,
        options: {
          label: 'Сумма',
          modelModifiers: { number: true },
        },
      },
    ],
    onModelUpdate: async (form) => {
      if (!form.date || form.value === null) {
        return {
          status: false,
          response: {
            errors: ['Все поля должны быть заполнены'],
          },
        };
      }

      return debtorFinanceDataApi.createItem({
        debtor_data: debtorId.value,
        type: 'paid_up',
        date: dateToApiDate(form.date) as string,
        for_date: dateToApiDate(form.date) as string,
        value: form.value,
      });
    },
  });

  const showDeleteDialog = async (partIds: number[]) => confirmDialog({
    key: 'remove_paid_ups',
    title: 'Удалить запись об оплате',
    withConfirmation: true,
  }).then(async (r) => {
    if (!r.result) {
      r.closeDialog();
      return { status: false };
    }
    const responses = await Promise.all(
      partIds.map(
        (id) => debtorFinanceDataApi.deleteItem(id),
      ),
    );
    r.closeDialog();
    return { status: responses.every((r) => r.status) };
  });

  return {
    showEditDialog: editDialog.showDialog,
    showCreateDialog: createDialog.showDialog,
    showDeleteDialog,
  };
};
