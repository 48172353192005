import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { ProductionType } from '@/hooks/useConstructor';
import { DocumentParsingTemplate, TemplateMode, UTF8 } from '@/hooks/useDocumentParsingTemplatesApi';
import { parseIfDate } from '@/utils/date';

export const templateFields: Omit<ActiveFormField<DocumentParsingTemplate>, 'field'>[] = [
  {
    key: 'title',
    label: 'Имя шаблона',
    type: ActiveFormFieldType.input,
  },
  {
    key: 'is_default',
    label: 'Шаблон по умолчанию',
    type: ActiveFormFieldType.checkbox,
  },
  {
    key: 'production_type',
    label: 'Модуль шаблона',
    type: ActiveFormFieldType.select,
    options: {
      allowEmpty: false,
      options: [
        {
          label: 'Досудебное производство',
          value: ProductionType.pretrial,
        }, {
          label: 'Судебное производство',
          value: ProductionType.judicial,
        },
        {
          label: 'Исполнительное производство',
          value: ProductionType.executive,
        },
      ],
    },
  },
  {
    key: 'mode',
    label: 'Режим шаблона',
    type: ActiveFormFieldType.select,
    options: {
      allowEmpty: false,
      options: [
        {
          label: 'Линейный',
          value: TemplateMode.linear,
        },
        {
          label: 'Табличный',
          value: TemplateMode.table,
        },
        {
          label: 'JSON',
          value: TemplateMode.json,
        },
      ],
    },
  },
  {
    key: 'encoding',
    label: 'Кодировка',
    type: ActiveFormFieldType.select,
    options: {
      allowEmpty: false,
      options: [
        {
          label: 'UTF-8',
          value: UTF8,
        },
      ],
    },
  },
  {
    key: 'rows_offset',
    label: 'Отступ строк',
    iconHint: 'Сколько первых строк реестра пропустить. Для отступа необходимо использовать числа: 0, 1, 2, ...',
    type: ActiveFormFieldType.input,
  },
  {
    key: 'sep',
    label: 'Разделитель',
    type: ActiveFormFieldType.select,
    options: {
      options: [
        {
          label: 'Не использовать',
          value: null,
        },
        {
          label: ';',
          value: ';',
        },
        {
          label: ',',
          value: ',',
        },
      ],
    },
  },
  {
    key: 'default_paid_up_day',
    label: 'День оплаты (по умолчанию)',
    type: ActiveFormFieldType.input,
  },
  {
    key: 'default_accrual_day',
    label: 'День начисления (по умолчанию)',
    type: ActiveFormFieldType.input,
  },
  {
    key: 'default_start_balance_day',
    label: 'День входящего сальдо (по умолчанию)',
    type: ActiveFormFieldType.input,
  },
  {
    key: 'register_compilation_date',
    label: 'Дата составления реестра',
    type: ActiveFormFieldType.date,
  },
  {
    key: 'disable_calculate_penalties',
    label: 'Отключить расчет пени',
    type: ActiveFormFieldType.checkbox,
  },
  {
    key: 'disable_calculate_fees',
    label: 'Отключить расчет пошлины',
    type: ActiveFormFieldType.checkbox,
  },
  {
    key: 'disable_calculations',
    label: 'Отключить расчеты',
    type: ActiveFormFieldType.checkbox,
  },
  {
    key: 'force_calculate_fees',
    label: 'Расчет пошлины',
    type: ActiveFormFieldType.checkbox,
  },
  {
    key: 'force_calculate_penalties',
    label: 'Расчет пени',
    type: ActiveFormFieldType.checkbox,
  },
  {
    key: 'disable_morphology',
    label: 'Отключить морфологию',
    type: ActiveFormFieldType.checkbox,
  },
  {
    key: 'overwrite',
    label: 'Перезапись данных',
    type: ActiveFormFieldType.checkbox,
  },
  {
    key: 'search_debtors',
    label: 'Добавление данных к имеющимся должникам',
    type: ActiveFormFieldType.checkbox,
    iconHint: 'Должники сопоставляются по ЛС, ФИО и адресу. Данные записываются только для уже существующих должников',
  },
  {
    key: 'search_fields',
    label: 'Поля для сопоставления данных по должнику',
    type: ActiveFormFieldType.select,
    options: {
      options: [
        {
          label: 'ФИО / Организация',
          value: 'full_name',
        },
        {
          label: 'ЛС',
          value: 'account',
        },
        {
          label: 'Адрес',
          value: 'address',
        },
        {
          label: 'Назначение платежа',
          value: 'purpose_of_payment',
        },
      ],
    },
  },
  {
    key: 'terminate_after_first_error',
    label: 'Завершать импорт при первой ошибке',
    type: ActiveFormFieldType.checkbox,
  },

].map((value) => ({
  ...value,
  field: value.key,
  options: {
    ...value?.options,
    id: `edit_template_settings_${value.key}_field`,
  },
}));
