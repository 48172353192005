import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useI18n } from 'vue-i18n';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import {
  FetchStandardizedDebtorAddressesResponse,
  RosreestrEstateObjectCharacteristic as Characteristic, RosreestrEstateObjectMovement as Movement,
  useRosreestr,
} from '@/hooks/useRosreestr';
import { computed, ref, watch } from 'vue';
import { ActiveTableColumnFormat, useActiveTable } from '@/components/activeTable/useActiveTable';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { dateToApiDate, formatDate } from '@/utils/date';

export const useDebtorCommonOwnersTab = () => {
  const { t } = useLocalI18n('debtor.common.owners');
  const { t: rootT } = useI18n();
  const { debtor } = useInjectDebtorDialog();

  const {
    fetchEstateObjectCharacteristics,
    fetchEstateObjectMovements,
  } = useRosreestr();

  const isCadnumLoaded = ref(false);
  const cadnum = ref<FetchStandardizedDebtorAddressesResponse>();

  const {
    fetchStandardizedDebtorAddresses,
  } = useRosreestr();

  const fetchCadnum = async () => {
    if (debtor.value?.debtor_main_profile.id) {
      const { status, response } = await fetchStandardizedDebtorAddresses({
        id: debtor.value.debtor_main_profile.id,
      });
      if (!status) {
        return;
      }
      cadnum.value = response;
      isCadnumLoaded.value = true;
    }
  };

  watch(debtor, () => {
    isCadnumLoaded.value = false;
    fetchCadnum();
  }, { immediate: true });

  const organizationColumns = computed(() => ([
    { key: 'owner_name', width: 2 },
    { key: 'ogrn', field: 'organization.ogrn' },
    { key: 'inn', field: 'organization.inn' },
    { key: 'kpp', field: 'organization.kpp' },
    {
      key: 'organization.ogrn_data_reg',
      field: 'organization.ogrn_data_reg',
      format: ActiveTableColumnFormat.date,
    },
    { key: 'full_name', field: 'full_name' },
    { key: 'registered_ownership_type', width: 1.5 },
    { key: 'fraction_in_ownership' },
    { key: 'ownership_registration_date', format: ActiveTableColumnFormat.date },
    { key: 'ownership_registration_number', width: 2 },
  ]).map((column) => ({
    field: column.key,
    ...column,
    label: t(`column.${column.key.split('.').pop()}`),
  })));

  const columns = computed(() => ([
    { key: 'owner_name', width: 2 },
    { key: 'debtor_data.tenants.0.birth_date', format: ActiveTableColumnFormat.date },
    { key: 'debtor_data.tenants.0.birth_place' },
    { key: 'debtor_data.tenants.0.num_of_passport' },
    { key: 'debtor_data.tenants.0.date_of_passport_issue', format: ActiveTableColumnFormat.date },
    { key: 'debtor_data.tenants.0.passport_issued_by' },
    { key: 'debtor_data.tenants.0.inn' },
    { key: 'registered_ownership_type', width: 1.5 },
    { key: 'fraction_in_ownership' },
    { key: 'ownership_registration_date', format: ActiveTableColumnFormat.date },
    { key: 'ownership_registration_number', width: 2 },
  ].map((column) => ({
    ...column,
    field: column.key,
    label: t(`column.${column.key.split('.').pop()}`),
  }))));

  const filters = computed(() => ([
    {
      key: 'debtor_id',
      field: 'debtor_id',
      type: ActiveFormFieldType.input,
      defaultValue: debtor.value?.debtor?.pk,
    },
    {
      key: 'active',
      field: 'active',
      type: ActiveFormFieldType.input,
      defaultValue: true,
    },
  ]));

  const {
    columns: characteristicColumns,
    records: characteristicRecords,
    isLoaded: isCharacteristicLoaded,
  } = useActiveTable<Characteristic, Characteristic, 'id'>({
    keyField: 'id',
    fetch: async ({ params, signal }) => {
      if (!params.filters?.debtor_id) {
        return {
          count: 0,
          results: [],
        };
      }
      // @ts-ignore
      const { response } = await fetchEstateObjectCharacteristics({ ...params, signal });

      return {
        ...response,
        results: response.results.filter(
          (r) => String(r.registered_ownership_type).toLowerCase() !== 'информация отсутсвует',
        ),
      };
    },
    columns: computed(() => {
      if (!characteristicRecords.value?.[0]?.organization) {
        return columns.value;
      }
      return organizationColumns.value;
    }),
    filters,
  });

  const {
    columns: movementColumns,
    records: movementRecords,
  } = useActiveTable<Movement, Movement, 'id'>({
    keyField: 'id',
    fetch: async ({ params, signal }) => {
      if (!params.filters?.debtor_id) {
        return {
          count: 0,
          results: [],
        };
      }
      const { response } = await fetchEstateObjectMovements({ ...params, signal });

      return response;
    },
    columns,
    filters,
  });

  const movementsRowHeaderIds = computed(() => (
    movementRecords.value.filter(({ date_from, id, date_to }, index) => {
      const uniqIndex = movementRecords.value.findIndex(
        (el, i) => {
          const dateToAreEqual = date_to && el.date_to && dateToApiDate(date_to) === dateToApiDate(el.date_to);
          const dateFromAreEqual = date_from && el.date_from && dateToApiDate(date_from) === dateToApiDate(el.date_from);
          return dateToAreEqual && dateFromAreEqual;
        },
      );
      return !!date_from && uniqIndex === index;
    }).map(({ id }) => id)
  ));

  const characteristicRecordsNorm = computed(
    () => characteristicRecords.value,
  );

  const characteristicsRowHeaderIds = computed(
    () => (characteristicRecordsNorm.value?.length
      ? [characteristicRecordsNorm.value[0]?.id]
      : []),
  );

  const characteristicsRow = computed(
    () => {
      if (!characteristicsRowHeaderIds.value.length) return null;
      return characteristicRecordsNorm.value[0];
    },
  );

  return {
    t,

    isCharacteristicLoaded,
    characteristicsRowHeaderIds,
    characteristicColumns,
    characteristicRecords,
    characteristicRecordsNorm,

    movementsRowHeaderIds,
    movementColumns,
    movementRecords,
    characteristicsRow,
    formatDate,
    cadnum,
    isCadnumLoaded,
    debtor,
  };
};
