import { ScreenBreakpoints, useBreakpoint } from '@/hooks/useBreakpoint';
import { computed, Ref } from 'vue';

const fontSizes = {
  [ScreenBreakpoints.tablet]: 12,
  [ScreenBreakpoints.laptop]: 14,
  [ScreenBreakpoints.desktop]: 16,
};

const rowHeights = {
  [ScreenBreakpoints.tablet]: 45,
  [ScreenBreakpoints.laptop]: 60,
  [ScreenBreakpoints.desktop]: 80,
};

export const useVirtualScrollRowHeight = (heightMap: Ref<Record<ScreenBreakpoints, number>|undefined>) => {
  const breakpoint = useBreakpoint();

  return computed(() => (heightMap.value || rowHeights)[breakpoint.value]);
};
