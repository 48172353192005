import dayjs from 'dayjs';

export const parseDateStringWithFormats = (value: string, formats: string[], finalFormat: string) => {
  const parsedResults = formats.map((fmt) => {
    const parsed = dayjs(value, fmt);
    return parsed.isValid() ? parsed : null;
  });
  const validResult = parsedResults.find((result) => result);
  if (!validResult) {
    return null;
  }
  return validResult.format(finalFormat);
};

export const parseDateStringWithDefaultFormat = (value: string | null) => (
  value
    ? parseDateStringWithFormats(value, ['YYYY-MM-DD'], 'DD.MM.YYYY')
    : '');
