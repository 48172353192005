import { Module } from 'vuex';
import { StoreState } from '@/store';

// eslint-disable-next-line @typescript-eslint/ban-types
export type CourtsState = {};

type CourtsModule = Module<CourtsState, StoreState>;

export const namespaced = true;

export const actions: CourtsModule['actions'] = {

};
