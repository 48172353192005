import { computed, Ref, ref } from 'vue';
import { PretrialAutomationModel, AutoTabKey } from '@/components/automation/pretrial/tabs/types';
import { ActiveFormField } from '@/hooks/useActiveForm';
import { useFields } from '@/components/automation/pretrial/tabs/fields';

type Tab = keyof typeof AutoTabKey;

export function useTabs(model: Ref<PretrialAutomationModel>) {
  const { fields } = useFields(model);
  const activeTab = ref<Tab>('commonP');

  const tabs = computed(() => (([
    {
      key: AutoTabKey.commonP,
      label: 'Общее',
    },
    {
      key: AutoTabKey.text,
      label: 'Уведомления SMS/мессенджеры',
    },
    {
      key: AutoTabKey.voice,
      label: 'Голосовые уведомления',
    },
    {
      key: AutoTabKey.claim,
      label: 'Уведомления Email',
    },
  ]).filter(Boolean) as Array<{ key: Tab; label: string }>)
    .map((tab) => ({
      ...tab,
      id: `modal_automation_pretrial_tab_${tab.key}`,
    })));

  const commonFields = computed(() => fields.value.filter(
    (field) => (field as any)?.ionFieldGroup?.key === 'common',
  ));

  const tabFields = computed(
    () => tabFieldsMap.value[activeTab.value],
  );

  const tabFieldsMap = computed(() => {
    return tabs.value.reduce((acc: Record<Tab, ActiveFormField<any>[]>, { key }: { key: Tab }) => {
      acc[key] = fields.value.filter(
        (field) => (field as any)?.ionFieldGroup?.key === key,
      ) as ActiveFormField<any>[];
      return acc;
    }, {} as Record<Tab, ActiveFormField<any>[]>);
  });

  return {
    activeTab,
    tabs,
    commonFields,
    tabFields,
    tabFieldsMap,
  };
}
