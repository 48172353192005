import { Ref, computed } from 'vue';
import {
  ActiveTableColumn,
  ActiveTableColumnFormat,
} from '@/components/activeTable/useActiveTable';
import { isOrganization } from '@/components/dialog/dialogs/debtor/isOrganization';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useLocalI18n } from '@/hooks/useLocalI18n';

export function useColumns(isEdit: Ref<boolean>) {
  const { debtor } = useInjectDebtorDialog();
  const { t } = useLocalI18n('debtor.courts');

  return computed(() => (([
    { key: 'index', width: '180px' },
    { key: 'complaint_type', width: '200px' },
    { key: 'document_number', width: '140px' },
    { key: 'case_number', width: '140px' },
    { key: 'writ_number', width: '180px' },
    { key: 'case_id', width: '160px' },
    { key: 'category_name', width: '240px' },
    { key: 'filing_date', format: ActiveTableColumnFormat.date, width: '180px' },
    { key: 'case_consideration_date', format: ActiveTableColumnFormat.date, width: '180px' },
    { key: 'effective_decision_date', format: ActiveTableColumnFormat.date, width: '140px' },
    {
      key: 'full_names',
      field: 'tracker.full_names',
      getValue: (record) => record.tracker?.full_names?.join('\n') || undefined,
      width: '240px',
      label: isOrganization(debtor.value) ? 'Наименование' : 'ФИО должника',
    },
    { key: 'judge_full_name', width: '140px' },
    {
      key: 'sittings',
      width: '440px',
      field: 'sittings',
      getValue: (record) => (record.sittings && record.sittings.length ? record.sittings[record.sittings.length - 1] : null),
    },
    { key: 'current_status', width: '220px' },
    { key: 'status_history', field: 'tracker.history', width: '440px' },
    { key: 'comment', field: 'comment', width: '240px' },
    { key: 'log', field: 'log', width: '140px' },
    { key: 'debt_period', width: isEdit.value ? '380px' : '180px' },
    { key: 'payment_document_number', width: '140px' },
    {
      key: 'payment_document_date',
      format: ActiveTableColumnFormat.date,
      width: '170px',
    },
    { key: 'court_place', width: '180px' },
    { key: 'total_debt', width: '150px', format: ActiveTableColumnFormat.money },
    { key: 'debt', width: '150px', format: ActiveTableColumnFormat.money },
    { key: 'penalty', format: ActiveTableColumnFormat.money },
    { key: 'fee', format: ActiveTableColumnFormat.money },
    { key: 'paid_debt', format: ActiveTableColumnFormat.money },
    { key: 'paid_penalty', format: ActiveTableColumnFormat.money },
    { key: 'paid_fee', format: ActiveTableColumnFormat.money },
  ] as Array<ActiveTableColumn<any> | boolean>)

    .filter(Boolean) as Array<ActiveTableColumn<any>>)

    .map((column) => ({
      ...column,
      field: column.field || column.key,
      label: column.label ?? t(`column.${String(column.key).split('.').pop()}`),
    })));
}
