import { useStore } from 'vuex';
import { ApiCommand, ApiRequest, ApiResponse } from '@/store/modules/api';

export const useApi = () => {
  const store = useStore();
  return <T = any>(request: ApiRequest) => store.dispatch(
    'api/request',
    request,
    { root: true },
  ) as Promise<ApiResponse<T>>;
};
