import { computed, onBeforeUpdate, ref } from 'vue';
import { useResizeObserver } from '@vueuse/core';
import debounce from 'lodash/debounce';
import { ActiveTableColumnStick } from '@/components/activeTable/useActiveTable';

export const useStickyColumnPositions = (
  stickLeftClassName: string,
  stickRightClassName: string,
) => {
  const containerRef = ref<HTMLElement>();
  const columnRefs = ref<HTMLElement[]>([]);
  const columnsOffsetArray = ref<Array<null|{right?: number; left?: number}>>([]);
  const columnsStyleArray = computed(
    () => columnsOffsetArray.value?.map((v) => {
      if (!v) return null;
      if (v.right !== undefined) return { right: `${v.right}px` };
      if (v.left !== undefined) return { left: `${v.left}px` };
      return null;
    }),
  );
  onBeforeUpdate(() => {
    columnRefs.value = [];
  });

  useResizeObserver(containerRef, debounce(() => {
    const rightColumns = columnRefs.value.filter(
      (el) => el?.classList.contains(stickRightClassName),
    );
    const leftColumns = columnRefs.value.filter(
      (el) => el?.classList.contains(stickLeftClassName),
    );
    if (!rightColumns.length && !leftColumns.length) {
      return;
    }
    const rightColumnsStartIndex = rightColumns.length
      ? columnRefs.value.findIndex((el) => el === rightColumns[0])
      : -1;
    const leftColumnEndIndex = leftColumns.length
      ? columnRefs.value.findIndex((el) => el === leftColumns[leftColumns.length - 1])
      : -1;

    const rightColumnsOffsetList = rightColumns.map((el) => {
      const width = el.getBoundingClientRect()?.width;
      return width;
    }).reduceRight((acc, width, i) => {
      if (i === 0) {
        return acc;
      }
      for (let accI = i - 1; accI >= 0; accI--) {
        acc[accI] += width;
      }
      return acc;
    }, new Array(rightColumns.length).fill(0));

    const leftColumnsOffsetList = leftColumns.map((el) => {
      const width = el.getBoundingClientRect()?.width;
      return width;
    }).reduce((acc, width, i, arr) => {
      if (i === arr.length - 1) {
        return acc;
      }
      for (let accI = i + 1; accI < arr.length; accI++) {
        acc[accI] += width;
      }

      return acc;
    }, new Array(leftColumns.length).fill(0));

    columnsOffsetArray.value = columnRefs.value.map((_, i) => {
      if (i <= leftColumnEndIndex) {
        return { left: leftColumnsOffsetList[i] };
      }
      if (i >= rightColumnsStartIndex) {
        return { right: rightColumnsOffsetList[i - rightColumnsStartIndex] };
      }
      return null;
    });
  }));

  return {
    containerRef,
    columnRefs,
    columnsStyleArray,
  };
};
