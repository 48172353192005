import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useModelDialog } from '@/hooks/useModelDialog';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useDocumentsApi } from '@/hooks/useDocumentsApi';
import { ApiCommand } from '@/store/modules/api';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { ProductionType } from '@/hooks/useConstructor';

type DocumentCommon = {
  id: number;
  production_type: ProductionType;
  is_loaded_manually: boolean;
  created_at: Date;
  debtor: number;
  number: string;
  date: string;
  estate_object: null;
  company: number;
  key: string;
}

export type DocumentDto = DocumentCommon & {
  file: string;
}

export type CreateModel = DocumentCommon & {
  file?: File | string | null;
}

export type UpdatePreloadedChargesModel = CreateModel & {
  id: number;
};

export const usePreloadedChargesModel = () => {
  const { debtorId, productionType } = useInjectDebtorDialog();
  const { companyId } = useProtectedDefaultCompany();

  const apiEndpoints = useDocumentsApi();

  const fields = [
    {
      key: 'number',
      field: 'number',
      type: ActiveFormFieldType.input,
      options: {
        label: 'Номер',
      },
    },
    {
      key: 'date',
      field: 'date',
      type: ActiveFormFieldType.date,
      options: {
        serializeValue: true,
        label: 'Дата составления',
      },
    },
    {
      key: 'file',
      field: 'file',
      type: ActiveFormFieldType.file,
      options: {
        label: 'Файл',
      },
    },
  ];

  const modelDialogCreate = useModelDialog<
    CreateModel,
    DocumentDto
  >({
    key: 'add_preloaded_charges',
    title: 'Добавление свода начислений',
    // @ts-ignore
    fields,
    onModelUpdate: async (model) => {
      const errors = [];
      if (!model.file) {
        errors.push(['file', 'Поле обязательно']);
      }

      if (errors.length) {
        return {
          status: false,
          response: errors,
        };
      }

      const recordResponse = await apiEndpoints.fetchUniqueDebtorsDocuments(
        { limit: 1000, page: 1, filters: { debtor_id: debtorId.value, key: 'preloaded_charges' } },
      );

      if (!recordResponse.status) {
        return {
          status: false, response: [['detail', 'неизвестная ошибка']],
        };
      }

      await Promise.all(
        recordResponse.response.results.map(
          ({ id }: any) => apiEndpoints.removeDocument(
            ApiCommand.removeUniqueDebtorsDocument,
            {
              id,
              debtor_id: debtorId.value,
            },
          ),
        ),
      );

      return apiEndpoints.uploadUniqueDebtorsDocument({
        debtor: debtorId.value,
        production_type: productionType.value,
        file: model.file as File,
        number: model.number,
        date: model.date,
        key: 'preloaded_charges',
        estate_object: null,
        company: companyId.value as number,
      });
    },
  });

  const modelDialogUpdate = useModelDialog<
    UpdatePreloadedChargesModel,
    DocumentDto,
    number
  >({
    key: 'edit_preloaded_charges',
    title: 'Редактирование свода начислений',
    // @ts-ignore
    fields,
    getForm: (id) => apiEndpoints.fetchUniqueDebtorsDocument({
      filters: {
        id,
      },
    }, 'preloaded_charges'),
    onModelUpdate: async (model) => {
      const errors = [];
      if (!model.file) {
        errors.push(['file', 'Поле обязательно']);
      }

      if (errors.length) {
        return {
          status: false,
          response: errors,
        };
      }

      const { file, ...rest } = model;

      return apiEndpoints.updateUniqueDebtorsDocument({
        ...rest,
        ...(typeof file === 'string' ? {} : { file }),
        key: 'preloaded_charges',
      }, model!.id);
    },
  });

  return {
    openCreateDialog: modelDialogCreate.showDialog,
    openUpdateDialog: modelDialogUpdate.showDialog,
  };
};
