import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { ref, Ref } from 'vue';
import { ITextInputType } from '@/components/textInput/useTextInput';

export const fields = ref([
  {
    key: 'partner',
    label: 'Партнер',
    type: ActiveFormFieldType.input,
  },
  {
    key: 'historical_bank_name',
    label: 'Историческое название Банка',
    type: ActiveFormFieldType.input,
  },
  {
    key: 'initial_agreement_number',
    label: 'Первоначальный номер договора',
    type: ActiveFormFieldType.input,
  },
  {
    key: 'assignment_number',
    label: 'Номер Цессии',
    type: ActiveFormFieldType.input,
  },
  {
    key: 'assignment_date',
    label: 'Дата Цессии',
    type: ActiveFormFieldType.date,
  },
  {
    key: 'interest_rate',
    label: 'Процентная ставка',
    type: ActiveFormFieldType.input,
    options: { type: ITextInputType.number },
  },
  {
    key: 'credit_limit',
    label: 'Кредитный лимит',
    type: ActiveFormFieldType.input,
  },

].map((item) => {
  return {
    ...item,
    field: item.key,
    state: ['horizontal', 'primary'],
  };
}));
