import { ActionType, ActiveTableAction } from '@/components/activeTable/useActiveTable';
import {
  computed, ref, Ref, watch,
} from 'vue';
import { arrayFrom } from '@/utils/object';

export const useActions = (
  actions: Ref<Array<ActiveTableAction<any, any>>>,
  prefTableActions: Ref<Record<string, any>>,
  includeActions: Ref<Array<ActiveTableAction<any, any>>>,
  selectedItems: Ref<any[]>,
) => {

  const visibleDefaultActions = ref<string[]>([]);

  watch([actions, prefTableActions], () => {
    visibleDefaultActions.value = actions.value?.filter(
      ({ types, key }) => arrayFrom(types).includes(ActionType.default) && (
        !prefTableActions.value || prefTableActions.value[key]
      ),
    ).map(({ key }) => key) || [];
  }, {
    immediate: true,
  });

  const localActions = computed<Array<ActiveTableAction<any, any>>>(() => ([
    ...includeActions.value,
    ...actions.value,
  ]).filter(Boolean) as Array<ActiveTableAction<any, any>>);

  const actionsMap = computed<{
    [key in ActionType]: Array<ActiveTableAction<any, any>>
  }>(() => {
    const map = localActions.value.reduce((acc, { types }) => {
      arrayFrom(types).forEach((type) => {
        // @ts-ignore
        acc[type] = [];
      });
      return acc;
    }, {} as {
      [key in ActionType]: Array<ActiveTableAction<any, any>>
    });

    localActions.value.forEach((action) => {
      arrayFrom(action.types).forEach((type) => {
        map[type as keyof typeof map].push({
          ...action,
          ...(
            action.checkIsDisabled
              ? {
                disabled: action.checkIsDisabled({
                  selectedRecords: selectedItems.value,
                }),
              }
              : {}
          ),
        });
      });
    });

    if (map[ActionType.default]) {
      map[ActionType.default] = map[ActionType.default].filter(
        ({ key: k, group }) => visibleDefaultActions.value.includes(k)
          || (!group || (group === 1.5)),
      );
    }

    return map;
  });

  const actionGroups = computed(
    () => Object.entries(actionsMap.value.default?.reduce((acc, action) => ({
      ...acc,
      // @ts-ignore
      [action.group || 1]: [...acc[action.group || 1] || [], action],
    }), { 1: [] }) || { }),
  );

  return {
    visibleDefaultActions,
    actionsMap,
    actionGroups,
    localActions,
  };
};
