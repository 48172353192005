import { useStore } from 'vuex';
import { onBeforeUnmount } from 'vue';
import { arrayFrom } from '@/utils/object';

export enum SignalType {
  companyAdded = 'companyAdded',
  companyUpdated = 'companyUpdated',
  defaultCompanyIdUpdated = 'defaultCompanyIdUpdated',
  defaultCompanyActiveServicesUpdated = 'defaultCompanyActiveServicesUpdated',
  companyDeleted = 'companyDeleted',
  employeeDeleted = 'employeeDeleted',
  employeeUpdated = 'employeeUpdated',
  employeeCreated = 'employeeCreated',
  accountDocumentCreated = 'accountDocumentCreated',
  accountDocumentUpdated = 'accountDocumentUpdated',
  accountDocumentDeleted = 'accountDocumentDeleted',
  companySettingsUpdated = 'companySettingsUpdated',
  companyFileSettingsUpdated = 'companyFileSettingsUpdated',
  model = 'model',
  modelSuccess = 'modelSuccess',
  modelErrors = 'modelErrors',
  tableSettings = 'tableSettings',
  tableActions = 'tableActions',

  debtorsUpdated = 'debtorsUpdated',
  debtorDocumentsUpdated = 'debtorDocumentsUpdated',
  debtorCourtCasesUpdated = 'debtorCourtCasesUpdated',
  debtorNeighboursFound = 'debtorNeighboursFound',
  debtorCourtUpdated = 'debtorCourtUpdated',
  navigateDebtor = 'navigateDebtor',
  findDebtorNeighbours = 'findDebtorNeighbours',
  getDebtorFilters = 'getDebtorFilters',
  debtorFilters = 'debtorFilters',
  debtorPochtaTracksUpdated = 'debtorPochtaTracksUpdated',
  debtorDialogMounted = 'debtorDialogMounted',
  dashboardLoaded = 'dashboardLoaded',
  debtorAgreementsUpdated = 'debtorAgreementsUpdated',
  debtorCustomTypesUpdated = 'debtorCustomTypesUpdated',

  criticalDataIsLoaded = 'criticalDataIsLoaded',
  userNotFound = 'userNotFound',
  globalPreloaderDataIsLoaded = 'globalPreloaderDataIsLoaded',

  reportingChartDialogModelUpdate = 'reportingChartDialogModelUpdate',
  reportingChartDialogModelResponse = 'reportingChartDialogModelReponse',
  dialogClosed = 'dialogClosed',

  cadNumDialogModelUpdated = 'cadNumDialogModelUpdated',
  updateAvailable = 'updateAvailable',
  cryptoError = 'cryptoError',
  addDialogInRoute = 'addDialogInRoute',
  removeDialogFromRoute = 'removeDialogFromRoute',
  bankAccModelUpdated = 'bankAccModelUpdated',
  bankAccModelCreated = 'bankAccModelCreated',

  integrationServiceConnectionUpdated = 'integrationServiceConnectionUpdated',
  billingUpdated = 'billingUpdated',
  standartizeUpdated = 'standartizeUpdated',
  standartizeTableRefetched = 'standartizeTableRefetched',
  standartizeColorAddressesList = 'standartizeColorAddressesList'
}

export type Signal = {
  type: SignalType;
  payload?: any; // @todo type it
}

type Signals = SignalType | Array<SignalType>

type Payload = Signal['payload'];
type Callback = (payload: Payload) => void;

const shouldCallCallback = (
  type: string,
  payload: Payload,
  signal: Signals,
) => type === 'layout/signal'
    && arrayFrom(signal)
      .includes(payload?.signalType);

export const useSignal = () => {
  const store = useStore();
  const unsubs: Array<() => void> = [];

  onBeforeUnmount(() => {
    unsubs.forEach((unsub) => unsub());
  });

  const dispatchSignal = async (
    signalType: SignalType,
    payload?: Payload,
  ) => {
    store.commit('layout/signal', { signalType, payload });
  };

  const subscribeToSignal = (
    signal: Signals,
    callback: (payload: Payload) => void,
  ): (() => void
) => store.subscribe(({ type, payload }) => {
    if (shouldCallCallback(type, payload, signal)) {
      callback(payload?.payload);
    }
  });

  const subscribeToSignalOnce = (
    signal: Signals,
    callback: (payload: Payload) => void,
  ): void => {
    const unsub = store.subscribe(({
      type, payload,
    }) => {
      if (shouldCallCallback(type, payload, signal)) {
        callback(payload?.payload);
        unsub();
      }
    });
  };

  const awaitSignalResponse = async <T extends any>(
    outSignal: SignalType,
    inSignal: Signals,
    outSignalPayload?: Payload,
  ): Promise<T> => new Promise(async (resolve) => {
    const unsub = subscribeToSignal(
      inSignal,
      (response: T) => {
        resolve(response);
        unsubs.splice(
          unsubs.findIndex((i) => i === unsub, 1),
        );
        unsub();
      },
    );
    unsubs.push(unsub);
    await dispatchSignal(outSignal, outSignalPayload);
  });

  const subscribeUntilUnmount = (signal: Signals, callback: Callback) => {
    onBeforeUnmount(
      subscribeToSignal(signal, callback),
    );
  };

  return {
    subscribeToSignal,
    dispatchSignal,
    awaitSignalResponse,
    subscribeToSignalOnce,
    subscribeUntilUnmount,
  };
};
