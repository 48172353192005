import {
  ref, SetupContext, watch,
} from 'vue';
import {
  CourtType,
} from '@/components/dialog/dialogs/debtor/tabs/courts/types';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand, ApiResponse } from '@/store/modules/api';
import { useToast } from '@/hooks/useToast';
import { useRegions } from '@/pages/exchange/useRegions';
import {
  Court, FetchCourtsRequest, FetchCourtsResponse, useCourts,
} from '@/hooks/useCourts';
import { SignalType, useSignal } from '@/hooks/useSignal';

const mapMain: Record<CourtType, string> = {
  magistrate: 'magistrate_court_place',
  regional: 'regional_court_place',
  arbitration: 'arbitration_court_place',
};

const mapBoolean: Record<CourtType, string> = {
  magistrate: 'use_contractual_magistrate_court_place',
  regional: 'use_contractual_regional_court_place',
  arbitration: 'use_contractual_arbitration_court_place',
};

export function useSelectCourt(emit: SetupContext['emit'] | any, courtType: CourtType, debtorId: number) {
  const { dispatchSignal } = useSignal();
  const { showPureDangerToast, showPureInfoToast } = useToast();
  const { fetchMagistrateCourts, fetchRegionalCourts, fetchArbitrationCourts } = useCourts();
  const courtId = ref<string | null>(null);
  const {
    region,
    regions,
    setRegion,
  } = useRegions();

  const searchCourtOptions = ref<Court[]>([]);

  const fetchCourtsType = (request: FetchCourtsRequest): Record<CourtType, Promise<ApiResponse<FetchCourtsResponse>>> => {
    return {
      magistrate: fetchMagistrateCourts(request),
      regional: fetchRegionalCourts(request),
      arbitration: fetchArbitrationCourts(request),
    };
  };

  watch(region, async (value) => {
    const limit = 11111;
    const page = 1;
    const request: FetchCourtsRequest = { page, limit, filters: { region: value } };
    if (!value) {
      return;
    }
    const result = await fetchCourtsType(request)[courtType];

    if (result.response) {
      searchCourtOptions.value = result.response.results;
    }
  });

  async function save() {
    if (!courtId.value) {
      return showPureDangerToast({ params: { message: 'Необходимо выбрать суд ' } });
    }
    const { status, patch } = await updateDebtorCourt(courtId.value);
    if (status) {
      await dispatchSignal(SignalType.debtorCourtUpdated, { id: courtId.value, patch });
      emit('close');
      return showPureInfoToast({ params: { message: 'Данные сохранены' } });
    }
  }

  async function reset() {
    const { status, patch } = await updateDebtorCourt();
    if (status) {
      await dispatchSignal(SignalType.debtorCourtUpdated, { id: null, patch });
      emit('close', patch);
      return showPureInfoToast({ params: { message: 'Данные удалены' } });
    }
  }

  async function updateDebtorCourt(id?: string) {
    const mainField = mapMain[courtType];
    const boolField = mapBoolean[courtType];
    const patch = id
      ? {
        [mainField]: id,
        [boolField]: true,
      }
      : {
        [boolField]: false,
      };
    const { status } = await commonLegacyApiRequest({
      data: patch,
      command: ApiCommand.updateDebtorMainInfo,
      params: {
        id: debtorId,
      },
    });
    return { status, patch };
  }

  function cancel() {
    emit('cancel');
  }

  return {
    courtId,
    region,
    regions,
    setRegion,
    searchCourtOptions,
    save,
    reset,
    cancel,
  };
}
