import { FilePayload } from '@/hooks/useDialog';
import {
  computed, ref, SetupContext, toRefs, watch,
} from 'vue';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { nString, prepareLinksSimple, useAuthProtectedFile } from '@/hooks/useAuthProtectedFile';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { copyToClipboard } from '@/utils/string';

export type FilePayloadWithDefaults = Omit<FilePayload, 'urlJson'> & {
  urls?: string[];
}

const extensionRgx = /\.([^.]*)$/;

type Item = {
  key: string;
  label: string;
  download: nString;
  view :nString;
}

export const useFileDialog = (props: FilePayloadWithDefaults, emit: SetupContext['emit'] | any) => {
  const { url, urls, reports } = toRefs(props);
  const { t } = useLocalI18n('fileDialog');
  const { showToast } = useToast();

  const selectedOption = ref<string>('pdf');

  const options = ref<Item[]>([]);

  // @ts-ignore
  watch(urls, async (value: string[]) => {
    if (!value.length) return;
    return updateOptions(value);
  }, { immediate: true });

  watch(url, async (value: string) => {
    if (!value) return;
    return updateOptions([value]);
  }, { immediate: true });

  async function updateOptions(values: string[]) {
    const result = await Promise.all(values.map(async (item) => {
      if (item.match(extensionRgx)?.length) {
        const extension = item.match(extensionRgx)![1];
        return {
          key: extension,
          label: extension,
          ...(await prepareLinksSimple(item.replace('.docx', ''))),
          // @todo: might be better
        };
      }
      return {
        key: '', label: '', download: '', view: '',
      };
    }));

    const pdfIndex = result.findIndex(({ key }) => key === selectedOption.value);

    options.value = result;
    selectedOption.value = result[pdfIndex]?.key || result[0].key;
  }

  const selected = computed(() => {
    // @ts-ignore
    return options.value.find((item) => item.key === selectedOption.value)!;
  });

  const downloadUrl = computed(() => selected.value.download);
  const viewUrl = computed(() => selected.value.view);

  const reportUrlPrepared = useAuthProtectedFile(
    computed(() => reports?.value?.[0].url ?? null),
    true,
  );

  const close = async () => {
    await new Promise(requestAnimationFrame);
    emit('close');
  };

  const copy = async () => {
    await copyToClipboard(props.url);
    await showToast({
      message: 'fileDialog.copied',
      level: IToastLevel.success,
    });
    await close();
  };

  return {
    t,
    options,
    selected,
    selectedOption,
    downloadUrl,
    viewUrl,
    reportUrlPrepared,
    close,
    copy,
  };
};
