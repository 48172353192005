import {
  ApiCommand,
  ApiRequest,
  ApiResponse,
  ListingRequestSource,
  ListingResponse,
} from '@/store/modules/api';
import { Bailiff, Debtor } from '@/hooks/useDebtors';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';
import {
  CourtCase,
  CourtCaseTracker,
  CourtCaseFilters,
  CourtTypeTabKey,
} from '@/components/dialog/dialogs/debtor/tabs/courts/types';
import {
  formatListingRequest,
  formatListingResponse
} from '@/components/activeTable/useActiveTable';

export enum CourtType {
  magistrate = 'm',
  regional = 'c'
}

export type Court = {
  id: number;
  region: number;
  created_at: string;
  updated_at: string;
  name: string;
  type: CourtType;
  address: string;
  name_declination: string;
  name_instrumental: string;
  phone_number_secretary: string;
  phone_number_assistant: string;
  phone_number_head_of_dep: string;
  email: string;
  payment_recipient_name: string;
  bic: string;
  payment_recipient_account: string;
  inn: string;
  kpp: string;
  oktmo: string;
  okato: string;
  payment_recipient_bank: string;
  kbk: string;
  link: string;
  magistrate: string;
  chairman: string;
  company_id: string;
}

export type FetchCourtsRequest = ListingRequestSource<Court>;
export type FetchCourtsResponse = ListingResponse<Court>;

export type FetchBailiffsRequest = ListingRequestSource<Bailiff>;
export type FetchBailiffsResponse = ListingResponse<Bailiff>;

export type FetchCasesModel = ListingRequestSource<CourtCaseFilters>

export type FetchCasesResponse = ListingResponse<any>;

export type FetchCourtRequisitesModel = {
  type: CourtTypeTabKey;
  id: Court['id'];
}

export type FetchCourtRequisitesResponse = any;

export type UpdateCourtRequisitesModel = {
  id: Court['id'];
  type: CourtTypeTabKey;
  model: Partial<Court>;
};

export type UpdateCourtRequisitesResponse = any;

export type CreateCourtCaseResponse = CourtCase;
export type UpdateCourtCaseResponse = CourtCase;
export type CreateCourtCaseTrackerResponse = CourtCaseTracker;
export type UpdateCourtCaseTrackerResponse = CourtCaseTracker;

export const useCourts = () => {

  const fetchMagistrateCourts = async (
    model: FetchCourtsRequest,
  ): Promise<ApiResponse<FetchCourtsResponse>> => {

    const { status, response } = await commonLegacyApiRequest({
      command: ApiCommand.fetchMagistrateCourts,
      params: formatListingRequest(model),
    } as ApiRequest<FetchCourtsResponse>);

    return {
      status,
      response: formatListingResponse(response),
    };
  };

  const fetchRegionalCourts = async (
    model: FetchCourtsRequest,
  ): Promise<ApiResponse<FetchCourtsResponse>> => {

    const { status, response } = await commonLegacyApiRequest({
      command: ApiCommand.fetchRegionalCourts,
      params: formatListingRequest(model),
    } as ApiRequest<FetchCourtsResponse>);

    return {
      status,
      response: formatListingResponse(response),
    };
  };

  const fetchArbitrationCourts = async (
    model: any,
  ): Promise<ApiResponse<FetchCourtsResponse>> => {

    const { status, response } = await commonLegacyApiRequest({
      command: ApiCommand.fetchArbitrationCourts,
      params: formatListingRequest(model),
    } as ApiRequest<any>);

    return {
      status,
      response: formatListingResponse(response),
    };
  };

  const fetchBailiffs = async (
    model: FetchBailiffsRequest,
  ): Promise<ApiResponse<FetchBailiffsResponse>> => {

    const { status, response } = await commonLegacyApiRequest({
      command: ApiCommand.fetchBailiffs,
      params: formatListingRequest(model),
    } as ApiRequest<FetchBailiffsRequest>);

    return {
      status,
      response: formatListingResponse(response),
    };
  };

  const fetchCourtCases = async (
    model: FetchCasesModel,
  ): Promise<ApiResponse<FetchCasesResponse>> => {

    const { status, response } = await commonLegacyApiRequest({
      command: ApiCommand.fetchCourtCases,
      params: formatListingRequest(model),

    } as ApiRequest<FetchBailiffsRequest>);

    return {
      status,
      response: formatListingResponse({
        ...response,
        results: response.results.map((item: any) => ({
          ...item,
          receipt_date: item.receipt_date
            ? new Date(item.receipt_date)
            : null,
          case_consideration_date: item.case_consideration_date
            ? new Date(item.case_consideration_date)
            : null,
          effective_decision_date: item.effective_decision_date
            ? new Date(item.effective_decision_date)
            : null,
        })),
      }),
    };
  };

  const fetchCourtCaseDetails = async (
    id: number,
  ): Promise<ApiResponse<any>> => {
    const { status, response } = await commonLegacyApiRequest({
      command: ApiCommand.fetchCourtCaseDetails,
      params: { id },
    } as ApiRequest<any>);
    return {
      status,
      response,
    };
  };

  const updateCourtCaseDetails = async (
    id: number,
    data: any,
  ): Promise<ApiResponse<any>> => {
    return commonLegacyApiRequest({
      command: ApiCommand.updateCourtCaseDetails,
      params: { id },
      data,
    } as ApiRequest<any>);
  };

  const updateCourtCasesByDebtor = async (
    debtor: number,
  ): Promise<ApiResponse<null>> => {

    return commonLegacyApiRequest({
      command: ApiCommand.updateCourtCaseByDebtor,
      data: { debtor },
    } as ApiRequest<{ debtor: number }>);

  };

  const fetchDebtorCourtApplicationsTracker = async (
    model: FetchCasesModel,
  ): Promise<ApiResponse<ListingResponse<any>>> => {

    const { status, response } = await commonLegacyApiRequest({
      command: ApiCommand.fetchDebtorCourtApplicationTracker,
      params: formatListingRequest(model),
    });

    return {
      status,
      response,
    };
  };

  const fetchCourtRequisites = async (
    model: FetchCourtRequisitesModel,
  ): Promise<ApiResponse<FetchCourtRequisitesResponse>> => {

    const { status, response } = await commonLegacyApiRequest({
      command: ApiCommand.fetchCourtRequisites,
      params: model,
    } as ApiRequest<FetchCourtRequisitesResponse>);

    return {
      status,
      response,
    };
  };

  const updateCourtRequisites = async (
    model: UpdateCourtRequisitesModel,
  ): Promise<ApiResponse<UpdateCourtRequisitesResponse>> => {

    const { status, response } = await commonLegacyApiRequest({
      command: ApiCommand.updateCourtRequisites,
      params: {
        type: model.type,
        id: model.id,
      },
      data: model.model,
    } as ApiRequest<UpdateCourtRequisitesResponse>);

    return {
      status,
      response,
    };
  };

  const removeCourtCase = async (
    id: number,
  ) => commonLegacyApiRequest<undefined>({
    command: ApiCommand.removeCourtCase,
    params: {
      id,
    },
  });

  const createCourtCase = async (
    data: Partial<CourtCase>,
  ): Promise<ApiResponse<CreateCourtCaseResponse>> => commonLegacyApiRequest({
    command: ApiCommand.createCourtCase,
    data,
  });

  const updateCourtCase = async (
    data: Partial<CourtCase>,
    id: number,
  ): Promise<ApiResponse<UpdateCourtCaseResponse>> => commonLegacyApiRequest({
    command: ApiCommand.updateCourtCase,
    params: { id },
    data,
  });

  const createCourtCaseTracker = async (
    data: Partial<CourtCaseTracker>,
  ): Promise<ApiResponse<CreateCourtCaseTrackerResponse>> => commonLegacyApiRequest({
    command: ApiCommand.createCourtCaseTracker,
    data,
  });

  const updateCourtCaseTracker = async (
    data: Partial<CourtCaseTracker>,
    id: string,
  ): Promise<ApiResponse<UpdateCourtCaseTrackerResponse>> => commonLegacyApiRequest({
    command: ApiCommand.updateCourtCaseTracker,
    params: { id },
    data,
  });

  return {
    fetchMagistrateCourts,
    fetchRegionalCourts,
    fetchArbitrationCourts,
    fetchBailiffs,
    fetchCourtCases,
    fetchCourtRequisites,
    fetchCourtCaseDetails,
    updateCourtCaseDetails,
    updateCourtRequisites,
    updateCourtCasesByDebtor,
    removeCourtCase,
    fetchDebtorCourtApplicationsTracker,
    createCourtCase,
    updateCourtCase,
    createCourtCaseTracker,
    updateCourtCaseTracker,
  };
};
