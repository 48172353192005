export const fileToBlob = async (file: File) => new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });

export const saveSignatureToFile = (sign: string, name: string) => new File(
  [
    new Blob([sign],
      { type: 'text/plain;charset=utf-8' }),
  ],
  name,
  {
    lastModified: new Date().getTime(),
    type: 'override/mimetype',
  },
);

export function downloadTextFile(filename: string, text: string) {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export const PLUGIN_EXECUTION_ERROR_MESSAGE = 'Ошибка исполнения функции';
