import { getApiRequest } from '@core/service/serviceConstructor';
import { apiUrl } from '@/utils/env';
import { apiCommands, ApiRequest } from '@/store/modules/api';
import { apiCommands as reportingApiCommands } from '@/service/api/reporting/api';
import { ApiResponseError, ApiResponseSuccess } from '@/service/api';

export const publicApiRequest = getApiRequest({
  baseUrl: apiUrl,
});

export const legacyApiPublicRequest = async <Suc, Err extends any = any>(request: ApiRequest) => {
  // @ts-ignore
  const command = apiCommands[request.command] || reportingApiCommands[request.command];
  const response = await publicApiRequest<Suc, Err>({
    headers: request.headers,
    url: command.url,
    method: command.method.toUpperCase(),
    data: request.data,
    params: request.params,
    asFormData: request.headers?.['Content-Type'] === 'multipart/form-data',
    signal: request.signal,
  })();

  if (response._tag === 'Right') {
    return { status: true, response: response.right } as ApiResponseSuccess<Suc>;
  }
  return { status: false, response: response.left } as ApiResponseError;
};
