import { TileLine, TileRenderParameters } from '@/service/api/reporting/tile';
import { isEqual } from '@/utils/object';
import { tileLineModelDefaultFilters } from '@/components/forms/form/reportingChartForm/new/default-filters';
import {
  ReportingDataModelConfig,
  ReportingDataModelSchema,
} from '@/service/api/reporting/dataModelConfig';
import { ReportingChartFormFilter } from '@/components/forms/form/reportingChartForm/filters';
import { groupLines } from '@/components/forms/form/reportingChartForm/form';
import {
  ChartFormField,
  getTileLineChartFormField, getTileLineChartFormFieldObject,
} from '@/components/forms/form/reportingChartForm/new/fields';

export type ExtendedFilterValue = {
  label: string;
  key: string;
  value: {
    group_by?: string|null;
    model?: {
      excludes?: Record<string, any>;
      filters?: Record<string, any>;
    };
  };
}

export type ExtendedFilterApplyValue = {
  filterKey: ExtendedFilter['key'];
  valueKey: ExtendedFilterValue['key'];
}

export type ExtendedFilterValueModelKeys = Array<keyof ExtendedFilterValue['value']['model']>

export type ExtendedFilter = {
  label: string;
  type: ('filter'|'group-by')[];
  key: string;
  modelsToApply: string[];
  excludeTypes?: string[];
  values: ExtendedFilterValue[];
}

export const TILE_LINE_EXTENDED_FILTERS: ExtendedFilter[] = [
  {
    label: 'Наличие подсудности',
    type: ['filter', 'group-by'],
    key: 'extended-jurisdiction',
    modelsToApply: ['debtor_data'],
    values: [
      {
        label: 'Получена',
        key: 'has',
        value: {
          model: {
            filters: {
              debtor_main_profile__regional_court_place__id__isnull: true,
              debtor_main_profile__magistrate_court_place__id__isnull: true,
            },
          },
        },
      },
      {
        label: 'Отсутствует',
        key: 'has-not',
        value: {
          model: {
            excludes: {
              debtor_main_profile__regional_court_place__id__isnull: true,
              debtor_main_profile__magistrate_court_place__id__isnull: true,
            },
          },
        },
      },
    ],
  },
  {
    label: 'Разбивка по городам',
    type: ['group-by'],
    key: 'extended-cities',
    excludeTypes: ['timeline', 'indicator'],
    modelsToApply: ['debtor_data'],
    values: [
      {
        label: 'По городам',
        key: 'cities',
        value: {
          group_by: 'debtor_main_profile__addr_st__readable_city',
        },
      },
    ],
  },
];

const applyFilterToLineKey = (key: TileLine['key'], filterValue: string) => {
  const splitted = key.split('___');
  if (splitted.length === 1) return `title${filterValue}___${key}`;
  const [prefix, name] = splitted;
  const prefixPrep = `${prefix}-title${filterValue}`;
  return `${prefixPrep}___${name}`;
};

const isNeedToApplyFilterValueToLineKey = (
  filterValue: ExtendedFilterValue,
) => filterValue.value?.group_by;

export const applyExtendedFilterToLine = (
  line: TileLine,
  filter: ExtendedFilter,
  value: ExtendedFilterValue,
): TileLine => ({
  ...line,
  key: isNeedToApplyFilterValueToLineKey(value)
    ? applyFilterToLineKey(line.key, '*p')
    : line.key,
  group_by: value.value.group_by || null,
  models: line.models.map((model) => {
    if (!filter.modelsToApply.includes(model.name)) {
      return model;
    }
    return {
      ...model,
      excludes: { ...(model.excludes || {}), ...(value.value.model?.excludes || {}) },
      filters: { ...(model.filters || {}), ...(value.value.model?.filters || {}) },
    };
  }),
});

export const getLineAppliedExtendedFilters = (
  line: TileLine,
) => TILE_LINE_EXTENDED_FILTERS.map((ef) => {
  const filterModels = ef.modelsToApply.map(
    (model) => line.models.find((m) => m.name === model),
  );
  const hasFilterModels = filterModels.some((v) => !!v);
  if (!hasFilterModels) return null;
  const filteredModels = filterModels as TileLine['models'];
  const found = ef.values.find(
    (filterValue) => {
      const { group_by, model } = filterValue.value;

      const modelMatched = model && (['excludes', 'filters'] as ExtendedFilterValueModelKeys).every(
        (filterValueKey) => Object.entries(model[filterValueKey] || {}).every(
          ([filterName, filterValue]) => filteredModels.every(
            (filterModel) => (filterModel[filterValueKey] || {})[filterName] === filterValue,
          ),
        ),
      );

      const groupByMatched = group_by && group_by === line.group_by;

      if (!model) {
        return groupByMatched;
      }
      if (!group_by) {
        return modelMatched;
      }
      return groupByMatched && modelMatched;
    },
  );
  if (!found) return null;
  return {
    filterKey: ef.key,
    valueKey: found.key,
  };
}).filter(Boolean) as ExtendedFilterApplyValue[];

export const unapplyExtendedFilters = (
  line: TileLine,
  filters: ExtendedFilterApplyValue[],
) => filters.reduce((lineAcc, f) => {
  const filter = TILE_LINE_EXTENDED_FILTERS.find(({ key }) => key === f.filterKey);
  if (!filter) return lineAcc;
  const value = filter?.values.find((v) => v.key === f.valueKey);
  if (!value) return lineAcc;

  if (value.value.group_by) {
    delete lineAcc.group_by;
  }

  lineAcc.models.forEach((model) => {
    if (filter.modelsToApply.includes(model.name)) {
      if (model.filters) {
        if (value.value.model?.filters) {
          Object.keys(value.value.model?.filters).forEach((k) => {
            // @ts-ignore
            delete model.filters[k];
          });
        }
      }
      if (model.excludes) {
        if (value.value.model?.excludes) {
          Object.keys(value.value.model?.excludes).forEach((k) => {
            // @ts-ignore
            delete model.excludes[k];
          });
        }
      }
    }
  });

  return lineAcc;
}, JSON.parse(JSON.stringify(line)) as TileLine);

export const unapplyDefaultFilters = (
  line: TileLine,
) => ({
  ...line,
  models: line.models.map((model) => {
    const defaultFilters = tileLineModelDefaultFilters[model.name];
    if (!defaultFilters) {
      return model;
    }
    if (defaultFilters.filters && model.filters) {
      Object.keys(defaultFilters.filters).forEach((k) => {
        // @ts-ignore
        delete model.filters[k];
      });
    }
    if (defaultFilters.excludes && model.excludes) {
      Object.keys(defaultFilters.excludes).forEach((k) => {
        // @ts-ignore
        delete model.excludes[k];
      });
    }
    return model;
  }),
}) as TileLine;

// TODO сделать это для excludes
// TODO баг если у двух моделей есть фильтры с одинаковыми названиями
// TODO возможно нужны available_models
export const getLineCommonFilters = (
  line: TileLine,
  // models: ReportingDataModelSchema[],
) => line.models.reduce((acc, model, i, models) => {
  if (model.filters) {
    Object.entries(model.filters).forEach(([key, value]) => {
      const isLastModelFilter = key.startsWith('v__');
      const keyPrepared = key.replace('__exact', '');
      const keyFinal = isLastModelFilter
        ? line.field
        : keyPrepared.replace('v__', '');
      const targetModel = isLastModelFilter
        ? models[models.length - 1].name
        : model.name;
      acc.push({
        key: keyFinal,
        model: targetModel,
        value,
      });
      return acc;
    });
  }
  return acc;
}, [] as { key: string; model: string; value: any }[]);

export type FormLineFilter = {
  key: string;
  value: any;
  type: 'extended';
} | {
  key: string;
  value: any;
  type: 'common';
  model: string;
};

export type LineGroupLine = {
  name: string;
  field: string;
  filters: { key: string; value: any }[];
}

// если мы выбрали несколько статусов и хотим показать их процент - дополнительно надо запрашивать
// линию без фильтров
export type LineGroup = {
  name: string;
  show_percent: boolean;
  group_by?: string|null;
  filters: { key: string; value: any }[];
  lines: LineGroupLine[];
  additional_field?: string;
}

export const getLineFilters = (
  line: TileLine,
) => {
  const lineExtendedFilters = getLineAppliedExtendedFilters(line);
  const unappliedExtendedFilters = unapplyExtendedFilters(line, lineExtendedFilters);
  const unappliedDefaultFilters = unapplyDefaultFilters(unappliedExtendedFilters);
  const lineCommonFilters = getLineCommonFilters(unappliedDefaultFilters);
  return [
    ...lineExtendedFilters.map((f) => ({
      key: f.filterKey,
      value: f.valueKey,
      type: 'extended',
    }) as FormLineFilter),
    ...lineCommonFilters.map(({ key, value, model }) => ({
      key, value, type: 'common', model,
    }) as FormLineFilter),
  ];
};

export const prepareLineFiltersToForm = (
  filters: FormLineFilter[],
) => filters.map(
  (filter) => (filter.type === 'extended'
    ? { key: filter.key, value: filter.value }
    : { key: `${filter.model}__${filter.key}`, value: filter.value }),
);

export const getGroupedLines = (
  lines: TileLine[],
  availableModels: ReportingDataModelConfig[],
  renderParams: TileRenderParameters,
  formFields: ChartFormField[],
) => {
  const getFilterGroupBy = (f: FormLineFilter): Record<string, any>|false => {
    if (f.type === 'extended') {
      const filter = TILE_LINE_EXTENDED_FILTERS.find(
        ({ key }) => key === f.key,
      );
      if (!filter) {
        throw Error('extended filter not found');
      }
      if (!filter.type.includes('group-by')) {
        return false;
      }
      return Object.fromEntries(
        filter.values.map((f) => ([f.key, f.value])),
      );
    }
    const schema = availableModels.find(({ name }) => name === f.model)?.schema;
    if (!schema) {
      throw Error('schema not found');
    }
    const filter = schema.fields[f.key];
    if (!filter) {
      throw Error('filter not found');
    }
    return filter.enum || false;
  };

  const { mainLines, additionalLines } = lines.reduce((acc, line) => {
    const lineRenderParams = renderParams.lines[line.key];
    if (lineRenderParams?.additional_to_group) {
      acc.additionalLines.push(line);
    } else {
      acc.mainLines.push(line);
    }
    return acc;
  }, { mainLines: [], additionalLines: [] } as { mainLines: TileLine[]; additionalLines: TileLine[] });
  const additionalFormFields = additionalLines.map((line) => getTileLineChartFormFieldObject(line, formFields));
  console.log('additionalFormFields', additionalFormFields);
  const groupedLines = groupLines(mainLines);
  return Object.entries(groupedLines).map(([rawKey, lines]) => {
    const noEntireLines = lines.filter((line) => !line.entire);
    const showPercent = lines.some((line) => line.entire);

    const { lineFilters, filtersMap } = noEntireLines
      .reduce((acc, line) => {
        const lineFilters = getLineFilters(line.line);
        acc.lineFilters.push({
          lineKey: line.line.key,
          filters: lineFilters,
        });
        lineFilters.forEach((filter) => {
          const filterKey = filter.type === 'extended' ? filter.key : `${filter.model}__${filter.key}`;
          if (!acc.filtersMap[filterKey]) {
            acc.filtersMap[filterKey] = [];
          }
          acc.filtersMap[filterKey].push({
            line: line.line,
            filterData: filter,
          });
        });
        return acc;
      }, { lineFilters: [], filtersMap: {} } as {
      lineFilters: {
        lineKey: string;
        filters: FormLineFilter[];
      }[];
      filtersMap: Record<string, { line: TileLine; filterData: FormLineFilter }[]>;
    });

    const groupByFilters = Object.values(filtersMap).reduce(
      (acc, [{ filterData }]) => {
        const gb = getFilterGroupBy(filterData);
        if (gb) {
          acc[filterData.type === 'common' ? `${filterData.model}__${filterData.key}` : filterData.key] = gb;
        }
        return acc;
      }, {} as Record<string, Record<string, any>>,
    );
    if (Object.keys(groupByFilters).length) {
      const groupByKey = Object.entries(groupByFilters).reduce((acc, [filterKey, fMap]) => {
        const currentFilters = filtersMap[filterKey];
        if (!currentFilters) return null;
        const filterValues = Object.keys(fMap);
        const isGroupedByCurrentLine = currentFilters.length === filterValues.length
          && filterValues.every(
            (filterValue) => currentFilters.find(
              ({ filterData }) => filterData.value === filterValue,
            ),
          );
        if (isGroupedByCurrentLine) {
          return filterKey;
        }
        return acc;
      }, null as string|null);

      const restFilters = getLineFilters(noEntireLines[0].line).filter(
        ({ key }) => key !== groupByKey,
      );
      const line = getTileLineChartFormField(
        noEntireLines[0].line,
        formFields,
      );

      const fieldObject = getTileLineChartFormFieldObject(
        noEntireLines[0].line,
        formFields,
      );

      const additionalFormField = additionalFormFields.find(
        (fieldData) => (fieldObject?.additionalLine
          ? fieldObject.additionalLineOptions?.find((opt) => opt.value === fieldData?.key)
          : false),
      );

      console.log('additionalFormField 999', additionalFormField);

      if (groupByKey) {
        return {
          name: '',
          show_percent: showPercent,
          group_by: groupByKey,
          filters: restFilters,
          lines: [{ name: '', field: line as string, filters: [] }],
          ...(additionalFormField ? { additional_field: additionalFormField.key } : { }),
        } as LineGroup;
      }
    }

    let groupName;
    let additionalFormField: ChartFormField|undefined;
    const finalLines = noEntireLines.map((l) => {
      const fieldKey = getTileLineChartFormField(
        l.line,
        formFields,
      );
      const fieldObject = getTileLineChartFormFieldObject(
        l.line,
        formFields,
      );
      additionalFormField = additionalFormFields.find(
        (fieldData) => (fieldObject?.additionalLine ? fieldObject.additionalLineOptions?.find((el) => el.value === fieldData?.key) : false),
      );
      console.log('additionalFormField', fieldObject, additionalFormField, additionalFormFields);
      const filters = getLineFilters(l.line);
      const lineRenderParams = renderParams.lines[l.line.key];
      groupName = lineRenderParams?.groupName ?? '';
      const name = lineRenderParams?.name ?? '';
      // lineFilters.find(
      //   (line) => line.lineKey === l.line.key,
      // )?.filters;
      return {
        field: fieldKey,
        name,
        filters: (filters || []), // .map((f) => ({ key: f.key, value: f.value })),
      };
    });

    return {
      name: groupName ?? '',
      show_percent: showPercent,
      filters: [],
      lines: finalLines,
      ...(additionalFormField ? { additional_field: additionalFormField.key } : { }),
    };
  });
};

export const prepareGroupedLines = (lineGroup: LineGroup) => ({
  ...lineGroup,
  // @ts-ignore
  filters: prepareLineFiltersToForm(lineGroup.filters || []),
  lines: lineGroup.lines.map((line) => ({
    ...line,
    // @ts-ignore
    filters: prepareLineFiltersToForm(line.filters || []),
  })),
});

const lines1 = [
  {
    key: 'group1-part1___status',
    field: 'status',
    models: [
      {
        name: 'debtor',
        function: 'count',
      },
      {
        name: 'debtor_data',
        filters: {
          status: 'active',
        },
        excludes: {
          debtor_main_profile__regional_court_place__id__isnull: true,
          debtor_main_profile__magistrate_court_place__id__isnull: true,
        },
        function: 'last',
        order_by: [
          'created_at',
        ],
      },
    ],
    split_by: {
      split_stop: 'created_at',
      split_start: 'created_at',
      split_stop_null: false,
      split_start_null: false,
    },
  },
  {
    key: 'group1-part2___status',
    field: 'status',
    models: [
      {
        name: 'debtor',
        function: 'count',
      },
      {
        name: 'debtor_data',
        filters: {
          status: 'active',
          debtor_main_profile__regional_court_place__id__isnull: true,
          debtor_main_profile__magistrate_court_place__id__isnull: true,
        },
        function: 'last',
        order_by: [
          'created_at',
        ],
      },
    ],
    split_by: {
      split_stop: 'created_at',
      split_start: 'created_at',
      split_stop_null: false,
      split_start_null: false,
    },
  },
] as TileLine[];

const lines2 = [
  {
    key: 'group1-entire___status',
    field: 'status',
    models: [
      {
        name: 'debtor',
        function: 'count',
      },
      {
        name: 'debtor_data',
        filters: {
          status: 'active',
        },
        function: 'last',
        order_by: [
          'created_at',
        ],
      },
    ],
    split_by: {
      split_stop: 'created_at',
      split_start: 'created_at',
      split_stop_null: false,
      split_start_null: false,
    },
  },
  {
    key: 'group1-part1___status',
    field: 'status',
    models: [
      {
        name: 'debtor',
        function: 'count',
      },
      {
        name: 'debtor_data',
        filters: {
          production_type: 'pretrial',
          status: 'active',
        },
        function: 'last',
        order_by: [
          'created_at',
        ],
      },
    ],
    split_by: {
      split_stop: 'created_at',
      split_start: 'created_at',
      split_stop_null: false,
      split_start_null: false,
    },
  },
  {
    key: 'group1-part2___status',
    field: 'status',
    models: [
      {
        name: 'debtor',
        function: 'count',
      },
      {
        name: 'debtor_data',
        filters: {
          status: 'active',
          production_type: 'judicial',
        },
        function: 'last',
        order_by: [
          'created_at',
        ],
      },
    ],
    split_by: {
      split_stop: 'created_at',
      split_start: 'created_at',
      split_stop_null: false,
      split_start_null: false,
    },
  },
  {
    key: 'group1-part3___status',
    field: 'status',
    models: [
      {
        name: 'debtor',
        function: 'count',
      },
      {
        name: 'debtor_data',
        filters: {
          status: 'active',
          production_type: 'executive',
        },
        function: 'last',
        order_by: [
          'created_at',
        ],
      },
    ],
    split_by: {
      split_stop: 'created_at',
      split_start: 'created_at',
      split_stop_null: false,
      split_start_null: false,
    },
  },
] as TileLine[];
