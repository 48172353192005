import {
  getProgressPageRemainingTile,
  getProgressPageSpeed,
} from '@/pages/admin/standartize/main/utils';
import { StandartizeProgressTask } from '@/store/modules/tasksProgress';
import { Progress, ProgressMap } from '@/store/modules/standartize/types';

export function getProgressBars(task: StandartizeProgressTask, map: ProgressMap) {

  const progressbars = [];

  if (task.standartizeTaskUUID) {
    const progress = map.standartizeTaskUUID;
    progressbars.push(progressBar('standartize_progress', 'Адрес', progress));
  }
  if (task.saveStandartizeTaskUUID) {
    const progress = map.saveStandartizeTaskUUID;
    progressbars.push(progressBar('save_standartize_progress', 'Сохранение прогресса', progress));
  }
  if (task.cadnumTaskUUID) {
    const progress = map.cadnumTaskUUID;
    progressbars.push(progressBar('cadnum_progress', 'Адрес', progress));
  }

  return progressbars;
}

function progressBar(key: string, messageStart: string, progress: Progress) {
  return {
    key,
    message: `${messageStart} ${progress.current_progress} из ${progress.max_progress}${secondLine(progress)}`,
    max: progress.max_progress,
    current: progress.current_progress,
    showMessageInsteadOfLabel: true,
  };
}

function secondLine(progress: Progress) {
  if (!progress.remaining_time && !progress.speed) {
    return '';
  }
  return `\n${getProgressPageRemainingTile(progress.remaining_time)}${getProgressPageSpeed(progress.speed)}`;
}
