import { h, VNode } from 'vue';

export const renderPlateGrid = (gridStyle: string, childrenGridStyle: string[], body: VNode[]) => h('div', {
  style: `${gridStyle}; flex-grow: 1`,
  class: 'plate-grid',
}, body.map((b, i) => h(
  'div', {
    style: `${childrenGridStyle[i]}; display: flex; justify-content: center; align-items: center`,
  }, [b],
)));
