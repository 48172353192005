// @ts-nocheck forms
import { ReportingChartFormModel } from '@/components/forms/form/reportingChartForm/form';
import {
  Tile, TileLine,
  TileParameters,
  TileParametersModel,
  TileRenderParameters, TileRenderParametersLineConfig,
} from '@/service/api/reporting/tile';
import { chartIntervals } from '@/components/forms/form/reportingChartForm/new/intervals';
import { ReportingDataModelConfig } from '@/service/api/reporting/dataModelConfig';
import {
  applyExtendedFilterToLine,
  LineGroupLine,
  TILE_LINE_EXTENDED_FILTERS,
} from '@/components/forms/form/reportingChartForm/new/filters';
import { ChartFormField, chartFormFields } from '@/components/forms/form/reportingChartForm/new/fields';
import {
  filtersToModelApply,
  tileLineModelDefaultFilters,
} from '@/components/forms/form/reportingChartForm/new/default-filters';
import { getRandomString } from '@/utils/string';
import { PickedColorsModel } from '@/components/forms/form/reportingChartForm/useColorPicker';

export const formToTile = (
  form: ReportingChartFormModel,
  availableModels: ReportingDataModelConfig[],
  chartColors: PickedColorsModel,
) => {
  let formatter; let start; let
    interval;
  const timeInterval = form.timeInterval
    ? chartIntervals.find((v) => v.key === form.timeInterval)
    : null;

  const parameters = {} as TileParameters;

  if (form.use_not_for_current_company) {
    if (form.all_companies) {
      parameters.all_companies = true;
    } else {
      parameters.companies = form.companies;
    }
  }

  const [plate_indicator_type, plate_indicator_variant] = form.variant?.split('--')
    ?? [undefined, undefined];
  const line_variant_default = form.seriesVariant;
  const render_parameters = {
    type: form.type,
    plate_indicator_type,
    plate_indicator_variant,
    line_variant_default,
    show_percent: form.lineGroups.some((v) => v.show_percent),
    show_timeline_percent: form.show_timeline_percent,
    show_growth: form.show_growth,
    stack_group: form.stack_group,
  } as TileRenderParameters;

  if (timeInterval) {
    formatter = timeInterval.formatter;
    if (formatter) {
      render_parameters.custom_date_formatter = formatter;
    }
    start = timeInterval.value.start;
    if (start) {
      parameters.start = start;
    }
    interval = timeInterval.value.interval;
    if (interval) {
      parameters.interval = interval;
      if (interval.days) {
        parameters.round_dates = 'day';
      } else if (interval.months) {
        parameters.round_dates = 'month';
      }
    }
  }

  const lineGroups = form.lineGroups.map((g, groupIndex) => {
    const lineKey = getRandomString(7);
    const lines = [];
    const renderParamsLinesMap = {} as Record<string, TileRenderParametersLineConfig>;

    const additionalFieldData = g.additional_field && JSON.parse(
      JSON.stringify(chartFormFields.find((field) => field.key === g.additional_field)),
    ) as ChartFormField ?? undefined;

    console.log('additionalFieldData', additionalFieldData);

    const applyDefaultFiltersToTileLine = (tl: TileLine) => ({
      ...tl,
      models: tl.models.map((model) => {
        const modelDefaultFilters = tileLineModelDefaultFilters[model.name]?.filters;
        if (modelDefaultFilters) {
          return {
            ...model,
            filters: {
              ...(model.filters || {}),
              ...(modelDefaultFilters),
            },
          };
        }
        return { ...model };
      }),
    });
    const applyFormFiltersToTileLine = (
      tl: TileLine, filters: { key: string; value: string }[],
    ) => {
      let final = tl;
      console.log('applyFormFiltersToTileLine zzz', tl, filters);
      filters.forEach(({ key, value }) => {
        if (key.startsWith('extended-')) {
          const filterData = TILE_LINE_EXTENDED_FILTERS.find((f) => f.key === key);
          const filterValue = filterData.values.find((v) => v.key === value);
          final = applyExtendedFilterToLine(
            tl,
            filterData,
            filterValue,
          );
          return;
        }
        const [filterName, ...modelNames] = key.split('__').reverse();
        const firstModelName = modelNames[modelNames.length - 1];
        const modelName = modelNames[0];
        const filterHasManyKeys = modelNames.length > 1;
        // применить фильтр к последней модели через v__exact запихнув его в первуж модель
        // если моделей несколько
        const isApplyToModelField = tl.field === filterName
          && tl.models.length > 1 && tl.models[tl.models.length - 1].name === modelName;
        const filterKey = isApplyToModelField ? 'v__exact' : `${filterName}__exact`;
        console.log('applyFormFiltersToTileLine', tl, key, value, firstModelName, modelName);
        final = {
          ...tl,
          models: tl.models.map((model, i) => ({
            ...model,
            filters: {
              ...model.filters,
              ...(filtersToModelApply[filterName]?.includes(model.name)
                ? { [filterName]: value }
                : (
                  isApplyToModelField
                    ? (i === 0 ? { [filterKey]: value } : {})
                    : (firstModelName === model.name ? { [filterHasManyKeys ? key : filterName]: value } : {})
                )
              ),
            },
          })),
        };
      });
      return final;
    };

    if (g.show_percent && !g.group_by) {
      const entireFormLine = JSON.parse(JSON.stringify(g.lines[0])) as LineGroupLine;
      delete entireFormLine.filters;
      const foundTileData = chartFormFields.find(
        (field) => field.key === entireFormLine.field,
      );
      const tileLineData = JSON.parse(
        JSON.stringify(foundTileData),
      );
      const { valueType } = tileLineData;
      let tileLine = tileLineData.line;
      tileLine.key = `group${groupIndex + 1}-entire___${lineKey}`;
      tileLine = applyDefaultFiltersToTileLine(tileLine);
      const entireLine = applyFormFiltersToTileLine(tileLine, g.filters);
      lines.push(entireLine);
      // if (g.show_percent) {
      //   render_parameters.show_percent = true;
      // }
      renderParamsLinesMap[tileLine.key] = {
        groupName: g.name,
        key: tileLine.key,
        value_type: valueType,
      };
    }
    console.log('ggg', g);
    if (g.group_by) {
      const baseLine = JSON.parse(JSON.stringify(g.lines[0])) as LineGroupLine;
      delete baseLine.filters;
      const tileLineData = JSON.parse(
        JSON.stringify(chartFormFields.find((field) => field.key === baseLine.field)),
      );
      const { valueType } = tileLineData;
      let tileLine = tileLineData.line;
      tileLine = applyDefaultFiltersToTileLine(tileLine);
      tileLine = applyFormFiltersToTileLine(tileLine, g.filters);
      if (g.group_by.startsWith('extended-')) {
        const groupFilter = TILE_LINE_EXTENDED_FILTERS.find((f) => f.key === g.group_by);
        groupFilter.values.forEach((value, i) => {
          let groupPartLine = JSON.parse(
            JSON.stringify(tileLine),
          );
          groupPartLine.key = `group${groupIndex + 1}-part${i + 1}___${lineKey}`;
          groupPartLine = applyExtendedFilterToLine(
            groupPartLine,
            groupFilter,
            value,
          );
          lines.push(groupPartLine);
          renderParamsLinesMap[lineKey] = {
            groupName: g.name,
            key: lineKey,
            name: `${baseLine.name}`,
            value_type: valueType,
          };
          renderParamsLinesMap[groupPartLine.key.replace('*p', '')] = {
            groupName: g.name,
            key: groupPartLine.key,
            name: `${value.label}`,
            value_type: valueType,
            stack: `group${groupIndex + 1}`,
          };
        });
      } else {
        const [modelName, field] = g.group_by.split('__');
        const modelSplitBy = availableModels.find((m) => m.name === modelName);
        if (!modelSplitBy) {
          throw new Error(`Model split by not found. modelName: ${modelName}, field: ${field}`);
        }
        const fieldData = modelSplitBy.schema.fields[field];
        // console.log('additionalFieldData 123', additionalFieldData && g.additional_field && g.additional_field === additionalFieldData?.key);
        // if (additionalFieldData && g.additional_field && g.additional_field === additionalFieldData.key) {
        //   const line = g.lines[0];
        //   const currentFieldData = chartFormFields.find((f) => f.key === line.field);
        //   const lineFilterToMapToAdditionalMap = currentFieldData?.additionalLine?.mainToAdditionalFiltersMap;
        //   if (lineFilterToMapToAdditionalMap) {
        //     Object.values(lineFilterToMapToAdditionalMap).forEach(
        //       (additionalFilterValue, i) => {
        //         const lineFilterValueToApplyToAdditional = additionalFilterValue
        //           ? {
        //             key: currentFieldData?.additionalLine?.additionalLineFilterKey as string,
        //             value: additionalFilterValue,
        //           }
        //           : {};
        //         console.log('additionalFieldData 2', additionalFilterValue, lineFilterValueToApplyToAdditional);
        //         let additionalLineFinal = additionalFieldData.line as TileLIne;
        //         const { valueType } = additionalFieldData;
        //         additionalLineFinal.key = `group${999}-part${i + 1}___${lineKey}`;
        //         additionalLineFinal = applyDefaultFiltersToTileLine(additionalLineFinal);
        //         additionalLineFinal = applyFormFiltersToTileLine(
        //           additionalLineFinal,
        //           [lineFilterValueToApplyToAdditional],
        //         );
        //         lines.push(additionalLineFinal);
        //         const additionalFieldLabel = currentFieldData.additionalLineOptions?.find(({ value }) => value === additionalFieldData.key)?.label;
        //         console.log('additionalFieldLabel', currentFieldData.additionalLineOptions, additionalFieldData.key);
        //         renderParamsLinesMap[additionalLineFinal.key] = {
        //           key: additionalLineFinal.key,
        //           groupName: additionalFieldLabel,
        //           name: `${additionalFieldLabel}`,
        //           value_type: valueType,
        //           additional_to_group: groupIndex + 1,
        //         };
        //       },
        //     );
        //   }
        // }

        Object.entries(fieldData.enum).forEach(([value, label], i) => {
          if (additionalFieldData && g.additional_field && g.additional_field === additionalFieldData.key) {
            const line = g.lines[0];
            const currentFieldData = chartFormFields.find((f) => f.key === line.field);
            const lineFilterToMapToAdditionalMap = currentFieldData?.additionalLine?.mainToAdditionalFiltersMap;
            if (lineFilterToMapToAdditionalMap) {
              const lineFilterValueToApplyToAdditional = {
                key: currentFieldData?.additionalLine?.additionalLineFilterKey as string,
                value: currentFieldData?.additionalLine?.mainToAdditionalFiltersMap[value],
              };
              let additionalLineFinal = additionalFieldData.line as TileLIne;
              const { valueType } = additionalFieldData;
              additionalLineFinal.key = `group${999}-part${i + 1}___${lineKey}`;
              additionalLineFinal = applyDefaultFiltersToTileLine(additionalLineFinal);
              additionalLineFinal = applyFormFiltersToTileLine(
                additionalLineFinal,
                [lineFilterValueToApplyToAdditional],
              );
              lines.push(additionalLineFinal);
              const additionalFieldLabel = currentFieldData.additionalLineOptions?.find(({ value }) => value === additionalFieldData.key)?.label;
              renderParamsLinesMap[additionalLineFinal.key] = {
                key: additionalLineFinal.key,
                groupName: additionalFieldLabel,
                name: `${additionalFieldLabel}`,
                value_type: valueType,
                additional_to_group: groupIndex + 1,
              };
            }
          }
          let groupPartLine = JSON.parse(
            JSON.stringify(tileLine),
          );
          groupPartLine.key = `group${groupIndex + 1}-part${i + 1}___${lineKey}`;
          groupPartLine = applyFormFiltersToTileLine(
            groupPartLine,
            [{ key: `${modelName}__${field}`, value }],
          );
          lines.push(groupPartLine);
          renderParamsLinesMap[lineKey] = {
            groupName: g.name,
            key: lineKey,
            name: `${baseLine.name}`,
            value_type: valueType,
          };
          renderParamsLinesMap[groupPartLine.key] = {
            groupName: g.name,
            key: groupPartLine.key,
            name: label,
            value_type: valueType,
            stack: `group${groupIndex + 1}`,
          };
        });
      }
    } else {
      g.lines.forEach((line, i) => {
        const lineConfig = JSON.parse(
          JSON.stringify(chartFormFields.find((field) => field.key === line.field)),
        ) as ChartFormField;

        if (additionalFieldData && g.additional_field && g.additional_field === additionalFieldData.key) {
          const currentFieldData = chartFormFields.find((f) => f.key === line.field);
          const lineFilterToMapToAdditional = line.filters.find(
            (f) => f.key === currentFieldData.additionalLine?.mainLineFilterKey as string,
          );
          if (lineFilterToMapToAdditional) {
            const additionalFilterValue = currentFieldData.additionalLine?.mainToAdditionalFiltersMap[
              lineFilterToMapToAdditional.value
            ];
            const lineFilterValueToApplyToAdditional = additionalFilterValue
              ? {
                key: currentFieldData?.additionalLine?.additionalLineFilterKey as string,
                value: additionalFilterValue,
              }
              : {};
            console.log('additionalFieldData 2', additionalFilterValue, lineFilterValueToApplyToAdditional);
            let additionalLineFinal = additionalFieldData.line as TileLIne;
            const { valueType } = additionalFieldData;
            additionalLineFinal.key = `group${999}-part${i + 1}___${lineKey}`;
            additionalLineFinal = applyDefaultFiltersToTileLine(additionalLineFinal);
            additionalLineFinal = applyFormFiltersToTileLine(
              additionalLineFinal,
              [lineFilterValueToApplyToAdditional],
            );
            lines.push(additionalLineFinal);

            const additionalFieldLabel = lineConfig.additionalLineOptions?.find(({ value }) => value === additionalFieldData.key)?.label;
            console.log('additionalFieldLabel', additionalFieldData.additionalLineOptions, additionalFieldData.key);
            renderParamsLinesMap[additionalLineFinal.key] = {
              key: additionalLineFinal.key,
              groupName: additionalFieldLabel,
              name: `${additionalFieldLabel}`,
              value_type: valueType,
              additional_to_group: groupIndex + 1,
            };
          }
        }

        const { valueType } = lineConfig;
        let lineFinal = lineConfig.line as TileLine;
        lineFinal.key = `group${groupIndex + 1}-part${i + 1}___${lineKey}`;
        const color = chartColors[groupIndex + 1]?.[i];
        console.log('form to tile', chartColors, groupIndex, i);
        lineFinal = applyDefaultFiltersToTileLine(lineFinal);
        lineFinal = applyFormFiltersToTileLine(
          lineFinal,
          g.filters,
        );
        lineFinal = applyFormFiltersToTileLine(
          lineFinal,
          line.filters,
        );
        lines.push(lineFinal);
        renderParamsLinesMap[lineKey] = {
          key: lineKey,
          groupName: g.name,
          name: `${line.name}`,
          value_type: valueType,
          ...(color ? { color } : {}),
        };
        renderParamsLinesMap[lineFinal.key] = {
          key: lineFinal.key,
          groupName: g.name,
          name: `${line.name || lineConfig.label}`,
          value_type: valueType,
          ...(color ? { color } : {}),
        };
      });
    }

    return {
      lines, renderParamsLinesMap,
    };
  });

  return {
    name: form.name,
    parameters: {
      ...parameters,
      calc_growth: form.show_timeline_percent,
      lines: lineGroups.reduce((acc, { lines }) => ([
        ...acc,
        ...lines,
      ]), [] as any[]),
    },
    render_parameters: [{
      ...render_parameters,
      lines: lineGroups.reduce((acc, { renderParamsLinesMap }) => ({
        ...acc,
        ...renderParamsLinesMap,
      }), {} as Record<string, any>),
    }],
    ...(
      form.use_not_for_current_company
        ? form.all_companies
          ? { all_companies: true }
          : { companies: form.companies }
        : {}
    ),
  } as Pick<Tile, 'render_parameters'|'name'|'parameters'|'companies'|'all_companies'>;
};
