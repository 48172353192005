import { h, VNode } from 'vue';
import Plate from '@/components/plate/plate/Plate.vue';
import {
  IPlateIndicatorMultiProps,
  IPlateIndicatorPieProps,
  IPlateIndicatorProps,
  IPlateIndicatorSimpleProps,
  IPlateIndicatorWithPrevValueProps,
  PlateIndicatorSimpleVariants,
} from '@/components/plate/plateIndicator/types';
import { IPlateIndicatorListProps, PlateIndicatorListVariant } from '@/components/plate/list/list';
import PlateIndicatorValue from '@/components/plate/plateIndicator/PlateIndicatorValue.vue';
import PlateIndicatorIndicator from '@/components/plate/plateIndicator/PlateIndicatorIndicator.vue';
import { renderPlateGrid } from '@/components/plate/plateIndicator/PlateGrid';
import { PlateIndicatorType } from '@/components/plate/plateIndicator/index';
import Chart from '@/components/chart/Chart.vue';
import PlateList from '@/components/plate/list/PlateList.vue';
import PlateIndicatorValueBordered
  from '@/components/plate/plateIndicator/PlateIndicatorValueBordered.vue';

export const renderPlate = (title: any, actions: any, body: any) => h(
  Plate,
  null,
  {
    title: () => title,
    actions: () => actions,
    body: () => body,
  },
);

export const rSimpleIndicator = (props: IPlateIndicatorSimpleProps) => {
  const indicator = props.variant === PlateIndicatorSimpleVariants.borderedIndicator
    ? PlateIndicatorValueBordered
    : PlateIndicatorValue;
  const bodyContent = [h(
    indicator,
    {
      value: props.value,
      caption: props.caption,
      state: props.variant === PlateIndicatorSimpleVariants.smallIndicator ? 'small' : null,
    },
  )];
  const body = h(
    'div',
    {
      class: props.bodyClass,
      style: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },
    [bodyContent],
  );
  return body;
};

export const rWithPrevValueIndicator = (props: IPlateIndicatorWithPrevValueProps) => {
  const bodyContent = [h(
    PlateIndicatorValue,
    {
      value: props.value,
    },
  ), h(
    PlateIndicatorIndicator,
    {
      ...props.indicator,
      style: { marginLeft: '10px' },
    },
  )];
  const body = h(
    'div',
    {
      class: props.bodyClass,
      style: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },
    [bodyContent],
  );
  return body;
};

const rList = (props: IPlateIndicatorListProps) => {
  switch (props.variant) {
    case PlateIndicatorListVariant.List:
      return h(
        PlateList,
        { class: props.bodyClass, list: props.list, showRaw: props.showRaw },
      );
    default:
      return h(
        PlateList,
        { class: props.bodyClass, list: props.list, showRaw: props.showRaw },
      );
  }
};

export const rMultiIndicator = (props: IPlateIndicatorMultiProps) => renderPlateGrid(
  props.gridConfig,
  props.childrenGridConfigs.map((c) => c),
  props.children.map((ch) => renderPlateIndicatorFromProps(ch, false)()),
);

export const rChart = (
  prop: IPlateIndicatorPieProps,
) => h(Chart, { class: prop.bodyClass, chartOptions: prop.chartOptions });

const noRender = () => ['no indicator renderer'];

type RenderIndicator = (props: IPlateIndicatorProps, root: boolean) => (actions?: any) => VNode

type RenderIndicatorBody = (prop: IPlateIndicatorProps) => VNode

export type RendererMap = { [key in PlateIndicatorType]: RenderIndicatorBody }

export const rendererMap: RendererMap = {
  [PlateIndicatorType.Simple]: rSimpleIndicator,
  [PlateIndicatorType.Fraction]: rChart,
  [PlateIndicatorType.WithPrevValue]: rWithPrevValueIndicator,
  [PlateIndicatorType.Multi]: rMultiIndicator,
  [PlateIndicatorType.Bar]: rChart,
  [PlateIndicatorType.Pie]: rChart,
  [PlateIndicatorType.Line]: rChart,
  [PlateIndicatorType.List]: rList,
} as unknown as RendererMap;

// @ts-ignore
export const _renderPlateIndicatorFromProps: RenderIndicator = (
  props,
  root = false,
) => {
  const renderer = rendererMap[props.type];
  if (!renderer) return (renderActions: any) => renderPlate(props.title, renderActions, ['no renderer']);
  // @ts-ignore
  const rendered = renderer(props);
  if (root) {
    return (renderedActions: any) => renderPlate(props.title || null, renderedActions, rendered);
  }
  return () => rendered;
};

export const getPlateIndicatorRenderer = (
  containerRenderer: (title: string|null, actions: any, body: any) => VNode,
) => ((
  props: IPlateIndicatorProps,
  root = false,
) => {
  const renderer = rendererMap[props.type];
  if (!renderer) return (renderActions: any) => containerRenderer(props.title, renderActions, ['no renderer']);
  // @ts-ignore
  const rendered = renderer(props);
  if (root) {
    return (renderedActions?: any) => containerRenderer(props.title || null, renderedActions, rendered);
  }
  return () => rendered;
});

export const renderPlateIndicatorFromProps = getPlateIndicatorRenderer(renderPlate);
