import { onBeforeUnmount, Ref, ComputedRef } from 'vue';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { getDebtorsModuleTableModuleKey, getDefaultUserPrefs } from '@/hooks/useUserPrefs';
import { ProductionType } from '@/hooks/useConstructor';
import { useDebtorsModuleUserPrefs } from '@/pages/debtors/_module/useModuleUserPrefs';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { ActionType, ActiveTableAction } from '@/components/activeTable/useActiveTable';

export const useActionsTableSettings = (
  tableKey: Ref<string>,
  visibleDefaultActions: Ref<string[]>,
  localActions: Ref<ActiveTableAction<any, any>[]>,
) => {
  const {
    subscribeToSignal,
  } = useSignal();
  const {
    updateModuleActions,
  } = useDebtorsModuleUserPrefs();

  const {
    showDialog,
  } = useDialog();

  onBeforeUnmount(
    subscribeToSignal(SignalType.tableActions, ({
      tableKey: payloadTableKey,
      actions,
    }: {
      tableKey: string;
      actions: string[];
    }) => {
      if (tableKey.value === payloadTableKey) {
        const debtorsModuleKey = getDebtorsModuleTableModuleKey(tableKey.value);
        if (debtorsModuleKey === false) {
          visibleDefaultActions.value = [...actions];
        } else {
          const moduleKey = debtorsModuleKey as ProductionType;
          const defaultActions = getDefaultUserPrefs().interface_preferences[moduleKey].actions;
          const mergedActions = Object.fromEntries(
            Object.keys(defaultActions).map((key) => [key, actions.includes(key)]),
          );
          updateModuleActions({
            actions: mergedActions,
            module_type: moduleKey,
          });
        }
      }
    }),
  );

  const openTableActionsDialog = () => {
    return showDialog({
      component: IDialogComponent.actionsSelector,
      addInRoute: false,
      payload: {
        actions: localActions.value.filter(({
          types, group,
        }) => types.includes(ActionType.default) && (!group || !(group! % 1))),
        visibleActions: visibleDefaultActions.value,
        tableKey: tableKey.value,
      },
    });
  };

  return {
    openTableActionsDialog,
  };
};
