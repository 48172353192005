export const CADESCOM_CADES_BES = 1;
export const CAPICOM_CURRENT_USER_STORE = 2;
export const CAPICOM_LOCAL_MACHINE_STORE = 1;
export const CAPICOM_MY_STORE = 'My';
export const CAPICOM_ROOT_STORE = 'Root';
export const CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;
export const CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME = 1;
export const CADESCOM_BASE64_TO_BINARY = 1;
export const CAPICOM_CERTIFICATE_FIND_TIME_VALID = 9;
export const CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY = 6;
export const CAPICOM_PROPID_KEY_PROV_INFO = 2;
export const CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;
