// @ts-nocheck third-party-js

import {
  CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY,
  CAPICOM_CERTIFICATE_FIND_TIME_VALID,
  CAPICOM_CURRENT_USER_STORE,
  CAPICOM_MY_STORE, CAPICOM_PROPID_KEY_PROV_INFO,
  CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
  CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
} from '@core/plugins/cryptoPro/consts';
import { formatDate, formatDateTime } from '@/utils/date';

const CADESCOM_ENCRYPTION_ALGORITHM_GOST_28147_89 = 25;
const CADESCOM_HASH_ALGORITHM_CP_GOST_3411 = 100;
const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256 = 101;
const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512 = 102;
const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_HMAC = 110;
const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256_HMAC = 111;
const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512_HMAC = 112;

export type Certificate = {
  name: string;
  issuerName: string;
  fio: string;
  subjectName: string;
  thumbprint: string;
  validFrom: string;
  validTo: string;
  publicKeyData: string;
  periodFormatted: string;
  serialNumber: string;
  _obj: any;
}

const _extractCommonName = (
  subjectName: string,
): string | undefined => subjectName.match(/CN=(.+?)(?:,|$)/)?.[1];

const _extractFio = (
  subjectName: string,
): string | undefined => [
  subjectName.match(/SN=(.+?)(?:,|$)/)?.[1],
  subjectName.match(/G=(.+?)(?:,|$)/)?.[1],
].filter(Boolean).join(' ');

const getCertificateFromObj = (obj: any) => new Promise(async (resolve, reject) => {
  try {
    const validFrom = await obj.ValidFromDate;
    const validTo = await obj.ValidToDate;
    const periodFormatted = `С ${formatDate(new Date(validFrom))} по ${formatDate(new Date(validTo))}`;
    const cert = await ({
      _obj: obj,
      name: _extractCommonName(await obj.SubjectName),
      issuerName: _extractCommonName(await obj.IssuerName),
      fio: _extractFio(await obj.SubjectName),
      serialNumber: await obj.SerialNumber,
      subjectName: await obj.SubjectName,
      thumbprint: await obj.Thumbprint,
      validFrom,
      validTo,
      periodFormatted,
      publicKeyData: await ((async () => {
        const publicKey = await obj.PublicKey();
        const algo = await publicKey.Algorithm;
        const friendlyName = await algo.FriendlyName;
        console.log('public key', publicKey, friendlyName);
        return friendlyName;
      })()),
    });
    resolve(cert);
  } catch (e) {
    console.log('getCertificateFromObj', e);
    console.error(e);
    reject(e);
  }
});

export const getUserCertificates = async () => new Promise<Certificate[]>((resolve, reject) => {
  if (!cadesplugin) {
    reject(new Error('cades plugin not found'));
    return;
  }

  cadesplugin.async_spawn(function* (args) {
    try {
      const oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
      yield oStore.Open(
        CAPICOM_CURRENT_USER_STORE,
        CAPICOM_MY_STORE,
        CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
      );
      const oStoreCerts = yield oStore.Certificates;
      let oCertificates = yield oStoreCerts.Find(
        CAPICOM_CERTIFICATE_FIND_TIME_VALID,
      );
      oCertificates = yield oCertificates.Find(
        CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY,
        CAPICOM_PROPID_KEY_PROV_INFO,
      );
      let nCertificatesCount = yield oCertificates.Count;

      if (!nCertificatesCount) {
        return [];
      }

      const certificateList = [];

      while (nCertificatesCount) {
        const cert = yield oCertificates.Item(nCertificatesCount);
        certificateList.push(cert);

        nCertificatesCount--;
      }

      const finalList = yield Promise.all(certificateList.map(getCertificateFromObj));

      yield oStore.Close();
      resolve((finalList as Certificate[]).filter((cert) => cert.publicKeyData && (cert.publicKeyData.toLowerCase().includes('гост') || cert.publicKeyData.toLowerCase().includes('gost'))));
    } catch (e) {
      console.error(`some get user cert error ${e}`);
      console.log('some get user cert error', e);
      resolve([]);
    }
  });
});

export const getFirstSertificateObj = async () => new Promise<any>((resolve, reject) => {
  if (!cadesplugin) {
    reject(new Error('cades plugin not found'));
    return;
  }

  cadesplugin.async_spawn(function* (args) {
    try {
      const oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
      yield oStore.Open(
        CAPICOM_CURRENT_USER_STORE,
        CAPICOM_MY_STORE,
        CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
      );
      const oStoreCerts = yield oStore.Certificates;
      let oCertificates = yield oStoreCerts.Find(
        CAPICOM_CERTIFICATE_FIND_TIME_VALID,
      );
      oCertificates = yield oCertificates.Find(
        CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY,
        CAPICOM_PROPID_KEY_PROV_INFO,
      );
      let nCertificatesCount = yield oCertificates.Count;

      if (!nCertificatesCount) {
        return [];
      }

      const certificateList = [];

      while (nCertificatesCount) {
        const cert = yield oCertificates.Item(nCertificatesCount);
        certificateList.push(cert);

        nCertificatesCount--;
      }

      const finalList = yield Promise.all(certificateList.map(getCertificateFromObj));

      yield oStore.Close();
      resolve((finalList as Certificate[]).filter((cert) => cert.publicKeyData && (cert.publicKeyData.toLowerCase().includes('гост') || cert.publicKeyData.toLowerCase().includes('gost'))));
    } catch (e) {
      console.error(`some get user cert error ${e}`);
      console.log('some get user cert error', e);
      resolve([]);
    }
  });
});
