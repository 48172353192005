import { Directive } from 'vue';
import { awaitFrame } from '@/utils/window';
import { onClickOutside } from '@vueuse/core';

const handlers: Array<{
  el: HTMLElement;
  handler: (event: MouseEvent) => void;
}> = [];

document.addEventListener('mousedown', (event) => {
  const { target } = event;
  if ((target as HTMLElement)?.dataset?.clickOptions === 'no-close') {
    return;
  }
  const popperContainers = document.body.querySelectorAll('[id^=el-popper]');
  handlers.forEach(({ el, handler }) => {
    if (Array.from(popperContainers).some(
      (container) => container.contains(target as HTMLElement) && !container.contains(el),
    )
    ) {
      return;
    }
    if (
      el === target
      || el.contains(target as HTMLElement)
      || el.matches('input[type="file"]')
    ) {
      return;
    }
    handler(event);
  });
}, true);

export const OutsideClickDirective: Directive = {
  async mounted(el, binding) {
    // await awaitFrame();
    handlers.push({
      el,
      handler: binding.value,
    });
  },
  beforeUnmount(el) {
    handlers.splice(
      handlers.findIndex((handler) => el === handler.el),
      1,
    );
  },
};
