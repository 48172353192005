import { Drivers, Storage } from '@ionic/storage';
import { envIsMobile } from '@/utils/env';

const storage = new Storage({
  name: '__myStorage',
  driverOrder: [envIsMobile ? Drivers.IndexedDB : Drivers.LocalStorage],
});

const awaitStore = new Promise<Storage>(async (resolve) => {
  await storage.create();
  return resolve(storage);
});

let accessStorePromise: Promise<void>|null = null;
let accessStorePromiseResolver: (() => void)|null;

const getItem = async (key: string) => {
  const store = await awaitStore;
  if (!accessStorePromise) {
    accessStorePromise = new Promise<void>((resolve) => {
      accessStorePromiseResolver = resolve;
    });
    const result = await store.get(key);
    accessStorePromiseResolver?.();
    accessStorePromise = null;
    return result;
  }
  await accessStorePromise;
  accessStorePromise = new Promise<void>((resolve) => {
    accessStorePromiseResolver = resolve;
  });
  const result = await store.get(key);
  accessStorePromiseResolver?.();
  accessStorePromise = null;
  return result;
};

const setItem = async (key: string, value: string) => {
  const store = await awaitStore;
  if (!accessStorePromise) {
    accessStorePromise = new Promise<void>((resolve) => {
      accessStorePromiseResolver = resolve;
    });
    const result = await store.set(key, value);
    accessStorePromiseResolver?.();
    accessStorePromise = null;
    return result;
  }
  await accessStorePromise;
  accessStorePromise = new Promise<void>((resolve) => {
    accessStorePromiseResolver = resolve;
  });
  const result = await store.set(key, value);
  accessStorePromiseResolver?.();
  accessStorePromise = null;
  return result;
};

const getParsedItem = async (key: string) => {
  const result = await getItem(key);
  return result ?? {};
  // console.log('getParsedItem', result);
  // return JSON.parse(result || '{}') || {};
};

const setStringifiedItem = async (key: string, value: any) => setItem(key, value);

const updateStorageKey = async (key: string, value: any) => {
  const valuePrepared = JSON.parse(JSON.stringify(value ?? null));
  const store = await awaitStore;
  if (!accessStorePromise) {
    accessStorePromise = new Promise<void>((resolve) => {
      accessStorePromiseResolver = resolve;
    });
    const storeData = await store.get('store') || {};
    storeData[key] = valuePrepared;
    await store.set('store', storeData);
    accessStorePromiseResolver?.();
    accessStorePromise = null;
  } else {
    await accessStorePromise;
    accessStorePromise = new Promise<void>((resolve) => {
      accessStorePromiseResolver = resolve;
    });
    const storeData = await store.get('store') || {};
    storeData[key] = valuePrepared;
    await store.set('store', storeData);
    accessStorePromiseResolver?.();
    accessStorePromise = null;
  }
};

export default {
  setItem, getParsedItem, setStringifiedItem, getItem, updateStorageKey, awaitStore,
};
