import { computed, Ref } from 'vue';
import { CourtTypeTabKey } from '@/components/dialog/dialogs/debtor/tabs/courts/types';
import { Debtor } from '@/hooks/useDebtors';

export function useContractualCourts(debtor: Ref<Debtor>, activeTab: Ref<CourtTypeTabKey>) {
  const contractualId = computed(() => {
    const profile = debtor.value?.debtor_main_profile;

    return profile[`use_contractual_${activeTab.value}_court_place`]
      ? profile[`${activeTab.value}_court_place`]
      : null;
  });

  return { contractualId };
}
