import { ApiResponse, createApiRequest } from '@/service/api';
import { ApiCommandConfig, ApiMethod, ApiRequestGeneric } from '@/service/api-types';
import { useCompanySettings } from '@/components/automation/useCompanySettings';
import { UrrobotOperatorUuid } from '@/hooks/useCompanies';
import {
  CompanyReadableUserSettingsSecure,
  useCompanySettingsSecure,
} from '@/hooks/useCompanySettingsSecure';
import { Ref } from 'vue';

const commands = {
  me: 'me',
  bots: 'bots',
  phoneNumbers: 'phoneNumbers',
};

type Command = keyof typeof commands;

const commandsMap: Record<Command, ApiCommandConfig> = {
  me: {
    url: '/api/public/users/me',
    method: ApiMethod.get,
  },
  bots: {
    url: '/api/public/bot/company/{company_sid}',
    method: ApiMethod.get,
  },
  phoneNumbers: {
    url: '/api/public/source_number/company/{company_sid}',
    method: ApiMethod.get,
  },
};

export type BotItem = {
  sid: string;
  name: string;
}

export type PhoneItem = {
  phone_number: string;
}

type Me = {
  company: {
    sid: string;
  };
}

export type CompanySettingUrrobotVoip = CompanyReadableUserSettingsSecure<
  { token: string },
  'urrobot_voice'
  >

export type Option = { label: string; value: string };

export function useVoipService(companyId: Ref<number>) {
  const { companySettings } = useCompanySettings(companyId);
  const { fetchUserSetting } = useCompanySettingsSecure();

  async function getToken() {
    const settings = companySettings.value;
    if (!settings) {
      console.error('Voip: settings not fetched');
      return null;
    }
    const useBots = settings.voice_operator_uuid === UrrobotOperatorUuid || !settings.voice_operator_uuid;
    if (!useBots) {
      console.info('Bots disabled', settings.voice_operator_uuid);
      return null;
    }
    const voipTokenResult = await fetchUserSetting<CompanySettingUrrobotVoip>({
      company_id: companyId.value,
      key: 'urrobot_voice',
    });
    const token = voipTokenResult.response?.data?.token;
    if (!token) {
      console.warn('Voip: no token', voipTokenResult.response);
      return null;
    }
    return token;
  }

  return {
    async fetchBotsAndPhones(): Promise<[Option[], Option[]]> {
      const token = await getToken();
      if (!token) {
        return [[], []];
      }
      const service = new VoipService(token);
      await service.fetchCompanySid();
      return Promise.all([
        service.fetchBots(),
        service.fetchPhoneNumbers(),
      ]);
    },
  };
}

export class VoipService {

  private readonly request: <Response>(request: ApiRequestGeneric<Command>) => Promise<ApiResponse<Response>>;
  private readonly apiToken;
  private companySid = '';

  constructor(apiToken: string) {
    this.apiToken = apiToken;
    this.request = createApiRequest({
      apiUrl: 'https://public.test-api-voip.urrobot.tech',
      getToken: async () => {
        return this.apiToken;
      },
      getApiCommand: (command: Command) => commandsMap[command],
      tokenField: 'api-key',
      appendParams: { format: 'json' },
    });
  }

  async fetchPhoneNumbers() {
    const result = await this.request<PhoneItem[]>({
      command: commands.phoneNumbers as Command,
      params: { company_sid: this.companySid },
    });
    if (!result.status) {
      console.error('failed to fetch numbers', result.response);
      return [];
    }
    return result.response.map((item) => ({
      label: item.phone_number,
      value: item.phone_number,
    }));
  }

  async fetchBots() {
    const result = await this.request<BotItem[]>({
      command: commands.bots as Command,
      params: { company_sid: this.companySid },
    });
    if (!result.status) {
      console.error('failed to fetch bots', result.response);
      return [];
    }
    return result.response.map((item) => ({
      label: item.name,
      value: item.sid,
    }));
  }

  async fetchCompanySid() {
    const result = await this.request<Me>({ command: commands.me as Command });
    if (!result.status) {
      console.error('failed to fetch sid', result.response);
    } else {
      this.companySid = result.response.company.sid;
    }
  }

}
