import { computed, ref, Ref } from 'vue';
import { Debtor } from '@/hooks/useDebtors';
import { useDialogRouteParam } from '@/hooks/useDialog';
import { ITab } from '@/components/tabs/useTabs';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { ProductionType } from '@/hooks/useConstructor';
import { ActiveTabKey, IncomeSource } from '@/components/dialog/dialogs/debtor/tabs/finance/types';

export const tabs = (debtor: Ref<Debtor>, productionType: Ref<ProductionType>) => {
  const { t } = useLocalI18n('debtor.finance');
  const tabs = computed<Array<ITab<ActiveTabKey>>>(() => ((([
    productionType.value !== ProductionType.executive && {
      key: 'accruals',
    },
    {
      key: 'debts',
    },
    {
      key: 'paid-ups',
    },
    productionType.value !== 'executive' && {
      key: 'recalculations',
    },
    {
      key: 'penalties',
    },
    productionType.value === 'executive' && {
      key: 'fee',
    },
    {
      key: IncomeSource,
    },
    {
      key: 'overdue_debt',
    },
  ])
    .filter(Boolean) as Array<ITab<ActiveTabKey>>)
    .map((tab) => ({
      ...tab,
      label: t(`${tab.key}.tab`),
      id: `modal_debtor_finance_tab_${tab.key}_tab`,
    }))));

  const [activeTab] = useDialogRouteParam<ActiveTabKey>(
    'finance-tab',
    productionType.value === ProductionType.executive
      ? 'debts'
      : 'accruals',
    ref(true),
  );

  return {
    tabs,
    activeTab,
  };
};
