import {
  ActiveTableColumn,
  ActiveTableColumnFormat,
  formatListingRequest,
  formatListingResponse,
  useActiveTable,
} from '@/components/activeTable/useActiveTable';
import { computed, onMounted, ref } from 'vue';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { ActiveFormField } from '@/hooks/useActiveForm';
import { ApiCommand, ListingRequestSource } from '@/store/modules/api';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';
import {
  hasExtra, renderExtra,
  valueFormatter,
} from '@/components/dialog/dialogs/debtor/tabs/history/debtorHistoryTabUtils';
import { DictType, useDicts } from '@/hooks/useDicts';
import { useCustomLocales } from '@/components/dialog/dialogs/debtor/useCustomLocales';

export const useDebtorHistoryTab = () => {
  const { t: pTypeT } = useLocalI18n('productionType');
  const { debtorId } = useInjectDebtorDialog();
  const { companyId } = useProtectedDefaultCompany();
  const isLoading = ref<boolean>(false);

  const { getDictMap } = useDicts();

  const translationsMap = computed(() => ({
    relationships: getDictMap(DictType.tenantRelationships).value,
  }));

  const fetchDebtorHistory = (
    model: ListingRequestSource<{ debtor_id: number; company_id: number }>,
  ) => commonLegacyApiRequest({
    command: ApiCommand.debtorDataGetHistory,
    params: formatListingRequest(model),
  }).then((r) => {
    if (!r.status) {
      return r;
    }
    return {
      status: true,
      response: formatListingResponse(r.response as any),
    };
  });
  const { dc, dh, fetchLocales } = useCustomLocales();

  const table = useActiveTable<any, any, 'index'>({
    keyField: 'index',
    defaultLimit: ref(1000),
    columns: computed(() => ([
      {
        key: 'production_type',
        field: 'production_type',
        label: 'Модуль',
        getValue: (record) => (record.production_type === 'no production_type in request' ? null : pTypeT(record.production_type)),
      },
      {
        key: 'menu',
        field: 'menu',
        label: 'Меню',
        getValue: ({ menu, tab }: any) => `${dh('menu', menu)} - ${dh('value_tab', tab)}`,
      },
      {
        key: 'status',
        field: 'status',
        label: 'Статус',
        format: (status) => dh('status', status),
      },
      {
        key: 'value',
        field: 'value',
        label: 'Наименование',
        getValue: (record) => dh('value', record.value),
      },
      {
        key: 'value_before',
        field: 'value_before',
        label: 'Значение до',
        getValue: (record) => valueFormatter(record.value, record.value_before, translationsMap.value),
      },
      {
        key: 'value_after',
        field: 'value_after',
        label: 'Значение после',
        getValue: (record) => {
          if (hasExtra(record)) {
            return renderExtra(record.extra, dc, translationsMap.value);
          }
          return valueFormatter(record.value, record.value_after, translationsMap.value);
        },
      },
      {
        key: 'change_time',
        field: 'change_time',
        label: 'Дата и время',
        isSortable: true,
        format: ActiveTableColumnFormat.dateTime,
      },
      {
        key: 'username',
        field: 'username',
        label: 'Пользователь',
      },
    ]) as ActiveTableColumn<any>[]),
    filters: computed(() => ([
      {
        key: 'debtor_id',
        defaultValue: debtorId.value,
      },
      {
        key: 'company_id',
        defaultValue: companyId.value,
      },
    ]) as ActiveFormField<any>[]),
    fetch: async ({ params, signal }) => {
      isLoading.value = true;
      await fetchLocales();
      const { response } = await fetchDebtorHistory(params);
      isLoading.value = false;
      return response;
    },
  });
  return { ...table, isLoading };
};
