import { createApiRequest } from '@/service/api';
import { ListingRequestSource, ListingResponse, OrderDirection } from '@/store/modules/api';
import { standartizeApiUrl } from '@/utils/env';
import { formatListingRequest } from '@/components/activeTable/useActiveTable';
import { resolveStore } from '@urrobot/core/utils/resolveStore';
import { StandartizeCadnumLevel } from '@/store/modules/user';
import { TileDataRaw } from '@/service/api/reporting/tile';
import { ApiCommandConfig, ApiMethod } from '@/service/api-types';
import { StandartizeUser } from '@/pages/admin/statistic/main/types';
import {
  AddrObj,
  CadnumLevelsResponse, GarAddress,
  GetStandartizeAddressListModel,
  SearchAddObjModel,
  StandartizationResponse,
  StandartizeAddress,
  StandartizeAddressCadnumSuggest,
  StandartizeAddressUpdateModel,
  StandartizeChartTileParams,
  StandartizeCourtEntry,
  StandartizeFile,
  StandartizeFileGetListModel,
  StandartizeFileUploadModel,
  StandartizeFnsEntry,
  StandartizeFsspEntry,
  StandartizeSearchModel,
} from '@/service/standartize/types';
import { jurisdiction } from '@/service/standartize/jurisdictionApi';

export enum ApiCommand {
  logIn = 'logIn',
  fileGetList = 'fileGetList',
  fileUpload = 'fileUpload',
  fileDelete = 'fileDelete',

  cadnumLevelsDataGet = 'cadnumLevelsDataGet',
  cadnumAdd = 'cadnumAdd',
  addressGetList = 'addressGetList',
  addressGetItem = 'addressGetItem',
  addressUpdateItem = 'addressUpdateItem',
  addressUpdateMany = 'addressUpdateMany',
  addressDelete = 'addressDelete',
  standartizeSelected = 'standartizeSelected',
  linkedAddressUpdateStandartization = 'linkedAddressUpdateStandartization',
  linkedAddressUpdateSlugs = 'linkedAddressUpdateSlugs',
  linkedAddressesDelete = 'linkedAddressesDelete',
  fnsSearch = 'fnsSearch',
  fsspGetList = 'fsspGetList',
  fsspSearch = 'fsspSearch',
  fsspMirCourtJurisdictionGetList = 'fsspMirCourtJurisdictionGetList',
  fsspMirCourtJurisdictionGetItem = 'fsspMirCourtJurisdictionGetItem',
  fsspMirCourtJurisdictionCreateItem = 'fsspMirCourtJurisdictionCreateItem',
  fsspMirCourtJurisdictionUpdateItem = 'fsspMirCourtJurisdictionUpdateItem',
  fsspMirCourtJurisdictionDeleteItem = 'fsspMirCourtJurisdictionDeleteItem',
  mirCourtSearch = 'mirCourtSearch',
  mirCourtGetList = 'mirCourtGetList',
  mirCourtUpdateAddress = 'mirCourtUpdateAddress',
  courtJurisdictionGetList = 'courtJurisdictionGetList',
  courtJurisdictionGetItem = 'courtJurisdictionGetItem',
  courtJurisdictionCreateItem = 'courtJurisdictionCreateItem',
  courtJurisdictionUpdateItem = 'courtJurisdictionUpdateItem',
  courtJurisdictionDeleteItem = 'courtJurisdictionDeleteItem',
  regCourtGetList = 'regCourtGetList',
  regCourtSearch = 'regCourtSearch',

  getAddrObjList = 'getAddrObjList',
  getHouseList = 'getHouseList',
  getFlatList = 'getFlatList',
  doStandartization = 'doStandartization',
  doSearchCadnum = 'doSearchCadnum',

  addressUpdateManyByPattern = 'addressUpdateManyByPattern',
  taskProgressGetItem = 'taskProgressGetItem',
  getStats = 'getStats',
  resetCadnumQuery = 'resetCadnumQuery',
  addressDublicateUpdateIsVerifiedById = 'addressDublicateUpdateIsVerifiedById',
  usersGetList = 'usersGetList',
  statisticGetList = 'statisticGetList',
  statisticGetChartData = 'statisticGetChartData',
}

type ApiCommandsMap = {
  [key in ApiCommand]: ApiCommandConfig
}

const apiCommands: ApiCommandsMap = {
  [ApiCommand.logIn]: {
    method: ApiMethod.post,
    url: '/rest-auth/login/',
  },
  [ApiCommand.cadnumLevelsDataGet]: {
    method: ApiMethod.get,
    url: '/api/gar/cadnum/enums/',
  },
  [ApiCommand.cadnumAdd]: {
    method: ApiMethod.get,
    url: '/api/gar/address/{slug}/add_cadnum/',
    paramsMap: {
      cadnum: 'cadnum',
      is_default: 'is_default',
      level: 'level',
    },
  },
  [ApiCommand.fileGetList]: {
    method: ApiMethod.get,
    url: '/api/gar/file/',
    paramsMap: {
      mark: 'mark',
      region: 'mark',
      limit: 'limit',
      offset: 'offset',
    },
  },
  [ApiCommand.fileUpload]: {
    method: ApiMethod.post,
    url: '/api/gar/file/',
  },
  [ApiCommand.fileDelete]: {
    method: ApiMethod.delete,
    url: '/api/gar/file/{id}/',
  },
  [ApiCommand.addressGetList]: {
    method: ApiMethod.get,
    url: '/api/gar/address/',
    paramsMap: {
      limit: 'limit',
      offset: 'offset',
      name: 'name',
      source: 'source',
      cadnum_sources: 'cadnum_sources',
      cadnum_quality_lte: 'cadnum_quality_lte',
      cadnum_quality_gte: 'cadnum_quality_gte',
      standardization_file: 'standardization_file',
      is_verified_result: 'is_verified_result',
      is_verified_mir_court: 'is_verified_mir_court',
      is_verified_reg_court: 'is_verified_reg_court',
      is_verified_fssp: 'is_verified_fssp',
      is_verified_cadnum: 'is_verified_cadnum',
      is_setted_or: 'is_setted_or',
      is_setted_and: 'is_setted_and',
      is_setted_or_not: 'is_setted_or_not',
      is_setted_and_not: 'is_setted_and_not',
      is_verified_or: 'is_verified_or',
      is_verified_and: 'is_verified_and',
      is_verified_or_not: 'is_verified_or_not',
      is_verified_and_not: 'is_verified_and_not',
      allow_levels: 'allow_levels',
      has_failed_sources: 'has_failed_sources',
      color: 'color',
      color_isnull: 'color_isnull',
    },
  },
  [ApiCommand.doStandartization]: {
    method: ApiMethod.get,
    url: '/api/gar/address/do_standardization/',
    paramsMap: {
      limit: 'limit',
      offset: 'offset',
      standardization_file: 'standardization_file',
      search_cadnums: 'search_cadnums',
      ws_group: 'ws_group',
      name: 'name',
      source: 'source',
      cadnum_sources: 'cadnum_sources',
      cadnum_quality_lte: 'cadnum_quality_lte',
      cadnum_quality_gte: 'cadnum_quality_gte',
      is_verified_result: 'is_verified_result',
      is_verified_mir_court: 'is_verified_mir_court',
      is_verified_reg_court: 'is_verified_reg_court',
      is_verified_fssp: 'is_verified_fssp',
      is_verified_cadnum: 'is_verified_cadnum',
      is_setted_or: 'is_setted_or',
      is_setted_and: 'is_setted_and',
      is_setted_or_not: 'is_setted_or_not',
      is_setted_and_not: 'is_setted_and_not',
      is_verified_or: 'is_verified_or',
      is_verified_and: 'is_verified_and',
      is_verified_or_not: 'is_verified_or_not',
      is_verified_and_not: 'is_verified_and_not',
      allow_levels: 'allow_levels',
      has_failed_sources: 'has_failed_sources',
      color: 'color',
      color_isnull: 'color_isnull',
      ignore_saved: 'ignore_saved',
    },
  },
  [ApiCommand.doSearchCadnum]: {
    method: ApiMethod.get,
    url: '/api/gar/address/do_search_cadnum/',
    paramsMap: {
      limit: 'limit',
      offset: 'offset',
      standardization_file: 'standardization_file',
      search_cadnums: 'search_cadnums',
      ws_group: 'ws_group',
      name: 'name',
      source: 'source',
      cadnum_sources: 'cadnum_sources',
      cadnum_quality_lte: 'cadnum_quality_lte',
      cadnum_quality_gte: 'cadnum_quality_gte',
      is_verified_result: 'is_verified_result',
      is_verified_mir_court: 'is_verified_mir_court',
      is_verified_reg_court: 'is_verified_reg_court',
      is_verified_fssp: 'is_verified_fssp',
      is_verified_cadnum: 'is_verified_cadnum',
      is_setted_or: 'is_setted_or',
      is_setted_and: 'is_setted_and',
      is_setted_or_not: 'is_setted_or_not',
      is_setted_and_not: 'is_setted_and_not',
      is_verified_or: 'is_verified_or',
      is_verified_and: 'is_verified_and',
      is_verified_or_not: 'is_verified_or_not',
      is_verified_and_not: 'is_verified_and_not',
      allow_levels: 'allow_levels',
      has_failed_sources: 'has_failed_sources',
      color: 'color',
      color_isnull: 'color_isnull',
      ignore_saved: 'ignore_saved',
    },
  },
  [ApiCommand.standartizeSelected]: {
    method: ApiMethod.post,
    url: '/api/gar/address/do_standardization/',
    paramsMap: {
      allow_levels: 'allow_levels',
      ignore_saved: 'ignore_saved',
      search_cadnums: 'search_cadnums',
      ws_group: 'ws_group',
      force_region: 'force_region',
    },
  },
  [ApiCommand.addressGetItem]: {
    method: ApiMethod.get,
    url: '/api/gar/address/{slug}/',
  },
  [ApiCommand.addressDelete]: {
    method: ApiMethod.delete,
    url: '/api/gar/address/{slug}/',
  },
  [ApiCommand.addressUpdateItem]: {
    method: ApiMethod.patch,
    url: '/api/gar/address/{slug}/save/',
  },
  [ApiCommand.addressUpdateMany]: {
    method: ApiMethod.patch,
    url: '/api/gar/address/update_many/',
  },
  [ApiCommand.fnsSearch]: {
    method: ApiMethod.get,
    url: '/api/fns/search/',
    paramsMap: {
      limit: 'limit',
      regioncode: 'regioncode',
      name: 'name',
    },
  },
  [ApiCommand.addressUpdateManyByPattern]: {
    method: ApiMethod.patch,
    url: 'api/gar/address/update_many_by_pattern/',
  },
  [ApiCommand.linkedAddressUpdateStandartization]: {
    method: ApiMethod.post,
    url: 'api/gar/address_dubles/update_standartization/',
  },
  [ApiCommand.linkedAddressUpdateSlugs]: {
    method: ApiMethod.post,
    url: 'api/gar/address_dubles/update_slugs/',
  },
  [ApiCommand.fsspSearch]: {
    method: ApiMethod.get,
    url: '/api/fssp/search/',
    paramsMap: {
      limit: 'limit',
      regioncode: 'regioncode',
      name: 'name',
    },
  },
  [ApiCommand.fsspGetList]: {
    method: ApiMethod.get,
    url: '/api/fssp/',
    paramsMap: {
      limit: 'limit',
      regioncode: 'regioncode',
    },
  },
  [ApiCommand.fsspMirCourtJurisdictionGetList]: {
    method: ApiMethod.get,
    url: '/api/fssp/{court_id}/jurisdiction/',
    paramsMap: {
      limit: 'limit',
      offset: 'offset',
      regioncode: 'regioncode',
      name: 'name',
      // court_id: 'court_id',
    },
  },
  [ApiCommand.fsspMirCourtJurisdictionGetItem]: {
    method: ApiMethod.get,
    url: '/api/fssp/{court_id}/jurisdiction/{id}/',
  },
  [ApiCommand.fsspMirCourtJurisdictionCreateItem]: {
    method: ApiMethod.post,
    url: '/api/fssp/{court_id}/jurisdiction/',
  },
  [ApiCommand.fsspMirCourtJurisdictionUpdateItem]: {
    method: ApiMethod.patch,
    url: '/api/fssp/{court_id}/jurisdiction/{id}',
  },
  [ApiCommand.fsspMirCourtJurisdictionDeleteItem]: {
    method: ApiMethod.delete,
    url: '/api/fssp/{court_id}/jurisdiction/{id}/',
  },
  [ApiCommand.mirCourtSearch]: {
    method: ApiMethod.get,
    url: '/api/mir_court/search/',
    paramsMap: {
      limit: 'limit',
      regioncode: 'regioncode',
      name: 'name',
    },
  },
  [ApiCommand.mirCourtGetList]: {
    method: ApiMethod.get,
    url: '/api/mir_court/',
    paramsMap: {
      limit: 'limit',
      regioncode: 'regioncode',
    },
  },
  [ApiCommand.mirCourtUpdateAddress]: {
    method: ApiMethod.patch,
    url: '/api/mir_court/{id}/',
  },
  [ApiCommand.courtJurisdictionGetList]: {
    method: ApiMethod.get,
    url: '/api/{court_type}/{court_id}/jurisdiction/',
    paramsMap: {
      limit: 'limit',
      offset: 'offset',
      court_id: 'court_id',
      court_type: 'court_type',
    },
  },
  [ApiCommand.courtJurisdictionGetItem]: {
    method: ApiMethod.get,
    url: '/api/{court_type}/{court_id}/jurisdiction/{id}/',
  },
  [ApiCommand.courtJurisdictionCreateItem]: {
    method: ApiMethod.post,
    url: '/api/{court_type}/{court_id}/jurisdiction/',
  },
  [ApiCommand.courtJurisdictionUpdateItem]: {
    method: ApiMethod.patch,
    url: '/api/{court_type}/{court_id}/jurisdiction/{id}/',
  },
  [ApiCommand.courtJurisdictionDeleteItem]: {
    method: ApiMethod.delete,
    url: '/api/{court_type}/{court_id}/jurisdiction/{id}/',
  },
  [ApiCommand.regCourtSearch]: {
    method: ApiMethod.get,
    url: '/api/reg_court/search/',
    paramsMap: {
      limit: 'limit',
      regioncode: 'regioncode',
      name: 'name',
    },
  },
  [ApiCommand.regCourtGetList]: {
    method: ApiMethod.get,
    url: '/api/reg_court/',
    paramsMap: {
      limit: 'limit',
      regioncode: 'regioncode',
    },
  },
  [ApiCommand.getAddrObjList]: {
    method: ApiMethod.get,
    url: '/api/gar/addrobj/',
    paramsMap: {
      limit: 'limit',
      parent_id: 'parent_id',
      path: 'path',
      aolevel: 'aolevel',
      name: 'name',
    },
  },
  [ApiCommand.getHouseList]: {
    method: ApiMethod.get,
    url: '/api/gar/house/',
    paramsMap: {
      limit: 'limit',
      parent_id: 'parent_id',
      path: 'path',
      aolevel: 'aolevel',
      name: 'name',
      o: 'ordering',
    },
  },
  [ApiCommand.getFlatList]: {
    method: ApiMethod.get,
    url: '/api/gar/apartment/',
    paramsMap: {
      limit: 'limit',
      parent_id: 'parent_id',
      path: 'path',
      aolevel: 'aolevel',
      name: 'name',
      o: 'o',
    },
  },
  [ApiCommand.taskProgressGetItem]: {
    method: ApiMethod.get,
    url: '/api/progress/{uuid}/',
  },
  [ApiCommand.getStats]: {
    method: ApiMethod.get,
    url: '/api/stats/',
  },
  [ApiCommand.resetCadnumQuery]: {
    method: ApiMethod.delete,
    url: '/api/gar/address/stop_search_cadnum/',
  },
  [ApiCommand.addressDublicateUpdateIsVerifiedById]: {
    method: ApiMethod.post,
    url: '/api/gar/address_dubles/update_verif/',
  },
  [ApiCommand.linkedAddressesDelete]: {
    method: ApiMethod.post,
    url: '/api/gar/address_dubles/delete_items/',
  },
  [ApiCommand.usersGetList]: {
    method: ApiMethod.get,
    url: '/api/users/',
  },
  [ApiCommand.statisticGetList]: {
    method: ApiMethod.get,
    url: '/api/statistic/',
    paramsMap: {
      limit: 'limit',
      offset: 'offset',
      user_in: 'user_in',
      created_at_from: 'created_at_from',
      created_at_to: 'created_at_to',
    },
  },
  [ApiCommand.statisticGetChartData]: {
    method: ApiMethod.post,
    url: '/api/statistic/tile/',
  },
};

const request = createApiRequest({
  apiUrl: standartizeApiUrl,
  getToken: async () => {
    const store = await resolveStore();
    const token = store.getters['user/standartizeToken']?.token;
    return token ? `Token ${token}` : null;
  },
  getApiCommand: (command: ApiCommand) => apiCommands[command],
  tokenField: 'Authorization',
  appendParams: { format: 'json' },
});

export const standartizeRequest = request;

export const mapAddressObjName = (obj: any) => {
  if (obj.typename && obj.name) return `${obj.typename} ${obj.name}`;
  if (obj.housenum) {
    return obj.name;
  } if (obj.number) {
    return obj.number;
  }
  return obj.name;
};

export const mapAddressObj = (obj: GarAddress): GarAddress => ({
  ...obj,
  name: mapAddressObjName(obj),
});

const mapFlatAddressObj = (obj: any): AddrObj & { name: string } => ({
  ...obj,
  name: mapAddressObjName(obj),
});

export const StandartizeApi = {
  auth: {
    logIn: (model: { username: string; password: string }) => request<{ key: string }>({
      command: ApiCommand.logIn,
      data: model,
    }),
  },
  cadnum: {
    getLevels: () => request<CadnumLevelsResponse>({
      command: ApiCommand.cadnumLevelsDataGet,
    }),
    add: (model: { cadnum: string; is_default: 0|1; slug: string }) => request<{ cadnum_suggest: StandartizeAddressCadnumSuggest[]}>({
      command: ApiCommand.cadnumAdd,
      params: model,
    }),
  },

  file: {
    getList: (
      model: StandartizeFileGetListModel,
    ) => request<ListingResponse<StandartizeFile>>({
      command: ApiCommand.fileGetList,
      params: formatListingRequest(model),
    }),
    upload: (model: StandartizeFileUploadModel) => request<StandartizeFile>({
      command: ApiCommand.fileUpload,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: model,
    }),
    deleteFile: (
      id: number,
    ) => request<any>({
      command: ApiCommand.fileDelete,
      params: { id },
    }),
  },
  address: {
    getList: (
      model: GetStandartizeAddressListModel,
    ) => request<ListingResponse<StandartizeAddress>>({
      command: ApiCommand.addressGetList,
      params: formatListingRequest(model),
    }),
    getItem: (slug: string) => request<StandartizeAddress>({
      command: ApiCommand.addressGetItem,
      params: { slug },
    }),
    deleteItem: (slug: string) => request<StandartizeAddress>({
      command: ApiCommand.addressDelete,
      params: { slug },
    }),
    addressUpdateItem: (model: StandartizeAddressUpdateModel) => request<StandartizeAddress>({
      command: ApiCommand.addressUpdateItem,
      params: { slug: model.slug },
      data: model,
    }),
    updateMany: (model: StandartizeAddressUpdateModel[]) => request<StandartizeAddress[]>({
      command: ApiCommand.addressUpdateMany,
      data: model,
    }),
    updateManyByPattern: (
      model: {
        house_fias: number;
        reg_court?: number|null;
        mir_court?: number|null;
        changed_reg_court?: number|null;
        changed_mir_court?: number|null;
      },
    ) => request({
      command: ApiCommand.addressUpdateManyByPattern,
      data: model,
    }),
    doStandartization: (model: GetStandartizeAddressListModel) => request<
      StandartizationResponse
    >({
      command: ApiCommand.doStandartization,
      params: formatListingRequest(model),
    }),
    doSearchCadnum: (model: GetStandartizeAddressListModel) => request<
      StandartizationResponse
    >({
      command: ApiCommand.doSearchCadnum,
      params: formatListingRequest(model),
    }),
    doSelectedStandartization: (model: string[], wsGroup: string, allowLevels: StandartizeCadnumLevel[]|null, searchCadnums?: 1, force?: boolean) => request<
      StandartizationResponse
    >({
      command: ApiCommand.standartizeSelected,
      data: model,
      params: {
        search_cadnums: searchCadnums,
        ws_group: wsGroup,
        ...(force ? { ignore_saved: true } : {}),
        ...(allowLevels ? { allow_levels: allowLevels.join(',') } : {}),
      },
    }),
    updateLinkedAddressStandartization: (model: string[]) => request<StandartizationResponse>({
      command: ApiCommand.linkedAddressUpdateStandartization,
      data: model,
    }),
    updateLinkedAddressSlugs: (model: string[]) => request<StandartizationResponse>({
      command: ApiCommand.linkedAddressUpdateSlugs,
      data: model,
    }),
    deleteLinkedAddresses: (model: string[]) => request<StandartizationResponse>({
      command: ApiCommand.linkedAddressesDelete,
      data: model,
    }),
  },
  fns: {
    search: (
      model: ListingRequestSource<StandartizeSearchModel>,
    ) => request<StandartizeFnsEntry[]>({
      command: ApiCommand.fnsSearch,
      params: model,
    }),
  },
  jurisdiction,
  fssp: {
    getList: (
      model: ListingRequestSource<StandartizeSearchModel>,
    ) => request<ListingResponse<StandartizeFsspEntry>>({
      command: ApiCommand.fsspGetList,
      params: formatListingRequest(model),
    }),
    search: (
      model: ListingRequestSource<StandartizeSearchModel>,
    ) => request<ListingResponse<StandartizeFsspEntry>>({
      command: ApiCommand.fsspSearch,
      params: formatListingRequest(model),
    }),
  },
  mirCourt: {
    getList: (
      model?: ListingRequestSource<{ regioncode: string }>,
    ) => request<ListingResponse<StandartizeCourtEntry>>({
      command: ApiCommand.mirCourtGetList,
      params: model ? formatListingRequest(model) : undefined,
    }),
    updateAddress: (
      model: { id: number; address: string },
    ) => request<StandartizeCourtEntry>({
      command: ApiCommand.mirCourtUpdateAddress,
      params: {
        id: model.id,
      },
      data: {
        info: {
          address: model.address,
        },
      },
    }),
    search: (
      model: ListingRequestSource<StandartizeSearchModel>,
    ) => request<StandartizeCourtEntry[]>({
      command: ApiCommand.mirCourtSearch,
      params: formatListingRequest(model),
    }),
  },
  regCourt: {
    getList: (
      model: ListingRequestSource<{ regioncode: string }>,
    ) => request<ListingResponse<StandartizeCourtEntry>>({
      command: ApiCommand.regCourtGetList,
      params: formatListingRequest(model),
    }),
    search: (
      model: ListingRequestSource<StandartizeSearchModel>,
    ) => request<StandartizeCourtEntry[]>({
      command: ApiCommand.regCourtSearch,
      params: formatListingRequest(model),
    }),
  },
  addrObj: {
    getList: (
      model: ListingRequestSource<SearchAddObjModel>,
    ) => request<ListingResponse<GarAddress>>({
      command: ApiCommand.getAddrObjList,
      params: { ...formatListingRequest(model), isactive: true },
    }).then((r) => {
      if (!r.status) return r;

      return {
        status: true,
        response: {
          ...r.response,
          results: r.response.results.map(mapAddressObj),
        },
      };
    }),
  },
  house: {
    getList: (
      model: ListingRequestSource<
        SearchAddObjModel & { housenum: string; buildnum: string; structnum: string }
        >,
    ) => request<ListingResponse<AddrObj>>({
      command: ApiCommand.getHouseList,
      params: formatListingRequest({
        ...model,
        ordering: [
          {
            key: 'housenum',
            direction: OrderDirection.asc,
          },
        ],
      }),
    }).then((r) => {
      if (!r.status) return r;
      console.log('addrObj.getList sortHouseNum', model);

      const sortHouseNum = (a: { housenum: string }, b: { housenum: string }) => {
        const [num, subnum] = a.housenum.split('/').map((v) => parseInt(v, 10));
        const [num1, subnum1] = b.housenum.split('/').map((v) => parseInt(v, 10));
        return num - num1 || (subnum || 0) - (subnum1 || 0);
      };

      return {
        status: true,
        response: {
          ...r.response,
          // @ts-ignore sortHouseNum
          results: r.response.results.sort(sortHouseNum).map(mapAddressObj),
        },
      };
    }),
  },
  flat: {
    getList: (
      model: ListingRequestSource<SearchAddObjModel & { flatnumber: string; number: string}>,
    ) => request<ListingResponse<AddrObj>>({
      command: ApiCommand.getFlatList,
      params: formatListingRequest({
        ...model,
        ordering: [
          {
            key: 'flatnumber',
            direction: OrderDirection.asc,
          },
          {
            key: 'number',
            direction: OrderDirection.asc,
          },
        ],
      }),
    }).then((r) => {
      if (!r.status) return r;

      return {
        status: true,
        response: {
          ...r.response,
          results: r.response.results.map(mapFlatAddressObj),
        },
      };
    }),
  },
  taskProgress: {
    getItem: (uuid: string) => request<{
      current_progress: number;
      max_progress: number;
      remaining_time: number;
      speed: number;
    }>({
      command: ApiCommand.taskProgressGetItem,
      params: { uuid },
    }),
  },
  getStats: () => request<{ queue_cadnum_search: number }>({
    command: ApiCommand.getStats,
  }),
  resetCadnumQueue: () => request<void>({
    command: ApiCommand.resetCadnumQuery,
  }),
  addressDuplicateUpdateIsVerifiedById: (duplicateId: number) => request<void>({
    command: ApiCommand.addressDublicateUpdateIsVerifiedById,
    data: { id: duplicateId },
  }),
  users: {
    getList: () => request<ListingResponse<StandartizeUser>>({
      command: ApiCommand.usersGetList,
      params: {
        limit: 1000,
      },
    }),
  },
  statistic: {
    getData: (params: ListingRequestSource<{ user_in: number[]; created_at_from: any; created_at_to: any }>) => request<ListingResponse<any>>({
      command: ApiCommand.statisticGetList,
      params: formatListingRequest(params),
    }),
    getChartData: (params: StandartizeChartTileParams) => request<TileDataRaw>({
      command: ApiCommand.statisticGetChartData,
      data: params,
    }),
  },
};
