import { onBeforeUnmount, Ref } from 'vue';

export const useUnsubs = () => {
  const unsubs: Array<(() => void)> = [];
  const unsubAll = () => {
    unsubs.forEach((unsub) => unsub());
    unsubs.splice(0, unsubs.length);
  };

  onBeforeUnmount(unsubAll);
  const unsub = (unsub: () => void) => {
    const i = unsubs.findIndex((s) => s === unsub);
    if (i !== -1) {
      unsub();
      unsubs.splice(
        unsubs.findIndex((s) => s === unsub),
        1,
      );
    }
  };
  const sub = (unsub: () => void) => unsubs.push(unsub);
  return {
    sub, unsub, unsubAll,
  };
};
