import {
  MoveDebtorsResult,
  MoveDebtorsResultConflict, MoveDebtorsResultError,
  MoveDebtorsResultStatus,
  MoveDebtorsResultTotalConflict,
} from '@/types/debtor/move-debtors';
import { IToastLevel } from '@/hooks/useToast';

export const getMoveDebtorsResultToastProps = (
  moveResult: MoveDebtorsResult, rootT: (str: string, opts?: Record<string, string>) => string,
) => {
  const getConflictingDebtorsMessage = (
    moveResult: MoveDebtorsResultConflict|MoveDebtorsResultTotalConflict,
  ) => {
    const ids = moveResult
      .conflictingDebtorsIds;
    return `${ids.join(', ')}(${ids.length})`;
  };
  const { getMessage, level } = ({
    [MoveDebtorsResultStatus.success]: {
      getMessage: () => rootT('moveDebtorsDialog.toast.success'),
      level: IToastLevel.success,
    },
    [MoveDebtorsResultStatus.conflict]: {
      level: IToastLevel.warning,
      getMessage: () => rootT('moveDebtorsDialog.toast.conflict',
        {
          debtor_ids: getConflictingDebtorsMessage(
            moveResult as MoveDebtorsResultConflict,
          ),
        }),
    },
    [MoveDebtorsResultStatus.totalConflict]: {
      level: IToastLevel.warning,
      getMessage: () => rootT('moveDebtorsDialog.toast.totalConflict',
        {
          debtor_ids: getConflictingDebtorsMessage(
            moveResult as MoveDebtorsResultTotalConflict,
          ),
        }),
    },
    [MoveDebtorsResultStatus.error]: {
      getMessage: () => rootT('moveDebtorsDialog.toast.error'),
      level: IToastLevel.danger,
    },
  } as Record<
    MoveDebtorsResultStatus,
    { getMessage: () => string; level: IToastLevel }
    >)[moveResult.status];
  let message = getMessage();

  if ('message' in moveResult) {
    message += `\n${moveResult.message};`;
  }

  return {
    message,
    level,
  };
};
