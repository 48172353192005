import { computed, ref } from 'vue';
import { ProductionType } from '@/hooks/useConstructor';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { useSocket } from '@/hooks/useSocket';
import { IToastLevel, IToastProgressbar, useToast } from '@/hooks/useToast';
import { ApiCommand } from '@/store/modules/api';
import { DataFileSocketMessage, getSocketMessageTaskID, isDataFileEvent } from '@/types/socket';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { IDialog, IDialogComponent, useDialog } from '@/hooks/useDialog';
import { commonLegacyApiRequest as request } from '@core/service/commonService';
import { dateToApiDate } from '@/utils/date';
import { Model } from './types';

export const useBorUpdate = (
  onUpdate?: () => void,
  fullPeriod = false,
) => {
  const { companyId, companyModuleType } = useDefaultCompany();
  const { subscribe } = useSocket();
  const { showToast, showPureDangerToast, showPureInfoToast } = useToast();
  const { showDialog } = useDialog();
  const { awaitSignalResponse, subscribeToSignalOnce } = useSignal();

  return async (
    productionType: ProductionType,
    debtorPks: number[],
  ) => {
    try {
      const filters = await awaitSignalResponse<Record<any, any>>(
        SignalType.getDebtorFilters,
        SignalType.debtorFilters,
      );

      const progressbars = ref<IToastProgressbar[]>([{
        key: 'progress',
        label: 'Обновление данных',
        max: 0,
        current: 0,
      }]);

      const progressbarsComputed = computed(
        () => progressbars.value.filter((v) => v.max),
      );

      const configResult = await new Promise<Model|null>(
        async (resolve, reject) => {
          await showDialog({
            component: IDialogComponent.borUpdate,
            addInRoute: false,
            payload: {
              onSave: (model) => resolve(model),
              fullPeriod,
            },
          });
          subscribeToSignalOnce(SignalType.dialogClosed, (params: IDialog) => {
            if (params.component === IDialogComponent.borUpdate) {
              reject();
            }
          });
        },
      );

      type Response = {
        uuid: string;
        payload: {
          task_count: number;
        };
      }

      const configRequest = fullPeriod ? {
        config: {
          datapool_get_penny_document: true,
        },
        arguments: {
          date: dateToApiDate(configResult!.date!),
        },
      } : {
        config: {
          datapool_set_period: true,
        },
        arguments: {
          date_from: dateToApiDate(configResult!.date_from!),
          date_to: dateToApiDate(configResult!.date_to!),
        },
      };

      // @ts-ignore
      const { status, response } = await request<Response>({
        command: ApiCommand.compositeUpdate,
        params: {
          company_id: companyId.value!,
        },
        data: {
          company: companyId.value!,
          filters: {
            ...filters,
            company_id: companyId.value!,
            production_type: productionType,
            debtor_ids: debtorPks || [],
            module: companyModuleType.value,
          },
          ...configRequest,
        },
      });

      if (!status) {
        await showPureDangerToast({
          params: {
            label: 'Ошибка',
            // @ts-ignore
            message: response.detail,
          },
        });
        return;
      }

      const taskCount = response.payload.task_count || 1;
      let taskCounter = 0;
      progressbars.value = new Array(taskCount).fill(null).map((_, i) => ({
        key: `stage_${i + 1}`,
        label: '...',
        message: undefined,
        max: 0,
        current: 0,
        showMessageInsteadOfLabel: true,
      }));

      const hideToast = await showPureInfoToast({
        duration: null,
        params: { message: 'Обновление данных' },
        progressbars: progressbarsComputed,
      });

      const updateStage = (
        stage: number,
        { message, max, current }: { message?: string; max?: number; current?: number },
      ) => {
        const currentProgress = progressbars.value.find(({ key }) => key === `stage_${stage}`);
        if (currentProgress) {
          if (message) {
            currentProgress.message = `${message}: ${current} из ${max}`;
          } else {
            const msg = currentProgress.message ? currentProgress.message!.split(':')[0] : 'Прогресс';
            currentProgress.message = `${msg}: ${current} из ${max}`;
          }
          if (max) {
            currentProgress.max = max;
          }
          if (current) {
            currentProgress.current = current;
          }
        }
      };

      const socketUnsub = await subscribe<DataFileSocketMessage>({
        condition: (msg) => (
          msg.action === 'progress_event'
            && isDataFileEvent(msg)
            && getSocketMessageTaskID(msg) === response.uuid
        ),
        handler: async (payload) => {
          let max = 0;
          let current = 0;
          let message = '';
          if (payload?.data?.progress) {
            max = payload.data.progress.total;
            current = payload.data.progress.step;
          }
          if (payload?.data?.description) {
            message = payload.data.description;
          }
          if (payload?.data?.obj?.status_text) {
            message = (message ? `${message} ` : '') + payload.data.obj?.status_text;
          }
          updateStage(taskCounter + 1, { message, max, current });
          if (payload.data.done) {
            taskCounter += 1;
          }
          if (taskCounter >= taskCount) {
            await showToast({
              duration: 3000,
              label: 'debtor.common.update_data_toast.success',
              message,
              level: IToastLevel.success,
            });
            if (onUpdate) {
              onUpdate();
            }
            socketUnsub();
            await hideToast();
          }
        },
      });

    } catch (e) {
      if (e !== undefined) {
        console.error(e);
      }
    }
  };
};
