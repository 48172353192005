import { PERIODIC_TASK_MINUTES, PeriodicTaskSchedule } from '@/hooks/useSchedule';
import {
  computed, ref, SetupContext, toRefs, watch,
} from 'vue';
import { useLocalValue } from '@/hooks/useLocalValue';
import { MONTH_TRANSLATIONS_FULL } from '@/utils/date';
import { getTimeIntervals } from '@/utils/time';
import {
  getInitValueDaysOfMonth,
  getInitValueDaysOfWeek,
  getInitValueEveryDay,
  getInitValueMonthOfYear,
  IScheduleSelector,
  isValidValue,
  modelValueToTab,
  weekDayOptions,
  ScheduleSelectorTab,
  getInitValueMonthsDays,
  ScheduleSelectorForm, scheduleToFormValue, formValueToSchedule,
} from '@/components/scheduleSelector/utils';
import { useMonthsDays } from '@/components/scheduleSelector/useMonthsDays';

export const useScheduleSelector = (props: IScheduleSelector, emit: SetupContext['emit'] | any) => {
  const { modelValue } = toRefs(props);
  const localValue = useLocalValue(props, 'modelValue', emit, true);

  const getTabValue = (tab: ScheduleSelectorTab) => {
    switch (tab) {
      case 'everyday':
        return getInitValueEveryDay;
      case 'daysOfMonth':
        return getInitValueDaysOfMonth;
      case 'daysOfWeek':
        return getInitValueDaysOfWeek;
      case 'monthsOfYear':
        return getInitValueMonthOfYear;
      case 'monthsDays':
        return getInitValueMonthsDays;
      default:
        return getInitValueEveryDay;
    }
  };

  const getInitValue = computed(() => {
    const defaultTab = props.availableTabs?.[0];
    return getTabValue(defaultTab as ScheduleSelectorTab);
  });

  const formValue = ref<ScheduleSelectorForm>(getInitValue.value());

  watch(getInitValue, () => {
    formValue.value = getInitValue.value();
  });

  const tab = ref<ScheduleSelectorTab>('everyday');

  const setDefaultTabAndFormValue = () => {
    tab.value = props.availableTabs?.[0] as ScheduleSelectorTab;
    const initValue = getInitValue.value();
    formValue.value = initValue;
  };

  watch(localValue, (v) => {
    if (!v) {
      setDefaultTabAndFormValue();
    } else {
      const localTab = modelValueToTab(v);
      if (!localTab) {
        setDefaultTabAndFormValue();
      } else {
        tab.value = localTab;
        formValue.value = scheduleToFormValue(v);
      }
    }
  }, { immediate: true });

  const valueIsValid = computed(() => isValidValue(formValue.value));

  const tabs = computed(
    () => ([
      {
        label: 'Каждый день',
        key: 'everyday',
      },
      {
        label: 'Дни недели',
        key: 'daysOfWeek',
      },
      {
        label: 'Дни месяца',
        key: 'daysOfMonth',
      },
      {
        label: 'Дни года',
        key: 'monthsDays',
      },
      {
        label: 'Месяцы',
        key: 'monthsOfYear',
      },
      {
        label: '',
        key: 'justTime',
      },
    ] as { label: string; key: ScheduleSelectorTab }[]).filter(
      ({ key }) => props.availableTabs?.includes(key),
    ) as { label: string; key: ScheduleSelectorTab }[],
  );

  const daysOfWeek = computed({
    get: () => {
      if (!formValue.value.day_of_week) {
        return [];
      }
      if (formValue.value.day_of_week === '*') {
        return weekDayOptions.map(
          ({ key }) => key,
        );
      }
      return formValue.value.day_of_week.split(',');
    },
    set: (value: string[]) => {
      formValue.value.day_of_week = value.join(',');
      if (props.hideActions) save();
    },
  });

  const monthDayOptions = new Array(31).fill(null).map(
    (_, i) => (i + 1).toString(),
  );

  const daysOfMonth = computed({
    get: () => {
      if (!formValue.value.day_of_month) {
        return [];
      }
      if (formValue.value.day_of_month === '*') {
        return [...monthDayOptions];
      }
      return formValue.value.day_of_month.split(',');
    },
    set: (value: string[]) => {
      formValue.value.day_of_month = value.join(',');
      if (props.hideActions) save();
    },
  });

  const monthYearOptions = new Array(12).fill(null).map(
    (_, i) => ({
      label: MONTH_TRANSLATIONS_FULL[i + 1],
      key: (i + 1).toString(),
    }),
  );
  const monthOfYear = computed({
    get: () => {
      if (!formValue.value.month_of_year) {
        return [];
      }
      if (formValue.value.month_of_year === '*') {
        return [...monthYearOptions.map(({ key }) => key)];
      }
      return formValue.value.month_of_year.split(',');
    },
    set: (value: string[]) => {
      formValue.value.month_of_year = value.join(',');
      if (props.hideActions) save();
    },
  });

  const onMonthOfYearUpdate = (months: string[]) => {
    monthOfYear.value = months;
  };

  const hourOptions = getTimeIntervals(0, 23)
    .map((value) => ({ label: value.split(':')[0], value: String(+value.split(':')[0]) }));

  const hour = computed({
    get: () => `${formValue.value.hour as string}`,
    set: (v: string) => {
      formValue.value.hour = v;
      if (props.hideActions) save();
    },
  });

  const minuteOptions = PERIODIC_TASK_MINUTES.map(
    (v) => ({ label: v.length === 1 ? `0${v}` : v, value: v }),
  );

  const minute = computed<string>({
    get: () => {
      const v = formValue.value.minute;
      return v;
    },
    set: (v: string) => {
      formValue.value.minute = v;
      if (props.hideActions) save();
    },
  });

  const monthsDays = useMonthsDays(formValue, tab);

  const onSelectTab = (value: ScheduleSelectorTab) => {
    tab.value = value;
    if (value === 'daysOfWeek') {
      formValue.value.day_of_week = null;
      formValue.value.day_of_month = '*';
      formValue.value.month_of_year = '*';
      formValue.value.month_days = null;
    } else if (value === 'everyday') {
      formValue.value.day_of_week = '*';
      formValue.value.day_of_month = '*';
      formValue.value.month_of_year = '*';
      formValue.value.month_days = null;
    } else if (value === 'daysOfMonth') {
      formValue.value.day_of_week = '*';
      formValue.value.day_of_month = null;
      formValue.value.month_of_year = '*';
      formValue.value.month_days = null;
    } else if (value === 'monthsOfYear') {
      formValue.value.day_of_week = '*';
      formValue.value.day_of_month = '1';
      formValue.value.month_of_year = '1,2,3,4,5,6,7,8,9,10,11,12';
      formValue.value.month_days = null;
    } else if (value === 'monthsDays') {
      formValue.value.day_of_month = null;
      formValue.value.day_of_week = null;
      formValue.value.month_of_year = null;
      formValue.value.month_days = [['1', ['1']]];
    }
    if (props.hideActions) save();
  };

  const onDaysUpdate = (days: string[]) => {
    if (props.allowOneDayOnly) {
      if (days.length) {
        daysOfMonth.value = [days[days.length - 1]];
      } else {
        daysOfMonth.value = [];
      }
    } else {
      daysOfMonth.value = days;
    }
  };

  const reset = () => {
    const v = localValue.value;
    if (!v) {
      setDefaultTabAndFormValue();
    } else {
      const localTab = modelValueToTab(v);
      if (!localTab) {
        setDefaultTabAndFormValue();
      } else {
        tab.value = localTab;
        formValue.value = scheduleToFormValue(v);
      }
    }
  };

  const save = () => {
    if (isValidValue(formValue.value)) {
      localValue.value = formValueToSchedule(formValue.value);
    }
    emit('close');
  };

  const hasTabs = computed(
    () => props.availableTabs?.length,
  );

  return {
    tab,
    tabs,
    formValue,
    onSelectTab,
    weekDayOptions,
    daysOfWeek,
    monthDayOptions,
    daysOfMonth,
    monthYearOptions,
    monthOfYear,
    onMonthOfYearUpdate,
    hourOptions,
    hour,
    minuteOptions,
    minute,
    onDaysUpdate,
    reset,
    save,
    valueIsValid,
    hasTabs,
    MONTH_TRANSLATIONS_FULL,
    ...monthsDays,
  };
};
