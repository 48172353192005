import {
  computed, h, ref, watch,
} from 'vue';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import {
  ActiveFormField,
  ActiveFormFieldType, ActiveFormIonFieldGroup, ActiveFormIonGroup,
  ActiveFormIonListGroup,
} from '@/hooks/useActiveForm';
import SocialNetworkIcon from '@core/components/socialNetworkIcon/SocialNetworkIcon.vue';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import {
  DebtorModel,
  SOCIAL_NETWORKS,
  UpdateDebtorMainProfileModel,
  useDebtors,
} from '@/hooks/useDebtors';
import {
  getEmptyModel,
  mapDebtorToModel,
  debtorIsOrganization,
  mapDebtorModelToPayload,
} from '@/components/dialog/dialogs/debtor/tabs/common/tabs/main/useDebtorModel/utils';
import { SourceErrors, useErrors } from '@/hooks/useErrors';
import {
  DebtorAddressStatusComponentKey,
  DebtorRegistrationAddressStatusComponentKey,
} from '@/symbols';
import {
  useDebtorPhoneNumbers,
} from '@/components/dialog/dialogs/debtor/tabs/common/tabs/main/useDebtorPhoneNumbers';
import { isPerson } from '@/components/dialog/dialogs/debtor/isOrganization';

export const useDebtorModel = () => {
  const { t } = useLocalI18n('debtor.common.main.field');
  const {
    debtor, productionType, fetchDebtorData,
  } = useInjectDebtorDialog();
  const { fields: phoneFields, submitPhones } = useDebtorPhoneNumbers(debtor, productionType);
  const { fields: tenantsPhoneFields } = useDebtorPhoneNumbers(debtor, productionType, true, { isTenants: true });

  const DebtorAddressStatusComponent = useProtectedInject(DebtorAddressStatusComponentKey);
  const DebtorRegistrationAddressStatusComponent = useProtectedInject(DebtorRegistrationAddressStatusComponentKey);
  const {
    updateDebtorMainProfile,
  } = useDebtors();

  const emailGroup = {
    tag: 'listGroup',
    key: 'emails',
    state: ['primary'],
    isDeletable: true,
    isAddable: true,
    label: t('email'),
    renderReadonly: (e) => e.map((em: { email: string }) => em.email).join(', '),
  } as ActiveFormIonListGroup;

  const socialNetworksGroup = {
    tag: 'listGroup',
    key: 'social_networks',
    label: 'Социальные сети',
    isDeletable: true,
    isAddable: true,
    withInitValue: true,
    state: ['horizontal'],
    renderReadonly: (
      socialNetworks: { key: string; value: string }[],
    ) => socialNetworks.map(({ key, value }) => h(SocialNetworkIcon, {
      link: value, type: key,
    })),
  } as ActiveFormIonListGroup;

  const initModel = ref<DebtorModel>(getEmptyModel(debtor.value));
  const model = ref<DebtorModel>(getEmptyModel(debtor.value));
  const isEditing = ref(false);

  const numberField = {
    key: 'personal_account',
    label: 'ЛС / Договор',
    type: ActiveFormFieldType.input,
    isReadonly: true,
    state: ['horizontal', 'primary'],
  };

  const personFields = computed(() => (([
    numberField,
    {
      key: 'full_name',
      label: t('full_name'),
      type: ActiveFormFieldType.input,
      state: ['horizontal', 'primary'],
      options: {
        placeholder: t('address'),
      },
    },
    {
      key: 'address',
      label: t('address'),
      type: ActiveFormFieldType.input,
      state: ['horizontal', 'primary'],
      options: {
        placeholder: t('address'),
      },
      renderValue: (
        // @ts-ignore
      ) => h(DebtorAddressStatusComponent, { debtor: debtor.value, productionType: productionType.value }, []),
    },
    {
      key: 'email',
      type: ActiveFormFieldType.input,
      state: ['primary'],
      ionFieldGroup: emailGroup,
    },
    ...phoneFields,
    ...(isPerson(model.value) ? tenantsPhoneFields : []),
    {
      key: 'key',
      label: t('social_networks.key'),
      type: ActiveFormFieldType.select,
      state: ['primary'],
      options: {
        displayField: 'name',
        valueField: 'key',
        options: SOCIAL_NETWORKS,
      },
      ionFieldGroup: socialNetworksGroup,
    },
    {
      key: 'value',
      label: t('social_networks.value'),
      type: ActiveFormFieldType.input,
      state: ['primary'],
      hint: ({ model, keyPath }: { model: any; keyPath: [string, string, string]}) => {
        const [groupKey, listNumber] = keyPath;
        const record = model?.[groupKey]?.[listNumber];
        if (!record?.key) return null;
        return SOCIAL_NETWORKS.find((obj) => obj.key === record.key)?.hint;
      },
      ionFieldGroup: socialNetworksGroup,
    },
  ]) as ActiveFormField<any>[]).map((field) => ({
    ...field,
    id: `modal_debtor_common_tab_form_field_${field.key as string}`,
    options: {
      ...field.options,
      ...(!field.ionFieldGroup && { id: `modal_debtor_common_tab_form_${field.type}_${field.key as string}` }),
    },
  })) as ActiveFormField<any>[]);

  const orgFields = computed(() => (([
    numberField,
    {
      key: 'name',
      label: t('name'),
      type: ActiveFormFieldType.input,
      isReadonly: true,
      state: ['horizontal', 'primary'],
      options: {
        placeholder: t('name'),
      },
    },
    {
      key: 'legal_address',
      label: t('legal_address'),
      type: ActiveFormFieldType.input,
      isReadonly: true,
      state: ['horizontal', 'primary'],
      options: {
        placeholder: t('legal_address'),
      },
      renderValue: (
        // @ts-ignore
      ) => h(DebtorAddressStatusComponent, { debtor: debtor.value, productionType: productionType.value }, []),
    },
    {
      key: 'physical_address',
      label: t('physical_address'),
      type: ActiveFormFieldType.input,
      isReadonly: true,
      state: ['horizontal', 'primary'],
      options: {
        placeholder: t('physical_address'),
      },
    },
    {
      key: 'mail_address',
      label: t('mail_address'),
      type: ActiveFormFieldType.input,
      isReadonly: true,
      state: ['horizontal', 'primary'],
      options: {
        placeholder: t('mail_address'),
      },
    },
    {
      key: 'email',
      type: ActiveFormFieldType.input,
      state: ['primary'],
      ionFieldGroup: emailGroup,
    },
    ...phoneFields,
  ]) as ActiveFormField<any>[]).map((field) => ({
    ...field,
    id: `modal_debtor_common_tab_form_field_${field.key as string}`,
    options: {
      ...field.options,
      id: `modal_debtor_common_tab_form_${field.type}_${field.key as string}`,
    },
  })) as ActiveFormField<any>[]);

  const fields = computed(() => (debtorIsOrganization(debtor.value)
    ? orgFields
    : personFields));

  watch(debtor, (d) => {
    const m = mapDebtorToModel(d);
    initModel.value = JSON.parse(JSON.stringify(m));
    model.value = JSON.parse(JSON.stringify(m));
  }, { immediate: true });

  const {
    errorsMap,
    setErrors,
    clearErrors,
  } = useErrors<keyof UpdateDebtorMainProfileModel>();

  const updateModel = (m: DebtorModel) => {
    model.value = m;
  };

  const submit = async () => {
    clearErrors();

    if (isPerson(model.value)) {
      await submitPhones(initModel.value.phone_numbers, model.value.phone_numbers, model.value.tenantId);
    } else {
      await submitPhones(initModel.value.phone_numbers, model.value.phone_numbers);
    }

    const { status, response } = await updateDebtorMainProfile({
      id: debtor.value!.debtor_main_profile.id,
      debtorId: debtor.value!.debtor.pk,
      model: mapDebtorModelToPayload(model.value),
      productionType: productionType.value,
    });

    if (!status) {
      setErrors(
        Object.entries(response) as
          unknown as SourceErrors<keyof UpdateDebtorMainProfileModel>,
      );
    } else {
      await fetchDebtorData();
      isEditing.value = false;
    }
  };

  const save = (m: DebtorModel) => {
    updateModel(m);
    return submit();
  };

  return {
    model,
    fields,
    save,
    errorsMap,
    isEditing,
  };
};
