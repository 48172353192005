import { Debtor } from '@/hooks/useDebtors';
import { copy2DToClipboard } from '@/utils/string';

export const copyDebtorsToClipboard = (debtors: Debtor[], getField: (debtor: Debtor) => any) => {
  const toCopy = debtors.map(
    (d) => [getField(d)],
  );
  console.log('copyDebtorsToClipboard', toCopy);
  copy2DToClipboard(toCopy);
};
