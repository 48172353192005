import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useUnsubs } from '@/hooks/useUnsubs';
import { Certificate } from '@urrobot/core/plugins/cryptoPro/getUserCertificates';

export const useLocalEncryptSignSelect = () => {
  const { showDialog } = useDialog();
  const { subscribeToSignal } = useSignal();
  const { sub, unsubAll } = useUnsubs();

  return async () => new Promise<Certificate|null>(async (resolve) => {
    const closeDialog = await showDialog({
      title: 'Выберите сертификат',
      component: IDialogComponent.localEncryptSignSelect,
      payload: {
        onSignSelected: (cert) => {
          resolve(cert);
          unsubAll();
        },
      },
      addInRoute: false,
    });
    sub(closeDialog);

    sub(
      subscribeToSignal(
        SignalType.dialogClosed,
        ({ component }: { component: IDialogComponent}) => {
          if (component === IDialogComponent.localEncryptSignSelect) {
            resolve(null);
          }
        },
      ),
    );
  });
};
