import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import {
  computed, onBeforeUnmount, ref, watch,
} from 'vue';
import { Tenant, useDebtors } from '@/hooks/useDebtors';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { FetchDebtorDataKey } from '@/symbols';
import {
  ActionType,
  ActiveTableAction,
  ActiveTableColumnFormat,
  useActiveTable,
} from '@/components/activeTable/useActiveTable';
import { parseDate } from '@/utils/dateFns';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { DictType, useDicts } from '@/hooks/useDicts';
import { SourceErrors, useErrors } from '@/hooks/useErrors';
import { dateToApiDate } from '@/utils/date';
import { useDebtorTenantsSave } from '@/components/dialog/dialogs/debtor/tabs/common/useDebtorTenantsSave';

export const useDebtorCommonPersonDetails = () => {
  const { t } = useLocalI18n('debtor.common.tenants');
  const { debtor, productionType } = useInjectDebtorDialog();
  const { saveAll } = useDebtorTenantsSave();
  const { getDict } = useDicts();
  const { errorsMap, clearErrors, setErrors } = useErrors<any>();

  const editingItems = ref<Array<Tenant['id']>>([]);
  const toRemove = ref<Array<Tenant['id']>>([]);

  const unsubs: Array<(() => void)> = [];
  onBeforeUnmount(() => {
    unsubs.forEach((unsub) => unsub());
    unsubs.splice(0, unsubs.length);
  });

  const fetchData = useProtectedInject(FetchDebtorDataKey);

  const {
    columns,
    records,
    actions,
    fetchData: refetchData,
  } = useActiveTable<Tenant, Tenant, 'id'>({
    keyField: 'id',
    async fetch() {
      editingItems.value = [];
      toRemove.value = [];
      return {
        count: debtor.value?.debtor_tenant_profiles?.length || 0,
        results: ([...debtor.value?.debtor_tenant_profiles || []]).map((tenant) => ({
          ...tenant,
          ...([
            'birth_date',
            'date_of_passport_issue',
            'registration_date',
          ] as Array<keyof Tenant>).reduce((acc, cur) => ({
            ...acc,
            [cur]: parseDate(tenant[cur] as Tenant[keyof Tenant] & Date),
          }), {} as unknown as Tenant),
          relationships: tenant.relationships[0] || null as any,
          num_of_passport: parseInt(tenant.num_of_passport || '', 10) ? tenant.num_of_passport : null,
        })),
      };
    },
    filters: computed(() => ([{
      key: 'id',
      field: 'id',
      type: ActiveFormFieldType.input,
      defaultValue: debtor.value?.debtor.pk,
    }, {
      key: 'production_type',
      field: 'production_type',
      type: ActiveFormFieldType.input,
      defaultValue: productionType.value,
    }])),
    columns: computed(() => ([
      { key: 'full_name', width: 1.5 },
      { key: 'birth_date', format: ActiveTableColumnFormat.date },
      { key: 'birth_place', width: 1.5 },
      { key: 'num_of_passport', width: 1.5 },
      { key: 'inn', width: 1.5 },
      { key: 'snils', width: 1.5 },
      { key: 'date_of_passport_issue', format: ActiveTableColumnFormat.date },
      { key: 'passport_issued_by', width: 2 },
      { key: 'registration_address_st', width: 3 },
      { key: 'registration', width: 1.5 },
      { key: 'registration_date', format: ActiveTableColumnFormat.date, allowEmpty: true },
      { key: 'gender', width: 0.5, format: (value: any) => (value ? t(`gender.${value}`) : null) },
    ].map((column) => ({
      width: 1,
      ...column,
      field: column.key,
      label: t(`column.${column.key}`),
    })))),
    actions: computed(() => ([
      {
        key: 'edit',
        icon: 'pencil',
        types: [ActionType.record],
        id: 'modal_debtor_common_tab_person_details_tab_edit_action',
        async handler({ selectedItems: [itemId] }) {
          editingItems.value.push(itemId);
        },
        check: ({ record: { id } }) => !editingItems.value.includes(id),
      },
    ] as Array<ActiveTableAction<any, any> | boolean>)
      .filter(Boolean) as Array<ActiveTableAction<any, any>>),
  });

  const registrationOptions = computed(() => (
    [
      { value: 1, label: t('registration.permanent') },
      { value: 2, label: t('registration.temporary') }]
  ));
  const relationshipsOptions = computed(() => (
    getDict(DictType.tenantRelationships).value
  ));

  const reset = async () => {
    editingItems.value = [];
    toRemove.value = [];
    await refetchData();
  };

  const submit = async () => {
    clearErrors();
    const set = new Set();
    editingItems.value.forEach((e) => set.add(e));
    const errors = await saveAll({
      records: records.value.filter((e) => set.has(e.id)),
      toRemove: toRemove.value,
      productionType: productionType.value,
      debtor: debtor.value!.debtor.pk,
    });
    setErrors(errors);
    if (errors.length > 0) {
      return;
    }
    await fetchData?.();
    await reset();
  };

  const add = () => {
    records.value.push(({
      full_name: '',
      birth_date: null,
      birth_place: '',
      citizenship: '',
      num_of_passport: '',
      inn: '',
      snils: '',
      date_of_passport_issue: null,
      passport_issued_by: '',
      registration: null,
      registration_date: null,
      relationships: null as any,
    } as Pick<Tenant,
      'full_name'
      | 'birth_date'
      | 'birth_place'
      | 'citizenship'
      | 'num_of_passport'
      | 'inn'
      | 'date_of_passport_issue'
      | 'passport_issued_by'
      | 'registration'
      | 'registration_date'
      | 'relationships'
      >) as Tenant);
  };

  watch(debtor, () => {
    refetchData();
  });

  return {
    submit,
    reset,
    add,

    t,

    columns,
    records,
    actions,
    editingItems,
    toRemove,
    registrationOptions,
    relationshipsOptions,
    errorsMap,
    refetchData,
  };
};
