import { Module, Plugin } from 'vuex';
import {
  AddCompanyModel,
  Company,
  FetchCompaniesResponse,
  FetchCompanyModel,
  FetchCompanySettingsModel,
  FetchCompanySettingsResponse,
  FetchDefaultCompanyIdModel,
  FetchDefaultCompanyIdResponse,
  FetchRegionsModel,
  FetchRegionsResponse,
  FileCompanySettings,
  Region,
  RemoveCompanyModel,
  RemoveCompanyResponse,
  SetDefaultCompanyIdModel,
  SetDefaultCompanyIdResponse,
  UpdateCompanyModel,
  UpdateCompanyResponse,
  UpdateCompanySettingsModel,
  UpdateCompanySettingsResponse, UpdateFileCompanySettingsModel,
  UpdateFileCompanySettingsResponse,
} from '@/hooks/useCompanies';
import { StoreState } from '@/store';
import {
  ApiCommand, ApiRequest, ApiResponse, ListingRequest, ListingResponse,
} from '@/store/modules/api';
import { formatListingRequest, formatListingResponse, getDefaultListingRequestSource } from '@/components/activeTable/useActiveTable';
import { SignalType } from '@/hooks/useSignal';
import {
  CompanyPermission,
  CompanyPermissionRaw, mapRawPermissionsResponse,
  PermissionsModel,
  permissionsToModel,
} from '@/hooks/useCompanyPermissionsApi';
import { Employee, EmployeeRole } from '@/hooks/useEmployees';
import { SocketSubscriber } from '@/store/modules/socket';
import { FetchActiveServicesResponse, FetchAvailableServicesResponse, FinanceServiceHandle } from '@/hooks/useFinance';
import { ACLRule, fetchACL } from '@/hooks/useACL';
import { PermissionType } from '@/hooks/useDefaultCompanyPermissions';
import { wait } from '@/utils/common';
import { unwrapFirstRecord, unwrapListingApiResponse } from '@/service/api';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';

export type CompaniesState = {
  companies: Array<Company>;
  defaultCompanyId: Company['id'] | null;
  defaultCompanyHasBilling: boolean;
  defaultCompanyLogo: string|null;
  defaultCompanyLogoName: string|null;
  defaultCompanyACL: Record<ACLRule, boolean>;
  permissions: PermissionsModel;
  employeeRoles: {
    isOwner: boolean;
    employeeRole: EmployeeRole|null;
  };
}

type CompaniesModule = Module<CompaniesState, StoreState>;

export const namespaced = true;

export const state: CompaniesModule['state'] = () => ({
  companies: [],
  defaultCompanyId: null,
  defaultCompanyHasBilling: false,
  defaultCompanyLogo: null,
  defaultCompanyLogoName: '© ЮРРОБОТ',
  defaultCompanyACL: {} as Record<ACLRule, boolean>,
  defaultCompanyAvailableServices: [] as FinanceServiceHandle[],
  permissions: {
    [PermissionType.pretrial]: null,
    [PermissionType.judicial]: null,
    [PermissionType.executive]: null,
  },
  employeeRoles: {
    isOwner: false,
    employeeRole: null,
  },
});

export const getters: CompaniesModule['getters'] = {
  companies: (state) => state.companies,
  defaultCompanyId: (state) => state.defaultCompanyId,
  urrobotLogo: (
    state,
  ) => require('@/components/logo/assets/defaultLogo.svg'),
  defaultCompanyLogo: (
    state, getters,
  ) => state.defaultCompanyLogo || getters.urrobotLogo,
  defaultCompanyLogoName: (
    state,
  ) => `${state.defaultCompanyLogoName || '© ЮРРОБОТ'}`,
  defaultCompany: (state) => {
    const found = state.companies.find(
      ({ id }) => id === state.defaultCompanyId,
    );
    if (!found) {
      return null;
    }
    return {
      ...found,
      // пока что в "оказание услуг" все то же самое, что в "ЖКХ"
      module: found.module === 3 ? 1 : found.module,
    };
  },
  defaultCompanyACL: (state, getters) => ({
    ...state.defaultCompanyACL,
    [ACLRule.organizationsIndex]: getters.permissions[PermissionType.organizations],
    [ACLRule.debtorsIndex]: [PermissionType.pretrial, PermissionType.judicial, PermissionType.executive].some(
      (type) => getters.permissions[type],
    ),
  }),
  defaultCompanyHasBilling: (state) => state.defaultCompanyHasBilling,
  permissions: (state) => state.permissions,
  employeeRoles: (state) => state.employeeRoles,
};

export const mutations: CompaniesModule['mutations'] = {
  setCompanies: (state, companies: Array<Company>) => {
    state.companies = companies;
  },
  updateCompanyBalance: (state, { companyId, balance }: { companyId: number; balance: number }) => {
    const company = state.companies.find((c) => c.id === companyId);
    if (company) {
      company.balance = balance;
    }
  },
  setPermissions: (state, permissions) => {
    state.permissions = permissions;
  },
  setEmployeeRoles: (state, roles) => {
    state.employeeRoles = roles;
  },
  setDefaultCompanyId: (state, companyId: Company['id']) => {
    state.defaultCompanyId = companyId;
  },
  setDefaultCompanyLogoInfo: (
    state,
    { logo, logo_name }: { logo: string|null; logo_name: string|null},
  ) => {
    state.defaultCompanyLogo = logo;
    state.defaultCompanyLogoName = logo_name;
  },
  setDefaultCompanyACL: (state, v: Record<ACLRule, boolean>) => {
    state.defaultCompanyACL = v;
  },
  setDefaultCompanyBillingAvailability: (state, v: boolean) => {
    state.defaultCompanyHasBilling = v;
  },
};

export const actions: CompaniesModule['actions'] = {

  async fetchCompanies({ dispatch, rootState }, request = getDefaultListingRequestSource<Company>('id')) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.fetchCompanies,
      params: formatListingRequest<ListingRequest<Company>>(request),
      signal: request.signal,
    } as ApiRequest, { root: true })) as ApiResponse<FetchCompaniesResponse>;

    const userId = rootState.user.data!.id;
    const demoCompany = response.results.find((c) => c.owner === userId && c.is_demo);
    if (demoCompany) {
      if (!demoCompany.is_demo_ready) {
        await wait(1000);
        return dispatch('fetchCompanies', request);
      }
    }

    const resp = {
      ...response,
      results: response.results.map((item) => ({
        ...item,
        name_with_branch: (item.name_short ?? item.name_full ?? '') + (item.name_branch ? ` (${item.name_branch})` : ''),
      })),
    };

    return {
      status,
      response: formatListingResponse(resp),
    } as ApiResponse<FetchCompaniesResponse>;
  },
  async fetchRegions({ dispatch }, request = getDefaultListingRequestSource<Region>('id')) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.fetchRegions,
      params: formatListingRequest<FetchRegionsModel>(request),
      signal: request.signal,
    } as ApiRequest, { root: true })) as ApiResponse<any>;

    return {
      status,
      response: formatListingResponse(response),
    } as ApiResponse<FetchRegionsResponse>;
  },
  async fetchCompany({ dispatch }, { id }: FetchCompanyModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.fetchCompany,
      params: {
        id,
      },
    } as ApiRequest, { root: true })) as ApiResponse<FetchCompaniesResponse>;

    return {
      status,
      response,
    } as ApiResponse<FetchCompaniesResponse>;
  },
  async fetchCompanySettings({ dispatch }, { id }: FetchCompanySettingsModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.fetchCompanySettings,
      params: {
        id,
      },
    } as ApiRequest, { root: true })) as ApiResponse<FetchCompanySettingsResponse>;

    return {
      status,
      response,
    } as ApiResponse<FetchCompanySettingsResponse>;
  },
  async fetchFileCompanySettings({ dispatch }, { id }: FetchCompanySettingsModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.fetchFileCompanySettings,
      params: {
        id,
      },
    } as ApiRequest, { root: true })) as ApiResponse<FileCompanySettings>;

    return {
      status,
      response,
    } as ApiResponse<FileCompanySettings>;
  },
  async updateCompanySettings({ commit, dispatch }, { id, payload }: UpdateCompanySettingsModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.updateCompanySettings,
      params: {
        id,
      },
      data: payload,
    } as ApiRequest<UpdateCompanySettingsModel>, {
      root: true,
    })) as ApiResponse<UpdateCompanySettingsResponse>;

    if (status) {
      commit('layout/signal', {
        signalType: SignalType.companySettingsUpdated,
        payload: {
          id,
          payload,
        },
      }, { root: true });
    }

    return {
      status,
      response,
    } as ApiResponse<UpdateCompanySettingsResponse>;
  },
  async updateFileCompanySettings({ commit, dispatch }, { id, payload }: UpdateFileCompanySettingsModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.updateFileCompanySettings,
      params: {
        id,
      },
      data: payload,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } as ApiRequest<UpdateFileCompanySettingsModel>, {
      root: true,
    })) as ApiResponse<UpdateFileCompanySettingsResponse>;

    if (status) {
      commit('layout/signal', {
        signalType: SignalType.companyFileSettingsUpdated,
        payload: {
          id,
          payload,
        },
      }, { root: true });
    }

    return {
      status,
      response,
    } as ApiResponse<UpdateFileCompanySettingsResponse>;
  },
  async removeCompany({ commit, dispatch }, { id }: RemoveCompanyModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.removeCompany,
      params: {
        id,
      },
    } as ApiRequest, { root: true })) as ApiResponse<RemoveCompanyResponse>;

    if (status) {
      commit('layout/signal', {
        signalType: SignalType.companyDeleted,
        payload: {
          id,
        },
      }, { root: true });
    }

    return {
      status,
      response,
    } as ApiResponse<RemoveCompanyResponse>;
  },
  async fetchDefaultCompanyId({ commit, rootGetters, dispatch }) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.fetchDefaultCompanyId,
      params: {
        id: rootGetters['user/data']?.id,
      },
    } as ApiRequest<FetchDefaultCompanyIdModel>, {
      root: true,
    })) as ApiResponse<FetchDefaultCompanyIdResponse>;

    if (status) {
      commit('setDefaultCompanyId', response.default_company);

      if (response.default_company) {
        dispatch('fetchPermissions');
      }
    }

    return {
      status,
      response,
    } as ApiResponse<FetchDefaultCompanyIdResponse>;
  },
  async fetchDefaultCompanyLogo({ commit, getters, dispatch }) {
    const id = getters.defaultCompanyId;
    if (!id) {
      return;
    }
    const { status, response } = await dispatch(
      'fetchCompanySettings', { id },
    ) as ApiResponse<FetchCompanySettingsResponse>;

    if (status) {
      commit('setDefaultCompanyLogoInfo', {
        logo: response.company_logo,
        logo_name: response.company_logo_name,
      });
    }
  },
  async fetchDefaultCompanyBillingAvailability({ commit, rootGetters, getters }) {
    const company = getters.defaultCompanyId;
    const responses = await Promise.all([
      commonLegacyApiRequest<ListingResponse<any>>({
        command: ApiCommand.scheduleCompanyPeriodicTaskGetList,
        params: {
          company,
          limit: 1000,
          offset: 0,
          name: 'scheduler.billing.mosvodocanal',
        },
      }).then(unwrapFirstRecord),
      commonLegacyApiRequest<ListingResponse<any>>({
        command: ApiCommand.scheduleCompanyPeriodicTaskGetList,
        params: {
          company,
          limit: 1000,
          offset: 0,
          name: 'scheduler.billing.billing_online_solutions',
        },
      }).then(unwrapFirstRecord),
    ]);

    if (responses.every((r) => !r.status)) {
      commit('setDefaultCompanyBillingAvailability', false);
      return;
    }
    if (responses.some((r) => r.response)) {
      commit('setDefaultCompanyBillingAvailability', true);
    }
  },
  async fetchPermissions({
    commit, dispatch, getters, rootGetters,
  }) {
    const companyId = getters.defaultCompanyId;
    const user = rootGetters['user/data'];
    {
      const { status, response } = await dispatch('api/request', {
        command: ApiCommand.fetchEmployee,
        params: {
          id: user.id,
          companyId,
        },
      }, { root: true }) as ApiResponse<Employee>;
      if (status) {
        commit(
          'setEmployeeRoles',
          { isOwner: !user.master, employeeRole: response.employee_role },
        );
        if (response.is_owner
          || response.employee_role === EmployeeRole.manager
          || response.employee_role === EmployeeRole.owner
        ) {
          commit('setPermissions', {
            [PermissionType.pretrial]: 'write',
            [PermissionType.judicial]: 'write',
            [PermissionType.executive]: 'write',
            [PermissionType.general_info]: 'write',
            [PermissionType.billing_and_transactions]: 'write',
            [PermissionType.rates_and_services]: 'write',
            [PermissionType.organizations]: 'write',
            [PermissionType.data_exchange_integrations]: 'write',
            [PermissionType.data_exchange_upload_report]: 'write',
          });
          return;
        }
      }
    }
    const { status, response } = await (dispatch('api/request', {
      command: ApiCommand.companyPermissionsFetchList,
      params: {
        company_id: companyId,
        user: user.id,
        limit: 1000,
      },
    }, { root: true }) as Promise<ApiResponse<ListingResponse<CompanyPermissionRaw>>>)
      .then(unwrapListingApiResponse)
      .then(mapRawPermissionsResponse);
    if (status) {
      const userPermissions = response.filter(
        ({ user: userId }) => userId === user.id,
      );
      commit('setPermissions', permissionsToModel(userPermissions));
    }
  },
  async fetchDefaultCompanyACL({ commit, getters, dispatch }) {
    const companyId = getters.defaultCompanyId;
    if (!companyId) {
      return;
    }
    const acl = await fetchACL(companyId);
    commit('setDefaultCompanyACL', acl);
  },
  async setDefaultCompanyId({ commit, dispatch, rootGetters }, companyId: Company['id']) {
    if (!companyId) {
      commit('setDefaultCompanyId', null);
      return;
    }
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.setDefaultCompanyId,
      params: {
        id: rootGetters['user/data']?.id,
      },
      data: {
        default_company: companyId,
      },
    } as ApiRequest<SetDefaultCompanyIdModel>, {
      root: true,
    })) as ApiResponse<SetDefaultCompanyIdResponse>;

    if (status) {
      commit('setDefaultCompanyId', response.default_company);
      dispatch('fetchDefaultCompanyLogo');
    }

    return {
      status,
      response,
    } as ApiResponse<SetDefaultCompanyIdResponse>;
  },
  async updateCompany({ commit, dispatch }, { id, payload }: UpdateCompanyModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.updateCompany,
      params: {
        id,
      },
      data: payload,
    } as ApiRequest<UpdateCompanyModel>, {
      root: true,
    })) as ApiResponse<UpdateCompanyResponse>;

    if (status) {
      commit('layout/signal', {
        signalType: SignalType.companyUpdated,
        payload: {
          id,
          payload,
        },
      }, { root: true });
    }

    return {
      status,
      response,
    } as ApiResponse<UpdateCompanyResponse>;
  },
  async addCompany({ commit, dispatch }, data: AddCompanyModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.addCompany,
      params: {
      },
      data,
    } as ApiRequest<SetDefaultCompanyIdModel>, {
      root: true,
    })) as ApiResponse<SetDefaultCompanyIdResponse>;

    if (status) {
      commit('layout/signal', {
        signalType: SignalType.companyAdded,
      }, { root: true });
    }

    return {
      status,
      response,
    } as ApiResponse<SetDefaultCompanyIdResponse>;
  },
};

export const plugins: Array<Plugin<StoreState>> = [
  (store) => {
    store.watch((state) => state.companies.defaultCompanyId, (id) => {
      if (id) {
        store.dispatch('companies/fetchDefaultCompanyACL');
        store.dispatch('companies/fetchDefaultCompanyBillingAvailability');
      }
    });
    store.subscribe(({ type, payload }) => {
      if (type === 'layout/signal'
        && payload.signalType === SignalType.defaultCompanyActiveServicesUpdated
      ) {
        store.dispatch('companies/fetchDefaultCompanyACL');
      }
    });
  },
];
