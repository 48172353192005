import {
  computed, onMounted, ref, Ref,
} from 'vue';
import { useApi2 } from '@/hooks/useApi2';
import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { DictType, useDicts } from '@/hooks/useDicts';

type Position = { position_id: number; name: string }

export const useEmployeePositions = (companyId: Ref<number>) => {
  const request = useApi2();
  const { getDict, getDictMap } = useDicts();
  const positionsDict = getDict<{ label: string; value: string }>(DictType.employeePositions); // hard preload

  const createPosition = (
    { name }: { name: string },
  ) => request<Position
    >({
      command: ApiCommand.addCompanyPositions,
      params: {
        company_id: companyId.value,
      },
      data: {
        name, sort_order: 1,
      },
    });

  const updatePosition = (
    { id, name }: { id: number; name: string },
  ) => request<Position>({
    command: ApiCommand.updateCompanyPositionById,
    params: {
      company_id: companyId.value,
      id,
    },
    data: {
      name,
    },
  });

  const deletePosition = (
    id: number,
  ) => request<boolean>({
    command: ApiCommand.deleteCompanyPosition,
    params: {
      company_id: companyId.value,
      id,
    },
  });

  return {
    positionsDict,
    createPosition,
  };
};
