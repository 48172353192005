import {
  computed,
  onMounted, ref, Ref, SetupContext,
} from 'vue';
import { DebtorAgreementModel, DebtorExtraAgreementModel, useDocumentsApi } from '@/hooks/useDocumentsApi';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useErrors } from '@/hooks/useErrors';
import { ApiResponse } from '@/service/api';
import { usePreventLeaveTrigger } from '@/hooks/usePreventLeave';

export type DebtorAgreementDialogPayload = {
  debtorId: Ref<number>;
  companyId: Ref<number>;
  agreementId: Ref<number|undefined>;
}

export type DebtorAgreementFormModel = Omit<DebtorAgreementModel, 'id'> & {
  id?: number;
  extra_agreements: Array<Omit<DebtorExtraAgreementModel, 'id'> & { id?: number }>;
}

export const useDebtorAgreementDialog = (
  { debtorId, companyId, agreementId }: DebtorAgreementDialogPayload,
  emit: SetupContext['emit'] | any,
) => {
  const leaveController = usePreventLeaveTrigger();
  const isLoading = ref(false);

  const documentsApi = useDocumentsApi();

  const { showToast } = useToast();

  const { dispatchSignal } = useSignal();

  const { errorsMap, setErrors } = useErrors(false);

  const prefix = computed(() => (agreementId.value ? 'edit' : 'add'));

  const getEmptyExtraAgreement = () => ({
    number: null,
    agreement: null,
    amount: 0,
    created_at: null,
    valid_from: null,
    valid_until: null,
  }) as unknown as Omit<DebtorExtraAgreementModel, 'id'>;

  const getEmptyModel = () => ({
    file: null,
    number: null,
    created_at: null,
    debtor: debtorId.value,
    valid_from: null,
    valid_until: null,
    extra_agreements: [getEmptyExtraAgreement()],
  }) as unknown as DebtorAgreementFormModel;

  const model = ref<DebtorAgreementFormModel>(getEmptyModel());

  const extraAgreementsToDelete = ref<number[]>([]);

  const fetchData = async () => {
    isLoading.value = true;
    const response = await documentsApi.fetchDebtorAgreement(agreementId.value as number);
    if (response.status) {
      model.value = response.response;
    }

    isLoading.value = false;
  };

  onMounted(() => {
    if (agreementId.value) {
      fetchData();
    }
  });

  const addExtraAgreement = () => {
    model.value.extra_agreements.push(getEmptyExtraAgreement());
    leaveController.markDirty();
  };

  const deleteExtraAgreement = (i: number) => {
    const agr = model.value.extra_agreements[i];
    if (!agr) {
      return;
    }
    if (agr.id) {
      extraAgreementsToDelete.value.push(agr.id);
    }
    model.value.extra_agreements.splice(i, 1);
    leaveController.markDirty();
  };

  const resetModel = () => {
    if (agreementId.value) {
      fetchData();
    } else {
      model.value = getEmptyModel();
    }
    leaveController.reset();
  };

  const onSave = async () => {
    isLoading.value = true;

    const { extra_agreements, file, ...agreementModel } = model.value;

    const response = await (agreementId.value
      ? documentsApi.updateDebtorAgreement({
        id: agreementId.value,
        ...agreementModel,
        ...(typeof file === 'string' ? {} : file ? { file } : {}),
      })
      : documentsApi.createDebtorAgreement({
        ...agreementModel,
        ...(file ? { file } : {}),
      })
    );

    if (!response.status) {
      if (typeof response.response === 'object') {
        // @ts-ignore
        setErrors(Object.entries(response.response));
      }
      showToast({
        level: IToastLevel.danger,
        label: 'pureLabel',
        message: 'pure',
        params: {
          label: 'Ошибка редактирования договора',
          message: (response.response as any)?.detail ?? '',
        },
        duration: 4000,
      });
      isLoading.value = false;
      resetModel();
      return;
    }

    const _agreementId = response.response.id;

    const results = await Promise.all<ApiResponse<any>>([
      ...extraAgreementsToDelete.value.map((id) => documentsApi.deleteDebtorExtraAgreement(id)),
      ...extra_agreements
        .filter((agr) => agr.created_at || agr.valid_from || agr.valid_until || agr.amount)
        .map((agr) => {
          if (agr.id) {
            return documentsApi.updateDebtorExtraAgreement({
              ...agr as DebtorExtraAgreementModel,
              agreement: _agreementId,
            });
          }
          return documentsApi.createDebtorExtraAgreement({
            ...agr,
            agreement: _agreementId,
          });
        }),
    ]);

    const anyError = results.some((r) => !r.status);
    if (anyError) {
      showToast({
        level: IToastLevel.danger,
        label: 'pureLabel',
        message: 'pure',
        params: {
          label: 'Ошибка редактирования договора',
          message: (response.response as any)?.detail ?? '',
        },
        duration: 4000,
      });
      resetModel();
    } else {
      leaveController.reset();
      showToast({
        level: IToastLevel.success,
        label: 'pureLabel',
        params: {
          label: 'Договор обновлен',
        },
        duration: 4000,
      });
      dispatchSignal(SignalType.debtorAgreementsUpdated);
      dispatchSignal(SignalType.debtorDocumentsUpdated);
      emit('close');
    }

    isLoading.value = false;
  };

  return {
    prefix,
    model,
    isLoading,
    addExtraAgreement,
    deleteExtraAgreement,
    onSave,
    errorsMap,
    extraAgreementsToDelete,
    leaveController,
  };
};
