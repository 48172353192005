import {
  inject,
  InjectionKey,
} from 'vue';

// inject значения, которое точно не будет undefined, иначе выкинет ошибку
export const useProtectedInject = <T>(injectionKey: InjectionKey<T>) => {
  const value = inject(injectionKey);
  if (value === undefined) {
    throw Error(`Could not resolve ${injectionKey.description}`);
  }
  return value;
};
