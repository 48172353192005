import { computed, ref } from 'vue';
import { Employee, useEmployees } from '@/hooks/useEmployees';
import { useActiveTable } from '@/components/activeTable/useActiveTable';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';

export const useFormEmployees = () => {
  const {
    fetchEmployees,
  } = useEmployees();

  const {
    companyId,
  } = useDefaultCompany();

  const {
    filtersModel: employeesFilterModel,
    records: employees,
  } = useActiveTable<
    Employee,
    Employee,
    'id'
    >({
      keyField: 'id',
      defaultLimit: ref(100),
      async fetch({ params, signal }) {
        const { response } = await fetchEmployees({
          ...params,
          signal,
        });

        return response;
      },
      filters: computed<Array<ActiveFormField<Employee>>>(() => ([
        {
          key: 'company_id',
          field: 'company_id',
          type: ActiveFormFieldType.input,
          defaultValue: companyId.value,
        },
      ])),
    });
  const onQueryEmployee = async ({ query }: { query: string }): Promise<void> => {
    employeesFilterModel.value.query = query;
  };

  return {
    employees,
    onQueryEmployee,
  };
};
