import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { UserSettingsResponse } from '@/pages/exchange/integration/dialogs/integrationGosuslugi/useGosuslugi';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';

export const useUserUsesRemoteSignUserSetting = () => {
  const fetchUserSettingsSecure = () => commonLegacyApiRequest<ListingResponse<UserSettingsResponse>>({
    command: ApiCommand.fetchUserSettingsSecure,
  });

  const userUsesRemoteSign = () => fetchUserSettingsSecure().then(
    (r) => {
      if (!r.status) {
        return r;
      }
      const response = r.response.results.some(
        (result) => result.key === 'user_uses_remote_sign',
      );
      return {
        status: true,
        response,
      };
    },
  );

  const resetUserUsesRemoteSign = async () => {
    const response = await fetchUserSettingsSecure();
    if (!response.status) {
      return response;
    }
    const setting = response.response.results.find((setting) => setting.key === 'user_uses_remote_sign');
    if (!setting) {
      console.error('resetUserUsesRemoteSign: setting not found');
      return { status: false, response: null };
    }
    const response1 = await commonLegacyApiRequest({
      command: ApiCommand.deleteUserSettingsSecure,
      params: {
        id: setting.id,
      },
    });
    return response1;
  };

  const setUserUsesRemoteSign = async () => {
    commonLegacyApiRequest<Response>({
      command: ApiCommand.createUserSettingsSecure,
      data: {
        key: 'user_uses_remote_sign',
        is_readable: true,
        source: {},
      },
    });
  };

  return {
    userUsesRemoteSign,
    resetUserUsesRemoteSign,
    setUserUsesRemoteSign,
  };
};
