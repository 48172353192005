import { useModelDialog } from '@/hooks/useModelDialog';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import {
  IncomeSourceInputModel, IncomeSourceApiModel,
} from '@/components/dialog/dialogs/debtor/tabs/finance/incomeSources/types';
import {
  fields,
} from '@/components/dialog/dialogs/debtor/tabs/finance/incomeSources/fields';
import {
  useIncomeSourcesCrud,
} from '@/components/dialog/dialogs/debtor/tabs/finance/incomeSources/useIncomeSourcesCrud';
import { ref } from 'vue';

export const useIncomeSourceDialogs = () => {

  const { debtorId } = useInjectDebtorDialog();
  const { create, update, fetch } = useIncomeSourcesCrud();
  const isSaving = ref<boolean>(false);

  const modelDialogCreate = useModelDialog<
    IncomeSourceInputModel,
    IncomeSourceApiModel
    >({
      isSaving,
      key: 'add_income_source',
      title: 'Добавление источника дохода должника',
      // @ts-ignore
      fields,
      onModelUpdate: async (payload) => {
        return createUpdateIncomeSource({
          ...payload,
          is_general_director: payload.is_general_director ?? false,
          is_founder: payload.is_founder ?? false,
        });
      },
    });

  const modelDialogUpdate = useModelDialog<
    IncomeSourceInputModel,
    IncomeSourceApiModel,
    { id: number; getForm:() => IncomeSourceApiModel }
      >({
        key: 'edit_income_source',
        title: 'Редактирование источника дохода',
        // @ts-ignore
        fields,
        onModelUpdate: async (payload, { id }) => {
          return createUpdateIncomeSource(payload, id);
        },
      });

  async function createUpdateIncomeSource(payload: IncomeSourceInputModel, id?: number) {
    if (!id) {
      isSaving.value = true;
      const result = await create({
        ...payload,
        debtor: debtorId.value,
      });
      isSaving.value = false;
      // @ts-ignore
      return result;
    }
    isSaving.value = true;
    const result = await update(id, {
      ...payload,
      debtor: debtorId.value,
    });
    isSaving.value = false;
    return result;
  }

  return {
    openCreateDialog: modelDialogCreate.showDialog,
    openUpdateDialog: async (id: number, model: IncomeSourceApiModel) => {

      await modelDialogUpdate.showDialog({
        id,
        getForm() {
          return model;
        },
      });
    },
  };
};
