import { DebtorPayload } from '@/hooks/useDialog';
import {
  computed, onBeforeUnmount, onMounted, provide, ref, toRefs, watch,
} from 'vue';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { Debtor, useDebtors } from '@/hooks/useDebtors';
import {
  DebtorDialogTitleKey,
  DebtorIdKey,
  DebtorIsOrganizationKey,
  DebtorKey,
  DialogIsCurrentKey,
  FetchDebtorDataKey,
  ProductionTypeKey,
} from '@/symbols';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { getAwaitFrame } from '@/utils/window';
import { useDebtorDialogTabs } from '@/components/dialog/dialogs/debtor/useDebtorDialogTabs';
import { useDebtorDialogNavigation } from '@/components/dialog/dialogs/debtor/useDebtorDialogNavigation';
import { useSocket } from '@/hooks/useSocket';
import { useUnsubs } from '@/hooks/useUnsubs';

type Props = DebtorPayload & { isCurrent: boolean };

export const useDebtorDialog = (
  props: Props,
) => {
  const {
    id, productionType, isOrganization, isCurrent, name, personalAccount,
  } = toRefs(props);
  const { t } = useLocalI18n('debtor');
  const isLoading = ref(false);

  const { tabs, activeTab } = useDebtorDialogTabs(
    productionType,
    isCurrent,
  );

  const {
    goTo, nextDebtorMeta, prevDebtorMeta, totalDebtors,
  } = useDebtorDialogNavigation(id);

  const modalTitle = computed(() => {
    if (!name.value || !personalAccount.value) return null;
    if (totalDebtors.value === null) {
      return name.value;
    }
    return `${name.value} (${personalAccount.value}) (${props.tableOffset + 1}/${totalDebtors.value})`;
  });

  const {
    dispatchSignal,
  } = useSignal();

  const {
    fetchDebtor,
  } = useDebtors();

  const {
    subscribe,
  } = useSocket();

  const {
    sub,
  } = useUnsubs();

  const { companyId, companyModuleType } = useDefaultCompany();

  const debtor = ref<Debtor>();

  provide(DebtorIsOrganizationKey, isOrganization);
  provide(ProductionTypeKey, productionType);
  provide(DebtorKey, debtor);
  provide(DebtorIdKey, computed(() => id.value));
  provide(DialogIsCurrentKey, isCurrent);
  provide(DebtorDialogTitleKey, modalTitle);

  let abortController: AbortController|null;

  const fetchData = async () => {
    console.log('fetchData', isLoading.value, abortController);
    isLoading.value = true;
    dispatchSignal(SignalType.findDebtorNeighbours, {
      debtorId: props.id,
      tableOffset: props.tableOffset,
    });

    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();

    const { status, response } = await fetchDebtor({
      id: id.value,
      productionType: productionType.value,
      companyId: companyId.value!,
      module: companyModuleType.value,
      signal: abortController?.signal,
    });

    isLoading.value = false;

    if (!status) {
      return;
    }

    debtor.value = response;
  };

  onBeforeUnmount(() => {
    if (abortController) {
      abortController.abort();
    }
  });

  provide(FetchDebtorDataKey, fetchData);

  onMounted(async () => {
    const unsubSocket = await subscribe({
      condition(payload) {
        return payload.data.event === 'updated_debtor_data';
      },
      handler(payload: any) {
        if (+payload.data.id === debtor.value?.pk as number) {
          fetchData();
        }
      },
    });
    sub(unsubSocket);
  });

  watch(id, getAwaitFrame(fetchData), { immediate: true });

  return {
    t,
    prevDebtorMeta,
    nextDebtorMeta,
    goTo,

    debtor,

    activeTab,
    tabs,
    modalTitle,
    isLoading,
  };
};
export type DebtorNeighboursMeta = {
  current: number;
  total: number;
  neighbours: NeighbourMeta[];
}
export type DebtorMeta = {
  id: number;
  name: string;
  personalAccount: string;
  isOrganization: boolean;
  tableOffset: number;
}

export type NeighbourMeta = {
  id: number;
  name: string;
  personalAccount: string;
  tableOffset: number;
}
