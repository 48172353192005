import { ActiveTableColumnFormat } from '@/components/activeTable/useActiveTable';
import { h } from 'vue';
import { FsspResponse } from '@/components/dialog/dialogs/debtor/tabs/executions/types';
import FsspResponseText from '@/components/dialog/dialogs/debtor/tabs/executions/FsspResponseText.vue';

const W_15 = '150px';

export const getFsspAnswerText = (r?: { response: FsspResponse }) => {
  if (r && !!Object.keys(r.response).length) {
    return h(FsspResponseText, { response: r.response });
  }

  return null;
};

export const fsspColumns = [
  { key: 'index' },
  { key: 'ls' },
  { key: 'action', width: W_15 },
  { key: 'order_id', width: W_15 },
  { key: 'status_current', width: W_15 },
  { key: 'serial_number' },
  { key: 'start_date', format: ActiveTableColumnFormat.date, width: W_15 },
  { key: 'send_to_fssp_date', format: ActiveTableColumnFormat.date },
  { key: 'case_number', label: 'Номер судебного дела', width: W_15 },
  { key: 'number', width: W_15 },
  { key: 'date', width: W_15 },
  {
    key: 'case_date', format: ActiveTableColumnFormat.date, label: 'Дата судебного дела', width: W_15,
  },
  { key: 'magistrate_name', field: 'magistrate' },
  { key: 'court', width: W_15 },
  { key: 'end_date', format: ActiveTableColumnFormat.date, width: W_15 },
  { key: 'termination_ground' },
  { key: 'termination_ground_description', width: '500px' },
  { key: 'amount', format: ActiveTableColumnFormat.money, width: W_15 },
  { key: 'executive_amount', format: ActiveTableColumnFormat.money, width: W_15 },
  { key: 'fssp', width: W_15 },
  { key: 'bailiff_full_name' },
  { key: 'bailiff_phone_number', format: ActiveTableColumnFormat.phone },
  { key: 'execution_type', width: '300px' },
  { key: 'debt_period' },
];
