export enum Field {
  reqType = 'Тип запроса',
  date = 'Дата ответа',
  response = 'Текст ответа',
  address = 'Адрес',
  position = 'Должность',
  department = 'ФССП',
  fio = 'ФИО исполнителя',
  email = 'Email',
  phone = 'Телефон',
  phoneExecutor = 'Телефон исполнителя',
}

export type FsspResponse = {
  [Field.reqType]: string;
  [Field.date]: Date;
  [Field.response]: string;
  [Field.department]: string;
  [Field.address]: string;
  [Field.fio]: string;
  [Field.position]: string;
  [Field.email]: string;
  [Field.phone]: string;
  [Field.phoneExecutor]: string;
}
