import { DateTime } from 'luxon';
import { ReportingChartFormModelTimeInterval } from '@/components/forms/form/reportingChartForm/form';

export type ReportingChartTimeIntervalPreset = string;

export const timeEntireOptions = [
  {
    value: '5-years',
    label: '5 лет',
  },
  {
    value: '1-years',
    label: '1 год',
  },
  {
    value: '1-quarters',
    label: '1 квартал',
  },
  {
    value: '30-days',
    label: '30 дней',
  },
];

export const timeSplitOptions = [
  {
    value: '5-years--by-year',
    label: '5 лет - по годам',
  },
  {
    value: '1-years--by-quarter',
    label: 'Год - по кварталам',
  },
  {
    value: '1-years--by-month',
    label: 'Год - по месяцам',
  },
  {
    value: '1-quarter--by-month',
    label: 'Квартал',
  },
  {
    value: '30-days--by-day',
    label: '30 дней',
  },
];

export const timeEntireOptionsMap: Record<
  ReportingChartTimeIntervalPreset, ReportingChartFormModelTimeInterval
  > = {
    '5-years': {
      start: DateTime.now().startOf('day').endOf('year').minus({ year: 5 })
        .toJSDate(),
      stop: DateTime.now().startOf('day').endOf('year').toJSDate(),
      interval: {
        years: 5,
        months: 0,
        weeks: 0,
        days: 0,
      },
    },
    '1-years': {
      start: DateTime.now().startOf('day').endOf('year').minus({ year: 1 })
        .toJSDate(),
      stop: DateTime.now().startOf('day').endOf('year').toJSDate(),
      interval: {
        years: 1,
        months: 0,
        weeks: 0,
        days: 0,
      },
    },
    '1-quarters': {
      start: DateTime.now().startOf('day').startOf('quarter').toJSDate(),
      stop: DateTime.now().startOf('day').endOf('quarter').toJSDate(),
      interval: {
        years: 0,
        months: 4,
        weeks: 0,
        days: 0,
      },
    },
    '30-days': {
      start: DateTime.now().startOf('day').minus({ day: 30 }).toJSDate(),
      stop: DateTime.now().startOf('day').toJSDate(),
      interval: {
        years: 0,
        months: 0,
        weeks: 0,
        days: 30,
      },
    },
  };

export const timeEntireWithPrevOptionsMap: Record<
  ReportingChartTimeIntervalPreset, ReportingChartFormModelTimeInterval
  > = {
    '5-years-with-prev': {
      start: DateTime.now().startOf('day').endOf('year').minus({ year: 10 })
        .toJSDate(),
      stop: DateTime.now().startOf('day').endOf('year').toJSDate(),
      interval: {
        years: 5,
        months: 0,
        weeks: 0,
        days: 0,
      },
    },
    '1-years-with-prev': {
      start: DateTime.now().startOf('day').endOf('year').minus({ year: 2 })
        .toJSDate(),
      stop: DateTime.now().startOf('day').endOf('year').toJSDate(),
      interval: {
        years: 1,
        months: 0,
        weeks: 0,
        days: 0,
      },
    },
    '1-quarters-with-prev': {
      start: DateTime.now().startOf('day').startOf('quarter').minus({ quarter: 1 })
        .toJSDate(),
      stop: DateTime.now().startOf('day').endOf('quarter').toJSDate(),
      interval: {
        years: 0,
        months: 4,
        weeks: 0,
        days: 0,
      },
    },
    '30-days-with-prev': {
      start: DateTime.now().startOf('day').minus({ day: 60 }).toJSDate(),
      stop: DateTime.now().startOf('day').toJSDate(),
      interval: {
        years: 0,
        months: 0,
        weeks: 0,
        days: 30,
      },
    },
  };

export const timeSplitOptionsMap: Record<
  ReportingChartTimeIntervalPreset, ReportingChartFormModelTimeInterval
  > = {
    '5-years--by-year': {
      start: DateTime.now().startOf('day').startOf('year').minus({ years: 5 })
        .toJSDate(),
      stop: DateTime.now().startOf('day').endOf('year').toJSDate(),
      interval: {
        years: 1,
      },
    },
    '1-years--by-quarter': {
      stop: DateTime.now().startOf('day').endOf('year').toJSDate(),
      start: DateTime.now().startOf('day').startOf('year').toJSDate(),
      interval: {
        months: 4,
      },
    },
    '1-years--by-month': {
      stop: DateTime.now().startOf('day').endOf('year').toJSDate(),
      start: DateTime.now().startOf('day').startOf('year').toJSDate(),
      interval: {
        months: 1,
      },
    },
    '1-quarter--by-month': {
      stop: DateTime.now().startOf('day').endOf('quarter').toJSDate(),
      start: DateTime.now().startOf('day').startOf('quarter').toJSDate(),
      interval: {
        months: 4,
      },
    },
    '30-days--by-day': {
      stop: DateTime.now().startOf('day').toJSDate(),
      start: DateTime.now().startOf('day').minus({ day: 30 }).startOf('day')
        .toJSDate(),
      interval: {
        days: 1,
      },
    },
  };

export const intervalsMapMerged = {
  ...timeEntireOptionsMap,
  ...timeEntireWithPrevOptionsMap,
  ...timeSplitOptionsMap,
};

export const intervalFormatterMap = {
  year: 'yyyy',
  quarter: 'qq',
  month: 'LLL',
  day: 'dd.LL.yyyy',
};

export const getIntervalFormatter = (key: string) => {
  const splited = key.split('--by-');
  if (!splited || !splited.length) return null;
  const [name, formatter] = splited;
  // @ts-ignore
  return (formatter && intervalFormatterMap[formatter]) || null;
};

export const timeOptionsMap = {
  entire: timeEntireOptionsMap,
  'entire-with-prev': timeEntireWithPrevOptionsMap,
  split: timeSplitOptionsMap,
};

export const getTimeIntervalPreset = (
  name: ReportingChartTimeIntervalPreset,
) => intervalsMapMerged[name];
