export type SocketErrorMessage = string|string[]|Record<string, string[]|string>;

export const getSocketErrorMessages = (
  errorMessages: SocketErrorMessage,
) => {
  if (Array.isArray(errorMessages)) return errorMessages.join('\n');
  if (typeof errorMessages === 'string') return errorMessages;
  return Object.values(errorMessages).reduce(
    (acc, messages) => `${acc}\n${Array.isArray(messages) ? messages.join(', ') : messages}`, '',
  ) as string;
};

export const hasSocketErrorMessage = (
  errorMessages: SocketErrorMessage,
  message: string,
): boolean => {
  if (Array.isArray(errorMessages)) {
    return errorMessages.some(
      (msg) => msg.toLowerCase() === message.toLowerCase(),
    );
  }
  if (typeof errorMessages === 'string') {
    return errorMessages.toLowerCase() === message.toLowerCase();
  }
  return Object.values(message).some(
    (msg) => hasSocketErrorMessage(msg, message),
  );
};
