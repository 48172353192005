export const parseHousesString = (str: string) => {
  const tokens = str.split(',').map((v) => v.trim());
  return tokens.map((token) => {
    if (token === '-') return '-';
    const isInterval = token.includes('-');
    if (isInterval) {
      return token.split('-').map((v) => {
        const trimmed = v.trim();
        const matched = trimmed.match(/^\d+/);
        return matched ? matched[0] : '';
      }).join(' - ');
    }
    return token;
  });
};
