import { DataFileSocketMessage, getSocketMessageTaskID, isDataFileEvent } from '@/types/socket';
import { IToast, IToastLevel } from '@/hooks/useToast';
import { DataFileStatus } from '@/types/datafile';
import { IDialog, IDialogComponent } from '@/hooks/useDialog';
import { h, VNode } from 'vue';
import ExchangeImportPaymentOrdersResult
  from '@/pages/exchange/import/paymentOrdersResult/ExchangeImportPaymentOrdersResult.vue';
import { SocketSubscriber } from '@/store/modules/socket';
import {
  CHECK_TASK_RESULT_RETRIES_COUNT,
  ProgressContext, ProgressPayload,
  ProgressTask,
  UNKNOWN_TOAST_RESULT,
} from '@/store/modules/tasksProgress';
import { openAuthProtectedFile } from '@/utils/fileUrl';
import { Dispatch } from 'vuex';

export async function startImportTask(
  context: ProgressContext,
  { task, restore }: ProgressPayload,
) {

  const {
    commit, state, dispatch, rootState, getters,
  } = context;

  const getTaskWithPassedOldStages = (lastProgressbarIndex: number) => {
    const stateTask: ProgressTask = JSON.parse(JSON.stringify(state.tasks[task.key]));
    for (let i = 0; ++i; i <= lastProgressbarIndex) {
      const progressbar = stateTask.progressbars[i];
      if (!progressbar) {
        return stateTask;
      }
      if (progressbar.max) {
        progressbar.current = progressbar.max;
      }
      progressbar.message = '';
    }
    return stateTask;
  };
  const getToastProgressMessage = (payload: DataFileSocketMessage) => {
    let message = '';
    if (payload.data.description) message += payload.data.description;
    // @ts-ignore
    if (payload.data?.obj?.name) message += ` ${payload.data?.obj?.name}`;
    if (payload.data?.obj?.status_text) message += payload.data?.obj?.status_text;
    return message;
  };

  const ERROR_TOAST_DEFAULT: IToast = {
    label: 'pureLabel',
    message: 'exchange.import.form.toast.submit.errorMessage',
    params: {
      label: task.label,
    },
    level: IToastLevel.danger,
    duration: 4000,
  };

  const SUCCESS_TOAST_DEFAULT: IToast = {
    label: 'pureLabel',
    message: 'exchange.import.form.toast.submit.successMessage',
    params: {
      label: task.label,
    },
    level: IToastLevel.success,
    duration: 4000,
  };
  let checkTaskStatusIntervalId;
  let unsubSocket: () => void;
  let progressHasBeenFulfilled = false;
  if (!restore) {
    commit('setTask', task);
  }
  const storedTask = state.tasks[task.key];

  const existingProgressToast = rootState.layout.toasts.find((t) => t.id === task.toastId);

  let closeProgressToast: () => void;

  const result: IToast|undefined = await new Promise(async (resolve) => {
    let lastSocketMessageTime: number;

    const onClose = () => resolve(undefined);

    if (!existingProgressToast) {
      closeProgressToast = await dispatch('layout/showToast', {
        message: 'exchange.import.form.toast.submit.message',
        progressbars: getters.taskProgressbars[storedTask.key],
        level: IToastLevel.info,
        duration: null,
        onClose,
      }, { root: true });
      commit(
        'setTaskToastId',
        {
          taskKey: storedTask.key, toastId: (closeProgressToast as unknown as { id: string }).id,
        },
      );
    } else {
      commit('layout/updateToastById', {
        id: storedTask.toastId,
        progressbars: getters.taskProgressbars[storedTask.key],
        onClose,
      }, { root: true });
      closeProgressToast = () => dispatch('layout/closeToastById', task.toastId, { root: true });
    }
    if (restore) {
      let checkTaskResultCounter = 0;
      const checkTaskResult = async () => {
        checkTaskResultCounter += 1;
        if (checkTaskResultCounter > CHECK_TASK_RESULT_RETRIES_COUNT && !lastSocketMessageTime) {
          resolve(UNKNOWN_TOAST_RESULT);
        } else {
          const taskResult = await dispatch('fetchTaskResult', task.id);
          if (taskResult) {
            if (taskResult.status === DataFileStatus.FAILED) resolve(ERROR_TOAST_DEFAULT);
            else if (
              taskResult.status === DataFileStatus.PROCESSED
            ) resolve(SUCCESS_TOAST_DEFAULT);
          }
        }
      };
      await checkTaskResult();
      checkTaskStatusIntervalId = setInterval(async () => {
        await checkTaskResult();
      }, 10000);
    }

    unsubSocket = await dispatch('socket/subscribe', {

      condition: (payload) => payload.action === 'progress_event'
        && isDataFileEvent(payload) && getSocketMessageTaskID(payload) === task.uuid,

      handler: async (payload) => {
        lastSocketMessageTime = new Date().getTime();

        if (payload.data?.obj && (payload.data?.obj.report || payload.data?.obj.archive)) {
          await showResultDialog(dispatch, payload.data.obj);
        }

        // для не custom
        if (payload.data.description && !payload.data.done) {
          const copiedTask = state.tasks[task.key];
          copiedTask.progressbars[1].message = `${payload.data.description} (${payload.data.progress.step} из ${payload.data.progress.total})`;
          copiedTask.progressbars[1].max = payload.data.progress.total;
          copiedTask.progressbars[1].current = payload.data.progress.step;
          commit('updateTask', copiedTask);
          commit('layout/updateToastById', {
            id: copiedTask.toastId,
            progressbars: getters.taskProgressbars[copiedTask.key],
          }, { root: true });
        }

        // для не custom
        if (payload.data?.obj?.error) {
          await dispatch('layout/showToast', {
            level: IToastLevel.danger,
            label: 'pureLabel',
            message: 'pure',
            params: {
              label: 'Ошибка загрузки',
              message: payload.data?.obj?.error,
            },
          } as IToast, { root: true });
        }

        if (payload.data?.obj?.is_success !== undefined) {
          resolve(
            payload.data?.obj?.is_success
              ? SUCCESS_TOAST_DEFAULT
              : ERROR_TOAST_DEFAULT,
          );
          return;
        }

        console.log('obj status_text', payload.data?.obj?.status_text, payload.data?.done);

        if (payload.data?.obj?.state === 4) {
          let message: string | VNode | undefined = payload.data?.obj.status_text;
          let reportLink: string | undefined;
          if ((payload.data?.obj as any)?.failure_info) {
            const { failed_line_in_file: failed_line, report } = (payload.data?.obj as any)?.failure_info;
            if (Number.isInteger(failed_line)) {
              message += ` на строке ${failed_line}`;
            }
            reportLink = report;
          }
          if (reportLink) {
            await showResultDialog(dispatch, {
              report: reportLink,
              title: 'Ошибки при загрузке',
            });
          }
          resolve({
            label: 'pureLabel',
            message: 'pure',
            params: {
              label: task.label,
              message,
            },
            level: IToastLevel.danger,
          });
        } else {
          if (restore && !progressHasBeenFulfilled) {
            const task = getTaskWithPassedOldStages(payload.data.stage - 1);
            commit('updateTask', task);
            progressHasBeenFulfilled = true;
          }
          const subprogress = payload.data?.sub_progresses?.[0];

          if (payload.data.done) {
            resolve(SUCCESS_TOAST_DEFAULT);
          } else {
            const copiedTask = state.tasks[task.key];
            const message = getToastProgressMessage(payload);

            if (subprogress) {
              console.log('set import subprogress', payload.data);
              const { step, total } = subprogress;
              copiedTask.progressbars[1].message = `${message}: ${step} из ${total}`;
              copiedTask.progressbars[1].max = total;
              copiedTask.progressbars[1].current = step;
            } else {
              try {
                copiedTask.progressbars[payload.data.stage].message = message;
                copiedTask.progressbars[payload.data.stage].max = payload.data.progress.total;
                copiedTask.progressbars[payload.data.stage].current = payload.data.progress.step;
              } catch (e) {
                console.log('stage error:', payload.data.stage, payload);
              }
            }
            commit('updateTask', copiedTask);
            commit('layout/updateToastById', {
              id: copiedTask.toastId,
              progressbars: getters.taskProgressbars[copiedTask.key],
            }, { root: true });
          }
        }
      },
    } as SocketSubscriber<DataFileSocketMessage>, { root: true });
  });

  if (result !== undefined) {
    await dispatch('layout/showToast', result, { root: true });
  }
  commit('removeTask', task);
  // @ts-ignore
  unsubSocket?.();
  // @ts-ignore
  closeProgressToast?.();
  clearInterval(checkTaskStatusIntervalId);
  return Promise.resolve(true);
}

type ResultPayload = {
  report?: string;
  archive?: string;
  title?: string;
};

function showResultDialog(dispatch: Dispatch, { report, archive, title }: ResultPayload) {
  return dispatch(
    'layout/showDialog',
    {
      component: IDialogComponent.content,
      payload: {
        title: title ?? 'Результат загрузки',
        content: h(ExchangeImportPaymentOrdersResult, {
          report,
          errorFiles: archive,
        }),
      },
      addInRoute: false,
    } as IDialog,
    { root: true },
  );
}
