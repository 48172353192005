import { commonListingRequest } from '@core/service/commonService';
import { ApiCommand } from '@/store/modules/api';

export type FsspArticle = {
  id: string;
  name: string;
  description: string;
}

export function useFsspArticles() {

  async function fetchFsspArticles(): Promise<FsspArticle[]> {

    const result = await commonListingRequest<FsspArticle>({
      command: ApiCommand.fetchEnforcementsFsspArticles,
    });
    if (!result.response) {
      console.error('Failed to fetch fssp articles', result.error);
      return [];
    }
    return result.response.results;
  }

  return {
    fetchFsspArticles,
  };
}
