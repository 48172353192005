import { Ref } from 'vue';
import { formatDate, formatDateTime } from '@/utils/date';
import { dateInterval } from '@/utils/dateFns';
import { useCustomLocales } from '@/components/dialog/dialogs/debtor/useCustomLocales';
import { useCourts } from '@/hooks/useCourts';
import { useExpandMixin } from '@/components/activeTable/activeTableRow/expand-mixin';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { Fetch, FetchFn } from '@/components/activeTable/useActiveTable';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { CourtCase, CourtCaseFilters } from '@/components/dialog/dialogs/debtor/tabs/courts/types';
import { ListingResponse } from '@/store/modules/api';

type SubRecord = {
  index: number; // client field
  complaint_type: string;
  current_status: string;
  history: string | unknown[];
  complaint_history: unknown[];
  id: string;
  is_test: boolean;
  screenshot: unknown;
  tracker_history: number;
  updated_at: Date;
  filing_date: string;
}

export function useFetchCourtsCases(isLoading: Ref<boolean>, archive: boolean): FetchFn<CourtCase> {

  const { fetchLocales } = useCustomLocales();
  const { setupRecordExpandRow } = useExpandMixin();
  const { debtor } = useInjectDebtorDialog();
  const { t: tComplaintType } = useLocalI18n('debtor.courts.complaint_types');

  const {
    fetchCourtCases,
    fetchDebtorCourtApplicationsTracker,
  } = useCourts();

  function complaintType(type?: string) {
    return type ? tComplaintType(type) : '';
  }

  const noResults = {
    count: 0,
    results: [],
  };

  return async function fetch({ params, signal }: Fetch<CourtCaseFilters>): Promise<ListingResponse<CourtCase>> {
    if (!debtor.value) {
      return noResults;
    }
    isLoading.value = true;
    await fetchLocales();
    const { response: courtCases } = await fetchCourtCases({
      ...params,
      signal,
    });
    const { similar_debtor, ...rest } = params.filters ?? {};
    const filters = { debtor: similar_debtor, ...rest };
    const trackerParams = { ...params, filters };
    const { response: courtApplications } = archive
      ? { response: noResults }
      : await fetchDebtorCourtApplicationsTracker({ ...trackerParams, signal });

    isLoading.value = false;
    let index = 1;

    return {
      count: courtCases.results.length + courtApplications.results.length,
      results: [
        ...(courtCases?.results || []).map((item: CourtCase) => {
          let subIndex = 1;
          const subrecords: SubRecord[] = item.tracker.history.reduce((acc: any[], subItem: SubRecord) => {
            return [
              ...acc,
              ...(subItem.complaint_history ? subItem.complaint_history : []),
            ];
          }, []).map((subItem: SubRecord) => ({
            ...subItem,
            index: subIndex++,
            filing_date: formatDate(subItem.filing_date, 'ru'),
            complaint_type: complaintType(subItem.complaint_type),
            history: !subItem.history?.length ? '' : subItem.history
              // @ts-ignore
              .map((v) => `<div style="display: block; padding: 0;"><span style="opacity: 0.7;">${formatDateTime(v.created_at)}</span> - ${v.status}</div>`)
              // @ts-ignore
              .reduce((acc, v) => `${acc}${v}`),
          }));
          setupRecordExpandRow(item, async () => {
            return subrecords;
          });
          return {
            ...item,
            index: index++,
            complaint_type: complaintType(item.tracker.type),
            filing_date: item.tracker.filing_date,
            debt_period: [item.tracker.date_from, item.tracker.date_to],
            payment_document_date: item.tracker.payment_document_date,
            current_status: item.tracker.current_status,
            court_place: item.tracker.court_place,
            payment_document_number: item.tracker.payment_document_number,
            type: 'court_case',
          };
        }),
        ...(courtApplications?.results || []).map((a) => ({
          index: index++,
          complaint_type: complaintType(a.complaint_type),
          tracker: {
            current_status: a.current_status,
            history: a.history,
          },
        })),
      ],
    };
  };
}
