import { Ref } from 'vue';
import { AutoTabKey, JudicialAutomationModel } from '@/components/automation/judicial/types';
import {
  fieldGroup,
  fieldsUniter,
} from '@/components/automation/judicial/tabs/utils';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';

export const getDebtorDocuments = (
  model: Ref<JudicialAutomationModel>,
) => {
  return fieldsUniter(AutoTabKey.debtorsDocuments, [
    {
      key: 'pik_sale_documents_period',
      type: ActiveFormFieldType.schedule,
      state: ['primary'],
      options: {
        state: ['primary'],
        isDisabled: !model.value.judicial_automation_enabled,
      },
      ionFieldGroup: fieldGroup(AutoTabKey.debtorsActions),
    },
  ]);
};
