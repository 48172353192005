import { onMounted, ref, Ref } from 'vue';
import { useCompanySettingsSecure } from '@/hooks/useCompanySettingsSecure';
import { useUser } from '@/hooks/useUser';
import { useToast } from '@/hooks/useToast';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { ProductionType } from '@/hooks/useConstructor';
import { useDebtorsModuleUserPrefs } from '@/pages/debtors/_module/useModuleUserPrefs';

type Payload<Model, ModelDto> = {
  companyId: Ref<number>;
  settingKey: string;
  model?: Ref<Model>;
  useOwner?: boolean;
  isReadable?: boolean;
  mapFromDto?: (dto: ModelDto) => Promise<Model>;
  onDelete?(): void;
  skipNotifications?: boolean;
}

export function useSettingsCommon<Model, ModelDto>({
  companyId,
  settingKey: key,
  model,
  useOwner,
  isReadable,
  mapFromDto,
  onDelete,
  skipNotifications,
}: Payload<Model, ModelDto>) {
  const { t } = useLocalI18n('exchange.integration.actions');
  const { user } = useUser();
  const { showDangerToast, showSuccessToast } = useToast();
  const { dispatchSignal } = useSignal();
  const skipSuccessNotices = skipNotifications ?? false;

  const companySettingsSecure = useCompanySettingsSecure();
  const isLoading = ref(true);
  const hasSetting = ref<boolean>(false);
  const {
    updateModuleActions,
  } = useDebtorsModuleUserPrefs();

  const userPrefsByModalKeyMap: Record<string, string> = {
    ais_gorod: 'aisGorodUpdate',
    megapolis: 'megapolisUpdate',
    pik: 'pikUpdate',
    asu: 'asuUpdate',
  };
  const currentKey = userPrefsByModalKeyMap[key];

  onMounted(async () => {
    await fetchSetting();
  });

  async function fetchSetting() {
    hasSetting.value = false;
    isLoading.value = true;
    const { status, response } = await companySettingsSecure.fetchUserSetting<{data: ModelDto}>({
      company_id: companyId.value,
      ...(useOwner === false ? {} : { owner: user.value?.id as number }),
      key,
    });
    if (status && response?.data) {
      hasSetting.value = true;
      if (model) {
        const data = response?.data as unknown as ModelDto;
        model.value = mapFromDto ? await mapFromDto(data) : data as unknown as Model;
      }
    }
    isLoading.value = false;
  }

  async function saveSetting(model: ModelDto) {
    isLoading.value = true;
    const { status } = await companySettingsSecure.saveUserSetting({
      key,
      company_id: companyId.value,
      ...(useOwner === false ? {} : { owner: user.value?.id as number }),
      is_readable: isReadable ?? false,
      source: model,
    });
    if (status) {
      hasSetting.value = true;
      !skipSuccessNotices && await showSuccessToast({
        message: t('save.success'),
      });

      if (key in userPrefsByModalKeyMap) {
        await updateModuleActions({ actions: { [currentKey]: true }, module_type: ProductionType.pretrial });
        await updateModuleActions({ actions: { [currentKey]: true }, module_type: ProductionType.judicial });
      }

    } else {
      await showDangerToast({
        message: t('save.error'),
      });
    }
    isLoading.value = false;

    await dispatchSignal(SignalType.integrationServiceConnectionUpdated);
  }

  async function deleteSetting() {
    if (!hasSetting.value) return;
    isLoading.value = true;

    const result = await companySettingsSecure.deleteUserSetting({
      company_id: companyId.value,
      ...(useOwner === false ? {} : { owner: user.value?.id as number }),
      key,
    });

    if (result.status) {
      !skipSuccessNotices && await showSuccessToast({
        message: t('delete.success'),
      });
      await fetchSetting();
      hasSetting.value = false;
      onDelete?.();
      updateModuleActions({ actions: { [currentKey]: false }, module_type: ProductionType.pretrial });
      updateModuleActions({ actions: { [currentKey]: false }, module_type: ProductionType.judicial });
    } else {
      await showDangerToast({
        message: t('delete.error'),
      });
    }
    isLoading.value = false;

    await dispatchSignal(SignalType.integrationServiceConnectionUpdated);
  }

  return {
    fetchSetting,
    saveSetting,
    deleteSetting,
    hasSetting,
    isLoading,
  };

}
