import { useModelDialog } from '@/hooks/useModelDialog';
import { FeePayment, FeePaymentModel, useDocumentsApi } from '@/hooks/useDocumentsApi';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { ITextInputType } from '@/components/textInput/useTextInput';
import { mbToBites } from '@/hooks/useFileManager';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { ErrorsMap } from '@/hooks/useErrors';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import {
  ApiResponse,
  ApiResponseError,
  ApiResponseSuccess,
} from '@/service/api';
import { computed, Ref, ref } from 'vue';
import {
  useFetchEstateOwners,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useFetchEstateOwners';
import { Debtor } from '@/hooks/useDebtors';

export const useFeePaymentModel = (debtor: Ref<Debtor>) => {
  const { debtorId, productionType } = useInjectDebtorDialog();
  const { t } = useLocalI18n('debtor.documents.forms.paymentDocument');
  const { createFeePayment, updateFeePayment, fetchFeePayment } = useDocumentsApi();
  const { fetchEstateOwners } = useFetchEstateOwners();

  const fields = computed(() => [
    {
      key: 'document_number',
      field: 'document_number',
      type: ActiveFormFieldType.input,
      options: {
        label: t('fields.document_number'),
        state: ['primary', 'short'],
      },
    },
    {
      key: 'document_amount',
      field: 'document_amount',
      type: ActiveFormFieldType.input,
      options: {
        type: ITextInputType.number,
        label: t('fields.document_amount'),
        state: ['primary', 'short'],
        numberOptions: {
          min: 0,
          step: '0.01',
        },
      },
    },
    {
      key: 'document_date',
      field: 'document_date',
      type: ActiveFormFieldType.date,
      options: {
        label: t('fields.document_date'),
        state: ['primary', 'short'],
      },
    },
    {
      key: 'file',
      field: 'file',
      type: ActiveFormFieldType.file,
      options: {
        label: t('fields.file'),
        state: ['primary', 'short'],
        maxSize: mbToBites(30),
      },
    },
    {
      key: 'oktmo',
      field: 'oktmo',
      type: ActiveFormFieldType.input,
      options: {
        label: t('fields.oktmo'),
        state: ['primary', 'short'],
      },
    },
    {
      key: 'recipient_inn',
      field: 'recipient_inn',
      type: ActiveFormFieldType.input,
      options: {
        label: t('fields.recipient_inn'),
        state: ['primary', 'short'],
      },
    },
    {
      key: 'recipient_kpp',
      field: 'recipient_kpp',
      type: ActiveFormFieldType.input,
      options: {
        label: t('fields.recipient_kpp'),
        state: ['primary', 'short'],
      },
    },
    {
      key: 'estate_object',
      field: 'estate_object',
      type: ActiveFormFieldType.searchSelect,
      options: {
        label: t('fields.estate_object'),
        state: ['drop-up', 'primary'],
        prefetch: true,
        isAutocomplete: false,
        displayField: ['owner_name', 'ownership_registration_number'],
        valueField: 'id',
        searchQuery: () => fetchEstateOwners(debtorId),
      },
    },
    {
      key: 'debtor_tenant',
      field: 'debtor_tenant',
      type: ActiveFormFieldType.select,
      options: {
        label: t('fields.debtor_tenant'),
        state: ['drop-up', 'primary'],
        displayField: 'full_name',
        valueField: 'id',
        options: debtor.value.debtor_tenant_profiles,
      },
    },
    {
      key: 'status',
      field: 'status',
      type: ActiveFormFieldType.select,
      options: {
        label: 'Статус',
        state: ['primary'],
        options: [
          {
            label: 'Оплата пошлины',
            value: 'paid',
          }, {
            label: 'Доплата пошлины',
            value: 'repaid',
          },
        ],
      },
    },
    {
      key: 'need_return_payment',
      field: 'need_return_payment',
      type: ActiveFormFieldType.checkbox,
      options: {
        label: 'Требуется возврат пошлины',
        state: ['primary'],
      },
    },
  ]);

  const modelDialogCreate = useModelDialog<
    FeePaymentModel,
    FeePayment
    >({
      key: 'fee_payment_add',
      title: 'Добавление платежного поручения об оплате пошлины',
      // @ts-ignore
      fields,
      onModelUpdate: async (payload) => {
        return createUpdateFeePayment(payload);
      },
    });

  const feePaymentExtra = ref<Record<string, any>>({});
  const modelDialogUpdate = useModelDialog<
    FeePaymentModel,
    FeePayment,
    number
    >({
      key: 'fee_payment_edit',
      title: 'Редактирование платежного поручения об оплате пошлины',
      // @ts-ignore
      fields,
      getForm: (payload) => fetchFeePayment({
        id: payload,
        debtor_id: debtorId.value,
      }).then((r) => {
        if (r.status) {
          feePaymentExtra.value = r.response.extra;
        }
        return r;
      }) as Promise<ApiResponse<FeePaymentModel>>,
      onModelUpdate: async (payload, id) => {
        return createUpdateFeePayment(payload, id);
      },
    });

  function createUpdateFeePayment(payload: FeePaymentModel, id?: number) {

    const { errorsMap, hasErrors } = Object.entries(payload).reduce(
      (acc, [key, value]) => {
        if (['document_number', 'document_amount', 'document_date', 'file'].includes(key)) {
          if (!value) {
            acc.errorsMap[key] = ['Поле обязательно'];
            acc.hasErrors = true;
          }
        }
        return acc;
      }, {
        errorsMap: {},
        hasErrors: false,
      } as { errorsMap: ErrorsMap<any>; hasErrors: boolean},
    );

    if (hasErrors) {
      return {
        status: false,
        response: errorsMap,
      } as ApiResponseError;
    }

    const { file, ...restPayload } = payload;

    if (!id) {
      return createFeePayment({
        ...payload,
        debtor_id: debtorId.value,
        production_type: productionType.value,
      }) as Promise<ApiResponseSuccess<FeePayment>>;
    }

    return updateFeePayment({
      id,
      ...restPayload,
      ...(typeof file !== 'string' ? { file } : {}),
      debtor_id: debtorId.value,
      production_type: productionType.value,
      extra: JSON.stringify(feePaymentExtra.value || {}),
    }) as Promise<ApiResponseSuccess<FeePayment>>;
  }

  return {
    openCreateDialog: modelDialogCreate.showDialog,
    openUpdateDialog: modelDialogUpdate.showDialog,
  };
};
