import { SignerGroup } from '@/pages/organizations/company/tabs/documents/types';
import { ref, Ref, watch } from 'vue';
import { AccountImage, AccountImageModel, useAccountImages } from '@/hooks/useAccountImages';
import { dataURLtoFile } from '@/hooks/useFileManager';

export type AccountImageName = 'Организация_Подпись' | 'Организация_Печать';

export type AccountImageGroup = Partial<Record<AccountImageName, AccountImage>>;

export type AccountImageGroupsList = Array<AccountImageGroup>;

export const useSignImages = (
  signGroups: Ref<SignerGroup[]>,
  companyId: Ref<number>,
) => {
  const imagesApi = useAccountImages();

  const deletedImageIds = ref<number[]>([]);

  const signImageGroupsList = ref<AccountImageGroupsList>([]);

  const fetchSignImages = async () => {
    deletedImageIds.value = [];
    const response = await imagesApi.fetchList({
      limit: 1000,
      filters: {
        company_id: companyId.value,
        klass: 21,
      },
      page: 1,
    });
    if (!response.status) {
      return;
    }
    signImageGroupsList.value = signGroups.value.reduce((acc, [signDoc]) => {
      const docId = signDoc.id as number;
      if (!docId) {
        acc.push({});
      } else {
        const docSignImages = response.response.results.filter((accountImage) => accountImage.document === docId);
        const signImg = docSignImages.reduce((accSignImg, accountImage) => {
          accSignImg[accountImage.name as AccountImageName] = accountImage;
          return accSignImg;
        }, {} as AccountImageGroup);
        acc.push(signImg);
      }
      return acc;
    }, [] as AccountImageGroupsList);
  };

  watch([companyId, signGroups], () => {
    // загружаем картинки только когда загрузились группы
    if (signGroups.value.length) {
      fetchSignImages();
    }
  }, { immediate: true });

  const updateSignImageGroup = (signIndex: number, newGroup: AccountImageGroup) => {
    const oldGroup = signImageGroupsList.value[signIndex];
    if (oldGroup) {
      const oldIds = Object.values(oldGroup).filter((doc) => doc.id).map(({ id }) => id) as number[];
      const newIds = Object.values(newGroup).filter((doc) => doc.id).map(({ id }) => id) as number[];
      const deletedIds = oldIds.filter((id) => !newIds.includes(id));
      console.log('deletedIds', deletedIds);
      deletedImageIds.value = deletedIds;
    }
    signImageGroupsList.value.splice(signIndex, 1, newGroup);
  };
  return {
    deletedImageIds,
    fetchSignImages,
    updateSignImageGroup,
    signImageGroupsList,
  };
};
