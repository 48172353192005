import { EgrnPayload } from '@/hooks/useDialog';
import { computed, ref, SetupContext } from 'vue';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import {
  RosreestrStatusLocalModel,
  RosreestrStatusModelType,
  useRosreestr,
} from '@/hooks/useRosreestr';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { useSocket } from '@/hooks/useSocket';
import { SocketSubscriber } from '@/store/modules/socket';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';
import { ApiCommand } from '@/store/modules/api';
import { getSocketMessageTaskID } from '@/types/socket';
import { DataFileResponse } from '@/types/datafile';

export const useEgrnDialog = (props: EgrnPayload, emit: SetupContext['emit'] | any) => {
  const { t } = useLocalI18n('egrn');

  const { companyId } = useDefaultCompany();

  const {
    extractFromEgrn,
  } = useRosreestr();

  const model = ref<RosreestrStatusLocalModel>({
    data: false,
    force: false,
    rights: false,
    ignore_norights: true,
  });

  const {
    dispatchSignal,
    awaitSignalResponse,
  } = useSignal();

  const {
    showToast,
  } = useToast();

  const {
    subscribe,
  } = useSocket();

  const submit = async () => {
    const maxProgress = ref(0);
    const currentProgress = ref(0);

    const progressbars = computed(() => ([
      {
        key: 'progress',
        label: 'egrn.toast.progress',
        max: maxProgress.value,
        current: currentProgress.value,
      },
    ]));

    const filters = await awaitSignalResponse<Record<any, any>>(
      SignalType.getDebtorFilters,
      SignalType.debtorFilters,
    );

    const { status, response } = await extractFromEgrn({
      company_id: companyId.value!,
      ...props.debtorIds?.length ? {
        debtor_ids: props.debtorIds,
      } : {
        filters: filters || {},
      },
      type: ([
        model.value.data && RosreestrStatusModelType.data,
        model.value.rights && RosreestrStatusModelType.rights,
      ] as Array<RosreestrStatusModelType | boolean>)
        .filter(Boolean) as Array<RosreestrStatusModelType>,
      force: model.value.force,
      ignore_norights: model.value.ignore_norights,
      production_type: props.productionType,
    });

    if (status) {
      maxProgress.value = 0;
      currentProgress.value = 0;

      emit('hide');

      const hideProgressToast = await showToast({
        message: 'egrn.toast.message',
        level: IToastLevel.info,
        duration: null,
        progressbars,
        isCloseable: true,
      });

      const unsub = await subscribe({
        condition: (payload) => (
          (
            // @ts-ignore
            payload.action === 'progress_event'
            && getSocketMessageTaskID(payload) === response.uuid
          ) || (
            payload.action === 'model_event'
            && payload.data.model === 'rosreestr/status/update'
            && payload.data.obj.id === (response.uuid as unknown as number)
          )
        ),
        async handler(payload) {
          // @ts-ignore
          if (payload.action === 'progress_event') {
            maxProgress.value = payload.data.progress.total;

            if (currentProgress.value !== maxProgress.value) {
              currentProgress.value = payload.data.progress.step;
            }
          } else if (payload.action === 'model_event') {
            currentProgress.value = maxProgress.value;

            // @ts-ignore
            const { stats } = payload?.data?.obj || {};

            if (stats) {
              await showToast({
                message: 'egrn.toast.success',
                level: IToastLevel.success,
                duration: 5000,
              });
              dispatchSignal(SignalType.debtorsUpdated);
            } else {
              await showToast({
                message: 'egrn.toast.failure',
                level: IToastLevel.danger,
                duration: 5000,
              });
            }

            hideProgressToast();
            unsub();
            dispatchSignal(SignalType.debtorsUpdated);

            emit('close');
          }
        },
      } as SocketSubscriber);

      const checkTaskStatus = async () => {
        const resp = await commonLegacyApiRequest<DataFileResponse>({
          command: ApiCommand.getDataFilePackage,
          params: {
            id: response.uuid,
          },
        });
        if (resp.status) {
          const statusRecord = resp.response.statusrecord_set[0];
          if (statusRecord) {
            if (statusRecord.state === 3 || statusRecord.state === 4) {
              maxProgress.value = statusRecord.status_value_max;
              currentProgress.value = statusRecord.status_value;
            }
            if (statusRecord.state === 4) {
              const textFinal = statusRecord.status_text.split(':')[0] ?? statusRecord.status_text;
              showToast({
                label: 'Выполнено успешно',
                message: textFinal,
                level: IToastLevel.danger,
                duration: 5000,
              });
              return true;
            } if (statusRecord.state === 3) {
              showToast({
                label: statusRecord.state_name,
                message: statusRecord.status_text,
                level: IToastLevel.success,
                duration: 5000,
              });
              return true;
            }
          }
        }
      };

      const intervalId = setInterval(async () => {
        const isDone = await checkTaskStatus();
        if (isDone) {
          clearInterval(intervalId);
          hideProgressToast();
          unsub();
          dispatchSignal(SignalType.debtorsUpdated);
        }
      }, 5000);
    } else {
      await showToast({
        label: t('toast.failure'),
        // @ts-ignore
        message: response?.detail ?? 'Неизвестная ошибка',
        level: IToastLevel.danger,
        duration: 5000,
      });
      emit('close');
    }
  };

  return {
    t,
    model,
    submit,
  };
};
