import * as Grid from '@/shared/grid';
import {
  mapReportingDashboardToPayload, mapReportingDashboardToPostPayload,
  ReportingDashboard, ReportingDashboardMode, ReportingDashboardPostRaw,
  ReportingDashboardRaw,
} from '@/service/api/reporting/dasboard';
import {
  DateTimeString,
  Tile, TileParameters,
  UUID,
} from '@/service/api/reporting/tile';
import { ApiCommand } from '@/service/api/reporting/apiCommand';

export type AvailablemodelsSchemaModel = {
  name: string;
}

export type DashboardGetListModel = {
  company_id: number;
}

export type DashboardGetModel = {
  company_id: number;
  dashboard_id: UUID;
}

export type DashboardPostModel = {
  company_id: number;
} & Omit<ReportingDashboard, 'id'|'position'>

export type DashboardPostModelPayload= {
  company_id: number;
} & ReportingDashboardPostRaw

export type DashboardPutModel = {
  company_id: number;
  dashboard_id: UUID;
} & ReportingDashboard

export type DashboardTestDataPayload = {
  company_id: number;
} & Record<string, any>;

export type DashboardPutModelPayload = {
  company_id: number;
  dashboard_id: UUID;
} & ReportingDashboardRaw

export type DashboardPatchModel = {
  company_id: number;
  dashboard_id: UUID;
} & Partial<ReportingDashboard>

export type DashboardPatchModelPayload = {
  company_id: number;
  dashboard_id: UUID;
} & Partial<ReportingDashboardRaw>

export type DashboardGetFullModel = DashboardGetModel;
export type DashboardDeleteModel = DashboardGetModel;

export type TileGetListModel = {
  company_id: number;
  dashboard_id: UUID;
}

export type TileGetModel = {
  company_id: number;
  dashboard_id: UUID;
  tile_id: UUID;
}

export type TilePostModel = {
  company_id: number;
  dashboard_id: UUID;
  all_companies?: boolean;
  companies?: number[];
} & Omit<Tile, 'id'>

export type TilePostModelRaw = {
  company_id: number;
  dashboard_id: UUID;
  config: string;
  all_companies?: boolean;
  companies?: number[];
} & Omit<Tile, 'id'>;

export type TilePatchModel = {
  company_id: number;
  dashboard_id: UUID;
  tile_id: UUID;
} & Partial<Tile> & { config?: Grid.TileGridConfig };

export type TilePatchModelRaw = {
  company_id: number;
  dashboard_id: UUID;
  tile_id: UUID;
} & Partial<Tile>;

export type TilePutModel = {
  company_id: number;
  dashboard_id: UUID;
  tile_id: UUID;
} & Tile

export type TilePutModelRaw = {
  company_id: number;
  dashboard_id: UUID;
  tile_id: UUID;
} & Tile

export type TileDeleteModel = {
  company_id: number;
  dashboard_id: UUID;
  tile_id: UUID;
}

export type TileGetDataModel = {
  company_id: number;
  dashboard_id: UUID;
  tile_id: UUID;
}

export type TilePostParamsModel = {
  company_id: number;
  dashboard_id: UUID;
  tile_id: UUID;
} & {
  parameters: TileParameters;
}

export type TilePostParamsModelRaw = {
  company_id: number;
  dashboard_id: UUID;
  tile_id: UUID;
} & {
  parameters: TileParameters;
}

export type TilePatchParamsModel = {
  company_id: number;
  dashboard_id: UUID;
  tile_id: UUID;
} & {
  parameters: Partial<TileParameters>;
}

export type TilePatchParamsModelRaw = {
  company_id: number;
  dashboard_id: UUID;
  tile_id: UUID;
} & {
  parameters: Partial<TileParameters>;
}

export type ReportingModel = Record<any, any>

export type ReportingModels = {
  [ApiCommand.availableModelsGet]: undefined;
  [ApiCommand.availableModelsGetSchema]: AvailablemodelsSchemaModel;
  // dashboards
  [ApiCommand.dashboardGetList]: DashboardGetListModel;
  [ApiCommand.dashboardGet]: DashboardGetModel;
  [ApiCommand.dashboardPost]: DashboardPostModel;
  [ApiCommand.dashboardPut]: DashboardPutModel;
  [ApiCommand.dashBoardPatch]: DashboardPatchModel;
  [ApiCommand.dashboardGetFull]: DashboardGetFullModel;
  [ApiCommand.dashboardDelete]: DashboardDeleteModel;
  [ApiCommand.dashboardTestChart]: DashboardTestDataPayload;
  [ApiCommand.dashboardCopy]: DashboardGetModel & {
    name: string; save_parent_id?: boolean; mode: ReportingDashboardMode; all_companies?: boolean; companies?: number[];
  };
  // tiles
  [ApiCommand.tileGetList]: TileGetListModel;
  [ApiCommand.tileGet]: TileGetModel;
  [ApiCommand.tilePost]: TilePostModel;
  [ApiCommand.tilePatch]: TilePatchModel;
  [ApiCommand.tilePut]: TilePutModel;
  [ApiCommand.tileDelete]: TileDeleteModel;
  [ApiCommand.tileGetData]: TileGetDataModel;
  [ApiCommand.tilePostData]: TilePostParamsModel;
  [ApiCommand.tilePatchData]: TilePatchParamsModel;
}

export type ReportingApiPayload = {
  [ApiCommand.availableModelsGet]: undefined;
  [ApiCommand.availableModelsGetSchema]: AvailablemodelsSchemaModel;
  [ApiCommand.dashboardGetList]: DashboardGetListModel;
  // dashboards
  [ApiCommand.dashboardGet]: DashboardGetModel;
  [ApiCommand.dashboardPost]: DashboardPostModelPayload;
  [ApiCommand.dashboardPut]: DashboardPutModelPayload;
  [ApiCommand.dashBoardPatch]: DashboardPatchModelPayload;
  [ApiCommand.dashboardGetFull]: DashboardGetFullModel;
  [ApiCommand.dashboardDelete]: DashboardDeleteModel;
  [ApiCommand.dashboardTestChart]: any;
  [ApiCommand.dashboardCopy]: DashboardGetModel & {
    name: string; save_parent_id?: boolean; mode: ReportingDashboardMode; all_companies?: boolean; companies?: number[];
  };
  // tiles
  [ApiCommand.tileGetList]: TileGetListModel;
  [ApiCommand.tileGet]: TileGetModel;
  [ApiCommand.tilePost]: TilePostModelRaw;
  [ApiCommand.tilePatch]: TilePatchModelRaw;
  [ApiCommand.tilePut]: TilePutModelRaw;
  [ApiCommand.tileDelete]: TileDeleteModel;
  [ApiCommand.tileGetData]: TileGetDataModel;
  [ApiCommand.tilePostData]: TilePostParamsModelRaw;
  [ApiCommand.tilePatchData]: TilePatchParamsModelRaw;
}

export type MapModel<C extends ApiCommand> = (
  model: ReportingModels[C]
) => ReportingApiPayload[C]

type ReportingModelMappers = {
  [key in ApiCommand]?: MapModel<key>
}

const prepareDashboardModel = (obj: Record<string, any>) => Object.fromEntries(
  Object.entries(obj).map(([key, value]) => {
    if (key === 'config') return [key, Grid.gridConfigToJSON(value)];
    return [key, value];
  }),
);

export const modelToApiDataMap: ReportingModelMappers = {
  [ApiCommand.dashboardPost]: (model) => ({
    company_id: model.company_id,
    ...mapReportingDashboardToPostPayload(model),
  }),
  [ApiCommand.dashboardPut]: (model) => ({
    company_id: model.company_id,
    ...mapReportingDashboardToPayload(model),
  }) as DashboardPutModelPayload,
  [ApiCommand.dashBoardPatch]: (
    model,
  ) => prepareDashboardModel(model) as DashboardPatchModelPayload,

  [ApiCommand.tilePost]: (
    model,
  ) => ({
    ...model,
    config: Grid.tileGridConfigToModel(model.config),
  }) as TilePostModelRaw,

  [ApiCommand.tilePatch]: (
    model,
  ) => ({
    ...model,
    ...(
      model.config
        ? { config: Grid.tileGridConfigToModel(model.config) }
        : {}
    ),
    ...(
      model.mobile_config
        ? { mobile_config: JSON.stringify(model.mobile_config) }
        : {}
    ),
  }) as TilePatchModelRaw,

  [ApiCommand.tilePut]: (
    model,
  ) => ({
    ...model,
  }),

  [ApiCommand.tilePostData]: (
    model,
  ) => ({
    tile_id: model.tile_id,
    company_id: model.company_id,
    dashboard_id: model.dashboard_id,
    parameters: model.parameters,
  }),

  [ApiCommand.tilePatchData]: (
    model,
  ) => ({
    tile_id: model.tile_id,
    company_id: model.company_id,
    dashboard_id: model.dashboard_id,
    parameters: model.parameters,
  }),

};
