export type GridConfig = {
  rows: number;
  cols: number;
  rowSizes: string;
  colSizes: string;
}

export type GridConfigJSON = string;

export const gridConfigToJSON = (conf: GridConfig): GridConfigJSON => {
  try {
    return JSON.stringify(conf);
  } catch (e) {
    console.error(e);
    return '' as GridConfigJSON;
  }
};

export const gridConfigFromResponse = (conf: GridConfigJSON): GridConfig => {
  if (!conf.length) {
    return {
      rows: 4,
      cols: 4,
      rowSizes: '150px 300px 300px 300px',
      colSizes: '1fr 1fr 1fr 1fr',
    };
  }

  return JSON.parse(conf) as GridConfig;
};

export const mapGridConfigToCss = (gridConfig: GridConfig): string => {
  const rowSizesArr = gridConfig.rowSizes.split(' ');
  const colSizes = gridConfig.colSizes.split(' ');
  const rowStyle = rowSizesArr.reduce(
    (acc, size) => `${acc} ${size}`,
    'grid-template-rows:',
  );
  const colStyle = colSizes.reduce(
    (acc, size) => `${acc} ${size}`,
    'grid-template-columns:',
  );
  return `${rowStyle}; ${colStyle}; display: grid`;
};

export type TileGridConfig = {
  rowStart: number; rowEnd: number; colStart: number; colEnd: number;
}

export type TileGridConfigJSON = string;

export const tileGridConfigToModel = (conf: TileGridConfig): TileGridConfigJSON => {
  try {
    return JSON.stringify(conf);
  } catch (e) {
    console.error(e);
    return '' as TileGridConfigJSON;
  }
};
export const tileGridConfigFromResponse = (conf: TileGridConfigJSON): TileGridConfig => {
  if (!conf.length) {
    return {
      rowStart: 1,
      rowEnd: 2,
      colStart: 1,
      colEnd: 2,
    };
  }
  return JSON.parse(conf) as TileGridConfig;
};

export const mapTileGridConfigToCss = (
  conf: TileGridConfig,
): string => `grid-area: ${conf.rowStart} / ${conf.colStart} / ${conf.rowEnd} / ${conf.colEnd}`;
