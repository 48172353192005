export enum ApiCommand {
  availableModelsGet = 'availableModelsGet',
  availableModelsGetSchema = 'availableModelsGetSchema',

  dashboardGetList = 'dashboardGetList',
  dashboardGet = 'dashboardGet',
  dashboardPost = 'dashboardPost',
  dashBoardPatch = 'dashBoardPatch',
  dashboardCopy = 'dashboardCopy',
  dashboardPut = 'dashboardPut',
  dashboardTestChart = 'dashboardTestChart',
  dashboardDelete = 'dashboardDelete',
  dashboardGetFull = 'dashboardGetFull',

  tileGetList = 'tileGetList',
  tileGet = 'tileGet',
  tilePost = 'tilePost',
  tilePatch = 'tilePatch',
  tilePut = 'tilePut',
  tileDelete = 'tileDelete',

  tileGetData = 'tileGetData',
  tilePostData = 'tilePostData',
  tilePatchData = 'tilePatchData',
}
