import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { AutoTabKey, JudicialAutomationModel } from '@/components/automation/judicial/types';
import {
  Ref, ref,
} from 'vue';
import {
  defaultCheckboxOptions,
  fieldsUniter,
  updatePeriodDefault,
  getCheckbox,
} from '@/components/automation/judicial/tabs/utils';
import { REGEX_EMAIL } from '@/utils/reg-exp';
import { Employee } from '@/hooks/useEmployees';
import { LabelValue } from '@/components/automation/judicial/tabs/types';

export function getCourtDecisionsFields(
  model: Ref<JudicialAutomationModel>,
  rootT: (s: string) => string,
  employees: Ref<Employee[]>,
) {

  const emailOptions = ref<LabelValue[]>([]);

  emailOptions.value = model.value.send_electronic_court_decisions_notify_emails.map((v) => ({ value: v, label: v }))
    .concat(employees.value
      .filter((empl) => !model.value.send_electronic_court_decisions_notify_emails.includes(empl.email))
      .map((empl) => ({
        label: empl.email,
        value: empl.email,
      })));

  return fieldsUniter(AutoTabKey.courtDecisions, [
    {
      key: 'auto_refiling_claims',
      type: ActiveFormFieldType.checkbox,
      options: {
        ...defaultCheckboxOptions(model, rootT),
      },
      onUpdateModelValue: () => {
        updatePeriodDefault('auto_refiling_claims_period');
        model.value.auto_filing_isk = false;
        model.value.auto_filing_claim_by_no_debtor_info = false;
        model.value.auto_filing_claim_by_another_jurisdiction = false;
        model.value.send_electronic_court_decisions = false;
      },
    },
    {
      key: 'auto_refiling_claims_period',
      type: ActiveFormFieldType.schedule,
      state: ['primary'],
      options: {
        state: ['primary'],
        isDisabled: !model.value.auto_refiling_claims,
      },
    },
    getCheckbox('auto_filing_isk', model, rootT, !model.value.auto_refiling_claims),
    getCheckbox('auto_filing_claim_by_no_debtor_info', model, rootT, !model.value.auto_refiling_claims),
    getCheckbox('auto_filing_claim_by_another_jurisdiction', model, rootT, !model.value.auto_refiling_claims),
    getCheckbox('sync_active_court_cases', model, rootT, !model.value.auto_refiling_claims),
    getCheckbox('sync_archive_court_cases', model, rootT, !model.value.auto_refiling_claims),
    getCheckbox('send_electronic_court_decisions', model, rootT, !model.value.auto_refiling_claims),
    {
      key: 'send_electronic_court_decisions_notify_emails',
      type: ActiveFormFieldType.select,
      onUpdateModelValue: (model: JudicialAutomationModel, $event: Array<string>) => {
        // @ts-ignore
        const values = $event.filter((value) => REGEX_EMAIL.test(value));
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        model.send_electronic_court_decisions_notify_emails = [...values];
        const newValues = values.filter(
          (v) => !emailOptions.value.find(({ value }: LabelValue) => value === v),
        );
        // @ts-ignore
        emailOptions.value = [
          ...newValues.map((value) => ({ label: value, value, key: value })),
          ...emailOptions.value,
        ];
      },
      options: {
        isDisabled: !model.value.judicial_automation_enabled || !model.value.auto_refiling_claims || !model.value.send_electronic_court_decisions,
        multiple: true,
        fillable: true,
        displayField: 'value',
        options: emailOptions.value,
      },
      defaultValue: model.value.send_electronic_court_decisions_notify_emails || [],
    },
  ]);
}
