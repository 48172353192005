import {
  Captcha,
  Error,
  Exception,
  Success,
  SuspiciousActivity,
  SuspiciousActivityResponse,
  GosuslugiProgressMessage,
} from '@/pages/exchange/integration/dialogs/integrationGosuslugi/useGosuslugi';
import { IToastProgressbar, useToast } from '@/hooks/useToast';
import { ref, Ref } from 'vue';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useSocket } from '@/hooks/useSocket';
import { useUnsubs } from '@/hooks/useUnsubs';
import { ExtraInputModel } from '@/pages/exchange/integration/dialogs/integrationGosuslugi/types';

const TIMEOUT_SECONDS = 3 * 60;

export const useEsiaSocket = (
  progressbars: Ref<[IToastProgressbar]>,
  isLoading: Ref<boolean>,
  extraInputModel: Ref<ExtraInputModel>,
) => {
  const { t } = useLocalI18n('exchange.integration.gosuslugi');
  const { subscribe } = useSocket();
  const { sub, unsub } = useUnsubs();
  const { showPureDangerToast, showPureInfoToast } = useToast();
  const authTimer = new CountDownTimer();

  const waitEsiaAuth = async (socketId: number) => {

    authTimer.reset();
    progressbars.value[0].current = 0;

    const closeMainToastCallback = await showPureInfoToast({
      duration: null,
      progressbars,
      countdownRef: authTimer.getCurrent(),
      isCloseable: false,
      params: {
        label: 'Авторизация на госуслугах',
      },
    });
    sub(closeMainToastCallback);

    const isSuccessEsiaAuth = await new Promise<boolean>(async (_resolve) => {

      function resolve(success: boolean) {
        extraInputModel.value = undefined;
        unsub(unsubSocket);
        unsub(closeMainToastCallback);
        authTimer.stop();
        _resolve(success);
      }

      function fail(errorType: string, userMessage = '', logMessage = '') {
        console.error(`Esia login failed: ${errorType}`, logMessage);
        unsub(unsubSocket);
        unsub(closeMainToastCallback);
        showPureDangerToast({
          duration: null,
          params: {
            label: 'Ошибка авторизации на госуслугах',
            message: userMessage,
          },
        });
        authTimer.stop();
        resolve(false);
      }

      authTimer.start(() => {
        fail('Client timeout', 'Время ожидания от сервиса истекло');
      });

      const unsubSocket = await subscribe<GosuslugiProgressMessage>({

        condition: (payload) => {
          return payload?.data?.event === 'esia/login/'
              && payload?.data?.user_secure_data_id === socketId;
        },

        handler: (payload) => {
          console.log('handle gosuslugi socket response', payload);
          if (payload.errors?.length) {
            payload.errors.forEach((error) => {
              showPureDangerToast({
                params: {
                  label: error,
                },
              });
            });
          }
          if (payload.data.progress) {
            const { step, total } = payload.data.progress;
            progressbars.value[0].max = total;
            progressbars.value[0].current = step;
            // drop timer on every step
            authTimer.start(() => {
              fail('Client timeout', 'Время ожидания от сервиса истекло');
            });
          }
          const dataObject = payload.data.obj;
          if (dataObject) {
            isLoading.value = false;
            if ((dataObject as Success).roles) {
              closeMainToastCallback();
              resolve(true);

            } else if ((dataObject as Exception).exception) {
              const obj = dataObject as Exception;
              const message = obj.exception === 'TimeoutError' ? 'Время ожидания от сервиса истекло' : '';
              fail(obj.exception, message);

            } else if ((dataObject as Error).tech_code || (dataObject as Error).errors) {
              const obj = dataObject as Error;
              fail(`Error: ${obj.tech_code}`, obj.errors.join(', '));

            } else if ((dataObject as SuspiciousActivityResponse).requested_user_input === 'suspicious_activity') {
              fail('suspicious_activity', (dataObject as SuspiciousActivityResponse).user_message);

            } else if ((dataObject as SuspiciousActivity).user_input_request === 'suspicious_activity') {
              displayExtraUserInput(dataObject as SuspiciousActivity, extraInputModel);

            } else if ((dataObject as Captcha).captcha_img) {
              displayCaptcha(dataObject as Captcha, extraInputModel);

            } else if (dataObject.user_input_request === 'sms_code') {
              displayCodeNeeded(extraInputModel);

            } else {
              fail('unknown socket response', '', JSON.stringify(dataObject));
            }
          }
        },
      });
    });
    return isSuccessEsiaAuth;
  };

  return {
    waitEsiaAuth,
    authTimer: authTimer.getCurrent(),
  };
};

function displayExtraUserInput(obj: SuspiciousActivity, extraInputModel: Ref<ExtraInputModel>) {
  extraInputModel.value = {
    type: 'suspicious_activity',
    user_input_placeholder: obj.user_input_placeholder,
    user_input_text: obj.user_input_text,
    user_input_title: obj.user_input_title,
    user_input: '',
  };
}

function displayCaptcha(obj: Captcha, extraInputModel: Ref<ExtraInputModel>) {
  extraInputModel.value = {
    type: 'captcha',
    user_input_placeholder: 'Введите данные с изображения',
    user_input_title: 'Введите данные с изображения',
    captcha_img: `data:image/png;base64,${obj.captcha_img}`,
    user_input: '',
  };
}

function displayCodeNeeded(extraInputModel: Ref<ExtraInputModel>) {
  extraInputModel.value = {
    type: 'sms_code',
    user_input_text: 'Введите код двухфакторной авторизации',
    user_input_title: 'Код из SMS или TOTP код',
    user_input_placeholder: '',
    user_input: '',
  };
}

class CountDownTimer {

  private current = ref(0);
  private handle: NodeJS.Timer | null = null;
  private sealed = false;

  getCurrent(): Ref<number> {
    return this.current;
  }

  start(timeoutCallback: () => void) {
    if (this.sealed) {
      return;
    }
    this.stop();
    this.current.value = TIMEOUT_SECONDS;
    this.handle = setInterval(() => {
      this.current.value -= 1;
      if (this.current.value === 0) {
        this.stop();
        timeoutCallback();
      }
    }, 1000);
  }

  stop() {
    this.handle && clearInterval(this.handle);
    this.handle = null;
    this.sealed = true;
  }

  reset() {
    this.sealed = false;
  }
}
