import { TileLine, TileLineSplitBy, TileParametersModel } from '@/service/api/reporting/tile';

export type ChartFormField = {
  key: string;
  label: string;
  for_whole_period?: boolean;
  valueType: 'number'|'currency';
  line: {
    field: string;
    models: {
      name: string;
      function?: string;
      order_by?: string[];
      split_by?: TileLineSplitBy;
    }[];
  };
  availableFunctions?: Array<'count'|'sum'>;
  additionalLineOptions?: { label: string; value: ChartFormField['key'] }[];
  // например статусы смс-сообщений можно сопоставить со статусами должника и вывести эти значения рядом (столбиками)
  additionalLine?: ChartFormFieldAdditionalLine;
}

export type ChartFormFieldAdditionalLine = {
  name: string;
  // например voice_document__status
  mainLineFilterKey: string;
  // например substatus__substatus
  additionalLineFilterKey: string;
  // например статус смс к соответствующему статусу должника
  // delayed -> sms_delayed
  mainToAdditionalFiltersMap: Record<string, string>;
}

export const chartFormFields: ChartFormField[] = [
  {
    // возможно такой ключе не будет работать
    key: 'debtor_data__total_total_debt___sum',
    label: 'Общий долг (долг + пени)',
    for_whole_period: true,
    valueType: 'currency',
    line: {
      field: 'total_total_debt',
      models: [
        {
          name: 'debtor_data',
          function: 'sum',
          order_by: [
            'created_at',
          ],
          split_by: {
            split_stop: 'created_at',
            split_start: 'created_at',
          },
        },
      ],
    },
  },
  // {
  //   key: 'court_tracker__debtor__debtor_data_sum',
  //   label: 'Судебный статус - сумма',
  //   for_whole_period: true,
  //   valueType: 'number',
  //   line: {
  //     field: 'total_debt',
  //     models: [
  //       {
  //         name: 'court_tracker',
  //         function: 'sum',
  //       },
  //       {
  //         name: 'debtor',
  //         function: 'last',
  //       },
  //       {
  //         name: 'debtor_data',
  //         function: 'sum',
  //         split_by: {
  //           split_start: 'created_at',
  //           split_stop: 'created_at',
  //           split_start_null: false,
  //           split_stop_null: false,
  //         },
  //       },
  //     ],
  //   },
  // },
  {
    key: 'debtor_data___count',
    label: 'Должник - количество',
    for_whole_period: true,
    valueType: 'number',
    line: {
      field: 'status',
      models: [
        {
          name: 'debtor',
          function: 'count',
        },
        {
          name: 'debtor_data',
          function: 'last',
          order_by: [
            'created_at',
          ],
          split_by: {
            split_stop: 'created_at',
            split_start: 'created_at',
          },
        },
      ],
    },
  },
  {
    key: 'debtor_data__debts_data___count',
    label: 'Должник - количество',
    for_whole_period: false,
    valueType: 'number',
    line: {
      field: 'value',
      models: [
        {
          name: 'debtor_data',
          function: 'count',
        },
        {
          name: 'debts_data',
          function: 'last',
          order_by: [
            'start_date',
          ],
          split_by: {
            split_stop: 'end_date',
            split_start: 'start_date',
            split_start_null: false,
            split_stop_null: true,
          },
        },
      ],
    },
  },
  {
    key: 'debtor_data__debts_data___count',
    label: 'Должник - количество',
    for_whole_period: true,
    valueType: 'number',
    line: {
      field: 'value',
      models: [
        {
          name: 'debtor_data',
          function: 'count',
        },
        {
          name: 'debts_data',
          function: 'last',
          order_by: [
            'start_date',
          ],
          split_by: {
            split_stop: 'end_date',
            split_start: 'start_date',
            split_start_null: false,
            split_stop_null: true,
          },
        },
      ],
    },
  },
  {
    key: 'debtor_data__debts_data___sum',
    label: 'Должник - долг',
    valueType: 'currency',
    line: {
      field: 'value',
      models: [
        {
          name: 'debtor_data',
          function: 'sum',
        },
        {
          name: 'debts_data',
          function: 'last',
          order_by: [
            'start_date',
          ],
          split_by: {
            split_stop: 'end_date',
            split_start: 'start_date',
            split_start_null: false,
            split_stop_null: true,
          },
        },
      ],
    },
  },
  {
    key: 'debtor_data___debt-sum',
    label: 'Должник - долг (1)',
    valueType: 'currency',
    for_whole_period: true,
    line: {
      field: 'total_debt',
      models: [
        {
          name: 'debtor_data',
          function: 'sum',
          order_by: [
            'created_at',
          ],
          split_by: {
            split_stop: 'created_at',
            split_start: 'created_at',
            split_start_null: false,
            split_stop_null: true,
          },
        },
      ],
    },
  },
  // {
  //   key: 'debtor_data___sum',
  //   label: 'Должник - долг + пени (1)',
  //   valueType: 'currency',
  //   for_whole_period: true,
  //   line: {
  //     field: 'total_total_debt',
  //     models: [
  //       {
  //         name: 'debtor_data',
  //         function: 'sum',
  //         order_by: [
  //           'created_at',
  //         ],
  //         split_by: {
  //           split_stop: 'created_at',
  //           split_start: 'created_at',
  //           split_start_null: false,
  //           split_stop_null: true,
  //         },
  //       },
  //     ],
  //   },
  // },
  {
    key: 'debtor_status',
    label: 'Должник - количество',
    valueType: 'number',
    for_whole_period: true,
    line: {
      field: 'status',
      models: [
        {
          name: 'debtor_data',
          function: 'count',
          order_by: [
            'created_at',
          ],
          split_by: {
            split_stop: 'created_at',
            split_start: 'created_at',
            split_stop_null: false,
            split_start_null: false,
          },
        },
      ],
    },
  },
  {
    key: 'debtor_status',
    label: 'Должник - статус',
    valueType: 'number',
    line: {
      field: 'status',
      models: [
        {
          name: 'debtor_data',
          function: 'count',
        },
        {
          name: 'debtor',
          function: 'last',
        },
        {
          name: 'debtor_status',
          function: 'last',
          order_by: [
            'updated_at',
          ],
          split_by: {
            split_stop: 'updated_at',
            split_start: 'updated_at',
            split_stop_null: false,
            split_start_null: false,
          },
        },
      ],
    },
  },
  {
    key: 'debtor_status_1',
    label: 'Должник - статус (1)',
    valueType: 'number',
    line: {
      field: 'status',
      models: [
        {
          name: 'debtor',
          function: 'count',
        },
        {
          name: 'debtor_status',
          function: 'last',
          order_by: [
            'updated_at',
          ],
          split_by: {
            split_stop: 'updated_at',
            split_start: 'updated_at',
            split_stop_null: false,
            split_start_null: false,
          },
        },
      ],
    },
  },
  {
    key: 'debtor_status_2',
    label: 'Должник - статус (2)',
    valueType: 'number',
    line: {
      field: 'production_type',
      models: [
        {
          name: 'debtor_data',
          function: 'count',
        },
        {
          name: 'debtor',
          function: 'last',
        },
        {
          name: 'debtor_status',
          function: 'last',
          order_by: [
            'updated_at',
          ],
          split_by: {
            split_stop: 'updated_at',
            split_start: 'updated_at',
            split_stop_null: false,
            split_start_null: false,
          },
        },
      ],
    },
  },
  {
    key: 'substatus',
    label: 'Должник - подстатус',
    valueType: 'number',
    line: {
      field: 'substatus',
      models: [
        {
          name: 'debtor_data',
          function: 'count',
        },
        {
          name: 'debtor',
          function: 'last',
        },
        {
          name: 'debtor_status',
          function: 'last',
          order_by: [
            'updated_at',
          ],
        },
        {
          name: 'substatus',
          function: 'last',
          order_by: [
            'updated_at',
          ],
          split_by: {
            split_stop: 'updated_at',
            split_start: 'updated_at',
            split_stop_null: false,
            split_start_null: false,
          },
        },
      ],
    },
  },
  {
    key: 'debtor_data__penalties_data___sum',
    label: 'Должник - пени',
    valueType: 'currency',
    line: {
      field: 'value',
      models: [
        {
          name: 'debtor_data',
          function: 'sum',
        },
        {
          name: 'penalties_data',
          function: 'sum',
          order_by: [
            'start_date',
          ],
          split_by: {
            split_stop: 'start_date',
          },
        },
      ],
    },
  },
  {
    key: 'total_fee',
    label: 'Должник - пошлина',
    for_whole_period: true,
    valueType: 'currency',
    line: {
      field: 'total_fee',
      models: [
        {
          name: 'debtor_data',
          function: 'sum',
          split_by: {
            split_stop: 'created_at',
            split_start: 'created_at',
            split_stop_null: false,
            split_start_null: true,
          },
        },
      ],
    },
  },
  {
    key: 'debtor_data__finance_data',
    label: 'Должник - выплачено',
    for_whole_period: false,
    valueType: 'number',
    line: {
      field: 'value',
      models: [
        {
          name: 'debtor_data',
          function: 'sum',
        },
        {
          name: 'finance_data',
          function: 'last',
          order_by: [
            'date',
          ],
          split_by: {
            split_stop: 'date',
            split_start: 'date',
          },
        },
      ],
    },
  },
  {
    key: 'debtor_data__total_paid_up',
    label: 'Должник - выплачено (1)',
    for_whole_period: true,
    valueType: 'number',
    line: {
      field: 'total_paid_up',
      models: [
        {
          name: 'debtor_data',
          function: 'sum',
          order_by: [
            'created_at',
          ],
          split_by: {
            split_stop: 'created_at',
            split_start: 'created_at',
            split_stop_null: true,
            split_start_null: false,
          },
        },
      ],
    },
  },
  {
    key: 'debtor_data__finance_data___sum',
    label: 'Должник - выплачено (2)',
    for_whole_period: true,
    valueType: 'currency',
    line: {
      field: 'value',
      models: [
        {
          name: 'debtor_data',
          function: 'sum',
        },
        {
          name: 'finance_data',
          function: 'last',
          order_by: [
            'date',
          ],
          split_by: {
            split_stop: 'date',
            split_start: 'date',
          },
        },
      ],
    },
  },
  {
    key: 'total_accrual',
    label: 'Начислено за услуги',
    valueType: 'currency',
    line: {
      field: 'total_accrual',
      models: [
        {
          name: 'debtor_data',
          function: 'sum',
          split_by: {
            split_stop: 'created_at',
            split_start: 'created_at',
            split_stop_null: true,
            split_start_null: false,
          },
        },
      ],
    },
  },
  {
    key: 'sms_document',
    label: 'Уведомления - СМС',
    valueType: 'number',
    line: {
      field: 'status',
      models: [
        {
          name: 'sms_document',
          function: 'count',
          order_by: [
            'send_at',
          ],
          split_by: {
            split_stop: 'send_at',
            split_start: 'send_at',
            split_stop_null: true,
            split_start_null: false,
          },
        },
      ],
    },
    additionalLineOptions: [
      {
        label: 'Общий долг',
        value: 'debtor_data__total_total_debt___sum',
      },
      {
        label: 'Долг',
        value: 'debtor_data___debt-sum',
      },
    ],
    additionalLine: {
      name: 'Кол-во должников',
      mainLineFilterKey: 'sms_document__status',
      additionalLineFilterKey: 'debtor_data__substatus_name_latest',
      mainToAdditionalFiltersMap: {
        new: 'sms_new',
        delayed: 'sms_delayed',
        send: 'sms_send',
        notsend: 'sms_notsend',
        delivered: 'sms_delivered',
        failed: 'sms_failed',
        denied: 'sms_denied',
        notfound: 'sms_notfound',
      },
    },
  },
  {
    key: 'voice_document',
    label: 'Уведомления - Голосовые',
    valueType: 'number',
    line: {
      field: 'status',
      models: [
        {
          name: 'voice_document',
          function: 'count',
          order_by: [
            'send_at',
          ],
          split_by: {
            split_stop: 'send_at',
            split_start: 'send_at',
            split_stop_null: true,
            split_start_null: false,
          },
        },
      ],
    },
    additionalLineOptions: [
      {
        label: 'Общий долг',
        value: 'debtor_data__total_total_debt___sum',
      },
      {
        label: 'Долг',
        value: 'debtor_data___debt-sum',
      },
    ],
    additionalLine: {
      name: 'Кол-во должников',
      mainLineFilterKey: 'voice_document__status',
      additionalLineFilterKey: 'debtor_data__substatus_name_latest',
      mainToAdditionalFiltersMap: {
        delayed: 'voice_delayed',
        delivered: 'voice_delivered',
        failed: 'voice_failed',
        machine: 'voice_machine',
        new: 'voice_new',
        none: 'voice_none',
        not_lpr: 'voice_not_lpr',
        notfound: 'voice_notfound',
        notsend: 'voice_notsend',
        ready: 'voice_ready',
        reset_on_greeting: 'voice_reset_on_greeting',
        send: 'voice_send',
        unknown: 'voice_unknown',
      },
    },
  },
  {
    key: 'email_document',
    label: 'Уведомления - E-mail',
    valueType: 'number',
    line: {
      field: 'status',
      models: [
        {
          name: 'email_document',
          function: 'count',
          order_by: [
            'send_at',
          ],
          split_by: {
            split_stop: 'send_at',
            split_start: 'send_at',
            split_stop_null: true,
            split_start_null: false,
          },
        },
      ],
    },
    additionalLineOptions: [
      {
        label: 'Общий долг',
        value: 'debtor_data__total_total_debt___sum',
      },
      {
        label: 'Долг',
        value: 'debtor_data___debt-sum',
      },
    ],
    additionalLine: {
      name: 'Кол-во должников',
      mainLineFilterKey: 'email_document__status',
      additionalLineFilterKey: 'debtor_data__substatus_name_latest',
      mainToAdditionalFiltersMap: {
        delivered: 'email_delivered',
        new: 'email_new',
        notfound: 'email_notfound',
        send: 'email_send',
        unknown: 'email_unknown',
        notsend: 'email_notsend',
        failed: 'email_failed',
      },
    },
  },
  {
    key: 'court_tracker___count',
    label: 'Статус судебного дела - кол-во',
    valueType: 'number',
    line: {
      field: 'current_status',
      models: [
        {
          name: 'court_tracker',
          function: 'count',
          order_by: [
            'created_at',
          ],
          split_by: {
            split_stop: 'created_at',
            split_start: 'created_at',
            split_stop_null: true,
            split_start_null: false,
          },
        },
      ],
    },
  },
  {
    key: 'court_tracker___sum',
    label: 'Статус судебного дела - сумма',
    valueType: 'currency',
    line: {
      field: 'total_debt',
      models: [
        {
          name: 'court_tracker',
          function: 'sum',
          order_by: [
            'created_at',
          ],
        },
        {
          name: 'debtor',
          function: 'last',
        },
        {
          name: 'debtor_data',
          function: 'sum',
          split_by: {
            split_start: 'created_at',
            split_stop: 'created_at',
            split_start_null: false,
            split_stop_null: false,
          },
        },
      ],
    },
  },
  ...([
    ['individual_order', 'Судебный приказ (физ.)'],
    ['individual_claim', 'Исковое заявление (физ.)'],
    ['legal_order', 'Судебный приказ (юр.)'],
    ['legal_claim', 'Исковое заявление (юр.)'],
  ]).map(([field, translation]) => ({
    key: `fees_data__${translation}`,
    label: `Выплата пошлины - ${translation}`,
    valueType: 'currency' as const,
    line: {
      field,
      split_by: {
        split_stop: 'date',
        split_start: 'date',
      },
      models: [
        {
          name: 'debtor_data',
          function: 'sum',
        },
        {
          name: 'fees_data',
          function: 'last',
          order_by: [
            'date',
          ],
        },
      ],
    },
  })),
];

const modelsAreEqual = (
  m1: TileParametersModel, m2: TileParametersModel,
) => m1.name === m2.name && m1.function === m2.function;

export const getTileLineChartFormField = (
  tileLine: TileLine,
  formFields: ChartFormField[],
) => formFields.find(({ line }) => line.field === tileLine.field
      && line.models.length === tileLine.models.length
      && tileLine.models.every(
        (tileLineModel, i) => modelsAreEqual(
          tileLineModel, line.models[i] as TileParametersModel,
        ),
      ))?.key;

export const getTileLineChartFormFieldObject = (
  tileLine: TileLine,
  formFields: ChartFormField[],
) => formFields.find(({ line }) => line.field === tileLine.field
      && line.models.length === tileLine.models.length
      && tileLine.models.every(
        (tileLineModel, i) => modelsAreEqual(
          tileLineModel, line.models[i] as TileParametersModel,
        ),
      ));
