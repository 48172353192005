import { useCompanySettings } from '@/components/automation/useCompanySettings';
import { PrintModel } from '@/components/dialog/dialogs/print/printDialogTypes';
import { Ref, watch } from 'vue';

const DEFAULT = true;
let savedValue: boolean;

export function useCourtTestSetting(model: Ref<PrintModel>) {
  const { companySettings, updateCompanySettings } = useCompanySettings();

  function setValue(value: boolean) {
    savedValue = value;
    model.value.courtTest = value;
  }

  watch(companySettings, (value) => {
    if (value) {
      setValue(value.court_test);
    } else {
      setValue(savedValue ?? DEFAULT);
    }
  }, { immediate: true });

  watch(() => model.value.courtTest, (value) => {
    if (value !== savedValue) {
      updateTestCourtSetting(value);
      savedValue = value;
    }
  });

  function updateTestCourtSetting(value: boolean) {
    setTimeout(async () => {
      const { status, response } = await updateCompanySettings({
        court_test: value,
      });
      if (!status) {
        console.error('failed to update court_test setting', response);
      }
    });
  }
}
