import { useProtectedInject } from '@/hooks/useProtectedInject';
import { PreventLeaveControllerKey } from '@/symbols';
import {
  inject, ref, provide, Ref,
} from 'vue';
import { useDialog } from '@/hooks/useDialog';

export type PreventLeaveController = {
  markDirty: () => void;
  reset: () => void;
  isDirty: Ref<boolean>;
  leaveGuard: () => Promise<boolean>;
  setDismissDataCallback: (callback: () => void) => void;
  setLeaveFreshCallback: (callback: () => void) => void;
}

export const usePreventLeaveController = () => {

  const parentController = inject(PreventLeaveControllerKey);
  const isDirty = parentController ? parentController.isDirty : ref(false);
  const dismissCallback: Ref<null | (() => void)> = ref(null);
  const leaveFreshCallback: Ref<null | (() => void)> = ref(null);

  const { confirmDialog } = useDialog();

  const controller = {
    markDirty: () => {
      console.log('markDirty');
      isDirty.value = true;
    },
    reset: () => {
      isDirty.value = false;
    },
    isDirty,
    leaveGuard: async () => {
      if (!isDirty.value) {
        if (leaveFreshCallback.value) {
          await leaveFreshCallback.value();
        }
        return true;
      }
      const { result, closeDialog } = await confirmDialog({
        title: 'Данные были изменены',
        message: 'Вы уверены, что хотите сбросить изменения?',
        withConfirmation: true,
        withCancel: true,
        confirmLabel: 'Сбросить',
      });
      if (result) {
        isDirty.value = false;
        if (dismissCallback.value) {
          await dismissCallback.value();
        }
      }
      closeDialog();
      return result;
    },
    setDismissDataCallback: (newCallback: () => void) => {
      dismissCallback.value = newCallback;
    },
    setLeaveFreshCallback: (newCallback: () => void) => {
      leaveFreshCallback.value = newCallback;
    },

  } as PreventLeaveController;

  provide(PreventLeaveControllerKey, controller);
  return controller;
};

export const usePreventLeaveTrigger = () => {
  const preventLeaveController = useProtectedInject(PreventLeaveControllerKey);

  return preventLeaveController;
};
