import { useLocalValue } from '@/hooks/useLocalValue';
import { computed, SetupContext, Ref } from 'vue';
import { Order, OrderDirection, OrderMap } from '@/store/modules/api';

export const useSort = (
  sort: Ref<Order<any>[]>,
  emit: SetupContext['emit'] | any,
) => {
  const localSort = useLocalValue({ sort }, 'sort', emit);
  const sortMap = computed<OrderMap<any>>(() => (
    localSort.value?.reduce((acc, { key, direction }, order) => {
      // @ts-ignore
      acc[key] = {
        order,
        direction,
      };
      return acc;
    }, {} as OrderMap<any>) || ({} as OrderMap<any>)
  ));
  const sortColumn = (column: string) => {
    if (!localSort.value) {
      return;
    }
    const lSort = [...localSort.value];
    const index = lSort.findIndex(({ key }) => key === column);
    if (index > -1) {
      localSort.value = sortMap.value[column].direction === OrderDirection.desc
        ? lSort.filter((item, i) => i !== index)
        : lSort.map((item, i) => ((index === i) ? {
          ...item,
          direction: OrderDirection.desc,
        } : item));
    } else {
      localSort.value = [
        ...lSort || [],
        {
          key: column,
          direction: OrderDirection.asc,
        },
      ];
    }
  };

  return {
    localSort,
    sortMap,
    sortColumn,
  };
};
