export type Borrower = {
  id?: number;
  created_at: Date;
  updated_at: Date;
  partner: string;
  historical_bank_name: string;
  initial_agreement_number: string; // Первоначальный номер договора
  assignment_number: string; // Номер цессии
  assignment_date?: Date; // Дата цессии
  interest_rate: string;
  credit_limit: string;
}

export const emptyBorrower: Borrower = {
  id: undefined,
  assignment_date: undefined,
  assignment_number: '',
  credit_limit: '',
  historical_bank_name: '',
  initial_agreement_number: '',
  interest_rate: '',
  partner: '',
  updated_at: new Date(),
  created_at: new Date(),
};
