import { useStore } from 'vuex';
import { Company } from '@/hooks/useCompanies';
import { SocketSubscriber } from '@/store/modules/socket';
import { SocketMessage, SocketMsg } from '@/types/socket';

export const useSocket = () => {
  const store = useStore();

  const subscribeToCompany = async (id: Company['id']) => {
    if (store.getters['companies/defaultCompanyId'] === id) {
      return;
    }
    await store.dispatch('socket/subscribeToCompany', id);
  };

  const unsubscribeFromCompany = async (id: Company['id']) => {
    if (store.getters['companies/defaultCompanyId'] === id) {
      return;
    }
    await store.dispatch('socket/unsubscribeFromCompany', id);
  };

  const subscribe = async <T = SocketMsg>(
    config: Omit<SocketSubscriber<T>, 'id'>,
  ): Promise<() => void> => store.dispatch('socket/subscribe', config);

  const send = async (message: any) => {
    store.dispatch('socket/send', message);
  };

  const unsubscribeById = async (id: SocketSubscriber['id']) => store.dispatch('socket/unsubscribe', id);

  return {
    send,
    subscribeToCompany,
    unsubscribeFromCompany,
    subscribe,
    unsubscribeById,
  };
};
