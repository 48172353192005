import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { apiCommands as reportingApiCommands } from '@/service/api/reporting/api';
import { ApiCommand as ReportingApiCommand } from '@/service/api/reporting/apiCommand';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand } from '@/store/modules/api';

export enum ACLRule {
  dashboardIndex = 'dashboardIndex',
  organizationsIndex = 'organizationIndex',
  debtorsIndex = 'debtorsIndex',
  exchangeIndex = 'exchangeIndex',
  panelIndex = 'panelIndex',
  exchangeExport = 'exchangeExport',
}

export const useACL = () => {
  const { companyId, acl } = useProtectedDefaultCompany();
  // @TODO надо реализовать проверку
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkAccess = (rule: ACLRule): boolean => {
    if (!companyId.value) {
      return false;
    }
    if (rule === ACLRule.debtorsIndex) {
      return acl.value[ACLRule.debtorsIndex];
    }
    if (rule === ACLRule.organizationsIndex) {
      return acl.value[ACLRule.organizationsIndex];
    }
    if (rule === ACLRule.dashboardIndex) {
      return acl.value[ACLRule.dashboardIndex];
    }
    if (rule === ACLRule.exchangeExport) {
      return acl.value[ACLRule.exchangeExport];
    }
    return true;
  };

  return {
    checkAccess,
  };
};

export const fetchACL = (companyId: number) => {
  const fetchDashboardAccess = () => reportingApiCommands[ReportingApiCommand.dashboardGetList].request({
    company_id: companyId,
  });
  const fetchExchangeExportAccess = () => commonLegacyApiRequest({
    command: ApiCommand.fetchConstructorReportTemplateTypes,
    params: {
      limit: 1,
      offset: 0,
      company_id: companyId,
    },
  });

  return Promise.all([
    fetchDashboardAccess(),
    fetchExchangeExportAccess(),
  ]).then(([r1, r2]) => ({
    [ACLRule.dashboardIndex]: r1.status,
    [ACLRule.exchangeExport]: r2.status,
  })) as Promise<Record<ACLRule, boolean>>;
};
