import { Tile, TileParameters, TileTimeInterval } from '@/service/api/reporting/tile';
import { DateTime } from 'luxon';
import { getRandomNumber } from '@/utils/number';

const generateTimeIntervals = (
  { start, interval }: { start: TileTimeInterval; interval: TileTimeInterval },
): Date[] => {
  const _start = start || { years: 5 };
  const _interval = interval || { years: 1 };
  const intervals: Date[] = [];
  const nowDt = DateTime.now();
  let startDt = DateTime.now();
  {
    const {
      days, months, weeks, years,
    } = _start;
    startDt = startDt.minus({ day: days });
    startDt = startDt.minus({ month: months });
    startDt = startDt.minus({ week: weeks });
    startDt = startDt.minus({ year: years });
    startDt.startOf(((start): 'year'|'month'|'week'|'day' => {
      if (start.years) {
        return 'year';
      } if (start.months) {
        return 'month';
      } if (start.weeks) {
        return 'week';
      }
      return 'day';
    })(_start));
  }
  while (startDt.startOf('day') < nowDt.startOf('day')) {
    intervals.push(startDt.toJSDate());
    const {
      days, months, weeks, years,
    } = _interval;
    startDt = startDt.plus({ day: days });
    startDt = startDt.plus({ month: months });
    startDt = startDt.plus({ week: weeks });
    startDt = startDt.plus({ year: years });
  }
  return intervals;
};

export const generateDemoData = (
  tile: Tile,
) => {
  let time: Date[]|null = null;
  if (tile.render_parameters[0].type === 'timeline') {
    time = generateTimeIntervals(
      tile.parameters,
    );
  }
  const { lines } = tile.parameters;

  const getRandomData = () => lines.reduce((acc, line) => ({
    ...acc,
    [line.key]: getRandomNumber(10000, 100),
  }), {} as Record<string, number>);

  return {
    summary: time
      ? lines.reduce((acc, line) => ({
        ...acc,
        [line.key]: (time as Date[]).reduce((acc, _, i) => {
          acc[i] = getRandomNumber(10000, 100);
          return acc;
        }, {} as any),
      }), {} as any)
      : getRandomData(),
    time: time as Date[],
  };
};
