import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useApi2 } from '@/hooks/useApi2';
import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { getTimeIntervals } from '@/utils/time';
import { unwrapFirstRecord, unwrapListingApiResponse } from '@/service/api';
import { Ref } from 'vue';

export type PeriodicTaskSchedule = {
  minute: string;
  hour: string;
  day_of_month: string;
  day_of_week: string;
  month_of_year: string;
}

export type CompanyPeriodicTask = {
  id: number;
  company: number;
  name: string;
  schedule: PeriodicTaskSchedule;
  limit?: {
    hours: number;
    minutes: number;
  };
  count?: number;
  interval?: {
    hours: number;
    minutes: number;
  };
  extra?: Record<string, any>;
};

export type CustomPeriodicTask<
  T extends any = void
  > = CompanyPeriodicTask & T;

export const PERIODIC_TASK_DAYS = [
  { label: 'Каждый день', value: '*' },
  ...new Array(31).fill(null).map((_, i) => ({
    label: i + 1,
    value: (i + 1).toString(),
  })),
];

export const PERIODIC_TASK_HOURS = [
  ...getTimeIntervals(10, 20)
    .map((value) => ({ label: value, value })),
];

export const PERIODIC_TASK_MINUTES = (() => {
  let current = 0;
  const arr = [];
  while (current !== 60) {
    arr.push(current);
    current += 5;
  }
  return arr.map((v) => v.toString());
})();

export const PERIODIC_TASK_MONTHS: {
  label: string; key: string; value: PeriodicTaskSchedule;
}[] = [
  {
    label: 'Каждый месяц',
    key: '1-12',
    value: {
      minute: '1',
      hour: '1',
      day_of_month: '9',
      day_of_week: '*',
      month_of_year: '1-12',
    },
  },
  {
    label: 'Каждые 2 месяца',
    key: '1/2',
    value: {
      minute: '1',
      hour: '1',
      day_of_month: '9',
      day_of_week: '*',
      month_of_year: '1/2',
    },
  },
  {
    label: 'Каждые 3 месяца',
    key: '1/6',
    value: {
      minute: '1',
      hour: '9',
      day_of_month: '9',
      day_of_week: '*',
      month_of_year: '1/6',
    },
  },
  {
    label: 'Каждые 6 месяцев',
    key: '1/6',
    value: {
      minute: '1',
      hour: '9',
      day_of_month: '9',
      day_of_week: '*',
      month_of_year: '1/6',
    },
  },
  {
    label: 'Каждый год',
    key: '1/6',
    value: {
      minute: '1',
      hour: '9',
      day_of_month: '9',
      day_of_week: '*',
      month_of_year: '1/6',
    },
  },
];

export const PERIODIC_TASK_DAYS_INTERVAL: {
  label: string; value: string;
}[] = [
  {
    label: 'Раз в день',
    value: '*',
  },
  {
    label: 'Раз в 2 дня',
    value: '1/2',
  },
  {
    label: 'Раз в 3 дня',
    value: '1/3',
  },
  {
    label: 'Раз в неделю',
    value: '1/7',
  },
  {
    label: 'Раз в две недели',
    value: '1/14',
  },
  {
    label: 'Раз в месяц',
    value: '1/13',
  },
];

export const PERIODIC_TASK_OPTIONS: {
  label: string; key: string; value: PeriodicTaskSchedule;
}[] = [
  {
    label: 'Раз в день',
    key: 'per_day',
    value: {
      minute: '0',
      hour: '10',
      day_of_month: '*',
      day_of_week: '*',
      month_of_year: '*',
    },
  },
  {
    label: 'Раз в неделю',
    key: 'per_week',
    value: {
      minute: '0',
      hour: '10',
      day_of_month: '*',
      day_of_week: '1',
      month_of_year: '*',
    },
  },
  {
    label: 'Раз в месяц',
    key: 'per_month',
    value: {
      minute: '0',
      hour: '10',
      day_of_month: '1',
      day_of_week: '*',
      month_of_year: '*',
    },
  },
];

export const useSchedule = <T extends CustomPeriodicTask<any> = CustomPeriodicTask>(
  initCompanyId?: Ref<number>,
) => {
  const request = useApi2();
  const { companyId } = useProtectedDefaultCompany();

  const api = {
    availableTasksGetList: () => request<{ label: string; name: string }[]>({
      command: ApiCommand.scheduleAvailableCompanyPeriodicTaskGetList,
    }),
    task: {
      // @ts-ignore
      getList: (filters?: { name: string }) => request<ListingResponse<T>>({
        command: ApiCommand.scheduleCompanyPeriodicTaskGetList,
        params: {
          company: initCompanyId?.value ?? companyId.value,
          limit: 1000,
          offset: 0,
          ...(filters || {}),
        },
      }).then(unwrapListingApiResponse),
      // @ts-ignore
      getFirst: (filters?: { name: string }) => request<ListingResponse<T>>({
        command: ApiCommand.scheduleCompanyPeriodicTaskGetList,
        params: {
          company: initCompanyId?.value ?? companyId.value,
          limit: 1000,
          offset: 0,
          ...(filters || {}),
        },
      }).then(unwrapFirstRecord),
      create: (model: Omit<T, 'id'|'company'> & { name: string; run_immediately?: boolean }) => request<T>({
        command: ApiCommand.scheduleCompanyPeriodicTaskCreate,
        data: {
          ...model,
          company: initCompanyId?.value ?? companyId.value,
        },
      }),
      delete: (
        taskId: number,
      ) => request<boolean>({
        command: ApiCommand.scheduleCompanyPeriodicTaskDelete,
        params: { id: taskId },
      }),
      upsert: async (key: string, schedule: PeriodicTaskSchedule, immediate = false) => {
        await api.task.getList({ name: key }).then((listResponse) => {
          if (!listResponse.status) {
            return;
          }
          if (!listResponse.response.length) {
            return;
          }
          // @ts-ignore
          return Promise.all(listResponse.response.map(({ id }) => api.task.delete(id)));
        });
        await api.task.create({
          name: key,
          // @ts-ignore
          schedule,
          run_immediately: immediate,
        });
      },
    },
  };
  return api;
};
