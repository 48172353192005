import {
  ApiCommand, ListingRequestSource, ListingResponse,
} from '@/store/modules/api';
import { ProductionType } from '@/hooks/useConstructor';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';
import { formatListingRequest } from '@/components/activeTable/useActiveTable';
import { ApiResponse } from '@/service/api';

export const SEPARATOR = '_______________________________________';

export type DocumentParsingTemplateType = 'company'|'common';
export type DocumentParsingTemplateMode = 'linear'|'table'|'json';

export const TemplateMode: Record<DocumentParsingTemplateMode, DocumentParsingTemplateMode> = {
  linear: 'linear',
  json: 'json',
  table: 'table',
};

export type DocumentParsingTemplateColumnWrite = {
  position: number;
  type: string;
  title: string;
  reversed: boolean;
  is_active: boolean;
  variable_name: string|null;
  required: boolean;
  use_default: boolean;
  skip_on_empty: boolean;
  in_file_title: boolean|null;
  _key: number;
}

export type DocumentParsingTemplate = {
  id: number;
  title: string;
  rows_offset: number;
  extra_rows: number;
  encoding: string;
  sep: string;
  production_type: ProductionType;
  type: DocumentParsingTemplateType;
  mode: DocumentParsingTemplateMode;
  columns_set: DocumentParsingTemplateColumnWrite[];
  disable_calculate_penalties: boolean;
  disable_calculate_fees: boolean;
  disable_calculations: boolean;
  disable_morphology: boolean;
  terminate_after_first_error: boolean;
  search_debtors: boolean;
  sheet_name: string|null;
  company_id: number|null;
  is_default: boolean;
  register_compilation_date?: string;
  force_calculate_penalties: boolean;
  force_calculate_fees: boolean;
}

export type DocumentParsingTemplateRaw = Omit<DocumentParsingTemplate, 'id'>;

export type DocumentParsingColumnGroup = {
  id: number;
  name: string;
  columns: DocumentParsingColumnType[];
}

export type DocumentParsingColumnType = {
  id: number;
  name: string;
  column_type: string;
  description: string;
  group: number;
}

export const UTF8 = 'utf-8';

export const getEmptyParsingTemplate = (type?: ProductionType) => ({
  title: '',
  rows_offset: 0,
  extra_rows: 0,
  encoding: UTF8,
  sep: ';',
  production_type: type ?? ProductionType.judicial,
  type: 'common',
  mode: 'linear',
  columns_set: [],
  disable_calculations: false,
  disable_calculate_penalties: false,
  disable_calculate_fees: false,
  force_calculate_penalties: false,
  force_calculate_fees: false,
  disable_morphology: false,
  terminate_after_first_error: false,
  search_debtors: false,
  sheet_name: null,
  company_id: null,
  is_default: false,
}) as DocumentParsingTemplateRaw;

export const getEmptyParsingTemplateColumns = () => ({
  id: null,
  position: 0,
  type: null,
  title: '',
  reversed: false,
  is_active: true,
  variable_name: null,
  required: false,
  use_default: false,
  skip_on_empty: true,
  in_file_title: '',
}) as unknown as DocumentParsingTemplateColumnWrite;

export const useDocumentParsingTemplatesApi = () => {
  const fetchTemplates = (
    params: ListingRequestSource<{ production_type: ProductionType }>,
  ) => commonLegacyApiRequest<ListingResponse<DocumentParsingTemplate>>({
    command: ApiCommand.fetchDocumentParsingTemplatesShort,
    params: formatListingRequest(params),
  });

  type Column = DocumentParsingTemplateColumnWrite;

  const fetchTemplate = (
    id: number,
  ) => commonLegacyApiRequest<DocumentParsingTemplate>({
    command: ApiCommand.fetchDocumentParsingTemplate,
    params: { id },
  }).then((result) => ({
    ...result,
    response: {
      columns_set: result.response.template_columns.sort((column1: Column, column2: Column) => column1.position - column2.position),
      ...result.response,
    },
  }));

  type Group = DocumentParsingColumnGroup;

  const fetchColumnGroups = async (): Promise<ApiResponse<Group[]>> => {
    const result = await commonLegacyApiRequest<Group[]>({
      command: ApiCommand.fetchDocumentParsingTemplateColumnTypes,
    });
    const groups = result.response.results;
    const results = (groups || []).sort((a: Group, b: Group) => a.id - b.id).map(
      (group: Group) => ({
        ...group,
        columns: group.columns.sort((a, b) => a.name.localeCompare(b.name)),
      }),
    );
    return {
      status: result.status,
      response: results,
    };
  };

  const fetchColumnTypes = async (): Promise<ApiResponse<DocumentParsingColumnType[]>> => {
    const result = await commonLegacyApiRequest<Group[]>({
      command: ApiCommand.fetchDocumentParsingTemplateColumnTypes,
    });
    const groups = result.response.results;
    let counter = 0;
    const results = (groups || []).sort((a: Group, b: Group) => a.id - b.id).reduce(
      (acc: Group[], group: Group) => [
        ...acc,
        ...group.columns.sort((a, b) => a.name.localeCompare(b.name)), {
          column_type: `separator${counter++}`, // used as a key
          name: SEPARATOR,
        }], [],
    );
    results.pop();
    return {
      status: result.status,
      response: results,
    };
  };

  const createTemplate = (model: DocumentParsingTemplateRaw) => commonLegacyApiRequest<DocumentParsingTemplate>({
    command: ApiCommand.createDocumentParsingTemplate,
    data: model,
  });

  const updateTemplate = (model: DocumentParsingTemplate) => commonLegacyApiRequest<DocumentParsingTemplate>({
    command: ApiCommand.updateDocumentParsingTemplate,
    params: { id: model.id },
    data: model,
  });

  const removeTemplate = (id: number) => commonLegacyApiRequest({
    command: ApiCommand.removeDocumentParsingTemplate,
    params: { id },
  });

  return {
    fetchTemplate,
    fetchTemplates,
    fetchColumnTypes,
    fetchColumnGroups,
    createTemplate,
    updateTemplate,
    removeTemplate,
  };
};
