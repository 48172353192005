import {
  onBeforeUnmount, onMounted, Ref, ref,
} from 'vue';
import { IToastLevel, useToast } from '@/hooks/useToast';
import mimeTypes from 'mime-types';

export const isBase64String = (str: string|null|undefined) => str?.startsWith('data:');

export const downloadFile = (file: string, withFlag = false) => {
  try {
    const url = new URL(
      file.startsWith('http')
        ? file
        : `${window.location.origin}${file}`,
    );
    const params = new URLSearchParams(url.search);
    if (withFlag) {
      params.append('download', '1');
    }
    url.search = params.toString();

    window.open(url.toString(), '_blank');
  } catch (e) {
    console.error(`${String(e)} - file: ${file}, withFlag: ${withFlag}`);
  }
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',');
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const extension = mimeTypes.extension(mime);
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], `${filename}.${extension}`, { type: mime });
};

export const convertFileToBase64 = async (
  file: File,
): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => {
    resolve(reader.result as string);
  });
  reader.addEventListener('error', reject);
  reader.readAsDataURL(file);
});

export const useFilePaster = (callback: ((file: File) => void)) => {
  const onPaste = (e: ClipboardEvent) => {
    const [file] = Array.from(e.clipboardData?.files || []);
    if (!file) {
      return;
    }
    callback(file);
  };

  const addListeners = () => {
    window.addEventListener('paste', onPaste as any);
  };

  const removeListeners = () => {
    window.removeEventListener('paste', onPaste as any);
  };

  onMounted(addListeners);
  onBeforeUnmount(removeListeners);
};

export const mbToBites = (mbs: number) => mbs * 1024 * 1024;
export const bitesToMb = (bites: number) => (bites / 1024 / 1024).toFixed(2);

export type FileManager = {
  accept?: string[];
  multiple?: boolean;
  maxSize?: number | null;
  inputRef?: Ref<HTMLInputElement|undefined>;
}

export const useFileManager = ({
  accept = [],
  multiple = false,
  maxSize,
  inputRef,
}: FileManager = {} as FileManager) => {
  const file = ref<File>();
  const files = ref<Array<File>>([]);

  const { showToast } = useToast();

  const showMaxSizeError = () => showToast({
    label: 'pureLabel',
    params: {
      label: `Размер файла не должен превышать ${bitesToMb(maxSize as number)} МБ`,
    },
    level: IToastLevel.danger,
  });

  const selectFiles = () => {
    if (inputRef?.value) {
      inputRef.value.click();
      return;
    }
    const input = document.createElement('input');
    input.accept = accept.join(',');
    input.multiple = multiple;
    input.type = 'file';
    input.id = 'hidden_upload_file_input';

    const onInputChange = () => {
      const inputFiles = Array.from(input.files || []).filter((inputFile) => {
        if (!maxSize) return true;
        const inLimit = inputFile.size <= maxSize;
        if (!inLimit) {
          showMaxSizeError();
        }
        return inLimit;
      });

      if (multiple) {
        files.value = inputFiles.filter(Boolean);
      } else {
        file.value = inputFiles[0] || null;
      }
      input.removeEventListener('change', onInputChange);
    };

    input.addEventListener('change', onInputChange);

    input.click();
    input.remove();
  };

  const onInputChange = () => {
    if (!inputRef?.value) return;
    const inputFiles = Array.from(inputRef.value.files || []).filter((inputFile) => {
      if (!maxSize) return true;
      const inLimit = inputFile.size <= maxSize;
      if (!inLimit) {
        showMaxSizeError();
      }
      return inLimit;
    });

    if (multiple) {
      files.value = inputFiles.filter(Boolean);
    } else {
      file.value = inputFiles[0] || null;
    }
  };

  onMounted(
    () => {
      inputRef?.value?.addEventListener('change', onInputChange);
    },
  );

  onBeforeUnmount(
    () => {
      inputRef?.value?.removeEventListener('change', onInputChange);
    },
  );

  return {
    file,
    files,
    selectFiles,
  };
};
