import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { computed, Ref, ref } from 'vue';
import { AutoTabKey, JudicialAutomationModel } from '@/components/automation/judicial/types';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import { ActiveFormField } from '@/hooks/useActiveForm';
import { useFields } from '@/components/automation/judicial/tabs/fields';
import { LabelValue } from '@/components/automation/judicial/tabs/types';

type Tab = keyof typeof AutoTabKey;

export function useTabs(
  model: Ref<JudicialAutomationModel>,
  emailOptions: Ref<LabelValue[]>,
) {

  const { companyModuleType, companyId } = useProtectedDefaultCompany();
  const { fields } = useFields(model, emailOptions);
  const activeTab = ref<Tab>('court');

  const tabs = computed(() => (([
    {
      key: AutoTabKey.court,
      label: 'Подача в суд',
    },
    {
      key: AutoTabKey.courtDecisions,
      label: 'Судебные решения',
    },
    {
      key: AutoTabKey.enrichment,
      label: 'Обогащение',
    },
    companyModuleType.value !== CompanyModuleType.Vehicles && {
      key: AutoTabKey.rosreestr,
      label: 'Росреестр',
    },
    {
      key: AutoTabKey.calculations,
      label: 'Расчеты',
    },
    {
      key: AutoTabKey.registry,
      label: 'Реестр оплаты пошлин',
    },
    {
      key: AutoTabKey.debtorsActions,
      label: 'Действия по должникам',
    },
    {
      key: AutoTabKey.debtorsDocuments,
      label: 'Документы по должникам',
    },
  ]).filter(Boolean) as Array<{ key: Tab; label: string }>)
    .map((tab) => ({
      ...tab,
      id: `modal_automation_judicial_tab_${tab.key}`,
    })));

  const commonFields = computed(() => fields.value.filter(
    (field) => (field as any)?.ionFieldGroup?.key === 'common',
  ));

  const tabFields = computed(
    () => tabFieldsMap.value[activeTab.value],
  );

  const tabFieldsMap = computed(() => {
    return tabs.value.reduce((acc: Record<Tab, ActiveFormField<any>[]>, { key }: { key: Tab }) => {
      acc[key] = fields.value.filter(
        (field) => (field as any)?.ionFieldGroup?.key === key,
      ) as ActiveFormField<any>[];
      return acc;
    }, {} as Record<Tab, ActiveFormField<any>[]>);
  });

  return {
    activeTab,
    tabs,
    commonFields,
    tabFields,
    tabFieldsMap,
  };
}
