import { useStore } from 'vuex';
import { computed, Ref } from 'vue';

export enum IToastLevel {
  danger = 'danger',
  info = 'info',
  warning = 'warning',
  success = 'success'
}

export type IToastProgressbar = {
  key: string;
  label?: string;
  max: number;
  current: number;
  message?: string;
  showMessageInsteadOfLabel?: boolean;
}

export type IToast = {
  id?: string;
  label?: string;
  message?: string;
  params?: Record<string, any>;
  duration?: number | null;
  isCloseable?: boolean;
  isHideable?: boolean;
  level: IToastLevel;
  progressbars?: Array<IToastProgressbar>|Ref<Array<IToastProgressbar>>;
  onClose?: () => void;
  onClick?: () => void;
  countdown?: number; // seconds
  countdownRef?: Ref<number>; // seconds
  ionToastOptions?: {
    position: 'top'|'bottom'|'middle';
  };
}

export const useToast = () => {
  const store = useStore();

  const toasts = computed<Array<IToast>>(() => (
    store.getters['layout/toasts']
  ));

  const toastsMap = computed<Array<IToast>>(() => (
    store.getters['layout/toastsMap']
  ));

  const showToast = async (toast: Partial<IToast>) => (
    store.dispatch('layout/showToast', toast) as unknown as ((() => Promise<void>) & { id: string })
  );

  const closeToastById = async (toastId: IToast['id']) => (
    store.dispatch('layout/closeToastById', toastId)
  );

  const updateToastById = (toast: Partial<IToast> & { id: string }) => store.commit(
    'layout/updateToastById',
    toast,
  );

  const showSuccessToast = async (options: Partial<IToast>) => {
    return showToast({
      ...options,
      level: IToastLevel.success,
    });
  };

  const showPureSuccessToast = async (options: Partial<IToast>) => {
    return showSuccessToast({
      label: 'pureLabel',
      message: 'pure',
      ...options,
    });
  };

  const showDangerToast = async (options: Partial<IToast>) => {
    return showToast({
      ...options,
      level: IToastLevel.danger,
    });
  };

  const showPureDangerToast = async (options: Partial<IToast>) => {
    return showDangerToast({
      label: 'pureLabel',
      message: 'pure',
      ...options,
    });
  };

  return {
    toasts,
    showToast,
    showSuccessToast,
    showInfoToast: showSuccessToast,
    showPureInfoToast: showPureSuccessToast,
    showPureSuccessToast,
    showDangerToast,
    showPureDangerToast,
    closeToastById,
    updateToastById,
    toastsMap,
  };
};
