import { useProtectedInject } from '@/hooks/useProtectedInject';
import { RouterKey } from '@core/symbols';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { IDialog } from '@/hooks/useDialog';

export const usePlugDialogRouteParams = () => {
  const router = useProtectedInject(RouterKey);
  const { subscribeToSignal } = useSignal();

  subscribeToSignal(SignalType.addDialogInRoute, (
    {
      component, payload, params,
    }: Pick<IDialog, 'component'|'payload'|'params'>,
  ) => {
    router.push({
      query: router.currentRoute.value.query,
      hash: `#${JSON.stringify({
        component,
        payload,
        ...(params || {}),
      })}`,
    });
  });

  subscribeToSignal(SignalType.removeDialogFromRoute, async (dialog: IDialog) => {
    try {
      const routerDialog = JSON.parse(
        router.currentRoute.value.hash.substr(1),
      ) as IDialog | undefined;
      if (
        routerDialog
        && 'component' in dialog
      ) {
        if (routerDialog.component === dialog?.component) {
          await router.push({ query: router.currentRoute.value.query, hash: '' });
        }
      }
    } catch (e) {
      //
    }
  });
};
