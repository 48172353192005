import { IToastLevel, IToastProgressbar, useToast } from '@/hooks/useToast';
import { useSocket } from '@/hooks/useSocket';
import { useUnsubs } from '@/hooks/useUnsubs';
import { Ref } from 'vue';
import { ExtraInputModel } from '@/pages/exchange/integration/dialogs/integrationGosuslugi/types';

const BadData = {
  is_final: true,
  tech_code: 'bad_data',
  user_message: 'Произошла ошибка при выполнении запроса, пожалуйста обратитесь в техническую поддержку. Код ошибки: bad_data (xxx)',
};

type Exception = {
  is_final: true;
  exception: string;
};

const NoMosRuAccount = {
  is_final: true,
  tech_code: 'no_mosru_account',
  text: 'Вы не зарегистрированы на сайте mos.ru. Пройдите регистрацию и войдите заново',
};

const BadEsia = {
  tech_code: 'bad_esia_id',
  text: 'Аккаунт не найден',
};

const Totp = {
  is_final: true,
  tech_code: 'mosru_totp_enabled',
  text: 'Отключите TOTP в настройках безопасности и войдите заново',
};

const PasswordNeeded = { // response {'mosru_password': 'Qwerty123'}
  is_final: false,
  user_input_request: 'mosru_link',
};

const SmsCodeNeeded = { // response {'sms_code': '123456'}
  is_final: false,
  user_input_request: 'mosru_sms_code',
};

export function useMosRuSocket(
) {
  let unsubSocket: (() => void);
  const { subscribe } = useSocket();
  const { sub, unsub } = useUnsubs();
  const { showPureDangerToast, showPureInfoToast } = useToast();

  const waitMosRuAuth = async (
    responseId: number,
    mosRuProgressbars: Ref<[IToastProgressbar]>,
    extraInputModel: Ref<ExtraInputModel>,
    closeToast: any,
    isLoading: Ref<boolean>,
  ) => {
    const mosRuResult = await new Promise<boolean | null>(async (resolve) => {

      const showAuthError = (message: string, isTimeout = false) => {
        showPureDangerToast({
          params: {
            message,
            label: 'Ошибка авторизации на mos.ru',
          },
        });
        return resolve(isTimeout ? false : null);
      };

      unsubSocket = await subscribe<any>({

        condition: (payload) => payload?.data?.event === 'mos_ru_login'
          && payload?.data?.user_secure_data_id === responseId,

        handler: (payload) => {
          console.log('mosru handler', payload);
          const data = payload.data;
          if (data.progress) {
            const { step, total } = data.progress;
            mosRuProgressbars.value[0].max = total;
            mosRuProgressbars.value[0].current = step;
          }
          const object = payload.data.obj;
          if (!object) {
            console.warn('No data in mos ru socket');
            return; // ignore
          }
          if (object.exception) {
            return showAuthError(object.exception as string, true);
          }
          if ([NoMosRuAccount.tech_code, Totp.tech_code].includes(object.tech_code)) {
            return showAuthError(object.text);
          }
          if (object.tech_code === BadData.tech_code) {
            return showAuthError(object.user_message);
          }
          // user input is handled in onSendExtraForm
          if (object.user_input_request) {
            isLoading.value = false;
            if (object.user_input_request === PasswordNeeded.user_input_request) {
              extraInputModel.value = {
                kind: 'mos_ru',
                type: 'mos_ru_password',
                user_input: '',
                user_input_placeholder: '',
                user_input_title: 'Введите пароль от mos.ru',
              };
            } else if (object.user_input_request === SmsCodeNeeded.user_input_request) {
              extraInputModel.value = {
                kind: 'mos_ru',
                type: 'mos_ru_sms',
                user_input: '',
                user_input_placeholder: '',
                user_input_title: 'Введите SMS код',
              };
            }
          }
          if (data.progress.progress === 100) {
            if (!object.tech_code) {
              resolve(true);
            } else {
              console.error(`mos ru auth error: ${object.tech_code}`);
              return showAuthError(object.user_message, false);
            }
          }
        },
      });
      sub(unsubSocket);
      sub(closeToast);
    });

    if (mosRuResult) {
      await showPureInfoToast({
        params: { label: 'Вы успешно авторизованы на mos.ru' },
      });
    }
    unsub(unsubSocket!);
    unsub(closeToast);

    return mosRuResult;
  };

  return { waitMosRuAuth };
}
