import { EChartsOption } from 'echarts';
import cloneDeep from 'lodash/cloneDeep';
// eslint-disable-next-line import/no-unresolved
import { DatasetOption, SeriesOption } from 'echarts/types/dist/shared';
import { getPercantageChange } from '@/utils/number';
import PlateIndicator from '@/components/plate/plateIndicator/PlateIndicator.vue';
import { formatMoney } from '@/utils/string';
import * as GridConfig from '@/shared/grid';
import { renderPlateIndicatorFromProps } from '@/components/plate/plateIndicator/renders';
import {
  ChartLineSeriesVariant,
  ChartLineVariant,
} from '@/components/plate/chartConfigs/lineChart/line-chart';
import { ChartBarVariant } from '@/components/plate/chartConfigs/bar-charts';
import { ChartPieVariant, getPieChartByVariant } from '@/components/plate/chartConfigs/pie-charts';
import {
  PlateIndicatorType,
  PlateIndicatorValueType,
} from '@/components/plate/plateIndicator/index';
import { IPlateIndicatorListProps, PlateIndicatorList } from '@/components/plate/list/list';
import { I_18N_CONFIG } from '@/i18n';
import {
  getLinearChartByVariant,
  getLineChartSeriesConstructor,
  getPolarChartSeriesConstructor,
} from '@/components/plate/chartConfigs/lineChart';
import { mergeDeep } from '@/utils/object';
import { ChartPolarSeriesVariant } from '@/components/plate/chartConfigs/lineChart/polar-chart';
import { CHART_COLORS } from '@/components/plate/utils';
import { getSimpleRingIndicator } from '@/components/plate/chartConfigs/simple-indicator-ring';

export type DateRawString = string;

// '2021-01-01;2021-11-19
export type PeriodString = string;

export type PlateIndicatorValueFormatterConfig = {
  prefix?: string;
  postfix?: string;
  mask?: string;
}
export type PlateIndicatorValueFormatter = (
  number: number, config?: PlateIndicatorValueFormatterConfig
) => string

export const ValueFormatterMap = {
  [PlateIndicatorValueType.Number]: (num: number) => num?.toLocaleString() ?? 0,
  [PlateIndicatorValueType.PlusNumber]: (num: number) => `+${num.toLocaleString()}`,
  [PlateIndicatorValueType.Percent]: (num: number) => `${num.toString()}%`,
  [PlateIndicatorValueType.PlusPercent]: (num: number) => `+${num.toString()}%`,
  [PlateIndicatorValueType.MinusPercent]: (num: number) => `-${num.toString()}%`,
  [PlateIndicatorValueType.Money]: (num: number) => formatMoney(num, 'RUB', I_18N_CONFIG.locale),
  [PlateIndicatorValueType.MoneyShort]: (num: number) => num.toString(),
} as {[key in PlateIndicatorValueType]: PlateIndicatorValueFormatter};

export const getValueFormatter = (
  type: PlateIndicatorValueType,
) => ValueFormatterMap[type] || ((v) => v);

export type PlateIndicatorValueConfig = {
  type: PlateIndicatorValueType;
  formatterConfig?: Record<any, any>;
}

export enum PlateIndicatorSimpleVariants {
  indicator = 'indicator',
  smallIndicator = 'smallIndicator',
  borderedIndicator = 'borderedIndicator'
}

export type PlateChartOptionsSeries<T> = SeriesOption & { variant?: T }

export type PlateChartOptions<
  T extends ChartLineSeriesVariant|ChartPolarSeriesVariant|any = any
  > = Omit<EChartsOption, 'series' | 'dataset' >
  & { series: PlateChartOptionsSeries<T>[]; dataset: DatasetOption }

export type PlateIndicatorRenderProps = {
  bodyClass?: string|string[];
}

export type PlateIndicatorSimple = {
  type: PlateIndicatorType.Simple;
  variant: PlateIndicatorSimpleVariants;
  title?: string;
  caption?: string;
  value: number;
  valueConfig: PlateIndicatorValueConfig;
} & PlateIndicatorRenderProps

export enum PlateIndicatorFractionVariant {
  Ring = 'ring'
}

export type PlateIndicatorFraction = {
  type: PlateIndicatorType.Fraction;
  variant: PlateIndicatorFractionVariant;
  title: string;
  value: number;
  entireValue: number;
  valueConfig: PlateIndicatorValueConfig;
  showPercent: boolean;
  showValue: boolean;
} & PlateIndicatorRenderProps

export type PlateIndicatorWithPrev = {
  type: PlateIndicatorType.WithPrevValue;
  variant: PlateIndicatorSimpleVariants;
  title: string;
  value: number;
  valueConfig: PlateIndicatorValueConfig;
  prevValue: number;
} & PlateIndicatorRenderProps

export type PlateIndicatorMulti = {
  type: PlateIndicatorType.Multi;
  title: string;
  gridConfig: GridConfig.GridConfig;
  childrenGridConfigs: GridConfig.TileGridConfig[];
  children: PlateIndicator[];
} & PlateIndicatorRenderProps

export type PlateIndicatorPie = {
  type: PlateIndicatorType.Pie;
  variant: ChartPieVariant;
  title: string;
  chartOptions: PlateChartOptions<null>;
  valueFormatter: (v: number) => string;
} & PlateIndicatorRenderProps

export type PlateIndicatorBar = {
  type: PlateIndicatorType.Bar;
  variant: ChartBarVariant;
  title: string;
  chartOptions: PlateChartOptions<null>;
} & PlateIndicatorRenderProps

export type PlateIndicatorLine = {
  type: PlateIndicatorType.Line;
  variant: ChartLineVariant;
  lineVariantDefault: ChartLineSeriesVariant|ChartPolarSeriesVariant;
  title: string;
  chartOptions: PlateChartOptions<ChartLineSeriesVariant|ChartPolarSeriesVariant>;
} & PlateIndicatorRenderProps

export type PlateIndicator = PlateIndicatorSimple
  | PlateIndicatorWithPrev
  | PlateIndicatorList
  | PlateIndicatorPie
  | PlateIndicatorBar
  | PlateIndicatorLine
  | PlateIndicatorMulti
  | PlateIndicatorFraction

export type IPlateIndicatorSimpleProps = {
  type: PlateIndicatorType.Simple;
  variant: PlateIndicatorSimpleVariants;
  title: string;
  value: string;
  caption: string;
} & PlateIndicatorRenderProps

export type IPlateIndicatorFractionProps = {
  type: PlateIndicatorType.Fraction;
  variant: PlateIndicatorFractionVariant;
  title: string;
  chartOptions: Omit<PlateChartOptions, 'dataset'>;
} & PlateIndicatorRenderProps

export type IIndicatorCompareIcon = 'chart-up' | 'chart-down'
export type IIndicatorIconProps = {
  caption: string;
  componentClass: string;
  icon: IIndicatorCompareIcon;
}

export type IPlateIndicatorWithPrevValueProps = {
  type: PlateIndicatorType.WithPrevValue;
  title: string;
  value: string;
  prevValue: string;
  indicator: IIndicatorIconProps;
} & PlateIndicatorRenderProps

export type IPlateIndicatorMultiProps = {
  type: PlateIndicatorType.Multi;
  title: string;
  gridConfig: string;
  childrenGridConfigs: string[];
  children: IPlateIndicatorProps[];
} & PlateIndicatorRenderProps

export type IPlateIndicatorBarProps = {
  type: PlateIndicatorType.Bar;
  title: string;
  chartOptions: EChartsOption;
} & PlateIndicatorRenderProps

export type IPlateIndicatorLineProps = {
  type: PlateIndicatorType.Line;
  variant: ChartLineVariant;
  title: string;
  chartOptions: PlateChartOptions<ChartLineSeriesVariant|ChartPolarSeriesVariant>;
} & PlateIndicatorRenderProps

export type IPlateIndicatorPieProps = {
  type: PlateIndicatorType.Pie;
  title: string;
  chartOptions: EChartsOption;
} & PlateIndicatorRenderProps

export type IPlateIndicatorProps = IPlateIndicatorSimpleProps
  | IPlateIndicatorWithPrevValueProps
  | IPlateIndicatorMultiProps
  | IPlateIndicatorBarProps
  | IPlateIndicatorLineProps
  | IPlateIndicatorPieProps
  | IPlateIndicatorListProps
  | IPlateIndicatorFractionProps

export const getIndicatorProps = (percentageChange: number): IIndicatorIconProps => {
  if (percentageChange >= 0) {
    return {
      icon: 'chart-up',
      componentClass: 'plate-indicator-indicator--increase',
      caption: `+${percentageChange}%`,
    };
  }
  return {
    icon: 'chart-down',
    componentClass: 'plate-indicator-indicator--decrease',
    caption: `${percentageChange}%`,
  };
};

export const mapMultiIndicatorToProps = (i: PlateIndicatorMulti): IPlateIndicatorMultiProps => ({
  type: PlateIndicatorType.Multi,
  title: i.title,
  gridConfig: GridConfig.mapGridConfigToCss(i.gridConfig),
  childrenGridConfigs: i.childrenGridConfigs.map((ch) => GridConfig.mapTileGridConfigToCss(ch)),
  children: i.children.map((ch) => mapIndicatorToProps(ch)),
});

export const mapSimpleIndicatorToProps = (indicator: PlateIndicatorSimple) => {
  const formatter = ValueFormatterMap[indicator.valueConfig.type];
  return {
    type: indicator.type,
    variant: indicator.variant,
    title: indicator.title,
    caption: indicator.caption,
    bodyClass: indicator.bodyClass,
    value: formatter(indicator.value, indicator.valueConfig?.formatterConfig),
  } as IPlateIndicatorSimpleProps;
};

export const mapWithPrevIndicatorToProps = (indicator: PlateIndicatorWithPrev) => {
  const formatter = ValueFormatterMap[indicator.valueConfig.type];
  return {
    type: indicator.type,
    title: indicator.title,
    value: formatter(indicator.value, indicator.valueConfig?.formatterConfig),
    prevValue: formatter(indicator.prevValue, indicator.valueConfig?.formatterConfig),
    indicator: getIndicatorProps(getPercantageChange(indicator.value, indicator.prevValue)),
  } as IPlateIndicatorWithPrevValueProps;
};

const mapPlateIndicatorPieToProps = (indicator: PlateIndicatorPie) => {
  // @ts-ignore
  const rawConfig = cloneDeep(getPieChartByVariant(indicator.variant));
  rawConfig.dataset.source = indicator.chartOptions.dataset.source;
  // @ts-ignore
  if (indicator.variant === ChartPieVariant.Ring) {
    // @ts-ignore
    const sum = indicator.chartOptions.dataset.source.reduce((acc, [_, __, value, ___], i) => {
      if (i === 0) return acc;
      return acc + Number(value);
    }, 0);
    rawConfig.title.text = 'Всего';
    rawConfig.title.subtext = indicator.valueFormatter(sum);
  }
  rawConfig.series = mergeDeep({}, rawConfig.series, indicator.chartOptions.series[0]);
  // @ts-ignore
  rawConfig.title = mergeDeep(rawConfig.title, indicator.chartOptions.title);

  return {
    title: indicator.title,
    type: PlateIndicatorType.Pie,
    chartOptions: rawConfig,
  } as unknown as IPlateIndicatorPieProps;
};

const mapPlateIndicatorLineToProps = (indicator: PlateIndicatorLine) => {
  const seriesPrepared = indicator.chartOptions.series.map((series, i) => {
    const lineVariant = series.variant || indicator.lineVariantDefault;
    const lineConstructor = (indicator.variant === ChartLineVariant.Linear)
      ? getLineChartSeriesConstructor(lineVariant as ChartLineSeriesVariant)
      : getPolarChartSeriesConstructor(lineVariant as ChartPolarSeriesVariant);
    return mergeDeep(
      {},
      (lineConstructor({ ...series, color: series.color || CHART_COLORS[i] })),
      series,
    );
  });

  return {
    title: indicator.title,
    type: PlateIndicatorType.Line,
    chartOptions: mergeDeep(
      {},
      getLinearChartByVariant(indicator.variant),
      indicator.chartOptions,
      { series: seriesPrepared },
    ),
  } as IPlateIndicatorLineProps;
};

export const mapFractionIndicatorToProps = (
  indicator: PlateIndicatorFraction,
): IPlateIndicatorFractionProps => {
  const formatter = getValueFormatter(indicator.valueConfig.type);
  const formattedValue = formatter(indicator.valueConfig.type === PlateIndicatorValueType.Percent
    ? Math.round((indicator.value / indicator.entireValue) * 100)
    : indicator.value);
  const percentValue = getValueFormatter(
    PlateIndicatorValueType.Percent,
  )(Math.round((indicator.value / indicator.entireValue) * 100));

  let formattedValueFinal = '';

  if (indicator.showValue) {
    formattedValueFinal += `${formatter(indicator.value)}/${formatter(indicator.entireValue)} `;
  }
  if (indicator.showPercent) {
    formattedValueFinal += percentValue;
  }

  return {
    title: indicator.title,
    type: indicator.type,
    variant: indicator.variant,
    chartOptions: getSimpleRingIndicator({
      value: indicator.value,
      entireValue: indicator.entireValue,
      formattedValue: formattedValueFinal || formattedValue,
    }),
  };
};

export const mapListIndicatorToProps = (
  indicator: PlateIndicatorList,
): IPlateIndicatorListProps => ({
  title: indicator.title,
  type: indicator.type,
  variant: indicator.variant,
  showRaw: indicator.showRaw,
  list: indicator.list.map((item) => ({
    ...item,
    value: {
      valueRaw: item.value.value,
      color: item.value.color,
      value: !indicator.showRaw ? getValueFormatter(
        item.value.valueConfig.type,
      )(item.value.value, item.value.valueConfig.formatterConfig) : '',
    },
  })),
});

export const mapIndicatorToProps = (plateIndicator: PlateIndicator): IPlateIndicatorProps => {
  switch (plateIndicator.type) {
    case PlateIndicatorType.Simple:
      return mapSimpleIndicatorToProps(plateIndicator);
    case PlateIndicatorType.Fraction:
      return mapFractionIndicatorToProps(plateIndicator);
    case PlateIndicatorType.WithPrevValue:
      return mapWithPrevIndicatorToProps(plateIndicator);
    case PlateIndicatorType.Pie:
      return mapPlateIndicatorPieToProps(plateIndicator);
    case PlateIndicatorType.Line:
      return mapPlateIndicatorLineToProps(plateIndicator);
    case PlateIndicatorType.List:
      return mapListIndicatorToProps(plateIndicator);
    case PlateIndicatorType.Multi:
      return mapMultiIndicatorToProps(plateIndicator);
    default:
      return { title: 'no indicator' } as unknown as IPlateIndicatorProps;
  }
};

export const renderPlateIndicator = (indicator: PlateIndicator) => renderPlateIndicatorFromProps(
  ((indicator) => {
    const renderProps = mapIndicatorToProps(indicator);
    return renderProps;
  })(indicator),
  true,
);
