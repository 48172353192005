import { getApiRequest } from '@core/service/serviceConstructor';
import * as E from 'fp-ts/Either';

const sendPulseRequest = getApiRequest({
  baseUrl: 'https://api.sendpulse.com/',
  interceptor: {
    request: [
      async (config) => {
        if (['authorization'].some(
          (header) => !config.headers[header],
        )) {
          throw new Error('no_token');
        }
        return config;
      },
    ],
  },
});

const sendPulseAuthRequest = getApiRequest({
  baseUrl: 'https://api.sendpulse.com/',
});

const getAccessToken = (
  { client_id, client_secret }: { client_id: string; client_secret: string },
) => sendPulseAuthRequest<{ access_token: string }, { error: 'invalid_client' }>({
  url: '/oauth/access_token',
  method: 'POST',
  data: {
    grant_type: 'client_credentials',
    client_id,
    client_secret,
  },
})();

export type SendPulseSender = {
  email: string; name: string; status: 'Active'|'Rejected'|'On moderation';
}

export const SendPulseService = {
  fetchSenders: async ({ client_id, client_secret }: { client_id: string; client_secret: string }) => {
    const authResponse = await getAccessToken({ client_id, client_secret });
    if (E.isLeft(authResponse)) {
      return authResponse;
    }
    return sendPulseRequest<SendPulseSender[]>({
      url: 'senders',
      method: 'GET',
      headers: {
        authorization: `Bearer ${authResponse.right.access_token}`,
      },
    })();
  },
};
