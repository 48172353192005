import { computed, ref, Ref } from 'vue';
import { ITab } from '@/components/tabs/useTabs';
import { CourtTypeTabKey } from '@/components/dialog/dialogs/debtor/tabs/courts/types';
import { useDialogRouteParam } from '@/hooks/useDialog';
import { isOrganization } from '@/components/dialog/dialogs/debtor/isOrganization';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';

// физ. лицо
const individualTabs: Array<ITab<CourtTypeTabKey>> = [
  {
    key: 'magistrate',
    label: '',
  },
  {
    key: 'regional',
    label: '',
  },
];

// юр. лицо
const legalEntityTabs = [
  {
    key: 'arbitration',
    label: 'Участок арбитражного суда',
  },
];

export function isMagistrate(activeTab: Ref<CourtTypeTabKey>) {
  return activeTab.value?.includes('magistrate');
}

export function isContractual(activeTab?: CourtTypeTabKey) {
  return activeTab?.includes('contractual');
}

export const useTabs = () => {
  const { debtor } = useInjectDebtorDialog();
  const { t } = useLocalI18n('debtor.courts');

  const tabKeys = () => (isOrganization(debtor.value) ? legalEntityTabs : individualTabs);
  const defaultTab = tabKeys()[0];
  const tabs = computed<Array<ITab<CourtTypeTabKey>>>(() => ((tabKeys() as Array<ITab<CourtTypeTabKey> | boolean>)
    .filter(Boolean) as Array<ITab<CourtTypeTabKey>>)
    .map((tab) => ({
      ...tab,
      label: tab.label || t(`${tab.key}.tab`),
      id: `modal_debtor_courts_tab_${tab.key}_tab`,
    })));

  const [activeTab] = useDialogRouteParam<CourtTypeTabKey>(
    'court-tab',
    <CourtTypeTabKey>defaultTab.key,
    ref(true),
  );

  return {
    tabs,
    activeTab,
    isContractual,
  };
};
