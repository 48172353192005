import {
  computed,
  unref,
  SetupContext,
  WritableComputedRef,
  Ref,
} from 'vue';

export const useLocalValue = <T, S extends keyof T>(
  props: T,
  field: S,
  emit: SetupContext['emit'] | any,
  serialize = false,
  getter: ((value: T[S]) => T[S]) = (value) => value,
): WritableComputedRef<T[S] extends Ref<infer Value> ? Value : T[S]> => computed<
  T[S] extends Ref<infer Value> ? Value : T[S]
  >({
    // @ts-ignore
    get: () => getter(unref(props[field])),
    set: (newValue: T[S]) => {
      emit(`update:${String(field)}`, serialize ? JSON.parse(JSON.stringify(newValue)) : newValue);
    },
  });
