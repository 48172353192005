import { AutoTabKey, JudicialAutomationModel } from '@/components/automation/judicial/types';
import { Ref } from 'vue';
import {
  defaultCheckboxOptions,
  fieldsUniter,
} from '@/components/automation/judicial/tabs/utils';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { debtorCompositeUpdateOptions } from '@/hooks/useDebtorCompositeUpdate';

export function getEnrichmentFields(
  model: Ref<JudicialAutomationModel>,
  rootT: (s: string) => string,
) {
  return fieldsUniter(AutoTabKey.enrichment, [
    {
      key: 'auto_update_debtor_data',
      type: ActiveFormFieldType.checkbox,
      options: defaultCheckboxOptions(model, rootT),
      onUpdateModelValue: (model: JudicialAutomationModel, value: boolean) => {
        if (!value) {
          model.auto_update_debtor_data_config = [];
          model.auto_update_debtor_data_period = null;
        } else {
          model.auto_update_debtor_data_period = {
            minute: '0',
            hour: '10',
            day_of_week: '*',
            day_of_month: '*',
            month_of_year: '*',
          };
        }
      },
    },
    {
      key: 'auto_update_debtor_data_config',
      type: ActiveFormFieldType.select,
      options: {
        state: ['primary'],
        isDisabled: !model.value.auto_update_debtor_data,
        options: debtorCompositeUpdateOptions.filter(
          (value) => value.value !== 'update_encr_address',
        ),
      },
    },
    {
      key: 'auto_update_debtor_data_period',
      type: ActiveFormFieldType.schedule,
      options: {
        state: ['primary'],
        isDisabled: !model.value.auto_update_debtor_data,
      },
    },
    {
      key: 'enrichment_by_address',
      type: ActiveFormFieldType.checkbox,
      options: {
        state: ['primary'],
      },
    },
    {
      key: 'enrichment_by_address_estates',
      type: ActiveFormFieldType.checkbox,
      options: {
        state: ['primary'],
      },
    },
    {
      key: 'judicial_auto_update_debtor_data_arguments',
      type: ActiveFormFieldType.checkbox,
      options: {
        state: ['primary'],
      },
    },
  ]);
}
