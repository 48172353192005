import { IToastLevel, IToastProgressbar, useToast } from '@/hooks/useToast';
import { useUnsubs } from '@/hooks/useUnsubs';
import { useUser } from '@/hooks/useUser';
import { useCompanySettingsSecure } from '@/hooks/useCompanySettingsSecure';
import { Ref, ref } from 'vue';
import { useMosRuSocket } from '@/pages/exchange/integration/dialogs/integrationGosuslugi/useMosRuSocket';
import { ExtraInputModel } from '@/pages/exchange/integration/dialogs/integrationGosuslugi/types';

const AUTHED_KEY = 'mos_ru_authed';
const MAX_RETRIES = 5;

export const useMosRuAuth = (
  companyId: Ref<number>,
  isLoading: Ref<boolean>,
  esiaId: Ref<string>,
  extraInputModel: Ref<ExtraInputModel>,
) => {
  const mosRuAuthed = ref<boolean>(false);
  const { sub } = useUnsubs();
  const { showToast, showPureDangerToast } = useToast();
  const { user } = useUser();
  const {
    saveUserSetting,
    fetchUserSettings,
  } = useCompanySettingsSecure();

  const { waitMosRuAuth } = useMosRuSocket();

  function commonParams() {
    return {
      company_id: companyId.value,
      owner: user.value?.id as number,
    };
  }

  async function checkAuth() {
    const result = await fetchUserSettings({
      key: AUTHED_KEY,
      ...commonParams(),
    });
    try {
      mosRuAuthed.value = result.response[0].data.value;
    } catch (e) {
      // ignore
    }
  }

  setTimeout(checkAuth);

  const mosRuProgressbars = ref<[IToastProgressbar]>([
    {
      key: 'progress',
      label: '',
      message: 'Прогресс',
      showMessageInsteadOfLabel: true,
      max: 0,
      current: 0,
    },
  ]);

  const authMosRu = async () => {
    const id = esiaId.value;
    console.log('ESIA id', id);
    isLoading.value = true;

    const closeToast = await showToast({
      label: 'pureLabel',
      params: {
        label: 'Авторизация на mos.ru',
      },
      level: IToastLevel.info,
      duration: null,
      progressbars: mosRuProgressbars,
    });
    sub(closeToast);

    const mosRuSetting = {
      key: 'mos_ru',
      ...commonParams(),
      is_readable: false,
      source: {
        esia_id: id,
      },
    };

    let tryNumber = 0;
    return tryAuth();

    async function tryAuth(): Promise<boolean | null> {
      tryNumber += 1;
      if (tryNumber >= MAX_RETRIES) {
        return finish(false);
      }
      console.log('try mos ru auth', { tryNumber });

      const mosRuResponse = await saveUserSetting(mosRuSetting);
      if (!mosRuResponse.status) {
        console.error('Ошибка сохранения настроек mos_ru');
        await showToast({
          label: 'Ошибка сохранения настроек mos.ru. Попробуйте обновить страницу',
          level: IToastLevel.danger,
        });
        return finish(null);
      }
      console.log('mosru request', mosRuSetting, mosRuResponse);

      const socketResult = await waitMosRuAuth(
        mosRuResponse.response.id,
        mosRuProgressbars,
        extraInputModel,
        closeToast,
        isLoading,
      );

      if (socketResult) {
        await saveUserSetting({
          key: AUTHED_KEY,
          ...commonParams(),
          source: { value: true },
          is_readable: true,
        });
        await checkAuth();
        return finish(true);
      }

      if (socketResult === false) { // === timeout
        return tryAuth();
      }

      return finish(null);
    }
  };

  async function finish(result: boolean | null) {
    if (result === false) {
      console.error('mos ru auth: max retries');
      await showPureDangerToast({
        params: { message: 'Превышено количество попыток авторизации.' },
      });
      await unAuthMosRu();
    } else {
      console.warn('finish mos ru auth', result);
    }
    isLoading.value = false;
    return result;
  }

  async function unAuthMosRu() {
    await saveUserSetting({
      key: AUTHED_KEY,
      ...commonParams(),
      source: { value: false },
      is_readable: true,
    });
    await checkAuth();
  }

  return {
    authMosRu,
    unAuthMosRu,
    mosRuAuthed,
  };
};
