import { PlateIndicatorType } from '@/components/plate/plateIndicator';
import {
  PlateIndicatorRenderProps,
  PlateIndicatorValueConfig,
} from '@/components/plate/plateIndicator/types';

export enum PlateIndicatorListVariant {
  Plates = 'plates',
  Slider = 'slider',
  SliderSpeedometer = 'slider-speedometer',
  List = 'list',
}

export type PlateIndicatorListItem = {
  title: string;
  icon?: {
    color: string;
  };
  value: {
    value: number;
    valueConfig: PlateIndicatorValueConfig;
    color?: string;
  };
}

export type PlateIndicatorListItemFormatted = {
  title: string;
  icon?: {
    color: string;
  };
  value: {
    valueRaw: number;
    value: string;
  };
}

export type PlateIndicatorList = {
  type: PlateIndicatorType.List;
  title: string;
  variant: PlateIndicatorListVariant;
  list: PlateIndicatorListItem[];
  showRaw: boolean;
} & PlateIndicatorRenderProps

export type IPlateIndicatorListProps = {
  type: PlateIndicatorType.List;
  title: string;
  variant: PlateIndicatorListVariant;
  list: PlateIndicatorListItemFormatted[];
  showRaw: boolean;
} & PlateIndicatorRenderProps
