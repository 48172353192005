import * as E from 'fp-ts/Either';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import { getApiRequest, isServerError } from './serviceConstructor';
import { apiUrl, isDev } from '@urrobot/web/src/utils/env';
import {
  isAuthRequiredError,
  isInvalidTokenError,
  isTokenNotValidAuthError,
} from './authService';
import { resolveStore } from '../utils/resolveStore';
import {
  ApiRequest,
  apiCommands,
  ListingResponse,
} from '@urrobot/web/src/store/modules/api';
import { pipe, flow } from 'fp-ts/function';
import { IToast, IToastLevel } from '@urrobot/web/src/hooks/useToast';
import { ApiResponse2, ApiResponseError, ApiResponseSuccess } from '@urrobot/web/src/service/api';
import { StoreJwtToken } from '@urrobot/web/src/hooks/useUser';
import { apiCommands as reportingApiCommands } from '@/service/api/reporting/api';
import { personalAccounts } from '@/pages/debtors/_module/mapDebtorsQueryModelToRequestFilters';

let customApiUrl:string|null = null;
let companyId: number|null = null;

resolveStore().then((store) => {
  store.watch((state) => state.user.customApiUrl, (url) => {
    customApiUrl = url;
  }, { immediate: true });
});

export const commonApiServiceRequest = getApiRequest({
  baseUrl: () => customApiUrl || apiUrl,
  interceptor: {
    request: [
      async (req) => {
        const store = await resolveStore();
        if (['/api/debtors-data/', '/api/debtors-data/total/'].includes(req.url)) {
          if (!companyId) {
            companyId = req.params?.company_id;
          }

          if (companyId === store.getters['companies/defaultCompanyId']) {
            if (personalAccounts.value[0] !== null && personalAccounts.value?.length) {
              req.headers['personal-account'] = encodeURIComponent(
                JSON.stringify(personalAccounts.value),
              );
              if (req.params?.offset >= personalAccounts.value.length) {
                delete req.headers['personal-account'];
              }
            }
          } else {
            delete req.headers['personal-account'];
            personalAccounts.value = [null];
            companyId = null;
          }

          delete req.params?.personal_account;

          if (req.headers['Content-Type'] === 'multipart/form-data') {
            delete req.headers['Content-Type'];
            req.asFormData = true;
          }
        }
        return req;
      },
      async (req) => {
        const store = await resolveStore();
        if (['/auth', '/restore', '/register', '/verificate'].some((v) => req.url.includes(v))) {
          return req;
        }
        const tokenData = await (store.dispatch(
          'user/getJwtTokens',
        ) as Promise<E.Either<O.Option<any>, StoreJwtToken>>);

        if (E.isLeft(tokenData)) {
          throw new Error('no_token');
        }
        req.headers.Authorization = `Bearer ${tokenData.right.access.value}`;
        return req;
      },
    ],
    responseError: [
      async (resp) => {
        const store = await resolveStore();
        if (isInvalidTokenError(resp.bodyJSON)) {
          return E.left({ bodyJSON: { code: 'jwt_auth_error' } });
        }

        if (isTokenNotValidAuthError(resp.bodyJSON)) {
          const tokensData = await (store.dispatch(
            'user/getJwtTokens', undefined,
          ) as Promise<E.Either<O.Option<any>, StoreJwtToken>>);

          if (E.isLeft(tokensData)) {
            return E.left({ bodyJSON: { code: 'jwt_auth_error' } });
          }

          return commonApiServiceRequest(resp.requestSource)();
        }

        if (isAuthRequiredError(resp.bodyJSON)) {
          return E.left({ bodyJSON: { code: 'jwt_auth_error' } });
        }
        return E.left(resp);
      },
    ],
  },
});

export const commonApiRequest = flow(
  commonApiServiceRequest,
  TE.orElse(
    (e) => pipe(
      TE.leftTask(async () => {
        if (!e) return O.none;
        if (isServerError(e)) {
          const store = await resolveStore();
          store.dispatch(
            'layout/showToast',
            {
              level: IToastLevel.danger,
              label: 'pureLabel',
              params: { label: 'Ошибка сервера' },
            },
          );
          return O.none;
        } if ((e instanceof Error && e.message === 'no_token')
            || (e as any)?.code === 'jwt_auth_error'
        ) {
          const store = await resolveStore();
          const NO_AUTH_ID = 'no_auth_id';
          if (!store.getters['layout/toasts'].find(
            (toast: IToast) => toast.id === NO_AUTH_ID,
          )) {
            store.dispatch(
              'layout/showToast',
              {
                id: NO_AUTH_ID,
                level: IToastLevel.danger,
                label: 'pureLabel',
                params: { label: 'Ошибка авторизации' },
                duration: 4000,
              },
            );
          }
        }
        return O.some(e);
      }),
    ),
  ),
);

export const commonApiCommand = async <Suc, Err extends any = any>(request: ApiRequest) => {
  // @ts-ignore
  const command = apiCommands[request.command] || reportingApiCommands[request.command];

  return commonApiRequest<Suc, Err>({
    headers: request.headers,
    url: command.url,
    method: command.method.toUpperCase(),
    data: request.data,
    params: request.params,
  })();
};

export const commonListingRequest = async <Suc, Err extends any = any>(request: ApiRequest): Promise<ApiResponse2<ListingResponse<Suc>>> => {
  // @ts-ignore
  const command = apiCommands[request.command] || reportingApiCommands[request.command];

  const response = await commonApiRequest<ListingResponse<Suc>, Err>({
    headers: request.headers,
    url: command.url,
    method: command.method.toUpperCase(),
    data: request.data,
    params: request.params,
    asFormData: request.headers?.['Content-Type'] === 'multipart/form-data',
    signal: request.signal,
  })();

  if (response._tag === 'Right') {
    return { response: response.right };
  }

  const errorResponse = O.isSome(response.left) ? response.left.value : null;
  return { error: errorResponse };
};

export const commonLegacyApiRequest = async <Suc, Err extends any = any>(request: ApiRequest) => {
  // @ts-ignore
  const command = apiCommands[request.command] || reportingApiCommands[request.command];
  const response = await commonApiRequest<Suc, Err>({
    headers: request.headers,
    url: command.url,
    method: command.method.toUpperCase(),
    data: request.data,
    params: request.params,
    asFormData: request.headers?.['Content-Type'] === 'multipart/form-data',
    signal: request.signal,
  })();

  if (response._tag === 'Right') {
    return { status: true, response: response.right } as ApiResponseSuccess<Suc>;
  }

  const errorResponse = O.isSome(response.left) ? response.left.value : null;
  // @ts-ignore
  const _statusCode = errorResponse?.__statusCode;
  return {
    status: false,
    response: errorResponse,
    statusCode: _statusCode,
  } as ApiResponseError;
};

const fileService = getApiRequest({
  baseUrl: isDev ? (process.env.VUE_APP_LOCAL_API_URL as string) : apiUrl,
});

export const getAuthProtectedFile1 = async (
  url: string,
  name: string,
) => {
  const store = await resolveStore();
  const accessToken = (
    await store.dispatch('user/getJwtTokens')
  ) as E.Either<any, { access: { value: string } }>;
  if (E.isLeft(accessToken)) {
    throw new Error(String(accessToken.left));
  }
  const resp = await fileService<Blob>({
    url: url.replace(/https?:\/\/?([^/]+)\/?/, ''),
    method: 'GET',
    responseAsFile: true,
    params: {
      access_token: accessToken.right.access.value,
    },
  })();
  if (E.isLeft(resp)) {
    store.dispatch('layout/showToast', {
      label: 'pureLabel',
      params: {
        label: `Ошибка загрузки файла: ${url}`,
      },
      level: IToastLevel.danger,
    });
    return E.left(null);
  }
  const file = new File([resp.right], name || 'no_name', { type: resp.right.type });
  return E.right(file);
};

export const getAuthProtectedFile = (
  url: string,
  name?: string,
) => flow(
  (url: string) => commonApiRequest<Blob>({
    url: url.replace(/.+urrobot\.net/, ''),
    method: 'GET',
    responseAsFile: true,
  }),
  TE.chain(
    (r) => {
      if (r instanceof Blob) {
        const file = new File([r], name || 'no_name', { type: r.type });
        return TE.right(file);
      }
      return TE.left(
        O.some(
          new Error(`GetAuthProtectedFileError: response is not a blob, url: ${url}`),
        ),
      );
    },
  ),
  TE.orElse((e) => TE.leftTask(async () => {
    const store = await resolveStore();
    console.error(`Fetch file error: ${url}`);
    store.dispatch('layout/showToast', {
      label: 'pureLabel',
      params: {
        label: `Ошибка загрузки файла: ${url}`,
      },
    });
    return e;
  })),
)(url)() as Promise<E.Either<unknown, File>>;
