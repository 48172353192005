import { useStore } from 'vuex';
import { Company } from '@/hooks/useCompanies';
import {
  ApiCommand,
  ApiResponse,
  ListingRequestSource,
  ListingResponse,
} from '@/store/modules/api';
import { ProductionType } from '@/hooks/useConstructor';
import { useApi } from '@/hooks/useApi';
import { formatListingResponse } from '@/components/activeTable/useActiveTable';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { Employee } from '@/hooks/useEmployees';

export enum AccountDocumentClass {
  default = 'default',
  attorney = 'attorney',
  attorney_sig = 'attorney_sig',
  document_sig = 'document_sig',
  ifns_vypis = 'ifns_vypis',
  image = '21',
  document_of_education = 'document_of_education',
  document_of_marriage_or_change_of_name = 'document_of_marriage_or_change_of_name',
}

export type AccountDocument = {
  id: number | null;
  name: string;
  file: string;
  description: string;
  yandex_url: string;
  can_be_attached: boolean;
  klass: AccountDocumentClass;
  signer: string;
  signer_name: string;
  company: Company['id'];
  readable_path: boolean;
  production_type: ProductionType;
  mark?: number;
  sig: number|null; // ID привязки документа-подписи
  document: number | null;
  users: Array<Employee['id']>; // id у employee и user одинаковые
  debtor_type: string|null;
}

export type FetchAccountDocumentsModel = ListingRequestSource<AccountDocument>;

export type FetchAccountDocumentsResponse = ListingResponse<AccountDocument>;

export type CreateAccountDocumentModel = {
  company_id: Company['id'];
  payload: AccountDocument;
};
export type CreateAccountDocumentResponse = AccountDocument;

export type UpdateAccountDocumentModel = {
  id: AccountDocument['id'];
  company_id: Company['id'];
  payload: AccountDocument;
};
export type UpdateAccountDocumentResponse = AccountDocument;

export type RemoveAccountDocumentModel = {
  company_id: Company['id'];
  id: AccountDocument['id'];
};
export type RemoveAccountDocumentResponse = null;

export const useAccountDocuments = () => {
  const store = useStore();
  const {
    companyId,
  } = useProtectedDefaultCompany();

  const fetchAccountDocuments = async (
    request?: FetchAccountDocumentsModel,
  ): Promise<ApiResponse<FetchAccountDocumentsResponse>> => {
    const { status, response } = await store.dispatch('accountDocuments/fetchAccountDocuments', request);

    return { status, response };
  };

  const createAccountDocument = async (
    request: CreateAccountDocumentModel,
  ): Promise<ApiResponse<CreateAccountDocumentResponse>> => {
    const { status, response } = await store.dispatch('accountDocuments/createAccountDocument', request);

    return { status, response };
  };

  const updateAccountDocument = async (
    request: UpdateAccountDocumentModel,
  ): Promise<ApiResponse<UpdateAccountDocumentResponse>> => {
    const { status, response } = await store.dispatch('accountDocuments/updateAccountDocument', request);

    return { status, response };
  };

  const removeAccountDocument = async (
    request: RemoveAccountDocumentModel,
  ): Promise<ApiResponse<RemoveAccountDocumentResponse>> => {
    const { status, response } = await store.dispatch('accountDocuments/removeAccountDocument', request);

    return { status, response };
  };

  return {
    fetchAccountDocuments,
    createAccountDocument,
    updateAccountDocument,
    removeAccountDocument,
  };
};

export const getEmptyAccountDocument = (
  companyId: Company['id'],
  documentClass: AccountDocumentClass = AccountDocumentClass.default,
  signerId: number | null = null,
): AccountDocument => ({
  id: null,
  name: '',
  file: '',
  description: '',
  yandex_url: '',
  can_be_attached: documentClass !== AccountDocumentClass.attorney
    && documentClass !== AccountDocumentClass.attorney_sig
    && documentClass !== AccountDocumentClass.document_sig,
  klass: documentClass,
  signer: '',
  signer_name: '',
  company: companyId,
  readable_path: false,
  production_type: ProductionType.judicial,
  sig: null,
  document: signerId,
  users: [],
  debtor_type: null,
});
