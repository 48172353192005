import { onBeforeUnmount, Ref } from 'vue';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { getDebtorsModuleTableModuleKey } from '@/hooks/useUserPrefs';
import { ProductionType } from '@/hooks/useConstructor';
import { useDebtorsModuleUserPrefs } from '@/pages/debtors/_module/useModuleUserPrefs';
import { ActiveTableColumn } from '@/components/activeTable/useActiveTable';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';

export const useTableSettings = (
  limit: Ref<number|undefined>,
  defaultLimit: Ref<number|undefined>,
  columns: Ref<Array<ActiveTableColumn<any>>>,
  localLimit: Ref<number>,
  tableKey: Ref<string>,
  visibleColumns: Ref<Array<ActiveTableColumn<any>['key']>>,
) => {
  const {
    updateModuleColumns,
  } = useDebtorsModuleUserPrefs();

  const {
    subscribeToSignal,
  } = useSignal();

  const {
    showDialog,
  } = useDialog();

  onBeforeUnmount(
    subscribeToSignal(
      SignalType.tableSettings,
      (payload: {tableKey: string; columns: string[]; limit: number}) => {
        if (payload.tableKey === tableKey.value) {
          const debtorsModuleKey = getDebtorsModuleTableModuleKey(tableKey.value);
          if (!debtorsModuleKey) {
            visibleColumns.value = payload.columns;
            localLimit.value = payload.limit;
          } else {
            const moduleKey = debtorsModuleKey as ProductionType;
            updateModuleColumns({
              module_type: moduleKey,
              debtor_per_page: payload.limit,
              visible_columns: payload.columns,
            });
          }
        }
      },
    ),
  );

  const openTableSettingsDialog = () => showDialog({
    component: IDialogComponent.tableDisplaySettings,
    addInRoute: false,
    payload: {
      limit: limit.value!,
      defaultLimit: defaultLimit.value!,
      visibleColumns: visibleColumns.value || [],
      columns: columns.value?.map(({ key, label, isRequired }) => ({
        key,
        label,
        isRequired,
      })) || [],
      tableKey: tableKey.value,
    },
  });

  return {
    openTableSettingsDialog,
  };
};
