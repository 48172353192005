import { ref, SetupContext } from 'vue';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { BorDialog, Model } from '@/pages/debtors/_module/tableActions/borUpdate/types';

export const useBorUpdateDialog = (props: {
  onSave: BorDialog['onSave'];
  fullPeriod: boolean;
}, { emit }: SetupContext<'close'[]>) => {

  const { companyModuleType } = useProtectedDefaultCompany();
  const model = ref<Model>(props.fullPeriod ? {
    date: new Date(),
  } : {
    date_from: new Date(),
    date_to: new Date(),
  });

  const submit = () => {
    props.onSave(model.value);
    emit('close');
  };

  return {
    model,
    submit,
    companyModuleType,
  };
};
