import {
  ActionType,
  ActiveTableAction,
  ActiveTableColumnFormat,
} from '@/components/activeTable/useActiveTable';
import { useSimpleFetch } from '@/hooks/useSimpleFetch';
import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { Debtor } from '@/hooks/useDebtors';
import { ApiResponse, unwrapListingApiResponse } from '@/service/api';
import { IToastLevel, useToast } from '@/hooks/useToast';
import {
  computed, ComputedRef, onBeforeUnmount, watch,
} from 'vue';
import { useApi } from '@/hooks/useApi';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { SourceErrors, useErrors } from '@/hooks/useErrors';

export type DebtorBankAccount = {
  id: number;
  debtor_id: number;
  credit_istitution_name: string;
  inn: string;
  bic: string;
  address: string;
  account_number: string;
  account_opening_date: Date;
  account_type: string;
  account_balance_amount: string;
  use_for_print: boolean;
  debtor: number;
}

export const useBankAccounts = (debtorId: ComputedRef<Debtor['debtor']['pk'] | undefined>) => {
  const request = useApi();

  const { showToast } = useToast();
  const { showDialog, confirmDialog } = useDialog();
  const { subscribeToSignal, dispatchSignal } = useSignal();

  const { t } = useLocalI18n('debtor.executions');

  const fetchBankAccounts = async () => {
    const response = await request<ListingResponse<DebtorBankAccount>>({
      command: ApiCommand.fetchDebtorBankAccounts,
      params: {
        debtor: debtorId.value,
        limit: 1000,
      },
    }).then(unwrapListingApiResponse).then((r) => {
      if (!r.status) return r;
      return {
        status: true,
        response: r.response.map((el, i) => ({
          index: i + 1,
          ...el,
        })).reduce((acc, ba) => {
          if (!acc[ba.credit_istitution_name]) {
            acc[ba.credit_istitution_name] = [];
          }
          acc[ba.credit_istitution_name].push(ba);
          return acc;
        }, {} as Record<DebtorBankAccount['credit_istitution_name'], DebtorBankAccount[]>),
      };
    });
    if (!response.status) {
      await showToast({
        label: 'pureLabel',
        message: 'pure',
        level: IToastLevel.danger,
        params: {
          label: 'Ошибка загрузки банковских счетов',
          message: response.response?.detail ?? response.response,
        },
      });
      return { status: false, response: null };
    }

    return response;
  };

  const { fetch, data, isLoading } = useSimpleFetch(
    fetchBankAccounts as unknown as () => Promise<ApiResponse<Record<string, DebtorBankAccount[]>>>,
  );

  const dataPrepared = computed(() => {
    if (!data.value || Object.keys(data.value).length === 0) {
      return { test: [] };
    }
    return data.value;
  });

  const flatBankAccounts = computed(() => {
    if (!data.value) return [];
    return Object.values(data.value)
      .reduce((acc, items) => ([...acc, ...items]), [] as DebtorBankAccount[]);
  });

  const selected = computed(() => {
    if (!flatBankAccounts.value) return [];
    return flatBankAccounts.value.filter((el) => el.use_for_print).map(({ id }) => id);
  });

  watch(debtorId, (v) => {
    if (v) {
      fetch();
    }
  }, { immediate: true });

  const onSelectBankAccount = async (newSelection: [number] | []) => {
    const selected = flatBankAccounts.value.find((el) => el.use_for_print);
    const promises = [];
    if (selected) {
      promises.push(
        commonLegacyApiRequest({
          command: ApiCommand.updateDebtorBankAccount,
          params: {
            id: selected.id,
          },
          data: {
            use_for_print: false,
          },
        }),
      );
      selected.use_for_print = false;
    }
    if (newSelection.length) {
      const [id] = newSelection;
      promises.push(
        commonLegacyApiRequest({
          command: ApiCommand.updateDebtorBankAccount,
          params: {
            id,
          },
          data: {
            use_for_print: true,
          },
        }),
      );
      const newSelected = flatBankAccounts.value?.find((el) => el.id === id);
      if (newSelected) {
        newSelected.use_for_print = true;
      }
    }
    const results = await Promise.all(promises);

    if (!newSelection.length) {
      return;
    }
    if (results.some((el) => !el.status)) {
      if (selected) {
        selected.use_for_print = true;
      }
      const [id] = newSelection;
      const newSelected = flatBankAccounts.value?.find((el) => el.id === id);
      if (newSelected) {
        newSelected.use_for_print = false;
      }
    }
  };

  const columns = [
    {
      key: 'index',
      label: '№',
    },
    {
      key: 'credit_istitution_name',
      field: 'credit_istitution_name',
      label: 'Название кредитной организации',
    },
    {
      key: 'inn',
      field: 'inn',
      label: 'ИНН',
    },
    {
      key: 'bic',
      field: 'bic',
      label: 'БИК',
    },
    {
      key: 'address',
      field: 'address',
      label: 'Адрес банка',
    },
    {
      key: 'account_number',
      field: 'account_number',
      label: '№ Счета',
    },
    {
      key: 'account_opening_date',
      field: 'account_opening_date',
      label: 'Дата открытия счета',
      format: ActiveTableColumnFormat.date,
    },
    {
      key: 'account_type',
      field: 'account_type',
      label: 'Вид счета',
    },
    {
      key: 'account_balance_amount',
      field: 'account_balance_amount',
      label: 'Сумма остатка на счету',
      format: ActiveTableColumnFormat.money,
    },
  ];

  const {
    errorsMap,
    clearErrors,
    setErrors,
  } = useErrors<keyof DebtorBankAccount>();

  watch(errorsMap, async (map) => {
    await dispatchSignal(SignalType.modelErrors, { id: SignalType.bankAccModelUpdated, errors: map });
  }, {
    immediate: true,
  });

  let unsubCreateTypeDialog: (() => void) | null = null;
  onBeforeUnmount(() => {
    unsubCreateTypeDialog?.();
  });

  const fetchBankAccount = async (id: number) => {
    const res = await request<DebtorBankAccount>({
      command: ApiCommand.fetchDebtorBankAccount,
      params: { id },
    });

    return res.response;
  };

  const actions = computed(() => ([
    {
      key: 'edit',
      icon: 'pencil',
      types: [ActionType.record],
      id: 'modal_debtor_executions_tab_bank_accounts_tab_edit_action',
      handler: async ({ selectedItems: [id] }: { selectedItems: number[] }) => {
        const record: DebtorBankAccount = await fetchBankAccount(id);
        const fields: Array<ActiveFormField<DebtorBankAccount>> = [
          { key: 'credit_istitution_name' },
          { key: 'address' },
        ].map((field) => ({
          ...field,
          field: field.key,
          options: {
            label: t(`bankRequisites.${field.key}`),
            placeholder: t(`bankRequisites.${field.key}`),
          },
          // @ts-ignore
          defaultValue: record[field.key] ?? '',
          type: ActiveFormFieldType.input,
        }));
        unsubCreateTypeDialog = await showDialog({
          key: 'edit_bank_account',
          title: 'Изменить данные банковского счета должника',
          component: IDialogComponent.model,
          addInRoute: false,
          ionDialogClassList: ['debtorAddExecutionModal'],
          payload: {
            isEditable: true,
            signal: SignalType.bankAccModelUpdated,
            fields,
            record,
          },
        });
      },
    },
    {
      key: 'remove',
      icon: 'trash',
      label: 'Удалить',
      types: [ActionType.record],
      id: 'modal_debtor_executions_tab_bank_accounts_tab_remove_action',
      async handler({ selectedItems: [id] }: { selectedItems: number[] }) {
        const confirmResult = await confirmDialog({
          key: 'remove_bank_account',
          title: 'Подтвердите действие',
          message: 'Удалить данные банковского счета должника?',
        });

        confirmResult.closeDialog();

        if (!confirmResult.result) {
          return;
        }

        const response = await commonLegacyApiRequest<DebtorBankAccount['id']>({
          command: ApiCommand.deleteDebtorBankAccount,
          params: {
            id,
          },
        });

        if (!response.status) {
          await showToast({
            label: 'pureLabel',
            message: 'pure',
            level: IToastLevel.danger,
            params: {
              label: 'Ошибка удаления банковского счета должника',
              message: response.response?.detail ?? response.response,
            },
          });
          return { status: false };
        }

        await fetch();
        await showToast({
          label: 'pureLabel',
          message: 'pure',
          level: IToastLevel.success,
          params: {
            label: 'Данные банковского счета удалены',
          },
        });
      },
    },
  ]));

  onBeforeUnmount(subscribeToSignal(
    SignalType.model,
    async ({ id, model }:{ id: string; model: DebtorBankAccount }) => {
      if (id === SignalType.bankAccModelUpdated) {
        clearErrors();

        const { status, response } = await commonLegacyApiRequest({
          command: ApiCommand.updateDebtorBankAccount,
          data: {
            credit_istitution_name: model.credit_istitution_name,
            address: model.address,
          },
          params: {
            id: model.id,
          },
        });

        if (!status) {
          setErrors(
            Object.entries(response) as SourceErrors<keyof DebtorBankAccount>,
          );
        } else {
          await fetch();
          await showToast({
            label: 'pureLabel',
            message: 'pure',
            level: IToastLevel.success,
            params: {
              label: 'Данные банковского счета обновлены',
            },
          });
          unsubCreateTypeDialog?.();
          unsubCreateTypeDialog = null;
        }
      }
    },
  ));

  return {
    columns,
    records: dataPrepared,
    actions,
    fetch,
    selected,
    onSelectBankAccount,
  };
};
