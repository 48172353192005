import { ApiCommand, ListingRequestSource, ListingResponse } from '@/store/modules/api';
import { formatListingRequest } from '@/components/activeTable/useActiveTable';
import { Debtor } from '@/hooks/useDebtors';
import { useApi } from '@/hooks/useApi';

export type DebtorFinanceDataType = 'paid_up'|'accrual'|'recalc';

export type DebtorFinanceDataFetchListModel = ListingRequestSource<{
  debtor_data: Debtor['pk'];
  type: DebtorFinanceDataType;
}>

export type DebtorFinanceData = {
  id: number;
  debtor_data: Debtor['pk'];
  date: string;
  value: number;
  type: DebtorFinanceDataType;
  for_date: string;
}

type DebtorFinanceDataCreateItemModel = Omit<DebtorFinanceData, 'id'>;

type DebtorFinanceDataUpdateItemModel = DebtorFinanceData;

export const useDebtorFinanceApi = () => {
  const api = useApi();

  return {
    fetchList: (model: DebtorFinanceDataFetchListModel) => api<ListingResponse<DebtorFinanceData>>({
      command: ApiCommand.debtorFinanceDataFetchList,
      params: formatListingRequest(model),
    }).then((r) => {
      if (!r.status) return r;

      return {
        status: true,
        response: {
          ...r.response,
          results: r.response.results.map((el) => ({
            ...el,
            value: +el.value,
          })),
        },
      };
    }),
    fetchItem: (id: number) => api<DebtorFinanceData>({
      command: ApiCommand.debtorFinanceDataFetchItem,
      params: { id },
    }),
    createItem: (model: DebtorFinanceDataCreateItemModel) => api<DebtorFinanceData>({
      command: ApiCommand.debtorFinanceDataCreateItem,
      data: model,
    }),
    updateItem: (model: DebtorFinanceDataUpdateItemModel) => api<DebtorFinanceData>({
      command: ApiCommand.debtorFinanceDataUpdateItem,
      params: { id: model.id },
      data: model,
    }),
    deleteItem: (id: number) => api<boolean>({
      command: ApiCommand.debtorFinanceDataDeleteItem,
      params: { id },
    }),
  };
};
