import {
  Component, ComputedRef, InjectionKey, Ref,
} from 'vue';
import { Company, Region } from '@/hooks/useCompanies';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import { Debtor } from '@/hooks/useDebtors';
import { ReportingDataModelConfig } from '@/service/api/reporting/dataModelConfig';
import { ProductionType } from '@/hooks/useConstructor';
import type { PreventLeaveController } from '@/hooks/usePreventLeave';
import { useTemplateStyleModal } from '@/pages/exchange/export/useTemplateStyleModal';
import { ConstructorReportTemplateRows } from '@/hooks/useConstructorReportTemplates';

export const CompanyIdKey: InjectionKey<Ref<Company['id']>> = Symbol('companyId');
export const CompanyModuleTypeKey: InjectionKey<Ref<CompanyModuleType>> = Symbol('companyModuleType');
export const RegionIdKey: InjectionKey<Ref<Region['id']>> = Symbol('regionId');
export const RegionCodeKey: InjectionKey<Ref<Region['code']|null>> = Symbol('regionCode');
export const FetchDebtorDataKey: InjectionKey<() => Promise<void>> = Symbol('fetchDebtorData');
export const DebtorIdKey: InjectionKey<ComputedRef<Debtor['debtor']['pk']>> = Symbol('debtorIdKey');
export const DebtorKey: InjectionKey<Ref<Debtor>> = Symbol('debtorKey');
export const ProductionTypeKey: InjectionKey<Ref<ProductionType>> = Symbol('productionType');
export const DebtorIsOrganizationKey: InjectionKey<ComputedRef<boolean>> = Symbol('debtorIsOrganizationKey');
export const DebtorDialogTitleKey: InjectionKey<ComputedRef<string>> = Symbol('debtorDialogTitleKey');
export const DialogIsCurrentKey: InjectionKey<ComputedRef<boolean>> = Symbol('dialogIsCurrent');
export const ReportingModelSchemas: InjectionKey<Ref<ReportingDataModelConfig[]>> = Symbol('ReportingChartFormModels');
export const IsIonicApp: InjectionKey<boolean> = Symbol('isIonicApp');

export const ReportingChartDialogSymbol = Symbol('ReportingChartDialog');
export const ReportingChartDialogResponseSymbol = Symbol('ReportingChartDialogResponseSymbol');

export const PreventLeaveControllerKey: InjectionKey<PreventLeaveController> = Symbol('PreventLeaveControllerKey');

// platform based components
export const PhoneNumberStatusComponentKey: InjectionKey<Component> = Symbol('PhoneNumberStatusComponent');
export const DebtorAddressStatusComponentKey: InjectionKey<Component> = Symbol('DebtorAddressStatusIconComponentKey');
export const DebtorRegistrationAddressStatusComponentKey: InjectionKey<Component> = Symbol('DebtorRegistrationAddressStatusComponentKey');

export const IonCheckboxComponentKey: InjectionKey<Component> = Symbol('IonCheckboxComponentKey');
export const ShowConstructorReportTemplateColumnStyleModalKey: InjectionKey<ReturnType<typeof useTemplateStyleModal>> = Symbol('ShowConstructorReportTemplateColumnStyleModalKey');

export const ConstructorReportTemplateHeaderHeights: InjectionKey<Ref<ConstructorReportTemplateRows>> = Symbol('ConstructorReportTemplateHeaderHeights');
