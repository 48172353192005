const normalizeTime = (hoursOrMinutes: number): string => {
  if (hoursOrMinutes < 10) return `0${hoursOrMinutes}`;
  return hoursOrMinutes.toString();
};

// 08:00, 08:30 и т.д.
export const getTimeIntervals = (startHour: number, endHour: number, halfHour = false) => {
  let currentHour = startHour;
  const end = endHour;
  const arr = [];
  do {
    arr.push([normalizeTime(currentHour), normalizeTime(0)]);
    if (halfHour) arr.push([normalizeTime(currentHour), normalizeTime(30)]);
    if (currentHour === 23) {
      currentHour = 0;
    } else {
      currentHour += 1;
    }
  } while (+(arr[arr.length - 1][0]) !== end);
  if (halfHour) arr.pop();
  return arr.map((arr) => arr.join(':'));
};
