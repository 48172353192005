import { ActionType, ActiveTableAction, ActiveTableActionPayload } from '@/components/activeTable/useActiveTable';
import { Ref, getCurrentInstance } from 'vue';
import ContextMenu, { MenuItem } from '@imengyu/vue3-context-menu';

export const useContextClick = (
  isSelectable: boolean,
  actionPayload: Ref<ActiveTableActionPayload<any, any>>,
  allSelected: Ref<boolean>,
  actionsMap: Ref<{
    [key in ActionType]: Array<ActiveTableAction<any, any>>
  }>,
  recordsData: Ref<{keys: any[]; map: any; records: any[]}>,
  cssModule: Record<string, string>,
  tableContext: any,
) => {
  const app = getCurrentInstance();
  const onContextClick = (e: PointerEvent) => {
    console.log('onContextClick', e);

    const findParentRowRecursive = (el: HTMLElement): HTMLElement|null => {
      if (el.classList?.contains(cssModule.row)) {
        return el;
      }
      if (!el.parentElement) {
        return null;
      }
      return findParentRowRecursive(el.parentElement);
    };

    // @ts-ignore
    const row = findParentRowRecursive(e.target);
    // e?.path?.find((el) => el.classList?.contains(cssModule.row));
    console.log('useContextClick 1', row);
    if (!row) {
      return;
    }
    if (row.dataset.table === 'table-header') {
      return;
    }
    // @ts-ignore
    const i = +row.dataset?.rowIndex;
    if (Number.isNaN(i)) {
      console.error('table context click: row not found');
      return;
    }
    const record = recordsData.value.records[i]?.record;
    console.log('useContextClick 2', actionsMap.value.context, isSelectable);

    const currentContainers = document.querySelectorAll<HTMLDivElement>('.mx-context-menu');
    currentContainers.forEach((container) => {
      container.remove();
    });

    if (!actionsMap.value.context?.length && !isSelectable) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    ContextMenu.showContextMenu({
      x: e.x,
      y: e.y,
      items: [
        isSelectable && {
          label: 'Выделить все',
          onClick: () => {
            allSelected.value = true;
          },
        },
        isSelectable && {
          label: 'Снять выделение',
          onClick: () => {
            allSelected.value = false;
          },
        },
        ...((actionsMap.value.context || []).map(
          ({ hint, handler }: Pick<ActiveTableAction<any, any>, 'hint'|'handler'>) => ({
            label: hint,
            onClick: () => handler?.(
              actionPayload.value,
              record,
              tableContext,
            ),
          }),
        )),
      ].filter(Boolean) as Array<MenuItem>,
    });
  };

  return onContextClick;
};
