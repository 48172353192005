import { Ref, VNode } from 'vue';
import { StateKey } from '@/components/types';

export enum ISelectInputState {
  default = 'default',
  tiny = 'tiny',
  medium = 'medium',
}

type SelectState = StateKey | keyof typeof ISelectInputState;

export type ISelectInput<
  Option extends Record<any, any> = Record<any, any>,
  KeyField extends keyof Option = keyof Option
> = {
  valueField?: KeyField;
  options?: Array<Option>;
  modelValue?: Option[KeyField] | Array<Option[KeyField]>;
  placeholder?: string;
  state?: ISelectInputState | Array<ISelectInputState> | Array<SelectState>;
  multiple?: boolean;
  fillable?: boolean;
  errors?: string[];
  displayField?: string | string[];
  allowEmpty?: boolean;
  emptyValue?: any;
  label?: string;
  query?: string;
  isSearchable?: boolean;
  isDisabled?: boolean|Ref<boolean>;
  renderLabel?: (option: any) => VNode|string;
  renderOption?: (option: any) => VNode|string;
  valueIsObj?: boolean;
  autoOpen?: boolean;
  id?: string;
}
