import {
  createApp, h, defineAsyncComponent, defineComponent, Suspense,
} from 'vue';
import { ElLoading } from 'element-plus';
import 'element-plus/es/components/loading/style/css';
import 'element-plus/es/components/tooltip/style/css';
import 'element-plus/es/components/popover/style/css';
import 'element-plus/es/components/icon/style/css';
import 'element-plus/es/components/message/style/css';
import 'element-plus/es/components/notification/style/css';
import 'element-plus/es/components/button/style/css';
import 'element-plus/es/components/dropdown/style/css';
import 'element-plus/es/components/dropdown-item/style/css';
import 'element-plus/es/components/dropdown-menu/style/css';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import type { Integration, Transaction } from '@sentry/types';
import { use } from 'echarts/core';
// @ts-ignore
import ResizeTextarea from 'resize-textarea-vue3';
import ActiveTable from '@urrobot/web/src/components/activeTable/ActiveTable.vue';
import ActiveField from '@urrobot/web/src/components/activeField/ActiveField.vue';
import ActiveForm from '@urrobot/web/src/components/activeForm/ActiveForm.vue';
import ActiveFormContainerNext from '@urrobot/web/src/components/activeFormContainerNext/ActiveFormContainerNext.vue';
import ActiveFormNext from '@urrobot/web/src/components/activeFormNext/ActiveFormNext.vue';
import Dialog from '@urrobot/web/src/components/dialog/Dialog.vue';
import DocumentField from '@urrobot/web/src/components/documentField/DocumentField.vue';
import { useModelDialog } from '@/hooks/useModelDialog';
// @ts-ignore
import VueResizeText from 'vue3-resize-text';
import {
  SVGRenderer,
} from 'echarts/renderers';
import {
  PieChart,
  BarChart,
  LineChart,
} from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent, PolarComponent, GraphicComponent, DatasetComponent,
} from 'echarts/components';
import { Workbox } from 'workbox-window';
import App from './App.vue';
import router from './router';
import { asyncStore } from './store';
import i18n from './i18n';
import { isDev, sentryDsn } from '@/utils/env';
import { OutsideClickDirective } from '@/directives/outsideClick';
import { SignalType } from '@/hooks/useSignal';
import { VERSION } from '@/store/modules/layout';
// @ts-ignore
import ContextMenu from '@imengyu/vue3-context-menu';
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';
import { testCore } from '@urrobot/core';
import { RouterKey } from '@core/symbols';
import { VueQueryPlugin } from 'vue-query';

use([
  SVGRenderer, BarChart, LineChart, PieChart, GridComponent, TooltipComponent, LegendComponent,
  TitleComponent, PolarComponent, GraphicComponent, DatasetComponent,
]);

asyncStore.then((store) => {
  const app = createApp(App)
    .use(store)
    .use(i18n)
    .use(ContextMenu)
    .use(ResizeTextarea);

  app.directive('outside-click', OutsideClickDirective);
  app.use(VueResizeText);
  app.use(VueQueryPlugin);
  app.use(ElLoading);
  app.provide(RouterKey, router);
  app.component('ActiveTable', ActiveTable);
  app.component('ActiveField', ActiveField);
  app.component('ActiveForm', ActiveForm);
  app.component('ActiveFormContainerNext', ActiveFormContainerNext);
  app.component('ActiveFormNext', ActiveFormNext);
  app.component('Dialog', Dialog);
  app.component('FileField', DocumentField);

  if (!isDev) {
    Sentry.init({
      release: `urrobot-frontend@${VERSION}`,
      logErrors: true,
      app,
      dsn: sentryDsn,
      integrations: [
        new Integrations.BrowserTracing({
          // @ts-ignore
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', window.location.origin, /^\//],
        }) as unknown as Integration,
      ],
      tracesSampleRate: 1.0,
      // beforeSend(event, hint) {
      //   const error = hint?.originalException as Error;
      //   if (
      //     error && error.message
      //     && (
      //       ['failed to fetch', 'loading css chunk'].some(
      //         (msg) => error.message.toLowerCase().includes(msg),
      //       )
      //     )
      //   ) {
      //     return null;
      //   }
      //   return event;
      // },
    });
  }

  app
    .use(router);

  app.component('Var', {
    render() {
      return this.$slots.default(this.$attrs);
    },
  });

  let previousMessage = '';
  app.config.warnHandler = (message, vm, trace) => {
    if (message.includes('injection "')) {
      return;
    }
    if (message === previousMessage) {
      return;
    }
    previousMessage = message;
    console.warn(`UR Vue warn: ${message}`, vm, `\n${trace}`);
    // console.warn(`UR Vue warn: ${message}`);
  };

  app.mount('#app');

  // @ts-ignore
  window.vueStore = store;
});

try {
  // TODO почему-то в событии installed event.isUpdate = true
  // только при первом вызове registered.update()
  // пришлось придумать хак с флагом isUpdate
  let isUpdate = false;
  const wb = new Workbox(`${window.origin}/service-worker.js`, {
    updateViaCache: 'none',
  });

  wb.addEventListener('installed', (event) => {
    console.log('Service worker installed! isUpdate', event.isUpdate, isUpdate);
    if (event.isUpdate) {
      window.location.reload();
      return;
    }
    if (isUpdate) {
      asyncStore.then((store) => {
        store.commit('layout/signal', { signalType: SignalType.updateAvailable });
      });
    }
  });

  // Register the service worker after event listeners have been added.
  wb.register()
    .then((registered) => {
      console.log('service worker registered');
      if (registered) {
        registered.update().catch((e) => {
          console.log(e);
        });
        setInterval(async () => {
          try {
            const result: any = await registered.update();
            if (result) {
              isUpdate = !!result.installing;
            }
          } catch (e) {
            console.log(e);
          }
        }, 60000);
      }
    })
    .catch((error) => console.log(`Service worker error: ${error}`));
} catch (e) {
  console.error(`Service worker error: ${String(e)}`);
}
