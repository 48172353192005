import {
  computed, Ref, ref, watch,
} from 'vue';
import { CompanySettings, UpdateCompanySettingsModel, useCompanies } from '@/hooks/useCompanies';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { getAwaitFrame } from '@/utils/window';

export const useCompanySettings = (payloadCompany?: Ref<number>) => {
  const {
    fetchCompanySettings, updateCompanySettings: _updateCompanySettings,
  } = useCompanies();

  const {
    companyId: defaultCompanyId,
  } = useDefaultCompany();

  const isLoading = ref(false);

  const companyId = computed(
    () => (payloadCompany ? payloadCompany.value : defaultCompanyId.value),
  );

  const companySettings = ref<CompanySettings|null>(null);

  const fetchData = async () => {
    if (!companyId.value) {
      return;
    }
    isLoading.value = true;
    const { status, response } = await fetchCompanySettings(companyId.value);
    if (status) {
      companySettings.value = response;
    } else {
      // companySettings.value = {};
    }
    isLoading.value = false;
  };

  watch(companyId, getAwaitFrame(fetchData), {
    immediate: true,
  });

  const updateCompanySettings = (
    model: UpdateCompanySettingsModel['payload'],
  ) => {
    if (isLoading.value) {
      return { status: false, response: null };
    }
    isLoading.value = true;
    if (companySettings.value) {
      Object.keys(model).forEach((k) => {
        // @ts-ignore
        (companySettings.value as CompanySettings)[k] = model[k];
      });
    }
    const oldSettings = companySettings.value ? { ...companySettings.value } : null;
    return _updateCompanySettings({
      id: companyId.value as number,
      payload: model,
    }).then((response) => {
      if (!response.status) {
        companySettings.value = oldSettings;
        return response;
      }
      isLoading.value = false;
      return response;
    });
  };

  return {
    companySettings, updateCompanySettings, isLoading,
  };
};
