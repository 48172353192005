import { ref, SetupContext } from 'vue';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { AsuDialog, Model } from '@/pages/debtors/_module/tableActions/asuUpdate/types';

export const useAsuUpdateDialog = (props: {
  onSave: AsuDialog['onSave'];
}, { emit }: SetupContext<'close'[]>) => {

  const { companyModuleType } = useProtectedDefaultCompany();
  const noPeriod = ref<boolean>(true);
  const model = ref<Model>(!noPeriod.value ? {
    date_from: new Date(),
    date_to: new Date(),
  } : {});

  const submit = () => {
    props.onSave(model.value, noPeriod.value);
    emit('close');
  };

  return {
    noPeriod,
    model,
    submit,
    companyModuleType,
  };
};
