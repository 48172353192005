import { ApiCommand } from '../store/modules/api';
import { useApi } from './useApi';
import { DataFileModel, DataFileResponse } from '../types/datafile';

export type DataFileApiCommand = ApiCommand.sendEmail
    |ApiCommand.sendVoice
    |ApiCommand.sendSms
    |ApiCommand.updateInn
    |ApiCommand.updateVehicle
    |ApiCommand.compositeUpdate
    |ApiCommand.sendPretrialClaim
    // |ApiCommand.fsspUpdate
    // |ApiCommand.claim

export type NotificationType = 'claim' | 'sms' | 'email' | 'voice'

export const notificationApiCommandMap = {
  sms: ApiCommand.sendSms,
  email: ApiCommand.sendEmail,
  voice: ApiCommand.sendVoice,
  claim: ApiCommand.sendPretrialClaim,
} as Record<NotificationType, DataFileApiCommand>;

export const useDataFileRequest = () => {
  const api = useApi();
  return <T extends DataFileModel = DataFileModel>(
    command: DataFileApiCommand, data: T,
  ) => api<DataFileResponse>({
    command,
    params: {
      company_id: data.filters.company_id,
    },
    data,
  });
};
