import { Ref, ref, watch } from 'vue';

export const useLocalState = (state: Ref<string|string[]>) => {
  const localState = ref<string[]>([]);
  watch(state, (s) => {
    if (typeof s === 'string' && localState.value[0] !== s) {
      localState.value = [s];
    } else if (JSON.stringify(localState.value) !== JSON.stringify(s)) {
      localState.value = [...(s as Array<string>)];
    }
  }, { immediate: true });
  return localState;
};
