import { Ref, ref } from 'vue';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useUnsubs } from '@/hooks/useUnsubs';
import { getRandomString } from '@/utils/string';
import {
  CloseDialogCallback, IDialog, IDialogComponent, useDialog,
} from '@/hooks/useDialog';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { ApiResponse } from '@/service/api';

export type ModelDialogConfig<
  ModelForm,
  ModelResponse,
  DialogPayload extends unknown = void,
> = {
  getForm?: (...args: DialogPayload extends void ? [] : [DialogPayload]) => Promise<ApiResponse<ModelForm>>;
  // todo rename ON_SUBMIT_BUTTON
  onModelUpdate: (modelForm: ModelForm, ...args: DialogPayload extends void ? [] : [DialogPayload]) => Promise<ApiResponse<ModelResponse>>;
  fields: Array<ActiveFormField<ModelForm>>;
  title: string;
  key?: string;
  ionDialogClassList?: string[];
} & (DialogPayload extends void
  // eslint-disable-next-line @typescript-eslint/ban-types
  ? {}
  : {
    getPrevInitPayload?: Ref<() => DialogPayload|undefined>;
    getNextInitPayload?: Ref<() => DialogPayload|undefined>;
    saveOnNavigate?: boolean;
    onNavigate?: (payload: DialogPayload) => void;
  })

export const useModelDialog = <ModelForm, ModelResponse, DialogPayload extends unknown = void>({
  getForm,
  getPrevInitPayload,
  getNextInitPayload,
  onNavigate,
  saveOnNavigate,
  onModelUpdate,
  fields,
  title,
  key,
  ionDialogClassList,
}: ModelDialogConfig<ModelForm, ModelResponse, DialogPayload>) => {
  const dialogId = `model-dialog-${getRandomString()}`;
  const { sub, unsub, unsubAll } = useUnsubs();
  const {
    subscribeToSignal, dispatchSignal, awaitSignalResponse, subscribeToSignalOnce,
  } = useSignal();
  const isOpen = ref(false);
  const responseResult = ref<ApiResponse<ModelResponse>>();
  let closeDialog: CloseDialogCallback|undefined;
  let unsubModelSignal: (() => void)|undefined;

  const {
    showDialog,
    updateDialogById,
  } = useDialog();
  const isLoading = ref(false);

  const showModelDialog = async (
    ...initPayload: DialogPayload extends void ? [] : [DialogPayload]
  ): Promise<ApiResponse<ModelResponse>> => {
    isLoading.value = true;
    let record;
    if (getForm) {
      const response = await getForm(...initPayload);
      if (!response.status) return response;
      record = response.response;
    } else { // @ts-ignore
      if (initPayload && initPayload[0] && initPayload[0].getForm) {
        // @ts-ignore
        record = initPayload[0].getForm();
        console.log('record', record);
      }
    }
    isLoading.value = false;

    const payload = {
      component: IDialogComponent.model,
      addInRoute: false,
      title,
      mKey: key,
      key,
      payload: {
        mKey: key,
        key,
        title,
        initEditing: true,
        record,
        signal: dialogId,
        isEditable: true,
        saveOnNavigate,
        fields,
        ...(getNextInitPayload || getPrevInitPayload ? {
          withPrevNext: true,
        } : {}),
        ...(getNextInitPayload?.value() ? {
          goNext: () => {
            const np = getNextInitPayload.value();
            if (np !== undefined) {
              onNavigate?.(np);
              return showModelDialog(...[np] as DialogPayload extends void ? [] : [DialogPayload]) as unknown as Promise<void>;
            }
          },
        } : {}),
        ...(getPrevInitPayload?.value() ? {
          goPrev: async () => {
            const pp = getPrevInitPayload.value();
            if (pp !== undefined) {
              onNavigate?.(pp);
              return showModelDialog(...[pp] as DialogPayload extends void ? [] : [DialogPayload]) as unknown as Promise<void>;
            }
          },
        } : {}),
        ...(ionDialogClassList ? { ionDialogClassList } : { }),
      },
      ...(closeDialog ? { id: closeDialog.id } : {}),
    } as IDialog;

    const dialogIsOpen = !!closeDialog;
    if (closeDialog) {
      updateDialogById(payload);
    } else {
      closeDialog = await showDialog(payload);
      isOpen.value = true;
      sub(closeDialog);
    }

    if (unsubModelSignal) {
      unsubModelSignal();
    }
    unsubModelSignal = subscribeToSignal(
      SignalType.model,
      async (payload: { id: string; model: ModelForm; preventClose: boolean }) => {
        if (payload.id === dialogId) {
          const response = await onModelUpdate(payload.model, ...initPayload);
          responseResult.value = response;
          if (!response.status) {
            dispatchSignal(
              SignalType.modelErrors,
              ({
                id: dialogId,
                errors: response.response,
              }),
            );
          } else {
            if (payload.preventClose) {
              dispatchSignal(
                SignalType.modelSuccess,
                { id: payload.id },
              );
            } else {
              unsub(closeDialog as CloseDialogCallback);
            }
          }
        }
      },
    );
    sub(unsubModelSignal);

    if (dialogIsOpen) {
      return Promise.resolve(undefined as unknown as ApiResponse<ModelResponse>);
    }

    let unsubClosedDialog: () => void;
    return new Promise<ApiResponse<ModelResponse>>(
      (resolve) => {
        // eslint-disable-next-line prefer-const
        unsubClosedDialog = subscribeToSignal(
          SignalType.dialogClosed,
          ({ component, payload }: any) => {
            if (component === IDialogComponent.model
              && payload.signal === dialogId
            ) {
              closeDialog = undefined;
              isOpen.value = false;
              const value = responseResult.value || { status: false, response: null };
              resolve(
                value,
              );
              responseResult.value = undefined;
              unsub(unsubClosedDialog);
              if (unsubModelSignal) {
                unsub(unsubModelSignal);
                unsubModelSignal = undefined;
              }
            }
          },
        );
        sub(unsubClosedDialog);
      },
    );
  };

  return {
    showDialog: showModelDialog,
    isOpen,
  };
};
