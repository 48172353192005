import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useDefaultCompanyPermissions } from '@/hooks/useDefaultCompanyPermissions';
import { formatPhone } from '@/utils/string';
import { useDebtorModel } from '@/components/dialog/dialogs/debtor/tabs/common/tabs/main/useDebtorModel/useDebtorModel';

export const useDebtorMainTab = () => {
  const {
    debtor, productionType, isOrganization,
  } = useInjectDebtorDialog();
  const { t } = useLocalI18n('debtor.common.main');
  const { hasWritePermission } = useDefaultCompanyPermissions(productionType);

  const {
    model, save, errorsMap, isEditing, fields,
  } = useDebtorModel();

  return {
    t,

    debtor,
    errorsMap,
    model,
    fields,
    save,
    isEditing,

    isOrganization,
    hasWritePermission,
    formatPhone,
  };
};
