import { dateToApiDate } from '@/utils/date';
import { ProductionType } from '@/hooks/useConstructor';
import { Ref } from 'vue';

export type DebtorCourtDecisionModelForm = {
  debtor_pk: string;
  file: File;
  court_name: string;
  decision_date: Date;
  case_number: string;
  debtor_name: string;
  address: string;
  birth_place: string;
  birth_date: Date;
  amount: number;
  penalties: number;
  duty: number;
  passport: string;
  snils: string;
  account_number: string;
  date_from: Date;
  date_to: Date;
}

export function modelToPayload(model: DebtorCourtDecisionModelForm, debtorPk: Ref<string>, productionType: Ref<ProductionType>) {
  return {
    ...model,
    debtor_pk: debtorPk.value,
    production_type: productionType.value,
    file: model.file,
    decision_date: dateToApiDate(model.decision_date),
    birth_date: dateToApiDate(model.birth_date),
    date_from: dateToApiDate(model.date_from),
    date_to: dateToApiDate(model.date_to),
  };
}
