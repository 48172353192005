import { formatDate, parseIfDate } from '@/utils/date';

type DictMaps = Record<string, any>;

export const valueFormatter = (key: string, value: string|number, dictMaps: DictMaps) => {
  if (value === null || value === undefined || value === 'null') {
    return '-';
  }
  if (value === 'paid_up') {
    return 'Оплата';
  } if (value === 'debts') {
    return 'Задолженность';
  } if (value === 'accruals') {
    return 'Начислено';
  } if (value === 'recalculations') {
    return 'Перерасчет';
  } if (value === 'penalties') {
    return 'Пеня';
  } if (value === 'penalties') {
    return 'Пошлина';
  }
  if (key === 'relationships') {
    if (Array.isArray(value)) {
      return value.map((v) => dictMaps.relationships[v] ?? v).join(', ');
    }
    if (!value) {
      return null;
    }
    let parsedList;
    try {
      const parsedGroups = (value as string).split(',').map((v) => v.match(/<.*?: '(.*?)'>/));
      console.log('parsedGroups', parsedGroups);
      if (parsedGroups.some((v) => !v)) {
        parsedList = (value as string).split(',').map((v) => v.match(/'(.*?)'/)?.[1]) as string[];
      } else {
        console.log('parsedGroups1', (parsedGroups as string[][]).map((v) => v[1]));
        parsedList = (parsedGroups as string[][]).map((v) => v[1]);
      }
    } catch (e) {
      console.error(e);
      return value;
    }
    console.log('dictMaps.relationships', parsedList, dictMaps.relationships);
    return parsedList.map((v) => dictMaps.relationships[v] ?? v).join(', ');
  }
  if (key === 'registration') {
    console.log('registration', value);
    if (value === 1) {
      return 'Постоянная';
    } if (value === 2) {
      return 'Временная';
    }
    return value;
  }
  if (value === 'True') return 'Да';
  if (value === 'False') return 'Нет';
  const parsedDate = parseIfDate(String(value));
  if (parsedDate !== null) {
    return formatDate(parsedDate);
  }

  return value;
};

export const hasExtra = (record: any) => record.extra && Object.keys(record.extra).length;

const excludeFields = ['id', 'created_at', 'updated_at', 'debtor_id', 'parsing_status', 'enrichment_date', 'key', 'document_ptr_id', 'production_type', 'estate_object_id', 'basedocument_ptr_id'];

type mapS = (key: string) => string;

export const renderExtra = (extra: Record<string, any>, t: mapS, dictMaps: DictMaps) => Object.entries(extra)
  .filter(([key]) => !excludeFields.includes(key))
  .map(([key, value]) => `${t(key)}: ${valueFormatter(key, value, dictMaps)}`).join('\n');
