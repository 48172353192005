import { useApi } from '@/hooks/useApi';
import { ApiCommand, ApiResponse, ListingResponse } from '@/store/modules/api';
import {
  CreateAccountDocumentModel,
  FetchAccountDocumentsModel,
  FetchAccountDocumentsResponse,
} from '@/hooks/useAccountDocuments';
import { formatListingRequest } from '@/components/activeTable/useActiveTable';

export type AccountImage = {
  id?: number|null;
  name: string;
  file: string;
  // привязка к документу подписи
  document: number;
  klass: 21;
}

export type AccountImageModel = {
  name: string;
  file: File;
  klass: 21;
  // привязка к документу подписи
  document: number;
}

export const useAccountImages = () => {
  const req = useApi();

  const fetchList = async (
    request: FetchAccountDocumentsModel,
  ): Promise<ApiResponse<ListingResponse<AccountImage>>> => req({
    command: ApiCommand.fetchAccountImages,
    params: formatListingRequest(request),
  });

  const create = (model: AccountImageModel) => req({
    command: ApiCommand.createAccountImage,
    data: model,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const remove = (id: number) => req({
    command: ApiCommand.removeAccountImage,
    params: { id },
  });

  // const update = (model: UpdateAccountDocumentModel) => req({
  //   command: ApiCommand.updateAccountImage,
  //   params: {
  //     company_id: model.company_id,
  //   },
  //   data: model,
  // })

  return {
    fetchList, create, remove,
  };
};
