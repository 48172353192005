import { ApiCommand } from '@/service/api/reporting/apiCommand';
import {
  mapReportingDashboardFromResponse,
  mapReportingDashboardFullFromResponse,
} from '@/service/api/reporting/dasboard';
import { mapTileFromResponse } from '@/service/api/reporting/tile';
import { ReportingResponses, ReportingResponsesRaw } from '@/service/api/reporting/responses';

type ModelMapper<C extends ApiCommand> = (
  model: ReportingResponsesRaw[C]
) => ReportingResponses[C]

type ReportingModelMappers = {
  [key in ApiCommand]?: ModelMapper<key>
}

export const responsesMappers: ReportingModelMappers = {
  [ApiCommand.dashboardGetList]: (r) => r.results.map(mapReportingDashboardFromResponse),
  [ApiCommand.dashboardGet]: mapReportingDashboardFullFromResponse,
  [ApiCommand.dashboardPost]: mapReportingDashboardFromResponse,
  [ApiCommand.dashboardPut]: mapReportingDashboardFromResponse,
  [ApiCommand.dashBoardPatch]: mapReportingDashboardFromResponse,
  [ApiCommand.dashboardGetFull]: mapReportingDashboardFullFromResponse,
  [ApiCommand.dashboardDelete]: undefined,
  [ApiCommand.tileGetList]: (r) => r.results.map(mapTileFromResponse),
  [ApiCommand.tileGet]: mapTileFromResponse,
  [ApiCommand.tilePost]: mapTileFromResponse,
  [ApiCommand.tilePatch]: mapTileFromResponse,
  [ApiCommand.tilePut]: mapTileFromResponse,
  [ApiCommand.tileDelete]: undefined,
  [ApiCommand.tileGetData]: undefined,
  [ApiCommand.tilePostData]: undefined,
};
