import { Ref, ref } from 'vue';
import {
  ApiResponse,
} from '@/service/api/index';

export const useSimpleFetch = <M>(
  fetch: () => Promise<ApiResponse<M>>,
  initValue: M | undefined = undefined,
) => {

  const dataStore = ref<M>() as Ref<(typeof initValue extends undefined ? (M | undefined) : M)>;
  if (initValue) {
    dataStore.value = initValue;
  }
  const isLoading = ref(false);
  const promise: Ref<Promise<ApiResponse<M>> | null> = ref(null);

  const doFetch = async () => {
    isLoading.value = true;
    promise.value = fetch();
    const response = await promise.value;
    if (response.status) {
      dataStore.value = response.response;
    } else {
      if (initValue) {
        dataStore.value = initValue;
      } else {
        // @ts-ignore
        dataStore.value = undefined;
      }
    }
    isLoading.value = false;
  };

  return {
    data: dataStore,
    isLoading,
    fetch: doFetch,
    promise,
  };
};
