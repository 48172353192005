import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { ProductionType } from '@/hooks/useConstructor';

export function productionOptions() {
  const { t: rootT } = useI18n();
  return [
    {
      value: ProductionType.pretrial,
      label: rootT('productionType.pretrial'),
    },
    {
      value: ProductionType.judicial,
      label: rootT('productionType.judicial'),
    },
  ];
}
