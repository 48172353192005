import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { AutoTabKey, JudicialAutomationModel } from '@/components/automation/judicial/types';
import { REGEX_EMAIL } from '@/utils/reg-exp';
import { Ref } from 'vue';
import {
  LabelValue,
  OnQueryEmployee,

} from '@/components/automation/judicial/tabs/types';
import { Employee } from '@/hooks/useEmployees';
import {
  defaultCheckboxOptions,
  fieldGroup,
  updatePeriodDefault,
} from '@/components/automation/judicial/tabs/utils';

export function getRegistryFields(
  model: Ref<JudicialAutomationModel>,
  rootT: (s: string) => string,
  onQueryEmployee: OnQueryEmployee,
  employees: Ref<Employee[]>,
  emailOptions: Ref<LabelValue[]>,
) {
  return [
    {
      key: 'auto_fees_discharge',
      type: ActiveFormFieldType.checkbox,
      options: {
        placeholder: '',
        allowEmpty: false,
        ...defaultCheckboxOptions(model, rootT),
      },
      onUpdateModelValue: updatePeriodDefault('fees_discharge_period'),
    },
    {
      key: 'pp3_counter',
      type: ActiveFormFieldType.input,
    },
    {
      key: 'without_fee',
      type: ActiveFormFieldType.checkbox,
    },
    {
      key: 'fees_discharge_period',
      type: ActiveFormFieldType.schedule,
      state: ['primary'],
      options: {
        state: ['primary'],
        isDisabled: !model.value.auto_fees_discharge,
      },
    },
    {
      key: 'employees',
      type: ActiveFormFieldType.select,
      options: {
        multiple: true,
        displayField: ['last_name', 'first_name'],
        valueField: 'id',
        isSearchable: true,
        options: employees.value,
      },
      defaultValue: [],
      onQuery: onQueryEmployee,
    },
    {
      key: 'notify_emails',
      type: ActiveFormFieldType.select,
      onUpdateModelValue: (model: JudicialAutomationModel, $event: Array<string>) => {
        // @ts-ignore
        const values = $event.filter((value) => REGEX_EMAIL.test(value));
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        model.notify_emails = [...values];
        const newValues = values.filter(
          (v) => !emailOptions.value.find(({ value }) => value === v),
        );
        // @ts-ignore
        emailOptions.value = [
          ...newValues.map((value) => ({ label: value, value, key: value })),
          ...emailOptions.value,
        ];
      },
      options: {
        multiple: true,
        fillable: true,
        placeholder: rootT('other.type'),
        options: emailOptions.value,
      },
      defaultValue: [],
    }].map((field) => ({
    ...field,
    ionFieldGroup: fieldGroup(AutoTabKey.registry),
  }));
}
