import { getApiRequest } from '@core/service/serviceConstructor';
import { NotificationSendType } from '@/hooks/useCompanySettingsSecure';

const smscServiceRequest = getApiRequest({
  baseUrl: 'https://smsc.urrobot.tech/',
  interceptor: {
    request: [
      async (config) => {
        if (!config.params?.login || !config.params?.psw) {
          throw new Error('no_token');
        }
        return config;
      },
    ],
  },
});

export const smscApi = {
  fetchSenders: ({ login, password, type }: { login: string; password: string; type: NotificationSendType }) => smscServiceRequest({
    url: 'sys/senders',
    method: 'GET',
    params: {
      login, psw: password, get: 1, fmt: 3, type,
    },
  })(),
};
