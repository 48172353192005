import {
  CompanySettingsJudicial,
  JudicialAutomationModel,
} from '@/components/automation/judicial/types';
import { DebtorCompositeUpdateType } from '@/hooks/useDebtorCompositeUpdate';

export const toModel = (settings: CompanySettingsJudicial): JudicialAutomationModel => {

  console.log('fetched settings', settings);

  const { judicial_amount_from, judicial_amount_to } = settings;

  return { // taking only automation settings
    judicial_automation_enabled: settings.judicial_automation_enabled,

    auto_attach_extracts_from_egrn: settings.auto_attach_extracts_from_egrn,
    auto_correct_debt_period: settings.auto_correct_debt_period,

    amount_range: [+judicial_amount_from || 0, +judicial_amount_to || 0],
    debtors_amount: settings.debtors_amount || 0,
    filling_claim_period: settings.filling_claim_period,
    fees_discharge_period: settings.fees_discharge_period,
    rosreestr_discharge_period: settings.rosreestr_discharge_period,
    auto_fees_discharge: settings.auto_fees_discharge,
    auto_filing_claim: settings.auto_filing_claim,
    send_type: settings.send_type,
    amount_setting: settings.amount_setting,
    need_rosreestr_discharge: settings.need_rosreestr_discharge,
    auto_rosreestr_discharge: settings.auto_rosreestr_discharge,
    auto_executive_transfer: settings.auto_executive_transfer,
    auto_rosreestr_discharge_period_force: settings.auto_rosreestr_discharge_period_force,
    rosreestr_discharge_period_force: settings.rosreestr_discharge_period_force,
    pik_sale_documents_period: settings.pik_sale_documents_period,
    pik_sale_documents: !!settings.pik_sale_documents_period,
    rosreestr_characteristics: !!settings.rosreestr_characteristics,
    rosreestr_movement: !!settings.rosreestr_movement,
    unknown_owner_rosreestr_movement: settings.unknown_owner_rosreestr_movement,
    employees: settings.employees as number[],
    notify_emails: settings.notify_emails,
    pp3_counter: settings.pp3_counter,
    court_test: settings.court_test,
    without_fee: settings.without_fee,
    auto_filing_complaint: !!settings.auto_filing_complaint_period,
    auto_filing_complaint_period: settings.auto_filing_complaint_period,
    court_complaint_overdue: settings.court_complaint_overdue,
    court_user: settings.court_user,
    auto_update_debtor_data: !!settings.auto_update_debtor_data_period,
    auto_update_debtor_data_period: settings.auto_update_debtor_data_period,
    auto_update_debtor_data_config: settings.judicial_auto_update_debtor_data_config
      ? Object.keys(settings.judicial_auto_update_debtor_data_config) as DebtorCompositeUpdateType[]
      : [],
    judicial_auto_update_debtor_data_arguments: settings.judicial_auto_update_debtor_data_arguments?.force,
    enrichment_by_address: settings.enrichment_by_address,
    enrichment_by_address_estates: settings.enrichment_by_address_estates,
    debt_previous_rosreestr_movement: settings.debt_previous_rosreestr_movement,

    move_judicial_debtors_without_debt_to_archive: settings.move_judicial_debtors_without_debt_to_archive,

    auto_refiling_claims: !!settings.auto_refiling_claims_period,
    auto_refiling_claims_period: settings.auto_refiling_claims_period,
    auto_filing_isk: settings.auto_filing_isk,
    auto_filing_claim_by_no_debtor_info: settings.auto_filing_claim_by_no_debtor_info,
    auto_filing_claim_by_another_jurisdiction: settings.auto_filing_claim_by_another_jurisdiction,
    sync_active_court_cases: settings.sync_active_court_cases,
    sync_archive_court_cases: settings.sync_archive_court_cases,
    send_electronic_court_decisions: settings.send_electronic_court_decisions,
    send_electronic_court_decisions_notify_emails: settings.send_electronic_court_decisions_notify_emails as string[] || [],
    exclude_when_died: settings.exclude_when_died,
    exclude_with_deregistration_date: settings.exclude_with_deregistration_date,
    include_children: settings.include_children,
    allow_without_court: settings.allow_without_court,
    show_administrative_district_filter: settings.show_administrative_district_filter,
    send_joint_ownership_letters_separately: settings.send_joint_ownership_letters_separately,
    court_order_period_after_canceled_judgment: settings.court_order_period_after_canceled_judgment,
    short_penalty_calculation: settings.short_penalty_calculation,
    show_automatic_attachments: settings.show_automatic_attachments,
    auto_rosreestr_config: settings.auto_rosreestr_config,
    ignore_norights: settings.auto_rosreestr_config?.ignore_norights || false,
    rosreestr_user: settings.rosreestr_user,
  };
};
