// @ts-nocheck forms

import {
  TileParameters,
  TileDefaultParametersFilters,
  TileLine,
  TileRenderParameters, Tile,
} from '@/service/api/reporting/tile';
import {
  ReportingDataModelFilterEnum,
  ReportingDataModelSchema,
} from '@/service/api/reporting/dataModelConfig';
import {
  mapFormModelFiltersToTile,
  ReportingChartFormFilter,
  ReportingChartFormFilterField,
  splitFilterString,
} from '@/components/forms/form/reportingChartForm/filters';
import {
  getIntervalFormatter,
  getTimeIntervalPreset,
  ReportingChartTimeIntervalPreset,
} from '@/components/forms/form/reportingChartForm/time-intervals';
import { ReportingChartPlateType } from '@/components/forms/form/reportingChartForm/utils';
import { NonNullableKeys } from '@/types/utils';
import { LineGroup } from '@/components/forms/form/reportingChartForm/new/filters';

export type ReportingChartFormModelTimeInterval = Pick<
  TileParameters<Date>, 'start'|'stop'|'interval'
>

export type ReportingChartFormModel = {
  name: string|null;
  type: ReportingChartPlateType|null;
  variant: string|null;
  seriesVariant: string|null;
  lineGroups: LineGroup[];
  timeInterval: string|null;
  show_growth: boolean;
  show_timeline_percent: boolean;
  stack_group: boolean;
  use_not_for_current_company: boolean;
  companies: number[];
  all_companies: boolean;
}

export const removeTitleFromLineKey = (key: string) => key.replace(
  /(?<before>.*)(?<titleToken>((-)?title))(?<title>.+)(?<after>___.+)/i, '$<before>title$<after>',
);

export const parseLineKey = (
  key: string,
): string| {
  name: string;
  group: number;
  title: string|null;
  part: number|null;
  entire: boolean;
  title: string;
  hasTitle: boolean;
} => {
  const matchResult = key.match(
    /((group(?<group>\d+))?(-part(?<part>\d+))?(?<entire>(-)?entire)?((-?)(?<titleToken>title)(?<title>.*?))?___)?(?<name>.+)/i,
  );
  if (!matchResult || !matchResult.groups) return key;
  return {
    group: +matchResult.groups.group,
    part: +matchResult.groups.part || null,
    name: matchResult.groups.name,
    entire: !!matchResult.groups.entire,
    hasTitle: !!matchResult.groups.titleToken,
    title: matchResult.groups.title || null,
  };
};
export const groupLines = (lines: TileLine[]) => lines.reduce((acc, line) => {
  const parsed = parseLineKey(line.key);
  if (typeof parsed === 'string') {
    if (!acc[line.key]) acc[line.key] = [];
    acc[line.key].push({
      group: null, part: null, line, entire: false, hasTitle: false, titleToken: null,
    });
  } else {
    const {
      name, group, part, entire, title, hasTitle,
    } = parsed;
    if (!acc[name]) acc[name] = [];
    acc[name].push({
      group,
      part,
      line: { ...line },
      entire,
      title,
      hasTitle,
      noTitleKey: removeTitleFromLineKey(line.key),
    });
  }
  return acc;
}, {

} as {
  [lineKey: string]: { group: number|null; part: number|null; entire: boolean; line: TileLine }[];
});

export const mapLinesToForm = (lines: TileLine[], rParams: TileRenderParameters) => {
  const linesGroupedByRealName = groupLines(lines);
  return Object.entries(linesGroupedByRealName).map(([cleanLineKey, lines]) => {
    const lineRenderParams = rParams.lines[cleanLineKey];

    let lineFound;
    lineFound = lines.find((l) => !l.group && !l.part && !l.entire);
    if (!lineFound) {
      lineFound = lines.find((l) => l.part);
    }

    if (!lineFound) {
      throw Error('mapping lines to form config error');
    }
    const { split_by } = lineRenderParams;
    const fieldSplitBy = (!split_by || typeof split_by === 'string')
      ? split_by
      : split_by.field;

    // этим фильтром мы делили на группы - убираем его из общих
    const clearFilters = split_by ? Object.fromEntries(
      Object.entries(lineFound.line.filters).filter(
        ([k, _]) => !k.startsWith(fieldSplitBy) && !k.startsWith(`!${fieldSplitBy}`),
      ),
    ) : lineFound.line.filters;

    return {
      ...lineFound.line,
      key: cleanLineKey,
      name: lineRenderParams.name,
      show_previous: lineRenderParams.show_previous,
      split_by,
      show_percent: lineRenderParams.show_percent,
      filters: mapFiltersToForm(clearFilters),
    };
  });
};

const mapFiltersToForm = (filters: TileDefaultParametersFilters) => Object.entries(filters)
  .map(([filterKey, value]) => {
    const matchResult = splitFilterString(filterKey);
    if (!matchResult) return null;
    const operator = `${matchResult.not ? '!' : ''}${matchResult.operator}`;
    const { name } = matchResult;
    return {
      name, operator, value,
    };
  }) as ReportingChartFormFilter[];

export const mapTileToFormModel = (
  tile: Tile,
): NonNullableKeys<ReportingChartFormModel> => {
  const renderParameters = tile.render_parameters[0];
  const linesParams = renderParameters.lines;
  const defaultParameters = tile.parameters;
  const filters = mapFiltersToForm(tile.parameters.filters);
  // if (renderParameters.interval_preset) {
  //   const interval = intervalsMapMerged[renderParameters.interval_preset];
  // }
  const lines = mapLinesToForm(defaultParameters.lines, renderParameters);

  return {
    name: tile.name,
    lines,
    filters: [],
    model: tile.parameters.model,
    type: tile.render_parameters[0].type,
    variant: tile.render_parameters[0].variant,
    splitBy: tile.parameters.split_by,
    timeInterval: tile.render_parameters[0].interval_preset,
  };
};

export const mapChartFormModelToTile = (
  model: NonNullableKeys<ReportingChartFormModel>, schema: ReportingDataModelSchema,
): Pick<Tile, 'name'|'parameters'|'render_parameters'> => {
  const timelinePreset = model.timeInterval as ReportingChartTimeIntervalPreset;
  const showPrevious = model.lines.some((line) => line.show_previous);
  const timeInterval = getTimeIntervalPreset(
    showPrevious ? `${timelinePreset}-with-prev` : timelinePreset,
  );
  // timeInterval.start = dateToApiDate(timeInterval.start);
  // timeInterval.stop = dateToApiDate(timeInterval.stop);
  const dateFormatter = getIntervalFormatter(timelinePreset);
  if (model.type === ReportingChartPlateType.Indicator) {
    const line = model.lines[0] as ReportingChartFormFilterField;
    const lines = [] as TileLine[];
    const valueType = schema.fields[line.field].type;
    const renderParams = {
      lines: {},
      type: model.type,
      variant: model.variant,
      interval_preset: timelinePreset,
    } as TileRenderParameters;
    if (line.show_percent) {
      const lineKey = `entire___${line.key}`;
      lines.push({
        key: lineKey,
        field: line.field,
        function: line.function,
        filters: {},
      });
      if (dateFormatter) {
        renderParams.custom_date_formatter = dateFormatter;
      }
      // renderParams.lines[lineKey] = { name: `${line.name} общ` };
    }
    lines.push({
      key: `${line.key}`,
      field: line.field,
      function: line.function,
      filters: mapFormModelFiltersToTile(
        line.filters as unknown as NonNullableKeys<ReportingChartFormFilter>,
      ),
    });
    renderParams.lines[line.key] = {
      name: line.name,
      show_percent: line.show_percent,
      show_previous: line.show_previous,
      value_type: valueType,
    };

    return {
      name: model.name,
      parameters: {
        ...timeInterval,
        model: model.model,
        order_by: [model.splitBy],
        split_by: model.splitBy,
        limit: 1000,
        offset: 0,
        filters: {},
        lines,
      },
      render_parameters: [renderParams],
    };
  }
  if (model.type === ReportingChartPlateType.ByStatus) {
    const { lines, renderParams } = model.lines.reduce(
      (acc, line, i) => {
        const lineFilters = mapFormModelFiltersToTile(
          line.filters as unknown as NonNullableKeys<ReportingChartFormFilter>,
        );
        const valueType = schema.fields[line.field].type;
        // split by enum
        if (line.split_by && typeof line.split_by === 'string') {
          const splitEnum = schema.filters[line.split_by];
          // @ts-ignore
          if (!splitEnum || !splitEnum.enum) {
            throw Error('split line error');
          }
          const enumKeys = (splitEnum as ReportingDataModelFilterEnum).enum;
          acc.renderParams.lines[line.key] = {
            name: line.name,
            split_by: line.split_by,
            show_percent: line.show_percent,
            show_previous: line.show_previous,
            value_type: valueType,
          };

          enumKeys.forEach((enumKey, j) => {
            const name = `${line.name} - ${enumKey}`;
            const key = `group${i + 1}-part${j + 1}___${line.key}`;
            acc.renderParams.lines[key] = {
              name,
              show_previous: showPrevious,
              value_type: valueType,
            };
            acc.lines.push({
              key,
              field: line.field,
              function: line.function,
              filters: { ...lineFilters, [`${line.split_by}__exact`]: enumKey },
            });
          });
        }

        return acc;
      }, {
        lines: [],
        renderParams: {
          lines: {},
          interval_preset: timelinePreset,
          custom_date_formatter: dateFormatter,
          type: model.type,
          variant: model.variant,
        },
      } as unknown as { lines: TileLine[]; renderParams: NonNullableKeys<TileRenderParameters> },
    );

    return {
      name: model.name,
      parameters: {
        ...timeInterval,
        model: model.model,
        order_by: [model.splitBy],
        split_by: model.splitBy,
        limit: 1000,
        offset: 0,
        filters: {},
        lines,
      },
      render_parameters: [renderParams],
    };
  }
  if (model.type === ReportingChartPlateType.Timeline) {
    const { lines, renderParams } = model.lines.reduce(
      (acc, line, i) => {
        const valueType = schema.fields[line.field].type;
        if (line.show_percent) {
          const lineKey = `entire___${line.key}`;
          acc.lines.push({
            key: lineKey,
            field: line.field,
            function: line.function,
            filters: {},
          });
        }
        acc.renderParams.lines[line.key] = {
          name: line.name,
          split_by: line.split_by,
          show_percent: line.show_percent,
          show_previous: line.show_previous,
          value_type: valueType,
        };
        const lineFilters = mapFormModelFiltersToTile(
          line.filters as unknown as NonNullableKeys<ReportingChartFormFilter>,
        );
        if (line.split_by) {
          acc.renderParams.lines[line.key] = {
            name: line.name,
            split_by: line.split_by,
            show_percent: line.show_percent,
            show_previous: line.show_previous,
            value_type: valueType,
          };
          // split by enum
          if (line.split_by && typeof line.split_by === 'string') {
            const splitEnum = schema.filters[line.split_by];
            // @ts-ignore
            if (!splitEnum || !splitEnum.enum) {
              throw Error('split line error');
            }
            const enumKeys = (splitEnum as ReportingDataModelFilterEnum).enum;
            enumKeys.forEach((enumKey, j) => {
              const name = `${line.name} - ${enumKey}`;
              const key = `group${i + 1}-part${j + 1}___${line.key}`;
              acc.renderParams.lines[key] = {
                custom_date_formatter: dateFormatter,
                name,
              };
              acc.lines.push({
                key,
                field: line.field,
                filters: { ...lineFilters, [`${line.split_by}__exact`]: enumKey },
                function: line.function,
              });
            });
          }
        } else {
          acc.renderParams.lines[`${line.key}`] = {
            name: line.name,
            custom_date_formatter: dateFormatter,
            show_percent: line.show_percent,
            value_type: valueType,
          };
          acc.lines.push({
            key: `${line.key}`,
            field: line.field,
            filters: { ...lineFilters },
            function: line.function,
          });
        }
        return acc;
      }, {
        lines: [],
        renderParams: {
          lines: {},
          interval_preset: timelinePreset,
          custom_date_formatter: dateFormatter,
          type: model.type,
          variant: model.variant,
        },
      } as unknown as { lines: TileLine[]; renderParams: NonNullableKeys<TileRenderParameters> },
    );

    return {
      name: model.name,
      parameters: {
        ...timeInterval,
        model: model.model,
        order_by: [model.splitBy],
        split_by: model.splitBy,
        limit: 1000,
        offset: 0,
        filters: {},
        lines,
      },
      render_parameters: [renderParams],
    };
  }

  return { };
};
