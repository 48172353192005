import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { computed, watch } from 'vue';
import { ActiveTableColumnFormat, useActiveTable } from '@/components/activeTable/useActiveTable';
import {
  DebtorMainProfileOrganization,
  DebtorOrganization,
  OrganizationBankruptData,
} from '@/hooks/useDebtors';
import { parseDateStringWithDefaultFormat } from '@/components/dateInputNext/utils';
import { openAuthProtectedFile } from '@/utils/fileUrl';

export const useDebtorOrganizationDetails = () => {
  const {
    debtor,
  } = useInjectDebtorDialog();
  const { t } = useLocalI18n('debtor.common.organization_details');

  const records = computed(() => [{
    ...(debtor.value?.debtor_main_profile || {}),
    ogrn_data_reg:
      new Date(debtor.value?.debtor_main_profile?.organization?.ogrn_data_reg as string)
      || null,
  }]);

  const getBankruptLabel = (bData: Array<OrganizationBankruptData>, name: DebtorMainProfileOrganization['name_short']) => {
    return bData.map((data) => {
      const parseDate = parseDateStringWithDefaultFormat;

      const hasKad = data.sources.indexOf('kad') !== -1;
      const hasEfrsb = data.sources.indexOf('efrsb') !== -1;

      const first = hasEfrsb ? 'является банкротом:\n' : (hasKad ? 'начата процедура банкротства:\n' : '');
      const second = `№${data.case_number}\n`;

      const kad = hasKad ? `КАД от ${parseDate(data.accept_statement_date)}\n` : '';
      const efrsb = hasEfrsb ? `ЕФРСБ от ${parseDate(data.decision_date)}\n` : '';

      const decision = data.decision_date ? `Дата признания банкротом:\n${parseDate(data.decision_date)}\n` : '';
      const sellProp = data.realization_property_date ? `Реализация имущества:\n${parseDate(data.realization_property_date)}\n` : '';
      const complDate = data.bankruptcy_completion_date ? `Дата завершения банкротства:\n${parseDate(data.bankruptcy_completion_date)}\n` : '';

      return `Компания ${name} - ${[first, second, kad, efrsb, decision, sellProp, complDate].join('')}`;
    }).join('\n');
  };

  const {
    columns,
    fetchData: refetchData,
  } = useActiveTable<DebtorOrganization, DebtorOrganization, 'pk'>({
    keyField: 'pk',
    async fetch() {
      return {
        count: 1,
        results: [],
      };
    },
    columns: computed(() => ([
      {
        key: 'name_full',
        field: 'organization.name_full',
      },
      { key: 'ogrn', field: 'organization.ogrn' },
      { key: 'inn', field: 'organization.inn' },
      { key: 'kpp', field: 'organization.kpp' },
      {
        key: 'ogrn_data_reg',
        field: 'ogrn_data_reg',
        format: ActiveTableColumnFormat.date,
      },
      { key: 'full_name', field: 'organization.director_info.director' },
    ].map((column) => ({
      width: 1,
      ...column,
      label: t(`column.${column.key}`),
    })))),
  });

  watch(debtor, () => {
    refetchData();
  });

  return {
    openAuthProtectedFile,
    getBankruptLabel,
    records,
    columns,
    t,
  };
};
