import { Module, Plugin } from 'vuex';
import { AppNotification, FetchNotificationsModel } from '@/hooks/useNotifications';
import { StoreState } from '@/store';
import { ApiResponse } from '@/service/api';
import {
  ApiCommand, ListingRequest, ListingResponse, OrderDirection,
} from '@/store/modules/api';
import { SocketSubscriber } from '@/store/modules/socket';
import { NotificationSocketMessage } from '@/types/socket';
import { formatListingRequest } from '@/components/activeTable/useActiveTable';

export type NotificationsState = {
  notifications: AppNotification[];
  isLoading: boolean;
  isLoaded: boolean;
}

type NotificationModule = Module<NotificationsState, StoreState>;

export const state: NotificationModule['state'] = {
  notifications: [],
  isLoading: true,
  isLoaded: false,
};

export const getters: NotificationModule['getters'] = {
  hasUnread: (state) => state.notifications.some((n) => !n.is_read),
  notifications: (state) => state.notifications,
};

export const namespaced = true;

export const mutations: NotificationModule['mutations'] = {
  setNotifications: (state, notifications: AppNotification[]) => {
    state.notifications = notifications;
    state.isLoading = false;
    state.isLoaded = true;
  },
  pushNotification: (state, notif: AppNotification) => {
    state.notifications.push(notif);
  },
  markAsRead: (state, id: number) => {
    const notification = state.notifications.find((el) => el.id === id);
    if (notification) {
      notification.is_read = true;
    }
  },
};

export const actions: NotificationModule['actions'] = {
  fetchNotifications: async ({ commit, dispatch, rootGetters }, params: FetchNotificationsModel) => {
    const companyId = rootGetters['companies/defaultCompanyId'];
    const response: Promise<ApiResponse<ListingResponse<AppNotification>>> = await dispatch('api/request', {
      command: ApiCommand.notificationsFetchList,
      params: formatListingRequest({
        ...params,
        filters: {
          company: companyId,
        },
      }),
    }, { root: true });

    // @ts-ignore
    if (response.response.status) commit('setNotifications', response.response.results);

    return response;
  },
  markAsRead: async ({ dispatch, commit }, id: number) => {
    commit('markAsRead', id);
    return (await dispatch('api/request', {
      command: ApiCommand.notificationsMarkAsRead,
      params: { id },
    }, { root: true }) as Promise<ApiResponse<ListingResponse<AppNotification>>>);
  },
};

export const plugins: Array<Plugin<StoreState>> = [
  (store) => {
    store.watch((store, getters) => getters['companies/defaultCompanyId'], (newCompany, oldCompany) => {
      if (newCompany) {
        store.dispatch('notifications/fetchNotifications');
      }
    }, { immediate: true });
  },
  (store) => {
    store.dispatch('socket/subscribe', {
      condition: (payload) => payload.data?.event === 'send_notification',
      handler: (payload) => {
        const {
          id, title, content, created_at, company_id,
        } = payload.data;
        if (company_id && company_id !== store.getters['companies/defaultCompanyId']) {
          return;
        }
        store.commit('notifications/pushNotification', {
          id, title, content, created_at,
        });
      },
    } as SocketSubscriber<NotificationSocketMessage>);
  },
];
