import { IToastLevel, useToast } from '@/hooks/useToast';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand } from '@/store/modules/api';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useDialog } from '@/hooks/useDialog';
import { useI18n } from 'vue-i18n';
import { Debtor } from '@/hooks/useDebtors';

export const ArchiveAction = 'archiveDebtors';
export const RemoveAction = 'removeDebtors';

export type RemoveArchiveAction = typeof ArchiveAction | typeof RemoveAction;

export function useRemoveDebtorsHandler() {
  const { t: rootT } = useI18n();
  const {
    confirmDialog,
  } = useDialog();
  const {
    showToast,
  } = useToast();
  const { dispatchSignal } = useSignal();

  const showNonSelectedItemsDialog = async (title: string, key: string) => {
    const { closeDialog } = await confirmDialog({
      key,
      title,
      cancelLabel: rootT('other.ok'),
      withConfirmation: false,
    });
    closeDialog();
  };

  function getRemoveHandler(action: RemoveArchiveAction) {
    const ApiCommandActionMap: Record<string, ApiCommand> = {
      removeDebtors: ApiCommand.removeDebtorData,
      archiveDebtors: ApiCommand.archiveDebtorData,
    };

    console.log(ApiCommandActionMap[action]);
    return async function removeDebtorsHandler({ selectedItems, allSelected, records }: { selectedItems: unknown[]; allSelected: boolean; records: Debtor[]}) {

      function t(part: string) {
        return rootT(`${action}${part}`);
      }
      if (allSelected) {
        await showToast({
          label: rootT('debtorsActions.wholeSelectedNotAllowed'),
          level: IToastLevel.warning,
        });
        return;
      }

      if (!selectedItems.length) {
        await showNonSelectedItemsDialog(t('.notSelected'), action);
        return;
      }

      const confirmResult = await confirmDialog({
        key: action,
        title: t('.dialog.title'),
        message: t('.dialog.message'),
        confirmLabel: t('.dialog.confirmLabel'),
      });
      confirmResult.closeDialog();
      if (!confirmResult.result) {
        return;
      }
      const closeToast = await showToast({
        label: t('.toast.process'),
        level: IToastLevel.info,
        duration: null,
      });
      const results = await Promise.all(
        records.map(({ pk: id }) => commonLegacyApiRequest({
          command: ApiCommandActionMap[action],
          params: { id },
        })),
      );
      await closeToast();
      if (results.every((r) => r.status)) {
        await showToast({
          label: t('.toast.success'),
          level: IToastLevel.success,
        });
      } else {
        await showToast({
          label: t('.toast.failure'),
          level: IToastLevel.danger,
        });
      }
      await dispatchSignal(SignalType.debtorsUpdated);
    };
  }

  return { getRemoveHandler };
}
