import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { useApi } from '@/hooks/useApi';
import { ApiResponseError, ApiResponseSuccess, unwrapListingApiResponse } from '@/service/api';
import { useCompanies } from '@/hooks/useCompanies';
import { UserSettingsResponse } from '@/pages/exchange/integration/dialogs/integrationGosuslugi/useGosuslugi';

export type PochtaIntegrationModel = {
  company_id: number;
  token: string;
  user_token: string;
}

export type AutodorIntegrationModel = {
  company_id: number;
  email: string;
  password: string;
}

export type SendpulseIntegrationModel = {
  send_type: NotificationSendType;
  company_id: number;
  client_id: string;
  secret: string;
  sender_name: string;
}

export type SmsCIntegrationModel = {
  send_type: NotificationSendType;
  company_id: number;
  login: string;
  password: string;
  sender_name: string;
}

export type SmsTrafficIntegrationModel = SmsCIntegrationModel;

export type CompanySettingsSecure = PochtaIntegrationResponse |
  AutodorIntegrationResponse |
  SendpulseIntegrationResponse |
  SmsCIntegrationResponse | {
  id: number;
  key: NotificationType;
};

export type PochtaIntegrationResponse = {
  key: 'pochta_ru';
  id: number;
}

export type AutodorIntegrationResponse = {
  key: 'tskad';
  id: number;
}

export type SendpulseIntegrationResponse = {
  key: 'sendpulse';
  id: number;
}

export type SmsCIntegrationResponse = {
  key: 'smsc';
  id: number;
}

export type SmsTrafficIntegrationResponse = {
  key: 'sms_traffic_sms';
  id: number;
}

export type CompanyReadableUserSettingsSecure<T, Key extends string> = {
  id: number;
  key: Key;
  is_readable: true;
  is_default: boolean;
  is_public: boolean;
  users: number[];
  owner: number|null;
  data: T;
}
export type CompanyUserSettingsSecure<Key extends string> = {
  id: number;
  key: Key;
  is_readable: true;
  is_default: boolean;
  is_public: boolean;
  users: number[];
  owner: number|null;
}

export type CompanyReadableUserSettingEsiaId = CompanyReadableUserSettingsSecure<
  { esia_id: string },
  '_esia_id'
>

export type CompanyReadableUserSettingsSecureModel<T, Key extends string> = Omit<CompanyReadableUserSettingsSecure<
  T, Key
  >, 'data'|'is_default'|'is_public'> & { source: T; set_default: boolean; set_public: boolean }

export type CompanyReadableUserSettingsSecureRolenames = CompanyReadableUserSettingsSecure<
  { rolenames: { short_rolename: string; display_text: string; rolename: string }[] },
  'esia_rolenames'
>
export type CompanyReadableUserSettingsSecureRolename = CompanyReadableUserSettingsSecure<
  { rolename: string },
  'active_esia_rolename'
>

export type CompanyReadableUserSettingsSecureRolenameModel = CompanyReadableUserSettingsSecureModel<
  { rolename: string },
  'active_esia_rolename'
>

export type NotificationSendType = 'sms'|'voice'|'viber'|'email';
export type NotificationOperator = 'sendpulse'|'smsc'|'imobis'|'kvint'|'sms_traffic'|'prostor';

export type NotificationType = `${NotificationOperator}_${NotificationSendType}`

export type EsiaModel = {
  password: string;
  username: string;
}

type SettingsFqn = { // fully qualified name
  company_id: number;
  owner?: number;
}

type SettingFqn = SettingsFqn & {
  key: string;
}

export const useCompanySettingsSecure = () => {
  const { fetchCompanySettings } = useCompanies();
  const request = useApi();

  const createPochtaIntegration = (
    model: PochtaIntegrationModel,
  ) => request<PochtaIntegrationResponse>({
    command: ApiCommand.createCompanySettingsSecure,
    params: {
      company_id: model.company_id,
    },
    data: {
      key: 'pochta_ru',
      source: { ...model },
    },
  });

  const createAutodorIntegration = (
    model: AutodorIntegrationModel,
  ) => request<AutodorIntegrationResponse>({
    command: ApiCommand.createCompanySettingsSecure,
    params: {
      company_id: model.company_id,
    },
    data: {
      key: 'tskad',
      source: { ...model },
    },
  });

  const createSendPulseIntegration = (
    model: SendpulseIntegrationModel,
  ) => request<SendpulseIntegrationResponse>({
    command: ApiCommand.createCompanySettingsSecure,
    params: {
      company_id: model.company_id,
    },
    data: {
      key: `sendpulse_${model.send_type}`,
      source: { ...model },
    },
  });

  const createSmsCIntegration = (
    model: SmsCIntegrationModel,
  ) => request<SmsCIntegrationResponse>({
    command: ApiCommand.createCompanySettingsSecure,
    params: {
      company_id: model.company_id,
    },
    data: {
      key: `smsc_${model.send_type}`,
      source: { ...model },
    },
  });

  const createSmsTrafficIntegration = (
    model: SmsTrafficIntegrationModel,
  ) => request<SmsTrafficIntegrationResponse>({
    command: ApiCommand.createCompanySettingsSecure,
    params: {
      company_id: model.company_id,
    },
    data: {
      key: `sms_traffic_${model.send_type}`,
      source: { ...model },
    },
  });

  const fetchPochtaList = (
    model: { company_id: number },
  ) => request<ListingResponse<CompanySettingsSecure>>({
    command: ApiCommand.fetchCompanySettingsSecure,
    params: {
      company_id: model.company_id,
    },
  }).then(unwrapListingApiResponse).then((r) => {
    if (!r.status) {
      return {
        status: false,
        response: r.response,
      } as ApiResponseError;
    }
    return {
      status: true,
      response: r.response.filter((el) => el.key === 'pochta_ru') as PochtaIntegrationResponse[],
    } as ApiResponseSuccess<PochtaIntegrationResponse[]>;
  });

  const fetchAutodorList = (
    model: { company_id: number },
  ) => request<ListingResponse<CompanySettingsSecure>>({
    command: ApiCommand.fetchCompanySettingsSecure,
    params: {
      company_id: model.company_id,
    },
  }).then(unwrapListingApiResponse).then((r) => {
    if (!r.status) {
      return {
        status: false,
        response: r.response,
      } as ApiResponseError;
    }
    return {
      status: true,
      response: r.response.filter((el) => el.key === 'tskad') as AutodorIntegrationResponse[],
    } as ApiResponseSuccess<AutodorIntegrationResponse[]>;
  });

  const deleteById = (
    { company_id, id }: {
      company_id: number;
      id: number;
    },
  ) => request<undefined>({
    command: ApiCommand.deleteCompanySettingsSecure,
    params: {
      id, company_id,
    },
  });

  const deleteList = (
    model: {
      company_id: number;
      id_list: number[];
    },
  ) => Promise.all(model.id_list.map((id) => request({
    command: ApiCommand.deleteCompanySettingsSecure,
    params: {
      id, company_id: model.company_id,
    },
  }))).then((responses) => {
    if (responses.every((r) => r.status)) {
      return {
        status: true,
        response: undefined,
      } as ApiResponseSuccess<undefined>;
    }
    return {
      status: false,
      response: { detail: 'Не удалось удалить станые настройки пользователя' },
    } as ApiResponseError;
  });

  const checkPochtaIntegrationAvailability = (
    model: { company_id: number },
  ) => Promise.all(
    [
      fetchPochtaList(model),
      fetchCompanySettings(model.company_id),
    ],
  ).then(([pochtaListResponse, companySettingsResponse]) => {
    if (!pochtaListResponse.status && !companySettingsResponse.status) {
      return { status: false, response: null };
    }
    let isAvailable = false;
    if (companySettingsResponse.status) {
      isAvailable = companySettingsResponse.response.pochta_ru_use_urrobot;
    }
    if (pochtaListResponse.status) {
      if (!isAvailable) {
        isAvailable = !!pochtaListResponse.response.length;
      }
    }
    return {
      status: true,
      response: isAvailable,
    };
  });

  const checkAutodorIntegrationAvailability = (
    model: { company_id: number },
  ) => fetchAutodorList(model).then((r) => {
    if (!r.status) {
      return {
        status: false,
        response: r.response as any,
      };
    }
    return {
      status: true,
      response: !!r.response.length,
    };
  });

  const fetchSendPulseList = (
    model: { company_id: number },
  ) => request<ListingResponse<CompanySettingsSecure>>({
    command: ApiCommand.fetchCompanySettingsSecure,
    params: {
      company_id: model.company_id,
    },
  }).then(unwrapListingApiResponse).then((r) => {
    if (!r.status) {
      return {
        status: false,
        response: r.response,
      } as ApiResponseError;
    }
    return {
      status: true,
      response: r.response.filter((el) => el.key === 'sendpulse') as SendpulseIntegrationResponse[],
    } as ApiResponseSuccess<SendpulseIntegrationResponse[]>;
  });

  const fetchSmsCList = (
    model: { company_id: number },
  ) => request<ListingResponse<CompanySettingsSecure>>({
    command: ApiCommand.fetchCompanySettingsSecure,
    params: {
      company_id: model.company_id,
    },
  }).then(unwrapListingApiResponse).then((r) => {
    if (!r.status) {
      return {
        status: false,
        response: r.response,
      } as ApiResponseError;
    }
    return {
      status: true,
      response: r.response.filter((el) => el.key === 'smsc') as SmsCIntegrationResponse[],
    } as ApiResponseSuccess<SmsCIntegrationResponse[]>;
  });

  const fetchList = (
    model: SettingsFqn,
  ) => request<ListingResponse<CompanySettingsSecure>>({
    command: ApiCommand.fetchCompanySettingsSecure,
    params: {
      company_id: model.company_id,
      owner: model.owner,
    },
  }).then(unwrapListingApiResponse);

  const fetchUserSettings = <T>(
    { company_id, owner, key }: SettingFqn,
  ) => request<ListingResponse<T>>({
    command: ApiCommand.fetchCompanySettingsSecure,
    params: {
      company_id,
      key,
      ...(owner ? { owner } : { is_public: true }),
    },
  }).then(unwrapListingApiResponse);

  const fetchUserSetting = <T>(
    { company_id, owner, key }: SettingFqn,
  ) => request<ListingResponse<T>>({
    command: ApiCommand.fetchCompanySettingsSecure,
    params: {
      company_id,
      key,
      ...(owner ? { owner } : { is_public: true }),
    },
  }).then(unwrapListingApiResponse).then(
    (r) => {
      if (!r.status) {
        return r as ApiResponseError;
      }
      return {
        status: true,
        response: (r.response?.[0] as T) ?? null,
      } as ApiResponseSuccess<T>;
    },
  );

  const fetchEsiaUserSetting = async ({
    company_id, owner,
  }: SettingsFqn) => fetchUserSetting<CompanyReadableUserSettingEsiaId>({
    owner,
    company_id,
    key: '_esia_id',
  });

  const fetchUserRolenames = (
    { company_id, owner }: SettingsFqn,
  ) => fetchUserSetting<CompanyReadableUserSettingsSecureRolenames>({
    company_id, owner, key: 'esia_rolenames',
  });

  const fetchUserRolename = (
    { company_id, owner }: SettingsFqn,
  ) => fetchUserSetting<CompanyReadableUserSettingsSecureRolename>({
    company_id, owner, key: 'active_esia_rolename',
  });

  const deleteUserSetting = async (
    { company_id, owner, key }: SettingFqn,
  ) => {
    console.log('delete setting', key);

    const listResponse = await fetchUserSettings({
      company_id, key, owner,
    });

    if (!listResponse.status) {
      return listResponse;
    }

    const deleteResponse = await deleteList({
      company_id,
      id_list: listResponse.response.map((el: any) => el.id),
    });

    return deleteResponse;
  };

  type SaveSettingModel<T, Key extends string> = {
    company_id: number;
    owner?: number;
    key: Key;
    source: T;
    is_readable: boolean;
    is_public?: boolean;
  }

  const saveUserSetting = async <T, Key extends string>(
    {
      company_id, owner, source, key, is_readable, is_public,
    }: SaveSettingModel<T, Key>,
  ) => {
    const listResponse = await fetchUserSettings({
      company_id, key, owner,
    });

    if (!listResponse.status) {
      return listResponse;
    }

    const deleteResponse = await deleteList({
      company_id,
      id_list: listResponse.response.map((el: any) => el.id),
    });

    if (!deleteResponse.status) {
      console.warn('no prev setting found');
    }

    return request<CompanyReadableUserSettingsSecure<T, Key>>({
      command: ApiCommand.createCompanySettingsSecure,
      params: {
        company_id,
      },
      data: {
        key,
        is_readable,
        source,
        ...(owner ? {
          set_default: true,
          set_public: is_public ?? false,
          users: [owner],
        } : {
          set_default: false,
          set_public: is_public ?? true,
          users: [],
        }),
      },
    });
  };

  const saveEsiaUserSetting = async (
    { company_id, owner, model }: { company_id: number; owner?: number; model: EsiaModel },
  ) => saveUserSetting<EsiaModel, 'esia'>({
    company_id, key: 'esia', owner, source: model, is_readable: false,
  });

  const saveUserRolename = async (
    { company_id, owner, rolename }: { company_id: number; owner?: number; rolename: string },
  ) => saveUserSetting<{ rolename: string }, 'active_esia_rolename'>({
    company_id, key: 'active_esia_rolename', owner, source: { rolename }, is_readable: true,
  });

  const ownerParams = (owner: unknown) => (owner ? {
    set_default: true,
    set_public: false,
    users: [owner],
  } : {
    set_default: false,
    set_public: true,
    users: [],
  });

  const sendEsiaExtraInput = ({
    company_id, owner, user_input,
  }: {
    company_id: number; owner?: number; user_input: string;
  }) => request<UserSettingsResponse>({
    command: ApiCommand.createCompanySettingsSecure,
    params: {
      company_id,
    },
    data: {
      key: '_esia_user_input',
      source: { user_input },
      ...ownerParams(owner),
    },
  });

  return {
    createPochtaIntegration,
    createAutodorIntegration,
    createSendPulseIntegration,
    createSmsCIntegration,
    createSmsTrafficIntegration,
    checkPochtaIntegrationAvailability,
    checkAutodorIntegrationAvailability,

    fetchPochtaList,
    fetchAutodorList,
    fetchSendPulseList,
    fetchSmsCList,
    deleteList,
    fetchList,

    saveUserSetting,
    fetchUserSettings,
    fetchUserSetting,
    deleteUserSetting,

    saveEsiaUserSetting,
    fetchEsiaUserSetting,
    fetchUserRolenames,
    fetchUserRolename,
    saveUserRolename,
    sendEsiaExtraInput,
  };
};
