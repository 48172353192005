export type VehiclePaymentCode = 1|2|3|10

export type VehiclePaymentSumType = 'sum_1' | 'sum_2' | 'sum_3' | 'sum_10'

export type VehiclePaymentType = {
  label: string;
  value: VehiclePaymentCode;
  total_name: string;
}

// TODO получать через API
export const VEHICLE_PAYMENT_CODES = ({
  1: {
    label: 'Взыскание платы невозможно',
    value: 1,
    total_name: 'sum_1',
  },
  2: {
    label: 'Проезд по ГРН',
    value: 2,
    total_name: 'sum_2',
  },
  3: {
    label: 'Транспондер',
    value: 3,
    total_name: 'sum_3',
  },
  10: {
    label: 'Проезд нарушителя (оплата отсутствует)',
    value: 10,
    total_name: 'sum_10',
  },
}) as Record<VehiclePaymentCode, VehiclePaymentType>;
