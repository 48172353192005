// выполняет асинхронные такси пачками, после каждой пачки вызывает каллбек
export const getTaskByChunksExecutor = (chunkSize = 100) => {
  let runningTasksCounter = 0;
  const awaiting: Array<() => Promise<void>> = [];
  const runInChunks = <T>(fn: () => Promise<T>, onFinishedChunkCallback: () => Promise<void>) => new Promise<T>((resolve) => {
    const task = async () => {
      const result = await fn();
      resolve(result);
      runningTasksCounter--;
      console.log('task executed, remaining tasks', runningTasksCounter);
      if (!runningTasksCounter) {
        console.log('all tasks executed => run onFinishedChunkCallback');
        await onFinishedChunkCallback();
        const newTasks = awaiting.splice(0, awaiting.length < chunkSize ? awaiting.length : chunkSize);
        newTasks.forEach((task) => {
          runningTasksCounter++;
          task();
        });
      }
    };

    if (runningTasksCounter >= chunkSize) {
      awaiting.push(task);
      return;
    }
    runningTasksCounter++;
    task();
  });

  return runInChunks;
};
