import { Ref, watch, ref } from 'vue';
import { getAuthProtectedFileUrl } from '@/utils/fileUrl';

export type nString = string | null;

export async function prepareLinksSimple(value: string): Promise<{ view: nString; download: nString }> {
  return {
    view: await getAuthProtectedFileUrl(value, false),
    download: await getAuthProtectedFileUrl(value, true),
  };
}

export function prepareLinks(fileUrl: Ref<string|null|undefined>) {
  const download = useAuthProtectedFile(fileUrl, true);
  const view = useAuthProtectedFile(fileUrl, false);
  return { view, download };
}

export const useAuthProtectedFile = (
  link: Ref<string|null|undefined>,
  download = false,
) => {
  const preparedLink = ref<string|null>(null);

  watch(link, async (value) => {
    if (value) {
      const prep = await getAuthProtectedFileUrl(value, download);
      preparedLink.value = prep ?? null;
    } else {
      preparedLink.value = null;
    }
  }, { immediate: true });

  return preparedLink;
};
