import { useStore } from 'vuex';
import { ProgressTask } from '@/store/modules/tasksProgress';
import { computed } from 'vue';

export const useTaskProgress = () => {
  const store = useStore();

  return {
    startTaskProgress: (
      task: ProgressTask,
    ): Promise<void> => store.dispatch(
      'tasksProgress/startTask',
      { task, restore: false },
    ),
    progressTasks: computed(() => store.state.tasksProgress.tasks),
  };
};
