import { useModelDialog } from '@/hooks/useModelDialog';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { mbToBites } from '@/hooks/useFileManager';
import { ErrorsMap } from '@/hooks/useErrors';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import {
  ApiResponseError,
  ApiResponseSuccess,
} from '@/service/api';
import {
  DocumentDto,
  UpdateModel,
  useLoanAgreementsApi,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useLoanAgreementsApi';

type Options = {
  create: string;
  edit: string;
}

export const useLoanAgreements = (options: Options) => {

  const { debtorId, productionType } = useInjectDebtorDialog();
  const { createLoanAgreement, updateLoanAgreement, fetchLoanAgreement } = useLoanAgreementsApi();

  const fields = [
    {
      key: 'file',
      field: 'file',
      type: ActiveFormFieldType.file,
      options: {
        label: 'Файл',
        state: ['primary', 'short'],
        maxSize: mbToBites(30),
      },
    },
  ];

  const modelDialogCreate = useModelDialog<
    UpdateModel,
    DocumentDto
    >({
      key: 'add_loan_agreement',
      title: options.create,
      // @ts-ignore
      fields,
      onModelUpdate: async (payload) => {
        return upsertLoanAgreement(payload);
      },
    });

  const modelDialogUpdate = useModelDialog<
    UpdateModel,
    DocumentDto,
    number
    >({
      key: 'edit_loan_agreement',
      title: options.edit,
      // @ts-ignore
      fields,
      getForm: (id) => fetchLoanAgreement({
        filters: {
          id,
        },
      }),
      onModelUpdate: async (payload, id) => {
        return upsertLoanAgreement(payload, id);
      },
    });

  function upsertLoanAgreement(payload: UpdateModel, id?: number) {

    const { errorsMap, hasErrors } = Object.entries(payload).reduce(
      (acc, [key, value]) => {
        if (['document_number', 'document_amount', 'document_date', 'file'].includes(key)) {
          if (!value) {
            acc.errorsMap[key] = ['Поле обязательно'];
            acc.hasErrors = true;
          }
        }
        return acc;
      }, {
        errorsMap: {},
        hasErrors: false,
      } as { errorsMap: ErrorsMap<any>; hasErrors: boolean},
    );

    if (hasErrors) {
      return {
        status: false,
        response: errorsMap,
      } as ApiResponseError;
    }

    const { file, ...restPayload } = payload;

    if (!id) {
      return createLoanAgreement({
        ...payload,
        debtor: debtorId.value,
        production_type: productionType.value,
        is_loaded_manually: true,
      }) as Promise<ApiResponseSuccess<DocumentDto>>;
    }

    return updateLoanAgreement({
      ...restPayload,
      ...(typeof file !== 'string' ? { file } : {}),
      debtor: debtorId.value,
      production_type: productionType.value,
    }) as Promise<ApiResponseSuccess<DocumentDto>>;
  }

  return {
    openCreateDialog: modelDialogCreate.showDialog,
    openUpdateDialog: modelDialogUpdate.showDialog,
  };
};
