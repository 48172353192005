import { computed } from 'vue';
import { t } from '@/i18n/index';
import { Phone, Tenant } from '@/hooks/useDebtors';

export type TenantId = number | string; // string for non-saved tenant

export type ClientTenant = Omit<Tenant, 'id'> & {
  id: TenantId;
}

export type HasPhones = {
  id: number;
  phone_numbers: Phone[];
}

export const registrationOptions = computed(() => (
  [
    { value: 1, label: t('debtor.common.tenants.registration.permanent') },
    { value: 2, label: t('debtor.common.tenants.registration.temporary') }]
));

export const isPassportNumber = (value: any) => {
  if (value == null || value === undefined) {
    return false;
  }

  const numberArr = String(value).replaceAll(' ', '').split('');
  if (numberArr.length !== 10) {
    return false;
  }
  return numberArr.every(
    (v) => Number.isInteger(+v) && !Number.isNaN(+v),
  );
};
