import { useModelDialog } from '@/hooks/useModelDialog';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { mbToBites } from '@/hooks/useFileManager';
import { ErrorsMap } from '@/hooks/useErrors';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import {
  ApiResponseError,
  ApiResponseSuccess,
} from '@/service/api';
import {
  ClaimDto,
  ClaimUpdateModel,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useClaimsApi';

type Options = {
  create: string;
  edit: string;
  api: () => any;
  confirmationFile: boolean;
}

export const useClaims = (options: Options) => {

  const { debtorId, productionType } = useInjectDebtorDialog();
  const { createClaim, updateClaim, fetchClaim } = options.api();

  const fields = [
    {
      key: 'file',
      field: 'file',
      type: ActiveFormFieldType.file,
      options: {
        label: 'Файл',
        state: ['primary', 'short'],
        maxSize: mbToBites(30),
      },
    },
  ];
  if (options.confirmationFile) {
    fields.push({
      key: 'confirmation_file',
      field: 'confirmation_file',
      type: ActiveFormFieldType.file,
      options: {
        label: 'Файл подтверждения',
        state: ['primary', 'short'],
        maxSize: mbToBites(30),
      },
    });
  }

  const modelDialogCreate = useModelDialog<
    ClaimUpdateModel,
    ClaimDto
    >({
      key: 'add_claim',
      title: options.create,
      // @ts-ignore
      fields,
      onModelUpdate: async (payload) => {
        return upsertClaim(payload);
      },
    });

  const modelDialogUpdate = useModelDialog<
    ClaimUpdateModel,
    ClaimDto,
    number
    >({
      key: 'update_claim',
      title: options.edit,
      // @ts-ignore
      fields,
      getForm: (id) => fetchClaim({
        id,
        debtor: debtorId.value,
      }),
      onModelUpdate: async (payload, id) => {
        return upsertClaim(payload, id);
      },
    });

  function upsertClaim(payload: ClaimUpdateModel, id?: number) {

    const { errorsMap, hasErrors } = Object.entries(payload).reduce(
      (acc, [key, value]) => {
        if (['document_number', 'document_amount', 'document_date'].includes(key)) {
          if (!value) {
            acc.errorsMap[key] = ['Поле обязательно'];
            acc.hasErrors = true;
          }
        }
        return acc;
      }, {
        errorsMap: {},
        hasErrors: false,
      } as { errorsMap: ErrorsMap<any>; hasErrors: boolean},
    );

    if (hasErrors) {
      return {
        status: false,
        response: errorsMap,
      } as ApiResponseError;
    }

    const { file, confirmation_file, ...restPayload } = payload;

    if (!id) {
      return createClaim({
        ...restPayload,
        ...(file ? { file } : {}),
        ...(confirmation_file ? { confirmation_file } : {}),
        debtor: debtorId.value,
        production_type: productionType.value,
        is_loaded_manually: true,
      }) as Promise<ApiResponseSuccess<ClaimDto>>;
    }

    return updateClaim({
      ...restPayload,
      ...(typeof file === 'string' ? {} : file ? { file } : {}),
      ...(typeof confirmation_file === 'string' ? {} : confirmation_file ? { confirmation_file } : {}),
      debtor: debtorId.value,
      production_type: productionType.value,
    }) as Promise<ApiResponseSuccess<ClaimDto>>;
  }

  return {
    openCreateDialog: modelDialogCreate.showDialog,
    openUpdateDialog: modelDialogUpdate.showDialog,
  };
};
