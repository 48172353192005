import { Ref } from 'vue';
import { AutoTabKey, PretrialAutomationModel } from '@/components/automation/pretrial/tabs/types';
import { fieldsUniter } from '@/components/automation/pretrial/tabs/utils';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';

export const getCommonPFields = (model: Ref<PretrialAutomationModel>, rootT: (s: string) => string) => {

  return fieldsUniter(AutoTabKey.commonP, [
    {
      key: 'auto_court_transfer',
      label: '',
      type: ActiveFormFieldType.checkbox,
    },
    {
      key: 'court_days_overdue_enabled',
      field: 'court_days_overdue_enabled',
      label: '',
      options: {
        isDisabled: !model.value.auto_court_transfer,
      },
      type: ActiveFormFieldType.checkbox,
      onUpdateModelValue: (model: PretrialAutomationModel, value: boolean) => {
        model.court_days_overdue = value ? 1 : null;
      },
    },
    {
      key: 'court_days_overdue',
      field: 'court_days_overdue',
      label: '',
      type: ActiveFormFieldType.select,
      options: {
        isDisabled: !model.value.auto_court_transfer || !model.value.court_days_overdue_enabled,
        placeholder: rootT('other.choose'),
        allowEmpty: false,
        state: ['primary'],
        options: [
          ...[1, 5, 10, 15, 20, 25].map((days) => ({
            label: `дней: ${days}`,
            value: days,
          })),
          ...new Array(12).fill(null).map((_, i) => ({
            label: `месяцев: ${i + 1}`,
            value: (i + 1) * 30,
          })),
        ],
      },
    },
    {
      key: 'court_threshold_enabled',
      label: '',
      options: {
        isDisabled: !model.value.auto_court_transfer,
      },
      type: ActiveFormFieldType.checkbox,
      onUpdateModelValue: (model: PretrialAutomationModel, value: boolean) => {
        if (!value) {
          model.court_threshold = 0;
        }
      },
    },
    {
      key: 'court_threshold',
      label: '',
      type: ActiveFormFieldType.range,
      options: {
        isDisabled: !model.value.auto_court_transfer || !model.value.court_threshold_enabled,
        min: 0,
        max: 10000,
        withManualInput: true,
      },
      value: model.value.court_threshold ?? 0,
      onUpdateModelValue: (model: any, value: boolean) => {
        if (!value) {
          model.court_threshold = 0;
        }
      },
    },
  ]);
};
