import { DebtorPhoneNumber } from '@/hooks/useDebtors';
import { useApi } from '@/hooks/useApi';
import { ApiCommand } from '@/store/modules/api';
import { ProductionType } from '@/hooks/useConstructor';

export type DebtorPhoneNumberCreateModel = Pick<
  DebtorPhoneNumber, 'number'|'is_valid'|'debtor_main_profile'|'debtor_tenant_profile'
  > & { production_type: ProductionType; debtor_id: number };

export const useDebtorPhoneNumbersApi = () => {
  const request = useApi();
  return {
    create: (model: DebtorPhoneNumberCreateModel) => request<DebtorPhoneNumber>({
      command: ApiCommand.debtorPhoneNumberCreate,
      data: model,
    }),
    update: (
      model: Partial<DebtorPhoneNumber> & { id: DebtorPhoneNumber['id']; production_type: ProductionType; debtor_id: number },
    ) => request<DebtorPhoneNumber>({
      command: ApiCommand.debtorPhoneNumberUpdate,
      data: model,
      params: { id: model.id },
    }),
    delete: (id: number, production_type: ProductionType, debtor_id: number) => request<boolean>({
      command: ApiCommand.debtorPhoneNumberDelete,
      params: { id, production_type, debtor_id },
    }),
  };
};
