import { Ref } from 'vue';

export enum ITextInputState {
  default = 'default'
}

export enum ITextInputType {
  text = 'text',
  number = 'number',
  textarea = 'textarea',
  password = 'password',
}

export type ITextInputNumberOptions = { min?: number; max?: number; step?: string }

export type ITextInput = {
  modelValue?: string;
  placeholder?: string;
  type?: ITextInputType;
  state?: ITextInputState | Array<ITextInputState>;
  errors?: string[];
  label?: string;
  hint?: string;
  isDisabled?: boolean|Ref<boolean>;
  numberOptions?: ITextInputNumberOptions;
  withSplitStringOnPaste?: boolean;
  id?: string;
}
