import { ref, SetupContext } from 'vue';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useSettingsCommon } from '@/pages/exchange/integration/dialogs/useSettingsCommon';
import { PikUpdatePayload, Model } from '@/pages/debtors/_module/tableActions/pikUpdate/types';
import { key } from '@/pages/exchange/integration/billing/Pik.vue';

export const usePikUpdateDialog = (props: {
  onSave: PikUpdatePayload['onSave'];

}, { emit }: SetupContext<'close'[]>) => {

  const setting = ref<Model>();
  const { companyModuleType, companyId } = useProtectedDefaultCompany();

  useSettingsCommon({
    settingKey: key,
    companyId,
    model: setting,
    useOwner: false,
  });

  const reportTypeOptions = [
    {
      value: 1,
      label: 'Расширенная выписка из ЛС по должникам с пени',
    },
    {
      value: 2,
      label: 'Расчет пени с пени',
    },
    {
      value: 4,
      label: 'Расширенная выписка из ЛС для_ДВЗ',
    },
  ];

  const model = ref<Model>({
    date_from: new Date(),
    date_to: new Date(),
    report_type_id: 1,
  });

  const submit = () => {
    props.onSave({
      ...model.value,
    });
    emit('close');
  };

  return {
    model,
    reportTypeOptions,
    submit,
    companyModuleType,
  };
};
