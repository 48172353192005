import { Ref } from 'vue';
import { AutoTabKey, PretrialAutomationModel } from '@/components/automation/pretrial/tabs/types';
import { fieldsUniter } from '@/components/automation/pretrial/tabs/utils';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';

export const getTextFields = (model: Ref<PretrialAutomationModel>, rootT: (s: string) => string) => {

  return fieldsUniter(AutoTabKey.text, [
    {
      key: 'auto_create_sms_notifications',
      field: 'auto_create_sms_notifications',
      label: '',
      type: ActiveFormFieldType.checkbox,
      state: ['primary', 'horizontal'],
      options: {
        placeholder: null,
        allowEmpty: false,
        state: ['primary', 'horizontal'],
        options: [
          { value: true, label: rootT('other.yes') },
          { value: false, label: rootT('other.no') },
        ],
      },
    },
    {
      key: 'debt_notifications_period_sms',
      type: ActiveFormFieldType.schedule,
      state: ['primary'],
      options: {
        state: ['primary'],
        isDisabled: !model.value.auto_create_sms_notifications,
      },
    },
    {
      key: 'voice_send_sms_if_unavailable',
      field: 'voice_send_sms_if_unavailable',
      label: '',
      type: ActiveFormFieldType.checkbox,
      state: ['primary', 'horizontal'],
      options: {
        placeholder: null,
        isDisabled: !model.value.auto_create_sms_notifications,
        allowEmpty: false,
        state: ['primary', 'horizontal'],
        options: [
          { value: true, label: rootT('other.yes') },
          { value: false, label: rootT('other.no') },
        ],
      },
    },
  ]);
};
