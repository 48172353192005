import { computed, ComputedRef, Ref } from 'vue';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { mbToBites } from '@/hooks/useFileManager';
import { useModelDialog } from '@/hooks/useModelDialog';
import {
  useDocumentsApi,
  IdentityDocument,
  CreateIdentityDocumentModel,
  UpdateIdentityDocumentModel,
} from '@/hooks/useDocumentsApi';
import { ApiResponse, ApiResponseError, ApiResponseSuccess } from '@/service/api';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { ErrorsMap } from '@/hooks/useErrors';
import { ProductionType } from '@/hooks/useConstructor';
import { IToastLevel, useToast } from '@/hooks/useToast';

export const useDebtorIdentityModel = (debtorId: ComputedRef<number>, productionType: Ref<ProductionType>, activeTab: Ref<string>) => {
  const { t } = useLocalI18n('dialogs.identity');
  const { showToast } = useToast();
  const {
    fetchIdentityDocument,
    createIdentityDocument,
    updateIdentityDocument,
    fetchBCIdentityDocument,
    createBCIdentityDocument,
    updateBCIdentityDocument,
    fetchInnIdentityDocument,
    createInnIdentityDocument,
    updateInnIdentityDocument,
  } = useDocumentsApi();
  const fields = computed(() => [
    {
      key: 'file',
      field: 'file',
      type: ActiveFormFieldType.file,
      options: {
        label: t('fields.file'),
        state: ['primary', 'short'],
        maxSize: mbToBites(30),
      },
    },
  ]);

  const fetchDocument = (tab: string, id: number) => {
    switch (tab) {
      case 'identity':
        return fetchIdentityDocument(id);
      case 'bc_identity':
        return fetchBCIdentityDocument(id);
      case 'inn_identity':
        return fetchInnIdentityDocument(id);
      default:
    }
  };

  const createDocument = (tab: string, payload: CreateIdentityDocumentModel) => {
    switch (tab) {
      case 'identity':
        return createIdentityDocument(payload);
      case 'bc_identity':
        return createBCIdentityDocument(payload);
      case 'inn_identity':
        return createInnIdentityDocument(payload);
      default:
    }
  };

  const updateDocument = (tab: string, payload: UpdateIdentityDocumentModel) => {
    switch (tab) {
      case 'identity':
        return updateIdentityDocument(payload);
      case 'bc_identity':
        return updateBCIdentityDocument(payload);
      case 'inn_identity':
        return updateInnIdentityDocument(payload);
      default:
    }
  };

  const modelDialogCreate = useModelDialog<
    CreateIdentityDocumentModel,
    IdentityDocument
  >({
    key: `debtor_${activeTab.value}_add`,
    title: t('title.create'),
    // @ts-ignore
    fields,
    onModelUpdate: async (payload) => {
      const { status, response } = await createOrEditIdentityDocument(payload, activeTab.value);
      if (!status) {
        await showToast({
          level: IToastLevel.danger,
          label: t('toasts.error'),
        });
      } else {
        await showToast({
          level: IToastLevel.success,
          label: t('toasts.success.create'),
        });
      }
      return {
        status,
        response,
      };
    },
  });

  const modelDialogUpdate = useModelDialog<
    UpdateIdentityDocumentModel,
    IdentityDocument,
    number
  >({
    key: `debtor_${activeTab.value}_edit`,
    title: t('title.edit'),
    // @ts-ignore
    fields,
    // @ts-ignore
    getForm: async (id: IdentityDocument['id']) => fetchDocument(activeTab.value, id),
    onModelUpdate: async (payload: UpdateIdentityDocumentModel, id: UpdateIdentityDocumentModel['id']) => {
      const { status, response } = await createOrEditIdentityDocument(payload, activeTab.value, id);
      if (!status) {
        await showToast({
          level: IToastLevel.danger,
          label: t('toasts.error'),
        });
      } else {
        await showToast({
          level: IToastLevel.success,
          label: t('toasts.success.edit'),
        });
      }
      return {
        status,
        response,
      };
    },
  });

  function createOrEditIdentityDocument(payload: CreateIdentityDocumentModel | UpdateIdentityDocumentModel, tab: string, id?: UpdateIdentityDocumentModel['id']) {

    const { errorsMap, hasErrors } = Object.entries(payload).reduce(
      (acc, [key, value]) => {
        if (['file'].includes(key)) {
          if (!value) {
            acc.errorsMap[key] = ['Поле обязательно'];
            acc.hasErrors = true;
          }
        }
        return acc;
      }, {
        errorsMap: {},
        hasErrors: false,
      } as { errorsMap: ErrorsMap<any>; hasErrors: boolean},
    );

    if (hasErrors) {
      return {
        status: false,
        response: errorsMap,
      } as ApiResponseError;
    }

    type Keys = keyof typeof payload;

    // @ts-ignore
    const prepPayload: UpdateIdentityDocumentModel = Object.fromEntries(
      (Object.keys(payload) as Keys[]).filter(
        (k) => (payload[k] !== null && ['file'].includes(k) ? typeof payload[k] !== 'string' : true),
      ).map((k) => ([
        k, payload[k],
      ])),
    );

    if (id) {
      return updateDocument(tab, prepPayload) as Promise<ApiResponse<IdentityDocument>>;
    }

    const createPrepPayload = {
      ...payload,
      debtor: debtorId.value,
      production_type: productionType.value,
    } as CreateIdentityDocumentModel;

    return createDocument(tab, createPrepPayload) as Promise<ApiResponse<IdentityDocument>>;
  }

  return {
    openCreateDialog: modelDialogCreate.showDialog,
    openUpdateDialog: modelDialogUpdate.showDialog,
  };
};
