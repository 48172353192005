import {
  defineComponent, h, ref, toRefs, watch, PropType, computed, onMounted,
} from 'vue';
import debounce from 'lodash/debounce';
import SelectInput from '@/components/selectInput/SelectInput.vue';
import { ISelectInput } from '@/components/selectInput/useSelectInput';
import { arrayFrom } from '@/utils/object';

export type SearchQuery = (search?: string) => Promise<ISelectInput['options']>

export const SearchSelectInput = defineComponent({
  props: {
    searchQuery: {
      required: true,
      type: Function as PropType<SearchQuery>,
    },
    prefetch: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    fetchOnFocus: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    displayField: {
      default: 'label',
      type: String as PropType<string>,
    },
    valueField: {
      default: 'value',
      type: String as PropType<string>,
    },
    isAutocomplete: {
      type: Boolean as PropType<boolean>,
    },
    isSearchOnly: {
      type: Boolean as PropType<boolean>,
    },
    disableFilter: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      required: true,
    },
    selectFirstIfEmpty: {
      type: Boolean,
    },
    id: {
      type: String as PropType<string>,
    },
  },
  emits: ['update:modelValue'],
  setup(props, {
    attrs, slots, emit, expose,
  }) {
    const { searchQuery, modelValue } = toRefs(props);
    const selectRef = ref();
    const results = ref<any[]>([]);
    const query = ref<string|null>('');
    const queryUpdated = ref(false);

    const localResults = computed(
      () => {
        if (props.disableFilter) {
          return results.value;
        }
        return (query.value
          ? results.value.filter(
            (v) => arrayFrom(props.displayField)
              .some((displayKey) => v[displayKey]?.toLowerCase?.()?.includes((query.value ?? '').toLowerCase?.())
                || v[props.valueField]?.toLowerCase?.()?.includes((query.value ?? '').toLowerCase?.())),
          )
          : results.value);
      },
    );

    const isLoaded = ref(false);
    const isSearching = ref(false);
    const isFocused = ref(false);

    watch(modelValue, (val) => {
      if (props.isAutocomplete) {
        query.value = (val as string) || null;
      }
    }, { immediate: true });

    const selectFirst = () => {
      if (results.value.length) {
        if ((Array.isArray(modelValue.value) && !modelValue.value.length) || !modelValue.value) {
          // @ts-ignore
          selectRef.value.selectOption(results.value[0]);
        }
      }
    };

    const search = async (search = '') => {
      query.value = search;
      queryUpdated.value = true;
      isLoaded.value = false;
      isSearching.value = true;
      const response = await searchQuery.value(search);
      isLoaded.value = true;
      results.value = response || [];
      if (props.selectFirstIfEmpty) {
        selectFirst();
      }
      isSearching.value = false;
    };

    const onQueryUpdate = debounce(search, 500);

    const onUpdateModelValue = (v: any) => {
      query.value = null;
      emit('update:modelValue', v);
    };

    const searchText = computed(() => {
      if (isSearching.value) return 'Загрузка..';
      if (!props.prefetch) {
        if (isSearching.value) return 'Загрузка..';
        if (isLoaded.value && !localResults.value.length) return 'Ничего не найдено';
        if (isFocused.value && !localResults.value.length) return 'Начните вводить';
      } else if (props.isAutocomplete) {
        if (!results.value?.length) return 'Ничего не найдено';
        if (!query.value && !props.prefetch) return 'Начните вводить';
      } else {
        if (isLoaded.value && !localResults.value.length) return 'Ничего не найдено';
      }
      return null;
    });

    const onFocus = async () => {
      isFocused.value = true;
      if (props.fetchOnFocus) {
        await search();
      }
    };

    onMounted(async () => {
      if (props.prefetch) {
        await search();
      }
    });

    const onBlur = () => {
      isFocused.value = false;
      if (props.isSearchOnly) {
        return;
      }
      if (props.isAutocomplete && query.value !== null && query.value !== undefined && queryUpdated.value && query.value !== modelValue.value) {
        emit('update:modelValue', query.value);
      }
    };

    const placeholder = computed(
      () => (props.isAutocomplete ? (query.value || attrs.placeholder) : attrs.placeholder),
    );
    return () => h(
      // @ts-ignore
      SelectInput,
      {
        ...props,
        ...attrs,
        modelValue: modelValue.value,
        options: localResults.value,
        isSearchable: true,
        'onUpdate:modelValue': onUpdateModelValue,
        query: query.value,
        placeholder: placeholder.value,
        'onUpdate:query': onQueryUpdate,
        onFocus,
        onBlur,
        ref: selectRef,
      },
      {
        ...slots,
        dropdownBefore: () => (searchText.value
          ? h('div', { style: { padding: '6px', fontSize: '0.8rem', opacity: 0.7 } }, [searchText.value])
          : null),
      },
    );
  },
});
