import {
  computed, ref, Ref,
} from 'vue';
import { useDialogRouteParam } from '@/hooks/useDialog';
import { ITab } from '@/components/tabs/useTabs';
import {
  Accruals,
  ActiveServiceTab,
  AllServices,
  FilterTabsValues,
  FilterTabValue,
  FullFinanceData,
  Saldo,
} from '@/components/dialog/dialogs/debtor/tabs/finance/types';
import { ActiveTableColumn } from '@/components/activeTable/useActiveTable';

function capitalize(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

type UseFilterSignature = (data: Ref<FullFinanceData>, columns: Ref<ActiveTableColumn<any>[]>) => {
  serviceTypes: Ref<Array<ITab<ActiveServiceTab>>>;
  activeServiceType: Ref<ActiveServiceTab>;
  filteredColumns: Ref<Array<ActiveTableColumn<any>>>;
}

/**
 * Фильтрует данные по типу поставщика
 * "Все услуги", "кап. ремонт" и .п.
 */
export const useServiceTypeFilter: UseFilterSignature = (data, columns) => {
  const values = computed<FilterTabsValues>(() => [
    ...getServicesFilterValues(data.value),
  ]);

  const tabs = computed<Array<ITab>>(() => values.value.map((value) => ({
    key: value,
    label: capitalize(value),
  })));

  const [activeTab] = useDialogRouteParam<string>(
    'finance-filter-tab',
    AllServices,
    ref(true),
  );

  return {
    serviceTypes: tabs,
    activeServiceType: activeTab,
    filteredColumns: computed(() => columns.value.filter((column) => showColumn(column, activeTab))),
  };
};

export const showColumn = (column: ActiveTableColumn<any>, activeServiceType: Ref<FilterTabValue>) => {
  const { label, key } = column;
  if (label === 'Дата' || label === 'Месяц оплаты' || key === Saldo) {
    return true;
  }
  if (activeServiceType.value === AllServices) {
    return true;
  }
  if (label === 'Пеня' || label === 'Месяц оплаты') {
    return false;
  }
  return String(key).includes(activeServiceType.value);
};

const anyRegex = /.*/;
/**
 * Получает значения типов поставщиков
 */
function getServicesFilterValues(data: FullFinanceData) {
  const map = data;
  const firstRecord = map[Accruals] ? map[Accruals][0] : null;
  if (firstRecord) {
    return Object.keys(firstRecord)
      .filter((value) => !(value === 'id' || value === 'date' || value === Saldo))
      .map((v) => {
        const array = v.match(anyRegex);
        if (array) {
          return array[0];
        }
        return null;
      }).filter((v) => !!v) as string[];
  }
  return [];
}
