import { ProductionType } from '@/hooks/useConstructor';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { computed, Ref } from 'vue';

export enum PermissionType {
  pretrial = 'pretrial',
  judicial = 'judicial',
  executive = 'executive',
  general_info = 'general_information',
  billing_and_transactions = 'account_transaction',
  rates_and_services = 'rate_service',
  organizations = 'organizations',
  data_exchange_integrations = 'integration',
  data_exchange_upload_report = 'report',
}

export const permissionRouteMap: Record<string, PermissionType> = {
  'panel-index': PermissionType.general_info,
  'panel-accounts': PermissionType.billing_and_transactions,
  'panel-tariffs': PermissionType.rates_and_services,
  'exchange-export': PermissionType.data_exchange_upload_report,
  'exchange-integration': PermissionType.data_exchange_integrations,
} as const;

export const useDefaultCompanyPermissions = (permissionType: Ref<PermissionType|ProductionType>) => {
  const {
    permissionsMap,
  } = useDefaultCompany();

  const hasWritePermission = computed(
    () => permissionsMap.value[permissionType.value] === 'write',
  );

  const hasReadPermission = computed(
    () => permissionsMap.value[permissionType.value] === 'write',
  );

  return {
    permissionsMap, hasReadPermission, hasWritePermission,
  };
};
