import {
  DashboardPatchModel,
  modelToApiDataMap,
  ReportingModels,
  TilePatchModel,
  TilePostModel,
} from '@/service/api/reporting/models';
import {
  ReportingResponses,
  ReportingResponsesRaw,

} from '@/service/api/reporting/responses';
import { ApiResponse, ApiResponseSuccess } from '@/service/api/index';
import { ApiCommand } from '@/service/api/reporting/apiCommand';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';
import { responsesMappers } from '@/service/api/reporting/response-mappers';
import { ApiCommandConfig, ApiMethod } from '@/service/api-types';

const mapModelToParams = <T extends Record<any, any>|undefined>(
  model: T|undefined,
  urlParams: Array<keyof T>,
) => {
  if (!model) return { data: {}, params: {} };
  const { data, params } = Object.entries(model).reduce((acc, [key, value]) => {
    // @ts-ignore
    if (urlParams.includes(key)) acc.params[key] = value;
    else acc.data[key] = value;
    return acc;
  }, { data: {}, params: {} } as { data: Record<string, any>; params: Record<any, any> });
  return { data, params };
};

export type ModelToResponse<Command extends ApiCommand> = (
  model: ReportingModels[Command]
) => Promise<ApiResponse<ReportingResponses[Command]>>

const getRequest = <C extends ApiCommand>(
  command: C,
  urlParams: Array<keyof Partial<ReportingModels[C]>>,
) => (model: ReportingModels[C]) => {

    const modelToData = modelToApiDataMap[command];
    const prepareResponse = responsesMappers[command];
    const { params } = mapModelToParams<ReportingModels[C]>(model, urlParams);
    return commonLegacyApiRequest<ReportingResponsesRaw[C]>({
      command,
      params,
      // @ts-ignore
      data: model ? modelToData ? modelToData(model) : model : undefined,
    }).then((response) => {
      if (!response.status) return response;

      return {
        status: true,
        // @ts-ignore
        response: prepareResponse ? prepareResponse(response.response) : response.response,
      } as ApiResponseSuccess<ReportingResponses[C]>;
    });
  };

  type ApiCommandsMap = {
    [command in ApiCommand]: ApiCommandConfig & {
      request: ModelToResponse<command>;
    }
  }

export const apiCommands: ApiCommandsMap = {
  [ApiCommand.availableModelsGet]: {
    method: ApiMethod.get,
    url: '/reporting/available_models/',
    request: getRequest(ApiCommand.availableModelsGet, []),
  },
  [ApiCommand.availableModelsGetSchema]: {
    method: ApiMethod.get,
    url: '/reporting/available_models/{name}',
    request: getRequest(ApiCommand.availableModelsGetSchema, ['name']),
  },
  [ApiCommand.dashboardGetList]: {
    method: ApiMethod.get,
    url: '/reporting/{company_id}/group/',
    // @ts-ignore
    request: getRequest(
      ApiCommand.dashboardGetList, ['company_id'],
    ),
  },
  [ApiCommand.dashboardGet]: {
    method: ApiMethod.get,
    url: '/reporting/{company_id}/group/{dashboard_id}/',
    request: getRequest(
      ApiCommand.dashboardGet,
      ['company_id', 'dashboard_id'],
    ),
  },
  [ApiCommand.dashboardPost]: {
    method: ApiMethod.post,
    url: '/reporting/{company_id}/group/',
    request: getRequest(
      ApiCommand.dashboardPost,
      ['company_id'],
    ),
  },
  [ApiCommand.dashBoardPatch]: {
    method: ApiMethod.patch,
    url: '/reporting/{company_id}/group/{dashboard_id}/',
    request: getRequest(
      ApiCommand.dashBoardPatch,
      ['company_id', 'dashboard_id'],
    ),
  },
  [ApiCommand.dashboardPut]: {
    method: ApiMethod.put,
    url: '/reporting/{company_id}/group/{dashboard_id}/',
    request: getRequest(
      ApiCommand.dashboardPut,
      ['company_id', 'dashboard_id'],
    ),
  },
  [ApiCommand.dashboardTestChart]: {
    method: ApiMethod.post,
    url: '/reporting/{company_id}/group/data/',
    request: getRequest(
      ApiCommand.dashboardTestChart,
      ['company_id'],
    ),
  },
  [ApiCommand.dashboardDelete]: {
    method: ApiMethod.delete,
    url: '/reporting/{company_id}/group/{dashboard_id}/',
    request: getRequest(
      ApiCommand.dashboardDelete,
      ['company_id', 'dashboard_id'],
    ),
  },
  [ApiCommand.dashboardCopy]: {
    method: ApiMethod.post,
    url: '/reporting/{company_id}/group/{dashboard_id}/copy/',
    request: getRequest(
      ApiCommand.dashboardCopy,
      ['company_id', 'dashboard_id'],
    ),
  },
  [ApiCommand.dashboardGetFull]: {
    method: ApiMethod.get,
    url: '/reporting/{company_id}/group/{dashboard_id}/full/',
    request: getRequest(
      ApiCommand.dashboardGetFull,
      ['company_id', 'dashboard_id'],
    ),
  },
  [ApiCommand.tileGetList]: {
    method: ApiMethod.get,
    url: '/reporting/{company_id}/group/{dashboard_id}/tile/',
    // @ts-ignore
    request: getRequest(
      ApiCommand.tileGetList,
      ['company_id', 'dashboard_id'],
    ),
  },
  [ApiCommand.tileGet]: {
    method: ApiMethod.get,
    url: '/reporting/{company_id}/group/{dashboard_id}/tile/{tile_id}/',
    request: getRequest(
      ApiCommand.tileGet,
      ['company_id', 'dashboard_id', 'tile_id'],
    ),
  },
  [ApiCommand.tilePost]: {
    method: ApiMethod.post,
    url: '/reporting/{company_id}/group/{dashboard_id}/tile/',
    request: getRequest(
      ApiCommand.tilePost,
      ['company_id', 'dashboard_id'],
    ),
  },
  [ApiCommand.tilePatch]: {
    method: ApiMethod.patch,
    url: '/reporting/{company_id}/group/{dashboard_id}/tile/{tile_id}/',
    request: getRequest(
      ApiCommand.tilePatch,
      ['company_id', 'dashboard_id', 'tile_id'],
    ),
  },
  [ApiCommand.tilePut]: {
    method: ApiMethod.put,
    url: '/reporting/{company_id}/group/{dashboard_id}/tile/{tile_id}/',
    request: getRequest(
      ApiCommand.tilePut,
      ['company_id', 'dashboard_id', 'tile_id'],
    ),
  },
  [ApiCommand.tileDelete]: {
    method: ApiMethod.delete,
    url: '/reporting/{company_id}/group/{dashboard_id}/tile/{tile_id}/',
    request: getRequest(
      ApiCommand.tileDelete,
      ['company_id', 'dashboard_id', 'tile_id'],
    ),
  },
  [ApiCommand.tileGetData]: {
    method: ApiMethod.get,
    url: '/reporting/{company_id}/group/{dashboard_id}/tile/{tile_id}/data/',
    request: getRequest(
      ApiCommand.tileGetData,
      ['company_id', 'dashboard_id', 'tile_id'],
    ),
  },
  [ApiCommand.tilePostData]: {
    method: ApiMethod.post,
    url: '/reporting/{company_id}/group/{dashboard_id}/tile/{tile_id}/data/',
    request: getRequest(
      ApiCommand.tilePostData,
      ['company_id', 'dashboard_id', 'tile_id'],
    ),
  },
  [ApiCommand.tilePatchData]: {
    method: ApiMethod.patch,
    url: '/reporting/{company_id}/group/{dashboard_id}/tile/{tile_id}/data/',
    request: getRequest(
      ApiCommand.tilePatchData,
      ['company_id', 'dashboard_id', 'tile_id'],
    ),
  },
};

export const updateTilesGridConfig = (
  gridConfigModels: (TilePatchModel)[],
) => gridConfigModels.map(
  (model) => apiCommands[ApiCommand.tilePatch].request(model),
);

export const updateTile = (
  model: TilePatchModel,
) => apiCommands[ApiCommand.tilePatch].request(model);
// .then(async (response) => {
//   if (!response.status) return response;
//
//   const tileDataResponse = await apiCommands[ApiCommand.tileGetData].request({
//     tile_id: model.tile_id,
//     company_id: model.company_id,
//     dashboard_id: model.dashboard_id,
//   });
//   if (!tileDataResponse.status) return response;
//   return {
//     status: true,
//     response: {
//       ...response.response,
//       // @ts-ignore
//       data: tileDataResponse.response.data,
//     } as Tile,
//   };
// }) as Promise<ApiResponse<Tile>>;

export const createTile = (
  model: TilePostModel,
) => apiCommands[ApiCommand.tilePost].request(model);
// .then(async (response) => {
//   if (!response.status) return response;
//
//   const tileDataResponse = await apiCommands[ApiCommand.tileGetData].request({
//     tile_id: response.response.id,
//     company_id: model.company_id,
//     dashboard_id: model.dashboard_id,
//   });
//   if (!tileDataResponse.status) return response;
//   return {
//     status: true,
//     response: {
//       ...response.response,
//       // @ts-ignore
//       data: tileDataResponse.response.data,
//     },
//   };
// });

// @ts-ignore
window.dashboardTestChart = (
  company_id: number, parameters: any,
) => apiCommands[ApiCommand.dashboardTestChart].request({ company_id, parameters });
