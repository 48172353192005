import {
  IncomeSourceApiModel, IncomeSourceInputModel,
} from '@/components/dialog/dialogs/debtor/tabs/finance/incomeSources/types';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand } from '@/store/modules/api';
import { ApiResponse } from '@/service/api';
import { useToast } from '@/hooks/useToast';
import { useDialog } from '@/hooks/useDialog';
import { Debtor } from '@/hooks/useDebtors';
import { Ref } from 'vue';

export const useIncomeSourcesCrud = () => {

  const { confirmDialog } = useDialog();
  const { showPureDangerToast, showPureSuccessToast } = useToast();
  async function handleResult(
    { status }: { status: boolean },
    successMessage: string,
    errorMessage: string,
  ) {
    if (status) {
      if (successMessage) {
        await showPureSuccessToast({ label: successMessage, duration: 800 });
      }
    } else {
      await showPureDangerToast({ label: errorMessage, duration: 3000 });
    }
  }

  async function fetchIncomeSources(debtor: Ref<Debtor>) {
    const result = await commonLegacyApiRequest({
      command: ApiCommand.fetchDebtorSourcesOfIncome,
      params: {
        debtor: debtor.value?.debtor.pk,
      },
    });
    await handleResult(result, '', 'Не удалось получить данные');
    return result.response;
  }

  async function create(model: IncomeSourceInputModel): Promise<ApiResponse<IncomeSourceApiModel>> {
    const result = await commonLegacyApiRequest<IncomeSourceApiModel>({
      command: ApiCommand.createDebtorSourceOfIncome,
      data: model,
    });
    await handleResult(
      result,
      'Источник дохода создан',
      'Ошибка при создании источника дохода',
    );
    return result;
  }

  async function update(id: number, model: IncomeSourceInputModel): Promise<ApiResponse<IncomeSourceApiModel>> {
    const result = await commonLegacyApiRequest<IncomeSourceApiModel>({
      command: ApiCommand.updateDebtorSourceOfIncome,
      params: { id },
      data: model,
    });
    await handleResult(
      result,
      'Источник дохода обновлен',
      'Ошибка при изменении источника дохода',
    );
    return result;
  }

  async function deleteIncomeSource(id: number): Promise<ApiResponse<unknown> | void> {
    const dialog = await confirmDialog({
      key: 'remove_income_source',
      message: 'Подтвердите удаление',
      confirmLabel: 'Удалить',
      cancelLabel: 'Отмена',
    });
    dialog.closeDialog(); // dafaq? --- not so easy to fix
    if (!dialog.result) {
      return;
    }
    const result = await commonLegacyApiRequest<{ id: number }>({
      command: ApiCommand.deleteDebtorSourceOfIncome,
      params: { id },
    });
    await handleResult(
      result,
      'Источник дохода удален',
      'Ошибка при удалении источника дохода',
    );
    return result;
  }

  return {
    create,
    update,
    fetch: fetchIncomeSources,
    deleteIncomeSource,
  };
};
