import { onBeforeUnmount, onMounted } from 'vue';

export const useCloseOnEscape = (close: () => void) => {
  const onKeypress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      close();
    }
  };

  onMounted(() => {
    document.addEventListener('keydown', onKeypress);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('keydown', onKeypress);
  });
};
