import { computed, Ref } from 'vue';
import { ActiveTabKey } from '@/components/dialog/dialogs/debtor/tabs/common/useDebtorCommonDialogTabs';
import DebtorCommonMainTab
  from '@/components/dialog/dialogs/debtor/tabs/common/tabs/main/DebtorMainTab.vue';
import DebtorCommonOrganizationDetails
  from '@/components/dialog/dialogs/debtor/tabs/common/tabs/organizationDetails/DebtorCommonOrganizationDetails.vue';
import DebtorCommonPersonDetails
  from '@/components/dialog/dialogs/debtor/tabs/common/tabs/personDetails/DebtorCommonPersonDetails.vue';
import DebtorCommonVehicleTab
  from '@/components/dialog/dialogs/debtor/tabs/common/tabs/vehicle/DebtorCommonVehicleTab.vue';
import DebtorCommonTenantsTab
  from '@/components/dialog/dialogs/debtor/tabs/common/tabs/tenants/TenantsTab.vue';
import DebtorCommonOwnersTab
  from '@/components/dialog/dialogs/debtor/tabs/common/tabs/owners/DebtorCommonOwnersTab.vue';
import DebtorBorrowerTab
  from '@/components/dialog/dialogs/debtor/tabs/common/tabs/borrower/DebtorBorrowerTab.vue';

export const useDebtorCommonDialogComponent = (activeTab: Ref<ActiveTabKey>) => computed(() => (({
  main: DebtorCommonMainTab,
  organization_details: DebtorCommonOrganizationDetails,
  person_details: DebtorCommonPersonDetails,
  vehicle: DebtorCommonVehicleTab,
  tenants: DebtorCommonTenantsTab,
  owners: DebtorCommonOwnersTab,
  borrower: DebtorBorrowerTab,
} as {[key in ActiveTabKey]: any})[activeTab.value]));
