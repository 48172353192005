import {
  notificationApiCommandMap,
  NotificationType,
  useDataFileRequest,
} from '@/hooks/useDataFileRequest';
import { ActiveTableActionPayload } from '@/components/activeTable/useActiveTable';
import {
  computed, h, Ref, ref,
} from 'vue';
import { Option, useVoipService } from '@/service/voipService';
import SelectInput from '@/components/selectInput/SelectInput.vue';
import Checkbox from '@/components/checkbox/Checkbox.vue';
import { IToastLevel, IToastProgressbar, useToast } from '@/hooks/useToast';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { DataFileFilters } from '@/types/datafile';
import {
  getSocketMessageTaskID,
  isDataFileEvent,
  isProcessingEvent,
  ProcessingSmsSocketMessage,
} from '@/types/socket';
import { wait } from '@/utils/common';
import { useSocket } from '@/hooks/useSocket';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { RouterKey } from '@core/symbols';
import { useI18n } from 'vue-i18n';
import { useDialog } from '@/hooks/useDialog';
import { RouteLocationRaw } from 'vue-router';

export function useNotificationsDialog() {
  const {
    companyId,
  } = useProtectedDefaultCompany();
  const router = useProtectedInject(RouterKey);

  const { t: rootT } = useI18n();
  const {
    confirmDialog,
  } = useDialog();
  const { dispatchSignal, awaitSignalResponse } = useSignal();
  const {
    showToast,
    showPureDangerToast,
  } = useToast();

  const dataFileRequest = useDataFileRequest();

  const {
    subscribe,
  } = useSocket();

  const { fetchBotsAndPhones } = useVoipService(companyId);

  const showNonSelectedItemsDialog = async (title: string, key?: string) => {
    const { closeDialog } = await confirmDialog({
      key,
      title,
      cancelLabel: rootT('other.ok'),
      withConfirmation: false,
    });
    closeDialog();
  };

  const showNotificationDialog = async (
    type: NotificationType,
    { selectedItems, allSelected, total }: ActiveTableActionPayload<any, any>,
  ) => {
    if (!allSelected && !selectedItems.length) {
      await showNonSelectedItemsDialog(rootT('notification.notSelected'), type);
      return;
    }
    const isVoice = type === 'voice';

    const forceInvalid = ref(true);
    const voiceBot = ref<string>('');
    const phone = ref<string>('');

    const [bots, phones] = isVoice ? await fetchBotsAndPhones() : [[], []];
    type LsKey = 'ls-voice-bot' | 'ls-phone';

    function setupDefault(key: LsKey, model: Ref<string>, options: Option[]) {
      const saved = localStorage.getItem(key);
      if (saved && options.find((option) => option.value === saved)) {
        model.value = saved;
      }
    }

    function saveValue(key: LsKey, model: Ref<string>, value: string) {
      localStorage.setItem(key, value);
      model.value = value;
    }

    setupDefault('ls-voice-bot', voiceBot, bots);
    setupDefault('ls-phone', phone, phones);

    const { result, closeDialog } = await confirmDialog({
      key: type,
      confirmLabel: rootT('notification.confirm'),
      message: rootT(`notification.${type}`),
      withCancel: false,
      extraContent: isVoice ? computed(() => h('div', [
        // @ts-ignore
        bots.length ? h(SelectInput, {
          state: 'primary',
          style: 'margin: 0 0 14px 0;',
          options: bots,
          modelValue: voiceBot,
          'onUpdate:modelValue': (v: string) => saveValue('ls-voice-bot', voiceBot, v),
        }) : h('span'),
        // @ts-ignore
        phones.length ? h(SelectInput, {
          state: 'primary',
          style: 'margin: 0 0 14px 0;',
          options: phones,
          modelValue: phone,
          'onUpdate:modelValue': (v: string) => saveValue('ls-phone', phone, v),
        }) : h('span'),
        h(Checkbox, {
          modelValue: forceInvalid.value,
          label: 'Отправить по невалидным номерам',
          'onUpdate:modelValue': (v: boolean) => {
            forceInvalid.value = v;
          },
        }),
      ])) : undefined,
    });

    function defaultProgress(label: string) {
      return {
        label,
        key: 'progress',
        current: 0,
        max: 0,
      };
    }

    function defaultProgresses() {
      return [
        // defaultProgress('notification.toast.progress'),
        defaultProgress('notification.toast.sentProgress'),
      ];
    }

    if (result) {
      const progressMap: {
        [key in NotificationType]: Ref<Array<IToastProgressbar>>;
      } = {
        claim: ref(defaultProgresses()),
        sms: ref(defaultProgresses()),
        voice: ref(defaultProgresses()),
        email: ref(defaultProgresses()),
      };

      const localFilters = await awaitSignalResponse<Record<any, any>
        >(
          SignalType.getDebtorFilters,
          SignalType.debtorFilters,
        );

      const filters = {
        company_id: localFilters.company_id,
        production_type: localFilters.production_type,
        module: localFilters.module,
        ...(selectedItems.length ? { debtor_ids: [...selectedItems] } : { ...localFilters }),
      } as DataFileFilters;

      const sendingDebtorsLength = filters!.debtor_ids?.length ?? total;
      const isSingle = sendingDebtorsLength === 1;

      const message = !isVoice
        ? `notification.toast.message${isSingle ? '' : '_multi'}`
        : `notification.toast.message_voice${isSingle ? '' : '_multi'}`;

      const hideProgressToast = await showToast({
        message,
        level: IToastLevel.info,
        duration: null,
        progressbars: progressMap[type],
      });

      progressMap[type].value[0].current = 0;
      progressMap[type].value[0].max = 0;
      // progressMap[type].value[1].current = 0;
      // progressMap[type].value[1].max = 0;

      const { status, response, statusCode } = await dataFileRequest(
        notificationApiCommandMap[type],
        {
          filters,
          company: localFilters.company_id,
          payload: {
            ...(forceInvalid.value ? { force_invalid: true } : {}),
            ...(voiceBot.value ? { bot_sid: voiceBot.value } : {}),
            ...(phone.value ? { caller_id: phone.value } : {}),
          },
        },
      );

      const progressMaps = progressMap[type].value;
      const [sendingProgress] = progressMaps;
      sendingProgress.max = sendingDebtorsLength;

      if (status) {
        const pathToSmsRoute = '/exchange/integration/notifications/sms';

        const unsub = await subscribe<ProcessingSmsSocketMessage>({
          condition: (payload) => (
            payload.action === 'progress_event'
            && (isProcessingEvent(payload) || isDataFileEvent(payload))
            && getSocketMessageTaskID(payload) === response.uuid
          ),
          async handler(payload) {
            if (payload.data.obj?.error_code === 'no_operator') {
              await showToast({
                duration: 8000,
                message: rootT('notification.toast.failure_no_operator'),
                level: IToastLevel.danger,
                onClick: () => router.push(pathToSmsRoute),
              });
              await router.push(pathToSmsRoute);
              return;
            }
            sendingProgress.current = payload.data.progress.step;

            if (payload.data.done) {
              const FINAL_MESSAGE_DURATION = 5000;
              if (type === 'claim') {
                await showToast({
                  duration: FINAL_MESSAGE_DURATION,
                  message: rootT('notification.toast.success'),
                  level: IToastLevel.success,
                });
              } else {
                if (sendingProgress.max === 1) { // сообщения для одного должника
                  if (sendingProgress.current === sendingProgress.max) {
                    await showToast({
                      duration: FINAL_MESSAGE_DURATION,
                      message: rootT('notification.toast.success'),
                      level: IToastLevel.success,
                    });
                  } else {
                    await showToast({
                      duration: FINAL_MESSAGE_DURATION,
                      message: rootT('notification.toast.failure_mono'),
                      level: IToastLevel.danger,
                    });
                  }
                } else {
                  if (sendingProgress.current === sendingProgress.max) { // сообщения для многих должников
                    await showToast({
                      duration: FINAL_MESSAGE_DURATION,
                      message: rootT('notification.toast.success_multi'),
                      level: IToastLevel.success,
                    });
                  } else if (sendingProgress.current === 0) {
                    await showToast({
                      duration: FINAL_MESSAGE_DURATION,
                      message: rootT('notification.toast.failure_total_multi'),
                      level: IToastLevel.danger,
                    });
                    await router.push(pathToSmsRoute);
                  } else {
                    await showToast({
                      duration: FINAL_MESSAGE_DURATION,
                      message: rootT(
                        'notification.toast.failure_partial_multi',
                        {
                          // errors_count: errorMessages.length,
                          errors_count: sendingProgress.max - sendingProgress.current,
                          max: sendingProgress.max,
                          delivered: sendingProgress.current,
                        },
                      ),
                      level: IToastLevel.warning,
                    });
                  }
                }
              }
              await dispatchSignal(SignalType.debtorsUpdated);
              await wait(1000);
              await hideProgressToast();
              unsub();
            }
          },
        });
      } else {
        await hideProgressToast();
        const errorDetails = response?.detail;
        await showPureDangerToast({
          params: {
            label: 'Ошибка отправки',
            message: errorDetails ?? '',
          },
        });
        if (statusCode === 402) {
          await router.push('/panel/tariffs' as RouteLocationRaw);
        }
      }
    }

    closeDialog();
  };

  return { showNotificationDialog };
}
