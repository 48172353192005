import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useDebtorCommonDialogTabs } from '@/components/dialog/dialogs/debtor/tabs/common/useDebtorCommonDialogTabs';
import { useDefaultCompanyPermissions } from '@/hooks/useDefaultCompanyPermissions';
import { computed, ref } from 'vue';
import { useDebtorCompositeUpdate } from '@/hooks/useDebtorCompositeUpdate';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { useUpdateCadNum } from '@/components/dialog/dialogs/debtor/tabs/common/useUpdateCadNum';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';

export const useDebtorCommonTab = () => {
  const {
    debtor,
    isOrganization,
    fetchDebtorData,
    productionType,
  } = useInjectDebtorDialog();
  const {
    activeTab, t, tabs,
  } = useDebtorCommonDialogTabs(debtor, productionType, isOrganization);
  const {
    companyModuleType,
  } = useDefaultCompany();
  const { hasWritePermission } = useDefaultCompanyPermissions(productionType);

  const isLoading = ref<boolean>(false);
  const componentRef = ref<null | { refetchData?:() => Promise<void>}>(null);
  const refetchData = computed(
    () => componentRef.value && componentRef.value.refetchData,
  );

  const afterUpdate = async (): Promise<void> => {
    isLoading.value = true;
    await fetchDebtorData();
    isLoading.value = false;
    if (refetchData.value) {
      return refetchData.value();
    }
    return Promise.resolve();
  };

  const update = useDebtorCompositeUpdate(
    afterUpdate,
  );
  const onUpdate = () => update(
    productionType.value,
    debtor.value ? [debtor.value.debtor.pk] : [],
    isOrganization.value,
  );

  const { updateCadNum } = useUpdateCadNum(debtor);
  const updateCadNumIsVisible = computed(
    () => companyModuleType.value === CompanyModuleType.Communal
      && activeTab.value === 'owners',
  );

  return {
    t,
    activeTab,
    tabs,
    isLoading,
    onUpdate,
    updateButtonIsVisible: true,
    componentRef,
    updateCadNum,
    updateCadNumIsVisible,
    hasWritePermission,
    debtor,
    isOrganization,
    productionType,
  };
};
