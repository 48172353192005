import { commonLegacyApiRequest as req } from '@urrobot/core/service/commonService';
import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { unwrapFirstRecord, unwrapListingApiResponse } from '@/service/api';

export enum BillingOperator {
  mosvodocanal = 'mosvodocanal',
  billing_online_solutions = 'billing_online_solutions',
  ais_gorod = 'ais_gorod',
  megapolis = 'megapolis',
  pik = 'pik',
  asu = 'asu',
  dynamics = 'dynamics',
}

export type CompanyBilling = {
  id: number;
  company: number;
  operator: BillingOperator;
}

export const useBillingApi = () => ({
  company: {
    // доступные компании биллинги
    getList: ({ company_id }: { company_id: number }) => req<ListingResponse<CompanyBilling>>({
      command: ApiCommand.scheduleBillingCompanyGetList,
      params: {
        limit: 1000,
        company: company_id,
      },
    }).then(unwrapListingApiResponse),
    // доступные компании биллинги
    getFirst: ({ company_id }: { company_id: number }) => req<ListingResponse<CompanyBilling>>({
      command: ApiCommand.scheduleBillingCompanyGetList,
      params: {
        limit: 1000,
        company: company_id,
      },
    }).then(unwrapFirstRecord),
  },
});
