import { useActiveTable } from '@/components/activeTable/useActiveTable';
import { Company, Region, useCompanies } from '@/hooks/useCompanies';
import { computed, Ref, ref } from 'vue';

export function useRegions(company?: Ref<number | null>) {
  const {
    fetchRegions,
    fetchCompanySettings,
    updateCompanySettings,
  } = useCompanies();
  const region = ref<Region['id'] | null>(null);
  const {
    records: regions,
  } = useActiveTable<Region, Region, 'id'>({
    keyField: 'id',
    defaultLimit: ref(10000), // ...
    async fetch({ params, signal }) {
      const { response } = await fetchRegions({ ...params ?? {}, signal });
      return response;
    },
  });
  const regionFilter = ref<string>();
  const computedRegions = computed(() => (
    regionFilter.value
      ? regions.value.filter(
        (region) => region.name.toLowerCase().includes(regionFilter.value!.toLowerCase()),
      )
      : regions.value
  ));
  const currentRegionCode = computed(
    () => {
      const r = computedRegions.value.find((v) => v.id === region.value);
      return r?.code ?? null;
    },
  );
  const setRegion = async (regionId: Region['id']) => {
    company && await updateCompanySettings({
      id: company.value!,
      payload: { default_region: regionId },
    });
    region.value = regionId;
    regionFilter.value = '';
  };
  const fetchDefaultRegion = async (id: Company['id']) => {
    region.value = null;
    if (id) {
      const { status, response } = await fetchCompanySettings(id);

      if (status) {
        region.value = response.default_region;
      }
    }
  };
  return {
    region,
    regionFilter,
    fetchDefaultRegion,
    setRegion,
    currentRegionCode,
    regionCode: currentRegionCode,
    regions: computedRegions,
  };
}
