import { useBreakpoints as useBr } from '@vueuse/core';
import { computed, watch } from 'vue';

const breakpoints = useBr({
  tablet: 1100,
  laptop: 1280,
  desktop: 1750,
});

export enum ScreenBreakpoints {
  tablet = 'tablet',
  laptop = 'laptop',
  desktop = 'desktop'
}
const breakpoint = computed(() => {
  if (breakpoints.smaller(ScreenBreakpoints.tablet).value) {
    return ScreenBreakpoints.tablet;
  }
  if (breakpoints.greater(ScreenBreakpoints.laptop).value) {
    return ScreenBreakpoints.desktop;
  }
  if (breakpoints.between(ScreenBreakpoints.tablet, ScreenBreakpoints.laptop).value) {
    return ScreenBreakpoints.laptop;
  }
  return ScreenBreakpoints.desktop;
});

export const useBreakpoint = () => breakpoint;
