import { ref } from 'vue';
import { UnRefedPayload } from '@/pages/admin/courts/main/useAddAddressesDialog';
import { StandartizeApi } from '@/service/standartizeService';
import debounce from 'lodash/debounce';
import { computed } from '@vue/reactivity';
import { GarAddress } from '@/service/standartize/types';

type SelectedResults = {
  search: string;
  items: GarAddress[];
  selected?: number;
}

type Check = {
  type: string;
  value: boolean;
  types?: string[];
}

export function setupAddAddresses(payload: UnRefedPayload, emit: (ev: string) => void) {

  const text = ref<string>('');
  const addressesRaw = ref<SelectedResults[]>([]);
  const checks = ref<Check[]>([
    { type: 'Ул', value: true },
    { type: 'Пер', value: false },
    { type: 'Пр-д', value: false, types: ['пр-д', 'проезд'] },
    { type: 'Д', value: false },
    { type: 'С', value: false },
    { type: 'П', value: false },
    { type: 'СНТ/ДНТ', value: false, types: ['снт', 'днт', 'тер.', 'тер. СНТ'] },
  ]);

  async function search(search: string, index: number) {
    return StandartizeApi.addrObj.getList({
      limit: 300,
      page: 1,
      filters: {
        path: payload.settlement,
        aolevel: [2, 3, 4, 5, 6, 7, 8].join(','),
        name: search,
      },
    }).then((r) => {
      if (!r.status) return [];
      const list = r.response.results.map((value) => value);
      addressesRaw.value[index] = {
        search,
        items: list,
        selected: list.length ? list[0].objectid : undefined,
      };
    });
  }

  async function doOnUpdate() {
    const lines = text.value
      .split(/,\s*|\n/)
      .filter((v) => !!v)
      .map((line) => line.replace(/['"]/g, ''));

    await Promise.all(lines.map((line, index) => {
      const result = addressesRaw.value[index];
      if (result && result.search === line) {
        return;
      }
      return search(line, index);
    }));
  }

  async function addRecords() {
    await Promise.allSettled(results.value.map((element) => {
      if (!element.selected) return;
      const objectLevel = element.items.find(({ objectid }) => objectid === element.selected)!.level;
      return StandartizeApi.jurisdiction.createItem({
        court_type: payload.activeTab,
        court_id: payload.courtId,
        id: 'new',
        addr_obj: { objectid: element.selected },
        houses_include: objectLevel === 7 || objectLevel === 6 ? '*' : '', // снт 7, деревни, села 6
        houses_exclude: '',
        houses_even: '',
        houses_odd: '',
      });
    }));
    emit('close');
  }

  const results = computed(() => {
    const currentFilter = checks.value.reduce((acc, check) => {
      if (!check.value) return acc;
      if (check.types) {
        return acc.concat(check.types);
      }
      return acc.concat([check.type.toLowerCase()]);
    }, [] as string[]);

    return addressesRaw.value.map((item) => {
      const items = item.items.filter((option) => {
        return currentFilter.length ? currentFilter.includes(option.typename) : true;
      });
      const selectedOk = items.find((option) => option.objectid === item.selected);
      return {
        ...item,
        items,
        selected: selectedOk ? item.selected : items.length ? items[0].objectid : null,
      };
    });
  });

  return {
    checks,
    text,
    onUpdate: debounce(doOnUpdate, 600),
    results,
    addRecords,
  };

}
