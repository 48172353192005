import * as GridConfig from '@/shared/grid';
import {
  ReportingDataModelConfig, ReportingDataModelField, ReportingDataModelFieldTypes,
  ReportingDataModelFilterName, ReportingDataModelFilterValue,
} from '@/service/api/reporting/dataModelConfig';
import { Entries } from '@/types/utils';
import { dateToApiDate } from '@/utils/date';
import { TilePatchParamsModelRaw } from '@/service/api/reporting/models';
import { ReportingChartFormFilterFieldSplitOption } from '@/components/forms/form/reportingChartForm/filters';
import { ReportingChartPlateType } from '@/components/forms/form/reportingChartForm/utils';
import { PlateIndicatorValueConfig } from '@/components/plate/plateIndicator/types';
import {
  ChartLineSeriesVariant,
  ChartLineVariant,
} from '@/components/plate/chartConfigs/lineChart/line-chart';
import { ChartPolarSeriesVariant } from '@/components/plate/chartConfigs/lineChart/polar-chart';
import { PlateIndicatorType } from '@/components/plate/plateIndicator';
import { ChartPieVariant } from '@/components/plate/chartConfigs/pie-charts';

export type UUID = string;
export type DateTimeString = string;
export type TileLineFunction = 'sum'|'min'|'max'|'avg'|'count'|'first'|'last';
export type TileDefaultParametersFilters = Record<
  ReportingDataModelFilterName, ReportingDataModelFilterValue
  >

export type TileLineSplitBy = {
  split_start?: string;
  split_stop: string;
  split_start_null?: boolean;
  split_stop_null?: boolean;
  use_previous?: boolean;
}

export type TileParametersModel = {
  name: string;
  order_by?: string[];
  function: TileLineFunction;
  filters?: Record<any, any>;
  excludes?: Record<any, any>;
}

export type TileLine = {
  key: string;
  field: string;
  models: TileParametersModel[];
  group_by?: string|null;
  split_by?: TileLineSplitBy;
}

export type TileTimeInterval = {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
}

export type TileParameters = {
  order_by: string[];
  split_by: {
    split_start_name: string;
    split_stop_name: string;
    allow_split_start_null: boolean;
    allow_split_stop_null: boolean;
  };
  model: ReportingDataModelConfig['name'];
  start: TileTimeInterval;
  interval: TileTimeInterval;
  limit: 1000;
  filters: TileDefaultParametersFilters;
  lines: TileLine[];
  round_dates?: 'day'|'month';
  all_companies?: boolean;
  companies?: number[];
}

export type TileParametersResponse = {
  parameters: TileParameters;
}

export type TileDataRaw = {
  extra?: Record<string, { growth: string; growth_procent: string }>;
  time: DateTimeString[];
  summary: Record<ReportingDataModelField, Record<number, number>>;
}
export type TileData = {
  extra?: Record<string, { growth: number|null; growth_procent: number|null }>;
  time: Date[];
  summary: Record<ReportingDataModelField, Record<number, number>|number>;
}
export type TileDataResponseRaw = {
  id: UUID;
  group: UUID;
  data: TileDataRaw|null|{ error: string };
}
export type TileDataResponse = {
  id: UUID;
  group: UUID;
  data: TileData|null|{ error: string };
}

export type TileRenderParametersLineConfig = {
  name: string;
  groupName?: string;
  color?: string;
  split_by?: ReportingChartFormFilterFieldSplitOption;
  // show_previous?: boolean;
  // show_percent?: boolean;
  line_variant?: ChartLineSeriesVariant | ChartPolarSeriesVariant;
  value_type?: ReportingDataModelFieldTypes;
  no_stack?: boolean;
  // показывает, что эта группа должна отображаться как дополнительное значение к другой
  // смотри диаграмму отправленных смс-сообщений
  additional_to_group?: number;
}
export type TileRenderParameters = {
  lines: Record<TileLine['key'], TileRenderParametersLineConfig>;
  type: ReportingChartPlateType;
  plate_indicator_type?: PlateIndicatorType;
  plate_indicator_variant?: ChartLineVariant | ChartPieVariant;
  line_variant_default?: ChartLineSeriesVariant | ChartPolarSeriesVariant;
  interval_preset: string|null;
  custom_date_formatter: string|null;
  show_timeline_percent?: boolean;
  show_previous?: boolean;
  show_percent?: boolean;
  show_value?: boolean;
  show_total?: boolean;
  show_growth?: boolean;
  sum_groups: boolean;
  show_groups_percent: boolean;
  pie_title_key: string;
  stack_group?: boolean;
}

type ErrorData = { error: string };

export type Tile = {
  id: UUID;
  group: UUID;
  name: string;
  config: GridConfig.TileGridConfig;
  mobile_config: { tile_order?: number; indicator_order?: number } | null;
  parameters: TileParameters;
  render_parameters: [TileRenderParameters];
  data?: TileData | ErrorData;
  all_companies?: boolean;
  companies?: number[];
}

export type TileRaw = {
  config: GridConfig.TileGridConfigJSON;
  mobileConfig: string;
  data?: TileDataRaw | ErrorData;
  parameters: TileParameters;
}
  & Omit<Tile, 'config'|'data'|'parameters'>;

export const mapTileFromResponse = (raw: TileRaw): Tile => ({
  ...raw,
  data: !raw.data
    ? undefined
    : (raw.data as ErrorData).error
      ? raw.data as ErrorData
      : mapTileDataFromResponse(raw.data as TileDataRaw),
  config: GridConfig.tileGridConfigFromResponse(raw.config),
  mobile_config: raw.config ? JSON.parse(raw.config) : null,
});

export const mapTileDataFromResponse = (
  d: TileDataRaw,
): TileData => ({
  time: d.time ? d.time.map((rawDate) => new Date(rawDate)) : [],
  summary: Object.fromEntries(
    Object.entries(d.summary).map(([k, values]) => ([
      k,
      Array.isArray(values)
        ? Object.fromEntries(
          values.map((v, i) => ([i, +v])),
        )
        : +values,
    ])),
  ),
  ...(d.extra
    ? {
      extra: Object.fromEntries(
        Object.entries(d.extra).map(
          ([k, v]) => ([
            k,
            Object.fromEntries(
              Object.entries(v).map(([k, v1]) => ([k, +v1])),
            ) as Record<'growth'|'growth_procent', number>,
          ]),
        ),
      ),
    }
    : {}),
});
