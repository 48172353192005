import {
  Ref, ref, h, computed,
} from 'vue';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useRosreestr } from '@/hooks/useRosreestr';
import { useUnsubs } from '@/hooks/useUnsubs';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { Debtor } from '@/hooks/useDebtors';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';

export const useUpdateCadNum = (debtor: Ref<Debtor>) => {
  const { t } = useLocalI18n('debtor.documents');

  const { showToast } = useToast();

  const { fetchDebtorData } = useInjectDebtorDialog();

  const {
    fetchStandardizedDebtorAddresses,
    updateStandardizedDebtorAddress,
  } = useRosreestr();

  const {
    showDialog,
    confirmDialog,
  } = useDialog();

  const { sub, unsub } = useUnsubs();
  const { subscribeToSignal, dispatchSignal } = useSignal();

  const cadNumIsUpdating = ref(false);

  const updateCadNum = async () => {
    if (cadNumIsUpdating.value) return;

    cadNumIsUpdating.value = true;
    const { status, response } = await fetchStandardizedDebtorAddresses({
      id: debtor.value?.debtor_main_profile.id as number,
    });
    if (!status) {
      showToast({
        label: t('toasts.addStandardizedDebtorAddress.no-address.title'),
        message: t('toasts.addStandardizedDebtorAddress.no-address.message'),
        level: IToastLevel.danger,
      });
      cadNumIsUpdating.value = false;
      return;
    }

    const close = await showDialog({
      component: IDialogComponent.model,
      addInRoute: false,
      payload: {
        mKey: 'update_cad_num',
        record: { cad_num: response.cad_num },
        signal: SignalType.cadNumDialogModelUpdated,
        isEditable: true,
        fields: [{
          key: 'cad_num',
          field: 'cad_num',
          type: ActiveFormFieldType.searchSelect,
          options: {
            label: 'Введите кадастровый номер',
            hint: '{цифры}:{цифры}:{цифры}:{цифры}',
            displayField: 'cadnum',
            valueField: 'cadnum',
            prefetch: true,
            isAutocomplete: true,
            searchQuery: () => Promise.resolve(response.suggests),
            // searchQuery: () => Promise.resolve(response.suggests),
            renderOption: (
              { cadnum, address }: { cadnum: string; address: string },
            ) => h('div', [
              h('div', [cadnum]),
              h('div', { style: 'opacity: 0.6' }, [address])]),
          },
        }],
      },
    });
    let unsubClosedDialog: () => void;
    const unsubSignal = subscribeToSignal(
      SignalType.model,
      async (payload: { id: SignalType; model: { cad_num: string} }) => {
        if (payload.id === SignalType.cadNumDialogModelUpdated) {
          if (!payload.model.cad_num) {
            dispatchSignal(
              SignalType.modelErrors,
              ({
                id: SignalType.cadNumDialogModelUpdated,
                errors: {
                  cad_num: ['Введите кадастровый номер'],
                },
              }),
            );
            return;
          }
          // if (!/^[\d:\-/()]+$/.test(payload.model.cad_num)) {
          //   dispatchSignal(
          //     SignalType.modelErrors,
          //     ({
          //       id: SignalType.cadNumDialogModelUpdated,
          //       errors: {
          //         cad_num: ['Кадастровый номер должен содержать только цифры и спец. символы (:-/)'],
          //       },
          //     }),
          //   );
          //   return;
          // }
          const { status, response } = await updateStandardizedDebtorAddress({
            id: debtor.value?.debtor_main_profile.id as number,
            cad_num: payload.model.cad_num,
          });
          if (!status) {
            dispatchSignal(
              SignalType.modelErrors,
              ({
                id: SignalType.cadNumDialogModelUpdated,
                errors: response,
              }),
            );
            showToast({
              label: t('toasts.addStandardizedDebtorAddress.error.title'),
              message: t('toasts.addStandardizedDebtorAddress.error.message'),
              level: IToastLevel.danger,
            });
          } else {
            showToast({
              label: t('toasts.addStandardizedDebtorAddress.success.title'),
              message: t('toasts.addStandardizedDebtorAddress.success.message'),
              level: IToastLevel.success,
            });
            cadNumIsUpdating.value = false;
            fetchDebtorData();
            unsub(unsubSignal);
            unsub(close);
            unsub(unsubClosedDialog);
          }
        }
      },
    );
    unsubClosedDialog = subscribeToSignal(
      SignalType.dialogClosed,
      ({ component, payload }: any) => {
        if (component === IDialogComponent.model
          && payload.signal === SignalType.cadNumDialogModelUpdated
        ) {
          cadNumIsUpdating.value = false;
          unsub(unsubSignal);
          unsub(unsubClosedDialog);
          unsub(close);
        }
      },
    );
    sub(unsubClosedDialog);
    sub(unsubSignal);
    sub(close);
  };

  return {
    updateCadNum,
  };
};
