import { createStore, Store } from 'vuex';
import { arrayFrom } from '@/utils/object';
import { StoreJwtToken, UserToken } from '@/hooks/useUser';
import * as user from './modules/user';
import * as api from './modules/api';
import * as thirdParty from './modules/thirdParty';
import * as layout from './modules/layout';
import * as companies from './modules/companies';
import * as employees from './modules/employees';
import * as employeeToDebtorAttach from './modules/employeeToDebtorAttach';
import * as accountDocuments from './modules/accountDocuments';
import * as dicts from './modules/dicts';
import * as dataFile from './modules/dataFile';
import * as socket from './modules/socket';
import * as courts from './modules/courts';
import * as construct from './modules/construct';
import * as debtors from './modules/debtors';
import * as rosreestr from './modules/rosreestr';
import * as pretrial from './modules/pretrial';
import * as documents from './modules/documents';
import * as finance from './modules/finance';
import * as tasksProgress from './modules/tasksProgress';
import * as notifications from './modules/notifications';
import Storage from '@/plugins/storage';

export type StoreState = {
  user: user.UserState;
  api: api.ApiState;
  thirdParty: thirdParty.ThirdPartyState;
  layout: layout.LayoutState;
  companies: companies.CompaniesState;
  employees: employees.EmployeesState;
  employeeToDebtorAttach: employeeToDebtorAttach.EmployeeToDebtorAttachState;
  accountDocuments: accountDocuments.AccountDocumentsState;
  dicts: dicts.DictsState;
  dataFile: dataFile.DataFileState;
  socket: socket.SocketState;
  courts: courts.CourtsState;
  construct: construct.ConstructorState;
  debtors: debtors.DebtorsState;
  rosreestr: rosreestr.RosreestrState;
  pretrial: pretrial.PretrialState;
  documents: documents.DocumentsState;
  finance: finance.FinanceState;
  tasksProgress: tasksProgress.TasksProgressState;
  notifications: notifications.NotificationsState;
}

const modules = [
  ['user.token', (val: StoreJwtToken | null) => val],
  'user.customApiUrl',
  'user.licenseToken',
  'user.standartizeToken',
  'user.data',
  'user.canCheckoutBack',
  'layout.isSidebarExpanded',
  'user.registrationData',
  ['user.codeUntil', (val: Date | null) => (val ? new Date(val) : val)],
  'layout.theme',
  'companies.defaultCompanyId',
  'companies.companies',
  'tasksProgress.tasks',
  'layout.settings.serviceVersion',
  'socket.standartizeSubsciberGroup',
];

export const asyncStore = new Promise<Store<StoreState>>(async (resolve) => {
  const persistedStateEntries = await modules.reduce(async (promise, module) => {
    const acc = await promise;
    const persistedState = await Storage.getParsedItem('store');

    if (!(arrayFrom(module)[0] in persistedState)) {
      return acc;
    }
    const value = persistedState[arrayFrom(module)[0]];
    acc.push([arrayFrom(module)[0], (arrayFrom(module)[1] || ((s: any) => s))(value) || value]);
    return acc;
  }, Promise.resolve<Array<[string, any]>>([]));

  resolve(createStore<StoreState>({
    modules: {
      user,
      api,
      thirdParty,
      layout,
      companies,
      employees,
      employeeToDebtorAttach,
      accountDocuments,
      dicts,
      dataFile,
      socket,
      courts,
      construct,
      debtors,
      rosreestr,
      pretrial,
      documents,
      finance,
      tasksProgress,
      notifications,
    },
    mutations: {
      restoreStoreModule(state, {
        module,
        value,
      }) {
        const split = arrayFrom(module)[0].split('.');
        const last = split.pop();

        if (!last) {
          return;
        }

        (split.length
          ? split.reduce((acc: Record<string, any>, cur: string) => acc[cur], state)
          : state)[last] = value;
      },
    },
    plugins: [
      (store) => {
        persistedStateEntries.forEach(([module, value]) => {
          store.commit('restoreStoreModule', { module, value });
        });
      },
      (store) => {
        modules.forEach((module) => {
          store.watch((state: any) => arrayFrom(module)[0].split('.').reduce(
            (acc: any, cur: string) => acc?.[cur], state as any,
          ), async (value) => {
            await Storage.updateStorageKey(arrayFrom(module)[0], value);
          }, {
            immediate: true,
            deep: true,
          });
        });
      },
      ...user.plugins,
      ...dicts.plugins,
      ...socket.plugins,
      ...tasksProgress.plugins,
      ...layout.plugins,
      ...notifications.plugins,
      ...companies.plugins,
    ],
  }));
});
