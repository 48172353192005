import {
  ActiveFormField,
  isActiveFormIonFieldGroup,
  isActiveFormIonListGroup,
} from '@/hooks/useActiveForm';
import { computed, ref, watch } from 'vue';
import { OnUpdateFieldPayload, UseMobileActiveFormConfig } from '@core/hooks/types';
import { arrayFrom } from '@/utils/object';

export type ActiveFormFieldGroupNext = {
  tag: 'fieldGroup';
  key: string;
  label?: string;
  groupOrder: number;
  fields: ActiveFormField<any>[];
};

export type ActiveFormListGroupNext = {
  tag: 'listGroup';
  key: string;
  label?: string;
  // listItemLabel?: string;
  groupOrder: number;
  fields: ActiveFormField<any>[];
  isDeletable?: boolean;
  withInitValue?: boolean; // add default value
  getItemLabel?: (value: any, index: number) => string;
  getEmptyListItem: () => any;
  renderReadonly?: (Record: any) => any;
  isReadonly?: boolean;
}

export type ActiveFormGroupNext =
  | ActiveFormFieldGroupNext
  | ActiveFormListGroupNext;

export const isIonFormGroup = (
  g: any,
): g is ActiveFormGroupNext => g.tag === 'fieldGroup' || g.tag === 'listGroup';

export const isIonFormIonFieldGroup = (
  g: ActiveFormGroupNext,
): g is ActiveFormFieldGroupNext => g.tag === 'fieldGroup';

export const isIonFormListGroup = (
  g: ActiveFormListGroupNext,
): g is ActiveFormListGroupNext => g.tag === 'listGroup';

export interface DeepErrorsMap extends Record<string, DeepErrorsMap|string[]> {}

export type OnDeleteIonFormFieldPayload<Rec extends Record<string, any>,
  KeyPath extends Array<keyof Rec>,
  > = {
  keyPath: KeyPath;
}
export const getFieldValue = <Rec extends Record<string, any>,
  KeyPath extends Array<keyof Rec>,
  >(keyPath: KeyPath, rec: Rec) => keyPath.reduce((acc, key) => rec[key], rec);

export const useActiveForm = <Model>(
  config: UseMobileActiveFormConfig<Model>,
) => {
  const fieldGroups = computed(
    () => config.fields.value.reduce((acc, field, i, fieldsList) => {
      if (field.ionFieldGroup) {
        const fieldGroup = field.ionFieldGroup;
        const group = (acc as ActiveFormGroupNext[]).find(
          (g) => g.tag && g.key === fieldGroup.key,
        );
        if (group) {
          group.fields.push({
            ...field,
            state: computed(() => ([...(config.fieldState?.value ?? []), ...arrayFrom(field.state ?? [])])),
          });
        } else {
          if (isActiveFormIonFieldGroup(fieldGroup)) {
            acc.push({
              tag: 'fieldGroup',
              key: fieldGroup.key,
              label: fieldGroup.label,
              groupOrder: fieldGroup.groupOrder,
              fields: [{
                ...field,
                state: computed(() => ([...(config.fieldState?.value ?? []), ...arrayFrom(field.state ?? [])])),
              }],
            });
          } else if (isActiveFormIonListGroup(fieldGroup)) {
            const wholeGroup = fieldsList.filter(
              (f) => f.ionFieldGroup?.key === fieldGroup.key,
            );
            const getEmptyListItem = () => Object.fromEntries(
              Object.values(wholeGroup).map(
                (field) => ([field.key, field.defaultValue || undefined]),
              ),
            );
            acc.push({
              tag: 'listGroup',
              key: fieldGroup.key,
              label: fieldGroup.label,
              isReadonly: fieldGroup.isReadonly,
              groupOrder: fieldGroup.groupOrder,
              withInitValue: fieldGroup.withInitValue,
              fields: [{
                ...field,
                state: computed(() => ([...(config.fieldState?.value ?? []), ...arrayFrom(field.state ?? [])])),
              }],
              getItemLabel: fieldGroup.getItemLabel,
              getEmptyListItem,
              renderReadonly: fieldGroup.renderReadonly,
            });
          }
        }
      } else {
        acc.push({
          ...field,
          state: computed(() => ([...(config.fieldState?.value ?? []), ...arrayFrom(field.state ?? [])])),
        });
      }
      return acc;
    }, [] as (ActiveFormGroupNext|ActiveFormField<Model>)[]),
  );
  const computeModel = () => fieldGroups.value.reduce((acc, fieldOrGroup) => {
    if (isIonFormGroup(fieldOrGroup)) {
      if (isIonFormIonFieldGroup(fieldOrGroup)) {
        fieldOrGroup.fields.forEach((field) => {
          // @ts-ignore
          acc[field.key] = field.defaultValue;
        });
      } else {
        acc[fieldOrGroup.key] = fieldOrGroup.withInitValue
          ? [fieldOrGroup.getEmptyListItem()]
          : [];
      }
    } else {
      acc[fieldOrGroup.key] = fieldOrGroup.defaultValue || null;
    }

    return acc;
  }, {} as Record<any, any>);

  const modelLocal = ref<Model>(computeModel());

  const isTouched = ref(false);

  if (config.modelValue?.value) {
    watch(config.modelValue, () => {
      if (config.modelValue) {
        modelLocal.value = JSON.parse(
          JSON.stringify(config.modelValue.value || computeModel()),
        );
      }
      isTouched.value = false;
    }, { immediate: true });
  }

  const resetModel = () => {
    modelLocal.value = JSON.parse(
      JSON.stringify(config.modelValue?.value || computeModel()),
    );
  };

  const updateModelField = ({ keyPath, value, field }: OnUpdateFieldPayload<Model, any>) => {
    isTouched.value = true;
    const models = [modelLocal.value] as Model[];
    if (config.syncOnUpdate && config.modelValue?.value) {
      models.push(config.modelValue.value);
    }
    models.forEach((model) => {
      if (field?.onUpdateModelValue) {
        field.onUpdateModelValue(model, value);
      }
      // @ts-ignore
      keyPath.reduce((acc, key, i, arr) => {
        if (i === arr.length - 1) {
          acc[key] = value;
        }
        return acc[key];
      }, model);
    });
  };

  const addModelListItem = ({
    keyPath,
  }: { keyPath: [string] }) => {
    isTouched.value = true;
    const [key] = keyPath;
    // TODO переписать для любой глубины
    const group = (fieldGroups.value as ActiveFormListGroupNext[]).find(
      (gr) => gr.key === key,
    );
    if (group) {
      const models = [modelLocal.value] as Model[];
      if (config.syncOnUpdate && config.modelValue?.value) {
        models.push(config.modelValue.value);
      }
      models.forEach((model) => {
        // @ts-ignore
        (model[key] as any[]).push(group.getEmptyListItem());
      });
    }
  };

  const deleteModelListItem = ({
    keyPath,
  }: { keyPath: [string, string ]}) => {
    const [pKey, sKey] = keyPath;
    isTouched.value = true;
    const models = [modelLocal.value] as Model[];
    if (config.syncOnUpdate && config.modelValue?.value) {
      models.push(config.modelValue.value);
    }
    models.forEach((model) => {
      // @ts-ignore
      model[pKey].splice(sKey as number, 1);
    });
  };

  return {
    model: modelLocal,
    isTouched,
    resetModel,
    fieldGroups,
    updateModelField,
    addModelListItem,
    deleteModelListItem,
  };
};
