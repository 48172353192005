export enum PlateIndicatorValueType {
  Number = 'number',
  PlusNumber = 'plusNumber',
  Percent = 'percent',
  PlusPercent = 'plusPercent',
  MinusPercent = 'minusPercent',
  Money = 'money',
  MoneyShort = 'moneyShort',
}

export enum PlateIndicatorType {
  Simple = 'simple',
  WithPrevValue = 'with_prev_value',
  Fraction = 'fraction',
  Multi = 'Multi',
  Line = 'line',
  Bar = 'bar',
  Pie = 'Pie',
  List = 'List'
}
