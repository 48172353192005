import { Ref } from 'vue';
import { unwrapListingApiResponse } from '@/service/api';
import { useRosreestr } from '@/hooks/useRosreestr';

export function useFetchEstateOwners() {
  const {
    fetchEstateObjectCharacteristics,
  } = useRosreestr();

  function fetchEstateOwners(debtorId: Ref<number>) {
    return fetchEstateObjectCharacteristics({
      limit: 1000,
      page: 1,
      filters: {
        debtor_id: debtorId.value,
        active: true,
      },
    }).then(unwrapListingApiResponse).then((r) => r.response);
  }

  return { fetchEstateOwners };
}
