import { DebtNotificationKey } from '@/hooks/useCompanies';
import { PretrialClaimSettings } from '@/hooks/usePretrialClaimSettings';
import {
  CompanySettingsPretrial,
  PretrialAutomationModel,
  AutoTabKey,
} from '@/components/automation/pretrial/tabs/types';
import { PeriodicTaskSchedule } from '@/hooks/useSchedule';

export const getBlankPretrialClaim = (number: number) => ({
  number,
  enabled: false,
  threshold: 0,
  days: 0,
  pochta_ru: false,
  is_ordered: false,
  email: false,
}) as unknown as PretrialClaimSettings;

export const getEmptyModel = () => ({
  auto_create_sms_notifications: true,
  auto_create_voice_notifications: true,
  auto_create_email_notifications: true,
  voice_notifications_by_period: true,
  voice_weekday_period_from: null,
  voice_weekday_period_to: null,
  voice_weekend_or_holiday_period_from: null,
  voice_weekend_or_holiday_period_to: null,
  voice_timezone_by_address: true,
  voice_auto_exclude_numbers: true,
  voice_excluding_all_numbers_statuses: [],
  voice_excluding_current_numbers_statuses: [],
  voice_assigned_user: 0,
  auto_court_transfer: false,
  actual_debt: false,
  court_days_overdue_enabled: false,
  court_days_overdue: null,
  court_threshold_enabled: false,
  court_threshold: 0,
  debt_notifications_enabled: false,
  debt_notification_threshold: 0,
  debt_notification_failed_attempts: 1,
  voice_send_sms_if_unavailable: true,
  debt_notifications_period: null,
  debt_notifications_period_sms: null,
  debt_notifications_period_voice: null,
  debt_notifications_period_email: null,
  debt_notifications: ['sms', 'voice', 'email'] as Array<DebtNotificationKey>,
  dialing_redial_interval: 0,
  enabled_debt_notifications: ['sms', 'voice'] as Array<DebtNotificationKey>,
  max_dialing_attempts: 0,
  pretrial_claim_enabled: false,
  pretrial_claim_period: null,
  pretrial_claim_send_type: null,
  pretrial_claim_threshold: 0,
  pretrial_claims: [
    getBlankPretrialClaim(1),
    getBlankPretrialClaim(2),
    getBlankPretrialClaim(3),
  ],
  request_recall_dialing_redial_interval: 0,
  transfer_to_operator_number: '',
  kvint_campaign_id: '',
}) as PretrialAutomationModel;

const timeToScheduleTime = (str: string) => {
  const [hour, minute, second] = str.split(':');

  return {
    hour: hour.startsWith('0') ? hour.substring(1, 2) : hour,
    minute: minute.startsWith('0') ? '0' : minute,
    day_of_month: '*',
    day_of_week: '*',
    month_of_year: '*',
  };
};

const scheduleTimeToTime = ({ hour, minute }: PeriodicTaskSchedule): string => [hour, minute].join(':');

export const toModel = (settings: CompanySettingsPretrial): PretrialAutomationModel => {
  const modelKeys = Object.keys(getEmptyModel()) as (keyof PretrialAutomationModel)[];
  const model = Object.fromEntries(
    // @ts-ignore
    modelKeys.map((key) => ([key, settings[key]])),
  ) as PretrialAutomationModel;

  return {
    ...model,
    voice_weekday_period_from: timeToScheduleTime(settings.voice_weekday_period_from),
    voice_weekday_period_to: timeToScheduleTime(settings.voice_weekday_period_to),
    voice_weekend_or_holiday_period_from: timeToScheduleTime(settings.voice_weekend_or_holiday_period_from),
    voice_weekend_or_holiday_period_to: timeToScheduleTime(settings.voice_weekend_or_holiday_period_to),
    court_days_overdue_enabled: !!settings.court_days_overdue,
    court_threshold_enabled: !!settings.court_threshold,
  };
};

export const toSettings = (model: PretrialAutomationModel) => ({
  ...model,
  voice_weekday_period_from: scheduleTimeToTime(model.voice_weekday_period_from as PeriodicTaskSchedule),
  voice_weekday_period_to: scheduleTimeToTime(model.voice_weekday_period_to as PeriodicTaskSchedule),
  voice_weekend_or_holiday_period_from: scheduleTimeToTime(model.voice_weekend_or_holiday_period_from as PeriodicTaskSchedule),
  voice_weekend_or_holiday_period_to: scheduleTimeToTime(model.voice_weekend_or_holiday_period_to as PeriodicTaskSchedule),
});

export function fieldGroup(group: AutoTabKey | 'common') {
  return {
    tag: 'fieldGroup',
    key: group.toString(),
  };
}

export function fieldsUniter(name: AutoTabKey | 'common', fields: Record<string, unknown>[]) {
  return fields.map((field) => ({
    ...field,
    ionFieldGroup: fieldGroup(name),
  }));
}
