import {
  onMounted, ref, Ref, watch,
} from 'vue';
import { Debtor } from '@/hooks/useDebtors';
import { useApi } from '@/hooks/useApi';
import { ApiCommand } from '@/store/modules/api';
import {
  useIncomeSourceDialogs,
} from '@/components/dialog/dialogs/debtor/tabs/finance/incomeSources/useIncomeSourceDialog';
import {
  IncomeSourceApiModel,
} from '@/components/dialog/dialogs/debtor/tabs/finance/incomeSources/types';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import {
  useIncomeSourcesCrud,
} from '@/components/dialog/dialogs/debtor/tabs/finance/incomeSources/useIncomeSourcesCrud';

type IncomeSourcesModelView = {
  id: number;
  title: string;
  table: {
    label: string;
    value: string;
  }[];
  apiModel: IncomeSourceApiModel;
}[];

export const useIncomeSource = () => {
  const { debtor } = useInjectDebtorDialog();
  const incomeSources: Ref<IncomeSourcesModelView> = ref([]);
  const { openUpdateDialog, openCreateDialog } = useIncomeSourceDialogs();
  const isLoading = ref<boolean>(false);
  const {
    fetch: fetchIncomeSources, deleteIncomeSource,
  } = useIncomeSourcesCrud();

  function title(v: IncomeSourceApiModel) {
    return v.income_from === 'ip'
      ? `ИП ${v.name}`
      : v.income_from === 'ul'
        ? `Юридическое лицо ${v.name}`
        : 'Самозанятый';
  }

  function ogrn(v: IncomeSourceApiModel) {
    if (!ipOrUl(v)) {
      return [];
    }
    return [{
      label: v.income_from === 'ip' ? 'ОГРНИП ' : 'ОГРН ',
      value: v.ogrn,
    }];
  }

  function addresses(v: IncomeSourceApiModel) {
    return ipOrUl(v) ? [
      { label: 'Юридический адрес', value: v.reg_addr },
      { label: 'Фактический адрес', value: v.fact_addr },
    ] : [
      { label: 'Адрес', value: v.fact_addr },
    ];
  }

  function relationValue(v: IncomeSourceApiModel) {
    const result = [
      v.is_founder ? 'Учредитель' : null,
      v.is_general_director ? 'Генеральный директор' : null,
    ].filter(Boolean).join(', ');
    if (result.length) {
      return result;
    }
    return null;
  }

  function ipOrUl(v: IncomeSourceApiModel) {
    return v.income_from === 'ul' || v.income_from === 'ip';
  }

  function relation(v: IncomeSourceApiModel) {
    if (!ipOrUl(v)) {
      return [];
    }
    return [{
      label: 'Должность',
      value: relationValue(v),
    }];
  }

  const fetch = async () => {
    if (!debtor) return;
    isLoading.value = true;
    const response = await fetchIncomeSources(debtor);
    incomeSources.value = response.results.map((v: IncomeSourceApiModel) => ({
      id: v.id,
      title: title(v),
      apiModel: v,
      table: [
        ...relation(v),
        { label: 'ИНН', value: v.inn },
        ...ogrn(v),
        ...addresses(v),
      ],
    }));
    isLoading.value = false;
  };

  onMounted(fetch);
  watch(debtor, fetch);

  async function onAddSource() {
    const result = await openCreateDialog();
    // @ts-ignore
    result?.status && await fetch();
  }

  function onEditSource(item: IncomeSourceApiModel) {
    console.log('edit', item.id, item);
    return openUpdateDialog(item.id, item);
  }

  async function onDelete(id: number) {
    await deleteIncomeSource(id);
    isLoading.value = true;
    await fetch();
    isLoading.value = false;
  }

  return {
    isLoading,
    incomeSources,
    onAddSource,
    onEditSource,
    onDelete,
  };
};
