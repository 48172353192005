<template>
  <div
    :class="$style.addSourceActions"
  >
    <Btn
      id="modal_debtor_finance_tab_income_source_tab_add_source_btn"
      :class="$style.addSource"
      state="tertiary"
      label="Добавить"
      @click="onAddSource"
    />
  </div>
  <div
    v-for="(source, i) in incomeSources"
    :key="i"
    v-loading="isLoading"
    style="margin: 24px 0; caret-color: transparent;"
  >
    <div>
      <div :class="$style.incomeTitle">
        {{ source.title }}
      </div>
      <div style="display: flex; flex-direction: column; float: right;">
        <Btn
          :id="`modal_debtor_finance_tab_income_source_tab_edit_source_btn_${i}`"
          :class="$style.addSource"
          state="tertiary"
          label="Редактировать"
          @click="onEditSource(source.apiModel)"
        />
        <Btn
          :id="`modal_debtor_finance_tab_income_source_tab_remove_source_btn_${i}`"
          :class="$style.addSource"
          state="tertiary"
          label="Удалить"
          @click="onDelete(source.id)"
        />
      </div>
    </div>
    <table>
      <tr
        v-for="(record, j) in source.table"
        :key="j"
      >
        <td :class="$style.incomeField">
          {{ record.label }}
        </td>
        <td
          v-if="record.value"
          :class="$style.incomeValue"
        >
          {{ record.value }}
        </td>
        <td v-else>
          <NA />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ActiveTable from '@/components/activeTable/ActiveTable.vue';
import Tabs from '@/components/tabs/Tabs.vue';
import SelectInput from '@/components/selectInput/SelectInput.vue';
import NA from '@/components/na/NA.vue';
import Btn from '@/components/btn/Btn.vue';
import {
  useIncomeSource,
} from '@/components/dialog/dialogs/debtor/tabs/finance/incomeSources/useIncomeSource';

export default defineComponent({
  name: 'IncomeSource',
  components: {
    ActiveTable,
    Tabs,
    SelectInput,
    NA,
    Btn,
  },
  setup() {
    return useIncomeSource();
  },
});
</script>

<style lang="scss" module>
@import "./styles";
</style>
