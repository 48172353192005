import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { ISelectInput } from '@/components/selectInput/useSelectInput';
import { computed, Ref, ref } from 'vue';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { ITextInput, ITextInputType } from '@/components/textInput/useTextInput';

export function useFields(
  isEmail: Ref<boolean>,
  step: Ref<number>,
  sendersOptions: Ref<unknown[]>,
) {
  const { t } = useLocalI18n('exchange.integration.smsc');

  const login = {
    key: 'login',
    field: 'login',
    label: t('form.login'),
    type: ActiveFormFieldType.input,
  };

  const password = {
    key: 'password',
    field: 'password',
    label: t('form.password'),
    type: ActiveFormFieldType.input,
    options: {
      type: ITextInputType.password,
    },
  };

  const sender = (type: ActiveFormFieldType, options: Partial<ISelectInput<any>> | Partial<ITextInput>) => ({
    key: 'sender_name',
    field: 'sender_name',
    label: t('form.sender_name'),
    type,
    options,
  });

  const senderSelect = computed(() => {
    return sender(ActiveFormFieldType.select, {
      options: sendersOptions.value,
      displayField: 'sender',
      valueField: 'sender',
    });
  });

  const senderString = computed(() => {
    return sender(ActiveFormFieldType.input, {
      placeholder: 'Email',
    });
  });

  const fields = computed(() => {
    console.log('is email', isEmail.value);
    return step.value === 0
      ? isEmail.value
        ? [login, password, senderString.value]
        : [login, password]
      : [senderSelect.value];
  });

  return { fields };
}
