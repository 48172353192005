/* eslint-disable object-curly-newline */
import { ActiveTableColumnFormat } from '@/components/activeTable/useActiveTable';

const width = '150px';

export const fsspRequestsColumns = [
  { key: 'counter', label: '№' },
  { key: 'ls', label: 'Номер договора', width },
  { key: 'serial_number', label: '№ ИП', field: 'writ_of_execution.serial_number', width },
  { key: 'uchastok', label: 'Участок ФССП', width },
  { key: 'status_current', label: 'Статус', width },
  { key: 'request_date', label: 'Дата запроса', format: ActiveTableColumnFormat.date, width },
  { key: 'request_type', label: 'Способ обращения', width },
  { key: 'order_id', label: '№ заявления', width },
  { key: 'action', label: 'Вид обращения', width },
  { key: 'request_template.name', label: 'Шаблон обращения', width },
  { key: 'response_date', label: 'Дата ответа', format: ActiveTableColumnFormat.date, width },
  { key: 'response', label: 'Текст ответа', width: '500px' },
  { key: 'control_date', label: 'Дата контроля', format: ActiveTableColumnFormat.date, width },
];
