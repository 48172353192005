import { computed, Ref } from 'vue';
import { ITab } from '@/components/tabs/useTabs';
import { ProductionType } from '@/hooks/useConstructor';
import { useDialogRouteParam } from '@/hooks/useDialog';
import { useLocalI18n } from '@/hooks/useLocalI18n';

export type ActiveTabKey = 'common' | 'documents' | 'finance' | 'notifications' | 'courts' | 'executions';

export const useDebtorDialogTabs = (
  productionType: Ref<ProductionType>,
  isCurrent: Ref<boolean>,
) => {
  const { t } = useLocalI18n('debtor');

  const tabs = computed<Array<ITab<ActiveTabKey>>>(() => ((([
    {
      key: 'common',
      label: t('common.tab'),
      icon: 'info',
    },
    {
      key: 'documents',
      label: t('documents.tab'),
      icon: 'documents',
    },
    {
      key: 'finance',
      label: t('finance.tab'),
      icon: 'wallet',
    },
    productionType.value === ProductionType.pretrial && {
      key: 'notifications',
      label: t('notifications.tab'),
      icon: 'bell',
    },
    (productionType.value === ProductionType.pretrial || productionType.value === ProductionType.judicial) && {
      key: 'courts',
      label: t('courts.tab'),
      icon: 'mace',
    },
    productionType.value === ProductionType.executive && {
      key: 'executions',
      label: t('executions.tab'),
      icon: 'guard',
    },
    {
      key: 'history',
      label: t('history.tab'),
      icon: 'clock',
    },
  ] as Array<ITab<ActiveTabKey> | boolean>))
    .filter(Boolean) as Array<ITab<ActiveTabKey>>).map((tab) => ({
    ...tab,
    id: `modal_debtor_tab_${tab.key}`,
  })));

  const [activeTab] = useDialogRouteParam<ActiveTabKey>(
    'tab',
    'common',
    isCurrent,
  );

  return {
    tabs,
    activeTab,
  };
};
