import { useApi } from '@/hooks/useApi';
import {
  ApiCommand, ApiResponse, ListingRequestSource, ListingResponse, OrderDirection,
} from '@/store/modules/api';
import { formatListingRequest } from '@/components/activeTable/useActiveTable';

export type VoiceCallQueue = {
  id: number;
  scheduled: number;
  called: number;
  created_at: Date;
  updated_at: Date;
  stopped: boolean;
  is_auto: boolean;
  company: number;
}

export type FetchVoiceCallQueueFilters = {
  company: number;
  id?: number;
}

export type FetchVoiceCallQueueModel = ListingRequestSource<FetchVoiceCallQueueFilters & {limit: number; page: number}>

export type FetchVoiceCallQueueResponse = ListingResponse<VoiceCallQueue>

export const useVoiceCallQueueApi = () => {
  const api = useApi();

  const updateVoiceCallQueue = (
    data: { stopped: VoiceCallQueue['stopped'] },
    id: number,
  ) => api<VoiceCallQueue>({
    command: ApiCommand.updateVoiceCallQueue,
    params: { id },
    data,
  });

  const deleteVoiceCallQueue = (id: number) => api<boolean>({
    command: ApiCommand.deleteVoiceCallQueue,
    params: { id },
  });

  return {
    updateVoiceCallQueue,
    deleteVoiceCallQueue,
  };
};
