export const matches: [string, string][] = [
  ['№', 'номер'],
  ['данные паспорта', 'Номер паспорта'],
  ['кем выдан', 'Кем выдан паспорт'],
  ['дата выдачи', 'Дата выдачи паспорта'],
  ['начислено за месяц', 'Начисление'],
  ['перерасчет за период (начисление разово)', 'Перерасчет'],
  ['e-mail', 'Электронная почта'],
  ['мобильный телефон', 'Номер телефона'],
  ['оплата за период', 'Оплата'],
  ['оплачено', 'Оплата'],
  ['серия и номер', 'Номер паспорта'],
  ['лицевой счет', 'Лицевой счёт \\ Договор'],
  ['период', 'Период задолженности'],
  ['ответственный собственник', 'ФИО / Орг'],
  ['адрес', 'Адрес (ФЛ и ЮЛ)'],
  ['входящий дебет', 'Часть входящего сальдо'],
  ['входящий кредит', 'Часть входящего сальдо'],
  ['исходящий дебет', 'Часть исходящего сальдо'],
  ['исходящий кредит', 'Часть исходящего сальдо'],
];
