import { computed, Ref, ref } from 'vue';
import { ProductionType } from '@/hooks/useConstructor';
import { useUserPrefs } from '@/hooks/useUserPrefs';

export const useDebtorsModuleUserPrefs = () => {
  const {
    updateUserPrefs,
  } = useUserPrefs();

  const updateModuleActions = <T>(
    { actions, module_type }: {actions: T; module_type: ProductionType },
  ) => {
    updateUserPrefs({
      interface_preferences: {
        [module_type]: {
          actions: { ...actions },
        },
      },
    });
  };

  const updateModuleColumns = (
    { visible_columns, debtor_per_page, module_type }:
      {visible_columns: string[]; debtor_per_page: number; module_type: ProductionType },
  ) => updateUserPrefs({
    debtor_per_page,
    interface_preferences: {
      [module_type]: {
        visible_columns,
      },
    },
  });

  return {
    updateModuleActions, updateModuleColumns,
  };
};
