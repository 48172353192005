/* eslint-disable eqeqeq */
// @ts-nocheck forms
import { Tile, TileTimeInterval } from '@/service/api/reporting/tile';
import { ReportingChartFormModel } from '@/components/forms/form/reportingChartForm/form';
import { ReportingDataModelConfig } from '@/service/api/reporting/dataModelConfig';
import {
  getGroupedLines,
  prepareGroupedLines,
} from '@/components/forms/form/reportingChartForm/new/filters';
import { chartIntervals } from '@/components/forms/form/reportingChartForm/new/intervals';
import { isEqual } from '@/utils/object';
import { chartFormFields } from '@/components/forms/form/reportingChartForm/new/fields';

const intervalsAreEqual = (
  a: TileTimeInterval, b: TileTimeInterval,
) => (a.years == b.years || (!a.years && !b.years))
  && (a.months == b.months || (!a.months && !b.months))
  && (a.weeks == b.weeks || (!a.weeks && !b.weeks))
  && (a.days == b.days || (!a.days && !b.days));

const getTileTimeIntervalKey = (tile: Tile): string|null => {
  const { interval, start } = tile.parameters;
  if (!interval) return null;
  return chartIntervals.find(
    (v) => intervalsAreEqual(v.value.interval, interval) && intervalsAreEqual(v.value.start, start),
  )?.key ?? null;
};

export const tileToForm = (
  tile: Tile,
  availableModels: ReportingDataModelConfig[],
): ReportingChartFormModel => {
  const timeIntervalKey = getTileTimeIntervalKey(tile);
  const formFields = chartFormFields.filter((f) => f.for_whole_period !== !!timeIntervalKey);
  const lineGroupsRaw = getGroupedLines(
    tile.parameters.lines,
    availableModels,
    tile.render_parameters[0],
    formFields,
  );
  const lineGroups = lineGroupsRaw.map(prepareGroupedLines);

  const lineGroupsPrepared = lineGroups.map((group) => {
    const groupFiltersMap = {};
    group.lines.forEach((line, i) => {
      line.filters.forEach((f) => {
        const serializedFilter = JSON.stringify(f);
        if (!groupFiltersMap[serializedFilter]) {
          groupFiltersMap[serializedFilter] = 0;
        }
        groupFiltersMap[serializedFilter] += 1;
      });
    });
    const commonFilters = Object.entries(groupFiltersMap).reduce((acc, [key, count]) => {
      if (count === group.lines.length) {
        acc.push(JSON.parse(key));
      }
      return acc;
    }, [] as any);

    return {
      ...group,
      filters: commonFilters,
      lines: group.lines.map((line) => ({
        ...line,
        filters: line.filters.filter(
          (f) => !commonFilters.find((cf) => isEqual(f, cf)),
        ),
      })),
    };
  });
  const rParams = tile.render_parameters[0];

  const variant = rParams.plate_indicator_type && rParams.plate_indicator_variant
    ? `${rParams.plate_indicator_type}--${rParams.plate_indicator_variant}`
    : null;

  return {
    name: tile.name,
    timeInterval: getTileTimeIntervalKey(tile),
    type: rParams.type,
    variant,
    seriesVariant: rParams.line_variant_default ?? null,
    lineGroups: lineGroupsPrepared,
    show_timeline_percent: rParams.show_timeline_percent,
    show_growth: rParams.show_growth,
    stack_group: rParams.stack_group,
    ...(
      tile.all_companies || Array.isArray(tile.companies)
        ? {
          use_not_for_current_company: true,
          companies: tile.companies || [],
          all_companies: tile.all_companies ?? false,
        } : {}
    ),
  };
};
