import { useDocumentsApi } from '@/hooks/useDocumentsApi';
import {
  useFetchFsspRequestsHistory,
} from '@/components/dialog/dialogs/debtor/tabs/executions/useFetchFsspRequestsHistory';
import { useClaimsApi } from '@/components/dialog/dialogs/debtor/tabs/documents/useClaimsApi';
import {
  useLoanAgreementsApi,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useLoanAgreementsApi';

export const useDocsCommonRequests = () => {
  const apiEndpoints = useDocumentsApi();

  const { translateApplication, fetchTranslations } = useFetchFsspRequestsHistory();
  const { fetchClaims } = useClaimsApi();
  const { fetchLoanAgreements } = useLoanAgreementsApi();

  const fsspHistory = async (...args: any) => {
    await fetchTranslations();
    // @ts-ignore
    const result = await apiEndpoints.fetchFsspHistoryDocuments(...args);
    const theResult = {
      ...result,
      response: {
        ...result.response,
        results: result.response.results.map((item) => ({
          ...item,
          request_name: translateApplication(item.action),
        })),
      },
    };
    return theResult;
  };

  return {
    common: apiEndpoints.fetchGeneralDocumentsFlow,
    housebook: apiEndpoints.fetchHousebook,
    photos: apiEndpoints.fetchDocumentsImages,
    claims: fetchClaims,
    loan_agreement: fetchLoanAgreements,
    sms: apiEndpoints.fetchSms,
    voice: apiEndpoints.fetchVoice,
    fns: apiEndpoints.fetchFnsHistory,
    fssp: fsspHistory,
    banks: apiEndpoints.fetchBankHistory,
    executions: apiEndpoints.fetchWritsOfExecution,
    identity: apiEndpoints.fetchDebtorIdentityDocuments,
    bc_identity: apiEndpoints.fetchDebtorBCIdentityDocuments,
    inn_identity: apiEndpoints.fetchDebtorInnIdentityDocuments,
  };
};
