/* eslint-disable prefer-destructuring */
import { createI18n } from 'vue-i18n';
// @ts-ignore
import ru from './locales/ru.json';

export const I_18N_CONFIG = {
  locale: 'ru',
  fallbackLocale: 'ru',
};

const i18n = createI18n({
  locale: I_18N_CONFIG.locale,
  fallbackLocale: I_18N_CONFIG.fallbackLocale,
  pluralizationRules: {
    ru: (choice, choicesLength) => {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
  messages: {
    ru,
  },
});

// @ts-ignore
export const t = i18n.global.t as (v: string, opts?: Record<any, any>) => string;

export default i18n;
