import { computed, ref } from 'vue';

export const useListenDialog = () => {
  const duration = ref(0);

  const computedDuration = computed(() => (
    [Math.floor(duration.value / 60), duration.value % 60].map(
      (item) => String(item).padStart(2, '0'),
    ).join(':')
  ));

  const onLoadStart = () => {
    duration.value = 0;
  };

  const audioRef = ref<HTMLAudioElement>();
  const onLoad = async () => {
    await new Promise(requestAnimationFrame);
    if (!audioRef.value) {
      return;
    }
    duration.value = Math.floor(audioRef.value?.duration);
  };

  const error = ref<string>();
  const onError = (err: string) => {
    error.value = err;
  };

  return {
    error,
    onError,

    duration,
    computedDuration,
    audioRef,

    onLoad,
    onLoadStart,
  };
};
