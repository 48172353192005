import { ActiveTableColumnFormat } from '@/components/activeTable/useActiveTable';
import { formatMoney, formatNumber, formatPhone } from '@/utils/string';
import { formatDate, formatDateTime, formatMonthAndYearShort } from '@/utils/date';
import { Duration } from 'luxon';

const toDate = (v: Date|string|undefined): any => {
  if (!v) return undefined;
  if (v instanceof Date) {
    return v;
  }
  const d = new Date(v);
  if (d.toString() === 'Invalid Date') return undefined;
  return d;
};

export const valueFormattersMap = {
  [ActiveTableColumnFormat.number]:
    (value: any, locale: string) => formatNumber(value, locale),
  [ActiveTableColumnFormat.string]:
    (value: any, locale: string) => value,
  [ActiveTableColumnFormat.date]:
    (value: any, locale: string) => formatDate(toDate(value), locale),
  [ActiveTableColumnFormat.dateYearMonth]:
    (value: any, locale: string) => formatMonthAndYearShort(
      toDate(value), locale,
    ),
  [ActiveTableColumnFormat.dateTime]:
    (value: any, locale: string) => formatDateTime(toDate(value), locale),
  [ActiveTableColumnFormat.money]:
    (value: any, locale: string) => formatMoney(+value, 'RUB', locale),
  [ActiveTableColumnFormat.phone]:
    (value: any, locale: string) => formatPhone(value),
  [ActiveTableColumnFormat.duration]:
    (
      value: any, locale: string,
    ) => {
      if (!value) return null;
      return Duration.fromMillis(value).toFormat('mm:ss');
    },
};
