import { useStore } from 'vuex';
import {
  ApiCommand,
  ApiResponse,
  ListingRequestSource,
  ListingResponse
} from '@/store/modules/api';
import { ProductionType } from '@/hooks/useConstructor';
import { Company, PenaltyKeyRateMode } from '@/hooks/useCompanies';
import { Debtor } from '@/hooks/useDebtors';
import { CompanyModuleType, DebtorQuery } from '@/pages/debtors/_module/useDebtorsActions';
import { useApi } from '@/hooks/useApi';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';
import { ComputedRef } from 'vue';

export type DocumentAttachment = {
  id?: number;
  production_type: ProductionType;
  type: string;
  name: string;
  attachment_name?: string;
  order_number: number;
  is_active: boolean;
  is_show: boolean;
  company: Company['id'];
  document: null|number;
  file?: null|string;
  group_name: string;
  rename_attachment_name: boolean;
  for_automatic: boolean;
  for_registered_letters: boolean;
  subgroup_name: string | null;
}

export type PrintDocument = {
  key: string;
  name: string;
  attachment_name?: string;
  order: number;
  document_id?: number;
  is_active: boolean;
  template_name?: string;
  group_name?: string;
  subgroup_name?: string;
}

export const mapDocumentAttachmentToPrintDocument = (doc: DocumentAttachment) => ({
  key: doc.type,
  name: doc.name,
  order: doc.order_number,
  is_active: doc.is_active,
  for_registered_letters: doc.for_registered_letters,
  ...(doc.document ? { document_id: doc.document } : {}),
  ...(doc.attachment_name ? { attachment_name: doc.attachment_name } : {}),
  ...(doc.group_name ? { group_name: doc.group_name } : {}),
  ...(doc.subgroup_name ? { subgroup_name: doc.subgroup_name } : {}),
});

export type BulkModelDocumentAttachment = Omit<DocumentAttachment, 'id'> & { id?: number }

export type PrintType = string | null;

export type FetchCompanyAttachmentsModel = ListingRequestSource<{
  production_type?: ProductionType;
  company_id: Company['id'];
  type?: string;
  group_name?: string;
  subgroup_name?: string;
  for_registered_letters?: boolean;
  for_automatic?: boolean;
  order_number?: number;
}>

export type FetchCompanyAttachmentsResponse = ListingResponse<DocumentAttachment>;

export type FetchEnforcementsApplicationsResponse = {
  applications: {
    value: string;
    label: string;
    complaint_text: boolean;
    extra: {[key: string]: {type: string; label?: string; choices?: []}}|null;
  }[];
}

export type UploadEnforcementsApplicationsModel = {
  company_id: Company['id'];
  action: string;
  debtor_ids: Debtor['pk'][];
  resolution_type?: string;
  petition_type?: string;
  printed: PrintDocument[];
  [key: string]: any;
}

export type FetchDefaultAttachmentsResponse = {
  attachments: {
    [key: string]: {
      name: string;
      rename_attachment_name: boolean;
    };
  };
  productions: {
    [key in ProductionType]: string[]
  };
};

export type GenerateMergedAttachmentsModel = {
  production_type: ProductionType;
  company_id: Company['id'];
  filters?: Partial<DebtorQuery> & {
    complaint_6_1?: number|null;
    module?: CompanyModuleType; // todo check if it's needed
  };
  pochta_ru: boolean;
  appeal_fns?: boolean;
  is_ordered: boolean;
  offline_court?: boolean;
  send_draft_court_order?: boolean;
  court_complaint_overdue?: number;
  config: {
    date_from?: string | null;
    date_to?: string | null;
    encrypt: boolean;
    encrypt_test?: boolean;
    court_test: boolean; // Тестовая отправка в суд
    scan_mode: boolean;
    moratorium_enabled: boolean;
    penalty_key_rate_mode: PenaltyKeyRateMode;
    printed: PrintDocument[];
    complaint?: string;
    // 0 - по умолчанию (контур)
    // 1 - контур
    // 2 - локально (новый)
    encrypt_provider?: 0|1|2;
    type?: PrintType;
    return_duplicate_payments: boolean;
    group_name: PrintType;
  };
}

export type GenerateMergedAttachmentsResponse = {
  id: number;
};

export type CreateBulkAttachmentsModel = {
  production_type: ProductionType;
  company_id: Company['id'];
  attachments: Array<BulkModelDocumentAttachment>;
  for_automatic: boolean;
  for_registered_letters: boolean;
}

export type CreateBulkAttachmentsResponse = any;

export type FillingCourtReport = {
  id: number;
  company: number;
  file: string;
}

export type PrintReport = {
  id: number;
  report: string;
  company: number;
}

export const usePrint = () => {
  const apiRequest = useApi();
  const store = useStore();
  const fetchCompanyAttachments = async (
    payload: FetchCompanyAttachmentsModel,
  ): Promise<ApiResponse<FetchCompanyAttachmentsResponse>> => {
    const {
      status,
      response,
    } = await store.dispatch('accountDocuments/fetchCompanyAttachments', payload);

    return { status, response };
  };

  const updateCompanyAttachment = async (
    payload: DocumentAttachment,
  ) => apiRequest<DocumentAttachment>({
    command: ApiCommand.updateCompanyAttachment,
    params: {
      id: payload.id as number,
    },
    data: {
      ...payload,
      type: payload.type as string,
    },
  });

  const fetchDefaultAttachments = async (
  ): Promise<ApiResponse<FetchDefaultAttachmentsResponse>> => {
    const { status, response } = await store.dispatch('accountDocuments/fetchDefaultAttachments');

    return { status, response };
  };

  const generateMergedAttachments = async (
    payload: GenerateMergedAttachmentsModel,
  ): Promise<ApiResponse<GenerateMergedAttachmentsResponse>> => {
    const { status, response } = await store.dispatch('accountDocuments/generateMergedAttachments', payload);

    return { status, response };
  };

  const fetchEnforcementsApplications = async () => apiRequest<FetchEnforcementsApplicationsResponse>({
    command: ApiCommand.fetchEnforcementsApplications,
  });

  const uploadEnforcementsApplications = async (
    payload: UploadEnforcementsApplicationsModel,
  ) => apiRequest({
    command: ApiCommand.uploadEnforcementsApplications,
    data: {
      ...payload,
    },
  });

  const createBulkAttachments = async (
    payload: CreateBulkAttachmentsModel,
  ): Promise<ApiResponse<CreateBulkAttachmentsResponse>> => {
    const { status, response } = await store.dispatch('accountDocuments/createBulkAttachments', payload);

    return { status, response };
  };

  const fetchCompanyAttachmentsToPrint = async (
    request: { company_id: Company['id'] },
  ) => apiRequest<FetchCompanyAttachmentsResponse>({
    command: ApiCommand.fetchCompanyAttachmentsToPrint,
    params: {
      company_id: request.company_id,
      distinct: 'name',
      can_be_attached: true,
    },
  });

  const fetchCourtReport = async (
    id: number,
  ) => apiRequest<FillingCourtReport>({
    command: ApiCommand.fetchCourtFillingReport,
    params: { id },
  });

  const fetchPrintReport = async (
    id: number,
  ) => apiRequest<PrintReport>({
    command: ApiCommand.fetchPrintReport,
    params: { id },
  });

  const deleteCompanyDocumentAttachment = (id: number) => commonLegacyApiRequest({
    command: ApiCommand.deleteCompanyDocumentAttachment,
    params: { id },
  });

  return {
    fetchCompanyAttachments,
    fetchDefaultAttachments,
    generateMergedAttachments,
    createBulkAttachments,
    fetchCompanyAttachmentsToPrint,
    fetchCourtReport,
    deleteCompanyDocumentAttachment,
    updateCompanyAttachment,
    fetchEnforcementsApplications,
    uploadEnforcementsApplications,
  };
};
