import * as E from 'fp-ts/Either';
import * as TE from 'fp-ts/TaskEither';
import { arrayFrom } from '@urrobot/web/src/utils/object';

export type FetchInterceptorResponse = {
  bodyJSON: any;
  requestSource: ApiRequest;
  url: string;
}

const normalizeSearchParamValue = (value: any) => {
  if (value === true) return 'true';
  if (value === false) return 'false';
  return value;
};

type ResponseInterceptor = (response: FetchInterceptorResponse) => Promise<E.Either<any, any>>

export type FetchInterceptor = {
  request?: Array<(config: ApiRequest & { headers: Record<string, string>}) => Promise<ApiRequest & { headers: Record<string, string>}>>;
  responseError?: Array<ResponseInterceptor>;
}

export type ApiMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD';

export type ApiRequest = {
  method: ApiMethod;
  url: string;
  params?: Record<string, any>;
  data?: Record<string, any>;
  headers?: Record<string, string>;
  signal?: AbortSignal;
  asFormData?: boolean;
  responseAsFile?: boolean;
  interceptor?: FetchInterceptor;
}

export type ApiServiceConfig = {
  baseUrl: string|(() => string);
  interceptor?: FetchInterceptor;
}

export type ApiResponse3<Suc, Err> = TE.TaskEither<Err, Suc>;

export type ServerError = { code: 'server_error' };

export type StatusCodeError = { code: 402 };

export type JwtAuthError = { code: 'jwt_auth_error' };

export const isJwtAuthError = (e: any): e is JwtAuthError => e && (e as JwtAuthError).code === 'jwt_auth_error';
export const isServerError = (e: any): e is ServerError => e && (e as ServerError).code === 'server_error';

export const getApiRequest = (config: ApiServiceConfig) => {
  const doRequest = async <Suc, Err extends any = any>(
    requestSource: ApiRequest,
  ): Promise<E.Either<Err|ServerError|StatusCodeError|JwtAuthError|null, Suc>> => {
    try {
      const requestInterceptors = [
        ...(config.interceptor?.request ?? []),
        ...requestSource?.interceptor?.request ?? [],
      ];

      const responseErrorInterceptors = [
        ...(config.interceptor?.responseError ?? []),
        ...(requestSource?.interceptor?.responseError ?? []),
      ];

      let request = {
        ...requestSource,
        headers: requestSource.headers || {},
      };
      // eslint-disable-next-line no-restricted-syntax
      for (const func of requestInterceptors) {
        // eslint-disable-next-line no-await-in-loop
        request = await func(request);
      }

      // const request = requestInterceptors.length ? requestInterceptors.reduce(
      //   (result, func) => func(result), requestSource,
      // ) : requestSource;

      const url = new URL(typeof config.baseUrl === 'string' ? config.baseUrl : config.baseUrl());

      const { path, queryParams } = Object.entries(request.params || {}).reduce(
        (acc, [field, value]) => {
          const replacedPath = acc.path.replace(
            new RegExp(`{${field}}`, 'g'),
            encodeURIComponent(
              (typeof value === 'string'
                ? value.replaceAll('/', '|')
                : value) as string,
            ),
          );
          if (replacedPath === acc.path) {
            acc.queryParams[field] = value;
          }
          acc.path = replacedPath;
          return acc;
        },
        {
          path: request.url,
          queryParams: {},
        } as { path: string; queryParams: Record<string, any> },
      );
      url.pathname = url.pathname.substr(1) + path;

      url.search = Object.entries(queryParams)
        .filter(([, value]) => value !== null && value !== undefined)
        .reduce((searchParams, [field, value]) => {
          const normValue = normalizeSearchParamValue(value);
          if (Array.isArray(normValue)) {
            normValue.forEach((v) => {
              searchParams.append(field, v);
            });
          } else {
            searchParams.append(field, normValue);
          }
          return searchParams;
        }, new URLSearchParams())
        .toString();

      const data = request.data ? request.data : undefined;

      let body: string | FormData | null = null;
      if (request.method !== 'GET') {
        if (request.asFormData && data) {
          body = new FormData();
          Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((val) => {
                if (val === null || val === undefined) {
                  (body as FormData).append(key, '');
                } else {
                  (body as FormData).append(key, val);
                }
              });
            } else {
              if (value === null || value === undefined) {
                (body as FormData).append(key, '');
              } else {
                (body as FormData).append(key, value);
              }
            }
          });
        } else {
          body = JSON.stringify(data);
        }
      }

      const headers = {
        ...(request.headers || {}),
      };
      if (request.asFormData) {
        delete headers['Content-Type'];
      } else {
        headers['Content-Type'] = headers['Content-Type'] || 'application/json';
      }

      const resp = await fetch(url.toString(), {
        method: request.method,
        signal: request.signal,
        headers,
        ...(body ? { body } : {}),
      });
      if (resp.status === 500) {
        return E.left({
          code: 'server_error',
          response: await resp.json(),
        });
      }
      const __httpResp = resp;

      const status = arrayFrom(({
        GET: 200,
        POST: [200, 201],
        PATCH: 200,
        PUT: 200,
        DELETE: [204, 202, 200],
      } as {[key in ApiMethod]: number | number[]})[request.method]).includes(resp.status);

      try {
        const response = await (
          request.responseAsFile
            ? resp.blob()
            : (resp.headers.get('Content-Type')?.includes('application/json')
              ? resp.json()
              : Promise.resolve(undefined)));
        if (status) return E.right(response as unknown as Suc);
        if (!responseErrorInterceptors.length) return E.left(response as unknown as Err|ServerError|null);

        let acc = { bodyJSON: response, requestSource, url: url.toString() };
        // eslint-disable-next-line no-restricted-syntax
        for (const func of responseErrorInterceptors) {
          // eslint-disable-next-line no-await-in-loop
          const resp: E.Either<any, any> = await func(acc);
          if (E.isLeft(resp)) {
            const j = resp.left.bodyJSON;
            j.__statusCode = __httpResp.status;
            return E.left(j);
          }
          // @ts-ignore
          acc = { bodyJSON: resp.right, requestSource, url: url.toString() };
        }

        return E.right(acc.bodyJSON as unknown as Suc);
      } catch (e) {
        console.error(e);
        return E.left(null);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === 'no_token') {
          return E.left({ code: 'jwt_auth_error' });
        }
        if (e.name === 'TypeError' && e.message === 'Failed to fetch') {
          const error = new Error(`command: ${requestSource.url}, status: ${(e as any).status}`);
          error.stack = e.stack;
          error.name = 'ApiError';
          console.error(error);
          return E.left(null);
        } if (e.name === 'AbortError') {
          return E.left(null);
        }
        console.error(`unknown api error: ${requestSource.url}, ${e.toString()}`);
        return E.left(null);
      }
      console.error(`unknown api error: ${String(e)}`);
      return E.left(null);
    }
  };

  const teRequest = <Suc, Err extends any = any>(
    requestSource: ApiRequest,
  ): ApiResponse3<Suc, Err|ServerError|null> => TE.tryCatch<Err, Suc>(
    async () => {
      const result = await doRequest<Suc, Err>(requestSource);
      if (E.isRight(result)) return result.right;

      // console.error('api error', result.left);

      throw result.left;
    },
    (e) => e as Err,
  );

  return teRequest;
};
