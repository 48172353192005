import { PeriodicTaskSchedule } from '@/hooks/useSchedule';
import { Nullable } from '@/types/utils';
import { NonEmptyArray } from 'fp-ts/es6/NonEmptyArray';
import { arrayFrom } from '@/utils/object';

export type ScheduleSelectorTab = 'everyday' | 'daysOfWeek' | 'daysOfMonth' | 'monthsDays' | 'monthsOfYear' | 'justTime';

// [[месяц, дни[]]]
// [['1'], ['1','10','11']]
export type ScheduleSelectorMonthDay = [string, string[]]

export type ScheduleSelectorForm = {
  hour: string;
  minute: string;
  day_of_week: string|null;
  day_of_month: string|null;
  month_of_year: string|null;
  month_days: ScheduleSelectorMonthDay[]|null;
}

export type ActiveFormFieldScheduleOptions = {
  availableTabs?: ScheduleSelectorTab[];
  isDisabled?: boolean;
  state?: any;
}

export type IScheduleSelector = {
  modelValue: PeriodicTaskSchedule|NonEmptyArray<PeriodicTaskSchedule>|null;
  availableTabs: ScheduleSelectorTab[];
  allowOneDayOnly: boolean;
  hideActions: boolean;
}

export const modelValueToTab = (m: PeriodicTaskSchedule|PeriodicTaskSchedule[]): ScheduleSelectorTab | null => {
  if (Array.isArray(m) || isMonthsDaysSchedule(m as PeriodicTaskSchedule)) {
    return 'monthsDays';
  }
  if ([m.day_of_month, m.month_of_year, m.day_of_week].every((v) => v === '*')) {
    return 'everyday';
  }
  if (m.day_of_week !== '*' && [m.day_of_month, m.month_of_year].every((v) => v === '*')) {
    return 'daysOfWeek';
  }
  if (m.day_of_month !== '*' && [m.month_of_year, m.day_of_week].every((v) => v === '*')) {
    return 'daysOfMonth';
  }
  if (m.month_of_year !== '*') {
    return 'monthsOfYear';
  }
  return null;
};

export const weekDayOptions: { label: string; key: string }[] = [
  'Пн.', 'Вт.', 'Ср.', 'Чт.', 'Пт.', 'Сб.', 'Вс.',
].map((label, i) => ({
  label,
  key: (i + 1).toString(),
}));

export const monthDaysMap = {
  1: 31,
  2: 29,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
} as const;

export const getInitValueEveryDay = (): ScheduleSelectorForm => ({
  minute: '0',
  hour: '10',
  day_of_week: '*',
  day_of_month: '*',
  month_of_year: '*',
  month_days: null,
});

export const getInitValueDaysOfWeek = (): ScheduleSelectorForm => ({
  minute: '0',
  hour: '10',
  day_of_week: '1,2,3,4,5,6,7',
  day_of_month: '*',
  month_of_year: '*',
  month_days: null,
});

export const getInitValueDaysOfMonth = (): ScheduleSelectorForm => ({
  minute: '0',
  hour: '10',
  day_of_week: '*',
  month_days: null,
  day_of_month: '1',
  month_of_year: '*',
});

export const getInitValueMonthsDays = (): ScheduleSelectorForm => ({
  minute: '0',
  hour: '10',
  day_of_week: '*',
  day_of_month: '*',
  month_of_year: '*',
  month_days: [],
});

export const getInitValueMonthOfYear = (): ScheduleSelectorForm => ({
  minute: '0',
  hour: '10',
  day_of_week: '*',
  day_of_month: '1',
  month_of_year: '1,2,3,4,5,6,7,8,9,10,11,12',
  month_days: null,
});

export const isValidValue = (
  v: Nullable<ScheduleSelectorForm>,
): v is ScheduleSelectorForm => (v.month_days
  ? !!v.month_days.length
  : (['minute', 'hour', 'day_of_week', 'day_of_month', 'month_of_year'] as (keyof ScheduleSelectorForm)[]).every(
    (key) => v[key] !== null && v[key] !== '',
  )
);

export const scheduleToFormValue = (
  v: PeriodicTaskSchedule|NonEmptyArray<PeriodicTaskSchedule>,
): ScheduleSelectorForm => {
  if (Array.isArray(v) || isMonthsDaysSchedule(v as PeriodicTaskSchedule)) {
    const arr = arrayFrom(v) as PeriodicTaskSchedule[];
    const firstEl = arr[0];
    const month_days = arr.reduce((acc, { month_of_year, day_of_month }) => {
      acc.push([
        month_of_year,
        day_of_month.split(','),
      ]);
      return acc;
    }, [] as ScheduleSelectorMonthDay[]);
    return {
      minute: firstEl.minute,
      hour: firstEl.hour,
      day_of_month: null,
      day_of_week: null,
      month_of_year: null,
      month_days,
    };
  }
  return JSON.parse(JSON.stringify(v));
};

export const formValueToSchedule = (
  v: ScheduleSelectorForm,
): PeriodicTaskSchedule|NonEmptyArray<PeriodicTaskSchedule> => {
  if (v.month_days) {
    if (!v.month_days.length) {
      throw new Error('month_days are empty');
    }
    const { minute, hour } = v;
    return v.month_days.map(([month, days]) => ({
      minute,
      hour,
      day_of_month: days.join(','),
      day_of_week: '*',
      month_of_year: month,
    })) as NonEmptyArray<PeriodicTaskSchedule>;
  }
  return {
    minute: v.minute ?? '',
    hour: v.hour ?? '',
    day_of_month: v.day_of_month ?? '',
    month_of_year: v.month_of_year ?? '',
    day_of_week: v.day_of_week ?? '',
  };
};

export const isMonthsDaysSchedule = (
  v: PeriodicTaskSchedule,
) => {
  const hasValue = (v: string) => v !== '' && v !== '*';
  // console.log('isMonthsDaysSchedule', hasValue(v.day_of_week));
  // в monthsDays попадет monthsOfYear если выбран всего один месяц
  // т.к. отличить их невозможно
  return !hasValue(v.day_of_week) && hasValue(v.day_of_month) && hasValue(v.month_of_year) && !v.month_of_year.includes(',');
};
