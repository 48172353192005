import { ListingResponse } from '@/store/modules/api';
import { formatListingRequest } from '@/components/activeTable/useActiveTable';
import { ApiCommand, standartizeRequest as request } from '@/service/standartizeService';
import { parseHousesString } from '@/pages/admin/courts/main/utils';
import {
  CourtJurisdictionGetListModel, CourtJurisdictionItem,
  CourtJurisdictionModel, CourtTypeAndId,
  JurisdictionModelClient,
} from '@/service/standartize/jurisdiction-types';

const mapHousesList = (list: string[]) => {
  if (list.length === 1 && list[0] === '0') return '';
  return list.join(', ');
};

const jurisdictionModelToPayload = (obj: JurisdictionModelClient & CourtTypeAndId) => ({
  ...obj,
  court: obj.court_id,
  addr_obj: obj.addr_obj?.objectid ?? null,
  houses_include: !obj.houses_include ? [0] : parseHousesString(obj.houses_include),
  houses_exclude: !obj.houses_exclude ? [0] : parseHousesString(obj.houses_exclude),
  houses_even: !obj.houses_even ? [0] : parseHousesString(obj.houses_even),
  houses_odd: !obj.houses_odd ? [0] : parseHousesString(obj.houses_odd),
});

export const prepareJurisdiction = (r: CourtJurisdictionModel) => ({
  ...r,
  addr_obj: r.addr_obj,
  houses_include: mapHousesList(r.houses_include),
  houses_exclude: mapHousesList(r.houses_exclude),
  houses_even: mapHousesList(r.houses_even),
  houses_odd: mapHousesList(r.houses_odd),
});

export const jurisdiction = {

  getList: (
    model: CourtJurisdictionGetListModel,
  ) => request<
    ListingResponse<
      CourtJurisdictionModel
      >
    >({
      command: ApiCommand.courtJurisdictionGetList,
      params: {
        court_type: model.court_type,
        ...formatListingRequest(model),
      },

    }).then((response) => {
      if (!response.status) {
        return response;
      }
      return {
        ...response,
        response: {
          ...response.response,
          results: response.response.results.map(prepareJurisdiction),
        },
      };
    }),

  getItem: (
    model: CourtJurisdictionItem,
  ) => request<
    CourtJurisdictionModel
    >({
      command: ApiCommand.courtJurisdictionGetItem,
      params: model,
    }).then((response) => {
      if (!response.status) {
        return response;
      }
      return {
        status: true,
        response: prepareJurisdiction(response.response),
      };
    }),

  createItem: (
    model: JurisdictionModelClient & CourtTypeAndId,
  ) => request<
    CourtJurisdictionModel
    >({
      command: ApiCommand.courtJurisdictionCreateItem,
      params: toParameters(model),
      data: jurisdictionModelToPayload(model),
    }),

  updateItem: (
    model: JurisdictionModelClient & CourtTypeAndId,
  ) => request<
    CourtJurisdictionModel
    >({
      command: ApiCommand.courtJurisdictionUpdateItem,
      params: toParameters(model),
      data: jurisdictionModelToPayload(model),
    }),

  deleteItem: (
    model: CourtJurisdictionItem,
  ) => request<
    boolean
    >({
      command: ApiCommand.courtJurisdictionDeleteItem,
      params: toParameters(model),
    }),

};

function toParameters(model: CourtTypeAndId | CourtJurisdictionItem) {
  return {
    court_type: model.court_type,
    court_id: model.court_id,
    id: (model as CourtJurisdictionItem).id && (model as CourtJurisdictionItem).id,
  };
}
