import { AutoTabKey, JudicialAutomationModel } from '@/components/automation/judicial/types';
import { Ref } from 'vue';
import { fieldGroup, fieldsUniter } from '@/components/automation/judicial/tabs/utils';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { Employee } from '@/hooks/useEmployees';

const COMPLAINT_OPTIONS = new Array(51).fill(null).map((_, i) => ({
  label: `Дней: ${i + 10}`,
  value: i + 10,
}));

// подача в суд
export function getCourtFields(
  model: Ref<JudicialAutomationModel>,
  localT: (s: string) => string,
  employees: Ref<Employee[]>,
) {
  return fieldsUniter(AutoTabKey.court, [
    {
      key: 'auto_correct_debt_period',
      type: ActiveFormFieldType.checkbox,
      options: {
        state: ['primary', 'short'],
      },
      ionFieldGroup: fieldGroup(AutoTabKey.court),
    },
    {
      key: 'court_test',
      type: ActiveFormFieldType.checkbox,
      options: {
        placeholder: '',
        allowEmpty: false,
        state: ['primary', 'short'],
      },
      ionFieldGroup: fieldGroup(AutoTabKey.court),
    },
    {
      key: 'show_automatic_attachments',
      type: ActiveFormFieldType.checkbox,
      options: {
        placeholder: '',
        allowEmpty: false,
        state: ['primary', 'short'],
      },
      ionFieldGroup: fieldGroup(AutoTabKey.court),
    },
    {
      key: 'auto_filing_claim',
      field: 'auto_filing_claim',
      type: ActiveFormFieldType.checkbox,
      options: {
        label: localT('autoFilingClaim.automatic'),
        isDisabled: !model.value.judicial_automation_enabled,
      },
      onUpdateModelValue: (model: JudicialAutomationModel, value: boolean) => {
        if (!value) {
          model.filling_claim_period = null;
        } else {
          model.filling_claim_period = {
            minute: '0',
            hour: '10',
            day_of_week: '*',
            day_of_month: '*',
            month_of_year: '*',
          };
        }
      },
      ionFieldGroup: fieldGroup(AutoTabKey.court),
    },
    {
      key: 'filling_claim_period',
      type: ActiveFormFieldType.schedule,
      state: ['primary'],
      options: {
        state: ['primary'],
        isDisabled: !model.value.auto_filing_claim,
      },
      ionFieldGroup: fieldGroup(AutoTabKey.court),
    },
    {
      key: 'court_user',
      type: ActiveFormFieldType.select,
      options: {
        placeholder: 'Выберите пользователя',
        allowEmpty: true,
        options: employees.value,
        displayField: ['first_name', 'last_name'],
        valueField: 'id',
        state: ['primary'],
      },
      ionFieldGroup: fieldGroup(AutoTabKey.court),
    },
    {
      key: 'need_rosreestr_discharge',
      type: ActiveFormFieldType.checkbox,
      options: {
        placeholder: '',
        allowEmpty: false,
        state: ['primary', 'short'],
      },
      ionFieldGroup: fieldGroup(AutoTabKey.court),
    },
    {
      key: 'auto_filing_complaint',
      type: ActiveFormFieldType.checkbox,
      options: {
        isDisabled: !model.value.judicial_automation_enabled,
      },
      onUpdateModelValue: (model: JudicialAutomationModel, value: boolean) => {
        if (!value) {
          model.court_complaint_overdue = null;
          model.auto_filing_complaint_period = null;
        } else {
          model.auto_filing_complaint_period = {
            minute: '0',
            hour: '10',
            day_of_week: '*',
            day_of_month: '1',
            month_of_year: '1,2,3,4,5,6,7,8,9,10,11,12',
          };
        }
      },
      ionFieldGroup: fieldGroup(AutoTabKey.court),
    },
    {
      key: 'court_complaint_overdue',
      type: ActiveFormFieldType.select,
      label: 'court_complaint_overdue',
      options: {
        state: ['drop-up', 'primary'],
        options: COMPLAINT_OPTIONS,
        isDisabled: !model.value.auto_filing_complaint,
      },
      ionFieldGroup: fieldGroup(AutoTabKey.court),
    },
    {
      key: 'auto_filing_complaint_period',
      type: ActiveFormFieldType.schedule,
      label: 'auto_filing_complaint_period',
      options: {
        isDisabled: !model.value.auto_filing_complaint,
      },
      ionFieldGroup: fieldGroup(AutoTabKey.court),
    },
  ]);
}
