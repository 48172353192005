import { ValidateCompanyResponse } from '@/components/dialog/dialogs/print/printDialogTypes';
import { IDialogComponent } from '@/hooks/useDialog';
import { IToast, IToastLevel } from '@/hooks/useToast';
import { RouteLocationRaw, Router } from 'vue-router';
import { IntegrationDialog } from '@/pages/exchange/integration/dialogs/integrationDialogs';

const fieldTranslatesMap = {
  esia: 'Авторизуйтесь на госуслугах организации',
  crypto_pro: 'Внесите данные по облачной ЭЦП',
  Организация_Подпись: 'Добавьте факсимильную подпись и печать',
  Организация_Печать: 'Добавьте факсимильную подпись и печать',
  pochta_ru: 'Авторизуйтесь в Почте России',
  attorney: 'Необходимо приложить доверенность',
} as const;

const getFieldTranslation = (
  field: keyof Omit<ValidateCompanyResponse, 'company_fields'>,
) => fieldTranslatesMap[field] ?? field;

const companyFieldTranslatesMap = {
  email: 'Email',
  email_buh: 'Email бух.',
  email_cor: 'Email кор.',
  physical_address: 'Физ. адрес',
  phone: 'Телефон',
  postal_address_zip_code: 'Почтовый индекс',
} as const;

const getCompanyFieldTranslateMessage = (
  field: keyof ValidateCompanyResponse['company_fields'],
) => companyFieldTranslatesMap[field] ?? field;

export const getValidateCompanyMessage = (
  resp: ValidateCompanyResponse,
  ignoreErrors: Array<keyof ValidateCompanyResponse>,
) => {
  const messages: string[] = [];
  (Object.keys(resp) as unknown as Array<keyof ValidateCompanyResponse>).forEach((key) => {
    if (!ignoreErrors.includes(key)) {
      if (key === 'company_fields') {
        const companyErrorMessages: string[] = [];
        const companyErrors = resp[key];
        if (companyErrors) {
          (Object.keys(companyErrors) as unknown as Array<keyof ValidateCompanyResponse['company_fields']>).forEach((key) => {
            const errors = companyErrors[key];
            if (errors && errors.length) {
              const message = getCompanyFieldTranslateMessage(key);
              companyErrorMessages.push(message);
            }
          });
        }
        if (companyErrorMessages.length) {
          messages.push(`- Заполните карточку организации: ${companyErrorMessages.join(', ')}`);
        }
      } else {
        const value = resp[key];
        if (value === false) {
          messages.push(`- ${getFieldTranslation(key)}`);
        }
      }
    }
  });
  return messages;
};

const redirectRouteMap = {
  esia: `/exchange/integration/services#${JSON.stringify({
    component: IntegrationDialog.integrationGosuslugi,
    addInRoute: true,
    preventCloseOnRouteChange: true,
    payload: {
      companyId: '{companyId}',
    },
  })}`,
  crypto_pro: '/exchange/integration/judgement/settings',
  Организация_Подпись: `/organizations#${JSON.stringify({
    component: IDialogComponent.company,
    addInRoute: true,
    preventCloseOnRouteChange: true,
    payload: {
      id: '{companyId}',
      isInitiallyEditing: true,
    },
    tab: 'documents',
  })}`,
  Организация_Печать: `/organizations#${JSON.stringify({
    component: IDialogComponent.company,
    payload: {
      id: '{companyId}',
      isInitiallyEditing: true,
    },
    addInRoute: true,
    preventCloseOnRouteChange: true,
    tab: 'documents',
  })}`,
  pochta_ru: `/exchange/integration/services#${JSON.stringify({
    component: IntegrationDialog.integrationPochta,
    payload: {
      companyId: '{companyId}',
    },
  })}`,
  company_fields: `/organizations#${JSON.stringify({
    component: IDialogComponent.company,
    addInRoute: true,
    preventCloseOnRouteChange: true,
    payload: {
      id: '{companyId}',
      isInitiallyEditing: true,
    },
  })}`,
} as const;

export const getCompanyValidationToasts = (
  resp: ValidateCompanyResponse,
  ignoreErrors: Array<keyof ValidateCompanyResponse>,
  companyId: number,
  router: Router,
) => {
  const toasts: Record<string, IToast> = {};
  (Object.keys(resp) as unknown as Array<keyof ValidateCompanyResponse>).forEach((key) => {
    if (ignoreErrors.includes(key)) {
      return;
    }
    let redirect: string|null = redirectRouteMap[key];
    redirect = redirect?.replace('"{companyId}"', String(companyId)) || null;
    if (key === 'company_fields') {
      const companyErrorMessages: string[] = [];
      const companyErrors = resp[key];
      if (companyErrors) {
        (Object.keys(companyErrors) as unknown as Array<keyof ValidateCompanyResponse['company_fields']>).forEach((key) => {
          const errors = companyErrors[key];
          if (errors && errors.length) {
            const message = getCompanyFieldTranslateMessage(key);
            companyErrorMessages.push(message);
          }
        });
      }
      if (companyErrorMessages.length) {
        toasts[key] = {
          id: key,
          label: `Перед продолжением необходимо заполнить следующие данные${redirect ? '(нажмите, чтобы перейти)' : ''}`,
          message: `Заполните карточку организации: ${companyErrorMessages.join(', ')}`,
          level: IToastLevel.danger,
          duration: null,
          isCloseable: true,
          onClick: redirect ? () => router.push(redirect as RouteLocationRaw) : undefined,
        };
      }
    } else {
      const value = resp[key];
      if (!value) {
        let redirect: string|null = redirectRouteMap[key];
        redirect = redirect?.replace('"{companyId}"', String(companyId)) || null;
        if (!toasts[key]) {
          toasts[key] = {
            id: key,
            label: `Отсутствуют необходимые данные ${redirect ? '(нажмите, чтобы перейти)' : ''}`,
            message: getFieldTranslation(key),
            level: IToastLevel.danger,
            duration: null,
            isCloseable: true,
            onClick: redirect ? () => router.push(redirect as RouteLocationRaw) : undefined,
          };
        } else {
          toasts[key].label += `, ${getFieldTranslation(key)}`;
        }
      }
    }
  });
  return Object.values(toasts);
};

export const getCompanyFirstValidationRoute = (
  resp: ValidateCompanyResponse,
  ignoreErrors: Array<keyof ValidateCompanyResponse>,
  companyId: number,
) => {
  const toasts: Record<string, IToast> = {};
  let firstRedirect: string|null = null;
  (Object.keys(resp) as unknown as Array<keyof ValidateCompanyResponse>).forEach((key) => {
    if (ignoreErrors.includes(key)) return;

    let redirect: string|null = redirectRouteMap[key];
    redirect = redirect?.replace('"{companyId}"', String(companyId)) || null;

    if (key === 'company_fields') {
      let hasCompanyErrorMessages = false;
      const companyErrors = resp[key];
      if (companyErrors) {
        (Object.keys(companyErrors) as unknown as Array<keyof ValidateCompanyResponse['company_fields']>).forEach((key) => {
          const errors = companyErrors[key];
          if (errors && errors.length) hasCompanyErrorMessages = true;
        });
      }
      if (hasCompanyErrorMessages && !firstRedirect) firstRedirect = redirect || null;
    } else {
      const value = resp[key];
      if (!value) {
        let redirect: string|null = redirectRouteMap[key];
        redirect = redirect?.replace('"{companyId}"', String(companyId)) || null;
        if (!toasts[key] && !firstRedirect) firstRedirect = redirect || null;
      }
    }
  });
  return firstRedirect;
};
