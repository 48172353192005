import { JudicialAutomationModel } from '@/components/automation/judicial/types';
import { Ref } from 'vue';
import {
  defaultCheckboxOptions,
  fieldGroup,
  fieldsUniter,
} from '@/components/automation/judicial/tabs/utils';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { ProductionType } from '@/hooks/useConstructor';
import { useI18n } from 'vue-i18n';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useDebtors } from '@/hooks/useDebtors';

export function useCommonFields(
  model: Ref<JudicialAutomationModel>,
) {

  const { t: rootT } = useI18n();
  const { companyModuleType, companyId } = useProtectedDefaultCompany();
  const { fetchDebtors } = useDebtors();

  return {
    getCommonFields() {
      return fieldsUniter('common', [
        {
          key: 'judicial_automation_enabled',
          field: 'judicial_automation_enabled',
          type: ActiveFormFieldType.checkbox,
          ionFieldGroup: fieldGroup('common'),
        },
        {
          key: 'auto_executive_transfer',
          type: ActiveFormFieldType.checkbox,
          options: defaultCheckboxOptions(model, rootT),
          ionFieldGroup: fieldGroup('common'),
        },
        {
          key: 'amount_range',
          field: 'amount_range',
          type: ActiveFormFieldType.range,
          options: {
            min: 0,
            max: 1000000,
            merge: 50000,
            tooltipPosition: 'bottom',
            withManualInput: true,
            ignoreCheckManualLimits: true,
            isDisabled: !model.value.judicial_automation_enabled,
          },
          onUpdateModelValue: (_: JudicialAutomationModel, value: number[]) => {
            const [min, max] = value;
            fetchDebtors({
              filters: {
                debtor_debt_min: min,
                debtor_debt_max: max,
                production_type: ProductionType.judicial,
                company_id: companyId.value,
                module: companyModuleType.value,
              },
              page: 1,
              limit: 1,
            }).then((e) => {
              model.value.debtors_amount = e?.response?.count || 0;
            });
          },
          ionFieldGroup: fieldGroup('common'),
        },
        {
          key: 'debtors_amount',
          field: 'debtors_amount',
          type: ActiveFormFieldType.input,
          options: {
            state: ['primary', 'tiny'],
            isDisabled: true,
          },
          ionFieldGroup: fieldGroup('common'),
        },
      ]);
    },
  };

}
