import {
  computed, onMounted, ref, SetupContext, watch,
} from 'vue';
import {
  DebtorCompositeUpdateConfigModel, debtorCompositeUpdateOptions,
  IDebtorCompositeUpdateConfigDialog,
} from '@/hooks/useDebtorCompositeUpdate';
import { ProductionType } from '@/hooks/useConstructor';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { Nullable } from '@/types/utils';

export const useDebtorCompositeUpdateConfig = (props: {
  onSave: IDebtorCompositeUpdateConfigDialog['onSave'];
  productionType: ProductionType;
  isOrganization?: boolean;
}, { emit }: SetupContext<'close'[]>) => {

  const { companyModuleType } = useProtectedDefaultCompany();
  const model = ref<Nullable<DebtorCompositeUpdateConfigModel>>({
    is_organization: false,
    update_type: 'update_passports_and_inn',
    only_main: true,
    update_encr_fio_by_estate: false,
    force: false,
  });

  const isOrganizationVisible = computed(
    () => props.isOrganization === undefined,
  );

  onMounted(() => {
    if (props.isOrganization !== undefined) {
      model.value.is_organization = props.isOrganization;
    }
  });

  const updateIsForTenants = computed(
    () => debtorCompositeUpdateOptions.find(
      ({ value }) => model.value.update_type === value,
    )?.for_tenants,
  );

  const updateOwnersIsVisible = computed(
    () => model.value.update_type?.includes('update_encr_fio'),
  );

  watch(updateIsForTenants, (v) => {
    if (!v) {
      model.value.only_main = false;
    }
  });

  watch(updateOwnersIsVisible, (v) => {
    if (!v) {
      model.value.update_encr_fio_by_estate = false;
    }
  });

  const submit = () => {
    if (!model.value.update_type) {
      return;
    }
    props.onSave(model.value as DebtorCompositeUpdateConfigModel);
    emit('close');
  };

  const updateTypeOptions = computed(
    () => debtorCompositeUpdateOptions.filter(
      (v) => (v.is_organization === undefined || v.is_organization === model.value.is_organization)
        && (!v.module_type || v.module_type.includes(2))
        && v.production_type.includes(props.productionType),
    ),
  );

  watch(() => model.value.is_organization, (v) => {
    model.value.update_type = !v ? 'update_passports_and_inn' : 'update_organization';
  });

  return {
    model,
    submit,
    updateTypeOptions,
    updateIsForTenants,
    updateOwnersIsVisible,
    isOrganizationVisible,
    companyModuleType,
  };
};
