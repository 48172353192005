import { ActiveFormField, ActiveFormModel, IActiveForm } from '@/hooks/useActiveForm';
import { computed, Ref, SetupContext } from 'vue';
import { isEqual } from '@/utils/object';
import { useToggle } from '@/hooks/useToggle';

export const useFilters = (
  filters: Ref<IActiveForm<any>['fields']|undefined>,
  filtersModel: Ref<ActiveFormModel<any>|undefined>,
  onResetFilters?: (defaultModel: ActiveFormModel<any>) => void,
) => {
  const [isFiltersVisible, toggleFilters, setState] = useToggle();

  const defaultFilter = computed(
    () => (filters.value || []).reduce((acc, cur) => {
      // @ts-ignore
      acc[cur.field] = JSON.parse(JSON.stringify(cur.defaultValue || null));
      return acc;
    }, {}),
  );
  const isFilterApplied = computed(() => (
    !isEqual(
      defaultFilter.value,
      filtersModel.value,
    )
  ));
  const filtersMap = computed<{
    [key in ActiveFormField<any>['key']]: ActiveFormField<any>
  }>(() => (filters.value || []).reduce((acc, filter) => {
    // @ts-ignore
    acc[filter.key] = filter;
    return acc;
  }, {}));
  const resetFilters = () => {
    if (onResetFilters) {
      const notResettableFilters = filters.value?.filter(
        ({ isResettable }) => typeof isResettable !== 'undefined' && !isResettable,
      ) ?? [];
      const notResettableFiltersModel = Object.fromEntries(
        notResettableFilters.map(({ key }) => ([key, filtersModel.value?.[key]])),
      );
      onResetFilters({
        ...JSON.parse(JSON.stringify(defaultFilter.value)) as ActiveFormModel<any>,
        ...notResettableFiltersModel,
      });
    }
  };
  const hideFilters = () => {
    setState(false);
  };

  return {
    isFilterApplied,
    filtersMap,
    resetFilters,
    isFiltersVisible,
    toggleFilters,
    hideFilters,
  };
};
