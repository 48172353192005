import { ActiveTableColumn, ActiveTableColumnStick } from '@/components/activeTable/useActiveTable';
import {
  ref, watch, Ref, computed, ComputedRef,
} from 'vue';
import { arrayFrom } from '@/utils/object';

export const useColumns = (
  columns: Ref<Array<ActiveTableColumn<any>>>,
  defaultVisibleColumns: Ref<string[]|null|undefined>,
  actionColumn?: Ref<ActiveTableColumn<any>|null>,
) => {
  const visibleColumns = ref<Array<ActiveTableColumn<any>['key']>>([]);
  watch(
    // @ts-ignore-next-line
    [columns, defaultVisibleColumns],
    ([columns, defaultVisibleColumns]: [ { key: string }[], string[]]) => {
      if (!defaultVisibleColumns) {
        visibleColumns.value = columns?.map(({ key }) => key) || [];
      } else {
        visibleColumns.value = [...defaultVisibleColumns];
      }
    }, {
      immediate: true,
    },
  );

  // @ts-ignore
  const computedColumns = computed<Array<ActiveTableColumn<any>>>(() => {
    if (!columns.value) return [];
    const comp = visibleColumns.value && visibleColumns.value.length
      ? visibleColumns.value
        .map((columnKey) => columns.value?.find(({ key }) => key === columnKey))
        .filter(Boolean)
      : columns.value;

    return [...comp, ...(actionColumn?.value ? [actionColumn.value] : [])];
  });

  return {
    computedColumns,
    visibleColumns,
  };
};
