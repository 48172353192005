import {
  computed, ref, watch, nextTick, onBeforeUpdate, onBeforeUnmount,
} from 'vue';
import { getInstanceById } from 'echarts/core';
// eslint-disable-next-line import/no-unresolved
import { ECElementEvent } from 'echarts/types/src/util/types';
import { parseLineKey } from '@/components/forms/form/reportingChartForm/form';
import {
  CHART_COLOR_NAMES, CHART_COLORS, ChartColorNames, reversedChartColorNames,
} from '@/components/plate/utils';
import ClickEvent = JQuery.ClickEvent;
import { wait } from '@/utils/common';

type Hex = string;

// { [номер группы]: цвет линии (hex или имя)[] }
export type PickedColorsModel = Record<string, (ChartColorNames|Hex)[]>;

export const useColorPicker = () => {
  const renderedTileRef = ref<any>();
  const popoverTargetRef = ref<EventTarget|undefined>();
  const popoverIsVisible = ref(false);
  const pickedLineMeta = ref<{ groupNumber: number; index: number }|undefined>();
  const pickedColors = ref<PickedColorsModel>({});
  const resetPickedColors = () => {
    pickedColors.value = {};
  };

  const pickedColor = computed<ChartColorNames|Hex|null>({
    get: () => {
      if (!pickedLineMeta.value) {
        return null;
      }
      const group = pickedColors.value[pickedLineMeta.value.groupNumber];
      console.log('picked color', group, pickedLineMeta.value);
      if (!group) {
        return null;
      }
      const color = group[pickedLineMeta.value?.index];
      return color.startsWith('#') ? color : reversedChartColorNames[color];
    },
    set: (color) => {
      popoverIsVisible.value = false;
      popoverTargetRef.value = undefined;
      if (!color) {
        pickedLineMeta.value = undefined;
        return;
      }
      if (!pickedLineMeta.value) {
        return;
      }
      nextTick(() => {
        if (pickedLineMeta.value) {
          const predefinedColor = reversedChartColorNames[color.toUpperCase()] as ChartColorNames;
          const group = pickedColors.value[pickedLineMeta.value?.groupNumber];
          group[pickedLineMeta.value?.index] = predefinedColor ?? color;
          pickedLineMeta.value = undefined;
        }
      });
    },
  });

  const onClick = (event: ECElementEvent) => {
    console.log('echart click event', event);
    if (!event.componentType) {
      return;
    }
    if (event.componentType === 'series') {
      const target = event.event?.event.target;
      if (target) {
        const lineKey = (event.data as any)[0];
        if (!lineKey) {
          return;
        }
        const parsedLine = parseLineKey(lineKey);
        if (typeof parsedLine === 'string') {
          return;
        }
        if (!parsedLine.part) {
          return;
        }
        pickedLineMeta.value = { groupNumber: parsedLine.group, index: parsedLine.part - 1 };
        popoverTargetRef.value = target;
        popoverIsVisible.value = true;
      }
    }
  };

  const unsubscribeChartClickEvent = () => {
    if (!renderedTileRef.value) {
      return;
    }
    const parent = renderedTileRef.value.$el.parentElement;
    const echartEl = parent.querySelector('x-vue-echarts');
    if (!echartEl) {
      return;
    }
    const echartId = echartEl.attributes._echarts_instance_.value;
    const echartInstance = getInstanceById(echartId);
    if (!echartInstance) {
      return;
    }
    console.log('unsubscribeChartClickEvent');
    // @ts-ignore
    echartInstance.off('click', onClick);
  };

  onBeforeUnmount(() => {
    unsubscribeChartClickEvent();
  });

  watch(renderedTileRef, (v) => {
    console.log('watch(renderedTileRef', v);
    if (!v) {
      return;
    }
    const parent = v.$el.parentElement;
    const echartEl = parent.querySelector('x-vue-echarts');
    if (!echartEl) {
      return;
    }
    const echartId = echartEl.attributes._echarts_instance_.value;
    const echartInstance = getInstanceById(echartId);
    console.log('echartInstance', echartInstance);
    if (echartInstance) {
      // @ts-ignore
      echartInstance.off('click', onClick);
      // @ts-ignore
      echartInstance.on('click', onClick);
    }
  });

  const closePopover = (e: MouseEvent & {
    path: HTMLElement[];
  }) => {
    if (Array.from(e.path).some((el) => el.classList?.contains('el-color-picker__panel'))) {
      return;
    }
    popoverIsVisible.value = false;
  };

  return {
    renderedTileRef,
    linePopoverRef: popoverTargetRef,
    colorPickerIsVisible: popoverIsVisible,
    closeColorPickerPopover: closePopover,
    pickedColors,
    resetPickedColors,
    pickedColor,
  };
};
