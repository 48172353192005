import { computed, ref } from 'vue';
import { ProductionType } from '@/hooks/useConstructor';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { useDataFileRequest } from '@/hooks/useDataFileRequest';
import { useSocket } from '@/hooks/useSocket';
import { IToastLevel, IToastProgressbar, useToast } from '@/hooks/useToast';
import { ApiCommand } from '@/store/modules/api';
import { PackageCompositeUpdateModel } from '@/types/datafile';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import { DataFileSocketMessage, getSocketMessageTaskID, isDataFileEvent } from '@/types/socket';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { IDialog, IDialogComponent, useDialog } from '@/hooks/useDialog';

export type DebtorCompositeUpdateType =
  'update_encr_fio'
  |'update_encr_inn'
  |'update_encr_passport'
  |'update_encr_snils'
  |'update_encr_phone'
  |'update_encr_address'
  |'update_organization'
  |'update_vehicle'
  |'update_passports_and_inn'

export type DebtorCompositeUpdateConfigModel = {
  is_organization?: boolean;
  update_type: DebtorCompositeUpdateType;
  only_main?: boolean;
  update_encr_fio_by_estate?: boolean;
  force?: boolean;
}

export type IDebtorCompositeUpdateConfigDialog = {
  onSave: (config: DebtorCompositeUpdateConfigModel) => void;
}

export const debtorCompositeUpdateOptions = [
  {
    is_organization: false,
    label: 'Получить ИНН / проверить действительность СНИЛС / паспорта (бесплатно)',
    value: 'update_passports_and_inn',
    for_tenants: true,
    production_type: [ProductionType.pretrial, ProductionType.judicial, ProductionType.executive],
  },
  {
    is_organization: false,
    label: 'Проверка банкротства - КАД / ЕФРСБ (бесплатно)',
    value: 'update_bankrupts',
    for_tenants: true,
    production_type: [ProductionType.pretrial, ProductionType.judicial, ProductionType.executive],
  },
  {
    is_organization: false,
    label: 'ФИО и дата рождения',
    value: 'update_encr_fio',
    for_tenants: true,
    production_type: [ProductionType.pretrial, ProductionType.judicial, ProductionType.executive],
  },
  {
    is_organization: false,
    label: 'ИНН',
    value: 'update_encr_inn',
    for_tenants: true,
    production_type: [ProductionType.pretrial, ProductionType.judicial, ProductionType.executive],
  },
  {
    is_organization: false,
    label: 'СНИЛС',
    value: 'update_encr_snils',
    for_tenants: true,
    production_type: [ProductionType.pretrial, ProductionType.judicial, ProductionType.executive],
  },
  {
    is_organization: false,
    label: 'Паспорт',
    value: 'update_encr_passport',
    for_tenants: true,
    production_type: [ProductionType.pretrial, ProductionType.judicial, ProductionType.executive],
  },
  {
    is_organization: false,
    label: 'Телефон',
    value: 'update_encr_phone',
    for_tenants: false,
    production_type: [ProductionType.pretrial, ProductionType.judicial, ProductionType.executive],
  },
  {
    is_organization: false,
    label: 'Адрес',
    value: 'update_encr_address',
    for_tenants: false,
    production_type: [ProductionType.pretrial, ProductionType.judicial, ProductionType.executive],
  },
  {
    label: 'Получить данные по ИНН / ОГРН (бесплатно)',
    is_organization: true,
    value: 'update_organization',
    for_tenants: false,
    production_type: [ProductionType.pretrial, ProductionType.judicial, ProductionType.executive],
  },
  {
    label: 'ГРЗ',
    value: 'update_vehicle',
    module_type: [2],
    for_tenants: false,
    production_type: [ProductionType.pretrial, ProductionType.judicial, ProductionType.executive],
  },
] as {
  label: string;
  value: DebtorCompositeUpdateType;
  for_tenants: boolean;
  is_organization?: boolean;
  production_type: ProductionType[];
  module_type?: CompanyModuleType[]; }[];

export const useDebtorCompositeUpdate = (
  onUpdate?: () => void,
) => {
  const { companyId, companyModuleType } = useDefaultCompany();
  const dataFileRequest = useDataFileRequest();
  const { subscribe } = useSocket();
  const { showToast, showPureDangerToast } = useToast();
  const { showDialog } = useDialog();
  const { awaitSignalResponse, subscribeToSignalOnce } = useSignal();

  return async (
    productionType: ProductionType,
    debtorPks: number[],
    isOrganization?: boolean,
  ) => {
    try {
      const filters = await awaitSignalResponse<Record<any, any>>(
        SignalType.getDebtorFilters,
        SignalType.debtorFilters,
      );

      const progressbars = ref<IToastProgressbar[]>([{
        key: 'progress',
        label: 'Обновление данных',
        max: 0,
        current: 0,
      }]);

      const progressbarsComputed = computed(
        () => progressbars.value.filter((v) => v.max),
      );

      const configResult = await new Promise<DebtorCompositeUpdateConfigModel|null>(
        async (resolve, reject) => {
          await showDialog({
            component: IDialogComponent.debtorCompositeUpdateConfig,
            addInRoute: false,
            title: 'Поиск данных по должнику',
            payload: {
              isOrganization,
              productionType,
              onSave: (model) => resolve(model),
            },
          });
          subscribeToSignalOnce(SignalType.dialogClosed, (params: IDialog) => {
            if (params.component === IDialogComponent.debtorCompositeUpdateConfig) {
              reject();
            }
          });
        },
      );

      const { status, response } = await dataFileRequest<PackageCompositeUpdateModel>(
        ApiCommand.compositeUpdate,
        {
          company: companyId.value!,
          filters: {
            ...filters,
            company_id: companyId.value!,
            production_type: productionType,
            debtor_ids: debtorPks || [],
            module: companyModuleType.value,
          },
          config: {
            ...(
              configResult ? Object.fromEntries(
                configResult.update_type.split(';').map((key) => ([key, true])),
              ) : {}
            ),
            ...(
              configResult?.update_encr_fio_by_estate ? {
                update_encr_fio_by_estate: true,
              } : {}
            ),
          },
          arguments: {
            ...(configResult
              ? { force: configResult.force, only_main: configResult.only_main }
              : {}
            ),
          },
        },
      );

      if (response.errors) {
        response.errors.forEach((error) => {
          const name = debtorCompositeUpdateOptions.find(
            (opt) => opt.value.includes(error.name),
          )?.label;
          showPureDangerToast({
            params: {
              label: name || error.name,
              message: error.error,
            },
          });
        });
      }

      if (!status) {
        await showPureDangerToast({
          params: {
            label: 'Ошибка',
            // @ts-ignore
            message: response.detail,
          },
        });
        return;
      }

      const taskCount = response.payload.task_count || 1;
      let taskCounter = 0;
      progressbars.value = new Array(taskCount).fill(null).map((_, i) => ({
        key: `stage_${i + 1}`,
        label: '...',
        message: undefined,
        max: 0,
        current: 0,
        showMessageInsteadOfLabel: true,
      }));
      const updateStage = (
        stage: number,
        { message, max, current }: { message?: string; max?: number; current?: number },
      ) => {
        const currentProgress = progressbars.value.find(({ key }) => key === `stage_${stage}`);
        if (currentProgress) {
          if (message) {
            currentProgress.message = `${message}: ${current} из ${max}`;
          } else {
            const msg = currentProgress.message ? currentProgress.message!.split(':')[0] : 'Прогресс';
            currentProgress.message = `${msg}: ${current} из ${max}`;
          }
          if (max) {
            currentProgress.max = max;
          }
          if (current) {
            currentProgress.current = current;
          }
        }
      };

      const hideToast = await showToast({
        message: 'debtor.common.update_data_toast.message',
        duration: null,
        level: IToastLevel.info,
        progressbars: progressbarsComputed,
        isCloseable: true,
      });

      const socketUnsub = await subscribe<DataFileSocketMessage>({
        condition: (msg) => (
          msg.action === 'progress_event'
        && isDataFileEvent(msg)
        && getSocketMessageTaskID(msg) === response.uuid
        ),
        handler: async (payload) => {
          let max = 0;
          let current = 0;
          let message = '';
          if (payload?.data?.progress) {
            max = payload.data.progress.total;
            current = payload.data.progress.step;
          }
          if (payload?.data?.description) {
            message = payload.data.description;
          }
          if (payload?.data?.obj?.status_text) {
            message = (message ? `${message} ` : '') + payload.data.obj?.status_text;
          }
          updateStage(taskCounter + 1, { message, max, current });
          if (payload.data.done) {
            taskCounter += 1;
          }
          if (taskCounter >= taskCount) {
            const { scarcity, used } = response.payload;
            const messageArr = [
              `Обогащено: ${used || 0}`,
            ];
            if (scarcity) {
              messageArr.push(`нехватка средств: ${scarcity || 0}`);
            }
            const message = messageArr.join(' ');
            await showToast({
              duration: 3000,
              label: 'debtor.common.update_data_toast.success',
              message,
              level: IToastLevel.success,
            });
            if (onUpdate) {
              onUpdate();
            }
            socketUnsub();
            await hideToast();
          }
        },
      });
    } catch (e) {
      if (e !== undefined) {
        console.error(e);
      }
    }
  };
};
