import {
  computed, ref, SetupContext,
} from 'vue';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useSettingsCommon } from '@/pages/exchange/integration/dialogs/useSettingsCommon';
import { MegapolisUpdatePayload, Model } from '@/pages/debtors/_module/tableActions/megapolisUpdate/types';
import { key } from '@/pages/exchange/integration/billing/Megapolis.vue';

export const useMegapolisUpdateDialog = (props: {
  onSave: MegapolisUpdatePayload['onSave'];

}, { emit }: SetupContext<'close'[]>) => {

  const setting = ref<Model>({});
  const { companyModuleType, companyId } = useProtectedDefaultCompany();

  useSettingsCommon({
    settingKey: key,
    companyId,
    model: setting,
    useOwner: false,
  });

  const model = ref<Model>({
    startDate: new Date(),
    endDate: new Date(),
  });

  const submit = () => {
    props.onSave({
      ...model.value,
    });
    emit('close');
  };

  return {
    model,
    submit,
    companyModuleType,
  };
};
