import {
  ActiveFormField,
  isActiveFormIonFieldGroup,
  isActiveFormIonListGroup,
} from '@/hooks/useActiveForm';
import {
  computed, ComputedRef, ref, Ref, watch,
} from 'vue';
import { arrayFrom } from '@/utils/object';
import { OnUpdateFieldPayload } from '@core/hooks/types';

export type ActiveFormFieldGroupNext = {
  tag: 'fieldGroup';
  key: string;
  label?: string;
  groupOrder: number;
  fields: ActiveFormField<any>[];
};

export type ActiveFormListGroupNext = {
  tag: 'listGroup';
  key: string;
  label?: string;
  // listItemLabel?: string;
  groupOrder: number;
  fields: ActiveFormField<any>[];
  isDeletable?: boolean;
  withInitValue?: boolean;
  getItemLabel?: (value: any, index: number) => string;
  getEmptyListItem: () => any;
  renderReadonly?: (Record: any) => any;
  isReadonly?: boolean;
}

export type ActiveFormGroupNext =
  | ActiveFormFieldGroupNext
  | ActiveFormListGroupNext;

export type UseMobileActiveFormConfig<Model extends Record<string, any>> = {
  modelValue: Ref<Model|undefined>;
  fields: Ref<ActiveFormField<Model>[]>;
  // errorsMap: Ref<DeepErrorsMap>;
  syncOnUpdate?: boolean;
  fieldState?: Ref<string[]>;
}

export const useActiveForm2 = <Model>(
  config: UseMobileActiveFormConfig<Model>,
) => {
  const initialModelValue = config.modelValue.value;
  function resetModel() {
    config.modelValue.value = initialModelValue;
  }

  const fieldGroups = computed(
    () => config.fields.value.reduce((acc, field, i, fieldsList) => {
      if (field.ionFieldGroup) {
        const fieldGroup = field.ionFieldGroup;
        const group = (acc as ActiveFormGroupNext[]).find(
          (g) => g.tag && g.key === fieldGroup.key,
        );
        if (group) {
          group.fields.push({
            ...field,
            state: computed(() => ([...(config.fieldState?.value ?? []), ...arrayFrom(field.state ?? [])])),
          });
        } else {
          if (isActiveFormIonFieldGroup(fieldGroup)) {
            acc.push({
              tag: 'fieldGroup',
              key: fieldGroup.key,
              label: fieldGroup.label,
              groupOrder: fieldGroup.groupOrder,
              fields: [{
                ...field,
                state: computed(() => ([...(config.fieldState?.value ?? []), ...arrayFrom(field.state ?? [])])),
              }],
            });
          } else if (isActiveFormIonListGroup(fieldGroup)) {
            const wholeGroup = fieldsList.filter(
              (f) => f.ionFieldGroup?.key === fieldGroup.key,
            );
            const getEmptyListItem = () => Object.fromEntries(
              Object.values(wholeGroup).map(
                (field) => ([field.key, field.defaultValue || undefined]),
              ),
            );
            acc.push({
              tag: 'listGroup',
              key: fieldGroup.key,
              label: fieldGroup.label,
              isReadonly: fieldGroup.isReadonly,
              groupOrder: fieldGroup.groupOrder,
              withInitValue: fieldGroup.withInitValue,
              fields: [{
                ...field,
                state: computed(() => ([...(config.fieldState?.value ?? []), ...arrayFrom(field.state ?? [])])),
              }],
              getItemLabel: fieldGroup.getItemLabel,
              getEmptyListItem,
              renderReadonly: fieldGroup.renderReadonly,
            });
          }
        }
      } else {
        acc.push({
          ...field,
          state: computed(() => ([...(config.fieldState?.value ?? []), ...arrayFrom(field.state ?? [])])),
        });
      }
      return acc;
    }, [] as (ActiveFormGroupNext|ActiveFormField<Model>)[]),
  );

  const modelLocal = config.modelValue;

  const updateModelField = ({ keyPath, value, field }: OnUpdateFieldPayload<Model, any>) => {
    const models = [modelLocal.value] as Model[];
    if (config.syncOnUpdate && config.modelValue?.value) {
      models.push(config.modelValue.value);
    }
    models.forEach((model) => {
      if (field?.onUpdateModelValue) {
        field.onUpdateModelValue(model, value);
      }
      // @ts-ignore
      keyPath.reduce((acc, key, i, arr) => {
        if (i === arr.length - 1) {
          acc[key] = value;
        }
        return acc[key];
      }, model);
    });
  };

  const addModelListItem = ({
    keyPath,
  }: { keyPath: [string] }) => {
    const [key] = keyPath;
    const group = (fieldGroups.value as ActiveFormListGroupNext[]).find(
      (gr) => gr.key === key,
    );
    if (group) {
      const models = [modelLocal.value] as Model[];
      if (config.syncOnUpdate && config.modelValue?.value) {
        models.push(config.modelValue.value);
      }
      models.forEach((model) => {
        // @ts-ignore
        (model[key] as any[]).push(group.getEmptyListItem());
      });
    }
  };

  const deleteModelListItem = ({
    keyPath,
  }: { keyPath: [string, string ]}) => {
    const [pKey, sKey] = keyPath;
    const models = [modelLocal.value] as Model[];
    if (config.syncOnUpdate && config.modelValue?.value) {
      models.push(config.modelValue.value);
    }
    models.forEach((model) => {
      // @ts-ignore
      model[pKey].splice(sKey as number, 1);
    });
  };

  return {
    resetModel,
    model: modelLocal,
    fieldGroups,
    updateModelField,
    addModelListItem,
    deleteModelListItem,
  };
};
