import { DocumentAttachment, usePrint } from '@/hooks/usePrint';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { computed, SetupContext } from 'vue';
import { useModelDialog } from '@/hooks/useModelDialog';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';

export type IPrintDocumentListProps = {
  isLoading: boolean;
  modelValue: DocumentAttachment[];
}

export const usePrintDocumentList = (
  props: IPrintDocumentListProps,
  emit: SetupContext['emit'] | any,
) => {
  const {
    t,
  } = useLocalI18n('print');

  const { updateCompanyAttachment } = usePrint();

  const draggableContainerLoadingClass = computed(() => (
    props.isLoading ? 'documentsList-disabled' : false
  ));

  const onContainerClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const { showDialog } = useModelDialog<{ name: string }, { name: string }, DocumentAttachment>({
    key: 'change_document_name',
    title: 'Изменение имени документа',
    fields: [
      {
        key: 'name',
        field: 'name',
        label: 'Имя документа',
        type: ActiveFormFieldType.input,
        options: {
          label: 'Имя документа',
        },
      },
    ],
    getForm: (document) => Promise.resolve({
      status: true,
      response: {
        name: document.attachment_name ?? document.name,
      },
    }),
    onModelUpdate: async (payload, document) => {
      if (!payload.name) {
        return {
          status: false,
          response: {
            name: ['Введите имя'],
          },
        };
      }

      if (document.id) {
        const response = await updateCompanyAttachment({
          ...document,
          attachment_name: payload.name,
        });
        if (!response.status) {
          return {
            status: false,
            response: response.response,
          };
        }
        return {
          status: true,
          response: {
            name: response.response.attachment_name as string,
          },
        };
      }
      return {
        status: true,
        response: {
          name: payload.name as string,
        },
      };
    },
  });

  const showRenameDocumentDialog = async (document: DocumentAttachment) => {
    const result = await showDialog(document);
    if (result.status) {
      const updatedDocument = props.modelValue.find((el) => {
        if (!document.id) {
          if (document.type === 'organization') {
            return el.document === document.document;
          }
          return el.type === document.type;
        }
        return el.id === document.id;
      });
      if (!updatedDocument) {
        console.error(`updated document not found: ${JSON.stringify(document)}`);
        return;
      }
      updatedDocument.attachment_name = result.response.name;
      if (!document.id) {
        emit('update:modelValue', props.modelValue);
      }
    }
  };

  return {
    draggableContainerLoadingClass, t, onContainerClick, showRenameDocumentDialog,
  };
};
