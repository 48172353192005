import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router';
import { h } from 'vue';
import { asyncStore } from '@/store';
import { AuthGuard, AuthGuardResponse } from '@/store/modules/user';
import { IDialog, IDialogComponent } from '@/hooks/useDialog';
import { ImportTypeKey } from '@/pages/exchange/import/importTypes';
import { SignalType } from '@/hooks/useSignal';
import { ACLRule } from '@/hooks/useACL';
import { getRoutePermissionChecker } from '@/hooks/useProtectedDefaultCompany';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/sign',
      name: 'sign',
      alias: '/login',
      redirect: {
        name: 'sign-in',
      },
      meta: {
        guestRequired: true,
      },
      component: () => import(/* webpackChunkName: "sign" */ '@/components/authLayout/AuthLayout.vue'),
      children: [
        {
          path: 'in',
          name: 'sign-in',
          component: () => import(/* webpackChunkName: "sign" */ '@/pages/sign/in/index.vue'),
        },
        {
          path: 'up',
          name: 'sign-up',
          component: () => import(/* webpackChunkName: "sign" */ '@/pages/sign/up/index.vue'),
        },
        {
          path: 'forgot',
          name: 'sign-forgot',
          component: () => import(/* webpackChunkName: "sign" */ '@/pages/sign/forgot/index.vue'),
        },
        {
          path: 'confirm',
          name: 'sign-confirm',
          component: () => import(/* webpackChunkName: "sign" */ '@/pages/sign/confirm/index.vue'),
        },
        {
          path: 'password',
          name: 'sign-password',
          component: () => import(/* webpackChunkName: "sign" */ '@/pages/sign/password/index.vue'),
        },
      ],
    },
    {
      path: '/media/:param+',
      component: () => import(/* webpackChunkName: "main" */ '@/pages/media/index.vue'),
      meta: {
        authRequired: true,
        noNeedLoadCriticalData: true,
      },
    },
    {
      path: '/',
      component: () => import(/* webpackChunkName: "main" */ '@/components/mainLayout/MainLayout.vue'),
      meta: {
        authRequired: true,
      },
      children: [
        {
          name: 'test',
          path: 'test',
          component: () => import('@/pages/test.vue'),
        },
        {
          name: 'index',
          path: '',
          redirect: {
            name: 'debtors',
          },
        },
        {
          name: 'dashboard',
          path: 'dashboard/:id?',
          props: true,
          component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/reporting/index.vue'),
        },
        {
          name: 'organizations',
          path: 'organizations',
          component: () => import(/* webpackChunkName: "organizations" */ '@/pages/organizations/organizations.vue'),
        },
        {
          name: 'debtors',
          path: 'debtors',
          component: {
            render() {
              return h('div');
            },
          },
        },
        {
          name: 'debtors',
          path: 'debtors',
          redirect: {
            name: 'debtors-module',
            params: {
              module: ImportTypeKey.judicial,
            },
          },
          component: () => import(/* webpackChunkName: "debtors" */ '@/pages/debtors/index.vue'),
          children: [
            {
              name: 'debtors-module',
              path: ':module?',
              props: true,
              component: () => import(/* webpackChunkName: "debtors" */ '@/pages/debtors/_module/index.vue'),
            },
          ],
          meta: {
            ACLRule: ACLRule.debtorsIndex,
            hasPrefetchData: true,
          },
          beforeEnter: async (to, from, next) => {
            const store = await asyncStore;
            if (!store.getters['companies/defaultCompany']) {
              next({ name: 'organizations' });
            } else {
              next();
            }
          },
        },
        {
          name: 'exchange-export',
          path: 'export/:dataType?',
          component: () => import(/* webpackChunkName: "exchange" */ '@/pages/export/index.vue'),
          props: true,
        },
        {
          name: 'exchange',
          path: 'exchange',
          redirect: {
            name: 'exchange-import',
          },
          component: () => import(/* webpackChunkName: "exchange" */ '@/pages/exchange/index.vue'),
          children: [
            {
              name: 'exchange-import',
              path: 'import/:dataType?',
              component: () => import(/* webpackChunkName: "exchange" */ '@/pages/exchange/import/index.vue'),
              props: true,
            },
            {
              name: 'exchange-integration',
              path: 'integration/',
              redirect: {
                name: 'exchange-integration-services',
              },
              component: () => import(/* webpackChunkName: "exchange" */ '@/pages/exchange/integration/index.vue'),
              props: true,
              children: [
                {
                  name: 'exchange-integration-notifications',
                  path: 'notifications/',
                  component: () => import(/* webpackChunkName: "exchange" */ '@/pages/exchange/integration/notifications/index.vue'),
                  props: true,
                  redirect: {
                    name: 'exchange-integration-notifications-tab',
                    params: {
                      type: 'sms',
                    },
                  },
                  children: [
                    {
                      name: 'exchange-integration-notifications-tab-messengers',
                      path: 'messengers/',
                      component: () => import(/* webpackChunkName: "exchange" */ '@/pages/exchange/integration/notifications/_tab-messengers/index.vue'),
                      props: {
                        type: 'messengers',
                      },
                    },
                    {
                      name: 'exchange-integration-notifications-tab',
                      path: ':type',
                      component: () => import(/* webpackChunkName: "exchange" */ '@/pages/exchange/integration/notifications/_tab/index.vue'),
                      props: true,
                    },
                  ],
                },
                {
                  name: 'exchange-integration-services',
                  path: 'services/',
                  component: () => import(/* webpackChunkName: "exchange" */ '@/pages/exchange/integration/services/index.vue'),
                  props: true,
                },
                {
                  name: 'exchange-integration-billing',
                  path: 'billing/',
                  component: () => import(/* webpackChunkName: "exchange" */ '@/pages/exchange/integration/billing/index.vue'),
                  props: true,
                },
                {
                  name: 'exchange-integration-judgement',
                  path: 'judgement/',
                  component: () => import(/* webpackChunkName: "exchange" */ '@/pages/exchange/integration/judgement/index.vue'),
                  props: true,
                  redirect: {
                    name: 'exchange-integration-judgement-settings',
                  },
                  children: [
                    {
                      name: 'exchange-integration-judgement-settings',
                      path: 'settings/',
                      component: () => import(/* webpackChunkName: "exchange" */ '@/pages/exchange/integration/judgement/settings/index.vue'),
                      props: true,
                    },
                    {
                      name: 'exchange-integration-judgement-crypto',
                      path: 'crypto/',
                      component: () => import(/* webpackChunkName: "exchange" */ '@/pages/exchange/integration/judgement/crypto/index.vue'),
                      props: true,
                    },
                  ],
                },
                {
                  name: 'exchange-integration-api',
                  path: 'api/',
                  component: () => import(/* webpackChunkName: "exchange" */ '@/pages/exchange/integration/api/index.vue'),
                  props: true,
                },
                {
                  name: 'exchange-integration-white-label',
                  path: 'white-label/',
                  component: () => import(/* webpackChunkName: "exchange" */ '@/pages/exchange/integration/whiteLabel/index.vue'),
                  props: true,
                },
              ],
            },
            {
              path: 'templates',
              name: 'exchange-templates',
              component: () => import(/* webpackChunkName: "admin" */ '@/pages/exchange/templates/index.vue'),
            },
            {
              path: 'templates/create',
              name: 'exchange-template-create',
              component: () => import(/* webpackChunkName: "admin" */ '@/pages/exchange/templates/template/template.vue'),
            },
            {
              path: 'templates/:id',
              name: 'exchange-template',
              props: true,
              component: () => import(/* webpackChunkName: "admin" */ '@/pages/exchange/templates/template/template.vue'),
            },
          ],
        },
        {
          name: 'test',
          path: 'test',
          component: () => import(/* webpackChunkName: "tests" */ '@/pages/test.vue'),
        },
        {
          name: 'updates',
          path: 'updates',
          component: () => import(/* webpackChunkName: "updates" */ '@/pages/updates/index.vue'),
        },
        {
          name: 'panel',
          path: 'panel',
          redirect: {
            name: 'panel-index',
          },
          component: () => import(/* webpackChunkName: "panel" */ '@/pages/panel/index.vue'),
          children: [
            {
              path: '',
              name: 'panel-index',
              component: () => import(/* webpackChunkName: "panel" */ '@/pages/panel/index/index.vue'),
            },
            {
              path: 'accounts',
              name: 'panel-accounts',
              component: () => import(/* webpackChunkName: "panel" */ '@/pages/panel/accounts/index.vue'),
            },
            {
              path: 'tariffs',
              name: 'panel-tariffs',
              component: () => import(/* webpackChunkName: "panel" */ '@/pages/panel/tariffs/index.vue'),
            },
            {
              path: 'password',
              name: 'panel-password',
              component: () => import(/* webpackChunkName: "panel" */ '@/pages/panel/password/index.vue'),
            },
            {
              path: 'referral',
              name: 'panel-referral',
              component: () => import(/* webpackChunkName: "panel" */ '@/pages/panel/referral/index.vue'),
            },
          ],
        },
        {
          path: 'constructor',
          name: 'constructor',
          component: () => import(/* webpackChunkName: "panel" */ '@/pages/constructor/index.vue'),
          children: [
            {
              path: 'index',
              name: 'panel-constructor',
              component: () => import(/* webpackChunkName: "panel" */ '@/pages/constructor/index/index.vue'),
            },
            {
              path: 'template/:id?',
              name: 'panel-constructor-template',
              component: () => import(/* webpackChunkName: "panel" */ '@/pages/constructor/template/index.vue'),
              props: true,
            },
            {
              path: 'templates',
              name: 'panel-constructor-templates',
              component: () => import(/* webpackChunkName: "panel" */ '@/pages/constructor/templates/index.vue'),
            },
          ],
        },
        {
          path: 'recognize',
          name: 'recognize',
          component: () => import(/* webpackChunkName: "panel" */ '@/pages/panel/recognize/index.vue'),
        },
        {
          name: 'admin-panel',
          path: 'a-panel',
          redirect: {
            name: 'admin-standartize',
          },
          component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/index.vue'),
          children: [
            {
              path: 'standartize',
              name: 'admin-standartize',
              component: () => import(
                /* webpackChunkName: "admin" */ '@/pages/admin/standartize/index.vue'
              ),
            },
            {
              path: 'courts',
              name: 'admin-courts',
              component: () => import(
                /* webpackChunkName: "admin" */ '@/pages/admin/courts/index.vue'
              ),
            },
            {
              path: 'region',
              name: 'admin-region-upload',
              component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/uploadRegion/index.vue'),
            },
            {
              path: 'enrichment',
              name: 'admin-enrichment-upload',
              component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/uploadEnrichment/index.vue'),
            },
            {
              path: 'recognize',
              name: 'admin-recognize',
              component: () => import(/* webpackChunkName: "admin" */ '@/pages/panel/recognize/index.vue'),
            },
            {
              path: 'statistic',
              name: 'admin-statistic',
              component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/statistic/index.vue'),
            },
            {
              path: 'billing',
              name: 'admin-billing',
              component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/billingCheck/index.vue'),
            },
          ],
        },
      ],
    },
  ],
});

const authGuard = ({
  isAuthorized,
  guestRequired,
  authRequired,
  forceLogOut,
}: AuthGuard): AuthGuardResponse => {
  if (isAuthorized /* && (getters.token.validUntil > new Date()) */) {
    if (guestRequired) {
      if (forceLogOut) {
        return { result: true };
      }
      return { result: false, redirect: { name: 'index' } };
    }
  } else if (authRequired) {
    return { result: false, redirect: { name: 'sign' } };
  }
  return { result: true };
};

router.beforeEach(async (to, from, next) => {
  const store = await asyncStore;
  if (store.state.layout.isUpdateAvailable && to.path !== from.path) {
    window.location.href = to.fullPath;
    return;
  }
  next();
});

window.onpopstate = async () => {
  const store = await asyncStore;
  const route = router.currentRoute.value as RouteLocationNormalized;
  if (route.name === 'sign-in' && store.getters['user/isAuthorized']) {
    router.push('/');
  }
};

router.beforeEach(async (to, from, next) => {
  const store = await asyncStore;
  if (store.state.layout.customIsLoaded) {
    next();
    return;
  }
  await new Promise<void>((resolve) => {
    const unwatch = store.watch((store) => store.layout.customIsLoaded, (value) => {
      if (value) {
        unwatch();
        resolve();
      }
    });
  });
  next();
});

router.beforeEach(async (to, from, next) => {
  const store = await asyncStore;
  const response = authGuard({
    isAuthorized: store.getters['user/isAuthorized'],
    ...to.meta,
    ...to.query,
  } as AuthGuard);
  if (response.result) {
    if (to.name === 'test') {
      next();
      return;
    }
    if (to.meta.authRequired) {
      // показываем роуты с авторизацией только после загрузки компаний
      // чтобы имень доступ к companyModuleType дефолтной компании
      if (to.meta.noNeedLoadCriticalData || store.getters['user/criticalDataIsLoaded']) {
        next();
      } else {
        store.dispatch('layout/showPreloader');
        await new Promise((resolve) => {
          const unsub = store.subscribe(({ type, payload }) => {
            if (type === 'layout/signal' && payload.signalType === SignalType.criticalDataIsLoaded) {
              resolve(true);
              unsub();
            }
          });
        });
        next();
      }
      return;
    }
    next();
    return;
  }
  next(response.redirect);
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'test') {
    next();
    return;
  }
  const store = await asyncStore;
  try {
    const dialog = JSON.parse(to.hash.substr(1)) as IDialog | undefined;
    if (
      dialog
      && 'component' in dialog
    ) {
      if (
        store.getters['layout/dialogs'][store.getters['layout/dialogs'].length - 1]?.component
        !== dialog.component
      ) {
        const {
          component, payload, ...rest
        } = dialog;
        await store.dispatch('layout/showDialog', {
          component,
          payload: payload || {},
          params: rest || {},
          addInRoute: false,
        } as IDialog);
      } else {
        store.commit('layout/updateDialogParams', dialog);
      }
    }
  } catch (e) {
    //
  }
  next();
});

// REDIRECT
router.beforeEach(async (to, from, next) => {
  console.log('redirect guard: to', to.path);
  if (!to.redirectedFrom) {
    next();
    return;
  }
  const store = await asyncStore;
  const permissionsMap = store.getters['companies/permissions'];
  const permissionChecker = getRoutePermissionChecker(permissionsMap);
  const routePermission = permissionChecker(to.name as string);
  console.log('redirect guard: routePermission', routePermission);
  if (routePermission) {
    next();
    return;
  }
  const currentMatchedRoute = to.redirectedFrom.matched.find(
    (route) => route.name === to.redirectedFrom!.name as string,
  );
  if (!currentMatchedRoute) {
    console.log('redirect guard: no matched route');
    next();
    return;
  }
  if (!currentMatchedRoute.children?.length) {
    console.log('redirect guard: no children in parent route');
    next();
    return;
  }
  const firstPermittedRoute = currentMatchedRoute.children.find(
    (route) => permissionChecker(route.name as string),
  );
  console.log('redirect guard: firstPermittedRoute', firstPermittedRoute);
  if (firstPermittedRoute) {
    next({ name: firstPermittedRoute.name });
    return;
  }
  next();
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'test') {
    next();
    return;
  }
  const store = await asyncStore;
  if (!store.getters['user/isAuthorized']) next();
  else {
    const isEulaAccepted = store.getters['user/isEulaAccepted'];
    if (isEulaAccepted === false) {
      if (!store.getters['layout/dialogs'].find((dialog: IDialog) => dialog.component === IDialogComponent.eulaDialog)) {
        store.dispatch('layout/showDialog', {
          component: IDialogComponent.eulaDialog,
          isCloseable: false,
          addInRoute: false,
        });
      }
    }
    next();
  }
});

router.afterEach(async (to) => {
  const store = await asyncStore;
  if (!to.meta.hasPrefetchData) {
    store.commit('layout/signal', {
      signalType: SignalType.globalPreloaderDataIsLoaded,
    });
  }
});

export default router;
