import { resolveStore } from '@core/utils/resolveStore';
import * as E from 'fp-ts/Either';
import * as ENV from '@/utils/env';

export const getAuthProtectedFileUrl = async (
  file: string,
  download = false,
) => {
  console.log('getAuthProtectedFileUrl', file);
  try {
    const store = await resolveStore();
    const url = new URL(
      (file.startsWith('http')
        ? file
        : `${window.location.origin}${file}`
      ).replace(window.location.origin, ENV.apiUrl),
    );
    const params = new URLSearchParams(url.search);
    const accessToken = (
      await store.dispatch('user/getJwtTokens')
    ) as E.Either<any, { access: { value: string } }>;
    if (E.isLeft(accessToken)) {
      throw new Error(String(accessToken.left));
    }
    params.append('access_token', accessToken.right.access.value);
    if (download) {
      params.append('download', '1');
    }
    url.search = params.toString();

    return url.toString();
  } catch (e) {
    console.error(`getAuthProtectedFileUrl error: ${String(e)} - file: ${file}`);
    return null;
  }
};

export const openAuthProtectedFile = async (file: string, withFlag = false) => {
  const url = await getAuthProtectedFileUrl(file, withFlag);
  if (!url) {
    return;
  }
  console.log(url);
  window.open(url.toString(), '_blank');
};

export const getFileExtension = (filePath: string) => {
  const splitted = filePath.split('.');
  return splitted.length ? splitted[splitted.length - 1] : null;
};
