import { reactive } from 'vue';

type ExpandRow = {
  fetched: boolean | undefined;
  expanded: boolean;

  fetch: () => Promise<any>;
  toggle: () => void;

  fetching: Promise<void> | null;
  records: Array<any>;
}

export type isExpandable = {
  expandRow: ExpandRow;
}

const fetchRow = async (row: ExpandRow) => {
  if (row.fetched) {
    return;
  }
  if (row.fetching) {
    return row.fetching;
  }
  row.fetching = row.fetch();
  await row.fetching;
  row.fetched = true;
};

const fetchExpand = async (row: ExpandRow) => {
  try {
    await fetchRow(row);
    row.expanded = true;
  } catch (error) {
    console.error('Error fetching subrecords', error);
  }
};

const collapseRow = (row: ExpandRow) => {
  row.expanded = false;
};

const toggleRow = async ({ expandRow, id }: { expandRow: ExpandRow; id: any }, expand?: boolean) => {
  const row = expandRow;
  if (expand || !row.expanded) {
    await fetchExpand(row);
  } else {
    collapseRow(row);
  }
  console.log('toggled row', format(id, expandRow));
};

const format = (id: any, row: ExpandRow) => ({
  id,
  expanded: row.expanded,
  records: row.records.length,
});

const setupRecordExpandRow = (
  record: any,
  fetch: (arg: any) => Promise<any[]>,
  prefetch = true,
  expand = true,
) => {
  record.expandRow = reactive({
    expanded: false,
    fetched: false,
    records: [],
    fetching: null,
    fetch: async () => {
      const data = await fetch(record.id);
      record.expandRow.records.push(...data);
    },
    toggle: () => toggleRow(record),
  });

  const verb = expand ? fetchExpand : fetchRow;

  prefetch && verb(record.expandRow).then(() => {
    console.log('fetched row expand data', format(record.id, record.expandRow));
  }).catch((error) => console.error('failed to fetch expand data', format(record.id, record.expandRow), error));
};

export const useExpandMixin = () => ({
  toggleRow,
  fetchRow,
  setupRecordExpandRow,
});
