import { TaskProgressData } from '@/types/socket';
import { commonLegacyApiRequest as request } from '@urrobot/core/service/commonService';
import { ApiCommand } from '@/store/modules/api';

export type UserSettingsSecureModel = {
  password: string;
  username: string;
}

export type UserSettingsResponse = {
  key: string;
  id: number;
}

export type Success = {
  roles: {
    display_text: string;
    short_rolename: string;
    rolename: string;
  }[];
}

export type Exception = {
  is_final: true;
  exception: 'TimeoutError';
}

export type Error = {
  is_final: true;
  tech_code: string;
  errors: string[];
}

export type SuspiciousActivity = {
  is_final: false;
  user_input_request: 'suspicious_activity';
  question_code: string;
  user_input_text: string;
  user_input_title: string;
  user_input_placeholder: string;
}

export type SuspiciousActivityResponse = {
  is_final: true;
  requested_user_input: 'suspicious_activity';
  user_message: string;
  tech_code: 'bad_user_input';
  user_input_placeholder: string;
}

export type Captcha = {
  is_final: false; user_input_title: string;
  user_input_placeholder: string; captcha_img: string;
}

export type GosuslugiDataObj = { user_input_request?: string } & (
  Success
  | Error
  | SuspiciousActivity
  | Captcha
  | Exception
  | SuspiciousActivityResponse
)

export type GosuslugiProgressMessage = {
  errors?: string[];
  data: {
    event: 'esia/login/';
    user_secure_data_id: number;
    progress: TaskProgressData;
    obj: GosuslugiDataObj;
    done: boolean;
  };
}

export function esiaTotpQR(company_id: number, snils: string) {
  return request({
    command: ApiCommand.integrationEsiaTotpQR,
    data: {
      snils,
      company_id,
    },
  });

}
