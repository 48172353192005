/// <reference types="grecaptcha" />

import { Module } from 'vuex';
import { StoreState } from '@/store';
import { tinyApiKey } from '@/utils/env';
import { IToast, IToastLevel } from '@/hooks/useToast';

export type ThirdPartyState = {
  isTinyMCELoaded: boolean;
  isCryptoProLoaded: boolean;
}

type ThirdPartyModule = Module<ThirdPartyState, StoreState>;

export enum CaptchaActions {
  signUp = 'signUp'
}

export const namespaced = true;

export const state: ThirdPartyModule['state'] = () => ({
  isTinyMCELoaded: false,
  isCryptoProLoaded: false,
});

export const getters: ThirdPartyModule['getters'] = {
  isTinyMCELoaded: (state) => state.isTinyMCELoaded,
  isCryptoProLoaded: (state) => state.isCryptoProLoaded,
};

export const mutations: ThirdPartyModule['mutations'] = {
  setTineMCELoadingState: (state, isLoaded: boolean) => {
    state.isTinyMCELoaded = isLoaded;
  },
  setCryptoProLoadingState: (state, isLoaded: boolean) => {
    state.isCryptoProLoaded = isLoaded;
  },
};

export const actions: ThirdPartyModule['actions'] = {
  async loadCryptoPro({ commit, getters }) {
    if (getters.isCryptoProLoaded) {
      return Promise.resolve(true);
    }
    // eslint-disable-next-line import/extensions
    // @ts-ignore
    await import('@core/plugins/cryptoPro/cadesplugin_api');
    commit('setCryptoProLoadingState', true);
  },
};
