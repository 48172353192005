import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { commonLegacyApiRequest as request } from '@core/service/commonService';
import { ProductionType } from '@/hooks/useConstructor';

type ClaimCommon = {
  id: number;
  production_type: ProductionType;
  is_loaded_manually: boolean;
  created_at: Date;
  debtor: number;
}

export type ClaimDto = ClaimCommon & {
  file: string;
  confirmation_file: string;
}

export type ClaimCreateModel = ClaimCommon & {
  file?: File | string | null;
  confirmation_file?: File | string | null;
}

export type ClaimUpdateModel = ClaimCreateModel & {
  id: number;
};

type Crud = {
  fetchAll: ApiCommand;
  fetch: ApiCommand;
  create: ApiCommand;
  update: ApiCommand;
}

export function useClaimsApi() {
  return useClaimsApiCommon({
    commands: {
      fetchAll: ApiCommand.fetchDebtorClaims,
      fetch: ApiCommand.fetchDebtorClaim,
      create: ApiCommand.createDebtorClaim,
      update: ApiCommand.updateDebtorClaim,
    },
  });
}

function useClaimsApiCommon({ commands }: { commands: Crud }) {

  const fetchClaims = (model: { filters: { debtor_id: number } }) => request<ListingResponse<ClaimDto>>({
    command: commands.fetchAll,
    params: {
      debtor_id: model.filters.debtor_id,
    },
  });

  const fetchClaim = (model: { id: number }) => request<ClaimDto>({
    command: commands.fetch,
    params: {
      id: model.id,
    },
  });

  const createClaim = (model: ClaimCreateModel) => request<ClaimDto>({
    command: commands.create,
    data: model,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const updateClaim = (model: ClaimUpdateModel) => request<ClaimDto>({
    command: commands.update,
    data: model,
    params: {
      id: model.id,
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {
    fetchClaims,
    fetchClaim,
    createClaim,
    updateClaim,
  };
}
