import base64 from 'base-64';
import { StoreJwtTokenData } from '@urrobot/web/src/hooks/useUser';

export type JwtContent = {
  exp: number; // timestamp
  iat: number; // timestamp
  user_id: number;
  linked_companies: number[];
}

export const parseJwtToken = (token: string) => {
  try {
    const [, dataPart] = token.split('.');
    const decoded = JSON.parse(base64.decode<JwtContent>(dataPart));
    return {
      ...decoded,
      iat: decoded.iat * 1000,
      exp: decoded.exp * 1000,
    } as JwtContent;
  } catch (e) {
    console.error(`JWT token parsing error: ${String(e)}`);
    return null;
  }
};

export const jwtContentToUserStore = (token: string, data: JwtContent): StoreJwtTokenData => ({
  exp: String(data.exp),
  value: token,
});

export const JWT_TOKEN_EXPIRED_THRESHOLD = 1000 * 10; // 10 секунд
// export const JWT_TOKEN_EXPIRED_THRESHOLD = 1000 * 3600; // час

export const jwtTokenIsExpired = (
  tokenData: StoreJwtTokenData,
) => +tokenData.exp - (new Date().getTime() + JWT_TOKEN_EXPIRED_THRESHOLD) < 0;
