import {
  computed, Ref, ref, watch,
} from 'vue';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { ApiResponseError, ApiResponseSuccess } from '@/service/api';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { useUser } from '@/hooks/useUser';
import { Employee, EmployeeRole } from '@/hooks/useEmployees';
import { isEqual } from '@/utils/object';

export type CryptoProCompanyUserSettingsSecureParams<Key> = {
  is_readable: boolean;
  company_id: number;
  key: Key;
  is_default?: boolean;
  is_public: boolean;
};

export const employeeCanEditPublicKey = (
  employee: Employee,
) => [EmployeeRole.manager, EmployeeRole.owner, EmployeeRole.manager].includes(
  employee.employee_role,
);

// TODO слишком переусложнено, убрать
export const useCompanySettingsSecureNext = <
  Model extends Record<string, any>,
  Response extends {
    id: number;
    is_default: boolean;
    data: Model;
  } & CryptoProCompanyUserSettingsSecureParams<Key>,
  Key extends string
  >({
    getEmptyModelData,
    initFetch,
    params,
  }: {
  getEmptyModelData: () => Model;
  initFetch?: boolean;
  params: Ref<CryptoProCompanyUserSettingsSecureParams<Key>>;
}) => {
  type ModelKeys = (keyof Model) | 'is_public' | 'use_public';
  const { user } = useUser();
  const { showToast } = useToast();
  const isLoading = ref(false);

  const getModel = () => ({
    data: getEmptyModelData(),
    ...params.value,
  }) as Response;

  const modelId = ref<number>();
  const backupModel = ref<Omit<Response, 'id'>>(getModel()) as Ref<Omit<Response, 'id'>>;
  const model = ref<Omit<Response, 'id'>>(getModel()) as Ref<Omit<Response, 'id'>>;

  // secure данные нельзя редактировать через patch, можно только пересоздавать
  // если is_readable = false, то никаких secure данных мы не можем менять, значит модель можно
  // обновить через patch
  const shouldRecreateEntity = computed(
    () => {
      if (!params.value.is_readable) {
        return false;
      }
      return !isEqual(backupModel.value.data, model.value.data);
    },
  );

  const fetch = async () => {
    isLoading.value = true;
    const response = await commonLegacyApiRequest<ListingResponse<Response>>({
      command: ApiCommand.fetchCompanySettingsSecure,
      params: {
        key: params.value.key,
        company_id: params.value.company_id,
        ...(params.value.is_public
          ? { is_public: true } : { owner: user.value?.id as number }),
      },
    }).then((response) => {
      if (response.status) {
        // if ('is_default' in params.value) {
        //   return {
        //     status: true,
        //     response: response.response.results.filter(
        //       (el) => el.is_default === params.value.is_default,
        //     )[0],
        //   } as ApiResponseSuccess<Response>;
        // }
        return {
          status: true,
          response: response.response.results[0],
        } as ApiResponseSuccess<Response>;
      }
      return response;
    });

    if (response.status) {
      modelId.value = response.response?.id;
      const finalModel = response.response
        ? JSON.parse(JSON.stringify(response.response))
        : getModel();
      model.value = finalModel;
      backupModel.value = JSON.parse(JSON.stringify(finalModel));
    } else {
      showToast({
        level: IToastLevel.danger,
        label: 'pureLabel',
        params: {
          label: `Ошибка загрузки настроек пользователя для ${params.value.key}`,
        },
      });
    }
    isLoading.value = false;
  };

  watch(params, fetch, { immediate: initFetch, deep: true });

  const remove = async () => {
    if (!modelId.value) {
      return {
        status: false,
        response: undefined,
      } as ApiResponseError;
    }
    const response = await commonLegacyApiRequest<undefined>({
      command: ApiCommand.deleteCompanySettingsSecure,
      params: {
        company_id: params.value.company_id,
        id: modelId.value,
      },
    });
    await fetch();
    return response;
  };

  const save = async () => {
    isLoading.value = true;
    const { data, ...restModel } = model.value;
    const { is_readable } = params.value;
    const apiPayload = {
      is_readable: params.value.is_readable,
      key: params.value.key,
      ...(!modelId.value || shouldRecreateEntity.value ? { source: data } : {}),
    } as any;
    if (restModel.is_default) {
      apiPayload.users = [user.value?.id as number];
      apiPayload.set_default = true;
    } else {
      apiPayload.set_default = false;
    }
    if (restModel.is_public) {
      apiPayload.users = [];
      apiPayload.set_default = false;
      apiPayload.set_public = true;
    } else {
      apiPayload.users = [user.value?.id as number];
      apiPayload.set_public = false;
      apiPayload.set_default = true;
    }

    if (shouldRecreateEntity.value && modelId.value) {
      const response = await commonLegacyApiRequest<undefined>({
        command: ApiCommand.deleteCompanySettingsSecure,
        params: {
          id: modelId.value,
          company_id: params.value.company_id,
        },
      });
      if (!response.status) {
        isLoading.value = false;
        showToast({
          level: IToastLevel.danger,
          label: 'pureLabel',
          params: {
            label: 'Ошибка',
          },
        });
        return {
          status: false,
          response: undefined,
        } as ApiResponseError;
      }
    }

    const response = await (!modelId.value || shouldRecreateEntity.value
      ? commonLegacyApiRequest<Response>({
        command: ApiCommand.createCompanySettingsSecure,
        params: {
          company_id: params.value.company_id,
        },
        data: apiPayload,
      })
      : commonLegacyApiRequest<Response>({
        command: ApiCommand.updateCompanySettingsSecure,
        params: {
          company_id: params.value.company_id,
          id: modelId.value,
        },
        data: apiPayload,
      }));
    await fetch();
    isLoading.value = false;

    if (!response.status) {
      return response as ApiResponseError;
    }

    return {
      status: true,
      response: response.response.data,
    } as ApiResponseSuccess<Model>;
  };

  const updateModelValue = <Key extends ModelKeys>(key: Key, value: Model[Key]) => {
    const _model = model.value;

    if (key === 'use_public') {
      // @ts-ignore
      _model.is_default = !value as unknown as boolean;
    } else if (key === 'is_public') {
      // @ts-ignore
      _model.is_public = true;
    } else {
      // @ts-ignore
      _model.data[key] = value;
    }
  };

  const getModelValue = (key: ModelKeys) => {
    const _model = model.value;

    if (key === 'use_public') {
      // @ts-ignore
      return !_model.is_default;
    } if (key === 'is_public') {
      // @ts-ignore
      return _model.is_public;
    }
    // @ts-ignore
    return _model.data[key];
  };

  return {
    isLoading,
    model,
    modelId,
    fetch,
    remove,
    save,
    updateModelValue,
    getModelValue,
  };
};
