import { arrayFrom } from '@/utils/object';

export const mapState = (
  state: string|string[]|undefined, map: Record<string, any>, defaultValue?: any,
) => {
  const mappedState = (state ? arrayFrom(
    state,
  ).map((state) => map[state]) : []).filter(Boolean);
  return mappedState.length
    ? mappedState
    : defaultValue
      ? [defaultValue]
      : [];
};
